import React from "react";
import Title from "../../components/title/index";
const HomePage = () => {
    return (
        <div id="homepage">
            <Title title="Tela Inicial" />
        </div>
    );
};

export default HomePage;
