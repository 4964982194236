import LoadingButton from "@mui/lab/LoadingButton";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Modal,
    Typography,
} from "@mui/material";
import React from "react";
import IconError from "../../assets/images/icons/close.png";
import IconCloseTwo from "../../assets/images/icons/icon-close-02.png";
import IconClose from "../../assets/images/icons/icon-close.png";
import styles from "./styles.module.scss";

// Default
export function ModalDefault(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        maxWidth: "395px",
    };
    const confirm = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        width: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background: "#e60e19 !important",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    //color:true
    const confirm2 = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        width: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%);",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    const cancel = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064b4 !important",
        background: "#f7f7f7 !important",
    };

    const handleClick = () => {
        props.setOpenModal(false);
    };
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <>
                        <Typography sx={h3} variant="subtitle1">
                            {props.title}
                        </Typography>
                        <Button
                            sx={{
                                color: "#3A393B",
                                width: "14px",
                                heigth: "14px",
                                float: "right",
                                top: -50,
                            }}
                            size="small"
                            variant="string"
                            onClick={() => props.setOpenModal(false)}
                        >
                            x
                        </Button>
                    </>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                        {/* Tem certeza que deseja sair do Sistema Web Contrate.ba? */}
                    </Typography>
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button
                            sx={props.color === "blue" ? confirm2 : confirm}
                            variant="outlined"
                            size="large"
                            onClick={handleClick}
                        >
                            {props.labelConfirm}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}
// Dialog Scroll (In Production)
export function ScrollDialog(props) {
    const scroll = "paper";
    const handleClose = () => {
        props.setOpenModal(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (props.openModal) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.openModal]);

    return (
        <div>
            <Dialog
                open={props.openModal}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {props.description ? (
                            <>{props.description}</>
                        ) : (
                            [...new Array(50)]
                                .map(
                                    () => `Cras mattis consectetur purus sit amet fermentum.
                                           Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                                           Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                                           Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                                )
                                .join("\n")
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
// Confirm (In Production)
export function ModalConfirm(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        maxWidth: "395px",
        marginTop: 3,
    };
    const confirm = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        width: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background: "#e60e19 !important",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#e60e19 !important",
    };
    //color:true
    const confirm2 = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        width: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%);",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    const cancel = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064b4 !important",
        background: "#FFFFFF !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <>
                        <Typography sx={h3} variant="subtitle1">
                            {props.title}
                        </Typography>
                        {props.labelXSair != "nao" && (
                            <Button
                                sx={{
                                    color: "#3A393B",
                                    width: "14px",
                                    heigth: "14px",
                                    float: "right",
                                    top: -50,
                                }}
                                size="small"
                                variant="string"
                                onClick={() => props.setOpenModal(false)}
                            >
                                <img
                                    src={IconClose}
                                    alt="Fechar"
                                    title="Fechar"
                                    width={20}
                                    height={20}
                                />
                            </Button>
                        )}
                    </>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                        {/* Tem certeza que deseja sair do Sistema Web Contrate.ba? */}
                    </Typography>
                    {props.description2 ? (
                        <Typography sx={body} variant="subtitle2">
                            {props.description2}
                            {/* Tem certeza que deseja sair do Sistema Web Contrate.ba? */}
                        </Typography>
                    ) : (
                        ""
                    )}
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        {props.hasCancel != "no" ? (
                            <Button
                                sx={cancel}
                                variant="outlined"
                                size="large"
                                onClick={handleCancel}
                            >
                                {props.labelCancel
                                    ? props.labelCancel
                                    : "Cancelar"}
                            </Button>
                        ) : (
                            ""
                        )}
                        <LoadingButton
                            sx={props.color === "blue" ? confirm2 : confirm}
                            variant="outlined"
                            size="large"
                            onClick={props.onClick}
                            loading={props.loading ? props.loading : false}
                        >
                            {props.labelConfirm}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}
/**
 * Modal Justificativa: Justify (In Production)
 */
export function ModalJustificativa(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "18px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29.05px",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        maxWidth: "395px",
    };
    const confirm = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px",
        textTransform: "none",
        color: "#fff !important",
        background: props.disabled ? "#B3B5B6" : "#e60e19",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        "&:hover": {
            color: "#D8D9DA",
            background:
                "linear-gradient(268.05deg, #e60e19 4.03%, #e60e19 101.81%)",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        },
    };
    //color:true
    const confirm2 = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        textTransform: "none",
        color: " #FFFFFF",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%)",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#0064B4",
        "&:hover": {
            borderColor: "#0064B4",
        },
    };
    const cancel = {
        fontFamily: "Inter",
        fontStyle: "normal",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064B4 !important",
        background: props.backgroundCacel
            ? `${props.backgroundCacel} !important`
            : "#FFFFFF !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    if (props.styles) {
        style.minHeight = props.styles.minHeight;
        style.width = props.styles.width;
    }
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <Typography sx={h3} variant="subtitle1">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                    </Typography>
                    {props.children}
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleCancel}
                            sx={cancel}
                            className="botaoCapitalizado"
                            size="large"
                        >
                            {props.labelCancel ? props.labelCancel : "Cancelar"}
                        </Button>
                        {!props.removeBtnConf && (
                            <Button
                                sx={
                                    props.color === "blue" && !props.disabled
                                        ? confirm2
                                        : confirm
                                }
                                onClick={props.onClick}
                                disabled={
                                    props.disabled ? props.disabled : false
                                }
                                variant="outlined"
                                className="botaoCapitalizado"
                                color="error"
                                size="large"
                            >
                                {props.labelConfirm}
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalSimplesSemConfirmacao(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "18px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29.05px",
        wordBreak: "break-all",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        maxWidth: "395px",
    };

    const estiloCancelar = {
        textTransform: "capitalize",
    };
    const confirm = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px",
        textTransform: "capitalize",
        color: props.disabled ? "#656A6E" : "#fff",
        background: props.disabled ? "#B3B5B6" : "#e60e19",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        "&:hover": {
            color: "#D8D9DA",
            background:
                "linear-gradient(268.05deg, #e60e19 4.03%, #e60e19 101.81%)",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        },
    };
    //color:true
    const confirm2 = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: " #FFFFFF",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%)",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    const cancel = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        textTransform: "capitalize",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064B4 !important",
        background: "#FFFFFF !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    if (props.styles) {
        style.minHeight = props.styles.minHeight;
        style.width = props.styles.width;
    }
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <Typography sx={h3} variant="subtitle1">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                    </Typography>
                    {props.children}
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleCancel}
                            className="botaoCapitalizado"
                            sx={cancel}
                            size="large"
                        >
                            {props.labelCancel ? props.labelCancel : "Cancelar"}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}

//Form (In Production)
export function ModalForm(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        height: "590px",
        width: "560px",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        p: 2,
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        outline: 0, //fix
    };

    const header = {
        width: "100%",
        height: "29px",
        padding: 0,
        margin: "10px 0",
    };
    const subHeader = {
        width: "100%",
        padding: 0,
        marginTop: -3.5,
    };
    const title = {
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };
    const subtitle = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
    };

    const body = {
        position: "absolute",
        top: "25%",
        margin: 0,
        padding: 0,
        height: "100%",
        width: "95%",
        // maxWidth: "560px",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "17px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        "&.MuiGrid-root": {
            m: 0,
            p: 0,
        },
    };
    const buttons = {
        height: "62px",
        width: "100%",
        margin: 0,
        padding: 0,
    };
    const confirm = {
        width: "181px",
        height: "60px",
        margin: 0,
        padding: 0,
        borderRadius: "50px !important",
        color: "#fff",
        background: "#e60e19 !important",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    const cancel = {
        right: "10px",
        minWidth: "181px",
        height: "60px",
        margin: 0,
        padding: 0,
        borderRadius: "50px !important",
        color: "#0064b4 !important",
        background: "#f7f7f7 !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid container direction="column" sx={style} spacing={1}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Typography sx={title} variant="subtitle1">
                            {props.title}
                        </Typography>
                        <Button
                            sx={{
                                color: "#3A393B",
                                width: "14px",
                                heigth: "14px",
                                float: "right",
                                // top: -50,
                                marginTop:
                                    props.title && props.title.length <= 69
                                        ? "40px"
                                        : "10px",
                            }}
                            size="small"
                            variant="string"
                            onClick={() => props.setOpenModal(false)}
                        >
                            x
                        </Button>
                    </div>
                    <Grid sx={subHeader}>
                        <Typography sx={subtitle} variant="subtitle2">
                            {props.description}
                        </Typography>
                    </Grid>
                </div>
                <Grid item sx={body}>
                    {props.children}

                    <Grid item sx={buttons}>
                        {props.disabledButtons === true ? (
                            ""
                        ) : (
                            <Box
                                sx={
                                    {
                                        // display: "flex",
                                        // justifyContent: "space-around",
                                    }
                                }
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleCancel}
                                    sx={cancel}
                                    size="large"
                                >
                                    {props.labelCancel
                                        ? props.labelCancel
                                        : "Cancelar"}
                                </Button>
                                <Button
                                    sx={confirm}
                                    onClick={props.onClick}
                                    variant="outlined"
                                    color="error"
                                    size="large"
                                >
                                    {props.labelConfirm}
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalConfirmOrNo(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        maxWidth: "395px",
    };
    const confirm = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        width: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background: "#e60e19 !important",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    //color:true
    const confirm2 = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        width: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%);",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    const cancel = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064b4 !important",
        background: "#f7f7f7 !important",
    };

    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <>
                        <Typography sx={h3} variant="subtitle1">
                            {props.title}
                        </Typography>
                        <Button
                            sx={{
                                color: "#3A393B",
                                width: "14px",
                                heigth: "14px",
                                float: "right",
                                top: -50,
                            }}
                            size="small"
                            variant="string"
                            onClick={() => props.setOpenModal(false)}
                        >
                            <img
                                src={IconClose}
                                alt="Fechar"
                                title="Fechar"
                                width={20}
                                height={20}
                            />
                        </Button>
                    </>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                        {/* Tem certeza que deseja sair do Sistema Web Contrate.ba? */}
                    </Typography>
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button
                            sx={cancel}
                            variant="outlined"
                            size="large"
                            onClick={props.handleCancel}
                        >
                            {props.labelCancel ? props.labelCancel : "Cancelar"}
                        </Button>
                        <LoadingButton
                            sx={props.color === "blue" ? confirm2 : confirm}
                            variant="outlined"
                            size="large"
                            onClick={props.onClick}
                            loading={props.loading ? props.loading : false}
                        >
                            {props.labelConfirm}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalOpenScreen(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        maxWidth: "395px",
    };
    const confirm2 = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        width: "350px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%);",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    const cancel = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064b4 !important",
        background: "#f7f7f7 !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() =>
                props.labelXSair === "nao" ? {} : props.setOpenModal(false)
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <>
                        <Typography sx={h3} variant="subtitle1">
                            {props.title}
                        </Typography>
                        {props.labelXSair !== "nao" && (
                            <Button
                                sx={{
                                    color: "#3A393B",
                                    width: "14px",
                                    heigth: "14px",
                                    float: "right",
                                    top: -50,
                                }}
                                size="small"
                                variant="string"
                                onClick={() => props.setOpenModal(false)}
                            >
                                <img
                                    src={IconClose}
                                    alt="Fechar"
                                    title="Fechar"
                                    width={20}
                                    height={20}
                                />
                            </Button>
                        )}
                    </>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                        {/* Tem certeza que deseja sair do Sistema Web Contrate.ba? */}
                    </Typography>
                    {props.description2 ? (
                        <Typography sx={body} variant="subtitle2">
                            {props.description2}
                            {/* Tem certeza que deseja sair do Sistema Web Contrate.ba? */}
                        </Typography>
                    ) : (
                        ""
                    )}
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <LoadingButton
                            sx={confirm2}
                            variant="outlined"
                            size="small"
                            onClick={props.onClick}
                            loading={props.loading ? props.loading : false}
                        >
                            {props.labelConfirm}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalGeneric(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        outline: 0,
        border: "1px solid #E5E5E5",
        borderRadius: "18px",
        boxShadow: 1,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
        fontFamily: "Inter",
        wordBreak: "break-all",
        textAlign: "center",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        width: "100%",
        justifyContent: "center",
        display: "flex",
    };
    const confirm = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px",
        textTransform: "capitalize",
        color: props.disabled ? "#FBFBFB !important" : "#fff !important",
        background: props.disabled ? "#B3B5B6" : "#e60e19",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        "&:hover": {
            color: "#D8D9DA",
            background:
                "linear-gradient(268.05deg, #e60e19 4.03%, #e60e19 101.81%)",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        },
    };
    //color:true
    const confirm2 = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        textTransform: "capitalize",
        color: " #FFFFFF",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%)",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#0064B4",
        "&:hover": {
            borderColor: "#0064B4",
        },
    };
    const cancel = {
        fontFamily: "Inter",
        fontStyle: "normal",
        textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064B4 !important",
        background: props.backgroundCacel
            ? `${props.backgroundCacel} !important`
            : "#f7f7f7 !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    if (props.styles) {
        style.minHeight = props.styles.minHeight;
        style.width = props.styles.width;
    }
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: -20,
                            paddingLeft: 20,
                            paddingRight: 20,
                        }}
                    >
                        {props.labelXSair !== "nao" && (
                            <Button
                                sx={{
                                    color: "#3A393B",
                                    width: "14px",
                                    heigth: "14px",
                                    // float: "right",
                                    // top: -35,
                                    // marginRight: 2,
                                    alignSelf: "flex-end",
                                }}
                                size="small"
                                variant="string"
                                onClick={() => props.setOpenModal(false)}
                            >
                                <img
                                    src={IconCloseTwo}
                                    alt="Fechar"
                                    title="Fechar"
                                    width={20}
                                    height={20}
                                />
                            </Button>
                        )}
                        <Typography sx={h3} variant="subtitle1">
                            {props.title}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                    </Typography>
                    {props.children}
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginBottom: 5,
                        }}
                    >
                        {props.btnCancel && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleCancel}
                                sx={cancel}
                                className="botaoCapitalizado"
                                size="large"
                            >
                                {props.labelCancel
                                    ? props.labelCancel
                                    : "Cancelar"}
                            </Button>
                        )}
                        <Box sx={{ padding: 1 }} />
                        {props.btnConfirm && (
                            <Button
                                sx={props.color === "blue" ? confirm2 : confirm}
                                onClick={props.onClick}
                                disabled={
                                    props.disabled ? props.disabled : false
                                }
                                variant="outlined"
                                className="botaoCapitalizado"
                                color="error"
                                size="large"
                            >
                                {props.labelConfirm}
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalSelect(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        // maxHeight: "245px",
        // maxWidth: "560px",
        // minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "18px",
        boxShadow: 1,
        // p: 1,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
        fontFamily: "Inter",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        // maxWidth: "395px",
        // maxWidth: "50px",
        width: "100%",
        justifyContent: "center",
        display: "flex",
    };
    const confirm = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px",
        textTransform: "capitalize",
        color: props.disabled ? "#656A6E" : "#fff",
        background: props.disabled ? "#B3B5B6" : "#e60e19",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        "&:hover": {
            color: "#D8D9DA",
            background:
                "linear-gradient(268.05deg, #e60e19 4.03%, #e60e19 101.81%)",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        },
    };
    //color:true
    const confirm2 = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        textTransform: "capitalize",
        color: " #FFFFFF",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%)",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#0064B4",
        "&:hover": {
            borderColor: "#0064B4",
        },
    };
    const cancel = {
        fontFamily: "Inter",
        fontStyle: "normal",
        textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064B4 !important",
        background: props.backgroundCacel
            ? `${props.backgroundCacel} !important`
            : "#f7f7f7 !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    if (props.styles) {
        style.minHeight = props.styles.minHeight;
        style.width = props.styles.width;
    }
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                    </Typography>
                    {props.children}
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalLoading(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        outline: 0,
        border: "1px solid #E5E5E5",
        borderRadius: "18px",
        boxShadow: 1,
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };

    if (props.styles) {
        style.minHeight = props.styles.minHeight;
        style.width = props.styles.width;
    }
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={body}>
                    {props.children}
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalConfirmFormato(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
    };
    const buttons = {
        maxWidth: "395px",
        marginTop: 2,
        marginBottom: 4,
    };
    const confirm = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        width: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background: "#e60e19 !important",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#e60e19 !important",
    };
    //color:true
    const confirm2 = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        width: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%);",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    const cancel = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064b4 !important",
        background: "#FFFFFF !important",
    };

    const colorData = props.colorBtn1 === "white" ? cancel : confirm;
    const colorBtn1 = props.colorBtn1 === "blue" ? confirm2 : colorData;

    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <>
                        <Typography sx={h3} variant="subtitle1">
                            {props.title}
                        </Typography>
                        {props.labelXSair !== "nao" && (
                            <Button
                                sx={{
                                    color: "#3A393B",
                                    width: "14px",
                                    heigth: "14px",
                                    float: "right",
                                    top: -28,
                                }}
                                size="small"
                                variant="string"
                                onClick={() => props.setOpenModal(false)}
                            >
                                <img
                                    src={IconCloseTwo}
                                    alt="Fechar"
                                    title="Fechar"
                                    width={18.67}
                                    height={18.67}
                                />
                            </Button>
                        )}
                    </>
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        {props.hasBtn1 != "no" ? (
                            <Button
                                sx={colorBtn1}
                                variant="outlined"
                                size="large"
                                onClick={props.onClickBtn1}
                            >
                                {props.labelBtn1 ? props.labelBtn1 : "Cancelar"}
                            </Button>
                        ) : (
                            ""
                        )}
                        <LoadingButton
                            sx={props.colorBtn2 === "blue" ? confirm2 : confirm}
                            variant="outlined"
                            size="large"
                            onClick={props.onClickBtn2}
                            loading={props.loading ? props.loading : false}
                        >
                            {props.labelBtn2}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalCustom(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "18px",
        boxShadow: 1,
        // p: 2,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        display: "flex",
        justifyContent: "center",
    };
    const confirm = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px !important",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px",
        textTransform: "none",
        color: props.disabled ? "#656A6E" : "#fff",
        background: props.disabled ? "#B3B5B6" : "#e60e19",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        "&:hover": {
            color: "#D8D9DA",
            background:
                "linear-gradient(268.05deg, #e60e19 4.03%, #e60e19 101.81%)",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        },
    };
    //color:true
    const confirm2 = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px !important",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        textTransform: "none",
        color: " #FFFFFF",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%)",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#0064B4",
        "&:hover": {
            borderColor: "#0064B4",
        },
    };
    const cancel = {
        fontFamily: "Inter",
        fontStyle: "normal",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px !important",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064B4 !important",
        background: props.backgroundCacel
            ? `${props.backgroundCacel} !important`
            : "#FFFFFF !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    if (props.styles) {
        style.minHeight = props.styles.minHeight;
        style.width = props.styles.width;
    }
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container={false} direction="column" spacing={1}>
                <Grid item sx={title}>
                    <Typography sx={h3} variant="subtitle1">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                    </Typography>
                    {props.children}
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "400px !important",
                            marginBottom: 3,
                            gap: 2,
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleCancel}
                            sx={cancel}
                            className="botaoCapitalizado"
                            size="large"
                        >
                            {props.labelCancel ? props.labelCancel : "Cancelar"}
                        </Button>
                        <Button
                            sx={
                                props.color === "blue" && !props.disabled
                                    ? confirm2
                                    : confirm
                            }
                            onClick={props.onClick}
                            disabled={props.disabled ? props.disabled : false}
                            variant="outlined"
                            className="botaoCapitalizado"
                            color="error"
                            size="large"
                        >
                            {props.labelConfirm}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalCustomUpload(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "18px",
        boxShadow: 1,
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    if (props.styles) {
        style.minHeight = props.styles.minHeight;
        style.width = props.styles.width;
    }
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container={false} direction="column" spacing={1}>
                <div className={styles.containerTopUpload}>
                    <div>
                        {props.labelXSair !== "nao" && (
                            <Button
                                size="small"
                                variant="string"
                                onClick={() => props.setOpenModal(false)}
                            >
                                <img
                                    src={IconCloseTwo}
                                    alt="Fechar"
                                    title="Fechar"
                                    width={24}
                                    height={24}
                                />
                            </Button>
                        )}
                    </div>
                    <span className={styles.titleModalUpload}>
                        {props?.title}
                    </span>
                </div>
                <div>{props.children}</div>
            </Grid>
        </Modal>
    );
}

export function ModalFormUpdatePassword(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        minHeight: "578px",
        width: "740px",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        p: 2,
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        outline: 0, //fix
    };

    const header = {
        width: "100%",
        height: "29px",
        padding: 0,
        margin: "10px 0px",
    };
    const title = {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };
    const subtitle = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
    };

    const body = {
        position: "absolute",
        margin: 0,
        padding: 0,
        height: "100%",
        width: "95%",
        // maxWidth: "560px",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "17px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        "&.MuiGrid-root": {
            m: 0,
            p: 0,
        },
    };
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid container direction="column" sx={style} spacing={1}>
                <Grid item sx={header}>
                    <Typography sx={title} variant="subtitle1">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item sx={header}>
                    <Typography sx={subtitle} variant="subtitle2">
                        {props.description}
                    </Typography>
                </Grid>
                <Grid item sx={body}>
                    {props.children}
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalConfirmCustom(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "23.29px",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22.4px",
        letterSpacing: 0,
        textAlign: props.textAlign ? props.textAlign : "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        maxWidth: "400px",
        marginTop: 3,
    };
    const confirm = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        width: props.widthButton ? props.widthButton : "360px",
        height: props.heightButton ? props.heightButton : "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background: "#e60e19 !important",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#e60e19 !important",
    };
    //color:true
    const confirm2 = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        width: props.widthButton ? props.widthButton : "360px",
        height: props.heightButton ? props.heightButton : "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background: props.backgroundColorButton
            ? props.backgroundColorButton
            : "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%);",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };

    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <>
                        <Typography sx={h3} variant="subtitle1">
                            {props.title}
                        </Typography>
                        {props.labelXSair !== "nao" && (
                            <Button
                                sx={{
                                    color: "#3A393B",
                                    width: "14px",
                                    heigth: "14px",
                                    float: "right",
                                    top: -50,
                                }}
                                size="small"
                                variant="string"
                                onClick={() => props.setOpenModal(false)}
                            >
                                <img
                                    src={IconClose}
                                    alt="Fechar"
                                    title="Fechar"
                                    width={20}
                                    height={20}
                                />
                            </Button>
                        )}
                    </>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                        {/* Tem certeza que deseja sair do Sistema Web Contrate.ba? */}
                    </Typography>
                    {props.description2 ? (
                        <Typography sx={body} variant="subtitle2">
                            {props.description2}
                            {/* Tem certeza que deseja sair do Sistema Web Contrate.ba? */}
                        </Typography>
                    ) : (
                        ""
                    )}
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <LoadingButton
                            sx={props.color === "blue" ? confirm2 : confirm}
                            variant="outlined"
                            size="large"
                            onClick={props.onClick}
                            loading={props.loading ? props.loading : false}
                            className={styles.hoverAvalPend}
                        >
                            {props.labelConfirm}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalJustCustom(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "18px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29.05px",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        width: "400px",
        alignSelf: "center",
    };
    const confirm = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px",
        textTransform: "none",
        color: " #FFFFFF !important",
        background: props.disabled ? "#B3B5B6" : "#e60e19",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        "&:hover": {
            color: "#D8D9DA",
            background:
                "linear-gradient(268.05deg, #e60e19 4.03%, #e60e19 101.81%)",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        },
    };
    //color:true
    const confirm2 = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        textTransform: "none",
        color: " #FFFFFF",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%)",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#0064B4",
        "&:hover": {
            borderColor: "#0064B4",
        },
    };
    const cancel = {
        fontFamily: "Inter",
        fontStyle: "normal",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064B4 !important",
        background: props.backgroundCacel
            ? `${props.backgroundCacel} !important`
            : "#FFFFFF !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    if (props.styles) {
        style.minHeight = props.styles.minHeight;
        style.width = props.styles.width;
    }
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <Typography sx={h3} variant="subtitle1">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                    </Typography>
                    {props.children}
                </Grid>
                <Grid sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: !props.removeBtnConf
                                ? "space-between"
                                : "center",
                            marginBottom: "30px",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={
                                props.statusClickCancel
                                    ? props.onClickCancel
                                    : handleCancel
                            }
                            sx={cancel}
                            className="botaoCapitalizado"
                            size="large"
                        >
                            {props.labelCancel ? props.labelCancel : "Cancelar"}
                        </Button>
                        {!props.removeBtnConf && (
                            <Button
                                sx={
                                    props.color == "blue" && !props.disabled
                                        ? confirm2
                                        : confirm
                                }
                                onClick={props.onClick}
                                disabled={
                                    props.disabled ? props.disabled : false
                                }
                                variant="outlined"
                                className="botaoCapitalizado"
                                color="error"
                                size="large"
                            >
                                {props.labelConfirm}
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalConfirScreenDoc(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "23.29px",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22.4px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const description = {
        margin: 0.5,
        padding: 1,
        marginBottom: 5,
    };
    const buttons = {
        // maxWidth: "395px",
    };
    const confirm2 = {
        textTransform: "none",
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        width: "350px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #0064B4 101.81%);",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    const cancel = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064b4 !important",
        background: "#f7f7f7 !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() =>
                props.labelXSair === "nao" ? {} : props.setOpenModal(false)
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <div
                // style={{
                //     backgroundColor: "red",
                //     alignItems: "center",
                //     alignContent: "center",
                //     alignSelf: "center",
                // }}
                >
                    <Grid item sx={title}>
                        <>
                            <Typography sx={h3}>{props.title}</Typography>
                            {props.labelXSair !== "nao" && (
                                <Button
                                    sx={{
                                        color: "#3A393B",
                                        width: "14px",
                                        heigth: "14px",
                                        float: "right",
                                        top: -50,
                                    }}
                                    size="small"
                                    variant="string"
                                    onClick={() => props.setOpenModal(false)}
                                >
                                    <img
                                        src={IconClose}
                                        alt="Fechar"
                                        title="Fechar"
                                        width={20}
                                        height={20}
                                    />
                                </Button>
                            )}
                        </>
                    </Grid>
                    <Grid item sx={description}>
                        <Typography sx={body} variant="subtitle2">
                            {props.description}
                            {/* Tem certeza que deseja sair do Sistema Web Contrate.ba? */}
                        </Typography>
                    </Grid>
                    <Grid item sx={buttons}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                            }}
                        >
                            <LoadingButton
                                sx={confirm2}
                                variant="outlined"
                                size="small"
                                onClick={props.onClick}
                                loading={props.loading ? props.loading : false}
                            >
                                {props.labelConfirm}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </div>
            </Grid>
        </Modal>
    );
}

export function ModalImagemUsuario(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        p: 4,
    };

    const buttonClose = {
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
    };

    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={buttonClose}>
                    <>
                        {props.labelXSair !== "nao" && (
                            <Button
                                sx={{
                                    color: "#3A393B",
                                    width: "14px",
                                    heigth: "14px",
                                    float: "right",
                                    top: -25,
                                }}
                                size="small"
                                variant="string"
                                onClick={() => props.setOpenModal(false)}
                            >
                                <img
                                    src={IconClose}
                                    alt="Fechar"
                                    title="Fechar"
                                    width={20}
                                    height={20}
                                />
                            </Button>
                        )}
                    </>
                </Grid>
                <div className={styles.fotoProf}>
                    <img src={props.imagem} width={500} height={500} />
                </div>
            </Grid>
        </Modal>
    );
}

export function ModalEditFotoPerfil(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "560px",
        minWidth: "311px",
        bgcolor: "background.paper",
        outline: 0, //fix
        border: "1px solid #E5E5E5",
        borderRadius: "15px",
        boxShadow: 1,
        p: 4,
    };

    const title = {
        maxWidth: "558px",
        height: "29px",
        letterSpacing: 0,
        textAlign: "center",
    };

    const h3 = {
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29.05px",
        color: "#0064B4",
    };

    const confirm = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        width: "285px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#fff",
        background: "#e60e19 !important",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#e60e19 !important",
    };
    //color:true
    const confirm2 = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        width: "285px",
        height: "60px",
        borderRadius: "50px !important",
        color: "#fff",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%);",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    };
    const cancel = {
        textTransform: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19.36px",
        width: "285px",
        height: "60px",
        borderRadius: "50px !important",
        color: "#0064b4 !important",
        background: "#FFFFFF !important",
        borderWidth: 1,
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column">
                <Grid item sx={title}>
                    <>
                        <Typography sx={h3}>{props.title}</Typography>
                        {props.labelXSair !== "nao" && (
                            <Button
                                sx={{
                                    color: "#3A393B",
                                    width: "14px",
                                    heigth: "14px",
                                    float: "right",
                                    top: -50,
                                }}
                                size="small"
                                variant="string"
                                onClick={() => props.setOpenModal(false)}
                            >
                                <img
                                    src={IconClose}
                                    alt="Fechar"
                                    title="Fechar"
                                    width={20}
                                    height={20}
                                />
                            </Button>
                        )}
                    </>
                </Grid>

                <div className={styles.containerEditFotoPerfil}>
                    <LoadingButton
                        sx={props.color === "blue" ? confirm2 : confirm}
                        variant="outlined"
                        size="large"
                        onClick={props.onClick}
                        loading={props.loading ? props.loading : false}
                    >
                        {props.labelConfirm}
                    </LoadingButton>
                    {props.hasCancel != "no" ? (
                        <Button
                            sx={cancel}
                            variant="outlined"
                            size="large"
                            onClick={handleCancel}
                        >
                            {props.labelCancel ? props.labelCancel : "Cancelar"}
                        </Button>
                    ) : (
                        ""
                    )}
                </div>
            </Grid>
        </Modal>
    );
}

export function ModalGenericCustom(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        outline: 0,
        border: "1px solid #E5E5E5",
        borderRadius: "18px",
        boxShadow: 1,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        margin: "25px 0px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
        fontFamily: "Inter",
        wordBreak: "break-all",
        textAlign: "center",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 2,
    };
    const buttons = {
        width: "100%",
        justifyContent: "center",
        display: "flex",
    };
    const confirm = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px",
        textTransform: "capitalize",
        color: props.disabled ? "#FBFBFB !important" : "#fff !important",
        background: props.disabled ? "#B3B5B6" : "#e60e19",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        "&:hover": {
            color: "#D8D9DA",
            background:
                "linear-gradient(268.05deg, #e60e19 4.03%, #e60e19 101.81%)",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        },
    };
    //color:true
    const confirm2 = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        textTransform: "capitalize",
        color: " #FFFFFF",
        background:
            "linear-gradient(268.05deg, #0064B4 4.03%, #1486E1 101.81%)",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#0064B4",
        "&:hover": {
            borderColor: "#0064B4",
        },
    };
    const cancel = {
        fontFamily: "Inter",
        fontStyle: "normal",
        textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064B4 !important",
        background: props.backgroundCacel
            ? `${props.backgroundCacel} !important`
            : "#f7f7f7 !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    if (props.styles) {
        style.minHeight = props.styles.minHeight;
        style.width = props.styles.width;
    }
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 0,
                            paddingLeft: 20,
                            paddingRight: 20,
                        }}
                    >
                        <Typography sx={h3} variant="subtitle1">
                            <img
                                src={IconError}
                                alt="Fechar"
                                title="Fechar"
                                width={32}
                                height={32}
                            />

                            <span style={{ marginLeft: 10 }}>
                                {props.title}
                            </span>
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                    </Typography>
                    {props.children}
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginBottom: 5,
                        }}
                    >
                        {props.btnCancel && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleCancel}
                                sx={cancel}
                                className="botaoCapitalizado"
                                size="large"
                            >
                                {props.labelCancel
                                    ? props.labelCancel
                                    : "Cancelar"}
                            </Button>
                        )}
                        <Box sx={{ padding: 1 }} />
                        {props.btnConfirm && (
                            <Button
                                sx={props.color === "blue" ? confirm2 : confirm}
                                onClick={props.onClick}
                                disabled={
                                    props.disabled ? props.disabled : false
                                }
                                variant="outlined"
                                className="botaoCapitalizado"
                                color="error"
                                size="large"
                            >
                                {props.labelConfirm}
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}

export function ModalGenericColor(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        outline: 0,
        border: "1px solid #E5E5E5",
        borderRadius: "18px",
        boxShadow: 1,
    };

    const title = {
        fontFamily: "Inter",
        maxWidth: "558px",
        height: "29px",
        marginTop: "10px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#0064B4",
    };

    const h3 = {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "29px",
        fontFamily: "Inter",
        wordBreak: "break-all",
        textAlign: "center",
    };
    const body = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: 0,
        textAlign: "center",
        color: "#343B40",
        margin: 0.5,
        padding: 1,
    };
    const buttons = {
        width: "100%",
        justifyContent: "center",
        display: "flex",
    };
    const confirm = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px",
        textTransform: "capitalize",
        color: props.disabled ? "#FBFBFB !important" : "#fff !important",
        background: props.disabled ? "#B3B5B6" : "#e60e19",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        "&:hover": {
            color: "#D8D9DA",
            background:
                "linear-gradient(268.05deg, #e60e19 4.03%, #e60e19 101.81%)",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        },
    };
    //color:true
    const confirm2 = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        textTransform: "capitalize",
        color: " #FFFFFF",
        background: props.color,
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        borderColor: "#0064B4",
        "&:hover": {
            borderColor: props.color,
            background: props.color,
        },
    };
    const cancel = {
        fontFamily: "Inter",
        fontStyle: "normal",
        textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "181px",
        height: "60px",
        margin: "0px 0px",
        borderRadius: "50px !important",
        color: "#0064B4 !important",
        background: props.backgroundCacel
            ? `${props.backgroundCacel} !important`
            : "#f7f7f7 !important",
    };

    const handleCancel = () => {
        props.setOpenModal(false);
    };
    if (props.styles) {
        style.minHeight = props.styles.minHeight;
        style.width = props.styles.width;
    }
    return (
        <Modal
            BackdropProps={{
                onMouseDown: (event) => {
                    const isRightClick = event.button === 2;
                    const isMiddleClick = event.button === 1;
                    if (isRightClick || isMiddleClick) {
                        props.setOpenModal(false);
                    }
                },
            }}
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style} container direction="column" spacing={1}>
                <Grid item sx={title}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 10,
                            paddingLeft: 20,
                            paddingRight: 20,
                        }}
                    >
                        <Typography sx={h3} variant="subtitle1">
                            {props.title}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={body}>
                    <Typography sx={body} variant="subtitle2">
                        {props.description}
                    </Typography>
                    {props.children}
                </Grid>
                <Grid item sx={buttons}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginBottom: 5,
                        }}
                    >
                        {props.btnCancel && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={
                                    props.statusClickCancel
                                        ? props.onClickCancel
                                        : handleCancel
                                }
                                sx={cancel}
                                className="botaoCapitalizado"
                                size="large"
                            >
                                {props.labelCancel
                                    ? props.labelCancel
                                    : "Cancelar"}
                            </Button>
                        )}
                        <Box sx={{ padding: 1 }} />
                        {props.btnConfirm && (
                            <Button
                                sx={props.color ? confirm2 : confirm}
                                onClick={props.onClick}
                                disabled={
                                    props.disabled ? props.disabled : false
                                }
                                variant="outlined"
                                className="botaoCapitalizado"
                                color="error"
                                size="large"
                            >
                                {props.labelConfirm}
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
}
