import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import Section1 from "../../../pages/cliente/Section/section1";
import Section4 from "../../../pages/cliente/Section/section4";
import Section6 from "../../../pages/cliente/Section/section6";
import Section7 from "../../../pages/cliente/Section/section7";

/* Content */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

/* id */
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
/* TabLabel */
function TabLabel(props) {
    return <Tab {...props} />;
}
// Size Container
const widthDefault = "880px";
const heightDefault = "880px";

const tabCSS = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    minWidth: "190px",
    "&.MuiButtonBase-root": {
        minWidth: "190px",
    },
    "&.MuiTab-root": {
        minWidth: "256px",
    }
};

export default function TabsPanel({ tabsData }) {
    //Default Tabs Open
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const BoxData = ({ children }) => {
        return (
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    background: "#F7F7F7",
                    borderRadius: "8px",
                    marginBottom: 7,
                    p: 3,
                }}
            >
                {children}
            </Box>
        );
    };

    //Renderiza por seção e carrega os dados
    const renderSwitch = (id, tabData) => {
        switch (id) {
            case 1:
                return <Section1 tabData={tabData} />;
            case 4:
                return (<BoxData><Section4 tabData={tabData} /></BoxData>);
            case 6:
                return (<BoxData><Section6 /></BoxData>);
            case 7:
                return (<BoxData><Section7 /></BoxData>);
            default:
                return;
        }
    };

    return (
        <Box
            sx={{
                maxWidth: widthDefault,
                minHeight: heightDefault,
            }}
        >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {/* labels */}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs"
                    variant={"scrollable"}
                >
                    {tabsData.map(({ tabLabel }, index) => {
                        // is it Selected?
                        tabCSS.color = value !== index ? "#C7C9D9" : "#0064B4";
                        return (
                            <TabLabel
                                key={index}
                                sx={tabCSS}
                                label={tabLabel}
                                {...a11yProps(index)}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            <Box sx={{ m: 2, color: "#ffffff" }}></Box>
                {/* content */}
                {tabsData.map(({ id, tabData }, index) => {
                    return (
                        <TabPanel key={index} value={value} index={index}>
                            {renderSwitch(id, tabData)}
                        </TabPanel>
                    );
                })}
        </Box>
    );
}
