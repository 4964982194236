import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb/index";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { Api } from "../../../providers";
import { DateBR, TimeBR } from "../../../utils/date";

const HistoricoSolicitacaoServicoPage = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const search = useLocation().search;
    const [historico, setHistorico] = useState([]);
    const [tarefa, setTarefa] = useState([]);

    useEffect(() => {
        setCurrentScreen(4);
    }, []);

    useEffect(() => {
        const idTarefa = new URLSearchParams(search).get("idTarefa");

        Api.get("/tarefa/" + idTarefa + "/historico").then((res) => {
            setHistorico(res.data.historico);
            setTarefa(res.data.tarefa);
        });
    }, []);

    if (tarefa.length <= 0) {
        return <Title title="Histórico ainda não disponível" />;
    }
    return (
        <Container fluid className="containerForm justify-content-center">
            <Breadcrumb
                data={{
                    category: "Categorias e Serviços > Tipo de serviço",
                    page: "Histórico",
                    menu: {
                        item: "Solicitações",
                        link: "/solicitacao",
                    },
                }}
            />
            <Title title="Histórico de Solicitações de Serviços" />
            <Container className="formulario">
                <br />
                {historico.map((row, key) => {
                    return (
                        <>
                            <div className="d-flex justify-content-between">
                                <p className="texto-tarefas-titulo">
                                    <b>{row.usuario?.nome}</b> mudou o status da
                                    solicitação para <b>{row.acao}</b>
                                </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="texto-tarefas">
                                    {DateBR(row.dataCadastro)} às{" "}
                                    {TimeBR(row.dataCadastro)}
                                </p>
                            </div>
                            <hr />
                        </>
                    );
                })}
                <div className="d-flex justify-content-between">
                    <p className="texto-tarefas-titulo">
                        <b>{tarefa.cliente?.nome}</b> criou a solicitação de
                        serviço
                    </p>
                </div>
                <div className="box-tarefas">
                    <b>Número da solicitação:</b>{" "}
                    {String(tarefa?.id).padStart(8, 0) + " "}
                    <b>Tipo de solicitação:</b> {tarefa?.tipo + " "}
                    <b>Data/hora:</b> {tarefa?.datas[0]} às {tarefa?.hora + " "}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="texto-tarefas">
                        {tarefa?.dataCadastro} {tarefa?.dataCadastro}
                    </p>
                </div>
            </Container>
        </Container>
    );
};

export default HistoricoSolicitacaoServicoPage;
