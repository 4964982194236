import { MenuItem, TextField } from "@mui/material";
import React from "react";

const Item = {
    maxWidth: 400,
    height: "56px",
    display: "flex",
    marginTop: "32px",
    justifyContent: "start",
    alignContent: "center",
    padding: 0,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    alignItems: "center",
    color: "#343B40",
    backgroundColor: "#ffffff",
    "& .MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "17px",
        color: "#343B40",
    },
    "& .MuiFormHelperText-root  ": {
        m: 0,
        p: 0,
        fontSize: 10,
        alignSelf: "flex-end",
        alignItems: "end",
        color: "red",
    },
};
export default function MyDropdown(props) {
    return (
        <TextField
            defaultValue={1}
            select
            fullWidth
            sx={Item}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
                shrink: true,
            }}
            {...props}
        >
            <MenuItem
                key={0}
                value={0}
                disabled={props.setvaluedefault ? false : true}
            >
                {props.setvaluedefault
                    ? props.setvaluedefault
                    : "Selecione uma opção"}
            </MenuItem>
            {props?.children}
        </TextField>
    );
}
