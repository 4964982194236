import React from "react";
import Breadcrumb from "../../../components/breadcrumb";
import Title from "../../../components/title";
import { Container } from "react-bootstrap";
import { ProfissionaisProvider } from "../../../contexts/service";
import ProfissionaisExcluidosTable from "../../../components/tables/profissionaisExcluidos";

const ProfissionaisExcluidosPage = () => {
    return (
        <ProfissionaisProvider>
            <Container fluid className="justify-content-center">
                <Breadcrumb
                    data={{
                        category: "Profissionais",
                        page: "Usuários excluídos",
                    }}
                />
                <Title title="Usuários excluídos" />
                <Container className="listagem">
                    <ProfissionaisExcluidosTable />
                </Container>
            </Container>
        </ProfissionaisProvider>
    );
};

export default ProfissionaisExcluidosPage;
