import qs from "qs";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { ServiceContext } from "../../../../contexts/service";
import { Api } from "../../../../providers";
import { InputSearch } from "../../../inputs";

export default function SolicitacaoClienteSearch(props) {
    const { setClientes } = useContext(ServiceContext);
    const [busca, setBusca] = useState("");

    const handleSumbit = (event) => {
        event.preventDefault();
        let params = {};
        if (busca !== "") params.fields = busca;
        Api.get(`/cliente/?situacao=ATIVO&flagEmpresa=`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Provider
                setClientes(res.data);
                if (res.data.length <= 0) {
                    toast.success("Nenhum resultado encontrado!", {
                        toastId: "msg",
                    });
                }
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
            });
        return true;
    };

    return (
        <div className="conteudo-tabela">
            <form>
                <InputSearch
                    placeholder="Pesquisar aqui pelo nome do cliente, número do cpf/cnpj ou número do telefone"
                    onClick={handleSumbit}
                    onChange={(e) => setBusca(e.target.value)}
                />
            </form>
        </div>
    );
}
