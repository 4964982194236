import React from "react";
import { Box, TextField, FormControl, FormHelperText } from "@mui/material";
import { CpfMask } from "../../../utils/mask";
import styles from "./styles.module.scss";

const ItemCustomField = {
    "& .MuiOutlinedInput-input": {
        padding: "16.5px 0px",
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiInputBase-input": {
        width: "93%",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
};

export default function Step1({ formik }) {
    return (
        <div className={styles.containerCadastro1}>
            <div className={`${styles.containerTextField}`}>
                <span>Nome</span>
                <div className={`${styles.containerInput} ${formik.touched.nome && formik.errors.nome ? styles.containerPassWordErro : {}}`}>
                    <TextField
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.nome}
                        fullWidth
                        sx={ItemCustomField}
                        className={`${styles.inputTextField} step1Cadast`}
                        name="nome"
                        id="nome"
                        placeholder="Seu nome"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div>
                    {Boolean(formik.touched.nome && formik.errors.nome) && (
                        <FormHelperText error>
                            {formik.errors.nome}
                        </FormHelperText>
                    )}
                </div>
            </div>
            
            <div className={`${styles.containerTextField} ${styles.dividerSpace}`}>
                <span>CPF</span>
                <div className={`${styles.containerInput} ${formik.touched.cpf && formik.errors.cpf ? styles.containerPassWordErro : {}}`}>
                    <TextField
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.cpf}
                        fullWidth
                        sx={ItemCustomField}
                        className={styles.inputTextField}
                        name="cpf"
                        id="cpf"
                        placeholder="000.000.000-00"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputComponent: CpfMask,
                        }}
                    />
                </div>
                <div>
                    {Boolean(formik.touched.cpf && formik.errors.cpf) && (
                        <FormHelperText error>
                            {formik.errors.cpf}
                        </FormHelperText>
                    )}
                </div>
            </div>
            
            <div className={`${styles.containerTextField} ${styles.dividerSpace}`}>
                <span>E-mail</span>
                <div className={`${styles.containerInput} ${formik.touched.email && formik.errors.email ? styles.containerPassWordErro : {}}`}>
                    <TextField
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        fullWidth
                        sx={ItemCustomField}
                        className={styles.inputTextField}
                        name="email"
                        id="email"
                        placeholder="voce@email.com"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div>
                    {Boolean(formik.touched.email && formik.errors.email) && (
                        <FormHelperText error>
                            {formik.errors.email}
                        </FormHelperText>
                    )}
                </div>
            </div>

            <div className={`${styles.containerTextField} ${styles.dividerSpace}`}>
                <span>Confirmar e-mail</span>
                <div className={`${styles.containerInput} ${formik.touched.emailConfirm && formik.errors.emailConfirm ? styles.containerPassWordErro : {}}`}>
                    <TextField
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.emailConfirm}
                        fullWidth
                        sx={ItemCustomField}
                        className={styles.inputTextField}
                        name="emailConfirm"
                        id="emailConfirm"
                        placeholder="voce@email.com"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div>
                    {Boolean(formik.touched.emailConfirm && formik.errors.emailConfirm) && (
                        <FormHelperText error>
                            {formik.errors.emailConfirm}
                        </FormHelperText>
                    )}
                </div>
            </div>
        </div>
    );
}
