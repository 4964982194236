import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import Breadcrumb from "../../../components/breadcrumb/index";
import { ModalLoading } from "../../../components/modal";
import ResultNotFound from "../../../components/resultNotFound";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { Api } from "../../../providers";
import { CpfCnpjMaskRegex, TelMaskRegex } from "../../../utils/mask";
import TabsPanel from "./../../../components/tabs/ocorrencia";
import { ClientesProfissionaisProvider } from "./../../../contexts/service";
import styles from "./styles.module.scss";

export default function NovaOcorrencia() {
    const { setCurrentScreen } = useContext(AuthContext);

    const [nameClient, setNameClient] = useState("");
    const [clientesData, setClientesData] = useState([]);
    const [idClient, setIdClient] = useState(0);
    const [loadingModal, setLoadingModal] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientError, setClientError] = useState("Campo obrigatório");

    let mounted = true;
    const itemsPerPage = 5;

    const tabsData = [
        { id: 1, tabLabel: "Data específica", tabData: [] },
        { id: 2, tabLabel: "Por profissional", tabData: [] },
    ];

    useEffect(() => {
        setCurrentScreen(9);
    }, []);

    // ***********************************************************************
    const inputSearch = (text) => {
        if (text.length > 0 && text.trim()) {
            setNameClient(text);
            setClientError("");
        } else {
            setNameClient("");
            setClientError("Campo obrigatório");
            setClientesData([]);
            setTotalPages(0);
            setIdClient(0);
        }
    };

    const handleBtn = (event) => {
        event.preventDefault();
        if (nameClient.length <= 0 && !nameClient.trim()) {
            return;
        }
        setLoadingModal(true);
        Api.get(`cliente/pagination/0/5?fields=${nameClient}`)
            .then((res) => {
                if (mounted && res.status == 200) {
                    const resultInfo = res.data.content.filter(
                        (val) => val.situacao != "Excluído",
                    );
                    setClientesData(resultInfo);
                    setTotalPages(res.data.totalPages);
                    setIdClient(0);
                    setLoadingModal(false);
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoadingModal(false);
                    setIdClient(0);
                }
            });
    };

    const handleSelected = (id) => {
        const resultId = Number(id);
        if (resultId > 0) {
            setIdClient(resultId);
        }
    };

    const nextPage = (page = 0) => {
        setLoadingModal(true);
        Api.get(
            `/cliente/pagination/${page}/${itemsPerPage}?fields=${nameClient}`,
        )
            .then((res) => {
                setClientesData(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoadingModal(false);
            })
            .catch((_) => {
                setLoadingModal(false);
            });
    };

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };
    // ***********************************************************************

    return (
        <ClientesProfissionaisProvider>
            <Grid>
                <Breadcrumb
                    data={{
                        category: "Home > Solicitação",
                        page: "Nova ocorrência",
                        menu: {
                            item: "Ocorrências",
                            link: "/ocorrencias/",
                        },
                    }}
                />
                <Title title="Nova ocorrência" />
                {/* **************************************************************** */}

                <div className={styles.containerClient}>
                    <div className="d-flex justify-content-center align-items-center container">
                        <div className="d-flex flex-column">
                            <span
                                className={
                                    clientError
                                        ? styles.textLabelRed
                                        : styles.textLabel
                                }
                            >
                                Selecione cliente
                            </span>
                            <div className={`${styles.containerInput}`}>
                                <div
                                    className={`col-sm-8 ${
                                        clientError ? styles.formGroupError : ""
                                    }`}
                                >
                                    <input
                                        onChange={(e) =>
                                            inputSearch(e.target.value)
                                        }
                                        value={nameClient}
                                        type="text"
                                        className={`form-control ${styles.inputSearchClient}`}
                                        title="Nome do cliente..."
                                        placeholder="Nome do cliente..."
                                        aria-label="Nome do cliente..."
                                    />
                                    <span className={styles.requiredField}>
                                        {clientError}
                                    </span>
                                </div>
                                <div>
                                    <button
                                        className={
                                            nameClient.length <= 0
                                                ? styles.buttonSearchBlock
                                                : styles.buttonSearch
                                        }
                                        type="submit"
                                        onClick={handleBtn}
                                        disabled={
                                            nameClient.length <= 0
                                                ? true
                                                : false
                                        }
                                    >
                                        Pesquisar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`d-flex flex-column justify-content-center align-items-center container table-responsive`}
                    >
                        {clientesData.length > 0 && (
                            <table
                                className={`table align-middle ${styles.table}`}
                            >
                                <thead>
                                    <tr>
                                        <th>
                                            <div>
                                                <span />
                                            </div>
                                        </th>
                                        <th>Cliente</th>
                                        <th>Telefone</th>
                                        <th>CPF/CNPJ</th>
                                    </tr>
                                </thead>
                                <tbody
                                    data-test="table-body"
                                    className={styles.tbodyTable}
                                >
                                    {clientesData.length > 0 &&
                                        clientesData.map((val) => {
                                            return (
                                                <tr
                                                    onClick={() =>
                                                        handleSelected(val.id)
                                                    }
                                                    className={
                                                        idClient == val.id
                                                            ? styles.textSelected
                                                            : styles.textNoSelected
                                                    }
                                                >
                                                    <td>
                                                        {idClient == val.id ? (
                                                            <div
                                                                className={
                                                                    styles.radioCircle
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles.circleBall
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    styles.radCirNoSelected
                                                                }
                                                            />
                                                        )}
                                                    </td>
                                                    <td>{val.nome}</td>
                                                    <td>
                                                        {val.telefoneCelular
                                                            ? TelMaskRegex(
                                                                  val.telefoneCelular,
                                                              )
                                                            : ""}
                                                    </td>
                                                    <td>
                                                        {val.cpfCnpj
                                                            ? CpfCnpjMaskRegex(
                                                                  val.cpfCnpj,
                                                              )
                                                            : ""}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        )}

                        {clientesData.length <= 0 && (
                            <ResultNotFound
                                style={{
                                    marginTop: 10,
                                    width: 853,
                                }}
                            />
                        )}

                        <div className={styles.reactPaginateModal}>
                            <ReactPaginate
                                breakLabel="..."
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={totalPages}
                                previousLabel="<"
                                nextLabel=">"
                                renderOnZeroPageCount={null}
                                marginPagesDisplayed={2}
                                containerClassName={styles.pagination}
                                pageClassName={styles.pageItem}
                                pageLinkClassName={"page-link"}
                                previousLinkClassName={styles.pageLink}
                                nextClassName={styles.pageItem}
                                nextLinkClassName={styles.pageLink}
                                activeClassName={"active"}
                                forcePage={currentPage - 1}
                                disabledClassName={styles.disabled}
                            />
                        </div>
                    </div>
                </div>

                {/* **************************************************************** */}

                <TabsPanel
                    tabsData={tabsData}
                    setLoadingModal={setLoadingModal}
                    idClient={idClient}
                />
            </Grid>
            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </ClientesProfissionaisProvider>
    );
}
