//Fix CSS
export const Item = {
    marginLeft: 1,
    width: "100%",
    minWidth: 130,
    marginTop: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    backgroundColor: "#ffffff",
    color: "#0064b4",
    "&.MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "17px",
    },
    "&.MuiFormHelperText-root  ": {
        fontSize: 10,
        alignSelf: "end",
        alignItems: "end",
        color: "red",
    },
    "&.MuiInputBase-input": {
        position: "relative",
        border: "1px solid #ced4da",
        fontSize: 16,
        width: "auto",
        padding: "10px 4px",
    },
    "&.MuiOutlinedInput-root": {},
};
