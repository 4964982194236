import { Box, TextField } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseURL } from "../../../api";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import MyDatePicker from "../../../components/datepicker";
import { MultiSelectDropdownCustom } from "../../../components/dropdown/multiSelectDropdown";
import HourPick from "../../../components/hora";
import { ModalConfirm, ModalLoading } from "../../../components/modal";
import { ListTable } from "../../../components/tables/profissionaisTreinamento/listTable";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { useUnidades } from "../../../hooks/useScriptRef";
import { Api } from "../../../providers";
import { FormatDate } from "../../../utils/date";
import styles from "../CriarTreinamento/criarTreinamento.module.scss";
import excel from "./../../../assets/images/excel.png";
import pdf from "./../../../assets/images/pdf.png";
const ENDPOINT = "/treinamento/";

const BoxData = ({ children }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                background: "#F7F7F7",
                borderRadius: "8px",
            }}
        >
            {children}
        </Box>
    );
};

const ItemCustomFieldDisabled = {
    width: "400px !important",
    height: "56px !important",
    borderRadius: "8px !important",
    "& .MuiInputBase-root": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
        height: "56px !important",
    },
    "& .MuiOutlinedInput-input": {
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "& .MuiInputBase-input": {
        width: "93.47%",
    },
};

let totalVacancy = 0;
const EditarTreinamentoPage = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const navigate = useNavigate();
    const [data, setData] = useState("");
    const [numeroVagas, setNumeroVagas] = useState("");
    const [inicio, setInicio] = useState("");
    const [termino, setTermino] = useState("");
    const [modalidade, setModalidade] = useState("");
    const [local, setLocal] = useState("");
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const [user, setUser] = useState("");
    const [idOperador, setIdOperador] = useState("");
    const [operador, setOperador] = useState("");
    const [unidadeID, setUnidadeID] = useState([]);
    const [statusButton, setStatusButton] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [vagueError, setVagueError] = useState(false);
    const [startTimeError, setStartTimeError] = useState(false);
    const [endTimeError, setEndTimeError] = useState(false);
    const [modalityError, setModalityError] = useState(false);
    const [localError, setLocalError] = useState(false);
    const [unitError, setUnitError] = useState(false);
    const [checkUpdate, setCheckUpdate] = useState(false);
    const [messageBackend, setMessageBackend] = useState("");
    const [startTimeText, setStartTimeText] = useState("");
    const [endTimeText, setEndTimeText] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [currentDateForm, setCurrentDateForm] = useState("");
    const [currentTime, setCurrentTime] = useState(0);
    const [serverUnit, setServerUnit] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);
    const [showRemove, setShowRemove] = useState(true);
    const [fimAval, setFimAval] = useState(false);
    const [fimTrein, setFimTrein] = useState(false);
    const [profTrein, setProfTrein] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);

    const unidadesMultiSelect = useUnidades({ multiSelect: true });
    const unidadesServer = useUnidades();
    const token = localStorage.getItem("token");

    // *************************************************************************
    const operadorStorage = JSON.parse(localStorage.getItem("user"));
    const permissoes = JSON.parse(localStorage.getItem("permissoes"));
    const checkOp =
        operadorStorage?.operador?.funcao?.slug == "administrador"
            ? true
            : false;
    const permTreinamentos = permissoes?.find(
        (val) => val.tela.slug == "treinamentos",
    );
    // *************************************************************************

    const ItemCustomDate = {
        width: "400px !important",
        height: "56px !important",
        borderRadius: "8px !important",
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
            border: "1px solid #C7C9D9 !important",
            borderColor: "#C7C9D9 !important",
            height: "56px !important",
            background: fimTrein ? "#ECEDEE" : "#fff",
        },
        "& .MuiOutlinedInput-input": {
            padding: "16.5px 14px",
            color: fimTrein ? "#8d8f90" : "#343B40",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19.36px",
            zIndex: 2,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            // borderRadius: "8px !important",
            // border: "1px solid #C7C9D9 !important",
            // borderColor: "#C7C9D9 !important",
            background: fimTrein ? "#ECEDEE" : "#fff",
            border: "none",
        },
        "& .MuiSvgIcon-root": {
            zIndex: 2,
            color: "#0064B4",
            display: "none",
        },
    };

    const ItemCustom = {
        width: "400px !important",
        height: "56px !important",
        borderRadius: "8px !important",
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
            border: "1px solid #C7C9D9 !important",
            borderColor: "#C7C9D9 !important",
            height: "56px !important",
            background: fimTrein ? "#ECEDEE" : "#fff",
        },
        "& .MuiOutlinedInput-input": {
            padding: "16.5px 14px",
            color: fimTrein ? "#8d8f90" : "#343B40",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19.36px",
            zIndex: 2,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            // borderRadius: "8px !important",
            // border: "1px solid #C7C9D9 !important",
            // borderColor: "#C7C9D9 !important",
            background: fimTrein ? "#ECEDEE" : "#fff",
            border: "none",
        },
        "& .MuiSvgIcon-root": {
            zIndex: 2,
            color: "#0064B4",
            display: fimTrein ? "none" : "block",
        },
    };

    const unidadeData = () => {
        let unidadesTemp = [];
        const _ = unidadesServer.filter((item) => {
            unidadesTemp.push(item.nome);
        });
        setServerUnit(unidadesTemp);
    };

    useEffect(() => {
        setCurrentScreen(8);
        unidadeData();
    }, [unidadesServer]);

    const dataAtual = (dataAtualiz, dataHoraFim) => {
        Api.get("/system/date")
            .then((res) => {
                if (res.status == 200) {
                    const checkTime = Number(res.data.hoursBr.replace(":", ""));
                    setCurrentTime(checkTime);
                    setCurrentDate(res.data.date);

                    // *********************************************************
                    const dataFimTrein = moment(dataHoraFim).format(
                        "YYYY-MM-DD HH:mm:ss",
                    );
                    const isValidFimDate = moment(dataHoraFim).isValid();

                    if (isValidFimDate) {
                        const maxDateAvailable = new Date(dataFimTrein);
                        const maxMonthDate = maxDateAvailable.getUTCMonth() + 1;
                        const maxCurrentMonth =
                            maxMonthDate <= 9
                                ? "0" + maxMonthDate
                                : maxMonthDate;
                        const maxCurrentDay =
                            maxDateAvailable.getUTCDate() <= 9
                                ? "0" + maxDateAvailable.getUTCDate()
                                : maxDateAvailable.getUTCDate();

                        const checkHours =
                            maxDateAvailable.getHours() < 10
                                ? "0" + maxDateAvailable.getHours()
                                : maxDateAvailable.getHours();
                        const hours =
                            maxDateAvailable.getHours() <= 0
                                ? "00"
                                : checkHours;
                        const minutes =
                            maxDateAvailable.getMinutes() <= 0
                                ? "00"
                                : maxDateAvailable.getMinutes();
                        const seconds =
                            maxDateAvailable.getSeconds() <= 0
                                ? "00"
                                : maxDateAvailable.getSeconds();

                        const maxCurrentDate =
                            maxDateAvailable.getUTCFullYear() +
                            "-" +
                            maxCurrentMonth +
                            "-" +
                            maxCurrentDay +
                            " " +
                            hours +
                            ":" +
                            minutes +
                            ":" +
                            seconds;

                        const dateServ = moment(
                            res.data.hoursDate,
                            "YYYY-MM-DD HH:mm:ss",
                        ).valueOf();

                        const dataLimiteEdit = moment(
                            maxCurrentDate,
                            "YYYY-MM-DD HH:mm:ss",
                        ).valueOf();

                        if (dateServ > dataLimiteEdit) {
                            setFimTrein(true);
                        }
                    }

                    // *********************************************************
                    const dataEditTrein = moment(dataAtualiz).format(
                        "YYYY-MM-DD HH:mm:ss",
                    );
                    const isValidEditDate = moment(dataAtualiz).isValid();

                    if (isValidEditDate) {
                        const maxDateAvailable = new Date(dataEditTrein);
                        maxDateAvailable.setUTCDate(
                            maxDateAvailable.getUTCDate() + 29,
                        );
                        const maxMonthDate = maxDateAvailable.getUTCMonth() + 1;
                        const maxCurrentMonth =
                            maxMonthDate <= 9
                                ? "0" + maxMonthDate
                                : maxMonthDate;
                        const maxCurrentDay =
                            maxDateAvailable.getUTCDate() <= 9
                                ? "0" + maxDateAvailable.getUTCDate()
                                : maxDateAvailable.getUTCDate();

                        const checkHours =
                            maxDateAvailable.getHours() < 10
                                ? "0" + maxDateAvailable.getHours()
                                : maxDateAvailable.getHours();
                        const hours =
                            maxDateAvailable.getHours() <= 0
                                ? "00"
                                : checkHours;
                        const minutes =
                            maxDateAvailable.getMinutes() <= 0
                                ? "00"
                                : maxDateAvailable.getMinutes();
                        const seconds =
                            maxDateAvailable.getSeconds() <= 0
                                ? "00"
                                : maxDateAvailable.getSeconds();

                        const maxCurrentDate =
                            maxDateAvailable.getUTCFullYear() +
                            "-" +
                            maxCurrentMonth +
                            "-" +
                            maxCurrentDay +
                            " " +
                            hours +
                            ":" +
                            minutes +
                            ":" +
                            seconds;

                        const dateServ = moment(
                            res.data.hoursDate,
                            "YYYY-MM-DD HH:mm:ss",
                        ).valueOf();

                        const dataLimiteEdit = moment(
                            maxCurrentDate,
                            "YYYY-MM-DD HH:mm:ss",
                        ).valueOf();

                        if (dateServ > dataLimiteEdit) {
                            setFimAval(true);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSelecionarTodasAsUnidades = (completar) => {
        let unidadesTemporarias = [];
        if (completar) {
            const _ = unidadesMultiSelect.filter((item) => {
                unidadesTemporarias.push(item.nome);
            });
        }
        setUnidadeID(unidadesTemporarias);
    };

    async function detalhesTrein(dataHoraFim) {
        try {
            const result = await Api.get(
                "/treinamento/" + idQuery + "/detalhes",
            );
            if (result.data.length > 0) {
                const dataTrein = result.data.filter(
                    (val) => val.dataAtualizacao != null,
                );
                if (dataTrein.length > 0) {
                    setShowRemove(false);
                }
                setProfTrein(true);
                dataAtual(result.data[0].dataAtualizacao, dataHoraFim);
            } else {
                dataAtual(null, dataHoraFim);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        let mounted = true;
        setLoadingModal(true);

        Api.get(`${ENDPOINT}${idQuery}`)
            .then((res) => {
                setData(res.data.dataHoraInicio);
                setLocal(res.data.local);
                setNumeroVagas(res.data.vagas);
                totalVacancy = res.data.vagas;
                setInicio(new Date(res.data.dataHoraInicio));
                setTermino(new Date(res.data.dataHoraFim));
                setModalidade(res.data.modalidade);
                setIdOperador(res.data.operador?.id);
                setOperador(res.data.operador?.nome);
                let unidadesSelecionadasTemporario = [];
                res.data.unidades.filter((item) => {
                    unidadesSelecionadasTemporario.push(item.nome);
                });
                setUnidadeID(unidadesSelecionadasTemporario);
                setLoadingModal(false);

                detalhesTrein(res.data.dataHoraFim);
            })
            .catch((error) => {
                const checkErro = error;
                let mensagenErro = "";
                if (checkErro.response.data.status == 500) {
                    mensagenErro = checkErro.response.data.error;
                } else {
                    mensagenErro = checkErro.response.data.message?.error;
                }
                toast.error(`Ops! ${mensagenErro}`, {
                    toastId: "msg",
                });
                setLoadingModal(false);
            });

        setTimeout(() => {
            setCheckUpdate(true);
        }, 3000);

        return () => {
            mounted = false;
        };
    }, [user.id]);

    const handleSubmit = () => {
        if (statusButton) {
            return;
        }

        let unidadeSelecionadas = [];
        unidadeID.filter((item) => {
            return unidadesServer.filter((uOff) => {
                if (uOff.nome === item && uOff.id !== 0)
                    unidadeSelecionadas.push(uOff);
            });
        });

        const myData = {
            local: local,
            dataHoraInicio:
                FormatDate(data) +
                " " +
                String(inicio.getHours()).padStart(2, "0") +
                ":" +
                String(inicio.getMinutes()).padStart(2, "0") +
                ":00",
            dataHoraFim:
                FormatDate(data) +
                " " +
                String(termino.getHours()).padStart(2, "0") +
                ":" +
                String(termino.getMinutes()).padStart(2, "0") +
                ":00",
            vagas: totalVacancy,
            modalidade: modalidade,
            operadorId: idOperador,
            unidades: unidadeSelecionadas,
        };
        setLoadingModal(true);

        Api.put("/treinamento/" + idQuery, myData)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Treinamento atualizado com sucesso", {
                        toastId: "success",
                    });
                    setLoadingModal(false);
                }
            })
            .catch((error) => {
                setLoadingModal(false);
                const checkErro = error;
                let mensagenErro = "";
                if (checkErro.response.data.status == 500) {
                    mensagenErro = checkErro.response.data.error;
                } else {
                    mensagenErro = checkErro.response.data.message?.error;
                }
                toast.error(`Ops! ${mensagenErro}`, {
                    toastId: "msg",
                });
                setMessageBackend(mensagenErro);
            });
    };

    const handleAvaliarTurma = () => {
        navigate("/treinamento/turma?id=" + idQuery);
    };

    const handleExpluir = () => {
        setOpenModalConfirm(false);
        setLoadingModal(true);
        Api.delete(`${ENDPOINT}${idQuery}`)
            .then((res) => {
                toast.success("Treinamento excluído com sucesso!", {
                    toastId: "success",
                });
                setLoadingModal(false);
                navigate("/treinamento/");
            })
            .catch((error) => {
                setLoadingModal(false);
                const checkErro = error;
                let mensagenErro = "";
                if (checkErro.response.data.status == 500) {
                    mensagenErro = checkErro.response.data.error;
                } else {
                    mensagenErro = checkErro.response.data.message?.error;
                }
                toast.error(`Ops! ${mensagenErro}`, {
                    toastId: "msg",
                });
                setMessageBackend(mensagenErro);
            });
    };

    const checkFields = () => {
        let statusBtn1 = true;
        let statusBtn2 = true;
        let statusBtn3 = true;
        let statusBtn4 = true;
        let statusBtn5 = true;
        let statusBtn6 = true;
        let statusBtn7 = true;
        let statusBtn8 = true;

        if (unidadeID.length <= 0) {
            setUnitError(true);
        } else {
            setUnitError(false);
            statusBtn1 = false;
        }

        if (!data) {
            setDateError(true);
        } else {
            setDateError(false);
            statusBtn2 = false;
        }

        if (numeroVagas <= 0) {
            setVagueError(true);
        } else {
            setVagueError(false);
            statusBtn3 = false;
        }

        if (!inicio) {
            setStartTimeError(true);
        } else {
            setStartTimeError(false);
            statusBtn4 = false;
        }

        if (!termino) {
            setEndTimeError(true);
        } else {
            setEndTimeError(false);
            statusBtn5 = false;
        }

        if (!modalidade) {
            setModalityError(true);
        } else {
            setModalityError(false);
            statusBtn6 = false;
        }

        if (!local || !local.trim()) {
            setLocalError(true);
        } else {
            setLocalError(false);
            statusBtn7 = false;
        }

        if (data && inicio && termino) {
            const dateForm = moment(data, "YYYY-MM-DD").valueOf();
            const dateCurrent = moment(currentDate, "YYYY-MM-DD").valueOf();
            if (dateForm === dateCurrent) {
                const checkEndTime = Number(moment(termino).format("HHmm"));
                if (currentTime >= checkEndTime) {
                    setMessageBackend(
                        "O horário de término não pode ser igual ou anterior ao atual, pois a data é referente a data atual!",
                    );
                } else {
                    statusBtn8 = false;
                    setMessageBackend("");
                }
            } else {
                statusBtn8 = false;
                setMessageBackend("");
            }
        } else {
            statusBtn8 = false;
            setMessageBackend("");
        }

        let btnStatus = true;
        if (
            !statusBtn1 &&
            !statusBtn2 &&
            !statusBtn3 &&
            !statusBtn4 &&
            !statusBtn5 &&
            !statusBtn6 &&
            !statusBtn7 &&
            !statusBtn8
        ) {
            btnStatus = false;
        }

        setStatusButton(btnStatus);
    };

    const handleStartTime = (data) => {
        const result = moment(data, "LT").isValid();
        const checkDate1 = Number(moment(data).format("HHmm"));
        const checkDate2 = Number(moment(termino).format("HHmm"));
        let checkTime = true;

        if (termino !== "" && checkDate1 >= checkDate2) {
            setStartTimeText(
                "Horário de início precisa ser menor que o de término",
            );
            setEndTimeText(
                "Horário de término precisa ser maior que o de início",
            );
            checkTime = false;
        } else {
            setStartTimeText("");
            setEndTimeText("");
        }

        if (result) {
            if (checkTime) {
                setInicio(data);
                setStartTimeError(false);
                checkFields();
            } else {
                setInicio("");
                setStartTimeError(true);
            }
        } else {
            setInicio("");
            setStartTimeError(true);
        }
    };

    const handleEndTime = (data) => {
        const result = moment(data, "LT").isValid();
        const checkDate1 = Number(moment(data).format("HHmm"));
        const checkDate2 = Number(moment(inicio).format("HHmm"));
        let checkTime = true;

        if (inicio !== "" && checkDate1 <= checkDate2) {
            setStartTimeText(
                "Horário de início precisa ser menor que o de término",
            );
            setEndTimeText(
                "Horário de término precisa ser maior que o de início",
            );
            checkTime = false;
        } else {
            setStartTimeText("");
            setEndTimeText("");
        }

        if (result) {
            if (checkTime) {
                setTermino(data);
                setEndTimeError(false);
                checkFields();
            } else {
                setTermino("");
                setEndTimeError(true);
            }
        } else {
            setTermino("");
            setEndTimeError(true);
        }
    };

    const handleData = (value) => {
        const result = moment(value, "YYYY-MM-DD", true).isValid();
        const dateForm = moment(value, "YYYY-MM-DD").valueOf();
        const dateCurrent = moment(currentDate, "YYYY-MM-DD").valueOf();

        if (result) {
            if (dateForm >= dateCurrent) {
                setData(value);
                setDateError(false);
                setCurrentDateForm("");
                checkFields();
            } else {
                setDateError(true);
                setCurrentDateForm(
                    "A data não pode ser anterior a data atual!",
                );
                checkFields();
            }
        } else {
            setData("");
            setDateError(true);
        }
    };

    const handleOnChangeForm = (option) => {
        switch (option.target.name) {
            case "numberVacancies":
                totalVacancy =
                    Number(option.target.value) <= 1000
                        ? Number(option.target.value)
                        : totalVacancy;
                if (typeof totalVacancy === "number") {
                    setNumeroVagas(totalVacancy);
                    setVagueError(false);
                } else {
                    setNumeroVagas("");
                    setVagueError(false);
                }
                break;
            case "modality":
                setModalidade(option.target.value);
                setModalityError(false);
                break;
            case "local":
                setLocal(option.target.value);
                setLocalError(false);
                break;
            default:
                break;
        }
    };

    const requiredField = "Campo obrigatório";

    useEffect(() => {
        if (checkUpdate) {
            checkFields();
        }
    }, [unidadeID]);

    const handleReport = (type) => {
        const reportTab = `${BaseURL}api/relatorio/profissional-treinamento?export=${type}&treinamentoId=${idQuery}&token=${token}`;
        window.open(reportTab);
    };

    return (
        <>
            <Container fluid className="containerForm justify-content-center">
                <BreadCrumb
                    data={{
                        page: "Visualizar treinamento",
                        menu: {
                            item: "Treinamentos",
                        },
                    }}
                />
                <Title title="Visualizar treinamento" />
                <BoxData>
                    {fimTrein ? (
                        <div className={styles.dadosTrein}>
                            Dados do treinamento
                        </div>
                    ) : (
                        <div className={styles.marginTopData} />
                    )}

                    {/* {messageBackend !== "" && (
                        <span className={styles.messageBackend}>
                            Ops: {messageBackend}
                        </span>
                    )} */}
                    <div className={styles.containerInputs}>
                        <div className={styles.linha1}>
                            <div
                                className={`${
                                    fimTrein
                                        ? styles.containerDatePickerDisabled
                                        : styles.containerDatePicker2
                                }`}
                            >
                                <span>Data</span>
                                <MyDatePicker
                                    fullWidth
                                    sx={ItemCustomDate}
                                    value={data}
                                    onChange={handleData}
                                    onBlur={checkFields}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={fimTrein}
                                    placeholder="Número de vagas"
                                    className="appTeste"
                                />
                                {dateError && (
                                    <span className={styles.requiredField}>
                                        {currentDateForm !== ""
                                            ? currentDateForm
                                            : requiredField}
                                    </span>
                                )}
                            </div>

                            <div
                                className={`${styles.containerTextField2} ${
                                    fimTrein ? styles.inputDisabled : {}
                                }`}
                            >
                                <span>Número de vagas</span>
                                <input
                                    disabled={fimTrein}
                                    value={numeroVagas}
                                    fullWidth
                                    onChange={handleOnChangeForm}
                                    placeholder="Número de vagas"
                                    name="numberVacancies"
                                    variant="outlined"
                                    onBlur={checkFields}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {vagueError && (
                                    <span
                                        className={`${styles.requiredField} ${styles.marginTopRequired}`}
                                    >
                                        {requiredField}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className={styles.linha2}>
                            <div
                                className={`${styles.containerDatePicker} ${styles.dividerSpace}`}
                            >
                                <span>Horário de início</span>
                                <HourPick
                                    name="startTime"
                                    value={inicio}
                                    onChange={handleStartTime}
                                    onBlur={checkFields}
                                    title={"Horário de início"}
                                    sx={ItemCustom}
                                    disabled={fimTrein}
                                />
                                {startTimeError && (
                                    <span className={styles.requiredField}>
                                        {startTimeText !== ""
                                            ? startTimeText
                                            : requiredField}
                                    </span>
                                )}
                            </div>

                            <div
                                className={`${styles.containerDatePicker} ${styles.dividerSpace}`}
                            >
                                <span>Horário de término</span>
                                <HourPick
                                    name="endTime"
                                    value={termino}
                                    onChange={handleEndTime}
                                    onBlur={checkFields}
                                    title={"Horário de término"}
                                    sx={ItemCustom}
                                    disabled={fimTrein}
                                />
                                {endTimeError && (
                                    <span className={styles.requiredField}>
                                        {endTimeText !== ""
                                            ? endTimeText
                                            : requiredField}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className={styles.linha3}>
                            <div
                                className={`${styles.containerFieldSelect} ${
                                    styles.dividerSpace
                                } ${
                                    fimTrein
                                        ? styles.colorDisableSelect
                                        : styles.colorActiveSelect
                                }`}
                            >
                                <span>Modalidade</span>

                                <select
                                    className={`form-select ${
                                        styles.formDropdown
                                    } ${
                                        fimTrein
                                            ? styles.formDropdownDisabled
                                            : {}
                                    }`}
                                    value={modalidade}
                                    onChange={handleOnChangeForm}
                                    onBlur={checkFields}
                                    name="modality"
                                    disabled={fimTrein}
                                >
                                    <option value="Online" key="1">
                                        Online
                                    </option>
                                    <option value="Presencial" key="2">
                                        Presencial
                                    </option>
                                </select>
                                {modalityError && (
                                    <span className={styles.requiredField}>
                                        {requiredField}
                                    </span>
                                )}
                            </div>

                            <div
                                className={`${styles.containerTextField2} ${
                                    styles.dividerSpace
                                } ${fimTrein ? styles.inputDisabled : {}}`}
                            >
                                <span>Local do treinamento</span>
                                <input
                                    disabled={fimTrein}
                                    value={local}
                                    fullWidth
                                    placeholder="Local"
                                    name="local"
                                    onChange={handleOnChangeForm}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={checkFields}
                                />
                                {localError && (
                                    <span
                                        className={`${styles.requiredField} ${styles.marginTopRequired}`}
                                    >
                                        {requiredField}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className={styles.linha4}>
                            <div
                                className={`${styles.containerFieldSelect} ${styles.dividerSpace}`}
                            >
                                <span>Aplicar unidade(s)</span>
                                <MultiSelectDropdownCustom
                                    id="tipo-servico"
                                    name="tipo-servico"
                                    value={unidadeID}
                                    totalUnit={serverUnit.length}
                                    placeholder="Selecione unidade"
                                    addIcon={"screen-custom"}
                                    disabled={fimTrein}
                                    onChange={(e) => {
                                        if (
                                            e[e.length - 1] ===
                                            "Todas as unidades"
                                        ) {
                                            const statusAll =
                                                unidadeID.length <=
                                                serverUnit.length
                                                    ? true
                                                    : false;
                                            handleSelecionarTodasAsUnidades(
                                                statusAll,
                                            );
                                        } else {
                                            if (e.includes("Todas unidades")) {
                                                const allUnits = serverUnit;
                                                const result = allUnits.filter(
                                                    (val) =>
                                                        val !== e[e.length - 1],
                                                );
                                                setUnidadeID(result);
                                            } else {
                                                setUnidadeID(e);
                                            }
                                        }
                                    }}
                                >
                                    {unidadesMultiSelect}
                                </MultiSelectDropdownCustom>
                                {unitError && (
                                    <span className={styles.requiredField}>
                                        {requiredField}
                                    </span>
                                )}
                            </div>

                            <div
                                className={`${styles.containerTextFieldDisabled} ${styles.dividerSpace} ${styles.colorFieldBlock}`}
                            >
                                <div>
                                    <span>Operador responsável</span>
                                    <TextField
                                        value={operador}
                                        fullWidth
                                        sx={ItemCustomFieldDisabled}
                                        name="local"
                                        variant="outlined"
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onBlur={checkFields}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* {!fimTrein ? ( */}
                    <div className={styles.containerBtn}>
                        <div className={styles.containerButtonEditDadosP}>
                            {showRemove &&
                                (checkOp || permTreinamentos?.excluir) && (
                                    <div
                                        onClick={() =>
                                            setOpenModalConfirm(true)
                                        }
                                    >
                                        Excluir treinamento
                                    </div>
                                )}

                            {!fimTrein && (
                                <button
                                    className={`${
                                        statusButton
                                            ? styles.buttonDisabled
                                            : styles.buttonSave
                                    }`}
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={statusButton}
                                >
                                    <span>Salvar</span>
                                </button>
                            )}
                        </div>
                    </div>
                    {/* ) : (
                        <div className={styles.containerGeralDadosP} />
                    )} */}
                </BoxData>
            </Container>

            <Container fluid className="containerForm justify-content-center">
                <BoxData>
                    <div
                        style={{
                            paddingLeft: 45,
                            paddingRight: 45,
                            paddingTop: 45,
                            marginTop: 32,
                        }}
                    >
                        <div className={styles.containerRel}>
                            <div>Lista dos Candidatos</div>
                            <div>
                                <span onClick={() => handleReport("excel")}>
                                    <img
                                        src={excel}
                                        alt="excel"
                                        title="excel"
                                    />
                                </span>
                                <span onClick={() => handleReport("pdf")}>
                                    <img src={pdf} alt="pdf" title="pdf" />
                                </span>
                            </div>
                        </div>
                        <ListTable />
                    </div>

                    <div className={styles.containerBtnMarginAvaliar}>
                        <div className={styles.containerBtnAvaliar}>
                            <button
                                className={
                                    profTrein
                                        ? styles.buttonSaveAvaliar
                                        : styles.buttonDisabledAvaliar
                                }
                                type="button"
                                onClick={handleAvaliarTurma}
                                disabled={!profTrein}
                            >
                                <span>{`${
                                    fimAval
                                        ? "Ver avaliações"
                                        : "Avaliar candidatos"
                                }`}</span>
                            </button>
                        </div>
                    </div>
                </BoxData>
            </Container>

            <ModalConfirm
                labelXSair="nao"
                title="Excluir treinamento"
                description="Tem certeza que deseja excluir este treinamento?"
                openModal={openModalConfirm}
                setOpenModal={setOpenModalConfirm}
                onClick={handleExpluir}
                labelConfirm="Excluir"
            />

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};

export default EditarTreinamentoPage;
