import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "../../../components/breadcrumb";
import Title from "../../../components/title";
import { OccurrencesProvider } from "../../../contexts/service";
import OcorrenciasTable from "../../../components/tables/ocorrencias";
import { AuthContext } from "../../../contexts/auth";
import Search from "./search";

const DashboardOcorrencias = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    useEffect(() => {
        setCurrentScreen(9);
    }, []);

    return (
        <OccurrencesProvider>
            <Container fluid className="justify-content-center">
                <Breadcrumb
                    data={{
                        category: "Ocorrências",
                        page: "Ocorrências",
                    }}
                />
                <Title title="Ocorrências" />
                <Container className="listagem">
                    <Search />
                    <OcorrenciasTable />
                </Container>
            </Container>
        </OccurrencesProvider>
    );
};

export default DashboardOcorrencias;
