import moment from "moment";
import qs from "qs";
import React, { memo, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import MyDatePicker from "../../../components/datepicker";
import { ServiceContext } from "../../../contexts/service";
import { useUnidades } from "../../../hooks/useScriptRef";
import { Api } from "../../../providers";
import { SearchField } from "./components/searchField";
import styles from "./styles.module.scss";

const Search = () => {
    const {
        setRequests,
        setLoading,
        setTotalPages,
        setTotalElements,
        setParamsFilter,
        setTotalUnits,
    } = useContext(ServiceContext);
    const unidadesServer = useUnidades();
    const [busca, setBusca] = useState("");

    const [unidadeID, setUnidadeID] = useState("");
    const [dataHoraInicio, setDataHoraInicio] = useState(null);
    const [dataHoraFim, setDataHoraFim] = useState(null);
    const [operadorId, setOperadorId] = useState("");
    const [operadores, setOperadores] = useState([]);

    useEffect(() => {
        let mounted = true;

        async function filterData() {
            try {
                await Promise.all([
                    Api.get("/operador/").then((res) => {
                        if (mounted) {
                            setOperadores(res.data);
                        }
                    }),
                ]);
            } catch (_) {
                toast.error("Ops! Aconteceu algum problema com os filtros!", {
                    toastId: "msg",
                });
            }
        }

        filterData();
        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (unidadesServer.length) {
            setTotalUnits(unidadesServer.length);
        }
    }, [unidadesServer]);

    const handleSumbit = (event) => {
        setLoading(true);
        event.preventDefault();

        let params = {};
        if (busca !== "") params.fields = busca;
        unidadeID !== "0"
            ? (params.unidadeId = unidadeID)
            : (params.unidadeId = "Todas");
        operadorId > 0
            ? (params.operadorId = operadorId)
            : (params.operadorId = "");
        if (dataHoraInicio !== null)
            params.dataHoraInicio = moment(dataHoraInicio, "YYYY-MM-DD").format(
                "YYYY-MM-DD",
            );
        if (dataHoraFim !== null)
            params.dataHoraFim = moment(dataHoraFim, "YYYY-MM-DD").format(
                "YYYY-MM-DD",
            );

        Api.get(`/treinamento/pagination/0/10?`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Provider
                setRequests(res.data.content);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setParamsFilter(params);
                setLoading(false);
            })
            .catch((_) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    };

    return (
        <div className={`conteudo-tabela`}>
            <form>
                <div>
                    <div>
                        <SearchField
                            placeholder="Pesquisar"
                            onClick={handleSumbit}
                            onChange={(e) => searchText(e.target.value)}
                            search={busca}
                        />
                    </div>

                    <div className={styles.containerFilds}>
                        <div className={styles.subContainerFilds}>
                            <span>Filtrar resultados</span>
                            <div className={styles.containerSelect}>
                                <div className={styles.fieldSelect}>
                                    <select
                                        className="form-select"
                                        value={operadorId}
                                        onChange={(e) =>
                                            setOperadorId(e.target.value)
                                        }
                                    >
                                        <option value="" key="0" disabled>
                                            Operador responsável
                                        </option>
                                        <option value="0" key="999">
                                            Todos
                                        </option>
                                        {operadores.map(({ id, nome }, key) => {
                                            return (
                                                <option
                                                    value={id}
                                                    key={key + 1}
                                                >
                                                    {nome}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className={styles.fieldSelect}>
                                    <div className={styles.inputData}>
                                        <MyDatePicker
                                            value={dataHoraInicio}
                                            title="Data inicial"
                                            onChange={(e) =>
                                                setDataHoraInicio(e)
                                            }
                                        />
                                    </div>
                                    <div className={styles.inputData}>
                                        <MyDatePicker
                                            value={dataHoraFim}
                                            title="Data final"
                                            onChange={(e) => setDataHoraFim(e)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldSelect}>
                                    <select
                                        className="form-select"
                                        value={unidadeID}
                                        onChange={(e) =>
                                            setUnidadeID(e.target.value)
                                        }
                                    >
                                        <option value="" key="0" disabled>
                                            Unidade
                                        </option>
                                        <option value="0" key="999">
                                            Todas
                                        </option>
                                        {unidadesServer.map(({ id, nome }) => {
                                            return (
                                                <option value={id} key={id}>
                                                    {nome}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className={styles.fieldSelect2} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default memo(Search);
