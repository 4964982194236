import { Container } from "@mui/material";
import { PDFExport } from "@progress/kendo-react-pdf";
import React, { useRef, useState } from "react";
import { ConvertCurrencyNotSign } from "../../../../utils/currency";
import { DateBRPorExtenso } from "../../../../utils/date";
import { rgmask } from "../../../../utils/mask";
import { ModalCustom, ModalLoading } from "../../../modal";
import styles from "./styles.module.scss";

//Componente responsavel por mostrar o modal e gerar o PDF de recibo.

export default function DownloadModalPDF({
    solicitacao,
    profissional,
    openModalVerRecibo,
    setOpenModalVerRecibo,
}) {
    const [checkPDF, setCheckPDF] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const pdfExportComponent = useRef(null);

    const handleDownloadPdf = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
            setOpenModalVerRecibo(false);
            setCheckPDF(false);
            setLoadingModal(false);
        }
    };

    const callPDF = () => {
        setLoadingModal(true);
        setCheckPDF(true);
        setTimeout(() => {
            handleDownloadPdf();
        }, 1500);
    };

    const layoutSelection = {
        text: "A4",
        value: "size-a4",
    };

    return (
        <>
            <ModalCustom
                styles={{
                    minHeight: "578px",
                    width: "520px",
                }}
                title="Recibo"
                description=""
                openModal={openModalVerRecibo}
                setOpenModal={setOpenModalVerRecibo}
                onClick={callPDF}
                color="blue"
                labelConfirm="Baixar recibo"
                labelCancel="Fechar"
            >
                <Container className={`${styles.recipeContainer}`}>
                    <PDFExport ref={pdfExportComponent} fileName="recibo">
                        <div
                            className={`${styles.pdfPage} ${
                                checkPDF ? {} : styles.containerModal
                            } ${layoutSelection.value}`}
                        >
                            <div className={styles.titleRecipe}>Cliente</div>
                            <div className={styles.subTitleRecipe}>Nome</div>
                            <div className={styles.subInfoRecipe}>
                                {solicitacao?.cliente?.nome}
                            </div>
                            <div className={styles.subTitleRecipe}>
                                Endereço
                            </div>
                            <div className={styles.subInfoRecipe}>
                                {solicitacao?.cliente?.enderecos[0]?.logradouro}
                            </div>
                            <hr />
                            <div className={styles.titleRecipe}>
                                Profissional
                            </div>
                            <div className={styles.subTitleRecipe}>Nome</div>
                            <div className={styles.subInfoRecipe}>
                                {profissional[0]?.nome}
                            </div>
                            <div className={styles.subTitleRecipe}>RG</div>
                            <div className={styles.subInfoRecipe}>
                                {profissional[0]?.rg?.length > 0
                                    ? rgmask(profissional[0]?.rg)
                                    : ""}
                            </div>
                            <Container className={styles.recipeInnerContainer}>
                                <div className={styles.titleRecipe}>
                                    Serviço
                                </div>
                                <div className={styles.subTitleRecipe}>
                                    Serviço
                                </div>
                                <div className={styles.subInfoRecipe}>
                                    {solicitacao?.servico?.servico?.nome}
                                </div>
                                <div className={styles.subTitleRecipe}>
                                    Tipo do serviço
                                </div>
                                <div className={styles.subInfoRecipe}>
                                    {}
                                    {solicitacao?.servicosTipos &&
                                    solicitacao?.servicosTipos?.length != 0
                                        ? solicitacao?.servicosTipos[0]?.nome
                                        : "Outros"}
                                </div>
                                <div className={styles.subTitleRecipe}>
                                    Local
                                </div>
                                <div className={styles.subInfoRecipe}>
                                    {solicitacao?.endereco?.logradouro}
                                </div>
                                <div className={styles.subTitleRecipe}>
                                    Data e hora
                                </div>
                                <div className={styles.subInfoRecipe}>
                                    {solicitacao?.datas
                                        ? DateBRPorExtenso(
                                              solicitacao?.datas[0],
                                          )
                                        : ""}
                                    ,{" "}
                                    {solicitacao?.hora
                                        ? solicitacao?.hora.slice(0, -3)
                                        : ""}
                                </div>
                                <div className={styles.subTitleRecipe}>
                                    Valor
                                </div>
                                <div className={styles.subInfoRecipe}>
                                    R${" "}
                                    {solicitacao?.valor > 0
                                        ? ConvertCurrencyNotSign(
                                              solicitacao.valor,
                                          )
                                        : "0,00"}
                                </div>
                            </Container>
                        </div>
                    </PDFExport>
                </Container>
            </ModalCustom>

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
}
