import React from "react";
import { DateBR, TimeBR } from "../../../utils/date";
import btnView from "./../../../assets/images/eye.png";
import styles from "./servico.module.scss";

export function ListTable({ currentItems }) {
    return (
        <div className={`table-responsive ${styles.radiusTable}`}>
            <table className={`table align-middle ${styles.table}`}>
                <thead>
                    <tr>
                        <th>Tela de ação</th>
                        <th>Tipo de usuário</th>
                        <th>Nome do usuário</th>
                        <th>Unidade</th>
                        <th>Data</th>
                        <th>Hora</th>
                        <th>Ação</th>
                        <th>Visual.</th>
                    </tr>
                </thead>
                <tbody data-test="table-body" className={styles.tbody}>
                    {currentItems.length > 0 ? (
                        currentItems.map((val) => {
                            return (
                                <tr key={val?.id.toString()}>
                                    <td className={styles.infoText}>
                                        {val?.tela?.nome}
                                    </td>
                                    <td className={styles.infoText}>
                                        {val?.usuario?.perfis[0]?.nome}
                                    </td>
                                    <td className={styles.infoText}>
                                        {val?.usuario?.nome}
                                    </td>
                                    <td className={styles.infoTextDesc}>
                                        {val?.unidade?.nome}
                                    </td>
                                    <td className={styles.infoTextDesc}>
                                        {DateBR(val?.dataCadastro)}
                                    </td>
                                    <td className={styles.infoTextDesc}>
                                        {TimeBR(val?.dataCadastro)}
                                    </td>
                                    <td className={styles.infoText}>
                                        {val?.acao}
                                    </td>
                                    <td>
                                        <a
                                            href={`/auditoria/view?id=${val.id}`}
                                        >
                                            <img src={btnView} alt="Editar" />
                                        </a>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
        </div>
    );
}
