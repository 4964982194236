import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseURL } from "../../../api";
import imageTrash from "../../../assets/images/icons/trash3.png";
import { BreadCrumb } from "../../../components/breadcrumb";
import { ModalConfirm, ModalLoading } from "../../../components/modal";
import Title from "../../../components/title";
import { AuthContext } from "../../../contexts/auth";
import { Api } from "../../../providers";
import styles from "./styles.module.scss";

const Input = styled("input")({
    display: "none",
});

const EditarCategoriaPage = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const navigate = useNavigate();
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");

    const [nome, setNome] = useState("");
    const [descricao, setDescricao] = useState("");
    const [ativo, setAtivo] = useState(true);
    const [imageLocal, setImageLocal] = useState("");
    const [image, setImage] = useState("");
    const [novaImagem, setNovaImagem] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp =
        operador.operador.funcao.slug === "administrador" ? true : false;

    useEffect(() => {
        setCurrentScreen(5);
    }, []);

    useEffect(() => {
        Api.get("/categoria/" + idQuery).then((res) => {
            if (res.status === 200) {
                setNome(res.data.nome);
                setDescricao(res.data.descricao);
                setAtivo(!!res.data.flagAtivo);

                if (res.data.caminho != null) {
                    const caminhoImagem = `${BaseURL}api/categoria/fotos/${res.data.caminho}`;
                    setImageLocal(caminhoImagem);
                }
            }
        });
    }, [idQuery]);

    const handleSubmit = () => {
        if (!checkOp) {
            return;
        }

        const categoria = {
            nome: nome,
            slug: "",
            descricao: descricao,
            flagAtivo: ativo,
        };
        setLoading(true);
        setConfirmModal(false);

        const formData = new FormData();
        if (novaImagem) {
            formData.append("file", image);
        }
        formData.append("categoria", JSON.stringify(categoria));

        Api.put("/categoria/" + idQuery, formData)
            .then((res) => {
                toast.success("Atualizado com sucesso", {
                    toastId: "success",
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                const checkErro = err;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });

        navigate("/categoria");
    };

    const handleFilesChange = (event) => {
        const checkData = ["image/png", "image/jpg", "image/jpeg"];
        const checkData2 = ["png", "jpg", "jpeg"];
        const checkType = event?.target?.files[0].name.split(".").pop();
        if (
            checkData.includes(event?.target?.files[0]?.type) &&
            checkData2.includes(checkType)
        ) {
            setImageLocal(URL.createObjectURL(event.target.files[0]));
            setImage(event.target.files[0]);
            setNovaImagem(true);
        }
    };

    const handleClearFiles = () => {
        document.getElementById("contained-button-file").value = "";
        setImageLocal("");
        setImage("");
        setNovaImagem(false);
    };

    const checkNome = nome.trim() && nome.length > 0 ? true : false;
    const checkDesc = descricao.trim() && descricao.length > 0 ? true : false;
    const checkImage = imageLocal != "" && imageLocal.length > 0 ? true : false;
    const checkButtonSubmit =
        checkNome && checkDesc && checkImage ? true : false;

    return (
        <>
            <Container maxWidth="xl">
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Editar categoria",
                        menu: {
                            item: "Categorias",
                            link: "/categoria",
                        },
                    }}
                />
                <Title title="Editar categoria" />
                <div className={styles.containerGeral}>
                    <div className={styles.subContainerGeral}>
                        <form className={`${styles.formularioItem}`}>
                            <Form.Group>
                                <span className={styles.labelText}>
                                    Nome da categoria
                                </span>
                                <Form.Control
                                    className={styles.containerInputFull}
                                    type="text"
                                    placeholder="Digite aqui o nome da categoria"
                                    name="nome"
                                    id="nome"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    maxLength={100}
                                    size="lg"
                                />
                            </Form.Group>

                            <div className={styles.marginLabelText}>
                                <span className={styles.labelText}>
                                    Descrição
                                </span>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Descreva a categoria"
                                    rows={3}
                                    name="descricao"
                                    id="descricao"
                                    value={descricao}
                                    maxLength={250}
                                    onChange={(e) =>
                                        setDescricao(e.target.value)
                                    }
                                    className={styles.textAreaComment}
                                />
                                <div className={styles.containerCaractere}>
                                    <span>{descricao.length}</span>
                                    <span>/250 caracteres</span>
                                </div>
                            </div>
                        </form>
                        <div className={styles.containerBtnUpload}>
                            <Input
                                accept="image/png, image/jpg, image/jpeg"
                                id="contained-button-file"
                                type="file"
                                onChange={(e) => {
                                    handleFilesChange(e);
                                }}
                            />
                            {imageLocal ? (
                                <>
                                    <div className={styles.imageSelected}>
                                        <img src={imageLocal} />
                                    </div>
                                    {checkOp && (
                                        <div
                                            className={styles.imageTrash}
                                            onClick={handleClearFiles}
                                        >
                                            <img src={imageTrash} />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {checkOp && (
                                        <label htmlFor="contained-button-file">
                                            <span
                                                className={styles.textBtnUpload}
                                            >
                                                Adicionar imagem
                                            </span>
                                        </label>
                                    )}
                                </>
                            )}
                        </div>
                        <div className={styles.containerBtn}>
                            {checkOp && (
                                <>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: 16,
                                                fontWeight: 600,
                                                color: "#0064B4",
                                                marginBottom: 2,
                                            }}
                                        >
                                            Status
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                width: 200,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontSize: 14,
                                                    fontWeight: 600,
                                                    color: "#343B40",
                                                }}
                                            >
                                                Ativo
                                            </Typography>
                                            <Box sx={{ marginLeft: 2 }}>
                                                <input
                                                    type="checkbox"
                                                    checked={ativo}
                                                    onChange={(e) =>
                                                        setAtivo(!ativo)
                                                    }
                                                    id={styles.checkbox}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <div
                                        onClick={() =>
                                            checkButtonSubmit
                                                ? setConfirmModal(true)
                                                : {}
                                        }
                                    >
                                        <button
                                            className={
                                                !checkButtonSubmit
                                                    ? styles.buttonSearchBlock
                                                    : styles.buttonSave
                                            }
                                            disabled={!checkButtonSubmit}
                                            type="button"
                                        >
                                            <span>Salvar</span>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
            <ModalConfirm
                title="Salvar edição"
                description="Tem certeza que deseja salvar as alterações feitas?"
                openModal={confirmModal}
                setOpenModal={setConfirmModal}
                onClick={() => handleSubmit()}
                labelConfirm="Salvar"
                labelCancel="Cancelar"
                color="blue"
                labelXSair="nao"
            />
            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loading}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};

export default EditarCategoriaPage;
