import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
// Largura das Células (column)
const CELLWIDTH = "20%";
const CELLHEIGHT = 20;

const styles = StyleSheet.create({
    tableContainer: {
        width: "100%",
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    header: {
        fontWeight: 600,
        fontSize: 10,
        color: "#343B40",
        backgroundColor: "#D9D9D9",
    },
    cellWrapper: {
        width: "100%",
        height: "100%",
        border: "0.5 solid #ECEDEE",
    },
    cellStyle: {
        padding: 0.5,
        fontSize: 9,
        width: "100%",
        maxWidth: CELLWIDTH,
        minHeight: CELLHEIGHT,
        // fontWeight: 400,
        textAlign: "center",
        alignItems: "center",
        whiteSpace: "wrap",
    },
});

// Rows
const TableRow = ({ data, lineByPage }) => {
    const rows = data.rows.map((item, index) => {
        return (
            // Row
            <View
                style={styles.row}
                key={index}
                break={(index + 1) % lineByPage === 0} //Quebra de linha !important
            >
                {data.columns.map((col, index2) => {
                    //Add Cell (col x row)
                    return (
                        <View
                            key={index2}
                            style={[
                                styles.cellWrapper,
                                styles.cellStyle,
                                { maxWidth: col.width ? col.width : CELLWIDTH }, //width custom
                            ]}
                        >
                            <Text key={col.label + index}>
                                {/* Debug: Add "Primo" in Cell */}
                                {/* {(index + 1) % lineByPage === 0 ? "Primo*" : ""} */}
                                {item[col.label]}
                            </Text>
                        </View>
                    );
                })}
            </View>
        );
    });
    const header = data.columns.map((col, index) => {
        return (
            <Text
                key={index}
                style={[
                    styles.cellWrapper,
                    styles.cellStyle,
                    styles.header,
                    { maxWidth: col.width ? col.width : CELLWIDTH }, //width custom
                ]}
            >
                {col.label}
            </Text>
        );
    });
    return (
        <View style={styles.tableContainer}>
            {/* Header */}
            <View style={styles.row} fixed>
                {header}
            </View>
            {/* Rows */}
            {rows}
        </View>
    );
};

export default TableRow;
