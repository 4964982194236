import React, { useState, useContext } from "react";
import { Api } from "../../../providers";
import { InputSearch } from "../../inputs";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { ServiceContext } from "../../../contexts/service";
import qs from "qs";
import "./../style.css";
import styles from "./styles.module.scss";
import { SearchField } from "./components/searchField";

const Search = () => {
    const { setCategorias, setLoading, setTotalPages, setTotalElements,
        setParamsFilter } = useContext(ServiceContext);
    const [busca, setBusca] = useState("");
    const [ativo, setAtivo] = useState("null");

    const handleSumbit = (event) => {
        event.preventDefault();

        let params = {};
        if (busca !== "") params.nome = busca;
        if (ativo !== "null") params.flagAtivo = ativo;

        Api.get(`/categoria/pagination/0/20?`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Servico Provider
                setCategorias(res.data.content);
                setTotalPages(res.data.totalPages);
                setParamsFilter(params);
                setLoading(false);
                if (res.data.content.length <= 0) {
                    toast.success("Nenhum resultado encontrado!", {
                        toastId: "msg",
                    });
                }
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    }

    return (
        <>
            <div className="conteudo-tabela">
                <form>
                    <div>
                        <div>
                            <SearchField
                                placeholder="Pesquise aqui"
                                onClick={handleSumbit}
                                onChange={(e) => searchText(e.target.value)}
                                search={busca}
                            />
                        </div>

                        <div className={styles.containerFilds}>
                            <div className={styles.subContainerFilds}>
                                <span>Filtrar resultados</span>
                                <div className={styles.fieldSelect}>
                                    <select className="form-select"
                                        value={ativo}
                                        onChange={(e) =>
                                            setAtivo(e.target.value)
                                        }
                                    >
                                        <option value="null" key="0" disabled>
                                            Status
                                        </option>
                                        <option value="" key="999">
                                            Todos
                                        </option>
                                        <option value="true" key="1">
                                            Ativo
                                        </option>
                                        <option value="false" key="2">
                                            Inativo
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Search;
