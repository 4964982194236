import {
    differenceInWeeks,
    format,
    getHours,
    getMinutes,
    getMonth,
    getWeekOfMonth,
    isBefore,
    isSameDay,
    isSameWeek,
    parseISO,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import ptBr from "date-fns/locale/pt-BR";
import { DateObject } from "react-multi-date-picker";

export const ZoneDateBR = (date) => {
    return zonedTimeToUtc(date, "America/Sao_Paulo");
};

export const FormatDate = (date, formatDefault = "yyyy-MM-dd") => {
    try {
        if (date) {
            date = ZoneDateBR(date);
            return format(date, formatDefault);
        }
    } catch (error) {
        // console.log(error);
    }
};
export const DateBR = (date) => {
    try {
        if (date) {
            date = ZoneDateBR(date);
            return format(date, "dd/MM/yyyy");
        }
    } catch (error) {
        // console.log(error);
    }
};

export const DateBRPorExtenso = (date) => {
    try {
        if (date) {
            date = ZoneDateBR(date);
            return (
                format(date, "dd") +
                " de " +
                monthBR(format(date, "MM")) +
                " de " +
                format(date, "yyyy")
            );
        }
    } catch (error) {
        // console.log(error);
    }
};

export const TimeBR = (date) => {
    date = ZoneDateBR(date);
    return format(date, "H:mm");
};

export const TimeBRCheck = (date) => {
    date = ZoneDateBR(date);
    const dateTime = format(date, "mm");
    return Number(dateTime) > 0
        ? format(date, "H:mm")
        : format(date, "H") + "h";
};

export const DateTimeBR = (date) => {
    date = ZoneDateBR(date);
    return format(date, "dd/MM/yyyy H:mm", {
        locale: ptBr,
    });
};
// get Minutes
export const Minutes = (date) => {
    date = ZoneDateBR(date);
    return getMinutes(date);
};
//Get Hours
export const Hours = (date) => {
    date = ZoneDateBR(date);
    return getHours(date);
};
//Get Month
export const MonthDate = (date) => {
    date = ZoneDateBR(date);
    return getMonth(date).toLocaleString(undefined, {
        minimumIntegerDigits: 2,
    });
};
// Is before
export const CompareDateIfBefore = (first, second) => {
    //Apenas Data
    let fr1 = FormatDate(first);
    let nd2 = FormatDate(second);
    //Parse ISO
    return isBefore(parseISO(fr1), parseISO(nd2));
};

/**
 * Utils Date
 */

// Quantidade de semanas
export const diffWeek = (
    datefirst = new Date(2022, 5, 6),
    dateSecond = new Date(2022, 4, 29),
) => {
    if (datefirst && dateSecond)
        return differenceInWeeks(datefirst, dateSecond);
    return null;
};
//As datas são da mesma semana?
export const someWeek = (datefirst, dateSecond) => {
    if (datefirst && dateSecond) return isSameWeek(datefirst, dateSecond);
    return null;
};

/**
 * As datas são iguais?
 * @param {object} datefirst
 * @param {object} dateSecond
 * @returns {boolean}
 */
export const someDays = (datefirst, dateSecond) => {
    if (datefirst && dateSecond) {
        let fr1 = parseISO(FormatDate(datefirst));
        let nd2 = parseISO(FormatDate(dateSecond));
        return isSameDay(fr1, nd2);
    }
    return false;
};
//Qual a semana do mês
export const weekMonth = (date) => {
    if (date) return getWeekOfMonth(date);
    return null;
};

/**
 * Converte Data em Objeto (vice-versa)
 * Used DataObject
 */

/**
 *
 * @param {*} dateObject
 * @param {string} formatDefault
 * @returns {string}
 */
export const dateObjectToString = (
    dateObject,
    formatDefault = "DD-MM-YYYY",
) => {
    return dateObject.format(formatDefault);
};

/**
 * Converte Datas String
 * i.e.: `2022-05-15`  - Output: `2022/05/20 12:00:00.000 am`
 * @param {string} dateString
 * @param {string} formatDefault
 * @returns {string}
 */
export const dateStringToObject = (
    dateString,
    formatDefault = "DD-MM-YYYY hh:mm:ss.SSS a",
) => {
    let date = new DateObject(dateString);
    return date.format(formatDefault); //2022/05/20 12:00:00.000 am
};
/**
 * Converte em Datas Objects
 * @param {string} dateString
 * @returns {object}
 */
export const convertToObject = (dateString) => {
    return new DateObject(dateString);
};

/**
 * Lista todos os dias do Mês atual
 * @param {int} year
 * @param {int} month
 * @returns {array}
 */
export const getAllDaysInMonth = (currentDate) => {
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const date = new Date(year, month, 1);
    const dates = [];

    while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
};

/**
 * Lista todos os dias do Mês atual
 * @param {int} year
 * @param {int} month
 * @returns {array}
 */
export const getAllDaysInMonthTreatment = (currentDate, currentMonth) => {
    let currentDateWithTreatment = currentMonth > 11;
    let year = currentDateWithTreatment
        ? currentDate.getFullYear() + 1
        : currentDate.getFullYear();
    let month = currentDate.getMonth();
    const date = new Date(year, month, 1);
    const dates = [];

    while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
};

/**
 * Today
 * Em Editar a data tem que ser a partir de hoje para habilitar o checkbox
 */
export const isDaysFromToday = (date) => {
    //Mês atual: A partir de hoje
    // if (date.getMonth() === new Date().getMonth()) {
    if (date.getMonth() === new Date().getMonth()) {
        return !!(date.getDate() >= new Date().getDate());
    }
    // Mês anterior: bloqueia selected
    if (
        date.getMonth() < new Date().getMonth() &&
        date.getFullYear() === new Date().getFullYear()
    ) {
        return false;
    }
    // MÊs posterior: liberar seleção
    return true;
};

/**
 * Today
 * Em Editar a data tem que ser a partir de hoje para habilitar o checkbox
 */
export const isToday = (date) => {
    return date.getDate() === new Date().getDate();
};

/**
 * Get Month in a range of four months
 * Index: [0 1 2 3 4 5 6 7 8 9 10 11]
 * Months:[J F M A M J J A S O N  D]
 * @param {int} monthIndex index month new [0,...,11]
 * @param {object} currentDate date current at calendar
 * @returns {object}
 */
export const getMonthBeforeOrAfter = (monthIndex, currentDate) => {
    let today = new Date();
    let todayYear = today.getFullYear();

    let currentMonth = currentDate.getMonth();
    let currentYear = currentDate.getFullYear();
    if (todayYear && currentYear) {
        //Before Year
        if (currentMonth === 0 && monthIndex < 0) {
            return new Date(todayYear - 1, 11);
        }
        //After year: first halfyear & second halfyear
        if (currentMonth === 11 && monthIndex > 11) {
            return new Date(todayYear + 1, monthIndex - 12);
        }
    }
    return new Date(todayYear, monthIndex);
};

export const FormatDateNameBREvaluation = (date) => {
    const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];

    let data = new Date(date);

    let dataFormatada =
        meses[data.getUTCMonth()] + " de " + data.getUTCFullYear();

    return dataFormatada;
};

const getMes = (index) => {
    const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];
    console.log(index + 1);
    return meses[parseInt(index) + 1];
};

const monthBR = (month) => {
    if (month > 0) {
        const value = month - 1;
        const meses = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        return meses[value];
    } else {
        return "Junho";
    }
};

function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
}

export function convertMsToTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
        seconds,
    )}`;
}
