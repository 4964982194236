import { Grid } from "@mui/material";
import moment from "moment";
import qs from "qs";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { BaseURL } from "../../../api";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import MyDatePicker from "../../../components/datepicker";
import { ModalGenericColor, ModalLoading } from "../../../components/modal";
import ResultNotFound from "../../../components/resultNotFound";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { ClientesProfissionaisProvider } from "../../../contexts/service";
import { Api } from "../../../providers";
import { CpfCnpjMaskRegex, TelMaskRegex } from "../../../utils/mask";
import styles from "./styles.module.scss";

export default function RelatClientesMunicipio() {
    const { setCurrentScreen } = useContext(AuthContext);

    const [loadingModal, setLoadingModal] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [municipios, setMunicipios] = useState([]);
    const [municipioId, setMunicipioId] = useState(0);
    const [bairrosId, setBairrosId] = useState(0);
    const [bairros, setBairros] = useState([]);
    const [nomeBairro, setNomeBairro] = useState("");
    const [nomeCidade, setNomeCidade] = useState("");
    const [todosClienteSwitch, setTodosClienteSwitch] = useState(true);
    const [nomeCliente, setNomeCliente] = useState("");
    const [placeholderNomeCliente, setPlaceholderNomeCliente] = useState(
        "Selecione um cliente ",
    );
    const [statusModal, setStatusModal] = useState(false);

    const [resultCliente, setResultCliente] = useState([]);
    const [totalPagesModal, setTotalPagesModal] = useState(0);
    const [currentPageModal, setCurrentPageModal] = useState(1);
    const [idCliente, setIdCliente] = useState(0);
    const [paramsFilter, setParamsFilter] = useState({});
    const [clienteSelecionado, setClienteSelecionado] = useState("");
    const [cidadeDifCep, setCidadeDifCep] = useState("");
    const [bairroDifCep, setBairroDifCep] = useState("");

    /*
        0 - estado inicial
        1 - retornou resultado
        2 - não retornou resultado
    */
    const [tipoBusca, setTipoBusca] = useState(0);

    const token = localStorage.getItem("token");
    let mounted = true;

    //dataInicio
    useEffect(() => {
        const elementInic = document.getElementById("dataInicio");
        elementInic.setAttribute("placeholder", "Escolha uma data");
        elementInic.addEventListener("click", displayDateInic);

        const elementFim = document.getElementById("dataFim");
        elementFim.setAttribute("placeholder", "Escolha uma data");
        elementFim.addEventListener("click", displayDateFim);
    }, []);

    function displayDateInic() {
        const element = document.getElementById("dataInicio");
        element.setAttribute("placeholder", "Escolha uma data");
    }

    function displayDateFim() {
        const element = document.getElementById("dataFim");
        element.setAttribute("placeholder", "Escolha uma data");
    }

    useEffect(() => {
        setCurrentScreen(15);

        async function filterData() {
            setLoadingModal(true);
            try {
                await Promise.all([
                    Api.get(`/municipio`).then((res) => {
                        if (res.status == 200) {
                            setMunicipios(res.data);
                        }
                    }),
                    Api.get(`/bairro`).then((res) => {
                        if (res.status == 200) {
                            const uniqueArray = res.data.filter(
                                (obj, index, self) =>
                                    index ===
                                    self.findIndex(
                                        (t) => t.logradouro == obj.logradouro,
                                    ),
                            );
                            const totalBairro = [];
                            const unicData = uniqueArray
                                .map((value) => {
                                    totalBairro.push(value.logradouro ?? "");
                                    return {
                                        ...value,
                                    };
                                })
                                .filter((data) => data.logradouro != "");
                            setBairros(totalBairro);
                        }
                    }),
                ]);
            } catch (error) {
                toast.error("Ops! Aconteceu algum problema com os filtros!", {
                    toastId: "msg",
                });
            } finally {
                setLoadingModal(false);
            }
        }
        filterData();
        return () => (mounted = false);
    }, []);

    const handleStartDate = (text) => {
        const checkDate01 = moment(text, "YYYY-MM-DD").valueOf();
        const checkDate02 = moment(endDate, "YYYY-MM-DD").valueOf();
        const subtractDate = moment(endDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        const checkDateStart =
            moment(text, "YYYY-MM-DD").valueOf() > 0 ? true : false;
        if (text && checkDateStart) {
            const dateSelected = new Date(text);
            if (!isNaN(checkDate02)) {
                if (checkDate01 <= checkDate02) {
                    setStartDate(dateSelected);
                } else {
                    if (checkDateStart) {
                        setStartDate(subtractDate);
                    } else {
                        setStartDate(dateSelected);
                    }
                }
            } else {
                setStartDate(dateSelected);
            }
        } else {
            setStartDate("");
        }
    };

    const handleEndDate = (text) => {
        const checkDate01 = moment(text, "YYYY-MM-DD").valueOf();
        const checkDate02 = moment(startDate, "YYYY-MM-DD").valueOf();
        const addDay = moment(startDate).add(1, "days").format("YYYY-MM-DD");
        const checkDateEnd =
            moment(text, "YYYY-MM-DD").valueOf() > 0 ? true : false;
        if (text && checkDateEnd) {
            const dateSelected = new Date(text);
            if (!isNaN(checkDate02)) {
                if (checkDate01 >= checkDate02) {
                    setEndDate(dateSelected);
                } else {
                    if (checkDateEnd) {
                        setEndDate(addDay);
                    } else {
                        setEndDate(dateSelected);
                    }
                }
            } else {
                setEndDate(dateSelected);
            }
        } else {
            setEndDate("");
        }
    };

    const selecionarCidade = (idCidade) => {
        const infoData = municipios.find((val) => val.id == idCidade);
        if (infoData) {
            setNomeCidade(infoData.nome);
        } else {
            setNomeCidade("");
        }
        setMunicipioId(idCidade);
    };

    const selecionarBairro = (nomeBairro) => {
        const infoData = bairros.find((val) => val == nomeBairro);
        if (infoData) {
            setNomeBairro(nomeBairro);
        } else {
            setNomeBairro("");
        }
        setBairrosId(nomeBairro);
    };

    const handleBtnRelat = () => {
        const date01 = moment(startDate).format("YYYY-MM-DD");
        const date02 = moment(endDate).format("YYYY-MM-DD");

        const geraRelat = `${BaseURL}api/relatorio/cliente-municipio?fields=${clienteSelecionado}&dataInicio=${date01}&dataFim=${date02}&cidade=${nomeCidade}&bairro=${nomeBairro}&token=${token}`;
        window.open(geraRelat);
    };

    const checkSwitch =
        !todosClienteSwitch && clienteSelecionado != "" ? true : false;
    const checkNomeCliente =
        todosClienteSwitch && clienteSelecionado == "" ? true : checkSwitch;

    const checkDate01 =
        moment(startDate, "YYYY-MM-DD").valueOf() > 0 ? true : false;
    const checkDate02 =
        moment(endDate, "YYYY-MM-DD").valueOf() > 0 ? true : false;

    const checkFilterSubmit =
        checkNomeCliente &&
        (municipioId !== "" || nomeCidade.length > 0) &&
        (bairrosId !== "" || nomeBairro.length > 0) &&
        checkDate01 &&
        checkDate02
            ? false
            : true;

    // ***********************************************************************

    const handleSwitch = (value) => {
        setTodosClienteSwitch(value);
        setPlaceholderNomeCliente(
            value ? "Selecione um cliente " : "Selecione nome do cliente ",
        );
        if (value) {
            setMunicipioId(0);
            setBairrosId(0);
            setNomeCliente("");
            setClienteSelecionado("");
            setResultCliente([]);
            setTotalPagesModal(0);
            setNomeCidade("");
            setNomeBairro("");
        } else {
            setMunicipioId("");
            setBairrosId("");
            setNomeCliente("");
            setClienteSelecionado("");
            setResultCliente([]);
            setTotalPagesModal(0);
            setNomeCidade("");
            setNomeBairro("");
        }
    };

    const handleBusca = (event) => {
        setLoadingModal(true);
        event.preventDefault();

        let params = {};
        if (nomeCliente != "") params.fields = nomeCliente;

        Api.get(`/cliente/pagination/0/5?`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Provider
                setResultCliente(res.data.content);
                setTotalPagesModal(res.data.totalPages);
                setParamsFilter(params);
                setLoadingModal(false);
                setTipoBusca(res.data.content.length > 0 ? 1 : 2);
            })
            .catch((_) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setResultCliente([]);
                setTotalPagesModal(0);
                setLoadingModal(false);
                setTipoBusca(2);
            });
    };

    const nextPageModal = (page = 0) => {
        setLoadingModal(true);
        const urlData = `/cliente/pagination/${page}/5`;
        const params = paramsFilter;
        Api.get(urlData, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                setResultCliente(res.data.content);
                setTotalPagesModal(res.data.totalPages);
                setLoadingModal(false);
            })
            .catch((_) => {
                setLoadingModal(false);
                setTotalPagesModal(0);
            });
    };

    const handlePageClickModal = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPageModal(total);
        nextPageModal(event.selected);
    };

    const inputSearch = (text) => {
        if (text.length > 0 && text.trim()) {
            setNomeCliente(text);
        } else {
            setNomeCliente("");
        }
    };

    const handleSelected = (id) => {
        const resultId = Number(id);
        if (resultId > 0) {
            setIdCliente(resultId);
        }
    };

    const handleBtnSalvar = () => {
        if (idCliente > 0) {
            const resultData = resultCliente.find(
                (data) => data.id == idCliente,
            );
            if (resultData) {
                setClienteSelecionado(resultData.nome);
                setStatusModal(false);
                if (resultData?.enderecos?.length > 0) {
                    const endereco = resultData.enderecos.find(
                        (val) => val.flagPrincipal,
                    );
                    if (endereco) {
                        if (endereco.cidade.length > 0) {
                            setNomeCidade(endereco.cidade);
                        } else {
                            setMunicipioId("");
                            setNomeCidade("");
                        }

                        if (endereco.bairro.length > 0) {
                            setNomeBairro(endereco.bairro);
                        } else {
                            setBairrosId("");
                            setNomeBairro("");
                        }
                    } else {
                        setMunicipioId("");
                        setBairrosId("");
                        setNomeCidade("");
                        setNomeBairro("");
                    }
                } else {
                    setMunicipioId("");
                    setBairrosId("");
                    setNomeCidade("");
                    setNomeBairro("");
                }
            }
        } else {
            setStatusModal(false);
        }
    };

    const handleBtnCancelar = () => {
        setStatusModal(false);
        setResultCliente([]);
        setTotalPagesModal(0);
        setNomeCliente("");
        setTipoBusca(0);
    };

    return (
        <ClientesProfissionaisProvider>
            <Grid>
                <BreadCrumb
                    data={{
                        menu: {
                            item: "Relatórios",
                            link: "",
                        },
                        page: `Clientes por Município`,
                    }}
                />
                <Title title="Relatório de Clientes por Município" />
                {/* **************************************************************** */}

                <div className={styles.containerGeral}>
                    <div className="justify-content-center align-items-center container">
                        <div className={`${styles.containerInput}`}>
                            <div className={styles.containerSwitch}>
                                <span className={styles.textInputNome}>
                                    Todos os clientes
                                </span>
                                <div>
                                    <input
                                        type="checkbox"
                                        checked={todosClienteSwitch}
                                        onChange={(e) =>
                                            handleSwitch(!todosClienteSwitch)
                                        }
                                        id={styles.checkbox}
                                    />
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputNome}`}>
                                    <label className="form-label">
                                        Nome do cliente
                                    </label>
                                    <input
                                        id="busca"
                                        type="text"
                                        placeholder={placeholderNomeCliente}
                                        name="busca"
                                        className={`${styles.inputPesquisar} ${
                                            todosClienteSwitch
                                                ? styles.inputColor
                                                : {}
                                        }`}
                                        disabled={todosClienteSwitch}
                                        value={clienteSelecionado}
                                        onClick={() =>
                                            todosClienteSwitch
                                                ? {}
                                                : setStatusModal(true)
                                        }
                                    />
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div
                                    className={`col ${styles.inputLine} ${
                                        todosClienteSwitch
                                            ? {}
                                            : styles.selectCidBair
                                    }`}
                                >
                                    <div>
                                        <label className="form-label">
                                            Cidade
                                        </label>
                                        <select
                                            className={`form-select`}
                                            value={municipioId}
                                            onChange={(e) =>
                                                selecionarCidade(e.target.value)
                                            }
                                            disabled={!todosClienteSwitch}
                                        >
                                            <option value="" key="0" disabled>
                                                {nomeCidade.length > 0
                                                    ? nomeCidade
                                                    : "Selecione uma cidade"}
                                            </option>
                                            <option value="0" key="999">
                                                Todas
                                            </option>
                                            {municipios.map((data, key) => {
                                                return (
                                                    <option
                                                        value={data.id}
                                                        key={key}
                                                    >
                                                        {data.nome}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div
                                    className={`col ${styles.inputLine} ${
                                        todosClienteSwitch
                                            ? {}
                                            : styles.selectCidBair
                                    }`}
                                >
                                    <div>
                                        <label className="form-label">
                                            Bairro
                                        </label>
                                        <select
                                            className="form-select"
                                            value={bairrosId}
                                            onChange={(e) =>
                                                selecionarBairro(e.target.value)
                                            }
                                            disabled={!todosClienteSwitch}
                                        >
                                            <option value="" key="0" disabled>
                                                {nomeBairro.length > 0
                                                    ? nomeBairro
                                                    : "Selecione um bairro"}
                                            </option>
                                            <option value="0" key="999">
                                                Todos
                                            </option>
                                            {bairros.map((data, key) => {
                                                return (
                                                    <option
                                                        value={data}
                                                        key={key}
                                                    >
                                                        {data}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLineData}`}>
                                    <div className={styles.inputData}>
                                        <label className="form-label">
                                            Data Início
                                        </label>
                                        <MyDatePicker
                                            id="dataInicio"
                                            value={startDate}
                                            onChange={(e) => handleStartDate(e)}
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLineData}`}>
                                    <div className={styles.inputData}>
                                        <label className="form-label">
                                            Data Fim
                                        </label>
                                        <MyDatePicker
                                            id="dataFim"
                                            value={endDate}
                                            onChange={(e) => handleEndDate(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.bntGerarRelat}>
                                <button
                                    className={
                                        checkFilterSubmit
                                            ? styles.buttonRelatBlock
                                            : styles.buttonRelat
                                    }
                                    type="button"
                                    onClick={
                                        checkFilterSubmit
                                            ? () => {}
                                            : () => handleBtnRelat()
                                    }
                                >
                                    <span>Gerar relatório</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* **************************************************************** */}
            </Grid>

            <ModalGenericColor
                styles={{
                    // minHeight: "578px",
                    // width: "886px",
                    // minHeight: "264px !important",
                    width: "694px",
                }}
                title="Buscar cliente"
                openModal={statusModal}
                setOpenModal={handleBtnCancelar}
                onClick={() => handleBtnSalvar()}
                labelConfirm="Salvar"
                labelCancel={resultCliente.length > 0 ? "Cancelar" : "Fechar"}
                backgroundCacel="#fff"
                color={"#0064B4"}
                btnCancel={true}
                btnConfirm={resultCliente.length > 0 ? true : false}
                statusClickCancel={true}
                onClickCancel={handleBtnCancelar}
            >
                <Container
                    className={`${styles.containerModal} ${
                        resultCliente.length <= 0 &&
                        (tipoBusca == 0 || tipoBusca == 2)
                            ? styles.containerModalBottom
                            : ""
                    }`}
                >
                    <div className="d-flex justify-content-center align-items-center container p-2">
                        <div className={styles.boxModal}>
                            <div className={`${styles.containerInputModal}`}>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            inputSearch(e.target.value)
                                        }
                                        value={nomeCliente}
                                        type="text"
                                        className={`form-control ${styles.inputSearchModal}`}
                                        title="Buscar por nome"
                                        placeholder="Buscar por nome"
                                        aria-label="Buscar por nome"
                                    />
                                </div>
                                <div>
                                    <button
                                        className={
                                            nomeCliente.length <= 0
                                                ? styles.buttonSearchBlock
                                                : styles.buttonSearch
                                        }
                                        type="submit"
                                        onClick={handleBusca}
                                        disabled={
                                            nomeCliente.length <= 0
                                                ? true
                                                : false
                                        }
                                    >
                                        Pesquisar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`justify-content-center align-items-center container mb-4 p-10 table-responsive ${
                            tipoBusca == 2 ? styles.tableFormModal : ""
                        }`}
                    >
                        {resultCliente.length > 0 && (
                            <table
                                className={`table align-middle ${styles.tableModal}`}
                            >
                                <thead>
                                    <tr>
                                        <th>
                                            <div>
                                                <span />
                                            </div>
                                        </th>
                                        <th>Cliente</th>
                                        <th>CPF/CNPJ</th>
                                        <th>Telefone</th>
                                    </tr>
                                </thead>
                                <tbody
                                    data-test="table-body"
                                    className={styles.tbodyModal}
                                >
                                    {resultCliente.length > 0 &&
                                        resultCliente.map((val) => {
                                            return (
                                                <tr
                                                    onClick={() =>
                                                        handleSelected(
                                                            val.id,
                                                            val.nome,
                                                        )
                                                    }
                                                    className={
                                                        idCliente == val.id
                                                            ? styles.textSelected
                                                            : styles.textNoSelected
                                                    }
                                                >
                                                    <td>
                                                        {idCliente == val.id ? (
                                                            <div
                                                                className={
                                                                    styles.radioCircle
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles.circleBall
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    styles.radCirNoSelected
                                                                }
                                                            />
                                                        )}
                                                    </td>
                                                    <td
                                                        className={
                                                            styles.infoText
                                                        }
                                                    >
                                                        {val.nome}
                                                    </td>
                                                    <td
                                                        className={
                                                            styles.infoText
                                                        }
                                                    >
                                                        {`${
                                                            val.cpfCnpj
                                                                ? CpfCnpjMaskRegex(
                                                                      val.cpfCnpj,
                                                                  )
                                                                : ""
                                                        }`}
                                                    </td>
                                                    <td
                                                        className={
                                                            styles.infoText
                                                        }
                                                    >
                                                        {val.telefoneCelular
                                                            ? TelMaskRegex(
                                                                  val.telefoneCelular,
                                                              )
                                                            : ""}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        )}

                        {resultCliente.length <= 0 && tipoBusca == 2 && (
                            <ResultNotFound
                                message="Nenhum resultado foi encontrado"
                                style={{
                                    color: "#343B40",
                                    fontWeight: 400,
                                }}
                            />
                        )}

                        {totalPagesModal > 0 && (
                            <div className={styles.reactPaginateModal}>
                                <ReactPaginate
                                    breakLabel="..."
                                    onPageChange={handlePageClickModal}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPagesModal}
                                    previousLabel="<"
                                    nextLabel=">"
                                    renderOnZeroPageCount={null}
                                    marginPagesDisplayed={2}
                                    containerClassName={styles.pagination}
                                    pageClassName={styles.pageItem}
                                    pageLinkClassName={"page-link"}
                                    previousLinkClassName={styles.pageLink}
                                    nextClassName={styles.pageItem}
                                    nextLinkClassName={styles.pageLink}
                                    activeClassName={"active"}
                                    forcePage={currentPageModal - 1}
                                    disabledClassName={styles.disabled}
                                />
                            </div>
                        )}
                    </div>
                </Container>
            </ModalGenericColor>

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </ClientesProfissionaisProvider>
    );
}
