import * as React from "react";

import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from "@mui/material/";
import { dateStringToObject } from "./../../utils/date/index";
import MyCheckbox from "./../checkbox/";
import styles from "./styles.module.scss";

//Card
export const MyCard = (props) => {
    return (
        <Card
            variant="outlined"
            sx={{
                maxWidth: 252,
                minHeight: 193,
                background: "#FFFFFF",
                border: "1px solid #0064B4",
                boxSizing: "border-box",
            }}
            {...props}
        >
            {props.children}
        </Card>
    );
};

// Address
export function MyCardAddress({
    address,
    checked,
    onClick,
    onChange,
    disabled,
}) {
    const cardProps = (
        <>
            <CardContent>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                    }}
                >
                    <Typography
                        sx={{
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "15px",
                            lineHeight: "17px",
                            color: "#252A31",
                        }}
                        gutterBottom
                        variant="inherit"
                        component="div"
                    >
                        {address.titulo}
                    </Typography>
                    <MyCheckbox
                        checked={checked}
                        onClick={onChange}
                        disabled={disabled}
                    />
                </Box>
                <Box sx={{ width: "200px", height: "75px" }}>
                    <Typography
                        sx={{
                            p: 1,
                            mb: 1.5,
                            width: "230px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",

                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "15px",
                            color: "#252A31",
                            textTransform: "capitalize",
                        }}
                        variant="inherit"
                        component="div"
                    >
                        {`${address.logradouro},${address.numero}`}
                        <br />
                        {`${address.bairro},${address.cidade} ${address.cep}`}
                    </Typography>
                    <Typography
                        sx={{
                            p: 1,
                            mb: 1.5,
                            width: "230px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",

                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "15px",
                            color: "#252A31",

                            justifyContent: "start",
                            textTransform: "capitalize",
                        }}
                        variant="inherit"
                        component="div"
                    >
                        {`${address.referencia}`}
                    </Typography>
                </Box>
            </CardContent>
            <CardActions sx={{ display: "flex", m: 0, p: 0, direction: "rtl" }}>
                <Button
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "12px",
                        lineHeight: "15px",
                        color: "#0064B4",
                        textTransform: "none",
                        textDecoration: "underline",
                    }}
                    size="small"
                    onClick={() => onClick("update", address.id)}
                >
                    Alterar
                </Button>{" "}
                <Button
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "12px",
                        lineHeight: "15px",
                        color: "#0064B4",
                        textTransform: "none",
                        textDecoration: "underline",
                    }}
                    size="small"
                    onClick={() => onClick("delete", address.id)}
                >
                    Excluir
                </Button>
            </CardActions>
        </>
    );

    return (
        <>
            <MyCard variant="outlined">{cardProps}</MyCard>
        </>
    );
}

const returnColor = (statusColor) => {
    switch (statusColor) {
        case "A Confirmar":
            return styles.aconfirmar;
        case "Confirmado":
            return styles.confirmado;
        case "Realizado":
            return styles.realizado;
        case "Não Realizado":
            return styles.nao_realizado;
        case "Avaliado":
            return styles.avaliado;
        case "Cancelado":
            return styles.cancelado;
        case "Expirado":
            return styles.expirado;
        default:
            return styles.confirmado;
    }
};

// Tasks
export function MyCardTask({ onClick, readMore, task }) {
    const servico = task?.servico?.servico?.nome?.substring(0, 15);
    const nomerServico =
        task?.servico?.servico?.nome?.length > 15 ? `${servico}...` : servico;

    const dataCliente = task?.cliente?.nome?.substring(0, 15);
    const nomeCliente =
        task?.cliente?.nome?.length > 15 ? `${dataCliente}...` : dataCliente;

    const containerCard = (
        <div className={styles.cardInfo}>
            <div className={styles.topoCard}>
                <span>{`#${task.id}`}</span>
                <span className={returnColor(task.status)}>
                    {task?.status == "Não Realizado"
                        ? "Não realizado"
                        : task?.status}
                </span>
            </div>
            <div className={styles.bodyCard}>
                <div>
                    <span>Data:</span>{" "}
                    <span>
                        {dateStringToObject(task.datas[0], "DD/MM/YYYY")}
                    </span>
                </div>
                <div>
                    <span>Horário:</span>{" "}
                    <span>{task?.hora?.substring(0, 5)}</span>
                </div>
                <div>
                    <span>Serviço:</span> <span>{nomerServico}</span>
                </div>
                <div>
                    <span>Cliente:</span> <span>{nomeCliente}</span>
                </div>
            </div>
            <div className={styles.rodapeCard} onClick={readMore}>
                <span>Ver mais</span>
            </div>
        </div>
    );

    return containerCard;
}

export function CardAddress({ address, checked, onClick, onChange, disabled }) {
    const totalCaracter =
        address?.referencia?.length > 12
            ? address.referencia.substring(0, 8) + "..."
            : address?.referencia?.substring(0, 9);
    const totalCaracterTitle =
        address?.titulo?.length > 23
            ? address.titulo.substring(0, 23) + "..."
            : address?.titulo?.substring(0, 23);

    const totalCaracterBairro =
        address?.bairro?.length > 20
            ? address.bairro.substring(0, 20) + "..."
            : address?.bairro?.substring(0, 20);
    const totalCaracterCidade =
        address?.cidade?.length > 20
            ? address.cidade.substring(0, 20) + "..."
            : address?.cidade?.substring(0, 20);
    const totalCaracterNumero =
        address?.numero?.length > 5
            ? address.numero.substring(0, 5) + "..."
            : address?.numero?.substring(0, 5);
    const totalCaracterLogradouro =
        address?.logradouro?.length > 23
            ? address.logradouro.substring(0, 23) + "..."
            : address?.logradouro?.substring(0, 20);

    const cardProps = (
        <div>
            <div className={styles.infoContent}>
                <span className={styles.titleAddress}>
                    {totalCaracterTitle}
                </span>
                <span className={styles.logradAddress}>
                    {totalCaracterLogradouro}, {totalCaracterNumero}
                </span>
                <span
                    className={styles.subLogradAddress}
                >{`${totalCaracterBairro}, ${totalCaracterCidade} - ${address.cep}`}</span>
                <div className={styles.referText}>
                    <div>
                        Ponto de referência: <span>{totalCaracter}</span>
                    </div>
                </div>
                <div className={styles.referText}>
                    <div>
                        Possui animais?{" "}
                        <span>{address.flagAnimais ? "Sim" : "Não"}</span>
                    </div>
                </div>

                <div className={styles.containerAcoes}>
                    <span onClick={() => onClick("update", address.id)}>
                        Alterar
                    </span>
                    <span onClick={() => onClick("delete", address.id)}>
                        Excluir
                    </span>
                </div>
            </div>
        </div>
    );

    return (
        <div
            className={`${styles.myCardAddres} ${
                address.flagPrincipal ? styles.myCardAddresPrinc : {}
            }`}
        >
            {cardProps}
        </div>
    );
}
