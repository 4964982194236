import React from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { RestricaoTableProfissional } from "../../../components/tables/restricao/profissional/";
import { ProfissionaisProvider } from "../../../contexts/service";
import { SearchRestricaoProfissional } from "./../../../components/search/restricao/profissional/";

//Documents
export default function Section8() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    return (
        <ProfissionaisProvider>
            <Container fluid className="justify-content-center">
                <SearchRestricaoProfissional idQuery={idQuery} />
                <RestricaoTableProfissional idQuery={idQuery} />
            </Container>
        </ProfissionaisProvider>
    );
}
