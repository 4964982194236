import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ModalLoading } from "../../../components/modal";
import { Api } from "../../../providers";
import styles from "./styles.module.scss";

const Form = () => {
    const [presencaSwitch, setPresencaSwitch] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Api.get("/system/register?slug=profissional").then((res) => {
            if (res.status === 200) {
                setPresencaSwitch(res.data.permissao);
            }
        });
    }, []);

    const handleSubmit = (status) => {
        const payload = {
            slug: "profissional",
            permissao: status,
            dataAtualizacao: null,
        };
        setLoading(true);

        Api.post("/system/register/", payload)
            .then((_) => {
                toast.success("Ação realizada com sucesso", {
                    toastId: "success",
                });
                setLoading(false);
                setPresencaSwitch(status);
            })
            .catch((err) => {
                setLoading(false);
                const checkErro = err;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });
    };

    return (
        <>
            <div className={styles.containerSwitch}>
                <span className={styles.textHabilitar}>
                    Habilitar novos cadastros de profissionais
                </span>
                <div>
                    <input
                        type="checkbox"
                        checked={presencaSwitch}
                        onChange={(e) => handleSubmit(!presencaSwitch)}
                        id={styles.checkbox}
                    />
                </div>
            </div>

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loading}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};

export default Form;
