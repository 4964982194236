import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Api } from "./../../../providers/index";
import { PhoneMask } from "./../../../utils/mask/index";
import styles from "./styles.module.scss";

const ItemCustom = {
    width: "400px !important",
    height: "56px",
    borderRadius: "8px !important",
    "& .MuiOutlinedInput-input": {
        padding: "16.5px 0px",
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
    },
    "& .MuiInputBase-input": {
        width: "93%",
    },
};

const BoxData = ({ children }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                background: "#F7F7F7",
                borderRadius: "8px",
            }}
        >
            {children}
        </Box>
    );
};

export default function Section2({ tabData }) {
    const [telefone, setTelefone] = useState(tabData.telefoneCelular);
    const [email, setEmail] = useState(tabData.email);
    const [nomeContato, setNomeContato] = useState(tabData.nomeContato);
    const [telefoneContato, setTelefoneContato] = useState(
        tabData.telefoneContato,
    );

    const handleSubmit = () => {
        tabData.telefoneCelular = telefone;
        tabData.email = email;
        tabData.nomeContato = nomeContato;
        tabData.telefoneContato = telefoneContato;
        Api.put(`/profissional/${tabData.id}`, tabData)
            .then((res) => {
                if (res.status === 200)
                    toast.success("Profissional atualizado com Sucesso", {
                        toastId: "success",
                    });
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "error",
                });
            });
    };

    return (
        <BoxData>
            <div className={styles.containerInputsBtn}>
                <div className={styles.containerGeralDadosP}>
                    <div className={styles.subContainerGeralDados01}>
                        <div className={styles.containerTextField}>
                            <div>
                                <span>Telefone celular (com DDD)</span>
                                <TextField
                                    className="contato_acesso"
                                    sx={ItemCustom}
                                    value={telefone}
                                    fullWidth
                                    onChange={(e) => {
                                        setTelefone(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputComponent: PhoneMask,
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className={`${styles.containerTextField} ${styles.dividerSpace}`}
                        >
                            <div>
                                <span>Nome do contato</span>
                                <TextField
                                    className="contato_acesso"
                                    sx={ItemCustom}
                                    value={nomeContato}
                                    fullWidth
                                    onChange={(e) => {
                                        setNomeContato(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Column Two */}
                    <div>
                        <div className={styles.containerTextField}>
                            <div>
                                <span>E-mail para contato</span>
                                <TextField
                                    className="contato_acesso"
                                    sx={ItemCustom}
                                    value={email}
                                    fullWidth
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className={`${styles.containerTextField} ${styles.dividerSpace}`}
                        >
                            <div>
                                <span>Telefone alternativo para contato</span>
                                <TextField
                                    className="contato_acesso"
                                    sx={ItemCustom}
                                    value={telefoneContato}
                                    fullWidth
                                    onChange={(e) => {
                                        setTelefoneContato(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputComponent: PhoneMask,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.containerInfoButtonEdit}>
                    <div className={styles.containerButtonEdit}>
                        <button
                            className={styles.buttonSearch}
                            type="button"
                            onClick={handleSubmit}
                            disabled={false}
                        >
                            <span>Salvar</span>
                        </button>
                    </div>
                </div>
            </div>

            {/* <div className={styles.containerGeralContat}>
                <div>
                    <div className={styles.containerTextField}>
                        <div>
                            <span>Telefone celular (com DDD)</span>
                            <TextField
                                className="contato_acesso"
                                sx={ItemCustom}
                                value={telefone}
                                fullWidth
                                onChange={(e) => {
                                    setTelefone(e.target.value);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    inputComponent: PhoneMask,
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.containerTextField}>
                        <div>
                            <span>E-mail para contato</span>
                            <TextField
                                className="contato_acesso"
                                sx={ItemCustom}
                                value={email}
                                fullWidth
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div> */}
        </BoxData>
    );
}
