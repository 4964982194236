/**
 * Find object in list 
 * @param {array} arrayList - List with all objects 
 * @param {array} arrayElement - list with object id/name.
 * @returns {array} objects found
 */
export const searchInArray = (arrayList = [], arrayElement = []) => {
    try {
        let list = [];
        Array.isArray(arrayElement) &&
            arrayElement.forEach((item) => {
                let obj = arrayList.find((obj) => {
                    if (
                        (typeof item === "string" &&
                            obj.nome.toLowerCase() === item.toLowerCase()) ||
                        (typeof item === "number" &&
                            parseInt(obj.id) === parseInt(item))
                    ) {
                        return obj;
                    }

                    return false;
                });
                list.push(obj);
            });
        return list;
    } catch (error) {
        // console.log(error);
    }
};
