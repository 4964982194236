import React from "react";
export const CountCaracter = ({ countText = 0, totalText = 200 }) => {
    return (
        <div className="form-textarea-caracter">
            <span>
                <strong>{countText}</strong>
                {"/"}
                {totalText} caracteres
            </span>
        </div>
    );
};
