import React from "react";
import { useLocation } from "react-router-dom";
import HistoricoTreinamentoProfissional from "../../../components/tables/historico/treinamento";

//Trreinamentos
export default function Section7() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    return <HistoricoTreinamentoProfissional key={idQuery} idQuery={idQuery} />;
}
