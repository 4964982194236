import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";
import AppRoutes from "./AppRoutes";
import Layout from "./components/layout";
//MDBTABLE
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "mdbreact/dist/css/mdb.css";

// Theme MUI
const theme = createTheme({
    palette: {
        primary: {
            main: "#0064B4",
        },
        secondary: {
            main: "#E60E19",
        },
        info: {
            main: "#0064B4",
        },
        error: {
            main: "#E60E19",
        },
        warning: {
            main: "#F9B000",
        },
        success: {
            main: "#95C11F",
        },
        typography: {
            fontFamily: ["Inter", "sans-serif"].join(","),
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                {/* USE CONTEXT */}
                <Layout>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    {/* AUTH CONTEXT */}
                    <AppRoutes />
                </Layout>
            </div>
        </ThemeProvider>
    );
}

export default App;
