import React, { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { HorStepper } from "./../../../components/stepper/index";
import Step3 from "./step3";
import Step2 from "./step2";
import Step1 from "./step1";
import useScriptRef from "./../../../hooks/useScriptRef";
import { Api } from "./../../../providers/index";
import { toast } from "react-toastify";
import { ServiceContext } from "./../../../contexts/service";
import { UserContext } from "../../../contexts/user";
import { ModalConfirmCustom, ModalDefault } from "../../../components/modal";
import styles from "./styles.module.scss";

const step1 = "/images/cadastrar/cadastrar-step1.png";
const step2 = "/images/cadastrar/cadastrar-step2.png";
const step3 = "/images/cadastrar/cadastrar-step3.png";

const CadastroOperadorPage = () => {
    const { setLoading } = useContext(ServiceContext);
    const { setPrintLayout, setIsLoggedUser, setScreenLogin } = useContext(UserContext);
    const scriptedRef = useScriptRef();
    const [stepImg, setStepImg] = useState(step1);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [errorCPF, setErroCPF] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);

    const formik = useFormik({
        initialValues: {
            nome: "",
            email: "",
            emailConfirm: "",
            cpf: "",
            telefone: "",
            senha: "",
            senhaConfirm: "",
            unidadeId: 0,
            funcaoId: 0,
            terms1: false,
            terms2: false,
        },
        validationSchema: Yup.object({
            nome: Yup.string()
                .max(200, "O máximo pemitido são 200 caracteres")
                .required("Nome é obrigatório"),
            email: Yup.string()
                .email("Insira um e-mail válido!")
                .max(100, "O máximo requerido são 100 caracteres")
                .required("O E-mail é obrigatório"),
            emailConfirm: Yup.string()
                .oneOf([Yup.ref("email"), null], "Os e-mails não coincidem!")
                .required("A confirmação do e-mail é obrigatório"),
            senha: Yup.string()
                .min(8, "O mínimo permitido são 8 caracteres")
                .max(20, "O máximo requerido são 20 caracteres")
                .required("Senha é obrigatório"),
            senhaConfirm: Yup.string()
                .oneOf([Yup.ref("senha"), null], "As senhas não coincidem!")
                .required("A confirmação da senha é obrigatório"),
            cpf: Yup.string()
                .matches(
                    /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/,
                    "CPF não é válido",
                )
                // .test("cpf", "CPF não é válido", isValidCPF)
                .required("CPF é obrigatório"),
            telefone: Yup.string()
                .test("len", "Número de telefone inválido.", (val = "") => {
                    // only number
                    const val_length_without_dashes = val.replace(
                        /[ ]|-|_|\(|\)/g,
                        "",
                    ).length;
                    return val_length_without_dashes === 11;
                })
                .required("Telefone é obrigatório"),
            unidadeId: Yup.string()
                .test("len", "Escolha uma unidade!", (val = 0) => {
                    return val != 0;
                })
                .required("Unidade é obrigatória"),
            funcaoId: Yup.string()
                .test("len", "Escolha um perfil!", (val = 0) => {
                    return val != 0;
                })
                .required("Unidade é obrigatória"),
            terms1: Yup.boolean()
                .oneOf([true], "O Termo precisa ser aceito")
                .required("Campo obrigatório"),
            terms2: Yup.boolean()
                .oneOf([true], "O Termo precisa ser aceito")
                .required("Campo obrigatório"),
        }),
        onSubmit: async (
            values,
            { setErrors, setStatus, resetForm, setSubmitting },
        ) => {
            try {
                setLoading(true);
                if (scriptedRef.current) {
                    const res = await Api.post("/operador/", values);
                    if (res.status === 200) {
                        setOpenModalConfirm(true);
                        setLoading(false);
                        setStatus({ success: true });
                        setSubmitting(false);
                        resetForm();
                    }
                }
            } catch (err) {
                if (scriptedRef.current) {
                    let message =
                        typeof err.response !== "undefined"
                            ? err.response.data.message
                            : err.message;

                    toast.error(`Ops! ${message.error}`, {
                        toastId: "message",
                    });
                    setLoading(false);
                    setStatus({ success: false });
                    setStatus({ submit: message.error });
                    //CPF
                    if (message.error.search("usuarios.login") >= 0) {
                        setStatus({ submit: "Cpf já cadastrado!" });
                        setErroCPF(true);
                    }
                    //Email
                    if (message.error.search("operadores.email") >= 0) {
                        setStatus({ submit: "E-mail já registrdo" });
                        setErrorEmail(true);
                    }
                    //Fix: Validation = false
                    values.senhaConfirm = "";
                }
                setOpenModalConfirm(false);
            }
        },
    });

    const stepsData = [
        {
            label: "",
            img: step1,
            step: <Step1 formik={formik} />,
        },
        {
            label: "",
            img: step2,
            step: <Step2 formik={formik} />,
        },
        {
            label: "",
            img: step3,
            step: <Step3 formik={formik} />,
        },
    ];

    //Fix Layout Public
    useEffect(() => {
        setIsLoggedUser(false);
        setPrintLayout(true);
        setScreenLogin(true);
    }, []);
    useEffect(() => {
        //CPF Erro
        if (errorCPF) {
            setTimeout(() => {
                formik.setErrors({ cpf: formik.status?.submit });
                setErroCPF(false);
            }, 500);
        }
    }, [errorCPF]);

    useEffect(() => {
        //Email Erro
        if (errorEmail) {
            setTimeout(() => {
                formik.setErrors({ email: formik.status?.submit });
                setErrorEmail(false);
            }, 500);
        }
    }, [errorEmail]);

    return (
        <>
            <dvi className={styles.containerGeral}>
                <div className={styles.containerImage}>
                    <img src={stepImg} alt="cadastro" title="cadastro" className={styles.loginImg} />
                </div>
                <div>
                    <form className={styles.containerStepper} onSubmit={formik.handleSubmit} autoComplete="off">
                        {/* slide forms */}
                        <HorStepper
                            setStepImg={setStepImg}
                            isValid={formik.isValid}
                            stepsData={stepsData}
                            setStepActive={errorCPF || errorEmail}
                            formik={formik}
                        />                            
                    </form>
                    {/* </Box> */}
                </div>
            </dvi>
            <ModalConfirmCustom
                title="Cadastro realizado"
                description="Nossa equipe irá validar o seu cadastro de operador. 
                Assim que possível, entraremos em contato. Por favor, 
                fique atento ao seu e-mail."
                openModal={openModalConfirm}
                onClick={() => setOpenModalConfirm(false)}
                color="blue"
                labelConfirm="Ok"
                labelXSair="nao"
                textAlign="left"
                widthButton="400px"
                heightButton="56px"
            />
        </>
    );
};

export default CadastroOperadorPage;
