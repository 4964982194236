import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./styles.css";
import { Button, Input } from "@mui/material";
import Icon from "react-icons-kit";
import { ic_keyboard_arrow_right_outline } from "react-icons-kit/md/ic_keyboard_arrow_right_outline";
import { ic_keyboard_arrow_left_outline } from "react-icons-kit/md/ic_keyboard_arrow_left_outline";

const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
};

export default function SinglePagePDFViewer(props) {
    const [file, setFile] = useState();
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    // pdfjs.GlobalWorkerOptions.workerSrc = "./pdf.worker.js";
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onFileChange(event) {
        setFile(event.target.files[0]);
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }
    React.useEffect(() => {
        if (props.file) setFile(props.file);
    }, [props.file]);
    return (
        <>
            <div className="pdf">
                <header>
                    Página {pageNumber || (numPages ? 1 : "--")} /{" "}
                    {numPages || "--"}
                    <span> </span>
                    <Button
                        size="small"
                        variant="string"
                        disabled={pageNumber <= 1}
                    >
                        <Icon
                            size={32}
                            icon={ic_keyboard_arrow_left_outline}
                            onClick={previousPage}
                        />
                    </Button>
                    <Button
                        size="small"
                        variant="string"
                        color="success"
                        disabled={pageNumber >= numPages}
                    >
                        <Icon
                            size={32}
                            icon={ic_keyboard_arrow_right_outline}
                            onClick={nextPage}
                        />
                    </Button>
                </header>
            </div>
            <div className="pdf__container">
                <div className="pdf__container__document">
                    <Document
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={options}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <div hidden={props.file !== ""}>
                        <label htmlFor="contained-button-file">
                            <Input
                                accept="application/pdf"
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={onFileChange}
                            />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}
