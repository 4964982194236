import Rating from "@mui/material/Rating";
import React from "react";
import ImageStarOff from "../../assets/images/icons/star_1.png";
import ImageStarOn from "../../assets/images/icons/star_2.png";

export const MyRating = ({ value }) => {
    return <Rating name="read-only" value={value} readOnly />;
};

export function RatingCustom({
    disabled,
    evaluation = 0,
    chooseEvaluation,
    width = 40,
    height = 40,
    marginLeft = 0,
    marginLeftDivider = 60,
    marginBottom = 0,
    marginTop = 0,
}) {
    return (
        <div
            style={{
                marginLeft: marginLeft,
                marginBottom: marginBottom,
                marginTop: marginTop,
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
            }}
        >
            {[1, 2, 3, 4, 5].map((value) => {
                return (
                    <div
                        key={value.toString()}
                        onClick={
                            disabled ? () => {} : () => chooseEvaluation(value)
                        }
                    >
                        <img
                            src={
                                evaluation >= value ? ImageStarOn : ImageStarOff
                            }
                            style={{
                                width: width,
                                height: height,
                            }}
                        />
                        <div
                            style={{
                                marginLeft: marginLeftDivider,
                                backgroundColor: "red",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
}
