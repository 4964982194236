import React from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import HistoricoTableCliente from "../../../components/tables/historico/cliente/";
import { HistoricoClienteProvider } from "../../../contexts/service";
import SearchHistoricoCliente from "./../../../components/search/historico/cliente/";

//Documents
export default function Section6() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    return (
        <HistoricoClienteProvider>
            <Container fluid className="justify-content-center">
                <SearchHistoricoCliente idQuery={idQuery} />
                <HistoricoTableCliente idQuery={idQuery} />
            </Container>
        </HistoricoClienteProvider>
    );
}
