import { Text, View } from "@react-pdf/renderer";
import React from "react";

export default function FooterPDF({ styles }) {
    return (
        <View style={styles.footer} fixed>
            <Text
                style={{
                    width: 400,
                    color: "#343B40",
                    fontSize: 9,
                    textAlign: "center",
                }}
            >
                SECRETARIA DO TRABALHO, EMPREGO, RENDA E ESPORTE DA BAHIA 2ª
                Avenida Centro Administrativo da Bahia, 200 - Salvador/BA - CEP
                41745-003 Telefone:(71) 3115-3247
            </Text>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                }
                fixed
            />
        </View>
    );
}
