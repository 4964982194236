import moment from "moment";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { CpfCnpjMaskRegex, TelMaskRegex } from "../../../../utils/mask";
import { ModalConfirm, ModalGeneric, ModalLoading } from "../../../modal";
import ResultNotFound from "../../../resultNotFound";
import styles from "../restricao.module.scss";
import { ServiceContext } from "./../../../../contexts/service";
import { Api } from "./../../../../providers";
import { ListTable } from "./listTable";

export const RestricaoTableCliente = ({ idQuery }) => {
    const {
        restProf,
        setRestProf,
        loading,
        setLoading,
        totalPages,
        setTotalPages,
        totalElements,
        setTotalElements,
        paramsFilter,
    } = useContext(ServiceContext);

    const [modalRemove, setModalRemove] = useState(false);
    const [observation, setObservation] = useState("");
    const [newRestriction, setNewRestriction] = useState(false);
    const [statusButton, setStatusButton] = useState(true);
    const [viewData, setViewData] = useState([]);
    const [statusViewData, setStatusViewData] = useState(false);
    const [selectedProf, setSelectedProf] = useState(0);
    const [idProf, setIdProf] = useState(0);
    const [requesterData, setRequesterData] = useState([]);
    const [reasonData, setReasonData] = useState([]);
    const [requesterId, setRequesterId] = useState("");
    const [reasonId, setReasonId] = useState(0);
    const [textObsError, setTextObsError] = useState("Campo obrigatório");
    const [textReqError, setTextReqError] = useState("Campo obrigatório");
    const [textReaError, setTextReaError] = useState("Campo obrigatório");
    const [currentDateTime, setCurrentDateTime] = useState("");
    const [profRestData, setProfRestData] = useState([]);
    const [nameProf, setNameProf] = useState("");
    const [loadingModal, setLoadingModal] = useState(false);
    const [checkSave, setCheckSave] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageModal, setCurrentPageModal] = useState(1);
    const [totalPagesModal, setTotalPagesModal] = useState(0);
    const itemsPerPage = 4;

    let mounted = true;
    const operador = JSON.parse(localStorage.getItem("user"));

    const resultData = () => {
        Api.get(`/restricao/cliente/${idQuery}/pagination/0/${itemsPerPage}`)
            .then((res) => {
                if (mounted && res.status === 200) {
                    setRestProf(res.data.content);
                    setTotalPages(res.data.totalPages);
                    setTotalElements(res.data.totalElements);
                    setLoading(false);
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoading(false);
                    toast.error("Ops! Aconteceu algum problema!", {
                        toastId: "msg",
                    });
                }
            });
    };

    const resultRequest = () => {
        Api.get(`/restricao/solicitante`).then((res) => {
            if (mounted && res.status === 200) {
                setRequesterData(res.data);
            }
        });
    };

    useEffect(() => {
        resultData();
        resultRequest();
    }, [checkSave]);

    useEffect(() => {
        Api.get("/system/date").then((res) => {
            if (mounted) {
                setCurrentDateTime(res.data.dateBr + " " + res.data.hoursBr);
            }
        });
        return () => {
            mounted = false;
        };
    }, [newRestriction]);

    useEffect(() => {
        setCurrentPage(1);
    }, [paramsFilter]);

    const nextPage = (page = 0) => {
        setLoading(true);
        const params = paramsFilter;
        Api.get(
            `/restricao/cliente/${idQuery}/pagination/${page}/${itemsPerPage}?`,
            {
                params,
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                },
            },
        )
            .then((res) => {
                setRestProf(res.data.content);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setLoading(false);
            })
            .catch((_) => {
                setLoading(false);
            });
    };

    const pageCountData = totalPages;

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    const handleRemove = () => {
        setModalRemove(false);
        setLoading(true);
        Api.delete(`/restricao/cliente/${idQuery}/profissional/${selectedProf}`)
            .then((res) => {
                if (mounted && res.status === 200) {
                    resultData();
                    toast.success("Profissional removido com sucesso", {
                        toastId: "success",
                    });
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoading(false);
                    toast.error("Ops! Aconteceu algum problema!", {
                        toastId: "msg",
                    });
                }
            });
    };

    const handleRestriction = () => setNewRestriction(true);

    const submitData = () => {
        setLoadingModal(true);
        const dataSend = {
            observacao: observation,
            solicitante: requesterId,
            motivoId: reasonId,
            profissionalId: idProf,
        };
        Api.post(`/restricao/cliente/${idQuery}`, dataSend)
            .then((res) => {
                if (mounted && res.status === 200) {
                    setObservation("");
                    setRequesterId("");
                    setNameProf("");
                    setReasonId(0);
                    setIdProf(0);
                    setStatusButton(true);
                    setProfRestData([]);
                    setTotalPagesModal(0);
                    setCurrentPageModal(1);
                    setReasonData([]);
                    setLoadingModal(false);
                    setNewRestriction(false);
                    setCheckSave(!checkSave);
                    toast.success("Profissional adicionado com sucesso", {
                        toastId: "success",
                    });
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoadingModal(false);
                    toast.error("Ops! Aconteceu algum problema!", {
                        toastId: "msg",
                    });
                }
            });
    };

    const handleSelected = (id) => {
        const resultId = Number(id);
        if (resultId > 0) {
            setIdProf(resultId);
            if (observation.length > 0 && reasonId > 0) {
                setStatusButton(false);
            } else {
                setStatusButton(true);
            }
        } else {
            setStatusButton(true);
        }
    };

    const resultReason = (option) => {
        Api.get(`/restricao/motivo?solicitante=${option}`).then((res) => {
            if (mounted && res.status === 200) {
                setReasonData(res.data);
            }
        });
    };

    const checkRequester = (text) => {
        const checkOption = ["Cliente", "Profissional"];
        if (checkOption.includes(text)) {
            setReasonData([]);
            setRequesterId(text);
            resultReason(text);
            setTextReqError("");
        } else {
            setReasonData([]);
            setRequesterId("");
            setReasonId(0);
            setTextReqError("Campo obrigatório");
        }
        setStatusButton(true);
        setTextReaError("Campo obrigatório");
    };

    const checkReason = (event) => {
        const resultId = Number(event.target.value);
        if (resultId > 0) {
            setReasonId(resultId);
            if (observation.length > 0 && idProf > 0) {
                setStatusButton(false);
            }
            setTextReaError("");
        } else {
            setStatusButton(true);
            setTextReaError("Campo obrigatório");
        }
    };

    function textObs(desc) {
        if (desc.length <= 0) {
            setObservation("");
            setTextObsError("Campo obrigatório");
            setStatusButton(true);
        } else {
            if (desc.length <= 200 && desc.trim()) {
                setObservation(desc);
                setTextObsError("");
                if (reasonId > 0 && idProf > 0) {
                    setStatusButton(false);
                } else {
                    setStatusButton(true);
                }
            }
        }
    }

    const handleProf = (event) => {
        event.preventDefault();
        if (nameProf.length <= 0 && !nameProf.trim()) {
            return;
        }
        setLoadingModal(true);
        Api.get(
            `/restricao/tarefa/cliente/${idQuery}/pagination/0/${itemsPerPage}?fields=${nameProf}&data=`,
        )
            .then((res) => {
                if (mounted && res.status === 200) {
                    setStatusButton(true);
                    setIdProf(0);
                    setProfRestData(res.data.content);
                    setTotalPagesModal(res.data.totalPages);
                    setCurrentPageModal(1);
                    setLoadingModal(false);
                }
            })
            .catch((_) => {
                if (mounted) {
                    setStatusButton(true);
                    setIdProf(0);
                    setLoadingModal(false);
                }
            });
    };

    const nextPageModal = (page = 0) => {
        setLoadingModal(true);
        Api.get(
            `/restricao/tarefa/cliente/${idQuery}/pagination/${page}/${itemsPerPage}?fields=${nameProf}&data=`,
        )
            .then((res) => {
                setProfRestData(res.data.content);
                setTotalPagesModal(res.data.totalPages);
                setLoadingModal(false);
            })
            .catch((_) => {
                setLoadingModal(false);
            });
    };

    const handlePageClickModal = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPageModal(total);
        nextPageModal(event.selected);
    };

    const inputSearch = (text) => {
        if (text.length > 0 && text.trim()) {
            setNameProf(text);
        } else {
            setNameProf("");
        }
    };

    const closeModal = (status) => {
        setNewRestriction(status);
        setProfRestData([]);
        setTotalPagesModal(0);
        setCurrentPageModal(1);
        setReasonData([]);
        setObservation("");
        setRequesterId("");
        setNameProf("");
        setReasonId(0);
        setIdProf(0);
        setStatusButton(true);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    //Get Rows
    return (
        <>
            <div className={styles.containerTable}>
                <span onClick={() => handleRestriction()}>Nova restrição</span>
                <span>Lista de restrições ({totalElements})</span>
                {restProf.length > 0 && (
                    <ListTable
                        setModalRemove={setModalRemove}
                        currentItems={restProf}
                        setStatusViewData={setStatusViewData}
                        setViewData={setViewData}
                        setSelectedProf={setSelectedProf}
                    />
                )}

                {restProf.length <= 0 && <ResultNotFound />}

                <div className={styles.reactPaginate}>
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCountData}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pageItem}
                        pageLinkClassName={"page-link"}
                        previousLinkClassName={styles.pageLink}
                        nextClassName={styles.pageItem}
                        nextLinkClassName={styles.pageLink}
                        activeClassName={"active"}
                        forcePage={currentPage - 1}
                        disabledClassName={styles.disabled}
                    />
                </div>
            </div>

            <ModalGeneric
                styles={{
                    minHeight: "578px",
                    width: "886px",
                }}
                title="Nova restrição"
                description=""
                openModal={newRestriction}
                setOpenModal={closeModal}
                onClick={() => submitData()}
                labelConfirm="Restringir"
                backgroundCacel="#fff"
                color={statusButton ? "" : "blue"}
                disabled={statusButton}
                btnCancel={true}
                btnConfirm={true}
            >
                <Container
                    className={`justify-content-end ${styles.containerModal}`}
                >
                    <div className="d-flex justify-content-center align-items-center container mb-4 p-2">
                        <div className={styles.boxModal}>
                            <div className={`${styles.containerInput}`}>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            inputSearch(e.target.value)
                                        }
                                        value={nameProf}
                                        type="text"
                                        className={`form-control ${styles.inputSearchRestriction}`}
                                        title="Nome do profissional..."
                                        placeholder="Nome do profissional..."
                                        aria-label="Nome do profissional..."
                                    />
                                </div>
                                <div>
                                    <button
                                        className={
                                            nameProf.length <= 0
                                                ? styles.buttonSearchBlock
                                                : styles.buttonSearch
                                        }
                                        type="submit"
                                        onClick={handleProf}
                                        disabled={
                                            nameProf.length <= 0 ? true : false
                                        }
                                    >
                                        Pesquisar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`justify-content-center align-items-center container mb-4 p-10 table-responsive ${styles.tableForm}`}
                    >
                        {profRestData.length > 0 && (
                            <table
                                className={`table align-middle ${styles.table}`}
                            >
                                <thead>
                                    <tr>
                                        <th>
                                            <div>
                                                <span />
                                            </div>
                                        </th>
                                        <th>Profissional</th>
                                        <th>Telefone</th>
                                        <th>CPF/CNPJ</th>
                                    </tr>
                                </thead>
                                <tbody
                                    data-test="table-body"
                                    className={styles.tbodyModal}
                                >
                                    {profRestData.length > 0 &&
                                        profRestData.map((val) => {
                                            return (
                                                <tr
                                                    onClick={() =>
                                                        handleSelected(
                                                            val.profissionais[0]
                                                                .id,
                                                        )
                                                    }
                                                    className={
                                                        idProf ===
                                                        val.profissionais[0].id
                                                            ? styles.textSelected
                                                            : styles.textNoSelected
                                                    }
                                                >
                                                    <td>
                                                        {idProf ===
                                                        val.profissionais[0]
                                                            .id ? (
                                                            <div
                                                                className={
                                                                    styles.radioCircle
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles.circleBall
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    styles.radCirNoSelected
                                                                }
                                                            />
                                                        )}
                                                    </td>
                                                    <td
                                                        className={
                                                            styles.infoText
                                                        }
                                                    >
                                                        {
                                                            val.profissionais[0]
                                                                .nome
                                                        }
                                                    </td>
                                                    <td
                                                        className={
                                                            styles.infoText
                                                        }
                                                    >
                                                        {val.profissionais[0]
                                                            .telefoneCelular
                                                            ? TelMaskRegex(
                                                                  val
                                                                      .profissionais[0]
                                                                      .telefoneCelular,
                                                              )
                                                            : ""}
                                                        {val.profissionais[0]
                                                            .telefoneContato
                                                            ? ` / ${TelMaskRegex(
                                                                  val
                                                                      .profissionais[0]
                                                                      .telefoneContato,
                                                              )}`
                                                            : ""}
                                                    </td>
                                                    <td
                                                        className={
                                                            styles.infoText
                                                        }
                                                    >
                                                        {`${CpfCnpjMaskRegex(
                                                            val.profissionais[0]
                                                                .cpf,
                                                        )}`}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        )}

                        {profRestData.length <= 0 && <ResultNotFound />}

                        <div className={styles.reactPaginateModal}>
                            <ReactPaginate
                                breakLabel="..."
                                onPageChange={handlePageClickModal}
                                pageRangeDisplayed={5}
                                pageCount={totalPagesModal}
                                previousLabel="<"
                                nextLabel=">"
                                renderOnZeroPageCount={null}
                                marginPagesDisplayed={2}
                                containerClassName={styles.pagination}
                                pageClassName={styles.pageItem}
                                pageLinkClassName={"page-link"}
                                previousLinkClassName={styles.pageLink}
                                nextClassName={styles.pageItem}
                                nextLinkClassName={styles.pageLink}
                                activeClassName={"active"}
                                forcePage={currentPageModal - 1}
                                disabledClassName={styles.disabled}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center container mb-4 p-2">
                        <div className={styles.boxModal}>
                            <div className={`${styles.containerSelect}`}>
                                <div
                                    className={
                                        textReqError
                                            ? styles.formGroupError
                                            : ""
                                    }
                                >
                                    <div className={styles.boxCommentText}>
                                        <Form.Label>Solicitante</Form.Label>
                                    </div>
                                    <select
                                        className={`form-select ${styles.formDropdown}`}
                                        onChange={(e) =>
                                            checkRequester(e.target.value)
                                        }
                                    >
                                        <option value="" selected>
                                            Selecione o solicitante
                                        </option>
                                        {requesterData.length > 0 &&
                                            requesterData.map((data) => (
                                                <option key={data} value={data}>
                                                    {data}
                                                </option>
                                            ))}
                                    </select>
                                    <span className={styles.requiredField}>
                                        {textReqError}
                                    </span>
                                </div>
                                <div
                                    className={
                                        textReaError
                                            ? styles.formGroupError
                                            : ""
                                    }
                                >
                                    <div className={styles.boxCommentText}>
                                        <Form.Label>Motivo</Form.Label>
                                    </div>
                                    <select
                                        className={`form-select ${styles.formDropdown}`}
                                        onClick={checkReason}
                                    >
                                        <option value="0" selected>
                                            Selecione o motivo
                                        </option>
                                        {reasonData.length > 0 &&
                                            reasonData.map((data) => (
                                                <option
                                                    key={data.nome}
                                                    value={data.id}
                                                >
                                                    {data.nome}
                                                </option>
                                            ))}
                                    </select>
                                    <span className={styles.requiredField}>
                                        {textReaError}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Form.Group className="d-flex justify-content-center align-items-center container mb-4 p-2">
                        <div className={styles.boxModal}>
                            <div className={styles.boxCommentText}>
                                <Form.Label>Observação</Form.Label>
                            </div>
                            <div
                                className={
                                    textObsError ? styles.formGroupError : ""
                                }
                            >
                                <Form.Control
                                    className={styles.textAreaComment}
                                    as="textarea"
                                    placeholder="Observação"
                                    rows={3}
                                    name="descricao"
                                    value={observation}
                                    onChange={(e) => textObs(e.target.value)}
                                />
                            </div>
                            <span className={styles.requiredField}>
                                {textObsError}
                            </span>
                            <div className="d-flex justify-content-between mt-2 mb-3">
                                <div className={styles.textOperator}>
                                    Operador {operador.operador.nome} -{" "}
                                    {currentDateTime}
                                </div>
                                <div>
                                    <span className={styles.characterNumber}>
                                        {observation.length}
                                    </span>
                                    <span className={styles.characterLetter}>
                                        /200 caracteres
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Container>
            </ModalGeneric>

            <ModalGeneric
                styles={{
                    minHeight: "578px",
                    width: "886px",
                }}
                title={viewData?.profissional}
                description=""
                openModal={statusViewData}
                setOpenModal={setStatusViewData}
                onClick={() => {}}
                labelCancel="Fechar"
                backgroundCacel="#fff"
                disabled={statusButton}
                btnCancel={true}
            >
                <Container className={`justify-content-end`}>
                    <div className="d-flex justify-content-center align-items-center container mb-4 p-2">
                        <div className={styles.boxModal}>
                            <div className={`${styles.containerSelect}`}>
                                <div>
                                    <div className={styles.boxCommentText}>
                                        <Form.Label>Solicitante</Form.Label>
                                    </div>
                                    <select
                                        className={`form-select ${styles.formDropdownView}`}
                                        disabled={true}
                                    >
                                        <option selected>
                                            {viewData?.solicitante}
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <div className={styles.boxCommentText}>
                                        <Form.Label>Motivo</Form.Label>
                                    </div>
                                    <select
                                        className={`form-select ${styles.formDropdownView}`}
                                        disabled={true}
                                    >
                                        <option selected>
                                            {viewData?.motivo}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Form.Group className="d-flex justify-content-center align-items-center container mb-4 p-2">
                        <div className={styles.boxModal}>
                            <div className={styles.boxCommentText}>
                                <Form.Label>Observação</Form.Label>
                            </div>
                            <Form.Control
                                className={styles.textAreaCommentView}
                                as="textarea"
                                placeholder="Observação"
                                rows={3}
                                name="descricao"
                                value={viewData?.observacao}
                                onChange={() => {}}
                                disabled={true}
                            />
                            <div className="d-flex justify-content-between mt-2 mb-3">
                                <div className={styles.textOperator}>
                                    Operador {viewData?.usuario} -{" "}
                                    {moment(viewData?.dataCadastro).format(
                                        "DD/MM/YY",
                                    )}{" "}
                                    {moment(viewData?.dataCadastro).format(
                                        "HH:mm",
                                    )}
                                </div>
                                <div>
                                    <span className={styles.characterNumber}>
                                        {viewData?.observacao?.length}
                                    </span>
                                    <span className={styles.characterLetter}>
                                        /200 caracteres
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Container>
            </ModalGeneric>

            <ModalConfirm
                title="Deletar restrição"
                description="Tem certeza que deseja deletar essa restrição?"
                openModal={modalRemove}
                setOpenModal={setModalRemove}
                onClick={() => handleRemove()}
                labelConfirm="Deletar"
                color="red"
                labelXSair="nao"
            />

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};
