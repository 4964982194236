import React, { useState } from "react";
import { Link } from "react-router-dom";
import btedit from "./../../../assets/images/bt-edit.png";
import styles from "./styles.module.scss";

export function ListTable({ currentItems }) {

    const getFlatAtivo = (flag) => {
        if (flag === true) return "Ativo";
        if (flag === false) return "Inativo";
    };

    const getEdit = (
        idCategoria,
    ) => {
        return (
                <Link
                    to={`/tipo-servico/editar?id=${idCategoria}`}
                >
                    <img src={btedit} alt="Editar" />
                </Link>
            );
    };

    const returnStyles = (info) => {
        if (info === true) {
            return styles.Ativo
        } else {
            return styles.Inativo;
        }
    };

    return (
        <div className={`table-responsive ${styles.radiusTable}`}>
            <table className={`table align-middle ${styles.table}`}>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Tipo de serviço</th>
                        <th>Serviço</th>
                        <th>Descrição</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody data-test="table-body" className={styles.tbody}>
                    {currentItems.length > 0 &&
                        currentItems.map((val, idx) => {
                            return (
                                <tr key={idx.toString()} className={styles.estadoCat1}>
                                    <td
                                        className={`${
                                            styles.estadoCat
                                        } ${returnStyles(val.flagAtivo)}`}
                                    >
                                        <span>
                                            {getFlatAtivo(val.flagAtivo)}
                                        </span>
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.nome}
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.servico?.nome}
                                    </td>
                                    <td className={styles.infoTextDesc}>
                                        {val.descricao}
                                    </td>
                                    <td>
                                        {getEdit(
                                            val.id
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}
