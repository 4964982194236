import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import { ModalLoading } from "../../../components/modal";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { ClientesProfissionaisProvider } from "../../../contexts/service";
import { Api } from "../../../providers";
import styles from "./styles.module.scss";

export default function ServicoEmail() {
    const { setCurrentScreen } = useContext(AuthContext);

    const [loadingModal, setLoadingModal] = useState(false);
    const [nomeUsuario, setNomeUsuario] = useState("");
    const [senha, setSenha] = useState("");
    const [provedor, setProvedor] = useState("");
    const [porta, setPorta] = useState("");
    const [encoding, setEncoding] = useState("");
    const [protocolo, setProtocolo] = useState("");
    const [testeConexao, setTesteConexao] = useState(true);
    const [testeTls, setTesteTls] = useState(true);
    const [authSMTP, setAuthSMTP] = useState(true);

    let mounted = true;

    useEffect(() => {
        setCurrentScreen(16);

        function filterData() {
            setLoadingModal(true);
            try {
                Api.get(`/system/email`).then((res) => {
                    if (res.status == 200) {
                        console.log("debug data: ", res.data);
                        setNomeUsuario(res.data.email ?? "");
                        setSenha(res.data.password ?? "");
                        setProvedor(res.data.host ?? "");
                        setPorta(res.data.port ?? "");
                        setEncoding(res.data.encoding ?? "");
                        setProtocolo(res.data.protocol ?? "");
                        setTesteConexao(res.data.connectionTest ?? false);
                        setTesteTls(res.data.tls ?? false);
                        setAuthSMTP(res.data.authSMTP ?? false);
                    }
                });
            } catch (error) {
                toast.error("Ops! Aconteceu algum problema com os filtros!", {
                    toastId: "msg",
                });
            } finally {
                setLoadingModal(false);
            }
        }
        filterData();
        return () => (mounted = false);
    }, []);

    const checkFilterSubmit =
        nomeUsuario !== "" &&
        senha !== "" &&
        provedor !== "" &&
        Number(porta) > 0 &&
        encoding !== "" &&
        protocolo !== ""
            ? false
            : true;

    // ***********************************************************************

    const handleSubmit = () => {
        const payload = {
            email: nomeUsuario,
            password: senha,
            host: provedor,
            port: porta,
            encoding: encoding,
            protocol: protocolo,
            connectionTest: testeConexao,
            tls: testeTls,
            authSMTP: authSMTP,
        };
        setLoadingModal(true);

        Api.post("/system/email", payload)
            .then((_) => {
                toast.success("Ação realizada com sucesso", {
                    toastId: "success",
                });
                setLoadingModal(false);
            })
            .catch((err) => {
                setLoadingModal(false);
                const checkErro = err;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });
    };

    return (
        <ClientesProfissionaisProvider>
            <Grid>
                <BreadCrumb
                    data={{
                        menu: {
                            item: "Configurações",
                            link: "",
                        },
                        page: `Serviço de e-mail`,
                    }}
                />
                <Title title="Serviço de e-mail" />
                {/* **************************************************************** */}

                <div className={styles.containerGeral}>
                    <div className="justify-content-center align-items-center container">
                        <div className={`${styles.containerInput}`}>
                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Nome do usuário
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={"Nome do usuário"}
                                            className={styles.inputText}
                                            value={nomeUsuario}
                                            onChange={(e) =>
                                                setNomeUsuario(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Senha
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={"Senha"}
                                            className={styles.inputText}
                                            value={senha}
                                            onChange={(e) =>
                                                setSenha(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Host (provedor)
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={"Host (provedor)"}
                                            className={styles.inputText}
                                            value={provedor}
                                            onChange={(e) =>
                                                setProvedor(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Porta
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={"Porta"}
                                            className={styles.inputText}
                                            value={porta}
                                            onChange={(e) =>
                                                setPorta(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Encoding
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={"Encoding"}
                                            className={styles.inputText}
                                            value={encoding}
                                            onChange={(e) =>
                                                setEncoding(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Protocolo
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={"Protocolo"}
                                            className={styles.inputText}
                                            value={protocolo}
                                            onChange={(e) =>
                                                setProtocolo(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.containerRowSwitch}>
                                <div className={styles.containerSwitch}>
                                    <span className={styles.textSpanSwitch}>
                                        Teste de conexão
                                    </span>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={testeConexao}
                                            onChange={(e) =>
                                                setTesteConexao(!testeConexao)
                                            }
                                            id={styles.checkbox}
                                        />
                                    </div>
                                </div>

                                <div className={styles.containerSwitch}>
                                    <span className={styles.textSpanSwitch}>
                                        TLS
                                    </span>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={testeTls}
                                            onChange={(e) =>
                                                setTesteTls(!testeTls)
                                            }
                                            id={styles.checkbox}
                                        />
                                    </div>
                                </div>

                                <div className={styles.containerSwitch}>
                                    <span className={styles.textSpanSwitch}>
                                        Autenticação SMTP
                                    </span>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={authSMTP}
                                            onChange={(e) =>
                                                setAuthSMTP(!authSMTP)
                                            }
                                            id={styles.checkbox}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.bntGerarRelat}>
                                <button
                                    className={
                                        checkFilterSubmit
                                            ? styles.buttonRelatBlock
                                            : styles.buttonRelat
                                    }
                                    type="button"
                                    onClick={
                                        checkFilterSubmit
                                            ? () => {}
                                            : () => handleSubmit()
                                    }
                                >
                                    <span>Salvar</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* **************************************************************** */}
            </Grid>

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </ClientesProfissionaisProvider>
    );
}
