import React from "react";
import btedit from "./../../../assets/images/bt-edit.png";
import styles from "./servico.module.scss";

export function ListTable({ currentItems }) {

    const returnStyles = (info) => {
        if (info === true) {
            return styles.Ativo
        } else {
            return styles.Inativo;
        }
    };

    const getFlatAtivo = (flag) => {
        if (flag === true) return "Ativo";
        if (flag === false) return "Inativo";
    };

  return (
    <div className={`table-responsive ${styles.radiusTable}`}>
        <table className={`table align-middle ${styles.table}`}>
            <thead>
                <tr>
                    <th>Status</th>
                    <th>Serviços</th>
                    <th>Categoria</th>
                    <th>Descrição</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody data-test="table-body" className={styles.tbody}>
                  {currentItems.length > 0 ? currentItems.map((val, idx) => {
                    const colorTr = idx % 2 == 0 ? styles.colorTrOne : styles.colorTrTwo;
                    return (
                        <tr className={colorTr} key={val.id.toString()}>
                            <td
                                className={`${
                                    styles.estadoCat
                                } ${returnStyles(val.flagAtivo)}`}
                            >
                                <span>
                                    {getFlatAtivo(val.flagAtivo)}
                                </span>
                            </td>
                            <td className={styles.infoText}>{val.nome}</td>
                            <td className={styles.infoText}>
                                {val.categoria.nome}
                            </td>
                            <td className={styles.infoTextDesc}>
                                {val.descricao.substring(0, 90) + "..."}
                            </td>
                            <td>
                                <a href={`/servico/editar?id=${val.id}`}>
                                    <img src={btedit} alt="Editar" />
                                </a>
                            </td>
                        </tr>
                    )
                }) : <></>}
            </tbody>
        </table>
    </div>
  );
}