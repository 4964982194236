import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import * as React from "react";
import styles from "./styles.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const WIDTHMAX = 400;
const WIDTHMIN = 380;
const HEIGHT = 56;
const FONTSIZE = 14;

const Item = {
    width: WIDTHMIN,
    maxWidth: WIDTHMAX,
    height: HEIGHT,
    margin: "24px 0",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: FONTSIZE,
    lineHeight: "17px",
    alignItems: "center",
    color: "#343B40",
    backgroundColor: "#ffffff",
    "& .MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "17px",
        color: "#343B40",
    },
};

const ItemCustom = {
    backgroundColor: "#ffffff",
    "& .MuiSelect-select": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#343B40",
        width: 400,
    },
    "& .MuiInputBase-root": {
        width: 400,
        borderRadius: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        top: -9,
        border: "1px solid #c7c9d9 !important",
        outline: "none !important",
        boxShadow: "none !important",
    },
};

const MenuProps = {
    PaperProps: {
        style: {
            height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: WIDTHMAX,
        },
    },
};
/**
 * Multibox
 * Important: Do not add {...props}, because it generates errors in some elements
 * @param {*} props
 * @param {id, name} children
 * @returns
 */
export default function MyMultipleSelectCheckmarks(props) {
    const mountArray = (value) => {
        return typeof value === "string" ? value.split(",") : value;
    };
    const mountString = (selected) => {
        return selected.join(", ");
    };

    return (
        <div>
            <FormControl
                variant="outlined"
                sx={props.sx ? props.sx : Item}
                className={styles.customselect}
            >
                <InputLabel shrink id="myMultipleSelectCheck">
                    {props.label}
                </InputLabel>
                <Select
                    className={`${props.addIcon} ${
                        props.addIcon ? styles.formDropdown : ""
                    }`}
                    sx={{ width: WIDTHMIN, height: HEIGHT }}
                    labelId="myMultipleSelectCheck"
                    multiple
                    value={props.value}
                    onChange={({ target: { value } }) => {
                        props.onChange(mountArray(value));
                    }}
                    disabled={props.disabled}
                    input={<OutlinedInput notched label={props.label} />}
                    renderValue={(selected) => mountString(selected)}
                    MenuProps={MenuProps}
                >
                    {props.children.map(({ id, nome }) => {
                        return (
                            <MenuItem key={id} value={nome}>
                                <Checkbox
                                    checked={props.value.indexOf(nome) > -1}
                                />
                                <ListItemText primary={nome} />
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

export function MyMultipleSelectCheckmarksCustom(props) {
    const mountArray = (value) => {
        return typeof value === "string" ? value.split(",") : value;
    };
    const mountString = (selected) => {
        return selected.join(", ");
    };

    const valueData =
        props.value.length >= props.totalUnit
            ? ["Todas unidades"]
            : props.value.filter((val) => val !== "Todas unidades");

    return (
        <div>
            {/* <FormControl variant="outlined" sx={props.sx ? props.sx : Item}> */}
            <FormControl sx={ItemCustom}>
                <InputLabel shrink id="myMultipleSelectCheck">
                    {props.label}
                </InputLabel>
                <Select
                    className={`${props.addIcon} ${
                        props.addIcon ? styles.formDropdown : ""
                    } ${styles.selectInfo} ${
                        props.disabled ? styles.formDropdownDisabled : {}
                    }`}
                    sx={{
                        width: WIDTHMIN,
                        height: HEIGHT,
                        backgroundColor: props.disabled ? "#ecedee" : "#fff",
                    }}
                    labelId="myMultipleSelectCheck"
                    multiple
                    value={valueData}
                    onChange={({ target: { value } }) => {
                        props.onChange(mountArray(value));
                    }}
                    disabled={props.disabled}
                    input={<OutlinedInput notched label={props.label} />}
                    renderValue={(selected) => mountString(selected)}
                    MenuProps={MenuProps}
                >
                    {props.children.map(({ id, nome }) => {
                        return (
                            <MenuItem key={id} value={nome}>
                                <Checkbox
                                    checked={props.value.indexOf(nome) > -1}
                                />
                                <ListItemText primary={nome} />
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

export function MultipleSelectCustom(props) {
    const mountArray = (value) => {
        return typeof value === "string" ? value.split(",") : value;
    };
    const mountString = (selected) => {
        return selected.join(", ");
    };

    return (
        <div>
            <FormControl sx={ItemCustom}>
                <Select
                    className={`${props.addIcon} ${
                        props.addIcon ? styles.formDropdown : ""
                    } ${styles.selectInfo}`}
                    labelId="myMultipleSelectCheck"
                    multiple
                    value={props.value}
                    onChange={({ target: { value } }) => {
                        props.onChange(mountArray(value));
                    }}
                    disabled={props.disabled}
                    input={<OutlinedInput notched label={props.label} />}
                    renderValue={(selected) => mountString(selected)}
                    MenuProps={MenuProps}
                >
                    {props.children.map(({ id, nome }) => {
                        return (
                            <MenuItem key={id} value={nome}>
                                <Checkbox
                                    checked={props.value.indexOf(nome) > -1}
                                />
                                <ListItemText primary={nome} />
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
}
