import React from "react";
import { Box, TextField, MenuItem, FormHelperText } from "@mui/material";
import { PhoneMask } from "../../../utils/mask";
import { Api } from "./../../../providers/index";
import styles from "./styles.module.scss";

const ItemCustomField = {
    "& .MuiOutlinedInput-input": {
        padding: "16.5px 0px",
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiInputBase-input": {
        width: "93%",
    },
    "& .MuiInputBase-root": {
        borderRadius: "8px !important",
    },
    // "& .MuiOutlinedInput-notchedOutline": {
    //     border: "1px solid #C7C9D9 !important",
    //     borderColor: "#C7C9D9 !important",
    // },
};

const ItemCustom = {
    // width: "400px !important",
    // height: "56px !important",
    // borderRadius: "8px !important",
    // "& .MuiOutlinedInput-input": {
    //     padding: "16.5px 14px",
    //     color: "#B3B5B6",
    //     fontFamily: "Inter",
    //     fontWeight: 400,
    //     fontSize: "16px",
    //     lineHeight: "19.36px",
    //     zIndex: 2,
    // },
    // "& .MuiOutlinedInput-notchedOutline": {
    //     borderRadius: "8px !important",
    // },
    "& .MuiSvgIcon-root": {
        display: "none",
    },    
};

export default function Step2({ formik }) {
    const [unidades, setUnidades] = React.useState([]);
    const [funcoes, setFuncoes] = React.useState([]);
    React.useEffect(() => {
        Api.get("/unidade/").then((res) => {
            if (res.status === 200) setUnidades(res.data);
        });
        Api.get("/operador-funcao/").then((res) => {
            if (res.status === 200) setFuncoes(res.data);
        });

        return () => {
            setUnidades([]);
            setFuncoes([]);
        };
    }, []);
    return (
        <div className={styles.containerCadastro}>
            <div className={`${styles.containerFieldSelect}`}>
                <div>
                    <span>Unidade</span>
                    <TextField
                        error={Boolean(
                            formik.touched.unidadeId && formik.errors.unidadeId,
                        )}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.unidadeId}
                        fullWidth
                        name="unidadeId"
                        id="unidadeId"
                        variant="outlined"
                        select                        
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={ItemCustom}
                        className={`${styles.formDropdown} step2Cadast`}
                    >
                        <MenuItem key={0} value={0} disabled>
                            Selecione a sua unidade
                        </MenuItem>
                        {unidades.map(({ id, nome }) => (
                            <MenuItem key={id} value={id}>
                                {nome}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div>
                    {Boolean(formik.touched.unidadeId && formik.errors.unidadeId) && (
                        <FormHelperText error>
                            {formik.errors.unidadeId}
                        </FormHelperText>
                    )}
                </div>
            </div>

            <div className={`${styles.containerFieldSelect} ${styles.dividerSpace}`}>
                <div>
                    <span>Perfil</span>
                    <TextField
                        error={Boolean(
                            formik.touched.funcaoId && formik.errors.funcaoId,
                        )}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.funcaoId}
                        fullWidth
                        name="funcaoId"
                        id="funcaoId"
                        variant="outlined"
                        select
                        sx={ItemCustom}
                        className={`${styles.formDropdown}`}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                        <MenuItem key={0} value={0} disabled>
                            Selecione o seu perfil
                        </MenuItem>
                        {funcoes.map(({ id, nome }) => (
                            // slug
                            <MenuItem key={id} value={id}>
                                {nome}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div>
                    {Boolean(formik.touched.funcaoId && formik.errors.funcaoId) && (
                        <FormHelperText error>
                            {formik.errors.funcaoId}
                        </FormHelperText>
                    )}
                </div>
            </div>

            <div className={`${styles.containerTextField} ${styles.dividerSpace}`}>
                <div>
                    <span>Telefone para contato</span>
                    <TextField
                        error={Boolean(
                            formik.touched.telefone && formik.errors.telefone,
                        )}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.telefone}
                        fullWidth
                        sx={ItemCustomField}
                        className={styles.inputTextField}
                        name="telefone"
                        id="telefone"
                        placeholder="(00) 00000-0000"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputComponent: PhoneMask,
                        }}
                    />
                </div>
                <div>
                    {Boolean(formik.touched.telefone && formik.errors.telefone) && (
                        <FormHelperText error>
                            {formik.errors.telefone}
                        </FormHelperText>
                    )}
                </div>
            </div>
        </div>
    );
}
