import { Box, TextField } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import MyDatePicker from "../../../components/datepicker";
import { MultiSelectDropdownCustom } from "../../../components/dropdown/multiSelectDropdown";
import HourPick from "../../../components/hora";
import { ModalLoading } from "../../../components/modal";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { useUnidades } from "../../../hooks/useScriptRef";
import { Api } from "../../../providers";
import { FormatDate } from "../../../utils/date";
import styles from "./criarTreinamento.module.scss";

const BoxData = ({ children }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                background: "#F7F7F7",
                borderRadius: "8px",
            }}
        >
            {children}
        </Box>
    );
};

const ItemCustomDate = {
    width: "400px !important",
    height: "56px !important",
    borderRadius: "8px !important",
    "& .MuiInputBase-root": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
        height: "56px !important",
    },
    "& .MuiOutlinedInput-input": {
        color: "#343B40",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "& .MuiSvgIcon-root": {
        zIndex: 2,
        color: "#0064B4",
        display: "none",
    },
};

const ItemCustom = {
    width: "400px !important",
    height: "56px !important",
    borderRadius: "8px !important",
    "& .MuiInputBase-root": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
        height: "56px !important",
    },
    "& .MuiOutlinedInput-input": {
        color: "#343B40",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "& .MuiSvgIcon-root": {
        zIndex: 2,
        color: "#0064B4",
    },
};

const ItemCustomFieldDisabled = {
    width: "400px !important",
    height: "56px !important",
    borderRadius: "8px !important",
    "& .MuiInputBase-root": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
        height: "56px !important",
    },
    "& .MuiOutlinedInput-input": {
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "& .MuiInputBase-input": {
        width: "93.47%",
    },
};

let totalVacancy = 0;
const CriarTreinamento = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const [data, setData] = useState("");
    const [numeroVagas, setNumeroVagas] = useState("");
    const [inicio, setInicio] = useState("");
    const [termino, setTermino] = useState("");
    const [modalidade, setModalidade] = useState("Online");
    const [local, setLocal] = useState("");
    const [operador, setOperador] = useState("");
    const [operadorIdt, setOperadorIdt] = useState("");
    const [unidadeID, setUnidadeID] = useState([]);
    const [statusButton, setStatusButton] = useState(true);
    const [dateError, setDateError] = useState(false);
    const [vagueError, setVagueError] = useState(false);
    const [startTimeError, setStartTimeError] = useState(false);
    const [endTimeError, setEndTimeError] = useState(false);
    const [modalityError, setModalityError] = useState(false);
    const [localError, setLocalError] = useState(false);
    const [unitError, setUnitError] = useState(false);
    const [messageBackend, setMessageBackend] = useState("");
    const [startTimeText, setStartTimeText] = useState("");
    const [endTimeText, setEndTimeText] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [currentDateForm, setCurrentDateForm] = useState("");
    const [currentTime, setCurrentTime] = useState(0);
    const [serverUnit, setServerUnit] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);

    const unidadesMultiSelect = useUnidades({ multiSelect: true });
    const unidadesServer = useUnidades();

    let mounted = true;

    const unidadeData = () => {
        let unidadesTemp = [];
        const _ = unidadesServer.filter((item) => {
            unidadesTemp.push(item.nome);
        });
        setServerUnit(unidadesTemp);
    };

    useEffect(() => {
        setCurrentScreen(8);
        unidadeData();
    }, [unidadesServer]);

    useEffect(() => {
        Api.get("/system/date").then((res) => {
            if (mounted) {
                const checkTime = Number(res.data.hoursBr.replace(":", ""));
                setCurrentTime(checkTime);
                setCurrentDate(res.data.date);
            }
        });
        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        const idDoOperador = JSON.parse(localStorage.getItem("user"));
        Api.get("/operador/filter?usuarioId=" + idDoOperador.usuario?.id).then(
            (res) => {
                if (mounted) {
                    setOperador(res.data[0]?.nome);
                    setOperadorIdt(res.data[0]?.id);
                }
            },
        );
        return () => {
            mounted = false;
        };
    }, []);

    const handleSelecionarTodasAsUnidades = (completar) => {
        let unidadesTemporarias = [];
        if (completar) {
            unidadesMultiSelect.filter((item) => {
                unidadesTemporarias.push(item.nome);
            });
        }
        setUnidadeID(unidadesTemporarias);
    };

    const handleSubmit = () => {
        if (statusButton) {
            return;
        }

        if (data && inicio && termino) {
            const dateForm = moment(data, "YYYY-MM-DD").valueOf();
            const dateCurrent = moment(currentDate, "YYYY-MM-DD").valueOf();
            if (dateForm === dateCurrent) {
                const checkEndTime = Number(moment(termino).format("HHmm"));
                if (currentTime >= checkEndTime) {
                    setMessageBackend(
                        "O horário de término não pode ser igual ou anterior ao atual, pois a data é referente a data atual!",
                    );
                    return;
                }
            }
        }

        let unidadeSelecionadas = [];
        unidadeID.filter((item) => {
            unidadesMultiSelect.filter((uOff) => {
                if (uOff.nome === item && uOff.id !== 0) {
                    unidadeSelecionadas.push(uOff);
                }
            });
        });

        const myData = {
            local: local,
            dataHoraInicio:
                FormatDate(data) +
                " " +
                String(inicio.getHours()).padStart(2, "0") +
                ":" +
                String(inicio.getMinutes()).padStart(2, "0") +
                ":00",
            dataHoraFim:
                FormatDate(data) +
                " " +
                String(termino.getHours()).padStart(2, "0") +
                ":" +
                String(termino.getMinutes()).padStart(2, "0") +
                ":00",
            vagas: totalVacancy,
            modalidade: modalidade,
            operadorId: operadorIdt,
            unidades: unidadeSelecionadas,
        };

        setLoadingModal(true);

        Api.post("/treinamento/", myData)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Cadastrado com sucesso", {
                        toastId: "success",
                    });
                }
                setLoadingModal(false);
                setMessageBackend("");
            })
            .catch((error) => {
                const checkErro = error;
                let mensagenErro = "";
                if (checkErro.response.data.status == 500) {
                    mensagenErro = checkErro.response.data.error;
                } else {
                    mensagenErro = checkErro.response.data.message?.error;
                }
                toast.error(`Ops! ${mensagenErro}`, {
                    toastId: "msg",
                });
                setLoadingModal(false);
                setMessageBackend(mensagenErro);
            });
    };

    function isValidURL(string) {
        let res = string.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        );
        return res !== null;
    }

    const checkFields = () => {
        let statusBtn1 = true;
        let statusBtn2 = true;
        let statusBtn3 = true;
        let statusBtn4 = true;
        let statusBtn5 = true;
        let statusBtn6 = true;
        let statusBtn7 = true;
        let statusBtn8 = true;

        if (unidadeID.length <= 0) {
            setUnitError(true);
        } else {
            setUnitError(false);
            statusBtn1 = false;
        }

        if (!data) {
            setDateError(true);
        } else {
            setDateError(false);
            statusBtn2 = false;
        }

        if (numeroVagas <= 0) {
            setVagueError(true);
        } else {
            setVagueError(false);
            statusBtn3 = false;
        }

        if (!inicio) {
            setStartTimeError(true);
        } else {
            setStartTimeError(false);
            statusBtn4 = false;
        }

        if (!termino) {
            setEndTimeError(true);
        } else {
            setEndTimeError(false);
            statusBtn5 = false;
        }

        if (!modalidade) {
            setModalityError(true);
        } else {
            setModalityError(false);
            statusBtn6 = false;
        }

        if (!local || !local.trim()) {
            setLocalError(true);
        } else {
            if (modalidade === "Online") {
                if (isValidURL(local)) {
                    setLocal(local);
                    setLocalError(false);
                    statusBtn7 = false;
                } else {
                    setLocal(local);
                    setLocalError(true);
                }
            } else {
                setLocalError(false);
                statusBtn7 = false;
            }
        }

        if (data && inicio && termino) {
            const dateForm = moment(data, "YYYY-MM-DD").valueOf();
            const dateCurrent = moment(currentDate, "YYYY-MM-DD").valueOf();
            if (dateForm === dateCurrent) {
                const checkEndTime = Number(moment(termino).format("HHmm"));
                if (currentTime >= checkEndTime) {
                    setMessageBackend(
                        "O horário de término não pode ser igual ou anterior ao atual, pois a data é referente a data atual!",
                    );
                } else {
                    statusBtn8 = false;
                    setMessageBackend("");
                }
            } else {
                statusBtn8 = false;
                setMessageBackend("");
            }
        } else {
            statusBtn8 = false;
            setMessageBackend("");
        }

        let btnStatus = true;
        if (
            !statusBtn1 &&
            !statusBtn2 &&
            !statusBtn3 &&
            !statusBtn4 &&
            !statusBtn5 &&
            !statusBtn6 &&
            !statusBtn7 &&
            !statusBtn8
        ) {
            btnStatus = false;
        }

        setStatusButton(btnStatus);
    };

    const handleStartTime = (data) => {
        const result = moment(data, "LT").isValid();
        const checkDate1 = Number(moment(data).format("HHmm"));
        const checkDate2 = Number(moment(termino).format("HHmm"));
        let checkTime = true;

        if (termino !== "" && checkDate1 >= checkDate2) {
            setStartTimeText(
                "Horário de início precisa ser menor que o de término",
            );
            setEndTimeText(
                "Horário de término precisa ser maior que o de início",
            );
            checkTime = false;
        } else {
            setStartTimeText("");
            setEndTimeText("");
        }

        if (result) {
            if (checkTime) {
                setInicio(data);
                setStartTimeError(false);
                checkFields();
            } else {
                setInicio("");
                setStartTimeError(true);
            }
        } else {
            setInicio("");
            setStartTimeError(true);
        }
    };

    const handleEndTime = (data) => {
        const result = moment(data, "LT").isValid();
        const checkDate1 = Number(moment(data).format("HHmm"));
        const checkDate2 = Number(moment(inicio).format("HHmm"));
        let checkTime = true;

        if (inicio !== "" && checkDate1 <= checkDate2) {
            setStartTimeText(
                "Horário de início precisa ser menor que o de término",
            );
            setEndTimeText(
                "Horário de término precisa ser maior que o de início",
            );
            checkTime = false;
        } else {
            setStartTimeText("");
            setEndTimeText("");
        }

        if (result) {
            if (checkTime) {
                setTermino(data);
                setEndTimeError(false);
                checkFields();
            } else {
                setTermino("");
                setEndTimeError(true);
            }
        } else {
            setTermino("");
            setEndTimeError(true);
        }
    };

    const handleData = (value) => {
        const result = moment(value, "YYYY-MM-DD", true).isValid();
        const dateForm = moment(value, "YYYY-MM-DD").valueOf();
        const dateCurrent = moment(currentDate, "YYYY-MM-DD").valueOf();

        if (result) {
            if (dateForm >= dateCurrent) {
                setData(value);
                setDateError(false);
                setCurrentDateForm("");
                checkFields();
            } else {
                setDateError(true);
                setCurrentDateForm(
                    "A data não pode ser anterior a data atual!",
                );
                checkFields();
            }
        } else {
            setData("");
            setDateError(true);
        }
    };

    const handleOnChangeForm = (option) => {
        switch (option.target.name) {
            case "numberVacancies":
                totalVacancy =
                    Number(option.target.value) <= 1000
                        ? Number(option.target.value)
                        : totalVacancy;
                if (typeof totalVacancy === "number") {
                    setNumeroVagas(totalVacancy);
                    setVagueError(false);
                } else {
                    setNumeroVagas("");
                    setVagueError(false);
                }
                break;
            case "modality":
                setModalidade(option.target.value);
                setModalityError(false);
                break;
            case "local":
                setLocal(option.target.value);
                setLocalError(false);
                break;
            default:
                break;
        }
    };

    const requiredField = "Campo obrigatório";

    useEffect(() => {
        checkFields();
    }, [unidadeID]);

    return (
        <>
            <Container fluid className="containerForm justify-content-center">
                <BreadCrumb
                    data={{
                        page: "Novo treinamento",
                        menu: {
                            item: "Treinamentos",
                        },
                    }}
                />
                <Title title="Novo treinamento" />
                <BoxData>
                    <div className={styles.dadosTrein}>
                        Dados do treinamento
                    </div>

                    <div className={styles.containerInputs}>
                        <div className={styles.linha1}>
                            <div className={`${styles.containerDatePicker2}`}>
                                <span>Data</span>
                                <MyDatePicker
                                    fullWidth
                                    sx={ItemCustomDate}
                                    value={data}
                                    onChange={handleData}
                                    onBlur={checkFields}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {dateError && (
                                    <span className={styles.requiredField}>
                                        {currentDateForm !== ""
                                            ? currentDateForm
                                            : requiredField}
                                    </span>
                                )}
                            </div>
                            <div className={styles.containerTextField2}>
                                <span>Número de vagas</span>
                                <input
                                    value={numeroVagas}
                                    fullWidth
                                    onChange={handleOnChangeForm}
                                    placeholder="Número de vagas"
                                    name="numberVacancies"
                                    variant="outlined"
                                    onBlur={checkFields}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {vagueError && (
                                    <span
                                        className={`${styles.requiredField} ${styles.marginTopRequired}`}
                                    >
                                        {requiredField}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className={styles.linha2}>
                            <div
                                className={`${styles.containerDatePicker} ${styles.dividerSpace}`}
                            >
                                <span>Horário de início</span>
                                <HourPick
                                    name="startTime"
                                    value={inicio}
                                    onChange={handleStartTime}
                                    onBlur={checkFields}
                                    title={"Horário de início"}
                                    sx={ItemCustom}
                                />
                                {startTimeError && (
                                    <span className={styles.requiredField}>
                                        {startTimeText !== ""
                                            ? startTimeText
                                            : requiredField}
                                    </span>
                                )}
                            </div>

                            <div
                                className={`${styles.containerDatePicker} ${styles.dividerSpace}`}
                            >
                                <span>Horário de término</span>
                                <HourPick
                                    name="endTime"
                                    value={termino}
                                    onChange={handleEndTime}
                                    onBlur={checkFields}
                                    title={"Horário de término"}
                                    sx={ItemCustom}
                                />
                                {endTimeError && (
                                    <span className={styles.requiredField}>
                                        {endTimeText !== ""
                                            ? endTimeText
                                            : requiredField}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className={styles.linha3}>
                            <div
                                className={`${styles.containerFieldSelect} ${styles.dividerSpace}`}
                            >
                                <span>Modalidade</span>

                                <select
                                    className={`form-select ${styles.formDropdown}`}
                                    value={modalidade}
                                    onChange={handleOnChangeForm}
                                    onBlur={checkFields}
                                    name="modality"
                                >
                                    <option value="Online" key="1">
                                        Online
                                    </option>
                                    <option value="Presencial" key="2">
                                        Presencial
                                    </option>
                                </select>
                                {modalityError && (
                                    <span className={styles.requiredField}>
                                        {requiredField}
                                    </span>
                                )}
                            </div>

                            <div
                                className={`${styles.containerTextField2} ${styles.dividerSpace}`}
                            >
                                <span>Local do treinamento</span>
                                <input
                                    value={local}
                                    fullWidth
                                    placeholder="Local"
                                    name="local"
                                    onChange={handleOnChangeForm}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={checkFields}
                                />
                                {localError && (
                                    <span
                                        className={`${styles.requiredField} ${styles.marginTopRequired}`}
                                    >
                                        {requiredField}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className={styles.linha4}>
                            <div
                                className={`${styles.containerFieldSelect} ${styles.dividerSpace}`}
                            >
                                <span>Aplicar unidade(s)</span>
                                <MultiSelectDropdownCustom
                                    id="tipo-servico"
                                    name="tipo-servico"
                                    value={unidadeID}
                                    totalUnit={serverUnit.length}
                                    placeholder="Selecione unidade"
                                    addIcon={"screen-custom"}
                                    onChange={(e) => {
                                        if (
                                            e[e.length - 1] ===
                                            "Todas as unidades"
                                        ) {
                                            const statusAll =
                                                unidadeID.length <=
                                                serverUnit.length
                                                    ? true
                                                    : false;
                                            handleSelecionarTodasAsUnidades(
                                                statusAll,
                                            );
                                        } else {
                                            if (e.includes("Todas unidades")) {
                                                const allUnits = serverUnit;
                                                const result = allUnits.filter(
                                                    (val) =>
                                                        val !== e[e.length - 1],
                                                );
                                                setUnidadeID(result);
                                            } else {
                                                setUnidadeID(e);
                                            }
                                        }
                                    }}
                                >
                                    {unidadesMultiSelect}
                                </MultiSelectDropdownCustom>
                                {unitError && (
                                    <span className={styles.requiredField}>
                                        {requiredField}
                                    </span>
                                )}
                            </div>

                            <div
                                className={`${styles.containerTextFieldDisabled} ${styles.dividerSpace} ${styles.colorFieldBlock}`}
                            >
                                <div>
                                    <span>Operador responsável</span>
                                    <TextField
                                        value={operador}
                                        fullWidth
                                        sx={ItemCustomFieldDisabled}
                                        name="local"
                                        variant="outlined"
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onBlur={checkFields}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.containerBtn}>
                        <div className={styles.containerButtonEditDadosP}>
                            <button
                                className={`${
                                    statusButton
                                        ? styles.buttonDisabled
                                        : styles.buttonSave
                                }`}
                                type="button"
                                onClick={handleSubmit}
                                disabled={statusButton}
                            >
                                <span>Salvar</span>
                            </button>
                        </div>
                    </div>
                </BoxData>
            </Container>

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};

export default CriarTreinamento;
