import { ptBR as corePtBr } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import React from "react";
import ResultNotFound from "../../resultNotFound";
import { ServiceContext } from "./../../../contexts/service";
import { CpfCnpjMaskRegex, TelMaskRegex } from "./../../../utils/mask/index";

//Limits
const CLIENT_LIMIT = 1;
//Theme Table
const theme = createTheme(
    {
        palette: {
            primary: { main: "#0069B4" },
        },
    },
    ptBR,
    corePtBr,
);
const SolicitacaoClienteTable = () => {
    const { clientes, selectCliente, setSelectCliente, setAddresses } =
        React.useContext(ServiceContext);
    const columns = [
        { field: "id", headerName: "ID", width: 60 },
        { field: "nome", headerName: "Cliente", width: 300 },
        { field: "telefone", headerName: "Telefone", width: 200 },
        {
            field: "rgcpf",
            headerName: "CPF/CNPJ",
            width: 200,
        },
    ];

    //Id is very important for checkbox's select
    const rows = clientes.map((row) => {
        let situacao = row.realizadas.length != 0 ? "Bloqueado" : row.situacao;
        return {
            id: row.id,
            nome: row.nome,
            telefone: TelMaskRegex(row.telefoneCelular),
            rgcpf: CpfCnpjMaskRegex(row.cpfCnpj),
            situacao: situacao,
        };
    });
    const verifySelect = (value) => {
        if (typeof selectCliente[0] === "number")
            return value.id === selectCliente[0];
        return false;
    };
    // Atualiza endereço
    React.useEffect(() => {
        let myAddresses = clientes.filter(verifySelect);
        if (Array.isArray(myAddresses) && myAddresses.length > 0)
            setAddresses(myAddresses[0].enderecos);
        return () => (myAddresses = []);
    }, [selectCliente, setAddresses]);

    return (
        <ThemeProvider theme={theme}>
            <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#CFD9E1",
                        },
                        "& .MuiDataGrid-columnHeadersInner": {
                            backgroundColor: "#CFD9E1",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "17px",
                            color: "#343B40",
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel) => {
                        if (newSelectionModel.length <= CLIENT_LIMIT) {
                            //limit
                            setSelectCliente(newSelectionModel);
                        }
                    }}
                    isRowSelectable={(params) => {
                        return params.row.situacao === "Ativo";
                    }}
                    getRowClassName={(params) => {
                        return params.row.situacao === "Ativo"
                            ? ""
                            : "row-disabled";
                    }}
                    selectionModel={selectCliente}
                />
            </div>
            {clientes.length <= 0 && (
                <ResultNotFound style={{ marginTop: 10 }} />
            )}
        </ThemeProvider>
    );
};

export default SolicitacaoClienteTable;
