import { Grid, MenuItem, Typography } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import { MyMultiSelectTS } from "../../../components/select/tiposervico";
import { MyTextArea } from "../../../components/textarea";
import { Api } from "../../../providers";
import { MyButtonLoading } from "./../../../components/button/index";
import { MyCalendar } from "./../../../components/calendar/index";
import { CountCaracter } from "./../../../components/caracter/index";
import MyDropdown from "./../../../components/dropdown/dropdown";
import MyTimePicker from "./../../../components/timerpicker/index";
import { ServiceContext } from "./../../../contexts/service";
import {
    FormatDate,
    TimeBR,
    dateObjectToString,
} from "./../../../utils/date/index";
import { searchInArray } from "./../../../utils/list/index";
import { limitString } from "./../../../utils/string/index";

export default function AddSolicitacao(props) {
    const [myList, setMyList] = React.useState([]);
    const { servicoList } = React.useContext(ServiceContext);
    const { servicoId, setServicoId } = React.useContext(ServiceContext);
    //Hora
    const { horario, setHorario } = React.useContext(ServiceContext);
    // Tipo Serviço
    const { tipoId, setTipoId } = React.useContext(ServiceContext);
    const { tipoList, setTipoList } = React.useContext(ServiceContext);
    //Descrição
    //Descrição
    const { descricao, setDescricao, openDescription, setOpenDescription } =
        React.useContext(ServiceContext);
    //Profissionais
    const { profissionais, selectProfissional } =
        React.useContext(ServiceContext);
    // outros
    const { enderecoID, selectCliente } = React.useContext(ServiceContext);
    //Important
    const { isNominal } = React.useContext(ServiceContext);
    // Tab1: Date
    const { data } = React.useContext(ServiceContext);
    //Inpotant: only state, not hook or context
    const [multiDate, setMultiDate] = React.useState([]);
    const [agenda, setAgenda] = React.useState([]);
    //Loading
    const [loading, setLoading] = React.useState(false);
    //Reset All
    React.useEffect(() => {
        if (isNominal) {
            setServicoId(0);
            setHorario(new Date());
            setTipoId([]);
            setTipoList([]);
            setMultiDate([]);
            setAgenda([]);
        }
    }, [selectProfissional]);
    React.useEffect(() => {
        // Quando somente um profissional for selecionado
        if (selectProfissional.length === 1) {
            let idProf = selectProfissional[0];
            Api.get(`profissional/${idProf}/agenda`)
                .then((res) => {
                    //Nova Lista de Serviços
                    let myList = res.data.servicos.map(({ servico }) => {
                        return {
                            id: servico.id,
                            nome: servico.nome,
                        };
                    });
                    if (Array.isArray(myList)) {
                        setMyList(myList);
                    }
                    //Datas Liberadas
                    let datasLiberadas = res.data.agendas.map((item) => {
                        return item.dataLiberada;
                    });
                    if (Array.isArray(datasLiberadas)) {
                        setAgenda(datasLiberadas);
                    }
                })
                .catch((err) => {
                    let message =
                        typeof err.response !== "undefined"
                            ? err.response.data.message
                            : err.message;

                    toast.error(`Ops! ${message.error}`, {
                        toastId: "error",
                    });
                });
        } else {
            /**
             * Recriar lista:
             * 1- Se nenhum for selecionado;
             * 2- Se mais de um profissional for selecionado;
             * Também serve para corrigir a transição entre abas
             */
            setMyList(servicoList);
        }
        return () => {
            setAgenda([]);
            setMyList([]);
        };
    }, [selectProfissional]);

    // Tipo de Serviços
    React.useEffect(() => {
        if (servicoId > 0)
            Api.get(
                `servico-tipo/filter?flagAtivo=true&servicoId=${servicoId}`,
            ).then((res) => {
                if (res.status === 200) {
                    //Clean type after modified service's ID
                    setTipoId([]);
                    //GET Type List
                    let myType = [];
                    myType = res.data.map(({ id, nome }) => {
                        return { id, nome };
                    });
                    setTipoList(myType);
                    return;
                }
            });
        return () => setTipoList([]);
    }, [servicoId]);

    //Important Trata Data retorno
    const treatDateFormat = () => {
        let res = [];
        if (multiDate.length > 0) {
            res = multiDate.map((date) =>
                dateObjectToString(date, "YYYY-MM-DD"),
            );
        } else {
            if (data) res = [FormatDate(data)];
        }
        return res;
    };

    //Type List
    const trataTipo = searchInArray(tipoList, tipoId);
    const trataProfissional = searchInArray(profissionais, selectProfissional);
    //Description
    const verifyArrayTS = (arr) => {
        let res = arr.findIndex((item) => item === "Outros");
        return res !== -1;
    };
    React.useEffect(() => {
        if (verifyArrayTS(tipoId)) {
            setOpenDescription(true);
        } else {
            setOpenDescription(false);
            setDescricao("");
        }
    }, [tipoId]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const params = {
            datas: treatDateFormat(),
            hora: `${TimeBR(horario)}:00`,
            tipo: isNominal ? "Nominal" : "Eventual",
            descricao: descricao,
            enderecoId: enderecoID,
            servicoId: servicoId,
            servicosTipos: trataTipo,
            profissionais: trataProfissional,
        };

        //Validação
        if (params.enderecoId === 0) {
            toast.error("Ops! Selecione um cliente!", {
                toastId: "error",
            });
            setLoading(false);
            return;
        }
        if (params.servicoId < 1) {
            toast.error("Ops! Selecione um serviço!", {
                toastId: "error",
            });
            setLoading(false);
            return;
        }
        if (params.servicosTipos.length < 1) {
            toast.error("Ops! Selecione um tipo de serviço!", {
                toastId: "error",
            });
            setLoading(false);
            return;
        }
        if (params.profissionais.length < 1) {
            toast.error("Ops! Selecione um profissional!", {
                toastId: "error",
            });
            setLoading(false);
            return;
        }
        //Nominal
        if (isNominal && multiDate.length < 1) {
            toast.error(
                "Ops! Selecione uma(s) data(s) disponível(eis) na agenda do profissional!",
                {
                    toastId: "error",
                },
            );
            setLoading(false);
            return;
        }
        //Eventual
        if (!isNominal && params.datas.length < 1) {
            toast.error("Ops! Selecione uma data!", {
                toastId: "error",
            });
            setLoading(false);
            return;
        }

        Api.post(`tarefa/cliente/${selectCliente[0]}/criar/`, params)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    toast.success("Solicitação realizada com Sucesso!", {
                        toastId: "success",
                        autoClose: 5000,
                        onClose: function () {
                            window.location.reload();
                        },
                    });
                }
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;
                setLoading(false);
                toast.error(`Ops! ${message.error}`, {
                    toastId: "error",
                });
            });
        return false;
    };

    return (
        <>
            {props.onlyButton === false ? (
                <Grid
                    container
                    spacing={0}
                    sx={{
                        m: 0,
                        p: 3,
                        backgroundColor: "#F7F7F7",
                    }}
                >
                    <Grid item md={12}>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "17px",
                                color: "#343B40",
                            }}
                        >
                            Nova Solicitação de Serviço
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        spacing={0}
                        sx={{
                            m: 0,
                            p: 3,
                            backgroundColor: "#F7F7F7",
                        }}
                    >
                        <Grid item md={6}>
                            <MyDropdown
                                id="servico"
                                name="servico"
                                label="Serviço"
                                value={servicoId}
                                onChange={(e) => {
                                    setServicoId(e.target.value);
                                }}
                                required
                            >
                                {myList.map(({ id, nome }) => (
                                    <MenuItem key={id} value={id}>
                                        {limitString(nome)}
                                    </MenuItem>
                                ))}
                            </MyDropdown>
                        </Grid>
                        <Grid item md={6}>
                            <MyMultiSelectTS
                                id="tipo-servico"
                                label="Tipo de Serviço"
                                name="tipo-servico"
                                value={tipoId}
                                onChange={setTipoId}
                            >
                                {tipoList}
                            </MyMultiSelectTS>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            sx={{
                                display: openDescription ? "display" : "none",
                            }}
                        >
                            <MyTextArea
                                label="Descrição"
                                name="descricao"
                                value={descricao}
                                onChange={({ target }) =>
                                    target.value.length <= 100
                                        ? setDescricao(target.value)
                                        : ""
                                }
                            />
                            <CountCaracter
                                totalText={100}
                                countText={descricao.length}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <MyCalendar
                                label="Data"
                                name="data"
                                dates={agenda}
                                handleChange={(value) => setMultiDate(value)}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <MyTimePicker
                                label="Horário"
                                name="horario"
                                value={horario}
                                onChange={(e) => setHorario(e)}
                                sx={{
                                    width: 400,
                                    maxWidth: 400,
                                    backgroundColor: "#fff",
                                    color: "#292E32",
                                    marginTop: "32px",
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ mt: 4 }}
                    >
                        <MyButtonLoading
                            id="btnSave"
                            onClick={handleSubmit}
                            loading={loading}
                        >
                            Enviar Solicitação
                        </MyButtonLoading>
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ mt: 4 }}
                >
                    <MyButtonLoading
                        id="btnSave"
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        Enviar Solicitação
                    </MyButtonLoading>
                </Grid>
            )}
        </>
    );
}
