import React from "react";
import { Card, CardMedia } from "@mui/material";

export default function MyThumbnail({ file }) {
    return (
        <Card sx={{ m: 0, p: 1, minWidth: 400 }}>
            <CardMedia component="img" height="100%" image={file} alt={file} />
        </Card>
    );
}
