/* eslint-disable no-unused-vars */
import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { Api } from "../../../providers";
import styles from "./styles.module.scss";

const EditarTipoServicoPage = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const navigate = useNavigate();
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const [nome, setNome] = useState("");
    const [descricao, setDescricao] = useState("");
    const [ativo, setAtivo] = useState(true);
    const [tipoServico, setTipoServico] = useState("");
    const [servicoId, setServicoId] = useState("0");
    const [servicos, setServicos] = useState([]);

    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp =
        operador.operador.funcao.slug === "administrador" ? true : false;

    useEffect(() => {
        setCurrentScreen(7);
    }, []);

    useEffect(() => {
        let mounted = true;
        Api.get("/servico-tipo/" + idQuery).then((res) => {
            if (res.status === 200 && mounted) {
                setNome(res.data.nome);
                setDescricao(res.data.descricao);
                setAtivo(res.data.flagAtivo);
                setServicoId(res.data.servico.id);
                setTipoServico(res.data.servico?.tipoAgendamento);
            }
        });
        return () => (mounted = false);
    }, [idQuery]);

    useEffect(() => {
        let mounted = true;
        Api.get("/servico/").then((res) => {
            if (res.status === 200 && mounted) {
                const result = res.data.filter((data) => data.flagAtivo);
                setServicos(result);
            }
        });
        return () => {
            mounted = false;
        };
    }, []);

    const getServicos = async () => {
        if (servicoId != "0") {
            let servicos = await Api.get(`/servico/${servicoId}`);
            if (servicos.status === 200) {
                setTipoServico(servicos.data.tipoAgendamento);
            }
        }
    };

    useEffect(() => {
        getServicos();
    }, [servicoId]);

    const handleSubmit = () => {
        if (!checkOp) {
            return;
        }
        const data = {
            nome: nome,
            slug: "",
            descricao: descricao,
            flagAtivo: ativo,
            tipoAgendamento: tipoServico,
            servicoId: servicoId,
        };

        Api.put("/servico-tipo/" + idQuery, data)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Atualizado com sucesso", {
                        toastId: "success",
                    });
                    navigate("/tipo-servico");
                }
            })
            .catch((err) => {
                const checkErro = err;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });
    };

    const checkNome = nome.trim() && nome.length > 0 ? true : false;
    const checkCat = Number(servicoId) > 0 ? true : false;
    const checkDesc = descricao.trim() && descricao.length > 0 ? true : false;
    const checkButtonSubmit = checkNome && checkDesc && checkCat ? true : false;

    return (
        <Container fluid className="containerForm justify-content-center">
            <BreadCrumb
                data={{
                    category: "",
                    page: "Editar tipo de serviço",
                    menu: {
                        item: "Categorias e serviços",
                        item2: "Tipo de serviço",
                        link: "/tipo-servico",
                    },
                }}
            />
            <Title title="Editar tipo de serviço" />
            <div className={styles.containerGeral}>
                <div className={styles.subContainerGeral}>
                    <form className={`${styles.formularioItem}`}>
                        <Form.Group>
                            <span className={styles.labelText}>
                                Tipo de serviço
                            </span>
                            <Form.Control
                                className={styles.containerInputFull}
                                type="text"
                                placeholder="Digite aqui o tipo de serviço"
                                name="nome"
                                id="nome"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                maxLength={100}
                                size="lg"
                            />
                        </Form.Group>
                        <div>
                            <span
                                className={`${styles.labelText} ${styles.maginTopLabel}`}
                            >
                                Serviços
                            </span>
                            <div className={styles.fieldSelect}>
                                <Form.Select
                                    className="form-select"
                                    aria-label="categoryOption"
                                    as="select"
                                    value={servicoId}
                                    onChange={(e) =>
                                        setServicoId(e.target.value)
                                    }
                                >
                                    <option value="0" key="0" disabled>
                                        Selecionar
                                    </option>
                                    {servicos.map((data, key) => {
                                        return (
                                            <option value={data.id} key={key}>
                                                {data.nome}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </div>
                        </div>

                        <Form.Group>
                            <span
                                className={`${styles.labelText} ${styles.maginTopLabel}`}
                            >
                                Descrição
                            </span>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="descricao"
                                id="descricao"
                                placeholder="Descreva o tipo de serviço"
                                value={descricao}
                                maxLength={200}
                                onChange={(e) => setDescricao(e.target.value)}
                                className={styles.textAreaComment}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    marginTop: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: 12,
                                        fontWeight: 700,
                                        color: "#8D8F90",
                                        lineHeight: "14.52px",
                                    }}
                                >
                                    {descricao.length}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "#8D8F90",
                                        lineHeight: "14.52px",
                                    }}
                                >
                                    /200 caracteres
                                </Typography>
                            </Box>
                        </Form.Group>
                    </form>
                    {checkOp && (
                        <div className={styles.containerBtn}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#0064B4",
                                        marginBottom: 2,
                                    }}
                                >
                                    Status
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        width: 200,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: "#343B40",
                                        }}
                                    >
                                        Ativo
                                    </Typography>
                                    <Box sx={{ marginLeft: 2 }}>
                                        <input
                                            type="checkbox"
                                            checked={ativo}
                                            onChange={(e) => setAtivo(!ativo)}
                                            id={styles.checkbox}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <div
                                onClick={() =>
                                    checkButtonSubmit ? handleSubmit() : {}
                                }
                            >
                                <button
                                    className={
                                        !checkButtonSubmit
                                            ? styles.buttonSearchBlock
                                            : styles.buttonSave
                                    }
                                    disabled={!checkButtonSubmit}
                                    type="button"
                                >
                                    <span>Salvar</span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default EditarTipoServicoPage;
