import { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import {BreadCrumb} from "../../../components/breadcrumb";
import Title from "../../../components/title";
import { ClientesProvider } from "../../../contexts/service";
import ClientesTable from "../../../components/tables/clientes";
import { AuthContext } from "../../../contexts/auth";
import Search from "./search";

const DashboardCliente = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    useEffect(() => {
        setCurrentScreen(1);
    }, []);

    return (
        <ClientesProvider>
            <Container fluid className="justify-content-center">
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Clientes",
                        menu: {
                            item: "Usuários",
                            link: "",
                        },
                    }}
                />
                <Title title="Clientes" />
                <Container className="listagem">
                    <Search />
                    <ClientesTable />
                </Container>
            </Container>
        </ClientesProvider>
    );
};

export default DashboardCliente;
