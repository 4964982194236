import React from "react";
import { useNavigate } from "react-router-dom";
import seta from "../../assets/images/seta.png";
import styles from "./styles.module.scss";

export default function Breadcrumb({ data }) {
    return (
        <div>
            <div className="caminhodepao">
                <div className="caminho-de-pao-container">
                    <div className="caminho-de-pao-home">
                        <a href="/">Home</a>
                    </div>

                    {data ? (
                        data.menu ? (
                            <>
                                <div className="caminho-de-pao-seta">
                                    <img src={seta} alt="seta" />
                                </div>

                                <div className="caminho-de-pao-meio">
                                    <a href={data.menu.link}>
                                        {data.menu.item}
                                    </a>
                                </div>

                                <div className="caminho-de-pao-seta">
                                    <img src={seta} alt="seta" />
                                </div>
                                <div className="caminho-de-pao-pagina">
                                    {data.page}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="caminho-de-pao-seta">
                                    <img src={seta} alt="seta" />
                                </div>
                                <div className="caminho-de-pao-pagina">
                                    {data.page}
                                </div>
                            </>
                        )
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
}

export function BreadCrumb({ data }) {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate("/");
    };

    const handleNewPage = (page) => {
        navigate(page);
    };

    return (
        <div className={styles.caminhoDePaoContainer}>
            <div className={styles.caminhoDePaoHome}>
                <a onClick={() => handleHome()}>Home</a>
            </div>

            {data ? (
                data.menu ? (
                    <>
                        <div className={styles.caminhoDePaoSeta}>
                            <img src={seta} alt="seta" />
                        </div>

                        <div className={styles.caminhoDePaoMeio}>
                            <a
                                onClick={() =>
                                    data.menu.link
                                        ? handleNewPage(data.menu.link)
                                        : {}
                                }
                            >
                                {data.menu.item}
                            </a>
                        </div>

                        {data.menu.item2 && (
                            <>
                                <div className={styles.caminhoDePaoSeta}>
                                    <img src={seta} alt="seta" />
                                </div>
                                <div className={styles.caminhoDePaoMeio}>
                                    <a
                                        onClick={() =>
                                            data.menu.link2
                                                ? handleNewPage(data.menu.link2)
                                                : {}
                                        }
                                    >
                                        {data.menu.item2}
                                    </a>
                                </div>
                            </>
                        )}

                        <div className={styles.caminhoDePaoSeta}>
                            <img src={seta} alt="seta" />
                        </div>
                        <div className={styles.caminhoDePaoPagina}>
                            {data.page}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.caminhoDePaoSeta}>
                            <img src={seta} alt="seta" />
                        </div>
                        <div className={styles.caminhoDePaoPagina}>
                            {data.page}
                        </div>
                    </>
                )
            ) : (
                ""
            )}
        </div>
    );
}
