import { Box } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_check_box_outline_blank } from "react-icons-kit/md/ic_check_box_outline_blank";
import { ic_check_outline } from "react-icons-kit/md/ic_check_outline";
import { toast } from "react-toastify";
import ImageClock from "../../../../assets/images/clock.png";
import MyCheckbox from "../../../checkbox";
import {
    ModalConfirm,
    ModalJustCustom,
    ModalJustificativa,
    ModalLoading,
} from "../../../modal";
import ResultNotFound from "../../../resultNotFound";
import { Api } from "./../../../../providers";
import { ListTable } from "./ListTable";
import { ListTableJustificativas } from "./ListTableJustificativas";
import styles from "./styles.module.scss";

const HistoricoTreinamentoProfissional = ({ idQuery }) => {
    const [situacao, setSituacao] = useState([]);

    //Modal de justificativa de reprovacao
    const [openModalJustificativa, setOpenModalJustificativa] = useState(false);
    const [justificativa, setJustificativa] = useState("");
    const [justificativaSelect, setJustificativaSelect] = useState("");

    // *********************************************************************
    const [loading, setLoading] = useState(true);
    const [resultHist, setResultHist] = useState([]);
    const [dataResult, setDataResult] = useState([]);
    const [openModalReabrirDatas, setOpenModalReabrirDatas] = useState(false);
    const [reabrirDatas, setReabrirDatas] = useState(false);
    const [justificativaFalta, setJustificativaFalta] = useState("");
    const [idTrein, setIdTrein] = useState("");
    const [nome, setNome] = useState("");
    const [clickView, setClickView] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [operatorName, setOperatorName] = useState("");
    const [operatorDate, setOperatorDate] = useState("");
    const [mostrarHistModal, setMostrarHistModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [resultJust, setResultJust] = useState([]);
    const [resultJustView, setResultJustView] = useState([]);
    const [modalPres, setModalPres] = useState(false);
    const [flagPresHist, setFlagPresHist] = useState(false);
    const [flagAprovHist, setFlagAprovHist] = useState(false);
    const [checkUpdate, setCheckUpdate] = useState(false);
    const [profissionalFalta, setProfissionalFalta] = useState([]);
    const [checkFlegAcoes, setCheckFlegAcoes] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);

    let mounted = true;

    let infoTreinamento = [];
    const handleTreinamento = async (idTreinamento, dataAtualizacao) => {
        const [dataTrein, resData] = await Promise.all([
            Api.get(`treinamento/` + idTreinamento),
            Api.get("/system/date"),
        ]);

        if (dataTrein.status == 200) {
            // *********************************************************************

            const dataFimTreino = moment(dataTrein.data.dataHoraFim).format(
                "YYYY-MM-DD HH:mm:ss",
            );

            const dataFinalAtualizacao =
                moment(dataAtualizacao).format("YYYY-MM-DD");
            const isValidDate = moment(dataAtualizacao).isValid();

            const dataServ = moment(resData.data.hoursDate);
            const dataFimTrein = moment(dataFimTreino);

            let statusFimTrein = false;
            let statusEditTrein = false;
            let limitEditPas = false;
            if (dataServ.diff(dataFimTrein, "m") >= 1) {
                statusFimTrein = true;
                // **********************************************************
                if (isValidDate) {
                    const maxDateAvailable = new Date(dataFinalAtualizacao);
                    maxDateAvailable.setUTCDate(
                        maxDateAvailable.getUTCDate() + 29,
                    );
                    const maxMonthDate = maxDateAvailable.getUTCMonth() + 1;
                    const maxCurrentMonth =
                        maxMonthDate <= 9 ? "0" + maxMonthDate : maxMonthDate;
                    const maxCurrentDay =
                        maxDateAvailable.getUTCDate() <= 9
                            ? "0" + maxDateAvailable.getUTCDate()
                            : maxDateAvailable.getUTCDate();
                    const maxCurrentDate =
                        maxDateAvailable.getUTCFullYear() +
                        "-" +
                        maxCurrentMonth +
                        "-" +
                        maxCurrentDay;

                    const dateServ = moment(
                        resData.data.date,
                        "YYYY-MM-DD",
                    ).valueOf();

                    const dataLimiteEdit = moment(
                        maxCurrentDate,
                        "YYYY-MM-DD",
                    ).valueOf();

                    if (dateServ <= dataLimiteEdit) {
                        statusEditTrein = true;
                    } else {
                        limitEditPas = true;
                    }
                }
            }

            // *********************************************************************

            const novoMap = infoTreinamento.map((val) => {
                if (val.treinamentoId == idTreinamento) {
                    return {
                        dataTreinamento: dataTrein?.data?.dataHoraInicio,
                        statusFimTrein,
                        statusEditTrein,
                        limitEditPas,
                        ...val,
                    };
                } else {
                    return {
                        ...val,
                    };
                }
            });

            let faltosos = [];
            let flegAcoes = [];
            const _ = novoMap.map((val) => {
                if (val.flagPresenca == false) {
                    faltosos.push(val);
                }
                flegAcoes.push({
                    treinamentoId: val.treinamentoId,
                    flagPresenca: val.flagPresenca,
                    flagAprovado: val.flagAprovado,
                });
            });
            setProfissionalFalta(faltosos);
            setCheckFlegAcoes(flegAcoes);

            infoTreinamento = novoMap;

            setDataResult(novoMap);
        }
    };

    useEffect(() => {
        Api.get(`treinamento/detalhes?profissionalId=${idQuery}`).then(
            (res) => {
                if (res.status === 200) {
                    const profData = res.data.map((val) => {
                        return {
                            treinamentoId: val.treinamentoId,
                            outro: "",
                        };
                    });
                    setResultJust(profData);
                    setResultJustView(profData);

                    infoTreinamento = res.data;
                    res.data.map(async (val) => {
                        handleTreinamento(
                            val.treinamentoId,
                            val.dataAtualizacao,
                        );
                    });

                    Api.get("/profissional-situacao/").then((resInfo) => {
                        if (resInfo.status === 200) {
                            setSituacao(resInfo.data);
                        }
                    });
                }
            },
        );

        Api.get(`/treinamento/profissional/${idQuery}/detalhes`)
            .then((res) => {
                if (mounted) {
                    setResultHist(res.data);
                    setLoading(false);
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoading(false);
                }
            });

        return () => {
            mounted = false;
        };
    }, [idQuery, checkUpdate]);

    const checkSend = dataResult.filter(
        (val) => val.statusEditTrein && !val.limitEditPas,
    );

    //Salvar tudo
    const handleSubmit = () => {
        setConfirmModal(false);
        setLoadingModal(true);
        const dadosSend = checkSend.filter(
            (val) => val.statusEditTrein && !val.limitEditPas,
        );
        if (dadosSend.length > 0) {
            let dados = {
                profissionalId: dadosSend[0].profissionalId,
                treinamentoId: dadosSend[0].treinamentoId,
                flagPresenca: dadosSend[0].flagPresenca,
                flagAprovado: dadosSend[0].flagAprovado,
                justificativaFalta: dadosSend[0].justificativaFalta,
                justificativaReprovacao:
                    justificativaSelect == "outro"
                        ? "Outro: " + justificativa
                        : justificativa,
                flagAbrirDatas: dadosSend[0].flagAbrirDatas,
                profissional: { id: dadosSend[0].profissionalId },
            };

            Api.post(
                "/treinamento/" +
                    dados.treinamentoId +
                    "/profissional/" +
                    dados.profissionalId +
                    "/detalhes",
                dados,
            )
                .then((res) => {
                    if (res.status === 200) {
                        setLoadingModal(false);
                        toast.success("Atualizado com sucesso", {
                            toastId: "success",
                        });
                        setCheckUpdate(!checkUpdate);
                    }
                })
                .catch((error) => {
                    setLoadingModal(false);
                    const checkErro = error;
                    toast.error(
                        `Ops! ${checkErro.response.data.message?.error}`,
                        {
                            toastId: "error",
                        },
                    );
                });
        }
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    // Reabrir Datas
    const handleModalReabrirDatas = (data) => {
        setIdTrein(data.idTrein);
        setJustificativaFalta(data.justificativa);
        setReabrirDatas(data.abrirDatas);
        setOpenModalReabrirDatas(true);
    };

    const closeModalReabrirDatas = () => {
        setOpenModalReabrirDatas(false);
    };

    //Presença do profissional: único
    const handleAtualizarPresenca = (id, checkStatus, novaPres) => {
        const profissioanalTemp = dataResult.map((val) => {
            let flagPres;
            if (checkStatus) {
                flagPres = !val.flagPresenca;
            } else {
                flagPres = novaPres;
            }

            if (!novaPres && val.treinamentoId == id && val.flagAprovado) {
                setNome(val.profissional?.nome);
                setIdTrein(val.treinamentoId);
                setJustificativa("");
                setJustificativaSelect("0");
                setOpenModal(true);
                setOpenModalJustificativa(false);
                setFlagPresHist(val.flagPresenca);
                setFlagAprovHist(val.flagAprovado);
                setModalPres(true);
            }

            return {
                ...val,
                flagPresenca:
                    val.treinamentoId == id ? flagPres : val.flagPresenca,
                flagAprovado:
                    val.treinamentoId == id && !flagPres
                        ? false
                        : val.flagAprovado,
            };
        });
        setDataResult(profissioanalTemp);
    };

    const handleAtualizarAprovacao = (id, valorNovo, checkModal = true) => {
        const profissioanalTemp = dataResult.map((val) => {
            if (!valorNovo && val.treinamentoId == id && !checkModal) {
                setNome(val.profissional?.nome);
                setIdTrein(val.treinamentoId);
                setJustificativa("");
                setJustificativaSelect("0");
                setOpenModal(true);
                setOpenModalJustificativa(false);
                setModalPres(false);
                setFlagPresHist(val.flagPresenca);
                setFlagAprovHist(val.flagAprovado);
            }

            return {
                ...val,
                flagPresenca:
                    val.treinamentoId == id && valorNovo
                        ? true
                        : val.flagPresenca,
                flagAprovado:
                    val.treinamentoId == id ? valorNovo : val.flagAprovado,
            };
        });

        setDataResult(profissioanalTemp);
    };

    const saveJust = (text) => {
        const rest = resultJust.map((val) => {
            return {
                ...val,
                outro: val.treinamentoId == idTrein ? text : val.outro,
            };
        });
        setJustificativa(text);
        setResultJust(rest);
        setResultJustView(rest);
    };

    const operatorData = (profId, novoTrein, treinamentoId) => {
        setClickView(true);
        setLoadingModal(true);

        Api.get(
            `/treinamento/${treinamentoId}/profissional/${profId}/detalhes?acao=Reprovação`,
        )
            .then((resp) => {
                setOperatorName(resp?.data[0]?.nome);
                setOperatorDate(resp?.data[0]?.dataAcao);

                Api.get(
                    "/treinamento/detalhes?profissionalId=" +
                        profId +
                        "&treinamentoId=" +
                        treinamentoId,
                )
                    .then((resOp) => {
                        const justRep = resOp?.data[0]?.justificativaReprovacao
                            ? true
                            : false;
                        if (justRep) {
                            const justSelect =
                                resOp?.data[0]?.justificativaReprovacao.indexOf(
                                    "Outro",
                                ) >= 0
                                    ? "outro"
                                    : resOp?.data[0]?.justificativaReprovacao;
                            setJustificativaSelect(justSelect);
                            setJustificativa(
                                resOp?.data[0]?.justificativaReprovacao.replace(
                                    "Outro:",
                                    "",
                                ),
                            );
                        } else {
                            setOperatorName("");
                            setOperatorDate("");
                            setJustificativaSelect("");
                            setJustificativa("");
                        }

                        setNome(resOp.data[0]?.profissional?.nome);
                        setIdTrein(treinamentoId);

                        setOpenModalJustificativa(true);
                        setMostrarHistModal(true);
                        setLoadingModal(false);
                    })
                    .catch((error) => {
                        setLoadingModal(false);
                        const checkErro = error;
                        toast.error(
                            `Ops! ${checkErro.response.data.message?.error}`,
                            {
                                toastId: "error",
                            },
                        );
                    });
            })
            .catch((error) => {
                setLoadingModal(false);
                const checkErro = error;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });
    };

    const openEdit = () => {
        setOpenModalJustificativa(false);
        setOpenModal(true);
    };

    //Justificativa
    const handleJustificativa = () => {
        setLoadingModal(true);
        if (!clickView) {
            const result = resultJust.filter(
                (val) => val.treinamentoId == idTrein,
            );
            const resultTextJust = result[0]?.outro ?? "";
            const resultSelect =
                justificativaSelect == "outro" ? "Outro:" : justificativaSelect;
            const justiSelect =
                resultTextJust.length > 0 ? resultSelect + " " : resultSelect;
            let profissioanalTemp = dataResult.map((val) => {
                setOpenModal(false);
                return {
                    ...val,
                    justificativaReprovacao:
                        val.treinamentoId == idTrein
                            ? justiSelect + "" + resultTextJust
                            : val.justificativaReprovacao,
                };
            });
            setDataResult(profissioanalTemp);
            setLoadingModal(false);
        } else {
            const result = resultJustView.filter(
                (val) => val.treinamentoId == idTrein,
            );
            const resultTextJust = result[0]?.outro ?? "";
            const resultSelect =
                justificativaSelect == "outro" ? "Outro:" : justificativaSelect;
            const justiSelect =
                resultTextJust.length > 0 ? resultSelect + " " : resultSelect;
            const profissioanalTemp = dataResult.map((val) => {
                setOpenModal(false);
                return {
                    ...val,
                    justificativaReprovacao:
                        val.treinamentoId == idTrein
                            ? justiSelect + "" + resultTextJust
                            : val.justificativaReprovacao,
                };
            });

            const profData = dataResult.map((val) => {
                return {
                    treinamentoId: val.treinamentoId,
                    outro: "",
                };
            });

            const profissioanisInfo = dataResult
                .map((val) => {
                    return {
                        ...val,
                        justificativaReprovacao:
                            val.treinamentoId == idTrein
                                ? justiSelect + "" + resultTextJust
                                : val.justificativaReprovacao,
                    };
                })
                .filter((data) => data.treinamentoId == idTrein);

            setDataResult(profissioanalTemp);

            Api.post("/treinamento/" + idTrein + "/detalhes", profissioanisInfo)
                .then((res) => {
                    if (res.status == 200) {
                        setJustificativa("");
                        setResultJust(profData);
                        setClickView(false);
                        setCheckUpdate(!checkUpdate);
                        setLoadingModal(false);
                        toast.success(
                            "Justificativa da reprovação realizada com sucesso",
                            {
                                toastId: "success",
                            },
                        );
                    }
                })
                .catch((err) => {
                    setLoadingModal(false);
                    const checkErro = err;
                    toast.error(
                        `Ops! ${checkErro.response.data.message?.error}`,
                        {
                            toastId: "error",
                        },
                    );
                });
        }
    };

    const closeModal = () => {
        setOperatorName("");
        setOperatorDate("");
        setOpenModalJustificativa(false);
        setOpenModal(false);
        setJustificativa("");
        setJustificativaSelect("0");
        setClickView(false);
    };

    const handleAtualizarPresencaModal = (id) => {
        const profissioanalTemp = dataResult.map((val) => {
            return {
                ...val,
                flagPresenca:
                    val.treinamentoId == id ? flagPresHist : val.flagPresenca,
                flagAprovado:
                    val.treinamentoId == id ? flagAprovHist : val.flagAprovado,
            };
        });
        setDataResult(profissioanalTemp);
    };

    const handleAtualizarAprovacaoModal = (id) => {
        const profissioanalTemp = dataResult.map((val) => {
            return {
                ...val,
                flagPresenca:
                    val.treinamentoId == id ? flagPresHist : val.flagPresenca,
                flagAprovado:
                    val.treinamentoId == id ? flagAprovHist : val.flagAprovado,
            };
        });
        setDataResult(profissioanalTemp);
    };

    const retornaHist = (tipoModal) => {
        if (tipoModal) {
            handleAtualizarPresencaModal(idTrein);
            handleAtualizarAprovacaoModal(idTrein);
        } else {
            handleAtualizarAprovacaoModal(idTrein);
        }
        closeModal();
    };

    let btnJust = "Reprovar";
    let checkLimitEdit = false;
    let checkDisabtrein = false;
    const resultDados = dataResult?.find((val) => val.treinamentoId == idTrein);
    if (resultDados) {
        btnJust = resultDados.statusEditTrein ? "Salvar" : "Reprovar";

        const permitEdit = resultDados.novoTreinamento
            ? true
            : justificativaSelect == 0;

        checkLimitEdit =
            !resultDados.limitEditPas && !resultDados.novoTreinamento
                ? resultDados.limitEditPas
                : permitEdit;

        checkDisabtrein = resultDados.novoTreinamento
            ? resultDados.novoTreinamento
            : !resultDados.statusEditTrein;
    }

    const handleReabrirDatas = () => {
        setOpenModalReabrirDatas(false);
        setJustificativa("");

        const profissioanalTemp = dataResult
            .map((val) => {
                const flagData =
                    val.flagAbrirDatas == null
                        ? reabrirDatas
                        : val.flagAbrirDatas;
                return {
                    ...val,
                    flagAbrirDatas:
                        val.treinamentoId == idTrein ? reabrirDatas : flagData,
                };
            })
            .filter((data) => data.treinamentoId == idTrein);
        Api.post("/treinamento/" + idTrein + "/detalhes", profissioanalTemp)
            .then((res) => {
                if (res.status == 200) {
                    setJustificativa("");
                    setResultJust([]);
                    setFlagPresHist(false);
                    setFlagAprovHist(false);
                    setCheckUpdate(!checkUpdate);
                    toast.success("Atualizado com sucesso", {
                        toastId: "success",
                    });
                }
            })
            .catch((err) => {
                const checkErro = err;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });
    };

    const dataOperator = () => {
        if (operatorName != "") {
            return (
                <div>
                    <img src={ImageClock} alt="imagem" />
                    <span>{`Operador ${operatorName} - ${operatorDate}`}</span>
                </div>
            );
        }
        return <div />;
    };

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    background: "#F7F7F7",
                    borderRadius: "8px",
                    marginBottom: 32,
                    p: 3,
                }}
            >
                {dataResult.length > 0 && (
                    <ListTable
                        currentItems={dataResult}
                        situacao={situacao}
                        handleAtualizarPresenca={handleAtualizarPresenca}
                        handleAtualizarAprovacao={handleAtualizarAprovacao}
                        operatorData={operatorData}
                        checkFlegAcoes={checkFlegAcoes}
                        setConfirmModal={setConfirmModal}
                    />
                )}
            </Box>

            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    background: "#F7F7F7",
                    borderRadius: "8px",
                    marginBottom: 32,
                    p: 3,
                }}
            >
                <div className={styles.titleHist}>Justificativas de falta</div>
                {dataResult?.length > 0 && (
                    <ListTableJustificativas
                        currentItems={profissionalFalta}
                        situacao={situacao}
                        handleModalReabrirDatas={handleModalReabrirDatas}
                    />
                )}
                {dataResult?.length <= 0 && <ResultNotFound />}
            </Box>

            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    background: "#F7F7F7",
                    borderRadius: "8px",
                    p: 3,
                }}
            >
                <div className={styles.titleHist}>Histórico de treinamento</div>
                <div className="table-responsive">
                    {resultHist.length > 0 ? (
                        <table
                            className={`table table-bordered align-middle ${styles.table}`}
                        >
                            <thead>
                                <tr>
                                    <th>Usuário</th>
                                    <th>Nome</th>
                                    <th>Data do treinamento</th>
                                    <th>Modalidade</th>
                                    <th>Ação</th>
                                    <th>Data da ação</th>
                                </tr>
                            </thead>
                            <tbody data-test="table-body">
                                {resultHist.length > 0 &&
                                    resultHist.map((value, idx) => {
                                        return (
                                            <tr
                                                key={
                                                    idx.toString() +
                                                    value.dataAcao
                                                }
                                            >
                                                <td>{value.usuario}</td>
                                                <td>{value.nome}</td>
                                                <td>{value.dataTreinamento}</td>
                                                <td>{value.modalidade}</td>
                                                <td>{value.acao}</td>
                                                <td>{value.dataAcao}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    ) : (
                        <ResultNotFound style={{ marginTop: 10 }} />
                    )}
                </div>
            </Box>

            {/* ********************************************************** */}

            {/* Modal Justificativa da Reprovação (Operador:post)*/}
            <ModalJustCustom
                title="Justificativa da reprovação"
                description=""
                openModal={openModal || openModalJustificativa}
                onClick={() =>
                    openModalJustificativa ? openEdit() : handleJustificativa()
                }
                setOpenModal={() =>
                    mostrarHistModal ? closeModal() : retornaHist(modalPres)
                }
                onClickCancel={() =>
                    mostrarHistModal ? closeModal() : retornaHist(modalPres)
                }
                statusClickCancel={true}
                labelCancel="Voltar"
                labelConfirm={openModalJustificativa ? "Editar" : btnJust}
                disabled={checkLimitEdit}
                color="blue"
                removeBtnConf={checkDisabtrein}
            >
                <div className={styles.containerGeralJust}>
                    <Form.Group>
                        <span className={styles.textLabel}>Nome</span>
                        <Form.Control
                            type="text"
                            placeholder="Nome"
                            name="nome"
                            id="nome"
                            value={nome}
                            disabled
                            className={styles.containerInputFull}
                        />
                    </Form.Group>
                    {/* Não salva no Banco essa informação */}
                    <Form.Group>
                        <span
                            className={`${styles.textLabel} ${styles.dividerSpace}`}
                        >
                            Justificativa
                        </span>
                        <div
                            className={
                                openModalJustificativa
                                    ? styles.fieldSelectDisabled
                                    : styles.fieldSelect
                            }
                        >
                            <Form.Select
                                aria-label="categoryOption"
                                as="select"
                                value={justificativaSelect}
                                disabled={openModalJustificativa}
                                onChange={(e) => {
                                    setJustificativaSelect(e.target.value);
                                    setJustificativa(
                                        e.target.value === "outro"
                                            ? ""
                                            : e.target.value,
                                    );
                                }}
                            >
                                <option
                                    value="0"
                                    key="0"
                                    disabled
                                    style={{ color: "gray" }}
                                >
                                    Escolha uma Justificativa
                                </option>
                                <option
                                    value="Não compareceu ao treinamento"
                                    key="1"
                                >
                                    Não compareceu ao treinamento
                                </option>
                                <option
                                    value="Apresentou conduta inadequada"
                                    key="2"
                                >
                                    Apresentou conduta inadequada
                                </option>
                                <option value="Desistência" key="3">
                                    Desistência
                                </option>
                                <option value="outro" key="4">
                                    Outro
                                </option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                    {justificativaSelect === "outro" ? (
                        <Form.Group>
                            <Form.Label
                                className={`${styles.textLabel} ${styles.dividerSpace}`}
                            >
                                Outro
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Justificativa"
                                name="justificativa"
                                id="justificativa"
                                value={justificativa}
                                maxLength={200}
                                className={`${styles.textAreaComment} ${
                                    openModalJustificativa
                                        ? styles.textAreaCommentDisabled
                                        : {}
                                }`}
                                onChange={(e) => {
                                    saveJust(e.target.value);
                                }}
                                disabled={openModalJustificativa}
                            />
                            <div className={styles.containerOpOutro}>
                                {dataOperator()}
                                <div>
                                    <span>{justificativa.length}</span>
                                    <span>/200 caracteres</span>
                                </div>
                            </div>
                        </Form.Group>
                    ) : (
                        <div className={styles.containerOpOutro}>
                            {dataOperator()}
                        </div>
                    )}
                </div>
            </ModalJustCustom>

            <ModalJustificativa
                styles={{
                    minHeight: "536px",
                    width: "480px",
                }}
                title="Justificativa de falta"
                description=""
                openModal={openModalReabrirDatas}
                setOpenModal={closeModalReabrirDatas}
                onClick={() => handleReabrirDatas()}
                color="blue"
                labelConfirm="Confirmar"
                disabled={checkDisabtrein}
                removeBtnConf={checkLimitEdit}
            >
                <div className={styles.containerJust}>
                    <span className={styles.label}>Justificativa</span>
                    <Form.Control
                        as="textarea"
                        placeholder="Aguardando justificativa..."
                        rows={3}
                        name="jfalta"
                        id="jfalta"
                        value={justificativaFalta ? justificativaFalta : ""}
                        className={styles.textAreaComment}
                        disabled={true}
                    />
                    <span className={styles.labelOpenDate}>
                        Reabrir datas para o profissional?
                    </span>
                    <div className={styles.containerChackBox}>
                        <MyCheckbox
                            checked={reabrirDatas === true}
                            onClick={(e) => setReabrirDatas(true)}
                            disabled={checkDisabtrein}
                            icon={
                                <Icon
                                    size={24}
                                    icon={ic_check_box_outline_blank}
                                    style={{
                                        color: checkDisabtrein
                                            ? "#D8D9DA"
                                            : "#FFFF",
                                        background: checkDisabtrein
                                            ? "#D8D9DA"
                                            : "#FFF",
                                        border: `2px solid ${
                                            checkDisabtrein
                                                ? "#8D8F90"
                                                : "#0064B4"
                                        }`,
                                        borderRadius: "4px",
                                    }}
                                />
                            }
                            checkedIcon={
                                <Icon
                                    size={24}
                                    icon={ic_check_outline}
                                    style={{
                                        color: "#FFF",
                                        background: checkDisabtrein
                                            ? "#8D8F90"
                                            : "#0064B4",
                                        border: `2px solid ${
                                            checkDisabtrein
                                                ? "#908d8d"
                                                : "#0064B4"
                                        }`,
                                        borderRadius: "4px",
                                    }}
                                />
                            }
                        >
                            <span className={styles.textCheckBox}>Sim</span>
                        </MyCheckbox>
                    </div>
                    <div className={styles.containerChackBox}>
                        <MyCheckbox
                            checked={reabrirDatas === false}
                            onClick={(e) => setReabrirDatas(false)}
                            disabled={checkDisabtrein}
                            icon={
                                <Icon
                                    size={24}
                                    icon={ic_check_box_outline_blank}
                                    style={{
                                        color: checkDisabtrein
                                            ? "#D8D9DA"
                                            : "#FFFF",
                                        background: checkDisabtrein
                                            ? "#D8D9DA"
                                            : "#FFF",
                                        border: `2px solid ${
                                            checkDisabtrein
                                                ? "#8D8F90"
                                                : "#0064B4"
                                        }`,
                                        borderRadius: "4px",
                                    }}
                                />
                            }
                            checkedIcon={
                                <Icon
                                    size={24}
                                    icon={ic_check_outline}
                                    style={{
                                        color: "#FFF",
                                        background: checkDisabtrein
                                            ? "#8D8F90"
                                            : "#0064B4",
                                        border: `2px solid ${
                                            checkDisabtrein
                                                ? "#8D8F90"
                                                : "#0064B4"
                                        }`,
                                        borderRadius: "4px",
                                    }}
                                />
                            }
                        >
                            <span className={styles.textCheckBox}>Não</span>
                        </MyCheckbox>
                    </div>
                </div>
            </ModalJustificativa>

            <ModalConfirm
                title="Editar apontamento"
                description="Tem certeza que deseja alterar esse apontamento?
                Essa ação não poderá ser desfeita!"
                openModal={confirmModal}
                setOpenModal={setConfirmModal}
                onClick={() => handleSubmit()}
                labelConfirm="Sim"
                labelCancel="Cancelar"
                color="blue"
                labelXSair="nao"
            />

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div className="spinner-border text-primary" />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};

export default HistoricoTreinamentoProfissional;
