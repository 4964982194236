import React, { createContext, useState } from "react";
//Default for ALL
export const ServiceContext = createContext();

// Page Service
export const SchedulerProvider = ({ children }) => {
    const [daysMarked, setDaysMarked] = React.useState([]);
    return (
        <ServiceContext.Provider value={{ daysMarked, setDaysMarked }}>
            {children}
        </ServiceContext.Provider>
    );
};

// Page Service
export const ServiceProvider = ({ children }) => {
    const [servicos, setServicos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [paramsFilter, setParamsFilter] = useState({});
    return (
        <ServiceContext.Provider
            value={{
                servicos,
                setServicos,
                loading,
                setLoading,
                totalPages,
                setTotalPages,
                paramsFilter,
                setParamsFilter,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

// Page Category
export const CategoryProvider = ({ children }) => {
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [paramsFilter, setParamsFilter] = useState({});

    return (
        <ServiceContext.Provider
            value={{
                categorias,
                setCategorias,
                loading,
                setLoading,
                totalPages,
                setTotalPages,
                paramsFilter,
                setParamsFilter,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};
// Page Type Service
export const TypeServiceProvider = ({ children }) => {
    const [typeService, setTypeService] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [paramsFilter, setParamsFilter] = useState({});
    return (
        <ServiceContext.Provider
            value={{
                typeService,
                setTypeService,
                loading,
                setLoading,
                totalPages,
                setTotalPages,
                paramsFilter,
                setParamsFilter,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

// Page Type Service
export const OperadoresProvider = ({ children }) => {
    const [operadores, setOperadores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [statusFilter, setStatusFilter] = useState(false);
    const [paramsFilter, setParamsFilter] = useState({});

    return (
        <ServiceContext.Provider
            value={{
                operadores,
                setOperadores,
                loading,
                setLoading,
                totalPages,
                setTotalPages,
                totalElements,
                setTotalElements,
                statusFilter,
                setStatusFilter,
                paramsFilter,
                setParamsFilter,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

export const ProfissionaisProvider = ({ children }) => {
    const [profissionais, setProfissionais] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newServiceModal, setNewServiceModal] = useState(false);
    const [checkModal, setCheckModal] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [statusFilter, setStatusFilter] = useState(false);
    const [paramsFilter, setParamsFilter] = useState({});
    const [restClient, setRestClient] = useState([]);

    return (
        <ServiceContext.Provider
            value={{
                profissionais,
                setProfissionais,
                loading,
                setLoading,
                newServiceModal,
                setNewServiceModal,
                totalPages,
                setTotalPages,
                totalElements,
                setTotalElements,
                statusFilter,
                setStatusFilter,
                paramsFilter,
                setParamsFilter,
                checkModal,
                setCheckModal,
                restClient,
                setRestClient,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

export const ClientesProvider = ({ children }) => {
    const [clientes, setClientes] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [enderecoID, setEnderecoID] = useState(0);
    const [restProf, setRestProf] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [paramsFilter, setParamsFilter] = useState({});

    return (
        <ServiceContext.Provider
            value={{
                clientes,
                setClientes,
                addresses,
                setAddresses,
                enderecoID,
                setEnderecoID,
                restProf,
                setRestProf,
                loading,
                setLoading,
                totalPages,
                setTotalPages,
                totalElements,
                setTotalElements,
                paramsFilter,
                setParamsFilter,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

export const TarefasProvider = ({ children }) => {
    const [tarefas, setTarefas] = useState([]);
    return (
        <ServiceContext.Provider value={{ tarefas, setTarefas }}>
            {children}
        </ServiceContext.Provider>
    );
};

//Page Training
export const TrainingProvider = ({ children }) => {
    const [treinamento, setTreinamento] = useState([]);
    const [loading, setLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [paramsFilter, setParamsFilter] = useState({});
    const [totalUnits, setTotalUnits] = useState(0);

    return (
        <ServiceContext.Provider
            value={{
                treinamento,
                setTreinamento,
                loading,
                setLoading,
                requests,
                setRequests,
                totalPages,
                setTotalPages,
                totalElements,
                setTotalElements,
                paramsFilter,
                setParamsFilter,
                totalUnits,
                setTotalUnits,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

//Register Operator
export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = React.useState(false);
    return (
        <ServiceContext.Provider value={{ loading, setLoading }}>
            {children}
        </ServiceContext.Provider>
    );
};

//Solicitação
export const ClientesProfissionaisProvider = ({ children }) => {
    //Cliente
    const [clientes, setClientes] = useState([]);
    const [selectCliente, setSelectCliente] = useState([]);
    const [enderecoID, setEnderecoID] = useState(0);
    //Profissional
    const [profissionais, setProfissionais] = useState([]);
    const [selectProfissional, setSelectProfissional] = useState([]);
    // Data / Hora
    const [horario, setHorario] = React.useState(new Date());
    const [data, setData] = React.useState(new Date());
    // Calendar (Test)
    const [multiDate, setMultiDate] = React.useState([]);
    const [daysActive, setDaysActive] = React.useState([]);
    const [daysDisable, setDaysDisable] = React.useState([]);
    //  Serviço
    const [servicoList, setServicoList] = React.useState([]);
    const [servicoId, setServicoId] = React.useState(0);
    // Endereço
    const [addresses, setAddresses] = React.useState([]);
    // Tipo Serviço
    const [tipoId, setTipoId] = React.useState([]);
    const [tipoList, setTipoList] = React.useState([]);
    //Descrição
    const [descricao, setDescricao] = React.useState("");
    const [openDescription, setOpenDescription] = React.useState(false);
    // Tipo: Eventual/Nominal
    const [isNominal, setIsNominal] = React.useState(false);
    return (
        <ServiceContext.Provider
            value={{
                profissionais,
                setProfissionais,
                selectProfissional,
                setSelectProfissional,

                clientes,
                setClientes,
                selectCliente,
                setSelectCliente,

                enderecoID,
                setEnderecoID,

                horario,
                setHorario,
                data,
                setData,
                multiDate,
                setMultiDate,

                servicoList,
                setServicoList,
                servicoId,
                setServicoId,

                addresses,
                setAddresses,

                tipoId,
                setTipoId,
                tipoList,
                setTipoList,

                descricao,
                setDescricao,
                openDescription,
                setOpenDescription,

                isNominal,
                setIsNominal,

                daysActive,
                setDaysActive,
                daysDisable,
                setDaysDisable,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

export const OccurrencesProvider = ({ children }) => {
    const [occurrences, setOccurrences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [statusFilter, setStatusFilter] = useState(false);
    const [paramsFilter, setParamsFilter] = useState({});

    return (
        <ServiceContext.Provider
            value={{
                occurrences,
                setOccurrences,
                loading,
                setLoading,
                totalPages,
                setTotalPages,
                totalElements,
                setTotalElements,
                statusFilter,
                setStatusFilter,
                paramsFilter,
                setParamsFilter,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

export const RequestsProvider = ({ children }) => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [statusFilter, setStatusFilter] = useState(false);
    const [paramsFilter, setParamsFilter] = useState({});

    return (
        <ServiceContext.Provider
            value={{
                requests,
                setRequests,
                loading,
                setLoading,
                totalPages,
                setTotalPages,
                totalElements,
                setTotalElements,
                statusFilter,
                setStatusFilter,
                paramsFilter,
                setParamsFilter,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

// Page HistoricoCliente
export const HistoricoClienteProvider = ({ children }) => {
    const [clientesHist, setClientesHist] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [paramsFilter, setParamsFilter] = useState({});

    return (
        <ServiceContext.Provider
            value={{
                clientesHist,
                setClientesHist,
                loading,
                setLoading,
                totalPages,
                setTotalPages,
                paramsFilter,
                setParamsFilter,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

// Page Unit
export const UnidadeProvider = ({ children }) => {
    const [unidades, setUnidades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [paramsFilter, setParamsFilter] = useState({});
    return (
        <ServiceContext.Provider
            value={{
                unidades,
                setUnidades,
                loading,
                setLoading,
                totalPages,
                setTotalPages,
                paramsFilter,
                setParamsFilter,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};

// Page Auditoria
export const AuditoriaProvider = ({ children }) => {
    const [auditorias, setAuditorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [paramsFilter, setParamsFilter] = useState({});
    return (
        <ServiceContext.Provider
            value={{
                auditorias,
                setAuditorias,
                loading,
                setLoading,
                totalPages,
                setTotalPages,
                paramsFilter,
                setParamsFilter,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};
