import React, { useState, useEffect, useContext } from "react";
import { Api } from "../../../providers";
import { toast } from "react-toastify";
import { ServiceContext } from "../../../contexts/service";
import qs from "qs";
import { limitString } from './../../../utils/string/index';
import { SearchField } from "./components/searchField";
import styles from "./styles.module.scss";

const Search = () => {
    const {
        setTypeService, setLoading, setTotalPages, setParamsFilter
    } = useContext(ServiceContext);
    const [busca, setBusca] = useState("");
    const [ativo, setAtivo] = useState("");
    //  Categoria de Servicos
    const [servicos, setServicos] = useState([]);
    const [servicoId, setServicoId] = useState("");

    // List Category
    useEffect(() => {
        let mounted = true;
        Api.get("/servico/").then((res) => {
            if (mounted) {
                // console.log(res.data);
                setServicos(res.data);
            }
        });

        return () => {
            mounted = false;
            setServicos({});
        };
    }, []);

    // List Service
    const handleSumbit = (event) => {
        event.preventDefault();

        let params = {};
        if (busca !== "") params.nome = busca;
        if (ativo !== "" && ativo !== "0") params.flagAtivo = ativo;
        if (servicoId !== "" && servicoId !== "0") params.servicoId = servicoId;
        Api.get(`/servico-tipo/pagination/0/20?`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Provider
                setTypeService(res.data.content);
                setTotalPages(res.data.totalPages);
                setParamsFilter(params);
                setLoading(false);
                if (res.data.content.length <= 0) {
                    toast.success("Nenhum resultado encontrado!", {
                        toastId: "msg",
                    });
                }
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    }

    return (
        <div className={styles.conteudoTabela}>
            <form>
                <div>
                    <div>
                        <SearchField
                            placeholder="Pesquise aqui"
                            onClick={handleSumbit}
                            onChange={(e) => searchText(e.target.value)}
                            search={busca}
                        />
                    </div>

                    <div className={styles.containerFilds}>
                        <div className={styles.subContainerFilds}>
                            <span>Filtrar resultados</span>
                            <div className={styles.containerSelect}>
                                <div className={styles.fieldSelect}>
                                    <select
                                        className="form-select"
                                        value={ativo}
                                        onChange={(e) =>
                                            setAtivo(e.target.value)
                                        }
                                    >
                                        <option value="" key="0" disabled>
                                            Status
                                        </option>
                                        <option value="0" key="999">
                                            Todos
                                        </option>
                                        <option value="true" key="1">
                                            Ativo
                                        </option>
                                        <option value="false" key="2">
                                            Inativo
                                        </option>
                                    </select>
                                </div>
                                <div className={styles.fieldSelect}>
                                    <select
                                        className="form-select"
                                        value={servicoId}
                                        onChange={(e) =>
                                            setServicoId(e.target.value)
                                        }
                                    >
                                        <option value="" key="0" disabled>
                                            Serviço
                                        </option>
                                        <option value="0" key="999">
                                            Todos
                                        </option>
                                        {servicos.map(({ id, nome }, key) => {
                                            return (
                                                <option
                                                    value={id}
                                                    key={key + 1}
                                                >
                                                    {limitString(nome, 35)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Search;
