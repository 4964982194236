import React, { useContext, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Api } from "../../../providers";

import { ServiceContext } from "./../../../contexts/service";
import DownloadExcel from "../../download/excel";
import DownloadPDF from "./../../download/pdf/index";

const ProfissionaisExcluidosTable = () => {
    const { profissionais, setProfissionais } = useContext(ServiceContext);
    const data = {
        columns: [
            {
                label: "Situação",
                field: "Situação",
                sort: "asc",
                width: 150,
            },
            {
                label: "Nome",
                field: "Nome",
                sort: "asc",
                width: 270,
            },
            {
                label: "RG e CPF",
                field: "RG e CPF",
                sort: "asc",
                width: 200,
            },
            {
                label: "Telefone",
                field: "Telefone",
                sort: "asc",
                width: 200,
            },
            {
                label: "Data de inscrição",
                field: "Data de inscrição",
                sort: "asc",
                width: 200,
            },
        ],
        rows: [],
    };

    useEffect(() => {
        let mounted = true;
        Api.get("/profissional/filter?situacaoId=4").then((res) => {
            if (mounted) {
                setProfissionais(res.data);
            }
        });
        return () => {
            mounted = false;
            setProfissionais({});
        };
    }, [setProfissionais]);
    const getDataRow = () => {
        try {
            if (profissionais.length > 0) {
                data.rows = profissionais.map((row) => {
                    //Add Row
                    return {
                        ["Situação"]: (
                            <div className={"estado-cat true"}>
                                {row.situacao?.nome}
                            </div>
                        ),
                        ["Nome"]: row.nome,
                        ["RG e CPF"]: row.rg + " " + row.cpf,
                        ["Telefone"]: (
                            <>
                                {row.telefoneCelular ? row.telefoneCelular : ""}
                                {row.telefoneContato
                                    ? ` / ${row.telefoneContato}`
                                    : ""}
                            </>
                        ),
                        ["Data de inscrição"]: (
                            <> {DateBR(row.dataCadastro)} </>
                        ),
                    };
                });
            }
        } catch (error) {
            // console.log(error);
        }
    };
    //init
    getDataRow();

    //Trata PDF/Excel Data
    const getDataDownload = (isPDF = false) => {
        let JsonObject = {
            columns: [
                {
                    label: "Situação",
                    width: "10%",
                },
                {
                    label: "Nome",
                    width: "40%",
                },
                {
                    label: "RG e CPF",
                    width: "20%",
                },
                {
                    label: "Telefone",
                    width: "15%",
                },
                {
                    label: "Data de inscrição",
                    width: "15%",
                },
            ],
            rows: [],
        };
        if (profissionais.length > 0) {
            JsonObject.rows = profissionais.map((row) => {
                //add line Excel
                return {
                    ["Situação"]: row.situacao?.nome,
                    ["Nome"]: row.nome,
                    ["RG e CPF"]: row.rg + " / " + row.cpf,
                    ["Telefone"]: row.telefoneCelular
                        ? row.telefoneCelular
                        : row.telefoneContato
                        ? ` / ${row.telefoneContato}`
                        : "",
                    ["Data de inscrição"]: DateBR(row.dataCadastro),
                };
            });
        }
        if (isPDF) {
            return JsonObject;
        }
        // To Excel
        return JsonObject.rows;
    };
    return (
        <>
            <div className="texto-criar">
                <DownloadExcel data={getDataDownload()} />
                <DownloadPDF
                    title="Lista de Profissionais Excluídos"
                    data={getDataDownload(true)}
                />
            </div>
            <MDBDataTable
                striped
                bordered={false}
                data={data}
                className="conteudo-tabela"
                infoLabel={["Mostrando", "para", "de", "entradas"]}
                searchLabel="Buscar"
                entriesLabel="Mostrar"
                paginationLabel={["Anterior", "Próximo"]}
            />
        </>
    );
};

export default ProfissionaisExcluidosTable;
