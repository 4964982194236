import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import qs from "qs";
import { Api } from "../../../providers";
import { ServiceContext } from "../../../contexts/service";
import { SearchField } from "./components/searchField";
import styles from "./styles.module.scss";

const Search = () => {
    const {
        setOperadores, setLoading, setTotalPages, setTotalElements,
        setParamsFilter
    } = useContext(ServiceContext);

    const [busca, setBusca] = useState("");
    const [ativo, setAtivo] = useState("");
    //  Categoria de Servicos
    const [perfis, setPerfis] = useState([]);
    const [perfilID, setPerfilID] = useState("");

    useEffect(() => {
        let mounted = true;
        Api.get("/operador-funcao/").then((res) => {
            if (mounted) {
                setPerfis(res.data);
            }
        });

        return () => {
            mounted = false;
            setPerfis({});
        };
    }, []);

    const handleSumbit = (event) => {
        setLoading(true);
        event.preventDefault();

        let params = {};
        if (busca !== "") params.fields = busca;
        if (ativo !== "0") params.flagAtivo = ativo;
        if (perfilID !== "0") params.funcaoId = perfilID;

        Api.get(`/operador/pagination/0/10?`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Provider
                setOperadores(res.data.content);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setParamsFilter(params);
                setLoading(false);
            })
            .catch(_ => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    }

    return (
        <div className="conteudo-tabela">
            <form>
                <div>
                    <div>
                        <SearchField
                            placeholder="Pesquise por nome ou CPF"
                            onClick={handleSumbit}
                            onChange={(e) => searchText(e.target.value)}
                            search={busca}
                        />
                    </div>

                    <div className={styles.containerFilds}>
                        <div className={styles.subContainerFilds}>
                            <span>Filtrar resultados</span>
                            <div className={styles.containerSelect}>
                                <div className={styles.fieldSelect}>
                                    <select className="form-select"
                                        value={ativo}
                                        onChange={(e) => setAtivo(e.target.value)}
                                    >
                                        <option value="" key="0" disabled>
                                            Status
                                        </option>
                                        <option value="0" key="999">
                                            Todos
                                        </option>
                                        <option value="true" key="1">
                                            Ativo
                                        </option>
                                        <option value="false" key="2">
                                            Inativo
                                        </option>
                                    </select>
                                </div>
                                <div className={styles.fieldSelect}>
                                    <select className="form-select"
                                        value={perfilID}
                                        onChange={(e) => setPerfilID(e.target.value)}
                                    >
                                        <option value="" key="0" disabled>
                                            Perfil
                                        </option>
                                        <option value="0" key="999">
                                            Todos
                                        </option>
                                        {perfis.map(({ id, nome }, key) => {
                                            return (
                                                <option value={id} key={key + 1}>
                                                    {nome}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Search;
