import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import Breadcrumb, { BreadCrumb } from "./../../../components/breadcrumb/index";
import Title from "./../../../components/title/index";
import TabsPanel from "./../../../components/tabs/cliente/";
import { Api } from "./../../../providers/index";
import { UserContext } from "./../../../contexts/user";
import { ClientesProvider } from "../../../contexts/service";
import { AuthContext } from "../../../contexts/auth";

const EditarCliente = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const { setPrintLayout } = useContext(UserContext);
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const [dataCliente, setDataCliente] = useState([]);
    const [nome, setNome] = useState("");
    //Context
    const tabsData = [
        { id: 1, tabLabel: "Dados pessoais", tabData: dataCliente },
        { id: 4, tabLabel: "Endereço", tabData: dataCliente },
        {
            id: 6,
            tabLabel: "Histórico de solicitações",
            tabData: null, //Dinamico
        },
        {
            id: 7,
            tabLabel: "Restrição de profissionais",
            tabData: null, //Dinamico
        },        
    ];

    useEffect(() => {
        setCurrentScreen(1);
    }, []);

    //Get cliente
    useEffect(() => {
        let mounted = true;
        Api.get(`/cliente/${idQuery}`).then((res) => {
            if (mounted) {
                setDataCliente(res.data);
                setNome(res.data.nome);
            }
            return mounted;
        });
        setPrintLayout(false);
        return () => {
            mounted = false;
            setDataCliente([]);
        };
    }, [idQuery]);

    return (
        <ClientesProvider>
            <Grid>
                <BreadCrumb
                    data={{
                        category: "Home > Usuários",
                        page: nome,
                        menu: {
                            item: "Usuários",
                            item2: "Clientes",
                            link: "/cliente/",
                        },
                    }}
                />
                <Title title={nome} />
                <TabsPanel tabsData={tabsData} />
            </Grid>
        </ClientesProvider>
    );
};

export default EditarCliente;
