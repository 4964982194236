import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ReactInputMask from "react-input-mask";
import { toast } from "react-toastify";
import ImageEyeOff from "../../../assets/images/icons/eye-off.png";
import ImageEyeOn from "../../../assets/images/icons/eye-on.png";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import { ModalFormUpdatePassword } from "../../../components/modal";
import Title from "../../../components/title/index";
import { Api } from "../../../providers";
import styles from "./styles.module.scss";

const EditarPerfilPage = () => {
    const [validated, setValidated] = useState(false);
    const [nome, setNome] = useState("");
    const [cpf, setCpf] = useState("");
    const [email, setEmail] = useState("");
    const [operadorID, setOperadorID] = useState("");

    const [usuarioID, setUsuarioID] = useState("");
    const [perfilID, setPerfilID] = useState("0");
    const [unidade, setUnidade] = useState("");
    const [senha, setSenha] = useState("");
    const [reSenha, setReSenha] = useState("");
    const [reSenha2, setReSenha2] = useState("");
    const [telefone, setTelefone] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [user, setUser] = useState("");
    const [eyeOn, setEyeOn] = useState(false);
    const [eyeOn2, setEyeOn2] = useState(false);
    const [eyeOn3, setEyeOn3] = useState(false);

    const operadorData = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        let mounted = true;
        setUser(operadorData);
        Api.get("/operador/filter?usuarioId=" + operadorData.usuario?.id).then(
            (res) => {
                if (mounted) {
                    setCpf(res.data[0]?.cpf);
                    setNome(res.data[0]?.nome);
                    setEmail(res.data[0]?.email);
                    setUnidade(res.data[0]?.unidadeId);
                    setPerfilID(res.data[0]?.funcao?.id);
                    setUsuarioID(res.data[0]?.usuarioId);
                    setOperadorID(res.data[0]?.id);
                    setTelefone(res.data[0]?.telefone);
                }
            },
        );

        return () => {
            mounted = false;
        };
    }, []);

    const handleAbrirModal = () => {
        setOpenModal(true);
    };

    const handleTrocaSenha = () => {
        const objSenha = {
            senhaAtual: senha,
            novaSenha: reSenha,
            confirmarNovaSenha: reSenha2,
        };

        if (reSenha.length >= 8 && reSenha.length >= 8) {
            Api.put("/operador/" + operadorID + "/alterar-senha/", objSenha)
                .then((res) => {
                    setOpenModal(false);
                    toast.success("Senha atualizada com Sucesso", {
                        toastId: "success",
                    });
                })
                .catch((err) => {
                    setOpenModal(false);
                    toast.error("Ops! Aconteceu algum problema!", {
                        toastId: "error",
                    });
                });
        }
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity()) {
            const operador = {
                id: operadorID,
                cpf: cpf,
                nome: nome,
                email: email,
                unidadeId: unidade,
                funcaoId: perfilID,
                usuarioId: usuarioID,
                telefone: telefone,
            };

            if (operador) {
                Api.put("/operador/" + operadorID, operador)
                    .then((res) => {
                        if (res)
                            toast.success("Atualizado com Sucesso", {
                                toastId: "success",
                            });
                    })
                    .catch((error) => {
                        const checkErro = error;
                        toast.error(
                            `Ops! ${checkErro.response.data.message?.error}`,
                            {
                                toastId: "error",
                            },
                        );
                    });
            }
        }
        setValidated(true);
    };

    const checkNome = nome.trim() && nome.length > 0 ? true : false;
    const checkTel = telefone.trim() && telefone.length > 0 ? true : false;
    const checkEmail =
        email.trim() && email.length > 0 && telefone !== "(__) ____-____"
            ? true
            : false;
    const checkButtonSubmit =
        checkNome && checkTel && checkEmail ? true : false;

    const senha1 = senha.trim() && senha.length > 0 ? true : false;
    const senha2 =
        reSenha.trim() && reSenha.length >= 8 && reSenha === reSenha2
            ? true
            : false;
    const senha3 =
        reSenha2.trim() && reSenha2.length >= 8 && reSenha === reSenha2
            ? true
            : false;

    const checkButtonModal = senha1 && senha2 && senha3 ? true : false;

    return (
        <>
            <div className={styles.containerGeral}>
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Meu perfil",
                    }}
                />
                <Title title="Meu perfil" />
                <div className={styles.subContainer}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <Form.Group>
                            <Form.Label
                                htmlFor="nome"
                                className={`${styles.textLabel}`}
                            >
                                Nome completo
                            </Form.Label>
                            <Form.Control
                                className={styles.containerInputFull}
                                type="text"
                                placeholder="Seu nome"
                                name="nome"
                                id="nome"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <div className={styles.containerGroup}>
                            <Form.Group>
                                <Form.Label
                                    className={`${styles.textLabel} ${styles.marginTopLabel}`}
                                >
                                    CPF
                                </Form.Label>
                                <Form.Control
                                    className={styles.inputItem}
                                    type="text"
                                    placeholder="CPF"
                                    name="cpf"
                                    id="cpf"
                                    value={cpf}
                                    disabled
                                />
                            </Form.Group>

                            <Form.Group className={styles.groupFone}>
                                <Form.Label
                                    className={`${styles.textLabel} ${styles.marginTopLabel}`}
                                >
                                    Telefone
                                </Form.Label>
                                <ReactInputMask
                                    className={`${styles.inputItem} ${styles.inputFone}`}
                                    mask="(99) 9999-9999"
                                    placeholder="Telefone"
                                    name="telefone"
                                    id="telefone"
                                    minLength={11}
                                    value={telefone}
                                    onChange={(e) =>
                                        setTelefone(e.target.value)
                                    }
                                    required
                                />
                            </Form.Group>
                        </div>
                        <Form.Group>
                            <Form.Label
                                className={`${styles.textLabel} ${styles.marginTopLabel}`}
                            >
                                Perfil de operador
                            </Form.Label>
                            <Form.Select
                                value={perfilID}
                                className={`form-select ${styles.formDropdown}`}
                                onChange={(e) => setPerfilID(e.target.value)}
                                auto
                                disabled
                            >
                                <option value="0" key="0" disabled>
                                    Perfil
                                </option>
                                <option>
                                    {operadorData.operador.funcao.nome}
                                </option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label
                                className={`${styles.textLabel} ${styles.marginTopLabel}`}
                            >
                                E-mail
                            </Form.Label>
                            <Form.Control
                                className={styles.containerInputFull}
                                type="email"
                                placeholder="E-mail"
                                name="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <div className={styles.containerTextAndBtn}>
                            <Form.Group className="my-5">
                                <div>
                                    <span
                                        onClick={handleAbrirModal}
                                        className={styles.textEmailUpdate}
                                    >
                                        Alterar senha
                                    </span>
                                </div>
                            </Form.Group>

                            <Form.Group className="my-5">
                                <button
                                    className={
                                        !checkButtonSubmit
                                            ? styles.buttonSearchBlock
                                            : styles.buttonSave
                                    }
                                    disabled={!checkButtonSubmit}
                                    type="submit"
                                >
                                    <span>Salvar</span>
                                </button>
                            </Form.Group>
                        </div>
                    </Form>
                </div>
            </div>
            <ModalFormUpdatePassword
                styles={{
                    minHeight: "578px",
                    width: "740px",
                }}
                title="Alterar senha"
                description=""
                openModal={openModal}
                setOpenModal={setOpenModal}
                labelConfirm="OK"
            >
                <div className={styles.containerModal}>
                    <Grid>
                        <Form.Group>
                            <Form.Label className={styles.labelModal}>
                                Senha atual
                            </Form.Label>
                            <div className={styles.containerInputControl}>
                                <Form.Control
                                    className={styles.inputControl}
                                    type={eyeOn ? "text" : "password"}
                                    placeholder="Senha atual"
                                    name="senha"
                                    id="senha"
                                    value={senha}
                                    autoComplete="off"
                                    onChange={(e) => setSenha(e.target.value)}
                                />
                                <div
                                    className={styles.imageEye}
                                    onClick={() => setEyeOn(!eyeOn)}
                                >
                                    <img
                                        src={eyeOn ? ImageEyeOn : ImageEyeOff}
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </Grid>
                    <Grid>
                        <Form.Group>
                            <Form.Label
                                className={`${styles.labelModal} ${styles.labelMarginTopModal}`}
                            >
                                Nova senha
                            </Form.Label>
                            <div className={styles.containerInputControl}>
                                <Form.Control
                                    className={styles.inputControl}
                                    required={senha.length > 0}
                                    type={eyeOn2 ? "text" : "password"}
                                    placeholder="Digite aqui a sua nova senha"
                                    name="resenha"
                                    id="resenha"
                                    value={reSenha}
                                    minLength="8"
                                    onChange={(e) => setReSenha(e.target.value)}
                                />
                                <div
                                    className={styles.imageEye}
                                    onClick={() => setEyeOn2(!eyeOn2)}
                                >
                                    <img
                                        src={eyeOn2 ? ImageEyeOn : ImageEyeOff}
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            </div>
                            <span className={styles.textInformativo}>
                                Mínimo 8 caracteres
                            </span>
                        </Form.Group>
                    </Grid>
                    <Grid>
                        <Form.Group>
                            <Form.Label
                                className={`${styles.labelModal} ${styles.labelMarginTopModal}`}
                            >
                                Confirmar nova senha
                            </Form.Label>
                            <div className={styles.containerInputControl}>
                                <Form.Control
                                    className={styles.inputControl}
                                    required={senha.length > 0}
                                    type={eyeOn3 ? "text" : "password"}
                                    placeholder="Confirme a sua nova senha"
                                    name="resenha2"
                                    id="resenha2"
                                    value={reSenha2}
                                    minLength="8"
                                    onChange={(e) =>
                                        setReSenha2(e.target.value)
                                    }
                                />
                                <div
                                    className={styles.imageEye}
                                    onClick={() => setEyeOn3(!eyeOn3)}
                                >
                                    <img
                                        src={eyeOn3 ? ImageEyeOn : ImageEyeOff}
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </Grid>
                    <div
                        className={styles.containerBtnModal}
                        onClick={() => handleTrocaSenha()}
                    >
                        <button
                            className={
                                !checkButtonModal
                                    ? styles.buttonSearchBlockModal
                                    : styles.buttonSaveModal
                            }
                            disabled={!checkButtonModal}
                            type="button"
                        >
                            <span>OK</span>
                        </button>
                    </div>
                </div>
            </ModalFormUpdatePassword>
        </>
    );
};

export default EditarPerfilPage;
