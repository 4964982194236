import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import imgCpf from "../../../assets/images/cpf.png";
import imgEmail from "../../../assets/images/email.png";
import imgNome from "../../../assets/images/nome.png";
import imgServico from "../../../assets/images/servico.png";
import imgTelefone from "../../../assets/images/telefone.png";
import imgUnidade from "../../../assets/images/unidade.png";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import { ModalLoading } from "../../../components/modal";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { Api } from "../../../providers";
import { CpfMaskRegex, TelMaskRegex } from "../../../utils/mask";
import styles from "./styles.module.scss";

const EditarOperadorPage = () => {
    const navigate = useNavigate();

    const { setCurrentScreen } = useContext(AuthContext);
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const [nome, setNome] = useState("");
    const [cpf, setCpf] = useState("");
    const [email, setEmail] = useState("");
    //Função
    const [funcoesLista, setFuncoesLista] = useState([]);
    const [funcaoId, setFuncaoId] = useState("0");
    const [idTipoPerfil, setIdTipoPerfil] = useState(0);
    //Undade
    const [unidadesLista, setUnidadesLista] = useState([]);
    const [unidadeId, setUnidadeId] = useState("");
    const [ativo, setAtivo] = useState("");
    const [unidadeIdLocal, setUnidadeIdLocal] = useState(0);
    const [statusSwitch, setStatusSwitch] = useState(false);
    const [todasPermiSwitch, setTodasPermiSwitch] = useState(false);
    const [permDados, setPermDados] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);
    const [tipoOperador, setTipoOperador] = useState("");
    const [dadosServidor, setDadosServidor] = useState([]);
    const [atualizaDados, setAtualizaDados] = useState(false);
    const [telefone, setTelefone] = useState("");

    let mounted = true;
    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp =
        operador.operador.funcao.slug === "administrador" ? true : false;

    const handleRedirect = () => {
        navigate("/operador");
    };

    useEffect(() => {
        if (mounted) {
            if (!checkOp) {
                handleRedirect();
            }
            setCurrentScreen(3);
        }
        return () => {
            mounted = false;
        };
    }, []);

    const manterDadosServidor = () => {
        Api.get("/operador/" + idQuery).then((res) => {
            if (res.status == 200 && mounted) {
                setDadosServidor([res.data]);
            }
        });
    };

    useEffect(() => {
        setLoadingModal(true);

        async function filterData() {
            try {
                await Promise.all([
                    Api.get("/unidade/").then((res) => {
                        if (res.status == 200 && mounted) {
                            const resultData = res.data.filter(
                                (val) => val.flagAtivo,
                            );
                            setUnidadesLista(resultData);
                        }
                    }),
                    Api.get("/operador/" + idQuery).then((res) => {
                        if (res.status == 200 && mounted) {
                            setCpf(res.data.cpf);
                            setNome(res.data.nome);
                            setEmail(res.data.email);
                            setTelefone(res.data.telefone);
                            setUnidadeId(res.data.unidadeId);
                            setUnidadeIdLocal(res.data.unidadeId);
                            setFuncaoId(res.data.funcao?.id);
                            setIdTipoPerfil(res.data.funcao?.id);
                            setTipoOperador(res.data.funcao?.slug);
                            setAtivo(!!res.data.usuario?.flagAtivo);
                            setStatusSwitch(!!res.data.usuario?.flagAtivo);
                            setPermDados([res.data]);
                            manterDadosServidor();
                            let totalTela = 0;
                            let totalPermis = 0;
                            const _ = res.data.permissoes.filter((val) => {
                                // Total de telas ativas
                                if (val.tela.visualizar) {
                                    totalTela += 1;
                                }
                                if (val.tela.criar) {
                                    totalTela += 1;
                                }
                                if (val.tela.atualizar) {
                                    totalTela += 1;
                                }
                                if (val.tela.excluir) {
                                    totalTela += 1;
                                }

                                // Total de permissoes ativas
                                if (val.visualizar) {
                                    totalPermis += 1;
                                }
                                if (val.criar) {
                                    totalPermis += 1;
                                }
                                if (val.atualizar) {
                                    totalPermis += 1;
                                }
                                if (val.excluir) {
                                    totalPermis += 1;
                                }
                            });
                            if (
                                (totalTela > 0 && totalTela == totalPermis) ||
                                res.data.funcao?.slug == "administrador"
                            ) {
                                setTodasPermiSwitch(true);
                            } else {
                                // setTodasPermiSwitch(false);
                                temTodasAsPermissoes(res.data.permissoes);
                            }
                        }
                    }),
                    Api.get("/operador-funcao/").then((res) => {
                        if (res.status == 200 && mounted) {
                            setFuncoesLista(res.data);
                        }
                    }),
                ]);
            } catch (_) {
                toast.error("Ops! Aconteceu algum problema com os filtros!", {
                    toastId: "msg",
                });
            } finally {
                setLoadingModal(false);
            }
        }
        filterData();
        return () => {
            mounted = false;
        };
    }, [idQuery, atualizaDados]);

    const handleSubmit = () => {
        setLoadingModal(true);

        Api.put("/operador/" + idQuery, permDados[0])
            .then((res) => {
                if (res.status == 200) {
                    setAtualizaDados(!atualizaDados);
                    toast.success("Atualizado com sucesso", {
                        toastId: "success",
                    });
                }
            })
            .catch((error) => {
                const checkErro = error;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            })
            .finally(() => {
                setLoadingModal(false);
            });
    };

    if (!checkOp) {
        return <></>;
    }

    const modificaSwitchLocal = (tipoStatus, idTela, acao) => {
        const tipoAcao = acao.toLowerCase();
        const dadosPermis = permDados[0]?.permissoes.map((val) => {
            if (val.telaId == idTela) {
                switch (tipoAcao) {
                    case "criar":
                        val[tipoAcao] = tipoStatus;
                        if (tipoStatus) {
                            val["visualizar"] = tipoStatus;
                        }

                        break;

                    case "editar":
                        val["atualizar"] = tipoStatus;
                        if (tipoStatus) {
                            val["visualizar"] = tipoStatus;
                        }

                        break;

                    case "excluir":
                        val[tipoAcao] = tipoStatus;
                        if (tipoStatus) {
                            val["visualizar"] = tipoStatus;
                        }

                        break;
                    case "visualizar":
                        val[tipoAcao] = tipoStatus;
                        if (!tipoStatus) {
                            val["criar"] = tipoStatus;
                            val["atualizar"] = tipoStatus;
                            val["excluir"] = tipoStatus;
                        }

                        break;
                }
            }

            return { ...val };
        });
        const resultDados = permDados.map((val) => {
            return {
                ...val,
                permissoes: dadosPermis,
            };
        });
        if (todasPermiSwitch && !tipoStatus) {
            setTodasPermiSwitch(tipoStatus);
        }

        let totalTela = 0;
        let totalPermis = 0;
        const _ = resultDados[0]?.permissoes.filter((val) => {
            // Total de telas
            if (val.tela.visualizar) {
                totalTela += 1;
            }
            if (val.tela.criar) {
                totalTela += 1;
            }
            if (val.tela.atualizar) {
                totalTela += 1;
            }
            if (val.tela.excluir) {
                totalTela += 1;
            }

            // Total de permissoes ativas
            if (val.visualizar) {
                totalPermis += 1;
            }
            if (val.criar) {
                totalPermis += 1;
            }
            if (val.atualizar) {
                totalPermis += 1;
            }
            if (val.excluir) {
                totalPermis += 1;
            }
        });
        if (totalTela == totalPermis) {
            setTodasPermiSwitch(true);
        }

        setPermDados(resultDados);
    };

    const temTodasAsPermissoes = (dadoPermis) => {
        const permissoes = [];

        dadoPermis.forEach(function(objeto, index) {
            permissoes.push(objeto.atualizar);
            permissoes.push(objeto.criar);
            permissoes.push(objeto.excluir);
            permissoes.push(objeto.visualizar);
        });

        console.log('[forEach]', permissoes.includes(false));
        setTodasPermiSwitch(!permissoes.includes(false));
    };

    const ativarTodasPermis = (dadoPermis) => {
        setTodasPermiSwitch(dadoPermis);
        const resultDados = permDados.map((val) => {
            const result = val.permissoes.map((perm) => {
                return {
                    ...perm,
                    visualizar: dadoPermis,
                    criar: dadoPermis,
                    atualizar: dadoPermis,
                    excluir: dadoPermis,
                };
            });
            return {
                ...val,
                permissoes: result,
            };
        });
        setPermDados(resultDados);
    };

    const alterarStatus = (statusOper) => {
        setStatusSwitch(statusOper);
        const resultDados = permDados.map((val) => {
            const usuario = {
                ...val.usuario,
                flagAtivo: statusOper,
            };
            return {
                ...val,
                usuario,
            };
        });
        setPermDados(resultDados);
    };

    const alterarDropDown = (tipoAcao, idDado, callBack) => {
        const resultDados = permDados.map((val) => {
            return {
                ...val,
                [tipoAcao]: Number(idDado),
            };
        });
        setPermDados(resultDados);
        callBack(idDado);
    };

    const checkFilterSubmit =
        JSON.stringify(dadosServidor[0]) != JSON.stringify(permDados[0])
            ? false
            : true;

    const returnEdit = (nomeTela, acao, statusTipo, idTela) => {
        return (
            <div className={styles.containerSwitchAtivar}>
                <span className={styles.textSwitchEdit}>
                    {acao} {nomeTela}
                </span>
                <div>
                    <input
                        type="checkbox"
                        checked={statusTipo}
                        onChange={(e) =>
                            modificaSwitchLocal(!statusTipo, idTela, acao)
                        }
                        id={styles.checkbox}
                    />
                </div>
            </div>
        );
    };

    return (
        <Container fluid className="containerForm justify-content-center">
            <BreadCrumb
                data={{
                    category: "",
                    page: "Editar",
                    menu: {
                        item: "Configurações",
                        link: "/operador",
                    },
                }}
            />
            <Title title="Editar operador" />
            <Container className={styles.container01}>
                <div className={styles.container01Interno}>
                    <div
                        className={`${styles.colorSatusAtivo} ${
                            !ativo ? styles.colorSatusInativo : ""
                        }`}
                    >
                        <span>{ativo ? "Ativo" : "Inativo"}</span>
                    </div>
                    <div>
                        <div>
                            <div>
                                <img
                                    src={imgNome}
                                    alt="Nome"
                                    width="24px"
                                    height="24px"
                                />
                                <span className={styles.spanDados}>{nome}</span>
                            </div>
                            <div>
                                <img
                                    src={imgCpf}
                                    alt="Nome"
                                    width="24px"
                                    height="24px"
                                />
                                <span className={styles.spanDados}>
                                    {cpf.length == 11 ? CpfMaskRegex(cpf) : cpf}
                                </span>
                            </div>
                            <div>
                                <img
                                    src={imgTelefone}
                                    alt="Nome"
                                    width="24px"
                                    height="24px"
                                />
                                <span className={styles.spanDados}>
                                    {TelMaskRegex(telefone)}
                                </span>
                            </div>
                        </div>

                        <div className="segundaParte">
                            <div>
                                <img
                                    src={imgServico}
                                    alt="Nome"
                                    width="24px"
                                    height="24px"
                                />
                                <span className={styles.spanDados}>
                                    {idTipoPerfil
                                        ? funcoesLista.filter(
                                              ({ id }) => id === idTipoPerfil,
                                          )[0]?.nome
                                        : ""}
                                </span>
                            </div>

                            <div>
                                <img
                                    src={imgEmail}
                                    alt="Nome"
                                    width="24px"
                                    height="24px"
                                />
                                <span className={styles.spanDados}>
                                    {email}
                                </span>
                            </div>

                            <div>
                                <img
                                    src={imgUnidade}
                                    alt="Nome"
                                    width="24px"
                                    height="24px"
                                />
                                <span className={styles.spanDados}>
                                    {unidadeId
                                        ? unidadesLista.filter(
                                              ({ id }) => id === unidadeId,
                                          )[0]?.nome
                                        : ""}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <Container className={styles.formularioContainer}>
                <div className={`${styles.containerInput}`}>
                    <div className={`row ${styles.containerRow}`}>
                        <div className={`col ${styles.inputLine}`}>
                            <div>
                                <label className="form-label">
                                    Perfil do operador
                                </label>
                                <select
                                    className={`form-select`}
                                    value={funcaoId}
                                    onChange={(e) =>
                                        alterarDropDown(
                                            "funcaoId",
                                            e.target.value,
                                            setFuncaoId,
                                        )
                                    }
                                >
                                    <option value="" key="0" disabled>
                                        Selecione o perfil do operador
                                    </option>
                                    {funcoesLista.map(({ id, nome }) => {
                                        return (
                                            <option value={id} key={id}>
                                                {nome}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className={`col ${styles.inputLine}`}>
                            <div>
                                <label className="form-label">Unidade</label>
                                <select
                                    className="form-select"
                                    value={unidadeIdLocal}
                                    onChange={(e) =>
                                        alterarDropDown(
                                            "unidadeId",
                                            e.target.value,
                                            setUnidadeIdLocal,
                                        )
                                    }
                                >
                                    <option value="" key="0" disabled>
                                        Selecione um unidade
                                    </option>
                                    {unidadesLista.map((data) => {
                                        return (
                                            <option
                                                value={data.id}
                                                key={data.id}
                                            >
                                                {data.nome}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.switchContainer}>
                    <div>
                        <span className={styles.statusOper}>
                            Status do operador
                        </span>
                        <div className={styles.containerSwitch}>
                            <span className={styles.textSwitch}>Ativo</span>
                            <div>
                                <input
                                    type="checkbox"
                                    checked={statusSwitch}
                                    onChange={(e) =>
                                        alterarStatus(!statusSwitch)
                                    }
                                    id={styles.checkbox}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.switchContainer}>
                    <div>
                        <span className={styles.statusOper}>Permissões</span>
                        <div className={styles.containerSwitchAtivarTodas}>
                            <span className={styles.textSwitchPerm}>
                                Ativar todas as permissões
                            </span>
                            <div>
                                <input
                                    type="checkbox"
                                    checked={todasPermiSwitch}
                                    onChange={(e) =>
                                        ativarTodasPermis(!todasPermiSwitch)
                                    }
                                    id={
                                        tipoOperador == "administrador"
                                            ? styles.checkboxDesabil
                                            : styles.checkbox
                                    }
                                    disabled={
                                        tipoOperador == "administrador"
                                            ? true
                                            : false
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {!todasPermiSwitch && (
                    <div className={styles.switchContainer}>
                        <div>
                            {permDados[0]?.permissoes?.length > 0 &&
                                permDados[0]?.permissoes.map((val, idx) => {
                                    const numero = idx + 1;
                                    if (val.tela.visualizar) {
                                        return (
                                            <div
                                                className={
                                                    styles.geralContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.containerSwitchAtivar
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            styles.textSwitchPermissoes
                                                        }
                                                    >
                                                        {numero}. Visualizar{" "}
                                                        {val.tela.nome}
                                                    </span>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                val.visualizar
                                                            }
                                                            onChange={(e) =>
                                                                modificaSwitchLocal(
                                                                    !val.visualizar,
                                                                    val.telaId,
                                                                    "visualizar",
                                                                )
                                                            }
                                                            id={styles.checkbox}
                                                        />
                                                    </div>
                                                </div>

                                                {/* *************************************** */}

                                                {val.tela.criar &&
                                                    returnEdit(
                                                        val.tela.nome,
                                                        "Criar",
                                                        val.criar,
                                                        val.tela.id,
                                                    )}
                                                {val.tela.atualizar &&
                                                    returnEdit(
                                                        val.tela.nome,
                                                        "Editar",
                                                        val.atualizar,
                                                        val.tela.id,
                                                    )}
                                                {val.tela.excluir &&
                                                    returnEdit(
                                                        val.tela.nome,
                                                        "Excluir",
                                                        val.excluir,
                                                        val.tela.id,
                                                    )}

                                                {/* *************************************** */}
                                            </div>
                                        );
                                    }
                                })}
                        </div>
                    </div>
                )}

                {/* ************************************************************* */}
                <Container>
                    <div className={`${styles.containerButton}`}>
                        <div className={styles.bntSalvar}>
                            <button
                                className={
                                    checkFilterSubmit
                                        ? styles.buttonSalvarBlock
                                        : styles.buttonSalvar
                                }
                                type="button"
                                onClick={
                                    checkFilterSubmit
                                        ? () => {}
                                        : () => handleSubmit()
                                }
                            >
                                <span>Salvar</span>
                            </button>
                        </div>
                    </div>
                </Container>
            </Container>

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </Container>
    );
};

export default EditarOperadorPage;
