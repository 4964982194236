import { useEffect, useRef, useState } from "react";
import { Api } from "../providers";

// ==============================|| ELEMENT REFERENCE HOOKS  ||============================== //

const useScriptRef = () => {
    const scripted = useRef(true);

    useEffect(
        () => () => {
            scripted.current = false;
        },
        [],
    );

    return scripted;
};

export default useScriptRef;

export const useUnidades = (props) => {
    const [unidades, setUnidades] = useState([]);
    let mounted = true;

    useEffect(() => {
        Api.get("/unidade/").then((res) => {
            if (mounted) {
                let result = res.data.filter((val) => val.flagAtivo);
                if (props?.multiSelect) {
                    result.push({
                        id: 0,
                        nome: "Todas as unidades",
                        flagAtivo: true,
                        telefonePrincipal: "7734242143",
                        telefoneSecundario: "7734229992",
                        email: null,
                        cep: "45000410",
                        logradouro: "Rua Rotary Club",
                        bairro: "Centro",
                        numero: "149",
                        complemento: "sl 5",
                        latitude: "-14.8536688",
                        longitude: "-40.8383800",
                    });
                }
                setUnidades(result);
            }
        });
        return () => {
            mounted = false;
        };
    }, []);

    return unidades;
};
