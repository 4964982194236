import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import { ModalConfirm } from "../../../components/modal";
import Title from "../../../components/title/index";
import { Api } from "../../../providers";
import { DateBR, TimeBR } from "../../../utils/date";
import styles from "./styles.module.scss";

const InativarProfissionalPage = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const [profissional, setProfissional] = useState({});
    const [motivo, setMotivo] = useState("0");
    const [observacao, setObservacao] = useState("");
    const [status, setStatus] = useState("convocado");
    const [motivos, setMotivos] = useState([]);
    const [tituloModal, setTituloModal] = useState("Reativar profissional");
    const [descricaoModal, setDescricaoModal] = useState(
        "Deseja realmente reativar este profissional ao Contrate.ba",
    );
    const [labelConfirmModal, setLabelConfirmModal] = useState("Confirmar");
    const [openModal, setOpenModal] = useState(false);
    const [labelLastAlter, setLabelLastAlter] = useState("");
    //Situação
    const [situacao, setSituacao] = useState([]);
    const [initialSituacaoId, setInitialSituacaoId] = useState();
    const [currentDateTime, setCurrentDateTime] = useState("");
    const [textObsError, setTextObsError] = useState("Campo obrigatório");
    const [textReqError, setTextReqError] = useState("Campo obrigatório");
    const [prevSit, setPrevSit] = useState([]);
    const [checkHist, setCheckHist] = useState([]);
    const [statusIdUpdate, setStatusIdUpdate] = useState(0);
    const [textHist, setTextHist] = useState("");
    const [situacaoId, setSituacaoId] = useState(0);

    let mounted = true;
    const operador = JSON.parse(localStorage.getItem("user"));

    const typeOp = ["selecao-e-treinamento", "gestor", "administrador"];
    const checkOp = typeOp.includes(operador.operador.funcao.slug)
        ? true
        : false;

    const canStatusBeUpdated = (initialStatus, newStatus) => {
        const disabled = ["inscrito", "convocado", "treinamento", "ativacao"];
        const currentStatusAtive = ["licenca", "suspenso", "inativo"];
        const histData = [prevSit[0]?.slug ?? ""];
        const histUserOld = [checkHist[0]?.situacao.slug ?? ""];
        const idHist = [
            "licenca",
            "inabilitado",
            "suspenso",
            "bloqueado",
            "inativo",
        ];
        const flagMig = profissional?.usuario?.flagMigracao ?? false;

        if (disabled.includes(initialStatus) && newStatus !== "inabilitado") {
            return true;
        }

        if (
            initialStatus == "ativo" &&
            !currentStatusAtive.includes(newStatus)
        ) {
            return true;
        }

        if (
            initialStatus == "inabilitado" &&
            !histData.includes(newStatus) &&
            checkHist.length > 1
        ) {
            return true;
        }

        // ******************************************************************

        if (
            idHist.includes(initialStatus) &&
            checkHist.length == 1 &&
            flagMig &&
            !histUserOld.includes(newStatus)
        ) {
            return true;
        }
        // ******************************************************************

        if (initialStatus == "licenca" && newStatus != "ativo") {
            return true;
        }

        if (initialStatus == "suspenso" && newStatus != "ativo") {
            return true;
        }

        if (initialStatus == "bloqueado") {
            return true;
        }

        if (initialStatus == "inativo" && newStatus != "ativo") {
            return true;
        }

        return false;
    };

    useEffect(() => {
        const idProfissional = new URLSearchParams(search).get("id");
        Api.get("/profissional/" + idQuery).then((res) => {
            setProfissional(res.data);

            setStatusIdUpdate(res.data.situacao.slug);
            setStatus(res.data.situacao.slug);
            setInitialSituacaoId(res.data.situacao.slug);
            //Situação
            Api.get("/profissional-situacao/").then((res2) => {
                if (res2.status === 200) {
                    setSituacao(res2.data);
                }
            });

            // Get last status alteration
            Api.get("/profissional/" + idQuery + "/historico").then((res3) => {
                if (res3.status === 200) {
                    setCheckHist(res3.data);
                    let motivo = res3.data[0]?.descricao
                        ? res3.data[0]?.descricao
                        : "";
                    let textTemp = res3.data[0]?.usuario?.nome;
                    if (res3.data[0]?.dataCadastro) {
                        textTemp =
                            textTemp +
                            " - " +
                            DateBR(res3.data[0]?.dataCadastro) +
                            " " +
                            TimeBR(res3.data[0]?.dataCadastro);
                    }

                    setLabelLastAlter(textTemp);
                    setTextHist(motivo);

                    if (res3.data[1]?.situacao?.id > 0) {
                        setPrevSit([res3.data[1]?.situacao]);
                    }
                    setLabelLastAlter(textTemp);
                }
                å;
            });
        });

        Api.get("/system/date").then((res) => {
            if (mounted) {
                setCurrentDateTime(res.data.dateBr + " " + res.data.hoursBr);
            }
        });

        return () => {
            mounted = false;
        };
    }, []);

    const handleSubmit = () => {
        setOpenModal(true);
    };

    const handleClick = () => {
        const obsInfo = motivo == "Outros" ? observacao : "";
        let corpo = {
            motivo: motivo,
            descricao: obsInfo,
            situacaoId: situacaoId,
            profissionalId: idQuery,
            usuarioId: operador.operador.id,
        };
        Api.post("/profissional/historico", corpo)
            .then((res) => {
                toast.success("Status atualizado com sucesso", {
                    toastId: "success",
                });
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "error",
                });
            });
        setOpenModal(false);

        navigate("/profissional");
    };

    const handleChangeSituacao = (idEstado) => {
        if (initialSituacaoId == idEstado) {
            return;
        }

        const checkSit = situacao.find((data) => data.slug == idEstado);
        setSituacaoId(checkSit.id);

        setMotivo("0");
        setTextReqError("Campo obrigatório");
        setStatus(idEstado);
        let movivosTemp = [];
        switch (idEstado) {
            // Ativo
            case "ativo":
                setTituloModal("Reativar profissional");
                setDescricaoModal(
                    "Deseja realmente reativar este profissional ao Contrate.ba",
                );
                setLabelConfirmModal("Confirmar");
                break;
            // Licenca
            case "licenca":
                movivosTemp = [
                    { id: 1, nome: "Falecimento" },
                    { id: 2, nome: "A pedido do profissional" },
                    { id: 3, nome: "Pontuação" },
                    { id: 4, nome: "Outros" },
                ];
                // setMotivo("0");
                setTextReqError("Campo obrigatório");
                setTituloModal("Licenciar profissional");
                setDescricaoModal(
                    "Profissional de licença! A partir de agora o profissional não poderá ter novos serviços agendados, mas ele ainda terá acesso ao sistema Contrate.ba",
                );
                setLabelConfirmModal("Licenciar");
                break;
            // Inabilitado
            case "inabilitado":
                movivosTemp = [
                    { id: 1, nome: "Sem experiência" },
                    {
                        id: 2,
                        nome: "Impossibilidade de contato com o profissional",
                    },
                    { id: 3, nome: "Sem anexo dos documentos solicitados" },
                    { id: 4, nome: "Documentos incompletos ou não validados" },
                    { id: 5, nome: "Convocado, mas faltou ao treinamento" },
                    { id: 6, nome: "Desistência durante o processo seletivo" },
                    {
                        id: 7,
                        nome: "Deixou de cumprir alguma etapa do processo seletivo",
                    },
                    { id: 8, nome: "Não compareceu ao treinamento" },
                    { id: 9, nome: "Apresentou conduta inadequada" },
                    { id: 10, nome: "Desistência" },
                    { id: 11, nome: "Outros" },
                ];
                setTituloModal("Inabilitação de profissional");
                setDescricaoModal(
                    "Tem certeza que deseja inabilitar esse profissional",
                );
                setLabelConfirmModal("Inabilitar");
                break;
            //Suspenso
            case "suspenso":
                movivosTemp = [
                    { id: 1, nome: "Ocorrência em Análise" },
                    { id: 2, nome: "Falta de contato" },
                    { id: 4, nome: "Outros" },
                ];
                setTituloModal("Suspensão do profissional");
                setDescricaoModal(
                    "Profissional suspenso! A partir de agora o profissional não poderá ter novos serviços agendados, mas ele ainda terá acesso ao sistema Contrate.ba",
                );
                setLabelConfirmModal("Suspender");
                break;
            //Inativo
            case "inativo":
                movivosTemp = [
                    { id: 1, nome: "Falecimento" },
                    { id: 2, nome: "A pedido do profissional" },
                    { id: 3, nome: "Pontuação" },
                    { id: 4, nome: "Outros" },
                ];
                setTituloModal("Inativação do profissional");
                setDescricaoModal(
                    "Os serviços agendados para esse profissional serão cancelados automaticamente. Deseja realmente inativar?",
                );
                setLabelConfirmModal("Inativar");
                break;
        }
        setMotivos(movivosTemp);
    };

    if (Object.values(profissional).length <= 0) {
        return <Title title="Carregando..." />;
    }

    const handleSelect = (text) => {
        const result = parseInt(text);
        if (text !== "0") {
            setMotivo(text);
            setTextReqError("");
        } else {
            setMotivo("0");
            setTextReqError("Campo obrigatório");
        }
    };

    function textObs(desc) {
        if (desc.length <= 0) {
            setObservacao("");
            setTextObsError("Campo obrigatório");
        } else {
            if (desc.length <= 1500 && desc.trim()) {
                setObservacao(desc);
                setTextObsError("");
            }
        }
    }

    const statusButtonActive = [
        "inscrito",
        "convocado",
        "treinamento",
        "ativo",
        "bloqueado",
        "ativacao",
    ];
    const actBut =
        status != initialSituacaoId && statusButtonActive.includes(status)
            ? false
            : true;

    const disabledInfo =
        status == initialSituacaoId ||
        motivo == "0" ||
        (motivo == "Outros" && textObsError.length > 0)
            ? actBut
            : false;

    const checkTypeOperator =
        textReqError.length <= 0 && textObsError.length <= 0
            ? false
            : disabledInfo;

    let statusButton = checkTypeOperator;

    if (!checkOp) {
        statusButton = true;
    }

    const checkUpdateStatus = ["licenca", "inabilitado", "suspenso", "inativo"];

    return (
        <>
            <ModalConfirm
                title={tituloModal}
                description={descricaoModal}
                openModal={openModal}
                setOpenModal={setOpenModal}
                onClick={handleClick}
                labelConfirm={labelConfirmModal}
                color={labelConfirmModal == "Confirmar" ? "blue" : ""}
                labelXSair="nao"
            />

            <Container maxWidth="xl" fluid>
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Status do profissional",
                        menu: {
                            item: "Usuários",
                            link: "/profissional",
                        },
                    }}
                />
                <Title title="Status do profissional" />
                <Container maxWidth="xl" className={styles.formulario}>
                    <form className={styles.formularioItem}>
                        <Form.Group className={styles.formGroup}>
                            <div className={styles.fieldSelect}>
                                <div>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        className="select-modalidade"
                                        value={status}
                                        onChange={(e) =>
                                            handleChangeSituacao(e.target.value)
                                        }
                                    >
                                        {situacao.map(
                                            ({ id, nome, slug }, key) => {
                                                return (
                                                    <option
                                                        value={slug}
                                                        key={key + 1}
                                                        disabled={canStatusBeUpdated(
                                                            initialSituacaoId,
                                                            slug,
                                                        )}
                                                    >
                                                        {nome}
                                                    </option>
                                                );
                                            },
                                        )}
                                    </Form.Select>
                                </div>
                            </div>
                        </Form.Group>

                        {status != "convocado" && motivos.length != 0 ? (
                            <Form.Group className={styles.formGroup}>
                                <div
                                    className={`${styles.fieldSelect} ${
                                        textReqError
                                            ? styles.formGroupError
                                            : ""
                                    }`}
                                >
                                    <div>
                                        <Form.Label
                                            className={
                                                textReqError
                                                    ? styles.textLabelRed
                                                    : ""
                                            }
                                        >
                                            Motivo
                                        </Form.Label>
                                        <Form.Select
                                            className="select-modalidade"
                                            value={motivo}
                                            onChange={(e) =>
                                                handleSelect(e.target.value)
                                            }
                                        >
                                            <option value="0" key="999">
                                                Selecionar motivo
                                            </option>
                                            {motivos.map(
                                                ({ id, nome }, key) => {
                                                    return (
                                                        <option
                                                            value={nome}
                                                            key={key + 1}
                                                        >
                                                            {nome}
                                                        </option>
                                                    );
                                                },
                                            )}
                                        </Form.Select>
                                    </div>
                                </div>
                            </Form.Group>
                        ) : (
                            ""
                        )}
                        {motivo == "Outros" && statusIdUpdate !== status && (
                            <Form.Group className={styles.formGroupTextArea}>
                                <Form.Label
                                    className={`${
                                        textObsError
                                            ? styles.textLabelRed
                                            : styles.textLabel
                                    }`}
                                >
                                    Observação
                                </Form.Label>
                                <div
                                    className={
                                        textObsError
                                            ? styles.formGroupError
                                            : ""
                                    }
                                >
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="motivo"
                                        id="motivo"
                                        value={observacao}
                                        maxLength={300}
                                        onChange={(e) =>
                                            textObs(e.target.value)
                                        }
                                        placeholder="Registrar observação"
                                    />
                                </div>

                                <div
                                    className={`d-flex justify-content-between ${styles.containerOperatorOccurrence}`}
                                >
                                    <div className={styles.textOperator}>
                                        Operador {operador.operador.nome} -{" "}
                                        {currentDateTime}
                                    </div>
                                    <div>
                                        <span
                                            className={styles.characterNumber}
                                        >
                                            {observacao.length}
                                        </span>
                                        <span
                                            className={styles.characterLetter}
                                        >
                                            /300 caracteres
                                        </span>
                                    </div>
                                </div>
                            </Form.Group>
                        )}

                        {labelConfirmModal == "Licenciar" ||
                            (statusIdUpdate == status && (
                                <>
                                    {checkUpdateStatus.includes(status) &&
                                        checkHist[0]?.motivo && (
                                            <Form.Group
                                                className={styles.formGroup}
                                            >
                                                <div
                                                    className={
                                                        styles.fieldSelect
                                                    }
                                                >
                                                    <div>
                                                        <Form.Label>
                                                            Motivo
                                                        </Form.Label>
                                                        <Form.Select
                                                            className="select-modalidade"
                                                            value={status}
                                                            onChange={(e) =>
                                                                handleChangeSituacao(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            disabled={true}
                                                        >
                                                            {checkHist.map(
                                                                (
                                                                    {
                                                                        id,
                                                                        motivo,
                                                                    },
                                                                    key,
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                motivo
                                                                            }
                                                                            key={
                                                                                key +
                                                                                1
                                                                            }
                                                                        >
                                                                            {
                                                                                motivo
                                                                            }
                                                                        </option>
                                                                    );
                                                                },
                                                            )}
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        )}

                                    <Form.Group
                                        className={styles.formGroupTextArea}
                                    >
                                        {textHist.length > 0 &&
                                            checkHist[0]?.motivo ==
                                                "Outros" && (
                                                <>
                                                    <Form.Label
                                                        className={
                                                            styles.textLabel
                                                        }
                                                    >
                                                        Observação
                                                    </Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="motivo"
                                                            id="motivo"
                                                            value={textHist}
                                                            maxLength={300}
                                                            onChange={(e) =>
                                                                textObs(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            className={
                                                                styles.textareaUpdate
                                                            }
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                        {textHist.length > 0 &&
                                            checkHist[0]?.motivo == "Outro" && (
                                                <>
                                                    <Form.Label
                                                        className={
                                                            styles.textLabel
                                                        }
                                                    >
                                                        Observação
                                                    </Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="motivo"
                                                            id="motivo"
                                                            value={textHist}
                                                            maxLength={300}
                                                            onChange={(e) =>
                                                                textObs(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            className={
                                                                styles.textareaUpdate
                                                            }
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                        {textHist.length > 0 &&
                                            !checkHist[0]?.motivo && (
                                                <>
                                                    <Form.Label
                                                        className={
                                                            styles.textLabel
                                                        }
                                                    >
                                                        Observação
                                                    </Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="motivo"
                                                            id="motivo"
                                                            value={textHist}
                                                            maxLength={300}
                                                            onChange={(e) =>
                                                                textObs(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            className={
                                                                styles.textareaUpdate
                                                            }
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                        <div
                                            className={`d-flex justify-content-between ${styles.containerOperatorOccurrence}`}
                                        >
                                            <div
                                                className={styles.textOperator}
                                            >
                                                Operador {labelLastAlter}
                                            </div>
                                            <div>
                                                <span
                                                    className={
                                                        styles.characterNumber
                                                    }
                                                >
                                                    {textHist.length}
                                                </span>
                                                <span
                                                    className={
                                                        styles.characterLetter
                                                    }
                                                >
                                                    /300 caracteres
                                                </span>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </>
                            ))}
                        {statusIdUpdate !== status && (
                            <Container className="d-flex justify-content-between">
                                <Form.Group></Form.Group>
                                <Form.Group className="my-5">
                                    <button
                                        // className="button-submeter"
                                        className={
                                            statusButton
                                                ? styles.buttonSaveBlock
                                                : styles.buttonSave
                                        }
                                        type="button"
                                        disabled={statusButton}
                                        onClick={() =>
                                            statusButton ? {} : handleSubmit()
                                        }
                                    >
                                        Salvar
                                    </button>
                                </Form.Group>
                            </Container>
                        )}
                    </form>
                </Container>
            </Container>
        </>
    );
};

export default InativarProfissionalPage;
