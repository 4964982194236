import React, { useContext, useEffect } from "react";
import { ServiceContext } from "../../../contexts/service";
import { Api } from "../../../providers";
import ResultNotFound from "../../resultNotFound";
import { ListTable } from "./listTable";

const UnidadeTable = () => {
    const { unidades, setUnidades, loading, setLoading } =
        useContext(ServiceContext);

    let mounted = true;

    useEffect(() => {
        Api.get(`/unidade/?fields=&flagAtivo=`)
            .then((res) => {
                if (mounted) {
                    setUnidades(res.data);
                    setLoading(false);
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoading(false);
                    setUnidades([]);
                }
            });
        return () => {
            mounted = false;
        };
    }, []);

    const currentItems = unidades;

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    return (
        <>
            {currentItems.length > 0 && (
                <ListTable currentItems={currentItems} />
            )}

            {currentItems.length <= 0 && (
                <ResultNotFound style={{ marginBottom: 10 }} />
            )}
        </>
    );
};

export default UnidadeTable;
