import React from "react";
import { Container } from "react-bootstrap";
import { ProfissionaisProvider } from "../../../contexts/service";
import SearchHistoricoProfissional from "./../../../components/search/historico/profissional/";
import HistoricoTableProfissional from "./../../../components/tables/historico/profissional/";
import { useLocation } from "react-router-dom";

//Documents
export default function Section6() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    return (
        <ProfissionaisProvider>
            <Container fluid className="justify-content-center">
                {/* <Container className="wrapperlistagem"> */}
                <SearchHistoricoProfissional idQuery={idQuery} />
                <HistoricoTableProfissional idQuery={idQuery} />
                {/* </Container> */}
            </Container>
        </ProfissionaisProvider>
    );
}
