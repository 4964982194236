import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import arrow from "../../../../assets/images/icons/arrow-down.png";
import imageCheck from "../../../../assets/images/icons/check.png";
import imageClose from "../../../../assets/images/icons/icon-close-03.png";
import { Api } from "../../../../providers";
import {
    ModalConfirm,
    ModalGeneric,
    ModalLoading,
    ModalSelect,
} from "../../../modal";
import styles from "./areaAtendimentoProfissional.module.scss";
import { ListTable } from "./listTable";

export const AreaAtendimentoTableProfissional = ({ idQuery }) => {
    const [selectUnit, setSelectUnit] = useState(false);
    const [selectNeighborhood, setSelectNeighborhood] = useState(false);
    const [dataUnit, setDataUnit] = useState([]);
    const [dataUnitAll, setDataUnitAll] = useState([]);
    const [serviceArea, setServiceArea] = useState(false);
    const [districtItems, setDistrictItems] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [neighborhoodsUpdate, setNeighborhoodsUpdate] = useState([]);
    const [selectedUnitName, setSelectedUnitName] = useState(
        "Selecione uma unidade",
    );
    const [selectedUnit, setSelectedUnit] = useState({});
    const [personalData, setPersonalData] = useState([]);
    const [checkSave, setCheckSave] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [mainUnit, setMainUnit] = useState("");
    const [statusUpdate, setStatusUpdate] = useState(false);
    const [modalRemove, setModalRemove] = useState(false);
    const [registeredUnits, setRegisteredUnits] = useState([""]);
    const [loading, setLoading] = useState(false);
    const [unitError, setUnitError] = useState("Campo obrigatório");
    const [checkNewSave, setCheckNewSave] = useState(false);

    function compareUnit(a, b) {
        if (a.nome < b.nome) {
            return -1;
        }
        if (a.nome > b.nome) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        setLoading(true);
        Api.get(`/profissional/${idQuery}`).then((res) => {
            if (res.status === 200) {
                const firstUnit = res.data.unidades.filter(
                    (data) => data.id == res.data.unidadeId,
                );
                res.data.unidades.sort(compareUnit);
                let listUnidades = firstUnit;
                const _ = res.data.unidades.filter((value) => {
                    if (value.id != listUnidades[0].id) {
                        listUnidades.push(value);
                    }
                });
                setPersonalData(res.data);
                setDataUnit(listUnidades);
                const resultData = res.data.unidades.find(
                    (data) => data.id == res.data.unidadeId,
                );
                setMainUnit(resultData?.nome ?? "");
                const unitData = res.data.unidades.map((data) => {
                    return data.nome;
                });
                setRegisteredUnits(unitData);
                setLoading(false);
            }
        });
    }, [checkSave]);

    useEffect(() => {
        Api.get(`/unidade`).then((res) => {
            if (res.status === 200) {
                setDataUnitAll(res.data);
            }
        });
    }, []);

    const replaceSpecialChars = (str) => {
        str = str.replace(/[ÀÁÂÃÄÅ]/, "A");
        str = str.replace(/[àáâãäå]/, "a");
        str = str.replace(/[ÈÉÊË]/, "E");
        str = str.replace(/[Ç]/, "C");
        str = str.replace(/[ç]/, "c");
        return str;
    };

    function compare(a, b) {
        if (a.logradouro < b.logradouro) {
            return -1;
        }
        if (a.logradouro > b.logradouro) {
            return 1;
        }
        return 0;
    }

    const fetchDataDistrict = async (district) => {
        Api.get(`/bairro/filter?cidade=${district}`).then((res) => {
            if (res.status === 200) {
                const result = res.data
                    .map((value) => {
                        return {
                            ...value,
                            logradouro: replaceSpecialChars(
                                value.logradouro ?? "",
                            ),
                        };
                    })
                    .filter((data) => data.logradouro !== "");
                result.sort(compare);
                setDistrictItems(result);
            }
        });
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    const handleSelecUnit = () => {
        setSelectUnit(true);
    };

    const handleCloseSelecUnit = () => {
        setSelectUnit(false);
    };

    const handleSelectNeighborhood = () => {
        setSelectNeighborhood(true);
    };

    const handleCloseSelectNeighborhood = () => {
        setSelectNeighborhood(false);
    };

    const handleServiceArea = () => {
        setServiceArea(true);
        setCheckNewSave(false);
    };

    const handleCloseServiceArea = () => {
        setServiceArea(false);
        setSelectedUnit({});
        setSelectedUnitName("Selecione uma unidade");
        setNeighborhoods([]);
        setNeighborhoodsUpdate([]);
        setCheckSave(!checkSave);
        setStatusUpdate(false);
        setDistrictItems([]);
    };

    const handleSearch = (data) => {
        fetchDataDistrict(data.nome);
        setSelectUnit(false);
        setSelectedUnitName(data.nome);
        setSelectedUnit(data);
        setCheckNewSave(true);
    };

    const handleSelectNeighb = (data) => {
        const checkItem = neighborhoods.filter((val) => val?.id == data.id);
        if (checkItem.length <= 0) {
            const itemsData = [...neighborhoods, data];
            setNeighborhoods(itemsData);
        } else {
            const checkItemData = neighborhoods.filter(
                (val) => val.id !== data.id,
            );
            setNeighborhoods(checkItemData);
        }
    };

    const onSubmit = async () => {
        setLoadingModal(true);
        const resultData = personalData;
        const savedNeighborhoods = personalData["bairros"].filter(
            (value) => value.cidade !== selectedUnitName,
        );
        const savedUnits = personalData["unidades"].filter(
            (value) => value.nome !== selectedUnitName,
        );
        const mergeCity = savedUnits.concat(selectedUnit);
        const mergeDistrict = savedNeighborhoods.concat(neighborhoods);
        resultData["bairros"] = mergeDistrict;
        resultData["unidades"] = mergeCity;

        const text = statusUpdate
            ? "Área de atendimento alterada com sucesso!"
            : "Área de atendimento adicionada com sucesso!";

        Api.put(`/profissional/${idQuery}`, resultData)
            .then((res) => {
                if (res.status === 200) {
                    handleCloseServiceArea();
                    setLoadingModal(false);
                    toast.success(text, {
                        toastId: "success",
                    });
                }
            })
            .catch((_) => {
                setLoadingModal(false);
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
            });
    };

    const handleRemove = () => {
        if (mainUnit === selectedUnitName) {
            return;
        }

        setModalRemove(false);
        setLoadingModal(true);
        const resultData = personalData;
        const savedNeighborhoods = personalData["bairros"].filter(
            (value) => value.cidade !== selectedUnitName,
        );

        const savedUnits = personalData["unidades"].filter(
            (value) => value.nome !== selectedUnitName,
        );

        resultData["bairros"] = savedNeighborhoods;
        resultData["unidades"] = savedUnits;

        Api.put(`/profissional/${idQuery}`, resultData)
            .then((res) => {
                if (res.status === 200) {
                    handleCloseServiceArea();
                    setLoadingModal(false);
                    toast.success("Unidade excluída com sucesso!", {
                        toastId: "success",
                    });
                }
            })
            .catch((_) => {
                setLoadingModal(false);
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
            });
    };

    const handleGetUpRemove = () => {
        setModalRemove(true);
    };

    function getDifference(array1, array2) {
        return array1.filter(
            (object1) => !array2.some((object2) => object1.id === object2.id),
        );
    }

    const difference = [
        ...getDifference(neighborhoodsUpdate, neighborhoods),
        ...getDifference(neighborhoods, neighborhoodsUpdate),
    ];

    const checkUpdate = statusUpdate ? difference : neighborhoods;
    const checkSaveUpdate = checkUpdate.length > 0 ? false : true;
    const checkSaveSituation = checkNewSave ? false : checkSaveUpdate;
    const statusButton = checkSaveSituation;
    const checkTextRequired =
        selectedUnitName !== "Selecione uma unidade" ? false : true;

    return (
        <>
            <div className={styles.containerTable}>
                <span onClick={() => handleServiceArea()}>
                    Adicionar nova área de atendimento
                </span>
                <ListTable
                    dataUnit={dataUnit}
                    personalData={personalData}
                    setServiceArea={setServiceArea}
                    setNeighborhoods={setNeighborhoods}
                    setSelectedUnitName={setSelectedUnitName}
                    fetchDataDistrict={fetchDataDistrict}
                    setStatusUpdate={setStatusUpdate}
                    setNeighborhoodsUpdate={setNeighborhoodsUpdate}
                    setSelectedUnit={setSelectedUnit}
                    setCheckNewSave={setCheckNewSave}
                />
            </div>

            <ModalGeneric
                styles={{
                    minHeight: "586px",
                    height: "686px",
                    width: "690px",
                }}
                title={
                    statusUpdate
                        ? "Área de atendimento"
                        : "Nova área de atendimento"
                }
                description=""
                openModal={serviceArea}
                setOpenModal={() => handleCloseServiceArea()}
                onClick={() => onSubmit()}
                labelCancel="Cancelar"
                labelConfirm="Salvar"
                backgroundCacel="#fff"
                disabled={statusButton}
                color={statusButton ? "" : "blue"}
                btnCancel={true}
                btnConfirm={true}
            >
                <Container
                    className={`justify-content-end ${styles.containerModal}`}
                >
                    <div className="d-flex justify-content-center align-items-center container p-2">
                        <div className={styles.boxModal}>
                            <div>
                                <div>
                                    <div
                                        className={
                                            checkTextRequired
                                                ? styles.boxCommentTextError
                                                : styles.boxCommentText
                                        }
                                    >
                                        <Form.Label>Unidade</Form.Label>
                                    </div>
                                    <div
                                        className={
                                            checkTextRequired
                                                ? styles.inputTextError
                                                : styles.inputText
                                        }
                                        onClick={() =>
                                            statusUpdate
                                                ? {}
                                                : handleSelecUnit()
                                        }
                                    >
                                        <span>{selectedUnitName}</span>
                                        {!statusUpdate && (
                                            <img
                                                src={arrow}
                                                alt="Selecione uma unidade"
                                                title="Selecione uma unidade"
                                            />
                                        )}
                                    </div>
                                    {checkTextRequired && (
                                        <span className={styles.requiredField}>
                                            {unitError}
                                        </span>
                                    )}
                                </div>
                                <div className={styles.containerSelect}>
                                    <div className={styles.boxCommentText}>
                                        <Form.Label>
                                            Bairros restringidos
                                        </Form.Label>
                                    </div>
                                    <div
                                        className={styles.inputText}
                                        onClick={() =>
                                            districtItems.length > 0
                                                ? handleSelectNeighborhood()
                                                : {}
                                        }
                                    >
                                        <span>{`${
                                            neighborhoods.length > 0
                                                ? `${neighborhoods.length} Bairros selecionados`
                                                : "Selecione os bairros"
                                        }`}</span>
                                        <img
                                            src={arrow}
                                            alt="Selecione os bairros"
                                            title="Selecione os bairros"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.containerPillBadges}>
                                {neighborhoods.length > 0 &&
                                    neighborhoods.map((data) => (
                                        <div
                                            onClick={() =>
                                                handleSelectNeighb(data)
                                            }
                                        >
                                            <span>{data.logradouro}</span>
                                            <span>
                                                <img
                                                    src={imageClose}
                                                    alt={data.logradouro}
                                                    title={data.logradouro}
                                                />
                                            </span>
                                        </div>
                                    ))}
                            </div>

                            {statusUpdate && mainUnit !== selectedUnitName && (
                                <span
                                    className={styles.removeUnit}
                                    onClick={() => handleGetUpRemove()}
                                >
                                    excluir unidade
                                </span>
                            )}
                        </div>
                    </div>
                </Container>
            </ModalGeneric>

            <ModalSelect
                styles={{
                    maxHeight: "245px",
                    width: "320px",
                }}
                openModal={selectUnit}
                setOpenModal={setSelectUnit}
                onClick={() => handleCloseSelecUnit()}
            >
                <Container>
                    <div className={styles.containerModalSelectTop}>
                        <span>Selecione a Unidade</span>
                        <img
                            src={arrow}
                            alt="Selecione os bairros"
                            title="Selecione os bairros"
                        />
                    </div>
                    <div className={styles.containerModalSelect}>
                        <div>
                            {dataUnitAll.length > 0 &&
                                dataUnitAll.map((data) => (
                                    <span
                                        key={data.id.toString()}
                                        onClick={() =>
                                            !registeredUnits.includes(data.nome)
                                                ? handleSearch(data)
                                                : {}
                                        }
                                    >
                                        {data.nome}
                                    </span>
                                ))}
                        </div>
                    </div>
                </Container>
            </ModalSelect>

            <ModalSelect
                styles={{
                    maxHeight: "245px",
                    width: "320px",
                }}
                openModal={selectNeighborhood}
                setOpenModal={setSelectNeighborhood}
                onClick={() => handleCloseSelectNeighborhood()}
            >
                <Container>
                    <div className={styles.containerModalSelectTop}>
                        <span>Selecionar bairro</span>
                        <img
                            src={arrow}
                            alt="Selecione os bairros"
                            title="Selecione os bairros"
                        />
                    </div>
                    <div className={styles.containerModalSelectNeighborhood}>
                        <div>
                            {districtItems.length > 0 &&
                                districtItems.map((data) => {
                                    const checkNeighb = neighborhoods.some(
                                        (val) => val.id === data.id,
                                    );
                                    return (
                                        <div
                                            key={data.id.toString()}
                                            onClick={() =>
                                                handleSelectNeighb(data)
                                            }
                                        >
                                            <span>{data.logradouro}</span>
                                            <div>
                                                {checkNeighb ? (
                                                    <img
                                                        src={imageCheck}
                                                        alt={data.cidade}
                                                        title={data.cidade}
                                                    />
                                                ) : (
                                                    <div />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </Container>
            </ModalSelect>

            <ModalConfirm
                title="Excluir unidade"
                description="Tem certeza que deseja excluir essa unidade?"
                openModal={modalRemove}
                setOpenModal={setModalRemove}
                onClick={() => handleRemove()}
                labelConfirm="Sim"
                labelCancel="Não"
                color="red"
                labelXSair="nao"
            />

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};
