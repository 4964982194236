import { Button, Link, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../../components/layout/navbar";
import SinglePagePDFViewer from "../../../components/pdf/view";
import MyThumbnail from "../../../components/thumbnail";
import { UserContext } from "../../../contexts/user";
import { DateTimeBR } from "../../../utils/date";
import { transformTextinSlug } from "../../../utils/string";
import { BaseURL } from "./../../../api/index";
import checkIcon from "./../../../assets/images/check.png";
import clockIcon from "./../../../assets/images/clock.png";
import documentIcon from "./../../../assets/images/document.png";
import downloadIconCheck from "./../../../assets/images/donwloadCheck.png";
import downloadIcon from "./../../../assets/images/download.png";
import downloadIconError from "./../../../assets/images/downloadError.png";
import errorIcon from "./../../../assets/images/error.png";
import setaEsq from "./../../../assets/images/seta-e.png";
import { ModalConfirm } from "./../../../components/modal/index";
import { Api } from "./../../../providers/index";
import styles from "./styles.module.scss";

const ENDPOINT = BaseURL + "api/documento/v2/";
const LayoutRoot = styled(Paper)(({ theme }) => ({
    maxWidth: "1440px",
    backgroundColor: theme.typography.body2,
    marginTop: 1,
    marginLeft: -10, //fix
    marginRight: -10, //fix
}));

const ContainerPDF = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#F7F7F7",
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    // width: "100%",
    // maxWidth: 804,
    // minWidth: 700,
}));

const Item = styled(Paper)(({ theme, color }) => ({
    width: "241px",
    height: "35px",
    display: "flex",
    // alignContent: "center",
    textTransform: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F7F7F7",
    ...theme.typography.body2,
    border: `1px solid ${color}`,
    borderRadius: "6px",
    padding: theme.spacing(1),
    justifyContent: "space-between",
    flexDirection: "row",
}));

// Motivos da Reprovação:
//Não tem motivos de reprovação para todos os tipos de documento: Ex.: Certificado de antecedentes criminais
const dataMotivo = [
    {
        type: "Documento Oficial com Foto",
        options: [
            "Nome diferente do Cadastro",
            "Data de Validade expirada",
            "Imagem enviada ilegível",
        ],
    },
    {
        type: "Certificado de cursos",
        options: [
            "Carga Horária insuficiente",
            "Sem carimbo e/ou assinatura da Instituição",
            "Comprovação não relacionada à função",
        ],
    },
    {
        type: "Certificado de antecedentes criminais",
        options: [
            "Nome diferente do Cadastro",
            "Documento fora da Validade",
            "Documento sem autenticação para validação",
        ],
    },
    {
        type: "Comprovante de Residência",
        options: [
            "Comprovante antigo (mais de 90 dias)",
            "Comprovante em nome diferente do cadastro",
        ],
    },
    {
        type: "Comprovante de experiência", //removido  " e/ou Carta de Referência" para poder dá match
        options: [
            "Tempo de experiência insuficiente (menor que 6 meses)",
            "Comprovação diferente da função em cadastro",
            "Carta de Referência sem dados de contato e assinatura do emissor",
        ],
    },
];

//DropDown Aprovação/Reprovação
const DropdownDocuments = ({
    selectProfessional,
    idProfesional,
    checkBackDoc,
    btnSave,
}) => {
    const navigate = useNavigate();
    const {
        docProfessional,
        setDocProfessional,
        loading,
        setLoading,
        professionalData,
        setCheckBackDocument,
        setStatusScreenDoc,
    } = useContext(UserContext);
    const [statusDoc, setStatusDoc] = useState("Análise");
    const [motivoDoc, setMotivoDoc] = useState("Nenhum");
    const [operador, setOperador] = useState(null);
    //Motivos padrões, mas pode implementar via endpoint
    const [allMotive, setAllMotive] = useState(dataMotivo);
    //Recebe focus quando selecionado a opção outros
    const [motivoReprovacao, setMotivoReprovacao] = useState("");
    const motivoElementRef = useRef("");
    //Modal
    const [openModalAprovar, setOpenModalAprovar] = useState(false);
    const [openModalReprovar, setOpenModalReprovar] = useState(false);
    const [arrayNewService, setArrayNewService] = useState([]);
    const [textDescError, setTextDescError] = useState("Campo obrigatório");
    const [currentDateTime, setCurrentDateTime] = useState("");

    let mounted = true;
    const textError = "Campo obrigatório";

    const operadorStorage = JSON.parse(localStorage.getItem("user"));

    //Get Dropdown
    useEffect(() => {
        if (selectProfessional) {
            setMotivoReprovacao("");
            //Status
            if (selectProfessional.status)
                setStatusDoc(selectProfessional.status);
            //Motivo
            if (selectProfessional.motivo)
                setMotivoDoc(selectProfessional.motivo);
            //Descrição
            if (selectProfessional.observacao)
                setMotivoReprovacao(selectProfessional.observacao);
            //Add Operador
            setOperador(null);
            if (selectProfessional.usuario) {
                updateOperador();
            }
        }
    }, [selectProfessional]);

    useEffect(() => {
        Api.get("/system/date").then((res) => {
            if (mounted) {
                setCurrentDateTime(res.data.dateBr + " " + res.data.hoursBr);
            }
        });
        return () => {
            mounted = false;
        };
    }, []);

    const updateOperador = () => {
        let operador = selectProfessional.dataAtualizacao
            ? `${selectProfessional.usuario.nome} - ${DateTimeBR(
                  selectProfessional.dataAtualizacao,
              )}`
            : `${selectProfessional.usuario.nome}`;
        setOperador(operador);
    };

    // Up Professional
    useEffect(() => {
        if (selectProfessional) {
            selectProfessional.status = statusDoc;
            selectProfessional.motivo = "Nenhum";
            if (statusDoc === "Reprovado" && motivoDoc !== "Nenhum") {
                selectProfessional.motivo = motivoDoc;
            }
            selectProfessional.observacao = null;
            if (statusDoc === "Reprovado" && motivoDoc == "Outros") {
                motivoElementRef.current.focus();
                selectProfessional.observacao = motivoReprovacao;
            }
        }
    }, [motivoDoc, statusDoc, motivoReprovacao, selectProfessional]);

    useEffect(() => {
        const checkNewService = () => {
            if (selectProfessional) {
                Api.get(
                    `/profissional/${selectProfessional.profissionalId}/servico`,
                )
                    .then((res) => {
                        if (res.status === 200 && mounted) {
                            const checkService = res.data
                                .filter(
                                    (data) =>
                                        data.flagAtivo === null &&
                                        data.flagNovo,
                                )
                                .map((value) => value.servicoId);
                            if (checkService.length > 0) {
                                setArrayNewService(checkService);
                            }
                        }
                    })
                    .catch((error) => {
                        const checkErro = error;
                        toast.error(
                            `Ops!  ${checkErro.response.data.message?.error}`,
                            {
                                toastId: "msg",
                            },
                        );
                    });
            }
        };
        checkNewService();
    }, [selectProfessional]);

    useEffect(() => {
        return () => {
            mounted = false;
        };
    }, []);

    const updateList = () => {
        docProfessional.map((item) => {
            if (item.id === selectProfessional.id) {
                //selectProfessional.motivo = "Nenhum"
                item.motivo =
                    selectProfessional.motivo !== "Nenhum"
                        ? selectProfessional.motivo
                        : null;
                item.usuario = selectProfessional.usuario;
                item.dataAtualizacao = selectProfessional.dataAtualizacao;
                item.status = selectProfessional.status;
                item.observacao = selectProfessional.observacao;
            }
        });
        setDocProfessional(docProfessional);
    };
    const handleSubmit = () => {
        if (
            selectProfessional === null ||
            (professionalData.situacao.slug !== "inscrito" &&
                professionalData?.situacao?.slug !== "ativo" &&
                !arrayNewService?.includes(selectProfessional?.servicoId))
        )
            return;
        //Update Objeto
        updateList();
        setLoading(true);
        //Update DB (Endpoint)
        Api.put(`/documento/${selectProfessional.id}`, selectProfessional)
            .then((res) => {
                if (res.status === 200 && mounted) {
                    if (res.data) {
                        selectProfessional = res.data;
                        updateList();
                        updateOperador();
                        setLoading(false);
                        btnSave(true);
                        toast.success("Documento Atualizado com Sucesso!", {
                            toastId: "success",
                            autoClose: 1000,
                            onClose: function () {
                                //do nothing
                            },
                        });
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                toast.error("Ops! Aconteceu algum problema.");
            });
    };
    const handleClick = () => {
        setOpenModalAprovar(false);
        setOpenModalReprovar(false);
        setTimeout(() => {
            handleSubmit();
        }, 500);
    };

    function textDesc(desc) {
        if (desc.length <= 0) {
            setMotivoReprovacao("");
            setTextDescError("Campo obrigatório");
        } else {
            if (desc.length > 0 && desc.length <= 200 && desc.trim()) {
                setMotivoReprovacao(desc);
                setTextDescError("");
            }
        }
    }

    //Back to before Page
    const backPage = () => {
        setCheckBackDocument(true);
        setStatusScreenDoc(false);
        navigate(`/profissional/editar?id=${idProfesional}`);
    };

    useEffect(() => {
        if (checkBackDoc) {
            backPage();
        }
    }, [checkBackDoc]);

    let checkButtonSubmit =
        ((statusDoc == "Aprovado" ||
            (statusDoc == "Reprovado" && motivoDoc != "Nenhum")) &&
            professionalData?.situacao?.slug == "inscrito") ||
        (professionalData?.situacao?.slug == "ativo" &&
            arrayNewService?.includes(selectProfessional?.servicoId))
            ? true
            : false;

    checkButtonSubmit =
        checkButtonSubmit &&
        motivoDoc == "Outros" &&
        motivoReprovacao.length <= 0
            ? false
            : checkButtonSubmit;

    const docRes = docProfessional?.filter(
        (value) => value.documentoTipoId == 3 && !value.analise,
    );

    checkButtonSubmit =
        docRes?.length > 0 &&
        selectProfessional?.documentoTipoId == 3 &&
        professionalData?.situacao?.slug == "ativo"
            ? true
            : checkButtonSubmit;

    return (
        <div>
            <ModalConfirm
                id="aprovar"
                title="Aprovar Documento"
                description="Tem certeza que deseja aprovar este documento?"
                openModal={openModalAprovar}
                setOpenModal={setOpenModalAprovar}
                color="blue"
                onClick={() => handleClick()}
                labelConfirm="Aprovar"
            />

            <ModalConfirm
                id="reprovar"
                title="Reprovar Documento"
                description="Tem certeza que deseja reprovar este documento?"
                openModal={openModalReprovar}
                setOpenModal={setOpenModalReprovar}
                onClick={() => handleClick()}
                labelConfirm="Reprovar"
            />

            <div className={styles.containerInfo}>
                <div className={styles.containerSelect}>
                    <div>
                        <span>Status</span>
                        <select
                            className={`form-select ${styles.formDropdown}`}
                            value={statusDoc}
                            onChange={(e) => {
                                setStatusDoc(e.target.value);
                            }}
                        >
                            <option value="Análise" key="0" disabled>
                                Selecione o status
                            </option>
                            <option key="1" value="Aprovado">
                                Aprovado
                            </option>
                            <option key="2" value="Reprovado">
                                Reprovado
                            </option>
                        </select>
                        <span
                            className={
                                statusDoc === "Análise"
                                    ? styles.requiredField
                                    : ""
                            }
                        >
                            {statusDoc === "Análise" ? textError : ""}
                        </span>
                    </div>
                    {operador && statusDoc === "Aprovado" && (
                        <div className={styles.containerOperador}>
                            <div>
                                <img src={clockIcon} width="16px" />
                                <span className={styles.textOperador}>
                                    Operador {operador}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                {/* motivo */}
                {statusDoc === "Reprovado" && selectProfessional && (
                    <div className={styles.espacoInfo}>
                        <div className={styles.containerSelect}>
                            <div>
                                <span>Motivo</span>
                                <select
                                    className={`form-select ${styles.formDropdown}`}
                                    value={motivoDoc}
                                    onChange={(e) => {
                                        setMotivoDoc(e.target.value);
                                    }}
                                >
                                    <option key="0" value="Nenhum" disabled>
                                        Selecione motivo
                                    </option>

                                    {selectProfessional.documentoTipoNome &&
                                        allMotive.map(
                                            ({ type, options }, index) => {
                                                // Se não der match
                                                if (
                                                    transformTextinSlug(
                                                        type,
                                                    ) ===
                                                    transformTextinSlug(
                                                        selectProfessional.documentoTipoNome,
                                                    )
                                                ) {
                                                    return options.map(
                                                        (value, index) => (
                                                            <option
                                                                key={index + 1}
                                                                value={value}
                                                            >
                                                                {value}
                                                            </option>
                                                        ),
                                                    );
                                                }
                                            },
                                        )}

                                    <option key={99} value="Outros">
                                        Outros
                                    </option>
                                </select>
                                <span
                                    className={
                                        motivoDoc === "Nenhum"
                                            ? styles.requiredField
                                            : ""
                                    }
                                >
                                    {motivoDoc === "Nenhum" ? textError : ""}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {/* motivo da reprovação */}
                {statusDoc === "Reprovado" && motivoDoc === "Outros" && (
                    <div className={styles.espacoInfo}>
                        <div className={styles.containerTextArea}>
                            <div>
                                <span>Motivo da reprovação</span>
                                <textarea
                                    className={styles.textAreaComment}
                                    placeholder="Descreva o motivo da reprovação"
                                    rows={3}
                                    onChange={(e) => textDesc(e.target.value)}
                                    ref={motivoElementRef}
                                    value={motivoReprovacao}
                                >
                                    {motivoReprovacao}
                                </textarea>
                                {!motivoReprovacao && (
                                    <span className={styles.requiredField}>
                                        {textDescError}
                                    </span>
                                )}
                            </div>

                            <div className={styles.containerCharacter}>
                                <span className={styles.characterNumber}>
                                    {motivoReprovacao.length}
                                </span>
                                <span className={styles.characterLetter}>
                                    /200 caracteres
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                <div className={styles.containerTextArea}>
                    {operador && statusDoc === "Reprovado" && (
                        <div className={styles.containerOperador}>
                            <div>
                                <img src={clockIcon} width="16px" />
                                <span className={styles.textOperador}>
                                    Operador {operador}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.containerInfoButton}>
                    <div className={styles.containerButton}>
                        <button
                            className={
                                !checkButtonSubmit
                                    ? styles.buttonSearchBlock
                                    : styles.buttonSearch
                            }
                            type="submit"
                            onClick={
                                checkButtonSubmit
                                    ? () => {
                                          if (statusDoc === "Aprovado") {
                                              setOpenModalAprovar(true);
                                          }
                                          if (
                                              statusDoc === "Reprovado" &&
                                              motivoDoc !== "Nenhum"
                                          ) {
                                              if (
                                                  motivoDoc === "Outros" &&
                                                  motivoReprovacao.length <= 0
                                              ) {
                                                  setOpenModalReprovar(false);
                                              } else {
                                                  setOpenModalReprovar(true);
                                              }
                                          }
                                      }
                                    : () => {}
                            }
                            disabled={!checkButtonSubmit ? true : false}
                        >
                            <span>Salvar</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// View Document
export default function ViewDocuments() {
    const search = useLocation().search;
    //Id do profissional
    const idProfesional = new URLSearchParams(search).get("id");
    //Tipo e url do documento
    const typeDocument = new URLSearchParams(search).get("tipo");
    const urlDocument = new URLSearchParams(search).get("url");
    const { setPrintLayout, docProfessional, loading, setStatusScreenDoc } =
        useContext(UserContext);
    // Nome do tipo de documento
    const [documentoTipoNome, setDocumentoTipoNome] = useState("");
    // Carrega documento no início
    const [fileDoc, setFileDoc] = useState(`${ENDPOINT}${urlDocument}`);
    const [selectProfessional, setSelectProfessional] = useState(null);
    const [documentsView, setDocumentsView] = useState([]);
    const [checkBackDoc, setCheckBackDoc] = useState(false);
    const [checkBtnSave, setCheckBtnSave] = useState(false);
    //Documento Atual
    const currentDocumnt = (url) => {
        //Url
        if (docProfessional.length > 0) {
            let res = docProfessional.find((item) => {
                return item.caminho === url;
            });
            setSelectProfessional(res);
        }
    };
    // Icon
    const getIconStatus = (status) => {
        if (status === "Análise") return documentIcon;
        if (status === "Aprovado") return checkIcon;
        if (status === "Reprovado") return errorIcon;
    };

    // Delete
    const getIconDownload = (status) => {
        if (status === "Análise") return downloadIcon;
        if (status === "Aprovado") return downloadIconCheck;
        if (status === "Reprovado") return downloadIconError;
    };

    //Status
    const getColorStatus = (status) => {
        if (status === "Análise") return "#0064B4";
        if (status === "Aprovado") return "#95C11F";
        if (status === "Reprovado") return "#E60E19";
    };
    //Seleciona o Item
    const handleClick = (url) => {
        currentDocumnt(url);
        setFileDoc(`${ENDPOINT}${url}`);
    };

    // Container Left
    const ContainerLeft = () => {
        return (
            Array.isArray(documentsView) &&
            documentsView.map((item, index) => {
                //Nome do tipo de documento
                if (documentoTipoNome === "") {
                    setDocumentoTipoNome(item.documentoTipoNome);
                }
                //Botão do documento
                const myColor = getColorStatus(item.status);
                return (
                    <Button
                        key={index}
                        onClick={() => handleClick(item.caminho)}
                    >
                        <Item color={myColor}>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                    window.open(`${ENDPOINT}${item.caminho}`);
                                }}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={getIconStatus(item.status)}
                                    alt="ico"
                                    style={{
                                        color: myColor,
                                    }}
                                    width="10px"
                                />
                            </Link>

                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    marginLeft: 1,
                                    flexWrap: "wrap",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    color:
                                        item.status === "Análise"
                                            ? myColor
                                            : "#343B40",
                                }}
                                variant="span"
                            >
                                {item.nome}
                            </Typography>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                    window.open(`${ENDPOINT}${item.caminho}`);
                                }}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={getIconDownload(item.status)}
                                    alt="ico"
                                    style={{
                                        color: myColor,
                                    }}
                                    width="10px"
                                />
                            </Link>
                        </Item>
                    </Button>
                );
            })
        );
    };

    const btnSave = (status) => {
        setCheckBtnSave(status);
    };

    //Back to before Page
    const backPage = () => {
        setCheckBackDoc(!checkBackDoc);
    };
    //Docs View Aside
    useEffect(() => {
        const documents =
            Array.isArray(docProfessional) &&
            docProfessional.filter((item, index) => {
                return item.documentoTipoId === parseInt(typeDocument);
            });
        const resultDoc = documents.filter((val) => val.status === "Análise");
        setDocumentsView(documents);
        if (resultDoc.length <= 0 && checkBtnSave) {
            backPage();
        }
    }, [docProfessional, loading, checkBtnSave]);

    //Current Doc
    useEffect(() => {
        setStatusScreenDoc(true);
        setPrintLayout(true);
        currentDocumnt(urlDocument);
    }, [urlDocument]);

    //back
    useEffect(() => {
        //Se refresh redireciona para pagina do profissional
        if (docProfessional.length < 1) {
            backPage();
        }
    }, [docProfessional]);

    return (
        <>
            <Navbar />

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                {/* Left */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: 273,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 24,
                            marginLeft: 20,
                            marginBottom: 31,
                            cursor: "pointer",
                            alignSelf: "flex-start",
                        }}
                        onClick={() => backPage()}
                    >
                        <div>
                            <img
                                src={setaEsq}
                                alt="ico"
                                style={{
                                    color: "#0064B4",
                                }}
                                width="7px"
                                height="14px"
                            />
                        </div>
                        <span
                            style={{
                                m: 1,
                                display: "inline-flex",
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "17px",
                                alignItems: "center",
                                color: "#343B40",
                                textTransform: "none",
                                marginLeft: 8,
                            }}
                        >
                            Todos os documentos
                        </span>
                    </div>
                    {ContainerLeft()}
                </div>
                {/* Center */}
                <div className={styles.containerPDF}>
                    <ContainerPDF>
                        {!!(
                            fileDoc.endsWith(".pdf") || fileDoc.endsWith(".PDF")
                        ) ? (
                            // Pdf
                            <SinglePagePDFViewer file={fileDoc} />
                        ) : (
                            // Image
                            <MyThumbnail file={fileDoc} />
                        )}
                    </ContainerPDF>
                </div>
                {/* Right */}
                <div style={{ width: "100%", maxWidth: 363 }}>
                    <DropdownDocuments
                        selectProfessional={selectProfessional}
                        idProfesional={idProfesional}
                        checkBackDoc={checkBackDoc}
                        btnSave={btnSave}
                    />
                </div>
            </div>
        </>
    );
}
