import React from "react";
import {
    Document,
    Page,
    View,
    StyleSheet,
    Font,
    Text,
} from "@react-pdf/renderer";
import HeaderPDF from "./../header/index";
import BodyPDF from "./../body/index";
import FooterPDF from "./../footer/index";

Font.register({
    family: "Lato",
    src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
    family: "Lato Italic",
    src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
    family: "Lato Bold",
    src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#FFFFFF",
        padding: 10,
    },
    wrapper: {
        flex: 1,
        // border: "1px dashed red",
    },
    content: {
        flex: 1,
        // border: "1px dashed blue",
    },
    row: {
        flex: 1,
        flexDirection: "row",
    },
    header: {
        flex: 1,
        flexDirection: "row",
        maxHeight: 100,
        alignItems: "center",
        justifyContent: "space-between",
        // border: "1px dashed #000",
    },
    title: {
        left: 0,
        fontFamily: "Lato Bold",
        color: "#343B40",
        fontSize: 12,
        fontWeight: 600,
        textAlign: "left",
        paddingBottom: 16,
    },
    subtitle: {
        fontFamily: "Lato",
        color: "#343B40",
        fontSize: 10,
        fontWeight: 600,
        textAlign: "right",
        paddingBottom: 16,
    },
    text: {
        width: "60%",
        margin: 10,
        fontFamily: "Lato",
        fontSize: 12,
        textAlign: "justify",
    },

    footer: {
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Lato Bold",
        marginTop: 15,
        marginBottom: 15,
        paddingTop: 5,
        "@media orientation: landscape": {
            marginTop: 10,
        },
        // border: "1px dashed gray",
    },
    pageNumber: {
        fontSize: 10,
        textAlign: "center",
        color: "grey",
    },
});
/**
 * Document PDF
 * @param {string} title Title of the Document
 * @param {array} data Data of the Document
 * @returns {*} pdf
 */
export default function DocumentPDF({ title, data }) {
    return (
        <Document title={title} author="SETRE-BA">
            <Page size="A4" style={styles.page} wrap>
                <View style={styles.wrapper}>
                    <HeaderPDF styles={styles} />
                    <BodyPDF styles={styles} title={title} data={data} />
                    <Text break></Text>
                    <FooterPDF styles={styles} />
                </View>
            </Page>
        </Document>
    );
}
