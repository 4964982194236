import { Box, Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { AgendaDate } from "../../../components/AgendaDate";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import { ModalLoading } from "../../../components/modal";
import ResultNotFound from "../../../components/resultNotFound";
import MyTasks from "../../../components/tasks/index";
import { NewTitle } from "../../../components/title/index";
import { Api } from "./../../../providers/index";
import styles from "./styles.module.scss";

export default function Agenda() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const navigate = useNavigate();
    const [situacao, setSituacao] = useState("0");
    const [appointments, setAppointments] = useState([]);
    // Data
    const [myData, setMyData] = useState([]);
    const [professionalData, setProfessionalData] = useState(null);
    const [loadingModal, setLoadingModal] = useState(true);
    const [compAtual, setCompAtual] = useState("");

    const getColor = {
        color1: "#FFFFFF",
        color2: "#FFDD98",
        color3: "#AACBE4",
        color4: "#E6D0D1",
        color5: "#CBFFBE",
    };

    // Agenda
    const makeAgenda = (mySchedule, hoursDateBr) => {
        if (mySchedule !== null) {
            //Add data of the Professional !important
            setProfessionalData(mySchedule);

            //Dias liberados = livres
            const livre = mySchedule.agendas.map((item) => {
                if (item.dataLiberada)
                    return {
                        priority: false,
                        date: item.dataLiberada,
                        color: getColor.color1,
                    };
            });

            //Caso tenha a mesma data ela sera removida
            const taskFinished = (item) => {
                livre.filter((value, idx) => {
                    if (value.date === item.datas[0]) {
                        livre.splice(idx, 1);
                    }
                });
            };

            //Agendamento pendente - A confirmar
            const solicitacoes = mySchedule.solicitacoes.map((item) => {
                taskFinished(item);
                if (item) {
                    return {
                        priority: true,
                        date: item.datas[0],
                        color: getColor.color2,
                    };
                }
            });
            //Confirmados = Dias com agendamento
            const tarefas = mySchedule.tarefas.map((item) => {
                //Caso tenha a mesma data ela sera removida
                solicitacoes.filter((value, idx) => {
                    if (value.date === item.datas[0]) {
                        solicitacoes.splice(idx, 1);
                    }
                });

                if (item) {
                    return {
                        priority: true,
                        date: item.datas[0],
                        color: getColor.color3,
                        hora: item.hora,
                    };
                }
            });

            //Tarefas nao finalizadas com data e hora maior que 24 horas
            const naoFinalizados = mySchedule.tarefas
                .map((item) => {
                    const dateTask = moment(item.datas[0]).format("DD/MM/YYYY");
                    const d1 = `${dateTask} ${item.hora}`;
                    const d2 = hoursDateBr; //data e hora atual.

                    const diff = moment(d2, "DD/MM/YYYY HH:mm:ss").diff(
                        moment(d1, "DD/MM/YYYY HH:mm:ss"),
                    );
                    const dias = moment.duration(diff).asDays();

                    tarefas.filter((value, idx) => {
                        if (
                            value.date === item.datas[0] &&
                            parseInt(dias) > 0
                        ) {
                            tarefas.splice(idx, 1);
                        }
                    });

                    if (parseInt(dias) > 0) {
                        if (item) {
                            return {
                                priority: true,
                                date: item.datas[0],
                                color: getColor.color4,
                                hora: item.hora,
                            };
                        }
                    }
                })
                .filter((data) => data);

            //Tarefas finalizadas, porem nao realizadas
            const naoRealizadas = mySchedule.naoRealizadas.map((item) => {
                taskFinished(item);
                if (item) {
                    return {
                        priority: true,
                        date: item.datas[0],
                        color: getColor.color5,
                        hora: item.hora,
                    };
                }
            });

            //Tarefas finalizadas, porem nao avaliada
            const realizadas = mySchedule.realizadas.map((item) => {
                taskFinished(item);
                if (item) {
                    return {
                        priority: true,
                        date: item.datas[0],
                        color: getColor.color5,
                    };
                }
            });

            //Tarefas finalizadas e avaliadas
            const avaliadas = mySchedule.avaliadas.map((item) => {
                taskFinished(item);
                if (item) {
                    return {
                        priority: true,
                        date: item.datas[0],
                        color: getColor.color5,
                    };
                }
            });

            const finalizados = realizadas.concat(avaliadas);

            setAppointments(
                [].concat(
                    tarefas,
                    solicitacoes,
                    naoFinalizados,
                    finalizados,
                    naoRealizadas,
                    livre,
                ),
            );
            setLoadingModal(false);
        } else {
            setLoadingModal(false);
        }
    };

    const currentDate = (valueData) => {
        Api.get("/system/date").then((res) => {
            makeAgenda(valueData, res.data.hoursDateBr);
        });
    };

    // Serviços
    useEffect(() => {
        let mounted = true;
        let ENDPOINT = `/profissional/${idQuery}/agenda/`;
        Api.get(ENDPOINT)
            .then((res) => {
                if (res.status === 200) {
                    if (mounted) {
                        currentDate(res.data);
                        if (res.data?.length <= 0) {
                            setLoadingModal(false);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
        return () => (mounted = false);
    }, [idQuery]);

    /**
     * Tasks cards
     * Atualiza conforme situação
     */
    useEffect(() => {
        if (professionalData) {
            let resultInfo;
            switch (situacao) {
                case "A Confirmar":
                    resultInfo = professionalData.solicitacoes.filter(
                        (val) => val.datas[0].substring(0, 7) == compAtual,
                    );
                    setMyData(resultInfo);
                    break;
                case "Confirmado":
                    resultInfo = professionalData.tarefas.filter(
                        (val) => val.datas[0].substring(0, 7) == compAtual,
                    );
                    setMyData(resultInfo);
                    break;
                case "Realizado":
                    resultInfo = professionalData.realizadas.filter(
                        (val) => val.datas[0].substring(0, 7) == compAtual,
                    );
                    setMyData(resultInfo);
                    break;
                case "Não realizado":
                    resultInfo = professionalData.naoRealizadas.filter(
                        (val) => val.datas[0].substring(0, 7) == compAtual,
                    );
                    setMyData(resultInfo);
                    break;
                case "Avaliado":
                    resultInfo = professionalData.avaliadas.filter(
                        (val) => val.datas[0].substring(0, 7) == compAtual,
                    );
                    setMyData(resultInfo);
                    break;
                case "Cancelado":
                    resultInfo = professionalData.canceladas.filter(
                        (val) => val.datas[0].substring(0, 7) == compAtual,
                    );
                    setMyData(resultInfo);
                    break;
                case "Expirado":
                    resultInfo = professionalData.expiradas.filter(
                        (val) => val.datas[0].substring(0, 7) == compAtual,
                    );
                    setMyData(resultInfo);
                    break;
                default:
                    setMyData([]);
                    break;
            }
        } else {
            setMyData([]);
        }

        return false;
    }, [situacao, compAtual]);

    const handleRedirect = () => {
        navigate(`/profissional/agenda/editar/?id=${idQuery}`);
    };

    const competenciaAtual = (competencia) => {
        setCompAtual(competencia);
    };

    return (
        <Grid className="justify-content-center">
            <BreadCrumb
                data={{
                    category: "",
                    page: professionalData?.nome,
                    menu: {
                        item: "Usuários",
                        link: "",
                        item2: "Profissionais",
                        link2: "/profissional/",
                    },
                }}
            />
            <NewTitle title={professionalData?.nome} />

            <Box
                sx={{
                    m: 0,
                    p: 3,
                    width: "889px",
                    minHeight: "440px",
                    backgroundColor: "#F7F7F7",
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                }}
            >
                {/* ********************************************************** */}

                <AgendaDate
                    appointments={appointments}
                    competenciaAtual={competenciaAtual}
                />

                {/* ********************************************************** */}
            </Box>

            <Box
                sx={{
                    m: 0,
                    p: 3,
                    width: "889px",
                    backgroundColor: "#F7F7F7",
                }}
            >
                <div className={styles.colorIndicator}>
                    <div>
                        <div />
                        <div>Dia atual</div>
                    </div>
                    <div>
                        <div />
                        <div>Dias bloqueados</div>
                    </div>
                    <div>
                        <div />
                        Dias liberados
                    </div>
                    <div>
                        <div />
                        Confirmados
                    </div>
                    <div>
                        <div />A confirmar
                    </div>
                </div>
                <div className={styles.colorIndicator2}>
                    <div>
                        <div />
                        Não finalizados
                    </div>
                    <div>
                        <div />
                        Finalizados/Não realizados/Avaliados
                    </div>
                </div>
            </Box>

            <Box
                sx={{
                    m: 0,
                    p: 3,
                    width: "889px",
                    backgroundColor: "#F7F7F7",
                    marginBottom: 10,
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                }}
            >
                <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        className={styles.buttonEdit}
                        type="submit"
                        onClick={handleRedirect}
                    >
                        Editar dias na agenda
                    </button>
                </Grid>

                <Grid
                    sx={{
                        display: "flex",
                        alignSelf: "center",
                        border: "1px solid #D8D9DA",
                        marginTop: 4,
                        marginBottom: 4,
                    }}
                />

                <div className={`${styles.containerInfo}`}>
                    <div>
                        <span>Situação de solicitação</span>
                        <select
                            className={`form-select ${styles.formDropdown}`}
                            value={situacao}
                            onChange={(e) => setSituacao(e.target.value)}
                        >
                            <option value="0" key="0" disabled>
                                Selecione uma opção
                            </option>
                            {[
                                "A Confirmar",
                                "Confirmado",
                                "Realizado",
                                "Não realizado",
                                "Avaliado",
                                "Cancelado",
                                "Expirado",
                            ].map((item, key) => {
                                return (
                                    <option value={item} key={key + 1}>
                                        {item}
                                    </option>
                                );
                            })}
                        </select>
                        {myData.length <= 0 && situacao !== "0" && (
                            <ResultNotFound style={{ marginTop: 10 }} />
                        )}
                    </div>
                </div>
                {/* <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    sx={{ backgroundColor: "#F7F7F7", m: 0, p: 2 }}
                >
                    <MyTasks tasks={myData} profissionalId={idQuery} />
                </Grid> */}
                <div className={styles.containerCard}>
                    <MyTasks tasks={myData} profissionalId={idQuery} />
                </div>
            </Box>

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </Grid>
    );
}
