import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ServiceContext } from "../../../contexts/service";
import { useUnidades } from "../../../hooks/useScriptRef";
import { Api } from "../../../providers";
import { limitString } from "./../../../utils/string/index";
import { SearchField } from "./components/searchField";
import styles from "./styles.module.scss";

const Search = () => {
    const {
        setProfissionais,
        setLoading,
        setTotalPages,
        setTotalElements,
        setStatusFilter,
        setParamsFilter,
    } = useContext(ServiceContext);

    const unidadesServer = useUnidades();

    const [busca, setBusca] = useState("");
    const [situacao, setSituacao] = useState([]);
    const [situacaoID, setSituacaoID] = useState("");
    const [servicos, setServicos] = useState([]);
    const [servicoID, setServicoID] = useState("");
    const [unidadeID, setUnidadeID] = useState("");

    useEffect(() => {
        let mounted = true;
        Api.get("/servico/").then((res) => {
            if (mounted) {
                setServicos(res.data);
            }
        });
        Api.get("/profissional-situacao/").then((res) => {
            if (mounted) {
                setSituacao(res.data);
            }
        });

        return () => {
            mounted = false;
            setServicos([]);
        };
    }, []);

    const handleSumbit = (event) => {
        setLoading(true);
        event.preventDefault();

        let params = {};
        if (busca !== "") params.fields = busca;
        if (situacaoID !== "null") params.situacaoId = situacaoID;
        if (servicoID !== "0") params.servicoId = servicoID;
        if (unidadeID !== "0") params.unidadeId = unidadeID;

        Api.get(`/profissional/pagination/0/10?`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Provider
                setProfissionais(res.data.content);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setParamsFilter(params);
                setStatusFilter(true);
                setLoading(false);
            })
            .catch((_) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    };

    return (
        <div className={styles.conteudoTabela}>
            <form>
                <div>
                    <div>
                        <SearchField
                            placeholder="Pesquise por nome, CPF, telefone..."
                            onClick={handleSumbit}
                            onChange={(e) => searchText(e.target.value)}
                            search={busca}
                        />
                    </div>

                    <div className={styles.containerFilds}>
                        <div className={styles.subContainerFilds}>
                            <span>Filtrar resultados</span>
                            <div className={styles.containerSelect}>
                                <div className={styles.fieldSelect}>
                                    <select
                                        className="form-select"
                                        value={situacaoID}
                                        onChange={(e) =>
                                            setSituacaoID(e.target.value)
                                        }
                                    >
                                        <option value="" key="0" disabled>
                                            Status
                                        </option>
                                        <option value="0" key="999">
                                            Todos
                                        </option>
                                        {situacao.map(({ id, nome }, key) => {
                                            return (
                                                <option
                                                    value={id}
                                                    key={key + 1}
                                                >
                                                    {limitString(nome)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className={styles.fieldSelect}>
                                    <select
                                        className="form-select"
                                        value={servicoID}
                                        onChange={(e) =>
                                            setServicoID(e.target.value)
                                        }
                                    >
                                        <option value="" key="0" disabled>
                                            Serviços
                                        </option>
                                        {servicos.map(({ id, nome }, key) => {
                                            return (
                                                <option
                                                    value={id}
                                                    key={key + 1}
                                                >
                                                    {limitString(nome)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className={styles.fieldSelect}>
                                    <select
                                        className="form-select"
                                        value={unidadeID}
                                        onChange={(e) =>
                                            setUnidadeID(e.target.value)
                                        }
                                    >
                                        <option value="" key="0" disabled>
                                            Unidade
                                        </option>
                                        <option value="0" key="999">
                                            Todos
                                        </option>
                                        {unidadesServer.map(
                                            ({ id, nome }, key) => {
                                                return (
                                                    <option
                                                        value={id}
                                                        key={key + 1}
                                                    >
                                                        {nome}
                                                    </option>
                                                );
                                            },
                                        )}
                                    </select>
                                </div>
                                <div className={styles.fieldSelect2} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Search;
