import { Box, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseURL } from "../../../api";
import creditDebitIcon from "../../../assets/images/credit-debit.png";
import imageCamera from "../../../assets/images/icons/camera.png";
import imageEstrela from "../../../assets/images/icons/estrela.png";

import imageEstrela1 from "../../../assets/images/icons/star_1.png";
import imageEstrela2 from "../../../assets/images/icons/star_2.png";

import { styled } from "@mui/system";
import moneyIcon from "../../../assets/images/money.png";
import pixIcon from "../../../assets/images/pix.png";
import {
    ModalEditFotoPerfil,
    ModalImagemUsuario,
    ModalOpenScreen,
    ModalSimplesSemConfirmacao,
} from "../../../components/modal";
import { ServiceContext } from "../../../contexts/service";
import { UserContext } from "../../../contexts/user";
import MyDatePicker from "./../../../components/datepicker/";
import { Api } from "./../../../providers/index";
import { FormatDate, FormatDateNameBREvaluation } from "./../../../utils/date";
import { CpfMask, rgmask } from "./../../../utils/mask/index";
import styles from "./styles.module.scss";

const Item = {
    maxWidth: "400px",
    height: "56px",
    display: "flex",
    marginTop: "32px",
    justifyContent: "start",
    alignContent: "center",
    padding: 0,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    alignItems: "center",
    color: "#343B40",
    backgroundColor: "#ffffff",
    "& .MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "17px",
        color: "#343B40",
    },
};

const ItemCustom = {
    width: "400px !important",
    height: "56px !important",
    borderRadius: "8px !important",
    "& .MuiOutlinedInput-input": {
        padding: "16.5px 14px",
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
    },
    "& .MuiSvgIcon-root": {
        zIndex: 2,
    },
};

const ItemCustomField = {
    width: "400px !important",
    height: "56px",
    borderRadius: "8px !important",
    "& .MuiOutlinedInput-input": {
        padding: "16.5px 0px",
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
    },
    "& .MuiInputBase-input": {
        width: "93%",
    },
};

const ItemCustomFieldCPF = {
    backgroundColor: "#ECEDEE",
};

const BoxData = ({ children }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                background: "#F7F7F7",
                borderRadius: "8px",
            }}
        >
            {children}
        </Box>
    );
};

const Estrelas = ({ evaluation, width = 24, height = 24 }) => {
    return (
        <div className={styles.containerEstrelas}>
            {[1, 2, 3, 4, 5].map((value) => {
                return (
                    <div key={value.toString()}>
                        <img
                            src={
                                evaluation >= value
                                    ? imageEstrela2
                                    : imageEstrela
                            }
                            width={width}
                            height={height}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const EstrelasModal = ({ evaluation, width = 30.98, height = 32.1 }) => {
    return (
        <div className={styles.containerEstrelasModal}>
            {[1, 2, 3, 4, 5].map((value) => {
                return (
                    <div key={value.toString()}>
                        <img
                            // src={imageEstrela}
                            src={
                                evaluation >= value
                                    ? imageEstrela2
                                    : imageEstrela1
                            }
                            width={width}
                            height={height}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const Input = styled("input")({
    display: "none",
});

export default function Section1({ tabData, handleChange }) {
    const inputRef = useRef(null);
    const { newServiceModal, setNewServiceModal, checkModal, setCheckModal } =
        useContext(ServiceContext);
    const {
        docProfessional,
        setDocProfessional,
        loading,
        setLoading,
        professionalData,
        checkBackDocument,
        setCheckBackDocument,
    } = useContext(UserContext);
    const token = localStorage.getItem("token");

    const navigate = useNavigate();
    const [nome, setNome] = useState("");
    const [dataNascimento, setDataNascimento] = useState(null);
    const [cpf, setCpf] = useState("");
    const [rg, setRg] = useState("");
    const [genero, setGenero] = useState([]);
    const [generoId, setGeneroId] = useState(0);
    const [escolaridade, setEscolaridade] = useState([]);
    const [escolaridadeId, setEscolaridadeId] = useState(0);
    const [situacao, setSituacao] = useState([]);
    const [situacaoId, setSituacaoId] = useState(0);
    const [raca, setRaca] = useState([]);
    const [racaId, setRacaId] = useState(0);
    const [serviceStatusModal, setServiceStatusModal] = useState(false);
    const [fotoProfissional, setFotoProfissional] = useState("");
    const [imagemModal, setImagemModal] = useState(false);
    const [servicos, setServicos] = useState([]);
    const [formasPagamento, setFormasPagamento] = useState([]);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [rating, setRating] = useState(0);
    const [totalRatings, setTotalRatings] = useState(2);
    const [openPerfil, setOpenPerfil] = useState(false);
    const [confirmModalEdit, setConfirmModalEdit] = useState(false);
    const [image, setImage] = useState("");
    const [novaImagem, setNovaImagem] = useState(false);

    const viewMore = () => {
        setTotalRatings(100);
    };

    useEffect(() => {
        setDataNascimento(FormatDate(tabData.dataNascimento, "yyyy/MM/dd"));
        setNome(tabData.nome);
        setCpf(tabData.cpf);
        const rgData = tabData?.rg?.length > 0 ? rgmask(tabData?.rg) : "";
        setRg(rgData);

        setFormasPagamento(tabData?.formaPagamentos);
        setAvaliacoes(tabData?.avaliacoes);
        setRating(tabData?.avaliacao);

        if (tabData.usuario?.foto != null) {
            const caminhoImagem = `${BaseURL}api/usuario/security/${tabData.usuario?.foto}?token=${token}`;
            setFotoProfissional(caminhoImagem);
        }
        setTotalRatings(2);
        if (tabData?.id) {
            Api.get("/profissional/" + tabData?.id + "/servico")
                .then((res) => {
                    if (res.status === 200) {
                        setServicos(res.data);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [tabData.cpf, tabData.dataNascimento, tabData.nome, tabData?.id]);

    useEffect(() => {
        let mounted = true;

        Api.get("/profissional-situacao/").then((res) => {
            if (res.status === 200) {
                if (mounted) {
                    setSituacao(res.data);
                    if (tabData.situacaoId) setSituacaoId(tabData.situacaoId);
                }
            }
        });
        return () => {
            mounted = false;
        };
    }, [tabData.situacaoId]);

    useEffect(() => {
        let mounted = true;
        Api.get("/raca/").then((res) => {
            if (res.status === 200) {
                if (mounted) {
                    setRaca(res.data);
                    if (tabData.racaId) setRacaId(tabData.racaId);
                }
            }
        });
        return () => {
            mounted = false;
        };
    }, [tabData.racaId]);

    useEffect(() => {
        let mounted = true;
        Api.get("/escolaridade/").then((res) => {
            if (res.status === 200) {
                if (mounted) {
                    setEscolaridade(res.data);
                    if (tabData.escolaridadeId)
                        setEscolaridadeId(tabData.escolaridadeId);
                }
            }
        });
        return () => {
            mounted = false;
        };
    }, [tabData.escolaridadeId]);

    useEffect(() => {
        let mounted = true;
        Api.get("/genero/").then((res) => {
            if (res.status === 200) {
                if (mounted) {
                    setGenero(res.data);
                    if (tabData.generoId) setGeneroId(tabData.generoId);
                }
            }
        });
        return () => {
            mounted = false;
        };
    }, [tabData.generoId]);

    useEffect(() => {
        if (checkModal && !checkBackDocument) {
            setServiceStatusModal(newServiceModal);
        }
        if (checkBackDocument) {
            setTimeout(() => {
                setCheckBackDocument(false);
                handleChange(0, 4);
                setServiceStatusModal(false);
                setNewServiceModal(false);
                setCheckModal(false);
            }, 1000);
        }
    }, [newServiceModal]);

    const handleFoto = () => {
        const formData = new FormData();
        if (novaImagem) {
            formData.append("file", image);
        }

        Api.put(`/usuario/${tabData?.usuario?.id}/foto`, formData)
            .then((res) => {
                if (res.status === 200) {
                }
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;

                toast.error(`Ops! ${message.error}`, {
                    toastId: "error",
                });
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        tabData.nome = nome;
        tabData.dataNascimento = FormatDate(dataNascimento);
        tabData.escolaridadeId =
            escolaridadeId > 0 ? escolaridadeId : tabData.escolaridadeId;
        tabData.generoId = generoId > 0 ? generoId : tabData.generoId;
        tabData.racaId = racaId > 0 ? racaId : tabData.racaId;

        if (novaImagem) {
            handleFoto();
        }

        Api.put(`/profissional/${tabData.id}`, tabData)
            .then((res) => {
                if (res.status === 200)
                    toast.success("Profissional atualizado com Sucesso", {
                        toastId: "success",
                    });
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;

                toast.error(`Ops! ${message.error}`, {
                    toastId: "error",
                });
            });
    };

    const handleClick = (event) => {
        event.preventDefault();
        const URLREDIRECT = `/profissional/inativar?id=${tabData.id}`;
        navigate(URLREDIRECT);
    };

    const handlePerfil = () => {
        setOpenPerfil(true);
    };

    const getImagemTipoPagamento = (tipo) => {
        switch (tipo) {
            case "Débito":
                return creditDebitIcon;
            case "Crédito":
                return creditDebitIcon;
            case "Pix":
                return pixIcon;
            default:
                return moneyIcon;
        }
    };

    const exibirAvaliacao = (item) => {
        return (
            <div className={styles.containerAvaliacoes}>
                <div className={styles.avaliacoesBox}>
                    <div>
                        <EstrelasModal
                            evaluation={item.nota}
                            width={20}
                            height={20}
                        />
                    </div>
                    <div className={styles.avaliacoesBoxRatingData}>
                        {FormatDateNameBREvaluation(item.data)}
                    </div>
                </div>
                <div className={styles.comentarios}>{item.comentario}</div>
            </div>
        );
    };

    const handleFilesChange = (event) => {
        const checkData = ["image/png", "image/jpg", "image/jpeg"];
        const checkData2 = ["png", "jpg", "jpeg"];
        const checkType = event?.target?.files[0].name.split(".").pop();
        if (
            checkData.includes(event?.target?.files[0]?.type) &&
            checkData2.includes(checkType)
        ) {
            setFotoProfissional(URL.createObjectURL(event.target.files[0]));
            setImage(event.target.files[0]);
            setNovaImagem(true);
        }
    };

    const handleEditFoto = () => {
        setConfirmModalEdit(false);
        inputRef?.current?.click();
    };

    const modalFotoPerfil = () => setConfirmModalEdit(true);

    return (
        <>
            <ModalOpenScreen
                title="Novo serviço cadastrado"
                description={`Esse profissional cadastrou um novo serviço no sistema. É necessário analisar os novos documentos para que o novo serviço seja aprovado`}
                openModal={serviceStatusModal}
                setOpenModal={setServiceStatusModal}
                onClick={() => {
                    setServiceStatusModal(false);
                    setNewServiceModal(false);
                    setCheckModal(false);
                    handleChange(0, 4);
                }}
                labelConfirm="Abrir documentos"
                color="blue"
                hasCancel="no"
                labelXSair="nao"
            />
            <BoxData>
                <div className={styles.containerFoto}>
                    <div className={styles.fotoContainer}>
                        <Input
                            accept="image/png, image/jpg, image/jpeg"
                            type="file"
                            onChange={(e) => {
                                handleFilesChange(e);
                            }}
                            ref={inputRef}
                        />
                        <div className={styles.fotoProf}>
                            {fotoProfissional && (
                                <img
                                    src={fotoProfissional}
                                    onClick={() => setImagemModal(true)}
                                />
                            )}
                        </div>
                        <div
                            className={styles.imageCamera}
                            onClick={modalFotoPerfil}
                        >
                            <img src={imageCamera} />
                        </div>
                    </div>
                    <div className={styles.containerTextoPerfil}>
                        <div
                            className={styles.textoPerfil}
                            onClick={handlePerfil}
                        >
                            Ver perfil público
                        </div>
                        <div className={styles.estrelasPerfil}>
                            <Estrelas evaluation={rating} />
                        </div>
                    </div>
                </div>
                <div className={styles.containerInputsBtn}>
                    <div className={styles.containerGeralDadosP}>
                        <div className={styles.subContainerGeralDados01}>
                            <div className={styles.containerTextField}>
                                <div>
                                    <span>Nome Completo</span>
                                    <TextField
                                        value={nome}
                                        fullWidth
                                        sx={ItemCustomField}
                                        onChange={(e) => {
                                            setNome(e.target.value);
                                        }}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className={`${styles.containerTextField} ${styles.dividerSpace} ${styles.colorFieldBlock}`}
                            >
                                <div>
                                    <span>CPF</span>
                                    <TextField
                                        value={cpf}
                                        fullWidth
                                        sx={ItemCustomField}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputComponent: CpfMask,
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className={`${styles.containerDatePicker} ${styles.dividerSpace}`}
                            >
                                <span>Data de nascimento</span>
                                <MyDatePicker
                                    fullWidth
                                    sx={ItemCustom}
                                    value={dataNascimento}
                                    onChange={(e) => {
                                        setDataNascimento(e);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <div></div>
                            </div>

                            <div
                                className={`${styles.containerFieldSelect} ${styles.dividerSpace}`}
                            >
                                <span>Cor/Raça</span>
                                <select
                                    className={`form-select ${styles.formDropdown}`}
                                    value={racaId}
                                    onChange={(e) => {
                                        setRacaId(e.target.value);
                                    }}
                                >
                                    <option value="0" key="0" disabled>
                                        Selecione uma opção
                                    </option>
                                    {raca.map(({ id, nome }) => (
                                        <option key={id} value={id}>
                                            {nome}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {/* Column Two */}
                        <div>
                            <div
                                className={`${styles.containerFieldSelect} ${styles.colorFieldBlock}`}
                            >
                                <span>Status</span>
                                <select
                                    className={`form-select ${styles.formDropdownStatus}`}
                                    value={situacaoId}
                                    disabled
                                >
                                    <option value="0" key="0" disabled>
                                        Selecione uma opção
                                    </option>
                                    {situacao.map(({ id, nome }) => (
                                        <option key={id} value={id}>
                                            {nome}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div
                                className={`${styles.containerTextField} ${styles.dividerSpace} ${styles.colorFieldBlock}`}
                            >
                                <div>
                                    <span>RG</span>
                                    <TextField
                                        value={rg}
                                        fullWidth
                                        sx={ItemCustomField}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className={`${styles.containerFieldSelect} ${styles.dividerSpace}`}
                            >
                                <span>Gênero</span>
                                <select
                                    className={`form-select ${styles.formDropdown}`}
                                    value={generoId}
                                    onChange={(e) => {
                                        setGeneroId(e.target.value);
                                    }}
                                >
                                    <option value="0" key="0" disabled>
                                        Selecione uma opção
                                    </option>
                                    {genero.map(({ id, nome }) => (
                                        <option key={id} value={id}>
                                            {nome}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div
                                className={`${styles.containerFieldSelect} ${styles.dividerSpace}`}
                            >
                                <span>Grau de escolaridade</span>
                                <select
                                    className={`form-select ${styles.formDropdown}`}
                                    value={escolaridadeId}
                                    onChange={(e) => {
                                        setEscolaridadeId(e.target.value);
                                    }}
                                >
                                    <option value="0" key="0" disabled>
                                        Selecione uma opção
                                    </option>
                                    {escolaridade.map(({ id, nome }) => (
                                        <option key={id} value={id}>
                                            {nome}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={styles.containerInfoButtonEditDadosP}>
                        <div className={styles.containerButtonEditDadosP}>
                            <div onClick={handleClick}>Alterar status</div>
                            <button
                                className={styles.buttonSearch}
                                type="button"
                                onClick={handleSubmit}
                                disabled={false}
                            >
                                <span>Salvar</span>
                            </button>
                        </div>
                    </div>
                </div>

                <ModalImagemUsuario
                    styles={{
                        width: "200px",
                    }}
                    openModal={imagemModal}
                    setOpenModal={setImagemModal}
                    imagem={fotoProfissional}
                />

                <ModalSimplesSemConfirmacao
                    styles={{
                        minHeight: "578px",
                        width: "558px",
                    }}
                    title={nome}
                    description=""
                    openModal={openPerfil}
                    setOpenModal={setOpenPerfil}
                    color="blue"
                    labelConfirm="Avaliar"
                    labelCancel="Fechar"
                >
                    <div className={styles.containerModal}>
                        <Container className="justify-content-end">
                            <div
                                className={`d-flex justify-content-center align-items-center container ${styles.containerFotoModal}`}
                            >
                                <div className={styles.fotoProfModal}>
                                    {fotoProfissional && (
                                        <img
                                            src={fotoProfissional}
                                            className={styles.profileImageModal}
                                        />
                                    )}
                                </div>
                            </div>

                            <Form.Group className="d-flex justify-content-center align-items-center container mb-4">
                                <EstrelasModal evaluation={rating} />
                            </Form.Group>

                            <Form.Group className="box-servicos">
                                <div className={styles.tituloBoxTitle}>
                                    Serviços
                                </div>
                                <ul className={styles.listaServicoTop}>
                                    {servicos.map((item) => {
                                        if (item.flagAtivo) {
                                            return (
                                                <li
                                                    className={
                                                        styles.listaServicosItem
                                                    }
                                                >
                                                    {item.servico.nome}
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            </Form.Group>
                            <Form.Group className="d-flex justify-content-center align-items-center container mb-4 p-2"></Form.Group>
                            <Form.Group className="box-servicos">
                                <div className={styles.tituloBoxTitle}>
                                    Formas de pagamento
                                </div>
                                <div className={styles.formaPagamento}>
                                    {formasPagamento?.map((item) => (
                                        <div className="formas-de-pagamento-item">
                                            <img
                                                src={getImagemTipoPagamento(
                                                    item.nome,
                                                )}
                                                width="48"
                                                height={48}
                                            />
                                            <span className="formas-de-pagamento-item-nome">
                                                {item.nome}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex justify-content-center align-items-center container mb-4 p-2"></Form.Group>
                            <Form.Group className="box-servicos">
                                <div className={styles.tituloBoxTitle}>
                                    Avaliações
                                </div>
                                <div className={styles.marginTopAvalicao} />

                                {avaliacoes?.map((item, idx) =>
                                    totalRatings > idx
                                        ? exibirAvaliacao(item)
                                        : null,
                                )}
                                {totalRatings === 2 &&
                                    avaliacoes?.length > 0 && (
                                        <span
                                            onClick={() => viewMore()}
                                            className="ver-mais-texto"
                                        >
                                            Ver mais
                                        </span>
                                    )}
                            </Form.Group>
                        </Container>
                    </div>
                </ModalSimplesSemConfirmacao>
            </BoxData>
            <ModalEditFotoPerfil
                title="Editar foto de perfil"
                openModal={confirmModalEdit}
                setOpenModal={setConfirmModalEdit}
                onClick={() => handleEditFoto()}
                labelConfirm="Escolher foto"
                labelCancel="Cancelar"
                color="blue"
                labelXSair="nao"
            />
        </>
    );
}
