import React from "react";
import { TelMaskRegex } from "../../../utils/mask";
import btedit from "./../../../assets/images/bt-edit.png";
import styles from "./styles.module.scss";

export function ListTable({ currentItems }) {
    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp =
        operador.operador.funcao.slug === "administrador" ? true : false;

    const returnStyles = (info) => {
        if (info === true) {
            return styles.Ativo;
        } else {
            return styles.Inativo;
        }
    };

    const getFlatAtivo = (flag) => {
        if (flag === true) return "Ativa";
        if (flag === false) return "Inativa";
    };

    return (
        <div className={`table-responsive ${styles.radiusTable}`}>
            <table className={`table table-borderless ${styles.table}`}>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Unidade</th>
                        <th>Telefone</th>
                        <th>Horário de atendimento</th>
                        <th>Dias de atendimento</th>
                        <th>Endereço</th>
                        {checkOp && <th>Ação</th>}
                    </tr>
                </thead>
                <tbody data-test="table-body" className={styles.tbody}>
                    {currentItems.length > 0 ? (
                        currentItems.map((val) => {
                            return (
                                <tr key={val.id.toString()}>
                                    <td
                                        className={`${
                                            styles.estadoInfo
                                        } ${returnStyles(val.flagAtivo)}`}
                                    >
                                        <span>
                                            {getFlatAtivo(val.flagAtivo)}
                                        </span>
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.nome}
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.telefonePrincipal
                                            ? TelMaskRegex(
                                                  val.telefonePrincipal,
                                              )
                                            : ""}
                                        {" /"}
                                        {`\n`}
                                        {val.telefoneSecundario
                                            ? TelMaskRegex(
                                                  val.telefoneSecundario,
                                              )
                                            : ""}
                                    </td>
                                    <td className={styles.infoTextDesc}>
                                        {val.horaAtendimento}
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.diaAtendimento}
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.logradouro}, {val.numero}
                                    </td>
                                    {checkOp && (
                                        <td>
                                            <a
                                                href={`/unidade/editar?id=${val.id}`}
                                            >
                                                <img
                                                    src={btedit}
                                                    alt="Editar"
                                                />
                                            </a>
                                        </td>
                                    )}
                                </tr>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
        </div>
    );
}
