import React, { useContext, useEffect } from "react";
import { BreadCrumb } from "../../../components/breadcrumb";
import Search from "../../../components/search/profissional";
import ProfissionaisTable from "../../../components/tables/profissionais";
import Title from "../../../components/title";
import { AuthContext } from "../../../contexts/auth";
import { ProfissionaisProvider } from "../../../contexts/service";
import styles from "./styles.module.scss";

const DashboardProfissional = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    useEffect(() => {
        setCurrentScreen(2);
    }, []);

    return (
        <ProfissionaisProvider>
            <div className={`justify-content-center ${styles.containerList}`}>
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Profissionais",
                        menu: {
                            item: "Usuários",
                            link: "",
                        },
                    }}
                />
                <Title title="Profissionais" />
                <div className={styles.listagem}>
                    <Search />
                    <ProfissionaisTable />
                </div>
            </div>
        </ProfissionaisProvider>
    );
};

export default DashboardProfissional;
