import React, { memo, useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { BreadCrumb } from "../../../components/breadcrumb";
import TreinamentoTable from "../../../components/tables/treinamento";
import Title from "../../../components/title";
import { AuthContext } from "../../../contexts/auth";
import { TrainingProvider } from "../../../contexts/service";
import Search from "./search";

const DashboardTreinamento = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    useEffect(() => {
        setCurrentScreen(8);
    }, []);

    return (
        <TrainingProvider>
            <Container fluid className="justify-content-center mb-5">
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Treinamentos",
                    }}
                />
                <Title title="Treinamentos" />
                <Container className="listagem">
                    <Search />
                    <TreinamentoTable />
                </Container>
            </Container>
        </TrainingProvider>
    );
};

export default memo(DashboardTreinamento);
