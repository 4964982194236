import React from "react";
import { DateBR } from "../../../../utils/date";
import styles from "./styles.module.scss";

export function ListTableJustificativas({
    currentItems,
    situacao,
    handleModalReabrirDatas,
}) {
    const resultService = (service) => {
        if (service.length > 0) {
            return (
                <div className={styles.serviceInfo}>
                    <span>{service[0].nome}</span>
                    {service.length > 1 && <span>+{service.length - 1}</span>}
                </div>
            );
        } else {
            return "";
        }
    };

    return (
        <div className={styles.containerTable1}>
            <div className="table-responsive">
                <table className={`table align-middle ${styles.table}`}>
                    <thead>
                        <tr>
                            <th>Situação</th>
                            <th>Data de inscrição</th>
                            <th>Data do treinamento</th>
                            <th>Serviço</th>
                            <th>Justificativa</th>
                        </tr>
                    </thead>
                    <tbody data-test="table-body">
                        {currentItems?.length > 0 &&
                            currentItems.map((row) => {
                                let sit = "";
                                let hexadecimal = "";

                                situacao.map((row2) => {
                                    if (
                                        row2.id == row?.profissional?.situacaoId
                                    ) {
                                        sit = row2.nome;
                                        hexadecimal = row2?.hexadecimal;
                                    }
                                });

                                return (
                                    <tr key={row?.profissionalId}>
                                        <td>
                                            <div
                                                className={styles.boxColor}
                                                style={{
                                                    backgroundColor:
                                                        hexadecimal,
                                                }}
                                            >
                                                <span
                                                    className={
                                                        styles.pillBadgesText
                                                    }
                                                >
                                                    {sit}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            {DateBR(
                                                row?.profissional?.dataCadastro,
                                            )}
                                        </td>
                                        <td>{DateBR(row?.dataTreinamento)}</td>
                                        <td>
                                            {resultService(
                                                row?.profissional?.servicos,
                                            )}
                                        </td>
                                        <td>
                                            {row?.justificativaFalta?.length >
                                            0 ? (
                                                <span
                                                    className={
                                                        styles.textJustif
                                                    }
                                                    onClick={() =>
                                                        handleModalReabrirDatas(
                                                            {
                                                                idTrein:
                                                                    row?.treinamentoId,
                                                                justificativa:
                                                                    row?.justificativaFalta,
                                                                abrirDatas:
                                                                    row?.flagAbrirDatas,
                                                                novoTreinamento:
                                                                    row.novoTreinamento,
                                                            },
                                                        )
                                                    }
                                                >
                                                    Visualizar justificativa
                                                </span>
                                            ) : (
                                                <span
                                                    className={
                                                        styles.textAguardJustif
                                                    }
                                                >
                                                    Aguardando justificativa
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
