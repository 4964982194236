import React from "react";
import styles from "./searchField.module.scss";

export const SearchField = ({
    onClick = () => {
        //do nothing
    },
    onChange = () => {
        //do nothing
    },
    placeholder = "Pesquisa aqui",
    search = ""
}) => {
    return (
        <div className="d-flex justify-content-center">
          <input
            id="busca"
            type="text"
            placeholder={placeholder}
            name="busca"
            className={`${styles.inputPesquisar}`}
            value={search}
            onChange={onChange}
        />
        <div className="containerIconSearch" />
          <span>
              <button
                  className={styles.buttonSearch}
                  type="submit"
                    onClick={onClick}
              >
                  Pesquisar
              </button>
          </span>
        </div>
    );
};
