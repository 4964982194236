import { Box, Grid, Stack } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AgendaDate } from "../../../components/AgendaDate";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import { NewTitle } from "../../../components/title/index";
import { isDaysFromToday } from "../../../utils/date";
import { ModalConfirm, ModalLoading } from "./../../../components/modal/index";
import { Api } from "./../../../providers/index";
import styles from "./styles.module.scss";

export default function EditarAgenda() {
    const navigate = useNavigate();
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    // Modal
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    // Agenda
    const [appointments, setAppointments] = useState([]);
    // Data
    const [professionalData, setProfessionalData] = useState(null);
    //Days Marckeds
    const [daysSelected, setDaysSelected] = useState([]);
    const [tarefas, setTarefas] = useState([]);
    const [dayAvailable, setDayAvailable] = useState([]);
    const [currentDay, setCurrentDay] = useState("");
    const [loadingModal, setLoadingModal] = useState(true);

    //callBack Submit !important
    const MyUseCallback = useCallback((days, month) => {
        let selected = days.filter((day) => {
            //Fixed future date !important
            return isDaysFromToday(day);
        });

        setCurrentMonth(month);
        setDaysSelected(selected);
    });

    // Agenda
    const makeAgenda = (mySchedule) => {
        let viewSchedule = [];
        if (mySchedule !== null) {
            setProfessionalData(mySchedule);

            //Dias liberados = livres
            const livre = mySchedule.agendas.map((item) => {
                if (item.dataLiberada)
                    return {
                        date: item.dataLiberada,
                        color: "#8CCCFF",
                    };
            });

            //Agendamento pendente - A confirmar
            const solicitacoes = mySchedule.solicitacoes.map((item) => {
                //Caso tenha a mesma data ela sera removida
                livre.filter((value, idx) => {
                    if (value.date === item.datas[0]) {
                        livre.splice(idx, 1);
                    }
                });

                if (item) {
                    return {
                        date: item.datas[0],
                        color: "#D8D9DA",
                    };
                }
            });

            //Confirmados = Dias com agendamento
            const tarefas = mySchedule.tarefas.map((item) => {
                //Caso tenha a mesma data ela sera removida
                solicitacoes.filter((value, idx) => {
                    if (value.date === item.datas[0]) {
                        livre.splice(idx, 1);
                    }
                });

                if (item) {
                    return {
                        date: item.datas[0],
                        color: "#D8D9DA",
                    };
                }
            });

            //Tarefas finalizadas, porem nao realizadas
            const naoRealizadas = mySchedule.naoRealizadas.map((item) => {
                if (item) {
                    return {
                        date: item.datas[0],
                        color: "#D8D9DA",
                        hora: item.hora,
                    };
                }
            });

            //Tarefas finalizadas, porem nao avaliada
            const realizadas = mySchedule.realizadas.map((item) => {
                if (item) {
                    return {
                        date: item.datas[0],
                        color: "#D8D9DA",
                    };
                }
            });

            //Tarefas finalizadas e avaliadas
            const avaliadas = mySchedule.avaliadas.map((item) => {
                if (item) {
                    return {
                        date: item.datas[0],
                        color: "#D8D9DA",
                    };
                }
            });

            const finalizados = realizadas.concat(avaliadas);

            setTarefas(tarefas);
            setAppointments(
                [].concat(
                    livre,
                    solicitacoes,
                    tarefas,
                    naoRealizadas,
                    finalizados,
                ),
            );

            const totalDate = [].concat(
                livre,
                solicitacoes,
                tarefas,
                naoRealizadas,
                finalizados,
            );

            const resultDay = totalDate.map((value) => value.date);
            setDayAvailable(resultDay);
            setLoadingModal(false);
        } else {
            setLoadingModal(false);
        }
    };

    const currentDate = (valueData) => {
        Api.get("/system/date").then((res) => {
            setCurrentDay(res.data.date);
            makeAgenda(valueData);
        });
    };

    // Serviços
    useEffect(() => {
        let mounted = true;
        Api.get(`/profissional/${idQuery}/agenda/`)
            .then((res) => {
                if (res.status === 200) {
                    if (mounted) {
                        currentDate(res.data);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
        return () => (mounted = false);
    }, [idQuery]);

    const handleCancel = () => {
        navigate(`/profissional/agenda/?id=${idQuery}`);
    };

    const handleSubmit = () => {
        setOpenModalConfirm(false);
        setLoadingModal(true);

        let scheduledSend = dayAvailable
            .map((val) => {
                const date1 = moment(val, "YYYY-MM-DD").valueOf();
                const date2 = moment(currentDay, "YYYY-MM-DD").valueOf();
                if (date1 >= date2) {
                    return { profissionalId: idQuery, dataLiberada: val };
                }
            })
            .filter((data) => data);

        const uniqueData = scheduledSend.filter((obj, index) => {
            return (
                index ===
                scheduledSend.findIndex(
                    (o) => obj.dataLiberada === o.dataLiberada,
                )
            );
        });

        if (scheduledSend.length > 0) {
            Api.put(`/profissional/${idQuery}/agenda/lista`, uniqueData)
                .then((res) => {
                    if (res.status === 200) {
                        setLoadingModal(false);
                        toast.success("Dias disponíveis salvo com sucesso", {
                            toastId: "success",
                        });
                        handleCancel();
                    }
                })
                .catch((err) => {
                    let message =
                        typeof err.response !== "undefined"
                            ? err.response.data.message
                            : err.message;

                    setLoadingModal(false);
                    toast.error(`Ops! ${message.error}`, {
                        toastId: "error",
                    });
                });
        }
        return;
    };

    const onDayPress = useCallback((dateString, day) => {
        setCurrentDay(day);
        const checkDate = dayAvailable.find((val) => val === dateString);
        if (checkDate?.length) {
            const removeDate = dayAvailable.filter((val) => val !== dateString);
            setDayAvailable(removeDate);
            const removeApp = appointments.filter(
                (val) => val.date !== dateString,
            );
            setAppointments(removeApp);
        } else {
            setDayAvailable([...dayAvailable, dateString]);
            const dataApp = {
                date: dateString,
                color: "#8CCCFF",
            };
            setAppointments([...appointments, dataApp]);
        }
    });

    return (
        <>
            <Grid className="justify-content-center">
                <BreadCrumb
                    data={{
                        category: "",
                        page: professionalData?.nome,
                        menu: {
                            item: "Usuários",
                            link: "",
                            item2: "Profissionais",
                            link2: "/profissional/",
                        },
                    }}
                />
                <NewTitle title={professionalData?.nome} />

                <Box
                    sx={{
                        m: 0,
                        p: 3,
                        width: "889px",
                        minHeight: "440px",
                        backgroundColor: "#F7F7F7",
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    }}
                >
                    {/* ********************************************************** */}

                    <AgendaDate
                        appointments={appointments}
                        edit={true}
                        callBack={onDayPress}
                    />

                    {/* ********************************************************** */}
                </Box>

                <Box
                    sx={{
                        m: 0,
                        p: 3,
                        width: "889px",
                        backgroundColor: "#F7F7F7",
                    }}
                >
                    <div className={styles.colorIndicatorEdit}>
                        <div>
                            <div />
                            <div>Dias liberados</div>
                        </div>
                        <div>
                            <div />
                            <div>Dias bloqueados</div>
                        </div>
                        <div>
                            <div />
                            Dias não editáveis
                        </div>
                    </div>
                </Box>

                <Box
                    sx={{
                        m: 0,
                        p: 3,
                        width: "889px",
                        // minHeight: "440px",
                        backgroundColor: "#F7F7F7",
                        marginBottom: 10,
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                    }}
                >
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            {/* <MyScheduler
                                appointments={appointments}
                                editar={true}
                                callBack={MyUseCallback}
                            /> */}
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="flex-end"
                                sx={{ mt: 2 }}
                            >
                                <button
                                    className={styles.buttonCancel}
                                    onClick={() => handleCancel()}
                                >
                                    <span>Cancelar</span>
                                </button>

                                <button
                                    className={styles.buttonSave}
                                    onClick={() => setOpenModalConfirm(true)}
                                >
                                    <span>Salvar</span>
                                </button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <ModalConfirm
                title="Salvar alterações"
                description="Tem certeza que deseja salvar as alterações?"
                openModal={openModalConfirm}
                setOpenModal={setOpenModalConfirm}
                onClick={(e) => handleSubmit(e)}
                labelConfirm="Salvar"
            />
            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
}
