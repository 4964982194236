import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalConfirm } from "../modal";
import { Api } from "./../../providers/index";
import { dateStringToObject } from "./../../utils/date/index";
import { MyCardTask } from "./../cards/index";

export default function MyTasks({ tasks, profissionalId }) {
    const navigate = useNavigate();
    const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
    const [openModalCancel, setOpenModalCancel] = React.useState(false);
    const [info, setInfo] = React.useState("");
    const [selectTask, setSelectTask] = React.useState(null);

    const handlerClick = (e, task) => {
        e.preventDefault();
        if (task === null) return;

        setSelectTask(task);
        if (task.status === "Confirmado") {
            setOpenModalCancel(true);
        } else {
            let date = dateStringToObject(task.datas[0], "DD/MM/YYYY");
            setInfo(`${date} às ${task.hora}`);
            setOpenModalConfirm(true);
        }
    };
    const handleSubmit = (action = "confirmar") => {
        if (selectTask === null) return;
        let idProf = profissionalId
            ? profissionalId
            : selectTask.profissionalId;
        let idTask = selectTask?.id;
        //Add professional ID
        selectTask.profissionais = [
            {
                id: idProf,
            },
        ];
        //Confirmar
        if (action === "confirmar") {
            Api.post(
                `tarefa/${idTask}/profissional/${idProf}/confirmar`,
                selectTask,
            )
                .then((res) => {
                    setOpenModalConfirm(false);
                    setOpenModalCancel(false);
                    if (res.status === 200) {
                        toast.success("Serviço confirmado com sucesso", {
                            toastId: "success",
                            autoClose: 1000,
                            onClose: function () {
                                window.location.reload();
                            },
                        });
                    }
                })
                .catch((error) => {
                    let message =
                        typeof error.response !== "undefined"
                            ? error.response.data.message
                            : error.message;

                    toast.error("Ops! " + message.error);
                });
        } else {
            //Cancelar
            Api.delete(`tarefa/${idTask}/profissional/${idProf}/cancelar`)
                .then((res) => {
                    setOpenModalConfirm(false);
                    setOpenModalCancel(false);
                    if (res.status === 200) {
                        toast.success("Serviço cancelado com sucesso", {
                            toastId: "success",
                            autoClose: 1000,
                            onClose: function () {
                                window.location.reload();
                            },
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error("Ops! Aconteceu algum problema.");
                });
        }
    };

    return (
        <>
            {Array.isArray(tasks) &&
                tasks.length > 0 &&
                tasks.map((task, key) => {
                    //URl Read more
                    let readmore = `/aceitar-solicitacao?id=${task?.id}&idProfissional=${profissionalId}&preview=profissional`;
                    return (
                        <Grid key={key}>
                            <MyCardTask
                                task={task}
                                onClick={(e) => {
                                    handlerClick(e, task);
                                }}
                                readMore={() => navigate(readmore)}
                            />
                        </Grid>
                    );
                })}
            <ModalConfirm
                id="confirmar"
                title="Confirmar Serviço"
                description={`Tem certeza que deseja confirmar serviço para dia \n ${info}?`}
                openModal={openModalConfirm}
                setOpenModal={setOpenModalConfirm}
                onClick={() => handleSubmit()}
                color="blue"
                labelConfirm="Confirmar"
            />
            <ModalConfirm
                id="cancelar"
                title="Cancelar Serviço"
                description="Tem certeza que deseja cancelar este serviço?"
                openModal={openModalCancel}
                setOpenModal={setOpenModalCancel}
                onClick={() => handleSubmit("cancelar")}
                labelConfirm="Sim"
                labelCancel="Não"
            />
        </>
    );
}
