import React from "react";
import { Typography, Checkbox } from "@mui/material";

export default function MyCheckbox(props) {
    return (
        <Typography sx={{ mx: 0, p: 0 }} component="span" variant="inherit">
            <Checkbox
                style={{
                    color: props.error ? "red" : "#8D8F90",
                }}
                {...props}
            />
            {props.children}
        </Typography>
    );
}
