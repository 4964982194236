import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadCrumb } from "../../components/breadcrumb/index";
import { InputCustomData } from "../../components/inputs/inputs";
import Title from "../../components/title/index";
import { Api } from "../../providers/index";
import { NumberFormatBr } from "../../utils/mask";
import { ModalConfirm } from "./../../components/modal/index";
import styles from "./styles.module.scss";

const ServicoProfissionalEdit = () => {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const idService = new URLSearchParams(search).get("servico");
    const [servicoId, setServicoId] = useState(0);
    const [servico, setServico] = useState("");
    const [servicoValor, setServicoValor] = useState(0);
    const [taxaVisita, setTaxaVisita] = useState(1);
    const [taxaVisitaValor, setTaxaVisitaValor] = useState("");
    //Modal
    const [openModal, setOpenModal] = useState(false);
    const [openModalActive, setOpenModalActive] = useState(false);
    const [active, setActive] = useState(false);
    // req
    const [isReqServicoValor, setIsReqServicoValor] = useState(false);
    const [isReqTaxaValor, setIsReqTaxaValor] = useState(false);
    // validall
    const [isValidAll, setIsValidAll] = useState(false);
    useEffect(() => {
        let mounted = true;
        Api.get(`/profissional/${idQuery}/servico/${idService}`).then((res) => {
            if (res.status === 200) {
                if (mounted) {
                    setActive(res.data.flagAtivo);
                    setServico(res.data.servico.nome);
                    setServicoId(res.data.servicoId);

                    if (res.data.servico.tipoAgendamento === "Orçamento") {
                        setServicoValor(0);
                        setTaxaVisita(1);
                        setTaxaVisitaValor(res.data.taxaVisitaValor);
                    }
                    if (res.data.servico.tipoAgendamento === "Diária") {
                        setTaxaVisitaValor(0);
                        setTaxaVisita(2);
                        if (res.data.servicoValor)
                            setServicoValor(res.data.servicoValor);
                    }
                }
            }
        });
        return () => {
            mounted = false;
        };
    }, [idQuery, idService]);

    const handleClick = (value) => {
        setOpenModal(false);
        setOpenModalActive(false);
        setTimeout(() => {
            setActive(value);
            sendForm(value);
        }, 500);
    };

    useEffect(() => {
        if (isValid(servicoId) && taxaVisita === 2 && isValid(servicoValor)) {
            setIsValidAll(true);
            //clear other input
            setIsReqTaxaValor(false);
            setTaxaVisitaValor(0);
        } else {
            if (
                isValid(servicoId) &&
                taxaVisita === 1 &&
                isValid(taxaVisitaValor)
            ) {
                setIsValidAll(true);
                //clear other input
                setIsReqServicoValor(false);
                setServicoValor(0);
            } else {
                setIsValidAll(false);
            }
        }
    }, [servicoId, servicoValor, taxaVisita, taxaVisitaValor]);
    /**
     * Check Validation
     * It's true, so it's ok!
     * @returns {boolean}
     */
    const isValid = (value) => {
        return parseInt(value) > 0 && value !== "";
    };

    const sendForm = (flagAtivo = true) => {
        let data = {};
        data.flagAtivo = flagAtivo;
        data.servicoValor = servicoValor;
        data.taxaVisitaValor = taxaVisitaValor;
        setActive(flagAtivo);
        Api.put(`profissional/${idQuery}/servico/${idService}`, data)
            .then((res) => {
                if (res.status === 200)
                    toast.success("Profissional atualizado com Sucesso", {
                        toastId: "success",
                    });
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "error",
                });
            });
    };

    const handleSubmit = () => {
        sendForm();
    };

    let checkButtonSubmit = true;

    if (taxaVisita === 1) {
        checkButtonSubmit =
            taxaVisitaValor.length > 0 &&
            Number(taxaVisitaValor) >= 1 &&
            Number(taxaVisitaValor) < 10000 &&
            servicoId > 0
                ? true
                : false;
    } else {
        checkButtonSubmit =
            servicoValor.length > 0 &&
            Number(servicoValor) >= 1 &&
            Number(servicoValor) < 10000 &&
            servicoId > 0
                ? true
                : false;
    }

    return (
        <>
            <Grid className="justify-content-center">
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Profissional",
                        menu: {
                            item: "Usuários",
                            link: "",
                        },
                    }}
                />
                <Title title="Editar serviço do profissional" />
                <div className={styles.containerEdit}>
                    <div className={styles.containerGeralInput}>
                        <div className={styles.containerInput}>
                            <div>
                                <span>Serviço</span>
                                <InputCustomData
                                    id="servico"
                                    name="servico"
                                    value={servico}
                                    fullWidth
                                    disabled
                                    backgroundColor="#ECEDEE"
                                    onChange={(e) => {
                                        setServico(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.containerInput}>
                            {taxaVisita === 1 ? (
                                <div>
                                    <span>Valor da taxa de visita</span>
                                    <InputCustomData
                                        value={taxaVisitaValor}
                                        required={true}
                                        onChange={(e) => {
                                            setTaxaVisitaValor(e.target.value);
                                            isValid(e.target.value)
                                                ? setIsReqTaxaValor(false)
                                                : setIsReqTaxaValor(true);
                                        }}
                                        InputProps={{
                                            inputComponent: NumberFormatBr,
                                        }}
                                    />
                                    {isReqServicoValor && (
                                        <span>
                                            Campo obrigatório! mínimo aceito R$
                                            1,00.
                                        </span>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <span>Valor da diária</span>
                                    <InputCustomData
                                        value={servicoValor}
                                        required={true}
                                        onChange={(e) => {
                                            setServicoValor(e.target.value);
                                            isValid(e.target.value)
                                                ? setIsReqServicoValor(false)
                                                : setIsReqServicoValor(true);
                                        }}
                                        InputProps={{
                                            inputComponent: NumberFormatBr,
                                        }}
                                    />
                                    {isReqServicoValor && (
                                        <span>
                                            Campo obrigatório! mínimo aceito R$
                                            1,00.
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.containerInfoButton}>
                        <div className={styles.containerButtonEdit}>
                            {active ? (
                                <div
                                    onClick={(e) => {
                                        setOpenModal(!openModal);
                                    }}
                                >
                                    <span>Desativar serviço</span>
                                </div>
                            ) : (
                                <div
                                    onClick={(e) => {
                                        setOpenModalActive(!openModalActive);
                                    }}
                                >
                                    <span>Ativar serviço</span>
                                </div>
                            )}
                            <button
                                className={
                                    !checkButtonSubmit
                                        ? styles.buttonSearchBlock
                                        : styles.buttonSearch
                                }
                                type="button"
                                onClick={handleSubmit}
                                disabled={!checkButtonSubmit}
                            >
                                <span>Salvar</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Grid>
            <ModalConfirm
                labelXSair="nao"
                title="Desativar Serviço"
                description="Tem certeza que deseja desativar este serviço?"
                openModal={openModal}
                setOpenModal={setOpenModal}
                onClick={() => handleClick(false)}
                labelConfirm="Desativar"
            />
            <ModalConfirm
                labelXSair="nao"
                title="Ativar Serviço"
                description="Tem certeza que deseja ativar este serviço?"
                openModal={openModalActive}
                setOpenModal={setOpenModalActive}
                onClick={() => handleClick(true)}
                labelConfirm="Ativar"
            />
        </>
    );
};

export default ServicoProfissionalEdit;
