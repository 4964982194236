import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ServiceContext } from "../../../contexts/service";
import { CompareDateIfBefore, DateBR, TimeBRCheck } from "../../../utils/date";
import btedit from "./../../../assets/images/bt-edit.png";
import styles from "./servico.module.scss";
const ENDPOINT = "/treinamento/";
export function ListTable({ currentItems }) {
    const { totalUnits } = useContext(ServiceContext);

    // *************************************************************************
    const operador = JSON.parse(localStorage.getItem("user"));
    const permissoes = JSON.parse(localStorage.getItem("permissoes"));
    const checkOp =
        operador?.operador?.funcao?.slug == "administrador" ? true : false;
    const permTreinamentos = permissoes?.find(
        (val) => val.tela.slug == "treinamentos",
    );
    // *************************************************************************

    const checkUnits = (dataInfo) => {
        let resultInit = [];
        const _ = dataInfo.map((data) => {
            resultInit.push(data.nome);
        });
        return dataInfo.length >= totalUnits ? "Todas" : resultInit.join(" / ");
    };

    return (
        <div className={`table-responsive ${styles.radiusTable}`}>
            <table className={`table align-middle ${styles.table}`}>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Horário</th>
                        <th>Modalidade</th>
                        <th>Local</th>
                        <th>Operador responsável</th>
                        <th>Unidades</th>
                        <th>Vagas disp.</th>
                        {(checkOp || permTreinamentos?.atualizar) && (
                            <th>Ação</th>
                        )}
                    </tr>
                </thead>
                <tbody data-test="table-body">
                    {currentItems.length > 0 ? (
                        currentItems.map((val, idx) => {
                            const colorTr = val.flagAbrirDatas
                                ? styles.colorTrRed
                                : idx % 2 == 0
                                ? styles.colorTrOne
                                : styles.colorTrTwo;
                            return (
                                <tr className={colorTr} key={val.id.toString()}>
                                    <td>
                                        {CompareDateIfBefore(
                                            val.dataHoraInicio,
                                            val.dataHoraFim,
                                        ) ? (
                                            <>
                                                {DateBR(val.dataHoraInicio)} às{" "}
                                                {DateBR(val.dataHoraFim)}
                                            </>
                                        ) : (
                                            <> {DateBR(val.dataHoraInicio)} </>
                                        )}
                                    </td>
                                    <td>
                                        {
                                            <>
                                                {TimeBRCheck(
                                                    val.dataHoraInicio,
                                                )}{" "}
                                                às{" "}
                                                {TimeBRCheck(val.dataHoraFim)}
                                            </>
                                        }
                                    </td>
                                    <td>{val.modalidade}</td>
                                    <td>{val.local}</td>
                                    <td>
                                        {val.operador.nome
                                            ? val.operador.nome
                                            : ""}
                                    </td>
                                    <td>{checkUnits(val.unidades)}</td>
                                    <td>{val.vagas}</td>
                                    {(checkOp ||
                                        permTreinamentos?.atualizar) && (
                                        <td>
                                            <Link
                                                to={`${ENDPOINT}editar?id=${val.id}`}
                                            >
                                                <img
                                                    src={btedit}
                                                    alt="Editar"
                                                />{" "}
                                            </Link>
                                        </td>
                                    )}
                                </tr>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
        </div>
    );
}
