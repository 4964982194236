import { InputBase, TextField } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React from "react";
const Item = {
    maxWidth: "400px",
    height: "56px",
    display: "flex",
    marginTop: "32px",
    justifyContent: "start",
    alignContent: "center",
    padding: 0,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    alignItems: "center",
    color: "#343B40",
    backgroundColor: "#ffffff",
    "& .MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "17px",
        color: "#343B40",
    },
    "& .MuiFormHelperText-root  ": {
        m: 0,
        p: 0,
        fontSize: 10,
        alignSelf: "end",
        alignItems: "end",
        color: "red",
    },
};

const ItemInputCustom = {
    marginTop: 0,
    marginBottom: 0,
    "& .MuiInputBase-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#343B40",
        backgroundColor: "#FFFFFF !important",
        borderRadius: "8px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        top: -9,
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
    },
};

const ItemInputCustomData = {
    marginTop: 0,
    marginBottom: 0,
    "& .MuiInputBase-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#343B40",
        backgroundColor: "#FFFFFF !important",
        borderRadius: "8px !important",
        height: 56,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        top: -9,
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
    },
};

export const MyInput = (props) => {
    return (
        <TextField
            fullWidth
            sx={Item}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
                shrink: true,
            }}
            {...props}
        />
    );
};

export const InputCustom = (props) => {
    return (
        <TextField
            className="dataService"
            fullWidth
            sx={ItemInputCustom}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
                shrink: true,
            }}
            {...props}
        />
    );
};

export const InputCustomData = (props) => {
    const customItem = {
        "& .MuiInputBase-root": {
            backgroundColor: `${props.backgroundColor} !important`,
        },
    };
    return (
        <TextField
            className="dataService"
            fullWidth
            sx={[ItemInputCustomData, customItem]}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
                shrink: true,
            }}
            {...props}
        />
    );
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    minWidth: "150px",
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        minWidth: "150px",
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
        border: "1px solid #ced4da",
        fontSize: 16,
        width: "auto",
        padding: "10px 12px",
        transition: theme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: "Inter",
        "&:focus": {
            boxShadow: `${alpha(
                theme.palette.primary.main,
                0.25,
            )} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));
export const MyInputDefault = (props) => {
    return (
        <BootstrapInput fullWidth placeholder="Insira um texto" {...props} />
    );
};
