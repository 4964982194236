import { Grid, MenuItem } from "@mui/material";
import qs from "qs";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { ServiceContext } from "../../../../contexts/service";
import { Api } from "../../../../providers";
import { FormatDate } from "../../../../utils/date";
import { InputSearch } from "../../../inputs";
import MyDropdown from "./../../../dropdown/dropdown";
import { Item } from "./../../style";

export default function SolicitacaoProfissionalSearch(props) {
    const {
        setProfissionais,
        enderecoID,
        data,
        selectCliente,
        servicoId,
        setIsNominal,
    } = useContext(ServiceContext);
    const [busca, setBusca] = useState("");
    const [valor, setValor] = useState("0");
    const [avaliacao, setAvaliacao] = useState("0");
    const [distancia, setDistancia] = useState("0");

    const handleSumbit = (event) => {
        event.preventDefault();

        if (!selectCliente[0]) {
            toast.error(`Ops! Selecione um cliente`, {
                toastId: "error",
            });
        }

        if (!enderecoID) {
            toast.error(`Ops! Selecione um endereço`, {
                toastId: "error",
            });
        }

        let ENDPOINT = `/profissional/agenda/cliente/${
            selectCliente[0]
        }/endereco/${enderecoID}/servico/${servicoId}/data/${FormatDate(
            data,
        )}/?`;

        let params = {};
        if (valor && valor !== "0") params.valor = valor; // params.taxaVisita
        if (avaliacao && avaliacao !== "0") params.avaliacao = avaliacao;
        if (distancia && distancia !== "0") params.distancia = distancia;
        //Se busca muda endpoint e params: apenas profissional
        if (busca) {
            //reset
            params = {};
            ENDPOINT = `/profissional/agenda/cliente/${selectCliente[0]}/endereco/${enderecoID}/?fields=${busca}`;
            //Set Nominal: true
            setIsNominal(true);
        }

        Api.get(ENDPOINT, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {                
                // Provider
                setProfissionais(res.data);
                if (res.data.length <= 0) {
                    toast.success("Nenhum resultado encontrado!", {
                        toastId: "msg",
                    });
                }
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;

                toast.error(`Ops! ${message.error}`, {
                    toastId: "error",
                });
            });
        return true;
    };

    return (
        <>
            <form>
                <Grid
                    // container
                    direction="row"
                    spacing={0.5}
                    sx={{ m: 0, p: 0 }}
                >
                    {props.search ? (
                        <Grid item md={12}>
                            <InputSearch
                                placeholder="Pesquisar aqui pelo nome do profissional, cpf, telefone ou e-mail"
                                onClick={handleSumbit}
                                onChange={(e) => setBusca(e.target.value)}
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                    {props.filter ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Grid
                                sx={{
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    lineHeight: "16px",
                                    fontSize: "17px",
                                    color: "#0064b4",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <span>Filtrar resultados</span>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    direction: "row",
                                    spacing: 1,
                                    pt: 2,
                                    gap: 0.5,
                                }}
                            >
                                <Grid
                                    sx={{
                                        width: 163,
                                    }}
                                >
                                    <MyDropdown
                                        sx={Item}
                                        label="Valor"
                                        value={valor}
                                        onChange={(e) =>
                                            setValor(e.target.value)
                                        }
                                    >
                                        {["menor", "maior"].map((item, key) => {
                                            return (
                                                <MenuItem
                                                    value={item}
                                                    key={key + 1}
                                                >
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </MyDropdown>
                                </Grid>

                                {/* <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                /> */}
                                <Grid
                                    sx={{
                                        width: 163,
                                    }}
                                >
                                    <MyDropdown
                                        sx={Item}
                                        label="Avaliação"
                                        value={avaliacao}
                                        onChange={(e) =>
                                            setAvaliacao(e.target.value)
                                        }
                                    >
                                        {["menor", "maior"].map((item, key) => {
                                            return (
                                                <MenuItem
                                                    value={item}
                                                    key={key + 1}
                                                >
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </MyDropdown>
                                </Grid>

                                <Grid
                                    sx={{
                                        width: 230,
                                        marginRight: 2,
                                    }}
                                >
                                    <MyDropdown
                                        sx={Item}
                                        label="Distância"
                                        value={distancia}
                                        onChange={(e) =>
                                            setDistancia(e.target.value)
                                        }
                                    >
                                        {[
                                            {
                                                key: "menor",
                                                name: "Menor distância",
                                            },
                                            {
                                                key: "maior",
                                                name: "Maior distância",
                                            },
                                        ].map((item, key) => {
                                            return (
                                                <MenuItem
                                                    value={item.key}
                                                    key={key + 1}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </MyDropdown>
                                </Grid>
                            </Grid>
                            <Grid>
                                <button
                                    className="button-pesquisar"
                                    style={{
                                        width: "163px",
                                        margin: "30px 0px",
                                    }}
                                    type="submit"
                                    onClick={handleSumbit}
                                >
                                    Pesquisar
                                </button>
                            </Grid>
                        </div>
                    ) : (
                        ""
                    )}
                </Grid>
            </form>
        </>
    );
}
