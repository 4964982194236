import { format } from "date-fns";
import React from "react";
import { Icon } from "react-icons-kit";
import { ic_remove_red_eye_outline } from "react-icons-kit/md/ic_remove_red_eye_outline";
import { useNavigate } from "react-router-dom";
import btbook from "../../../../assets/images/book.png";
import { ConvertCurrency } from "../../../../utils/currency";
import { DateBR } from "../../../../utils/date";
import { MyRating } from "../../../rating";
import btedit from "./../../../../assets/images/bt-edit.png";
import styles from "./styles.module.scss";

export function ListTable({ currentItems, idQuery, fetchRecibo }) {
    const navigate = useNavigate();

    //Color Status
    const getColorStatus = (status) => {
        switch (status) {
            case "A Confirmar":
                return "#F9B000";
            case "Confirmado":
                return "#95C11F";
            case "Cancelado":
                return "#E60E19";
            case "Realizado":
                return "#0064B4";
            case "Avaliado":
                return "#1486E1";
            case "Expirado":
                return "#E60E19";
            case "Não Realizado":
                return "#656A6E";
            default:
                return "#656A6E";
        }
    };

    const handleNewPage = (value) => {
        navigate(value);
    };

    const valorServico = (row) => {
        if (row.valor) {
            return `${ConvertCurrency(row.valor)}`;
        }
        if (row.servico.servicoValor) {
            return `${ConvertCurrency(row.servico.servicoValor)}`;
        }
        if (row.servico.taxaVisitaValor) {
            return `${ConvertCurrency(row.servico.taxaVisitaValor)}`;
        }
        return `-`;
    };

    const formatTime = (dataList, timeList) => {
        let customTime = "";
        if (timeList) {
            const exactDate = `${dataList} ${timeList}`;
            const exactDateTimestamp = new Date(exactDate).getTime();
            if (exactDateTimestamp) {
                customTime = format(exactDateTimestamp, "H:mm");
            }
        }
        return customTime;
    };

    const actionsType = ["A Confirmar", "Confirmado", "Realizado", "Avaliado"];

    const actionShow = ["Cancelado", "Expirado", "Não Realizado"];

    return (
        <div className={`table-responsive ${styles.radiusTable}`}>
            <table className={`table align-middle ${styles.table}`}>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Nº da solicitação</th>
                        <th>Serviço</th>
                        <th>Data do serviço</th>
                        <th>Hora</th>
                        <th>Nome do profissional</th>
                        <th>Avaliação</th>
                        <th>Valor</th>
                        <th>Endereço</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody data-test="table-body" className={styles.tbody}>
                    {currentItems.length > 0 &&
                        currentItems.map((val, idx) => {
                            // Endereço
                            // let titulo = val.endereco.titulo;
                            // let bairro = val.endereco.bairro;
                            // let cidade = val.endereco.cidade;
                            const logradouro = val.endereco.logradouro;
                            const numero = val.endereco.numero;
                            const enderecoCompleto = `${logradouro} ${numero}`;

                            const colorTrLine =
                                val.status == "Realizado"
                                    ? styles.colorTrRed
                                    : {};

                            return (
                                <tr
                                    key={idx.toString()}
                                    className={colorTrLine}
                                >
                                    <td>
                                        <div
                                            className={styles.boxColor}
                                            style={{
                                                backgroundColor: getColorStatus(
                                                    val.status,
                                                ),
                                            }}
                                        >
                                            <span
                                                className={
                                                    styles.pillBadgesText
                                                }
                                            >
                                                {val.status}
                                            </span>
                                        </div>
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.id}
                                    </td>
                                    <td className={styles.infoTextDesc}>
                                        {val.servico?.servico.nome}
                                    </td>
                                    <td className={styles.infoText}>
                                        {DateBR(val.datas[0])}
                                    </td>
                                    <td className={styles.infoText}>
                                        {formatTime(val.datas[0], val.hora)}
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.profissionais[0]?.nome}
                                    </td>

                                    <td>
                                        <MyRating
                                            value={
                                                val.avaliacao
                                                    ? val.avaliacao
                                                    : 0
                                            }
                                        />
                                    </td>
                                    <td className={styles.infoText}>
                                        {valorServico(val)}
                                    </td>
                                    <td className={styles.infoText}>
                                        {enderecoCompleto}
                                    </td>
                                    <td>
                                        <div className={styles.containerAcoes}>
                                            {actionsType.includes(
                                                val.status,
                                            ) && (
                                                <span
                                                    onClick={() =>
                                                        handleNewPage(
                                                            `/aceitar-solicitacao/?id=${val.id}&idProfissional=${idQuery}&preview=cliente`,
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={btedit}
                                                        alt="editar"
                                                    />
                                                </span>
                                            )}

                                            {val.status === "Avaliado" && (
                                                <span
                                                    onClick={() =>
                                                        fetchRecibo(val.id)
                                                    }
                                                >
                                                    <img
                                                        src={btbook}
                                                        alt="recibo"
                                                    />
                                                </span>
                                            )}

                                            {actionShow.includes(
                                                val.status,
                                            ) && (
                                                <span
                                                    style={{ color: "#0064B4" }}
                                                    onClick={() =>
                                                        handleNewPage(
                                                            `/aceitar-solicitacao/?id=${val.id}&idProfissional=${idQuery}&preview=cliente`,
                                                        )
                                                    }
                                                >
                                                    <Icon
                                                        size={24}
                                                        icon={
                                                            ic_remove_red_eye_outline
                                                        }
                                                        alt="ver"
                                                    />
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}
