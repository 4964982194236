import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import qs from "qs";
import { Api } from "./../../../../providers";
import { ServiceContext } from "../../../../contexts/service";
import { SearchField } from "../components/searchField";

export const SearchRestricaoCliente = ({ idQuery }) => {
    const {
        setRestProf, setLoading, setParamsFilter, setTotalElements,
        setTotalPages
    } = useContext(ServiceContext);
    const [busca, setBusca] = useState("");
    
    const handleSumbit = (event) => {
        event.preventDefault();
        setLoading(true);

        let params = {};
        if (busca !== "") params.fields = busca;

        Api.get(`/restricao/cliente/${idQuery}/pagination/0/2?`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Provider
                setRestProf(res.data.content);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setParamsFilter(params);
                setLoading(false);
            })
            .catch(_ => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    }

    return (
        <div className="conteudo-tabela">
            <form>
                <div>
                    <div>
                        <SearchField
                            placeholder="Nome do profissional..."
                            onClick={handleSumbit}
                            onChange={(e) => searchText(e.target.value)}
                            search={busca}
                            checkDisabled={busca.length <= 0 ? true : false}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};
