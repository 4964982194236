import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { BaseURL } from "../../../api";
import { BreadCrumb } from "../../../components/breadcrumb";
import MyDatePicker from "../../../components/datepicker";
import { ModalConfirmFormato } from "../../../components/modal";
import Title from "../../../components/title";
import { AuthContext } from "../../../contexts/auth";
import { useUnidades } from "../../../hooks/useScriptRef";
import styles from "./styles.module.scss";

const DashboardReportProd = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const unidadesServer = useUnidades();

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startDateError, setStartDateError] = useState("Campo obrigatório");
    const [endDateError, setEndDateError] = useState("Campo obrigatório");
    const [textReqError, setTextReqError] = useState("Campo obrigatório");
    const [unidadeID, setUnidadeID] = useState("");
    const [modalSelectFormat, setModalSelectFormat] = useState(false);
    const token = localStorage.getItem("token");

    useEffect(() => {
        setCurrentScreen(10);
    }, []);

    const handleSelectUnit = (text) => {
        const result = parseInt(text);
        if (result >= 0) {
            setUnidadeID(text);
            setTextReqError("");
        } else {
            setTextReqError("Campo obrigatório");
        }
    };

    const handleStartDate = (text) => {
        const checkDate01 = moment(text, "YYYY-MM-DD").valueOf();
        const checkDate02 = moment(endDate, "YYYY-MM-DD").valueOf();
        const subtractDate = moment(endDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        const checkDateStart =
            moment(text, "YYYY-MM-DD").valueOf() > 0 ? true : false;
        if (text && checkDateStart) {
            const dateSelected = new Date(text);
            if (!isNaN(checkDate02)) {
                if (checkDate01 <= checkDate02) {
                    setStartDate(dateSelected);
                    setStartDateError("");
                    setEndDateError("");
                } else {
                    if (checkDateStart) {
                        setStartDate(subtractDate);
                    } else {
                        setStartDate(dateSelected);
                    }
                    setStartDateError("");
                }
            } else {
                setStartDate(dateSelected);
                setStartDateError("");
            }
        } else {
            setStartDate("");
            setStartDateError("Campo obrigatório");
        }
    };

    const handleEndDate = (text) => {
        const checkDate01 = moment(text, "YYYY-MM-DD").valueOf();
        const checkDate02 = moment(startDate, "YYYY-MM-DD").valueOf();
        const addDay = moment(startDate).add(1, "days").format("YYYY-MM-DD");
        const checkDateEnd =
            moment(text, "YYYY-MM-DD").valueOf() > 0 ? true : false;
        if (text && checkDateEnd) {
            const dateSelected = new Date(text);
            if (!isNaN(checkDate02)) {
                if (checkDate01 >= checkDate02) {
                    setEndDate(dateSelected);
                    setEndDateError("");
                    setStartDateError("");
                } else {
                    if (checkDateEnd) {
                        setEndDate(addDay);
                    } else {
                        setEndDate(dateSelected);
                    }
                    setEndDateError("");
                }
            } else {
                setEndDate(dateSelected);
                setEndDateError("");
            }
        } else {
            setEndDate("");
            setEndDateError("Campo obrigatório");
        }
    };

    const checkDate01 =
        moment(startDate, "YYYY-MM-DD").valueOf() > 0 ? true : false;
    const checkDate02 =
        moment(endDate, "YYYY-MM-DD").valueOf() > 0 ? true : false;
    const checkFilterSubmit =
        unidadeID !== "" && checkDate01 && checkDate02 ? false : true;

    const handleBtn1 = () => {
        setModalSelectFormat(false);
        const date01 = moment(startDate).format("YYYY-MM-DD");
        const date02 = moment(endDate).format("YYYY-MM-DD");
        const pdf = `${BaseURL}api/relatorio/export-pdf?unidadeId=${
            unidadeID == 0 ? "" : unidadeID
        }&dataInicio=${date01}&dataFim=${date02}&token=${token}`;
        window.open(pdf);
    };

    const handleBtn2 = () => {
        setModalSelectFormat(false);
        const date01 = moment(startDate).format("YYYY-MM-DD");
        const date02 = moment(endDate).format("YYYY-MM-DD");
        const excel = `${BaseURL}api/relatorio/export-excel?unidadeId=${
            unidadeID == 0 ? "" : unidadeID
        }&dataInicio=${date01}&dataFim=${date02}&token=${token}`;
        window.open(excel);
    };

    const handleSelectOption = () => setModalSelectFormat(true);

    return (
        <Container
            fluid
            className={`justify-content-center ${styles.containerGeneral}`}
        >
            <BreadCrumb
                data={{
                    category: "",
                    page: "Produtividade",
                    menu: {
                        item: "Relatórios",
                        link: "/relatorios/produtividade",
                    },
                }}
            />
            <Title title="Relatório de produtividade" />
            <Container fluid className={`listagem`}>
                <div className={styles.containerFilds}>
                    <span
                        className={
                            textReqError
                                ? styles.textLabelRed
                                : styles.textLabel
                        }
                    >
                        Unidade
                    </span>
                    <div className={styles.containerSelect}>
                        <div
                            className={`${styles.fieldSelect} ${
                                textReqError ? styles.formGroupError : ""
                            }`}
                        >
                            <div>
                                <select
                                    className="form-select"
                                    value={unidadeID}
                                    onChange={(e) =>
                                        handleSelectUnit(e.target.value)
                                    }
                                >
                                    <option value="" key="0" disabled>
                                        Selecione a unidade
                                    </option>
                                    <option value="0" key="999">
                                        Todos
                                    </option>
                                    {unidadesServer.map(({ id, nome }) => {
                                        return (
                                            <option value={id} key={id}>
                                                {nome}
                                            </option>
                                        );
                                    })}
                                </select>
                                <span className={styles.requiredField}>
                                    {textReqError}
                                </span>
                            </div>
                        </div>
                        <div className={styles.fieldSelect} />
                        <div className={styles.inputData}>
                            <span
                                className={
                                    startDateError
                                        ? styles.textLabelRed
                                        : styles.textLabel
                                }
                            >
                                Data Início
                            </span>
                            <MyDatePicker
                                value={startDate}
                                onChange={(e) => handleStartDate(e)}
                            />
                            <span className={styles.requiredField}>
                                {startDateError}
                            </span>
                        </div>
                        <div className={styles.inputData}>
                            <span
                                className={
                                    endDateError
                                        ? styles.textLabelRed
                                        : styles.textLabel
                                }
                            >
                                Data Fim
                            </span>
                            <MyDatePicker
                                value={endDate}
                                onChange={(e) => handleEndDate(e)}
                            />
                            <span className={styles.requiredField}>
                                {endDateError}
                            </span>
                        </div>
                    </div>
                    <div className={styles.containerButton}>
                        <button
                            className={
                                checkFilterSubmit
                                    ? styles.buttonSearchBlock
                                    : styles.buttonSearch
                            }
                            type="submit"
                            onClick={
                                checkFilterSubmit
                                    ? () => {}
                                    : (e) => handleSelectOption(e)
                            }
                            disabled={checkFilterSubmit}
                        >
                            Gerar relatório
                        </button>
                    </div>
                </div>
            </Container>
            <ModalConfirmFormato
                title="Selecione formato"
                openModal={modalSelectFormat}
                setOpenModal={setModalSelectFormat}
                onClickBtn1={() => handleBtn1()}
                onClickBtn2={() => handleBtn2()}
                labelBtn1="Baixar em PDF"
                colorBtn1="blue"
                labelBtn2="Baixar em Excel"
                colorBtn2="blue"
            />
        </Container>
    );
};

export default DashboardReportProd;
