let data = {};
// Data Demo
export default data = {
    columns: [
        {
            label: "Situação",
            width: "15%", //não utilizado
        },
        {
            label: "Nome",
            width: "30%",
        },
        {
            label: "Rg e CPF",
            width: "15%",
        },
        {
            label: "Telefone",
            width: "20%",
        },
        {
            label: "Data de Inscrição",
            width: "20%",
        },
    ],
    rows: [
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
        {
            Situação: "Inscrito",
            Nome: "Maria da Silva Conceição de Nobrega",
            "Rg e CPF": "80038880000",
            Telefone: "(91) 9999-9999",
            "Data de Inscrição": "06/07/2022",
        },
    ],
};
