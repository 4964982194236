import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { BreadCrumb } from "./../../../components/breadcrumb";
import Title from "../../../components/title";
import { OperadoresProvider } from "../../../contexts/service";
import OperadoresTable from "../../../components/tables/operadores";
import { AuthContext } from "../../../contexts/auth";
import Search from "./search";

const DashboardOperadores = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    useEffect(() => {
        setCurrentScreen(3);
    }, []);

    return (
        <OperadoresProvider>
            <Container fluid className="justify-content-center">
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Configurações",
                    }}
                />
                <Title title="Operadores" />
                <Container className="listagem">
                    <Search />
                    <OperadoresTable />
                </Container>
            </Container>
        </OperadoresProvider>
    );
};

export default DashboardOperadores;
