import React from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {SearchRestricaoProfissional} from "./../../../components/search/restricao/profissional/";
import {AreaAtendimentoTableProfissional} from "../../../components/tables/areaAtendimento/profissional/";

//Documents
export default function Section9() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    return (
        <>
            <Container fluid className="justify-content-center">
                {/* <SearchRestricaoProfissional idQuery={idQuery} /> */}
                <AreaAtendimentoTableProfissional idQuery={idQuery} />
            </Container>
        </>
    );
}