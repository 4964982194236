//Use complete numbers with commas or dollar sign (R$)
export const ConvertCurrency = (value, defaultLocale = "pt-br") => {
    if (value && value != "undefined") {
        return value.toLocaleString(defaultLocale, {
            style: "currency",
            currency: "BRL",
        });
    }
    return value;
};

//Use complete numbers without commas or dollar sign (R$)
export const ConvertCurrencyNotSign = (value, defaultLocale = "pt-br") => {
    if (value && value != "undefined") {
        return value.toLocaleString(defaultLocale, {
            minimumFractionDigits: 2,
        });
    }
    return value;
};

export const ConvertCurrencyNotSignUS = (value) => {
    if (value && value != "undefined") {
        let mewValue = parseFloat(value.replace(/\s|[$a-zA-Z]/gi, ""));
        return mewValue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    }
    return null;
};
