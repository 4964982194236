import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { MDBDataTable } from "mdbreact";
import React from "react";
import { useUnidades } from "../../../../hooks/useScriptRef";
import { CpfCnpjMaskRegex, TelMaskRegex } from "../../../../utils/mask";

const ProfissionaisSolicitadosTable = (profissionais) => {
    const unidadesServer = useUnidades();

    const getUnidadeProfissional = (idUnidadeProfissionalSelecionado) => {
        let unidades = "";
        unidadesServer.map((unit) => {
            if (unit.id == idUnidadeProfissionalSelecionado) {
                unidades = unidades + unit.nome + ", ";
            }
        });
        return unidades.slice(0, -2);
    };

    const getClassAndNameProfessional = (nomeStatus, color) => {
        return (
            <Box
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "118px",
                    height: "23px",
                    padding: "0px 2px",
                    borderRadius: "99px",
                    background: color,
                }}
            >
                <Typography
                    sx={{
                        m: 0,
                        color: "#FFFFFF",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                    }}
                >
                    {nomeStatus}
                </Typography>
            </Box>
        );
    };

    const data = {
        columns: [
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 150,
            },
            {
                label: "Nome",
                field: "nome",
                sort: "asc",
                width: 200,
            },
            {
                label: "CPF",
                field: "cpf",
                sort: "asc",
                width: 200,
            },
            {
                label: "Telefone",
                field: "telefone",
                sort: "asc",
                width: 200,
            },
            {
                label: "Unidade",
                field: "unidade",
                sort: "asc",
                width: 200,
            },
        ],
        rows: [],
    };
    if (profissionais) {
        data.rows = profissionais.profissionais.map((row) => {
            return {
                status: getClassAndNameProfessional(
                    row.situacao.nome,
                    row.situacao.hexadecimal,
                ),
                nome: (
                    <span className="profissionaisTablaName">{row.nome}</span>
                ),
                cpf: CpfCnpjMaskRegex(row.cpf),
                unidade: getUnidadeProfissional(row.unidadeId),
                telefone: TelMaskRegex(row.telefoneCelular),
            };
        });
    }

    return (
        <MDBDataTable
            striped
            bordered={false}
            data={data}
            entries={4}
            className="conteudo-tabela"
            infoLabel={["Mostrando", "para", "de", "entradas"]}
            searchLabel="Buscar"
            entriesLabel="Mostrar"
            entriesOptions={[4, 10, 20, 50, 100]}
            paginationLabel={["Anterior", "Próximo"]}
            searching={false}
            responsive={true}
        />
    );
};

export default ProfissionaisSolicitadosTable;
