import axios from "axios";
import { BaseURL } from "./../api/index";

let baseURL = BaseURL;
const token = localStorage.getItem("token");
export const Api = axios.create({
    baseURL: `${baseURL}api`,
    headers: {
        Authorization: `Bearer ${token}`,
    },
});

export const ApiLogin = axios.create({
    baseURL: `${baseURL}`,
});
export const ApiCep = axios.create({
    baseURL: `${baseURL}api/cep/`,
});
