import { Grid, MenuItem, Typography } from "@mui/material";
import React from "react";
import { MyTextArea } from "../../../components/textarea";
import { Api } from "../../../providers";
import { CountCaracter } from "./../../../components/caracter/index";
import MyDatePicker from "./../../../components/datepicker/";
import MyDropdown from "./../../../components/dropdown/dropdown";
import WhiteLine from "./../../../components/line/index";
import SolicitacaoProfissionalSearch from "./../../../components/search/solicitacao/profissional/index";
import { MyMultiSelectTS } from "./../../../components/select/tiposervico/index";
import SolicitacaoProfssionalTable from "./../../../components/tables/solicitacao/solicitacaoprofssional";
import MyTimePicker from "./../../../components/timerpicker/";
import { ServiceContext } from "./../../../contexts/service";
import { limitString } from "./../../../utils/string/index";
import AddSolicitacao from "./../CriarSolicitacao/add";

export default function Section1() {
    const { servicoList, setServicoList } = React.useContext(ServiceContext);
    const { servicoId, setServicoId } = React.useContext(ServiceContext);
    //Data e Hora
    const { horario, setHorario } = React.useContext(ServiceContext);
    const { data, setData } = React.useContext(ServiceContext);
    // Tipo Serviço
    const { tipoId, setTipoId } = React.useContext(ServiceContext);
    const { tipoList } = React.useContext(ServiceContext);
    //Descrição
    const { descricao, setDescricao, openDescription, setOpenDescription } =
        React.useContext(ServiceContext);
    //Fix MultiSelects
    const { setSelectProfissional, setIsNominal, isNominal, profissionais } =
        React.useContext(ServiceContext);

    // List Service
    React.useEffect(() => {
        let mounted = true;
        Api.get("/servico/filter?flagAtivo=true").then((res) => {
            if (res.status === 200) {
                if (mounted) setServicoList(res.data);
            }
        });
        return () => {
            mounted = false;
        };
    }, []);
    //Description
    const verifyArrayTS = (arr) => {
        let res = arr.findIndex((item) => item === "Outros");
        return res !== -1;
    };
    React.useEffect(() => {
        if (verifyArrayTS(tipoId)) {
            setOpenDescription(true);
        } else {
            setOpenDescription(false);
            setDescricao("");
        }
    }, [tipoId]);

    //Fix MultiSelects
    React.useEffect(() => {
        setSelectProfissional([]);
        setIsNominal(false);
    }, []);

    React.useEffect(() => {
        //Se por datas seleciona todos
        if (!isNominal) {
            let res = profissionais.map(({ id, name }) => {
                return id;
            });
            setSelectProfissional(res);
        }
    }, [isNominal, profissionais]);
    return (
        <>
            <Grid container spacing={0} sx={{ m: 0, p: 0 }}>
                <Grid item md={12}>
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "17px",
                            color: "#343B40",
                        }}
                    >
                        Nova Solicitação de Serviço
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <MyDropdown
                        id="servico"
                        name="servico"
                        label="Serviço"
                        value={servicoId}
                        required
                        onChange={(e) => {
                            setServicoId(e.target.value);
                        }}
                    >
                        {servicoList.map(({ id, nome }) => (
                            <MenuItem key={id} value={id}>
                                {limitString(nome)}
                            </MenuItem>
                        ))}
                    </MyDropdown>

                    <MyDatePicker
                        value={data}
                        title="Data"
                        onChange={(e) => setData(e)}
                        sx={{
                            maxWidth: 400,
                            color: "#ff0000",
                            backgroundColor: "#fff",
                            p: 0,
                            mt: "32px",
                            display: "flex",
                            justifyContent: "start",
                            alignContent: "center",
                        }}
                    />
                </Grid>
                <Grid item md={6}>
                    <MyMultiSelectTS
                        id="tipo-servico"
                        label="Tipo de Serviço"
                        name="tipo-servico"
                        value={tipoId}
                        onChange={setTipoId}
                    >
                        {tipoList}
                    </MyMultiSelectTS>

                    <MyTimePicker
                        label="Horário"
                        name="horario"
                        value={horario}
                        onChange={(e) => setHorario(e)}
                        sx={{
                            maxWidth: 400,
                            backgroundColor: "#fff",
                            color: "#292E32",
                            marginTop: "32px",
                            p: 0,
                            mt: "32px",
                            display: "flex",
                            justifyContent: "start",
                            alignContent: "center",
                        }}
                    />
                </Grid>

                <Grid
                    item
                    md={12}
                    sx={{ display: openDescription ? "display" : "none" }}
                >
                    <MyTextArea
                        label="Descrição"
                        name="descricao"
                        value={descricao}
                        onChange={({ target }) =>
                            target.value.length <= 100
                                ? setDescricao(target.value)
                                : ""
                        }
                    />
                    <CountCaracter
                        totalText={100}
                        countText={descricao.length}
                    />
                </Grid>

                <WhiteLine />

                <Grid
                    item
                    md={12}
                    sx={{
                        backgroundColor: "#F7F7F7",
                        p: 1.0,
                    }}
                >
                    <SolicitacaoProfissionalSearch filter={true} />
                    <SolicitacaoProfssionalTable />
                </Grid>
                <Grid item md={12}>
                    <AddSolicitacao onlyButton={true} />
                </Grid>
            </Grid>
        </>
    );
}
