import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ServiceContext } from "../../../../contexts/service";
import { Api } from "./../../../../providers";
import { limitString } from "./../../../../utils/string/index";
import MyDatePicker from "./../../../datepicker/index";
import { SearchField } from "./components/searchField";
import styles from "./styles.module.scss";

const SearchHistoricoCliente = ({ idQuery }) => {
    const { setClientesHist, setLoading, setParamsFilter, setTotalPages } =
        useContext(ServiceContext);

    const [busca, setBusca] = useState("");
    const [situacao, setSituacao] = useState([]);
    const [situacaoID, setSituacaoID] = useState("");
    const [servicos, setServicos] = useState([]);
    const [servicoID, setServicoID] = useState("");
    //Datas
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);

    useEffect(() => {
        let mounted = true;
        Api.get("/servico/").then((res) => {
            if (mounted) {
                setServicos(res.data);
            }
        });
        Api.get("/tarefa/status/").then((res) => {
            if (mounted) {
                setSituacao(res.data);
            }
        });

        return () => {
            mounted = false;
            setServicos([]);
            setSituacao([]);
        };
    }, []);

    const handleSumbit = (event) => {
        event.preventDefault();
        setLoading(true);
        if (parseInt(idQuery) < 1) {
            toast.error("Ops! Selecione corretamente o cliente!", {
                toastId: "msg",
            });
            return;
        }
        let params = {};
        //Obrigatório
        // if (idQuery) params.clienteId = idQuery;
        //Opcional
        if (busca !== "") params.fields = busca;
        if (situacaoID !== "" && situacaoID !== "0") params.status = situacaoID;
        if (servicoID !== "" && servicoID !== "0") params.servicoId = servicoID;
        if (dataInicio !== null)
            params.dataInicio = dataInicio.toISOString().slice(0, 10);
        if (dataFim !== null)
            params.dataFim = dataFim.toISOString().slice(0, 10);
        //Endpoint
        Api.get(`/tarefa/pagination/0/20?clienteId=${idQuery}`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setClientesHist(res.data.content);
                    setTotalPages(res.data.totalPages);
                    setParamsFilter(params);
                    setLoading(false);
                }
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    };

    return (
        <>
            <div className="conteudo-tabela">
                <form>
                    <div>
                        <div>
                            <SearchField
                                placeholder="Pesquise aqui o nome do profissional, número da solicitação ou endereço"
                                onClick={handleSumbit}
                                onChange={(e) => searchText(e.target.value)}
                                search={busca}
                            />
                        </div>

                        <div className={styles.containerFilds}>
                            <div className={styles.subContainerFilds}>
                                <span>Filtrar resultados</span>
                                <div className={styles.containerSelect}>
                                    <div className={styles.fieldSelect}>
                                        <select
                                            className="form-select"
                                            value={servicoID}
                                            onChange={(e) =>
                                                setServicoID(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Serviços
                                            </option>
                                            <option value="0" key="999">
                                                Todos
                                            </option>
                                            {servicos.map(
                                                ({ id, nome }, key) => {
                                                    return (
                                                        <option
                                                            value={id}
                                                            key={key + 1}
                                                        >
                                                            {limitString(
                                                                nome,
                                                                35,
                                                            )}
                                                        </option>
                                                    );
                                                },
                                            )}
                                        </select>
                                    </div>
                                    <div
                                        className={`${styles.fieldSelect} ${styles.fieldSelectMini}`}
                                    >
                                        <select
                                            className="form-select"
                                            value={situacaoID}
                                            onChange={(e) =>
                                                setSituacaoID(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Status
                                            </option>
                                            <option value="0" key="999">
                                                Todos
                                            </option>
                                            {situacao.map(
                                                ({ id, nome }, key) => {
                                                    return (
                                                        <option
                                                            value={id}
                                                            key={key + 1}
                                                        >
                                                            {limitString(nome)}
                                                        </option>
                                                    );
                                                },
                                            )}
                                        </select>
                                    </div>
                                    <div className={styles.fieldSelect2}>
                                        <div className={styles.inputData}>
                                            <MyDatePicker
                                                value={dataInicio}
                                                title="Data início"
                                                onChange={(e) =>
                                                    setDataInicio(e)
                                                }
                                            />
                                        </div>
                                        <div className={styles.inputData}>
                                            <MyDatePicker
                                                value={dataFim}
                                                title="Data fim"
                                                onChange={(e) => setDataFim(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.fieldSelect} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default SearchHistoricoCliente;
