import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DocumentPDF from "./../../pdf/maker/document/index";
import { IconButton } from "@mui/material";
import { Icon } from "react-icons-kit";
import { filePdfO } from "react-icons-kit/fa/filePdfO";
/**
 * Download PDF
 * @param {string} title Title
 * @param {array} data Data
 * @returns
 */
export default function DownloadPDF({ title, data }) {
    return (
        <PDFDownloadLink
            document={<DocumentPDF title={title} data={data} />}
            fileName={`${title}.pdf`}
        >
            {({ blob, url, loading, error }) =>
                loading ? (
                    "Loading..."
                ) : (
                    <IconButton
                        sx={{
                            m: 0.5,
                            p: 0,
                            "&.MuiButtonBase-root": {
                                textTransform: "none",
                                },
                             fontSize:"inherit"
                        }}
                        color="primary"
                        component="span"
                    >
                        <Icon size={21} icon={filePdfO} />
                    </IconButton>
                )
            }
        </PDFDownloadLink>
    );
}
