import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import qs from "qs";
import { Api } from "./../../../../providers";
import { ServiceContext } from "./../../../../contexts/service";
import { ListTable } from "./listTable";
import styles from "./profissional.module.scss";
import ResultNotFound from "../../../resultNotFound";

const HistoricoTableProfissional = ({ idQuery }) => {
    const {
        profissionais, setProfissionais, loading, setLoading, statusFilter,
        setTotalPages, setTotalElements, setStatusFilter, paramsFilter, 
        totalElements, totalPages
    } = useContext(ServiceContext);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [showing, setShowing] = useState(1);
    const itemsPerPage = 10;

    //GET
    useEffect(() => {
        let mounted = true;

        Api.get(`tarefa/profissional/${idQuery}/pagination/0/10`).then((res) => {
            if (mounted) {
                setProfissionais(res.data.content);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setStatusFilter(false);
                setLoading(false);
            }
        }).catch(_ => {
            if (mounted) {
                setLoading(false);
            }
        });
        return () => {
            mounted = false;
            setProfissionais([]);
        };
    }, [idQuery]);

    useEffect(() => {
        setCurrentPage(1);
        setShowing(1);
    }, [paramsFilter]);


    const nextPage = (page = 0) => {
        setLoading(true);
        let urlData = `/tarefa/profissional/${idQuery}/pagination/${page}/10`;

        const params = paramsFilter;
        Api.get(urlData, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        }).then((res) => {
            setProfissionais(res.data.content);
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
            setLoading(false);
        }).catch(_ => {
            setLoading(false);
        });
    }

    const resultDataItem = totalElements;
    const currentItems = profissionais;
    const pageCountData = totalPages;

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        const page = (parseInt(event.selected) * itemsPerPage) + 1;
        setShowing(page);
        nextPage(event.selected);
    };

    const checkPageData = pageCountData > 1 ? currentPage * itemsPerPage : itemsPerPage;
    const dataItem = currentPage === pageCountData ? resultDataItem : checkPageData;
    const totalItems = resultDataItem;

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={styles.margiContainer} />            

            {currentItems.length > 0 && (
                <ListTable currentItems={currentItems} idQuery={idQuery} />
            )}

            {currentItems.length <= 0 && (
                <ResultNotFound />
            )}

            <div className={styles.reactPaginate}>
                {
                    currentItems.length > 0 && (
                        <span>Mostrando {showing} para {dataItem} de {totalItems} entradas</span>
                    )
                }
                <ReactPaginate
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCountData}
                    previousLabel="Anterior"
                    nextLabel="Próximo"
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    marginPagesDisplayed={2}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={currentPage - 1}
                />
            </div>
        </>
    );

    // ************************************************************************************************************
};

export default HistoricoTableProfissional;
