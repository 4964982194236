import React, { useContext, useEffect } from "react";
import { BreadCrumb } from "../../../components/breadcrumb";
import Search from "../../../components/search/unidade";
import UnidadeTable from "../../../components/tables/unidade";
import Title from "../../../components/title";
import { AuthContext } from "../../../contexts/auth";
import { UnidadeProvider } from "../../../contexts/service";
import styles from "./dashboardServico.module.scss";

const DashBoardUnidade = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp =
        operador.operador.funcao.slug === "administrador" ? true : false;

    useEffect(() => {
        setCurrentScreen(11);
    }, []);

    return (
        <UnidadeProvider>
            <div className={`justify-content-center ${styles.containerList}`}>
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Unidades",
                    }}
                />
                <Title title="Unidades" />
                <div className={styles.listagem}>
                    <Search />
                    <div className={styles.newRegister}>
                        {checkOp && <a href="/unidade/criar">Nova Unidade</a>}
                    </div>
                    <UnidadeTable />
                </div>
            </div>
        </UnidadeProvider>
    );
};

export default DashBoardUnidade;
