import React, { useState } from "react";
import {
    Box,
    TextField,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormGroup,
    Checkbox,
    Link,
} from "@mui/material";
import { ic_radio_button_checked_outline } from "react-icons-kit/md/ic_radio_button_checked_outline";
import { ic_radio_button_unchecked_outline } from "react-icons-kit/md/ic_radio_button_unchecked_outline";
import { Icon } from "react-icons-kit";
import { ScrollDialog } from "./../../../components/modal/";
import styles from "./styles.module.scss";
import ImageEyeOn from "../../../assets/images/icons/eye-on.png";
import ImageEyeOff from "../../../assets/images/icons/eye-off.png";

const ItemCustomField = {
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
};

export default function Step3({ formik }) {
    const [openModal, setOpenModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [password, setPassword] = useState("");
    const [eyeOn, setEyeOn] = useState(false);
    const [eyeOnConf, setEyeOnConf] = useState(false);

    const handleClick = (e) => {
        setTitleModal(e.target.childNodes[0].data);
        setOpenModal(true);
    };

    const redirectToPage = (link) => {
        const url = link;
        window.open(url , '_blank');
    }
    
    return (
        <>
            <ScrollDialog
                title={titleModal}
                openModal={openModal}
                setOpenModal={setOpenModal}
            />
            <div className={styles.containerCadastro}>

                <div className={`${styles.containerTextFieldSelect}`}>
                    <span>Definir senha</span>
                    <div className={`${styles.containerPassWord} ${formik.touched.senha && formik.errors.senha ? styles.containerPassWordErro : {}}`}>
                        <TextField
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.senha}
                            fullWidth
                            sx={ItemCustomField}
                            name="senha"
                            type={eyeOn ? "text" : "password"}
                            id="senha"
                            placeholder="Digite a sua Senha"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div className={styles.imageEye} onClick={() => setEyeOn(!eyeOn)}>
                            <img
                                src={eyeOn ? ImageEyeOn : ImageEyeOff}
                                width={24}
                                height={24}
                            />
                        </div>                        
                    </div>
                    <div className={styles.fontFamily}>
                        {Boolean(formik.touched.senha && formik.errors.senha) && (
                            <FormHelperText error>
                                {formik.errors.senha}
                            </FormHelperText>
                        )}
                    </div>
                    <span className={styles.minimoCaract}>Mínimo 8 caracteres</span>
                </div>

                <div className={`${styles.containerTextFieldSelect} ${styles.dividerSpace}`}>
                    <span>Confirmar senha</span>
                    <div className={`${styles.containerPassWord} ${formik.touched.senhaConfirm && formik.errors.senhaConfirm ? styles.containerPassWordErro : {}}`}>
                        <TextField
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.senhaConfirm}
                            fullWidth
                            sx={ItemCustomField}
                            name="senhaConfirm"
                            type={eyeOnConf ? "text" : "password"}
                            id="senhaConfirm"
                            placeholder="Digite novamente a sua senha"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div className={styles.imageEye} onClick={() => setEyeOnConf(!eyeOnConf)}>
                            <img
                                src={eyeOnConf ? ImageEyeOn : ImageEyeOff}
                                width={24}
                                height={24}
                            />
                        </div>
                    </div>
                    <div className={styles.fontFamily}>
                    {Boolean(formik.touched.senhaConfirm && formik.errors.senhaConfirm) && (
                        <FormHelperText error>
                            {formik.errors.senhaConfirm}
                        </FormHelperText>
                        )}
                    </div>
                </div>
                
                <FormGroup sx={{ mb: 4 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                checked={formik.values.terms1}
                                name="terms1"
                                icon={
                                    <Icon
                                        size={25}
                                        icon={ic_radio_button_unchecked_outline}
                                    />
                                }
                                checkedIcon={
                                    <Icon
                                        size={25}
                                        icon={ic_radio_button_checked_outline}
                                    />
                                }
                            />
                        }
                        label={
                            <div className={styles.textTermResp}>
                                Li e estou de acordo com o{" "}
                                <span
                                    onClick={() => redirectToPage("http://www.setre.ba.gov.br/modules/conteudo/conteudo.php?conteudo=295")}
                                >
                                    Termo de Responsabilidade
                                </span>
                            </div>
                        }
                    />
                    <div className={styles.fontFamily}>
                        {Boolean(formik.touched.terms1 && formik.errors.terms1) && (
                            <FormHelperText error>
                                {formik.errors.terms1}
                            </FormHelperText>
                        )}
                    </div>

                    <FormControlLabel
                        control={
                            <Checkbox
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                checked={formik.values.terms2}
                                name="terms2"
                                sx={{
                                    borderRadius: "50px",
                                }}
                                icon={
                                    <Icon
                                        size={25}
                                        icon={ic_radio_button_unchecked_outline}
                                    />
                                }
                                checkedIcon={
                                    <Icon
                                        size={25}
                                        icon={ic_radio_button_checked_outline}
                                    />
                                }
                            />
                        }
                        label={
                            <div className={styles.textTermResp}>
                                Li e estou de acordo com o{" "}
                                <span
                                    onClick={() => redirectToPage("http://www.setre.ba.gov.br/modules/conteudo/conteudo.php?conteudo=295")}
                                >
                                    Termo de Consentimento da Politica de Privacidade
                                </span>
                            </div>
                        }
                    />
                    <div className={styles.fontFamily}>
                        {Boolean(formik.touched.terms2 && formik.errors.terms2) && (
                            <FormHelperText error>
                                {formik.errors.terms2}
                            </FormHelperText>
                        )}
                    </div>
                </FormGroup>
            </div>
        </>
    );
}
