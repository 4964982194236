import qs from "qs";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { ServiceContext } from "../../../contexts/service";
import { Api } from "../../../providers";
import "./../style.css";
import { SearchField } from "./components/searchField";
import styles from "./styles.module.scss";

const Search = () => {
    const { setUnidades, setLoading } = useContext(ServiceContext);
    const [busca, setBusca] = useState("");

    const handleSumbit = (event) => {
        setLoading(true);
        event.preventDefault();

        let params = {};
        if (busca !== "") params.nome = busca;

        Api.get(`/unidade/?fields=&flagAtivo=`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                setUnidades(res.data);
                setLoading(false);
                if (res.data.length <= 0) {
                    toast.success("Nenhum resultado encontrado!", {
                        toastId: "msg",
                    });
                }
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    };

    return (
        <div className={styles.conteudoTabela}>
            <form>
                <div>
                    <div>
                        <SearchField
                            placeholder="Nome da unidade"
                            onClick={handleSumbit}
                            onChange={(e) => searchText(e.target.value)}
                            search={busca}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Search;
