import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth";
import { ProfissionaisProvider } from "../../../contexts/service";
import { newServiceMdalTrue } from "../../../utils/constant";
import { BreadCrumb } from "./../../../components/breadcrumb/index";
import TabsPanel from "./../../../components/tabs/profissional/";
import Title from "./../../../components/title/index";
import { UserContext } from "./../../../contexts/user";
import { Api } from "./../../../providers/index";

const EditarProfissional = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    //Context
    const {
        setPrintLayout,
        docProfessional,
        setDocProfessional,
        setProfessionalData,
    } = useContext(UserContext);
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const serviceDoc = new URLSearchParams(search).get("serviceDoc");
    const statusNewService = serviceDoc === newServiceMdalTrue ? true : false;

    // Add in Context?
    const [tipoDocumento, setTipoDocumento] = useState([]);
    const [dataProfessional, setDataProfessional] = useState([]);
    const [nome, setNome] = useState("");
    const [checkUpdate, setCheckUpdate] = useState(false);
    const [checkLoading, setCheckLoading] = useState(true);

    const loadingData = () => {
        setCheckLoading(false);
        setCheckUpdate(!checkUpdate);
    };

    const tabsData = [
        { id: 1, tabLabel: "Dados pessoais", tabData: dataProfessional },
        { id: 2, tabLabel: "Contato e acesso", tabData: dataProfessional },
        { id: 4, tabLabel: "Endereço", tabData: dataProfessional },
        { id: 7, tabLabel: "Treinamento", tabData: dataProfessional },
        {
            id: 5,
            tabLabel: "Documentos",
            tabData: {
                dataProfessional: dataProfessional,
                docProfessional: docProfessional,
                tipoDocumento: tipoDocumento,
            },
        },
        { id: 9, tabLabel: "Área de atendimento", tabData: dataProfessional },
        { id: 3, tabLabel: "Serviços", tabData: dataProfessional },
        {
            id: 6,
            tabLabel: "Histórico de solicitações",
            tabData: null, //Dinamico
        },
        {
            id: 8,
            tabLabel: "Restrições de clientes",
            tabData: null, //Dinamico
        },
        {
            id: 10,
            tabLabel: "Pontuação",
            tabData: null, //Dinamico
        },
    ];

    useEffect(() => {
        setCurrentScreen(2);
    }, []);

    //Get Profissional
    useEffect(() => {
        let mounted = true;
        Api.get(`/profissional/${idQuery}`)
            .then((res) => {
                if (mounted) {
                    //Todos os dados do profissional
                    setDataProfessional(res.data);
                    setNome(res.data.nome);
                    setProfessionalData(res.data);
                }
                return mounted;
            })
            .then((mounted) => {
                //Documentos do profissional
                Api.get(`/documento/profissional/${idQuery}`).then((res) => {
                    if (mounted) {
                        setDocProfessional(res.data);
                    }
                });
            });
        return () => {
            mounted = false;
            setDataProfessional([]);
        };
    }, [idQuery, checkUpdate]);

    //Get TIpo
    useEffect(() => {
        Api.get("/documento-tipo/").then((res) => {
            if (res.status === 200) {
                setTipoDocumento(res.data);
            }
        });
        setPrintLayout(false);
    }, []);

    //Adicionar tipo nome, pois não existe no endpoint. Se no futuro for implementado poderá remover esse Use Effect
    useEffect(() => {
        if (Array.isArray(docProfessional) && docProfessional.length > 0)
            docProfessional.forEach((item, index) => {
                tipoDocumento.forEach((item2) => {
                    if (item.documentoTipoId === item2.id) {
                        docProfessional[index].documentoTipoNome = item2.nome;
                    }
                });
            });
    }, [docProfessional, tipoDocumento]);
    return (
        <>
            <Grid>
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Profissional",
                        menu: {
                            item: "Usuários",
                            link: "",
                        },
                    }}
                />
                <Title title={nome} />
                <ProfissionaisProvider>
                    <TabsPanel
                        tabsData={tabsData}
                        statusNewService={statusNewService}
                        checkLoading={checkLoading}
                        loadingData={loadingData}
                    />
                </ProfissionaisProvider>
            </Grid>
        </>
    );
};

export default EditarProfissional;
