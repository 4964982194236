import React, {useContext, useState, useEffect} from "react";
import { Container } from "react-bootstrap";
import { BreadCrumb } from "./../../../components/breadcrumb";
import Search from "../../../components/search/servico/";
import Title from "../../../components/title";
import ServicoTable from "./../../../components/tables/servico";
import { ServiceProvider } from "./../../../contexts/service";
import styles from './dashboardServico.module.scss';
import { AuthContext } from "../../../contexts/auth";

const DashBoardServico = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const [selectValue, setSelectValue] = useState(10);

    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp = operador.operador.funcao.slug === "administrador" ? true : false;

    useEffect(() => {
        setCurrentScreen(6);
    }, []);

    return (
        <ServiceProvider>
            <div className={`justify-content-center ${styles.containerList}`}>
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Serviços",
                        menu: {
                            item: "Categorias e Serviços",
                            link: "/categoria",
                        },
                    }}
                />
                <Title title="Serviços" />
                <Container className="listagem">
                    <Search />
                    <div className={styles.novoServico}>
                        {checkOp && (
                            <a href="/servico/criar">Cadastrar novo serviço</a>
                        )}
                    </div>
                    <ServicoTable selectValue={selectValue} />
                </Container>
            </div>
        </ServiceProvider>
    );
};

export default DashBoardServico;
