import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadCrumb } from "../../components/breadcrumb/index";
import { InputCustomData } from "../../components/inputs/inputs";
import Title from "../../components/title/index";
import { Api } from "../../providers/index";
import { NumberFormatBr } from "../../utils/mask";
import styles from "./styles.module.scss";


const ServicoProfissionalAdd = () => {
   const search = useLocation().search;
   const idQuery = new URLSearchParams(search).get("id");
   const [servicoList, setServicoList] = useState([]);
   const [servicoId, setServicoId] = useState(0);
   const [servicoValor, setServicoValor] = useState(0);
   const [taxaVisita, setTaxaVisita] = useState(1);
   const [taxaVisitaValor, setTaxaVisitaValor] = useState(0);
   // req
   const [isReqServicoValor, setIsReqServicoValor] = useState(false);
   // validall
   const [isValidAll, setIsValidAll] = useState(false);
   // List Service
   useEffect(() => {
       let mounted = true;
       Api.get("/servico/filter?flagAtivo=true").then((res) => {
           if (res.status === 200) {
               if (mounted) setServicoList(res.data);
           }
       });
       return () => {
           mounted = false;
       };
   }, []);
   // select
   useEffect(() => {
       if (servicoId > 0) {
           let res = servicoList.find((row) => row.id === servicoId);
           if (res.tipoAgendamento === "Orçamento") {
               setTaxaVisita(1);
               setIsReqServicoValor(false);
           }
           if (res.tipoAgendamento === "Diária") {
               setTaxaVisita(2);
           }
       }
   }, [servicoId, servicoList]);


   useEffect(() => {
       if (isValid(servicoId) && isValid(servicoValor)) {
           setIsValidAll(true);
       } else {
           if (
               isValid(servicoId) &&
               taxaVisita === 1 &&
               isValid(taxaVisitaValor)
           ) {
               setIsValidAll(true);
               //clear other input
               setIsReqServicoValor(false);
           } else {
               setIsValidAll(false);
           }
       }
   }, [servicoId, servicoValor, taxaVisita, taxaVisitaValor]);


   /**
    * Check Validation
    * It's true, so it's ok!
    * @returns {boolean}
    */
   const isValid = (value) => {
       return parseInt(value) > 0 && value !== "";
   };


   // UPDATE
   const handleSubmit = () => {
       if (isValidAll) {
           let data = {};
           data.servicoId = servicoId;
           data.servicoValor = servicoValor;
           data.taxaVisitaValor = taxaVisitaValor;
           data.flagAtivo = true;
           // post envia e-mail
           Api.post(`profissional/${idQuery}/servico/${servicoId}`, data)
               .then((res) => {
                   if (res.status === 200)
                       toast.success(
                           "Serviço do profissional adicionado com Sucesso",
                           {
                               toastId: "success",
                           },
                       );
               })
               .catch((err) => {
                   toast.error("Ops! Aconteceu algum problema!", {
                       toastId: "error",
                   });
               });
       }
   };


   const handleChangeService = (e) => {
       setServicoId(Number(e.target.value));
       isValid(servicoId);
       setTaxaVisitaValor(0);
       setServicoValor(0);
   };


   let checkButtonSubmit = true;


   if (taxaVisita === 1) {
       checkButtonSubmit =
           taxaVisitaValor.length > 0 &&
           Number(taxaVisitaValor) >= 1 &&
           Number(taxaVisitaValor) < 10000 &&
           servicoId > 0
               ? true
               : false;
   } else {
       checkButtonSubmit =
           servicoValor.length > 0 &&
           Number(servicoValor) >= 1 &&
           Number(servicoValor) < 10000 &&
           servicoId > 0
               ? true
               : false;
   }


   return (
       <>
           <Grid className="justify-content-center">
               <BreadCrumb
                   data={{
                       category: "",
                       page: "Profissional",
                       menu: {
                           item: "Usuários",
                           link: "",
                       },
                   }}
               />
               <Title title="Adicionar serviço do profissional" />
               <div className={styles.containerEdit}>
                   <div className={styles.containerGeralInput}>
                       {/* <Grid> */}
                       <div className={styles.containerInfo}>
                           <div>
                               <span>Serviço</span>
                               <select
                                   className={`form-select ${styles.formDropdown}`}
                                   value={servicoId}
                                   onChange={(e) => handleChangeService(e)}
                               >
                                   <option value="0" key="0" disabled>
                                       Selecione uma opção
                                   </option>
                                   {servicoList.map(({ id, nome }) => (
                                       <option key={id} value={id}>
                                           {nome}
                                       </option>
                                   ))}
                               </select>
                           </div>
                       </div>
                       <div className={styles.containerInput}>
                           {taxaVisita === 1 ? (
                               <div>
                                   <span>Valor da taxa de visita</span>
                                   <InputCustomData
                                       value={taxaVisitaValor}
                                       required={true}
                                       onChange={(e) => {
                                           setTaxaVisitaValor(e.target.value);
                                       }}
                                       InputProps={{
                                           inputComponent: NumberFormatBr,
                                       }}
                                   />
                                   {isReqServicoValor && (
                                       <span>
                                           Campo obrigatório! mínimo aceito R$
                                           1,00.
                                       </span>
                                   )}
                               </div>
                           ) : (
                               <div>
                                   <span>Valor da diária</span>
                                   <InputCustomData
                                       value={servicoValor}
                                       required={true}
                                       onChange={(e) => {
                                           setServicoValor(e.target.value);
                                           isValid(e.target.value)
                                               ? setIsReqServicoValor(false)
                                               : setIsReqServicoValor(true);
                                       }}
                                       InputProps={{
                                           inputComponent: NumberFormatBr,
                                       }}
                                   />
                                   {isReqServicoValor && (
                                       <span>
                                           Campo obrigatório! mínimo aceito R$
                                           1,00.
                                       </span>
                                   )}
                               </div>
                           )}
                       </div>
                   </div>
                   <div className={styles.containerInfoButton}>
                       <div className={styles.containerButton}>
                           <button
                               className={
                                   !checkButtonSubmit
                                       ? styles.buttonSearchBlock
                                       : styles.buttonSearch
                               }
                               type="button"
                               onClick={handleSubmit}
                               disabled={!checkButtonSubmit}
                           >
                               <span>Adicionar</span>
                           </button>
                       </div>
                   </div>
               </div>
           </Grid>
       </>
   );
};


export default ServicoProfissionalAdd;





