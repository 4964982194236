import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Api, ApiCep } from "./../../../providers/index";
import { CepMask } from "./../../../utils/mask/index";
import styles from "./styles.module.scss";

const ItemCustomField = {
    width: "400px !important",
    height: "56px",
    borderRadius: "8px !important",
    "& .MuiOutlinedInput-input": {
        padding: "16.5px 0px",
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
    },
    "& .MuiInputBase-input": {
        width: "93%",
    },
};

const BoxData = ({ children }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                background: "#F7F7F7",
                borderRadius: "8px",
            }}
        >
            {children}
        </Box>
    );
};

export default function Section4({ tabData }) {
    const [cep, setCep] = useState(
        tabData.enderecos[0].cep ? tabData.enderecos[0].cep : "",
    );

    const [logradouro, setLogradouro] = useState(
        tabData.enderecos[0].logradouro ? tabData.enderecos[0].logradouro : "",
    );

    const [bairro, setBairro] = useState(
        tabData.enderecos[0].bairro ? tabData.enderecos[0].bairro : "",
    );

    const [cidade, setCidade] = useState(
        tabData.enderecos[0].cidade ? tabData.enderecos[0].cidade : "",
    );

    const [numero, setNumero] = useState(
        tabData.enderecos[0].numero ? tabData.enderecos[0].numero : "",
    );

    const [complemento, setComplemento] = useState(
        tabData.enderecos[0].complemento
            ? tabData.enderecos[0].complemento
            : "",
    );

    const [referencia, setReferencia] = useState(
        tabData.enderecos[0].referencia ? tabData.enderecos[0].referencia : "",
    );

    const getCep = () => {
        let trataCep = cep.replace(/\D/g, "");
        if (trataCep.length >= 8) {
            ApiCep.get(`${trataCep}`).then((res) => {
                if (res.status === 200) {
                    let data = res.data;
                    setBairro(data.bairro);
                    setCidade(data.localidade);
                    setLogradouro(data.logradouro);
                }
            });
        }
    };

    const handleSubmit = () => {
        let index = 0;
        tabData.enderecos[index].cep = cep;
        tabData.enderecos[index].logradouro = logradouro;
        tabData.enderecos[index].bairro = bairro;
        tabData.enderecos[index].cidade = cidade;
        tabData.enderecos[index].numero = numero;
        tabData.enderecos[index].complemento = complemento;
        tabData.enderecos[index].referencia = referencia;

        Api.put(`/profissional/${tabData.id}/`, tabData)
            .then((res) => {
                // console.log(res);
                if (res.status === 200)
                    toast.success("Profissional atualizado com Sucesso", {
                        toastId: "success",
                    });
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "error",
                });
            });
    };

    // init

    return (
        <BoxData>
            <div className={styles.containerInputsBtn}>
                <div className={styles.containerGeralDadosP}>
                    <div className={styles.subContainerGeralDados01}>
                        <div className={styles.containerTextField}>
                            <div>
                                <span>CEP</span>
                                <TextField
                                    value={cep}
                                    fullWidth
                                    sx={ItemCustomField}
                                    // search address to cep
                                    onBlur={(e) => {
                                        getCep();
                                    }}
                                    onChange={(e) => {
                                        setCep(e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputComponent: CepMask,
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className={`${styles.containerTextField} ${styles.dividerSpace}`}
                        >
                            <div>
                                <span>Número</span>
                                <TextField
                                    value={numero}
                                    fullWidth
                                    sx={ItemCustomField}
                                    onChange={(e) => {
                                        setNumero(e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className={`${styles.containerTextField} ${styles.dividerSpace}`}
                        >
                            <div>
                                <span>Bairro</span>
                                <TextField
                                    value={bairro}
                                    fullWidth
                                    sx={ItemCustomField}
                                    onChange={(e) => {
                                        setBairro(e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className={`${styles.containerTextField} ${styles.dividerSpace}`}
                        >
                            <div>
                                <span>Ponto de referência</span>
                                <TextField
                                    value={referencia}
                                    fullWidth
                                    sx={ItemCustomField}
                                    onChange={(e) => {
                                        setReferencia(e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Column Two */}
                    <div>
                        <div className={styles.containerTextField}>
                            <div>
                                <span>Endereço</span>
                                <TextField
                                    value={logradouro}
                                    fullWidth
                                    sx={ItemCustomField}
                                    onChange={(e) => {
                                        setLogradouro(e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className={`${styles.containerTextField} ${styles.dividerSpace}`}
                        >
                            <div>
                                <span>Complemento</span>
                                <TextField
                                    value={complemento}
                                    fullWidth
                                    sx={ItemCustomField}
                                    onChange={(e) => {
                                        setComplemento(e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className={`${styles.containerTextField} ${styles.dividerSpace}`}
                        >
                            <div>
                                <span>Cidade</span>
                                <TextField
                                    value={cidade}
                                    fullWidth
                                    sx={ItemCustomField}
                                    onChange={(e) => {
                                        setCidade(e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.containerInfoButtonEditDadosP}>
                    <div className={styles.containerButtonEditDadosP}>
                        <button
                            className={styles.buttonSearch}
                            type="button"
                            onClick={handleSubmit}
                            disabled={false}
                        >
                            <span>Salvar</span>
                        </button>
                    </div>
                </div>
            </div>
        </BoxData>
    );
}
