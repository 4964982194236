import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import ImageCheck from "../../../assets/images/icons/check2.png";
import ImageEye from "../../../assets/images/icons/eye.png";
import { DateBR } from "../../../utils/date";
import { CpfCnpjMaskRegex, TelMaskRegex } from "../../../utils/mask";
import styles from "./styles.module.scss";

export function ListTable({
    currentItems,
    situacao,
    handleAtualizarPresenca,
    handleAtualizarAprovacao,
    operatorData,
    profissionaisOrig,
    statusFimTrein,
    statusEditTrein,
}) {
    const positionRef = useRef({
        x: 0,
        y: 0,
    });
    const popperRef = useRef(null);
    const areaRef = useRef(null);

    const [mousePosition, setMousePosition] = useState({ x: null, y: null });

    useEffect(() => {
        const updateMousePosition = (ev) => {
            setMousePosition({ x: ev.clientX, y: ev.clientY });
        };
        window.addEventListener("mousemove", updateMousePosition);
        return () => {
            window.removeEventListener("mousemove", updateMousePosition);
        };
    }, []);

    const handleMouseMove = (event) => {
        positionRef.current = { x: event.clientX, y: event.clientY };

        if (popperRef.current != null) {
            popperRef.current.update();
        }
    };

    const resultService = (service, novoTreinamento) => {
        if (service.length > 0) {
            return (
                <div
                    className={
                        !novoTreinamento
                            ? styles.serviceInfo
                            : styles.serviceInfoDisabled
                    }
                >
                    <span>{service[0].nome}</span>
                    {service.length > 1 && <span>+{service.length - 1}</span>}
                </div>
            );
        } else {
            return "";
        }
    };

    const lineTable = (row) => {
        let sit = "";
        let hexadecimal = "";

        situacao.map((row2) => {
            if (row2.id == row?.profissional?.situacaoId) {
                sit = row2?.nome;
                hexadecimal = row2?.hexadecimal;
            }
        });

        let checkModal = row?.flagAprovado;

        if (row?.flagAprovado) {
            checkModal = !row?.flagAprovado;
        }

        //Verifica se tem avaliacao
        const checkPrimAval = profissionaisOrig?.filter(
            (val) =>
                val?.profissionalId == row?.profissionalId &&
                val?.flagPresenca == null,
        );

        const avalDisp_mostraCor =
            statusFimTrein && statusEditTrein
                ? styles.checkbox
                : styles.checkboxDisabled;

        return (
            <tr
                key={row?.profissionalId}
                ref={areaRef}
                onMouseMove={handleMouseMove}
            >
                <td>
                    <div
                        className={styles.boxColor}
                        style={{
                            backgroundColor: !row.novoTreinamento
                                ? hexadecimal
                                : "#8D8F90",
                        }}
                    >
                        <span className={styles.pillBadgesText}>{sit}</span>
                    </div>
                </td>
                <td className={row.novoTreinamento ? styles.tableDisabld : {}}>
                    {row?.profissional?.nome}
                </td>
                <td className={row.novoTreinamento ? styles.tableDisabld : {}}>
                    {CpfCnpjMaskRegex(row?.profissional?.cpf ?? 0)}
                </td>
                <td className={row.novoTreinamento ? styles.tableDisabld : {}}>
                    {TelMaskRegex(row?.profissional?.telefoneCelular ?? 0)}
                </td>
                <td>
                    {resultService(
                        row?.profissional?.servicos,
                        row.novoTreinamento,
                    )}
                </td>
                <td className={row.novoTreinamento ? styles.tableDisabld : {}}>
                    {DateBR(row?.profissional?.dataCadastro)}
                </td>
                <td>
                    <Box
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        {row?.flagPresenca != null && (
                            <div className={styles.typeCheckbox}>
                                <input
                                    type="checkbox"
                                    checked={!row?.flagPresenca}
                                    onChange={() =>
                                        statusFimTrein &&
                                        statusEditTrein &&
                                        !row.novoTreinamento
                                            ? handleAtualizarPresenca(
                                                  row?.profissionalId,
                                                  true,
                                              )
                                            : {}
                                    }
                                    id={
                                        row.novoTreinamento
                                            ? styles.checkboxDisabled
                                            : avalDisp_mostraCor
                                    }
                                />
                                <div
                                    onClick={() =>
                                        statusFimTrein &&
                                        statusEditTrein &&
                                        !row.novoTreinamento
                                            ? handleAtualizarPresenca(
                                                  row?.profissionalId,
                                                  !row?.flagAprovado,
                                                  checkModal,
                                              )
                                            : {}
                                    }
                                >
                                    X
                                </div>
                            </div>
                        )}

                        {row?.flagPresenca == null && (
                            <div className={styles.checkToggle}>
                                <span
                                    onClick={() =>
                                        statusFimTrein && statusEditTrein
                                            ? handleAtualizarPresenca(
                                                  row?.profissionalId,
                                                  false,
                                                  true,
                                              )
                                            : {}
                                    }
                                >
                                    <img src={ImageCheck} />
                                </span>
                                <div
                                    onClick={() =>
                                        statusFimTrein && statusEditTrein
                                            ? handleAtualizarPresenca(
                                                  row?.profissionalId,
                                                  false,
                                                  false,
                                              )
                                            : {}
                                    }
                                >
                                    X
                                </div>
                            </div>
                        )}
                    </Box>
                </td>
                <td>
                    {row?.flagAprovado != null && (
                        <div className={styles.flagApr}>
                            <div className={styles.typeCheckbox}>
                                <input
                                    type="checkbox"
                                    checked={!row?.flagAprovado}
                                    onChange={() =>
                                        statusFimTrein &&
                                        statusEditTrein &&
                                        !row.novoTreinamento
                                            ? handleAtualizarAprovacao(
                                                  row?.profissionalId,
                                                  !row?.flagAprovado,
                                                  checkModal,
                                              )
                                            : {}
                                    }
                                    id={
                                        row.novoTreinamento
                                            ? styles.checkboxDisabled
                                            : avalDisp_mostraCor
                                    }
                                />
                                <div
                                    onClick={() =>
                                        statusFimTrein &&
                                        statusEditTrein &&
                                        !row.novoTreinamento
                                            ? handleAtualizarAprovacao(
                                                  row?.profissionalId,
                                                  !row?.flagAprovado,
                                                  checkModal,
                                              )
                                            : {}
                                    }
                                >
                                    X
                                </div>
                            </div>
                            {row?.flagAprovado == false &&
                                checkPrimAval?.length <= 0 && (
                                    <img
                                        src={ImageEye}
                                        onClick={() =>
                                            operatorData(
                                                row?.profissionalId,
                                                row.novoTreinamento,
                                            )
                                        }
                                    />
                                )}
                        </div>
                    )}

                    {row?.flagAprovado == null && (
                        <div className={styles.checkToggle}>
                            <span
                                onClick={() =>
                                    statusFimTrein && statusEditTrein
                                        ? handleAtualizarAprovacao(
                                              row?.profissionalId,
                                              true,
                                          )
                                        : {}
                                }
                            >
                                <img src={ImageCheck} />
                            </span>
                            <div
                                onClick={() =>
                                    statusFimTrein && statusEditTrein
                                        ? handleAtualizarAprovacao(
                                              row?.profissionalId,
                                              false,
                                              false,
                                          )
                                        : {}
                                }
                            >
                                X
                            </div>
                        </div>
                    )}
                </td>
            </tr>
        );
    };

    <div className={styles.tooltip}>
        <span className={styles.tooltiptext}>
            O candidato não poderá ser editado, pois já está inscrito em um novo
            treinamento
        </span>
    </div>;

    return (
        <div className={styles.containerTable1}>
            <div className="table-responsive">
                <table className={`table align-middle ${styles.table}`}>
                    <thead>
                        <tr>
                            <th>Situação</th>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>Telefone</th>
                            <th>Serviço</th>
                            <th>Data de Inscrição</th>
                            <th>Presença</th>
                            <th>Aprovação</th>
                        </tr>
                    </thead>
                    <tbody data-test="table-body">
                        {currentItems?.length > 0 &&
                            currentItems.map((row) => {
                                if (!row?.novoTreinamento) {
                                    {
                                        return lineTable(row);
                                    }
                                } else {
                                    return (
                                        <Tooltip
                                            title="O candidato não poderá ser editado pois já esta inscrito em um novo treinamento."
                                            placement="top"
                                            arrow
                                            PopperProps={{
                                                popperRef,
                                                anchorEl: {
                                                    getBoundingClientRect:
                                                        () => {
                                                            return new DOMRect(
                                                                positionRef?.current?.x,
                                                                mousePosition.y,
                                                                0,
                                                                0,
                                                            );
                                                        },
                                                },
                                            }}
                                        >
                                            {lineTable(row)}
                                        </Tooltip>
                                    );
                                }
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
