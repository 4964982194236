/**
 *remove write space only
 */
export const removeSpace = (text) => {
    return text.toLowerCase().replace(/\s/g, "");
};
/**
 * Remove wirte space, / and \
 */
export const transformTextinSlug = (text) => {
    return text.toLowerCase().replace(/[\s|\\|//]/g, "");
};
/**
 * Slice String
 * @param {string} text text
 * @param {number} limit limit
 * @returns {string}
 */
export const limitString = (text, limit = 25) => {
    if (text.length > 0) return text.slice(0, limit);
    return text;
};
/**
 * Add element in start text
 * @param {string} text text
 * @param {number} qtd quantity
 * @param {string} el elemnt add
 * @returns
 */
export const addElementStart = (text, qtd = 4, el = "0") => {
    if (text.length > 0) return text.padStart(qtd, el);
    return text;
};
/**
 *  Add element in end text
 * @param {string} text text
 * @param {number} qtd quantity
 * @param {string} el elemnt add
 * @returns {string}
 */
export const addElementEnd = (text, qtd = 4, el = "0") => {
    if (text.length > 0) return text.padEnd(qtd, el);
    return text;
};
