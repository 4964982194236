import { Container } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BreadCrumb } from "../../../components/breadcrumb";
import Title from "../../../components/title";
import { AuthContext } from "../../../contexts/auth";
import { Api } from "../../../providers";
import styles from "./styles.module.scss";

const VisualizarAuditoriaPage = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");

    const [camposInfo, setCamposInfo] = useState([]);
    const [tipoAcao, setTipoAcao] = useState("");
    const [dataAcao, setDataAcao] = useState("");
    const [horaAcao, setHoraAcao] = useState("");
    const [identificador, setIdentificador] = useState("");
    const [nomeUsuario, setNomeUsuario] = useState("");
    const [perfil, setPerfil] = useState("");
    const [telaAcao, setTelaAcao] = useState("");

    useEffect(() => {
        setCurrentScreen(12);
    }, []);

    useEffect(() => {
        Api.get("/auditoria/" + idQuery).then((res) => {
            if (res.status === 200) {
                switch (res.data.acao) {
                    case "CRIACAO":
                        setTipoAcao("Criação");
                        break;
                    case "EDICAO":
                        setTipoAcao("Edição");
                        break;
                    case "EXCLUSAO":
                        setTipoAcao("Exclusão");
                        break;
                    default:
                        setTipoAcao("");
                        break;
                }
                const data = moment(res.data.dataCadastro).format("DD/MM/YYYY");
                const hora = moment(res.data.dataCadastro).format("HH:mm");
                setDataAcao(data);
                setHoraAcao(hora);
                setIdentificador(res.data.informacao.identificador);
                setCamposInfo(res.data.informacao.campos);
                setNomeUsuario(res.data.usuario.nome);
                setPerfil(res.data.usuario.perfis[0].slug);
                setTelaAcao(res.data.tela.nome);
            }
        });
    }, [idQuery]);

    const tipoData = (tipo) => {
        let dataInfo = "";
        switch (tipo) {
            case "Criação":
                dataInfo = "criou";
                break;
            case "Edição":
                dataInfo = "editou";
                break;
            case "Exclusão":
                dataInfo = "excluiu";
                break;
            default:
                dataInfo = "";
                break;
        }

        return (
            <div>
                <div className={styles.labelText}>
                    <span className={styles.text01}>O {perfil} </span>
                    <span className={styles.text02}>{nomeUsuario} </span>
                    <span className={styles.text01}>
                        {dataInfo} {telaAcao?.toLowerCase()}{" "}
                    </span>
                    {identificador.length > 0 && (
                        <span className={styles.text01}>
                            (
                            <span className={styles.identificador}>
                                {identificador}
                            </span>
                            )
                        </span>
                    )}
                    ,{" "}
                    <span className={styles.text02}>
                        dia {dataAcao} às {horaAcao}h
                    </span>
                </div>

                {camposInfo.length > 0 &&
                    camposInfo.map((data, idx) => {
                        return (
                            <div key={idx} className={styles.textCampos}>
                                Campo: {data.nome?.toLowerCase()},{" "}
                                {data.detalhe?.toLowerCase()}
                            </div>
                        );
                    })}
            </div>
        );
    };

    return (
        <>
            <Container maxWidth="xl">
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Detalhamento",
                        menu: {
                            item: "Auditoria",
                            link: "/auditoria",
                        },
                    }}
                />
                <Title title="Detalhamento" />
                <div className={styles.containerGeral}>
                    <div className={styles.subContainerGeral}>
                        <div className={styles.containerInfo}>
                            <span className={styles.labelTextTitle}>
                                Detalhamento da ação - {tipoAcao}
                            </span>

                            {tipoData(tipoAcao)}

                            <div className={styles.divider} />
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default VisualizarAuditoriaPage;
