import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Loading from "./../loading/index";
import styles from "./styles.module.scss";

export function HorStepper({ setStepImg, isValid, stepsData, setStepActive, formik }) {
    const navigate = useNavigate();
    //step
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const stepOptional = null; //index: 0, 1 or 2.

    // sidebar img
    useEffect(() => {
        setStepImg(stepsData[activeStep].img);
    }, [activeStep, setStepImg, stepsData]);

    // Optional
    const isStepOptional = (step) => {
        return step === stepOptional;
    };

    // Skip
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    // Next
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    // Back
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // Restart
    // eslint-disable-next-line no-unused-vars
    const handleReset = (step = 0) => {
        setActiveStep(step);
    };

    useEffect(() => {
        if (setStepActive) handleReset();
    }, [setStepActive]);

    const nextPage = (page) => {
        navigate(page);
    }

    const checkNome = formik.values.nome.trim();
    const checkCPF = formik.values.cpf.trim();
    const checkEmail = formik.values.email.trim();
    const checkEmailConf = formik.values.emailConfirm.trim();
    const checkTelefone = formik.values.telefone.trim();
    const checkSenha = formik.values.senha.trim();
    const checkSenhaConfirm = formik.values.senhaConfirm.trim();
    const checkTerms1 = formik.values.terms1;
    const checkTerms2 = formik.values.terms2;

    const nome = checkNome.length <= 0 || formik.errors.nome?.length > 0 ? true : false;
    const cpf = checkCPF.length <= 0 || formik.errors.cpf?.length > 0 ? true : false;
    const email = checkEmail.length <= 0 || formik.errors.email?.length > 0 ? true : false;
    const emailConfirm = checkEmailConf.length <= 0 || formik.errors.emailConfirm?.length > 0 ? true : false;
    const checkPage0 = activeStep === 0 && nome || cpf || email || emailConfirm ? true : false;
    
    const unidadeId = formik.values.unidadeId <= 0 || formik.errors.unidadeId?.length > 0 ? true : false;
    const funcaoId = formik.values.funcaoId <= 0 || formik.errors.funcaoId?.length > 0 ? true : false;
    const telefone = checkTelefone.length <= 0 || formik.errors.telefone?.length > 0 ? true : false; 
    const checkPage1 = activeStep === 1 && unidadeId || funcaoId || telefone ? true : false;

    const senha = checkSenha.length <= 0 || formik.errors.senha?.length > 0 ? true : false;
    const senhaConfirm = checkSenhaConfirm.length <= 0 || formik.errors.senhaConfirm?.length > 0 ? true : false;
    const checkPage2 = activeStep === 2 && senha || senhaConfirm || !checkTerms1 || !checkTerms2 ? true : false;

    const submitNext = activeStep === 0 ? checkPage0 : checkPage1;
    const submitInfo = activeStep === 2 ? checkPage2 : submitNext;

    return (
        <>
            <Stepper sx={{
                marginTop: "56px",
            }} activeStep={activeStep}>
                {stepsData.map((item, index) => {
                    const stepProps = {};
                    const itemProps = {};
                    // Disabled
                    if (isStepOptional(index)) {
                        itemProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    // console.log(item);
                    return (
                        // Steppers label
                        <Step key={index} {...stepProps}>
                            <StepLabel {...itemProps}>
                                <Typography sx={{ mt: 1, mb: 1 }}>
                                    {item.label}
                                </Typography>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <span className={styles.textRegister}>Cadastro</span>

            {/* step Content */}
            {activeStep !== stepsData.length
                ? stepsData[activeStep].step
                : ""}
            
            {/* Steppers footer buttons*/}
            {activeStep === stepsData.length ? (
                <></>
            ) : (
                <>
                    <Box sx={{ display: "flex", flexDirection: "row", marginTop: "64px" }}>
                        <Button
                            className={activeStep === 0 ? styles.buttonBackDisab : styles.buttonBack}
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                                <span>Voltar</span>
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        {isStepOptional(activeStep) && (
                            <></>
                        )}

                        {activeStep === stepsData.length - 1 ? (
                            <Button
                                className={submitInfo ? styles.buttonNextDisab : styles.buttonNext }
                                disabled={submitInfo}
                                type="submit"
                            >
                                <span>Cadastrar</span> <Loading />
                            </Button>
                        ) : (
                            <Button
                                className={submitInfo ? styles.buttonNextDisab : styles.buttonNext }
                                disabled={submitInfo}
                                onClick={handleNext}
                            >
                                <span>Próximo</span>
                            </Button>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            pt: 2,
                        }}
                    >
                        <span
                            onClick={() => nextPage('/login')}
                            className={styles.textPossuiConta}
                        >
                            Você já possui uma conta?
                        </span>
                    </Box>
                </>
            )}
        </>
    );
}
