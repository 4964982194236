import React from "react";
import { Typography, Box, Tab, Tabs } from "@mui/material";
import Section2 from "../../../pages/ocorrencia/Section/section2";
import Section1 from "./../../../pages/ocorrencia/Section/section1";

/* Content */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

/* id */
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
/* TabLabel */
function TabLabel(props) {
    return <Tab {...props} />;
}
// Size Container
const widthDefault = 917;
const heightDefault = "30px";

const tabCSS = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "140%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    minWidth: "400px",
    width: "100%",
    "&.MuiButtonBase-root": {
        minWidth: "400px",
    },
    "&.MuiTab-root": {
        alignItems: "center",
        textAlign: "center",
        minWidth: "400px",
    },
};

export default function TabsPanel({ tabsData, setLoadingModal, idClient }) {
    //Default Tabs Open
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const renderSwitch = (id) => {
        switch (id) {
            case 1:
                return <Section1 setLoadingModal={setLoadingModal} idClient={idClient} />;
            case 2:
                return <Section2 setLoadingModal={setLoadingModal} idClient={idClient} />;
            default:
                return <Typography> Conteúdo em construção!</Typography>;
        }
    };
    return (
        <Box
            sx={{
                maxWidth: widthDefault,
                minHeight: heightDefault,
            }}
        >
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                }}
            >
                {/* labels */}
                <Tabs
                    // fullWidth
                    centered={true}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs"
                    sx={{
                        backgroundColor: "#FFF",
                    }}
                >
                    {tabsData.map(({ tabLabel }, index) => {
                        // is it Selected?
                        tabCSS.color = value !== index ? "#C7C9D9" : "#0064B4";
                        return (
                            <TabLabel
                                key={index}
                                sx={tabCSS}
                                label={tabLabel}
                                {...a11yProps(index)}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            <Box sx={{ mb: 2, color: "#ffffff" }}></Box>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    background: "#F7F7F7",
                    justifyContent: "center",
                    borderRadius: "8px",
                    marginBottom: 10
                }}
            >
                {/* content */}
                {tabsData.map(({ id }, index) => {
                    return (
                        <TabPanel key={index} value={value} index={index}>
                            {renderSwitch(id)}
                        </TabPanel>
                    );
                })}
            </Box>
        </Box>
    );
}
