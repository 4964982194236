import React, { useContext, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ImageEyeOff from "../../assets/images/icons/eye-off.png";
import ImageEyeOn from "../../assets/images/icons/eye-on.png";
import { ModalLoading } from "../../components/modal";
import { AuthContext } from "../../contexts/auth";
import { UserContext } from "../../contexts/user";
import { CpfCnpjMaskRegex } from "../../utils/mask";
import styles from "./styles.module.scss";

const LoginPage = () => {
    const navigate = useNavigate();
    const { login, loading } = useContext(AuthContext);
    const { setIsLoggedUser, setPrintLayout, setScreenLogin } =
        useContext(UserContext);
    const [cpf, setCpf] = useState("");
    const [password, setPassword] = useState("");
    const [eyeOn, setEyeOn] = useState(false);

    const handleSubmit = () => {
        login(cpf, password);
    };

    //Fix Layout Public
    useEffect(() => {
        setIsLoggedUser(false);
        setPrintLayout(true);
        setScreenLogin(true);
    }, []);

    const nextPage = (page) => {
        navigate(page);
    };

    const checkPassword = password.trim();
    const submitData =
        cpf.length === 14 && checkPassword.length >= 8 ? false : true;

    return (
        <div className={styles.containerGeral}>
            <div className={styles.containerImage}>
                <div />
            </div>
            <div className={styles.containerFormInput}>
                <div className={styles.containerLogin}>
                    <span className={styles.textLogin}>Login</span>
                    <div className={styles.containerForm}>
                        <Form.Group>
                            <span className={styles.labelModal}>CPF</span>
                            <div className={styles.containerInputControl}>
                                <Form.Control
                                    className={styles.containerInputFull}
                                    type="text"
                                    maxLength="14"
                                    placeholder="000000000-00"
                                    name="cpf"
                                    id="cpf"
                                    value={cpf}
                                    onChange={(e) =>
                                        setCpf(CpfCnpjMaskRegex(e.target.value))
                                    }
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <span
                                className={`${styles.labelModal} ${styles.labelMarginTopModal}`}
                            >
                                Senha
                            </span>
                            <div className={styles.containerInputControl}>
                                <Form.Control
                                    className={styles.containerInputFull}
                                    type={eyeOn ? "text" : "password"}
                                    placeholder="Digite a sua senha de 8 caracteres"
                                    name="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <div
                                    className={styles.imageEye}
                                    onClick={() => setEyeOn(!eyeOn)}
                                >
                                    <img
                                        src={eyeOn ? ImageEyeOn : ImageEyeOff}
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                        <div className={styles.containerForgot}>
                            <span onClick={() => nextPage("/redefinir")}>
                                Esqueci a minha senha
                            </span>
                        </div>

                        <div className={styles.containerBtn}>
                            <button
                                className={styles.buttonEnterCreate}
                                onClick={() => nextPage("/cadastrar")}
                                type="button"
                            >
                                <span>Criar conta</span>
                            </button>

                            <button
                                onClick={handleSubmit}
                                className={
                                    submitData
                                        ? styles.buttonEnterDisab
                                        : styles.buttonEnter
                                }
                                disabled={submitData}
                                type="button"
                            >
                                <span>Entrar</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loading}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </div>
    );
};

export default LoginPage;
