import { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [isLoggedUser, setIsLoggedUser] = useState(false);
    //Layout de Impressão
    const [printLayout, setPrintLayout] = useState(false);
    //Document Print
    const [docProfessional, setDocProfessional] = useState([]);
    const [professionalData, setProfessionalData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkBackDocument, setCheckBackDocument] = useState(false);
    const [statusScreenDoc, setStatusScreenDoc] = useState(false);
    const [screenLogin, setScreenLogin] = useState(false);

    // LocalStorage
    const getStorage = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (getStorage) setIsLoggedUser(true);
    }, []);

    return (
        <UserContext.Provider
            value={{
                isLoggedUser,
                setIsLoggedUser,
                printLayout,
                setPrintLayout,
                docProfessional,
                setDocProfessional,
                loading,
                setLoading,
                professionalData,
                setProfessionalData,
                checkBackDocument,
                setCheckBackDocument,
                statusScreenDoc,
                setStatusScreenDoc,
                screenLogin,
                setScreenLogin,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
