import PropTypes from "prop-types";
import React from "react";
import { IMaskInput } from "react-imask";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

/**
 * REGEX
 */
// Phone Mask Regex
export const PhoneMaskRegex = (value) => {
    if (value.length > 0) {
        return value
            .replace(/\D/g, "")
            .replace(/(\d{2})?(\d{4})?(\d{4})/, "$1 $2-$3");
    } else {
        return "";
    }
};
// Tel Mask Regex
export const TelMaskRegex = (value) => {
    if (value.length > 0) {
        return value
            .replace(/\D/g, "")
            .replace(/(\d{2})?(\d{4})?(\d{4})/, "($1) $2-$3");
    } else {
        return "";
    }
};
// cpf Mask Regex
export const CpfMaskRegex = (value) => {
    if (value.length > 0) {
        const size = value.replace(/\D/g, "").length;
        if (size <= 11) {
            return value
                .replace(/\D/g, "")
                .replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, "$1.$2.$3-$4");
        }
        return;
    } else {
        return "";
    }
};
//cpf e cnpj mask
export const CpfCnpjMaskRegex = (value) => {
    if (value.length > 0) {
        const size = value.replace(/\D/g, "").length;
        if (size <= 11) {
            return value
                .replace(/\D/g, "")
                .replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, "$1.$2.$3-$4");
        }
        return value
            .replace(/\D/g, "")
            .replace(
                /(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/,
                "$1.$2.$3/$4-$5",
            );
    } else {
        return "";
    }
};
//Number Only
export const NumberOnly = (val, max = 10) => {
    let value = val.replace(/\D/g, "");

    if (value.length <= max) {
        return value;
    }
};
/**
 * MASK
 */
// CEP Mask
export const CepMask = React.forwardRef(function MyMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="00.000-000"
            inputRef={ref}
            onAccept={(value) =>
                onChange({ target: { name: props?.name, value } })
            }
            overwrite
        />
    );
});
// Phone Mask
export const PhoneMask = React.forwardRef(function MyMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(00) 00000-0000"
            inputRef={ref}
            onAccept={(value) =>
                onChange({ target: { name: props.name, value } })
            }
            overwrite
        />
    );
});
//Fixo
export const PhoneFixoMask = React.forwardRef(function MyMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(00) 0000-0000"
            inputRef={ref}
            onAccept={(value) =>
                onChange({ target: { name: props.name, value } })
            }
            overwrite
        />
    );
});
// Cpf
export const CpfMask = React.forwardRef(function MyMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="000.000.000-00"
            inputRef={ref}
            onAccept={(value) =>
                onChange({ target: { name: props.name, value } })
            }
            overwrite
        />
    );
});
//CNPJ
export const CnpjMask = React.forwardRef(function MyMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="00.000.000/0000-00"
            inputRef={ref}
            onAccept={(value) =>
                onChange({ target: { name: props.name, value } })
            }
            overwrite
        />
    );
});

export const rgmask = (v = "00000000") => {
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
    return v;
};
//Cifrão

function currencyFormatter(value) {
    if (!Number(value)) return "";

    const amount = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(value / 100);

    return `${amount}`;
}

export const NumberFormatBr = React.forwardRef(function MyMask(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            // format={currencyFormatter}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            // value={money(props.value)}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={2}
            fixedDecimalScale
            placeholder="R$ 0,00"
            decimalSeparator=","
            thousandSeparator={"."}
            isNumericString
            permiteLeadingZeros={false}
            prefix="R$ "
        />
    );
});

export const NumberFormatBrNew = React.forwardRef(function MyMask(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            isNumericString={false}
            permiteLeadingZeros={false}
            prefix="R$"
        />
    );
});

NumberFormatBr.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
CpfMask.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
CepMask.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
PhoneMask.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
PhoneFixoMask.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const defaultMaskOptions = {
    prefix: "R$",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 5, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
};

const CurrencyInput = ({ ...inputProps }) => {
    const currencyMask = createNumberMask(defaultMaskOptions);
    return <MaskedInput mask={currencyMask} {...inputProps} />;
};

export default CurrencyInput;

export const ZipCodeMask = (value) => {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");
    return value;
};
