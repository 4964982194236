import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Api } from "../../../providers";
import { ConvertCurrency } from "../../../utils/currency";
import { ModalConfirm, ModalLoading } from "../../modal";
import btedit from "./../../../assets/images/bt-edit.png";
import trashcan from "./../../../assets/images/trashcan.png";
import styles from "./restricao.module.scss";

export function ListTable({ currentItems }) {
    const { id } = currentItems;
    const [servicoProfissional, setServicoProfissional] = useState([]);
    const [serviceAndProfessional, setServiceAndProfessional] = useState({
        idService: 0,
        idProfessional: 0,
    });
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [checkDelete, setCheckDelete] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Loading...
        function resultInfo() {
            try {
                Api.get(`/profissional/${id}/servico/`).then((res) => {
                    setLoading(false);
                    setServicoProfissional(res.data);
                });
            } catch (error) {
                setLoading(false);
            }
        }
        // Clear...
        resultInfo();
        return () => {
            setServicoProfissional([]);
        };
    }, [id, checkDelete]);

    const getFlatAtivo = (flag) => {
        if (flag === true) return "Ativo";
        if (flag === false) return "Inativo";

        return "Em análise";
    };

    const handleConfirmDeleteServiceFromProfessional = (
        idService,
        idProfessional,
    ) => {
        setOpenModalConfirm(true);
        serviceAndProfessional.idProfessional = idProfessional;
        serviceAndProfessional.idService = idService;
    };

    const getEditOrDeleteServiceFromProfessional = (
        idService,
        idProfessional,
        flag,
    ) => {
        if (flag === true || flag === false)
            return (
                <Link
                    to={`/servicoprofissional/?id=${idProfessional}&servico=${idService}`}
                >
                    <img src={btedit} alt="Editar" />
                </Link>
            );
        return (
            <a
                onClick={() =>
                    handleConfirmDeleteServiceFromProfessional(
                        idService,
                        idProfessional,
                    )
                }
            >
                <img src={trashcan} alt="Apagar" />
            </a>
        );
    };

    const handleDeleteServiceFromProfessional = (data) => {
        setOpenModalConfirm(false);
        setLoadingModal(true);
        Api.delete(
            `/profissional/${data.idProfessional}/servico/${data.idService}`,
        )
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Deletado com sucesso", {
                        toastId: "msg",
                    });
                    //servicoProfissional, setServicoProfissional
                    // setCheckDelete(!checkDelete);
                    const newList = servicoProfissional.filter(
                        (value) => value.servicoId !== data.idService,
                    );
                    setServicoProfissional(newList);
                    setLoadingModal(false);
                }
            })
            .catch((_) => {
                setLoadingModal(false);
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
            });
    };

    const returnStyles = (info) => {
        if (info === true) return styles.Ativo;
        if (info === false) return styles.Inativo;

        return;
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    return (
        <div className={`table-responsive ${styles.radiusTable}`}>
            <table className={`table align-middle ${styles.table}`}>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Serviço</th>
                        <th>Valor do serviço</th>
                        <th>Taxa de visita</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody data-test="table-body" className={styles.tbody}>
                    {servicoProfissional.length > 0 &&
                        servicoProfissional.map((val, idx) => {
                            return (
                                <tr key={idx.toString()}>
                                    <td
                                        className={`${
                                            styles.estadoCat
                                        } ${returnStyles(val.flagAtivo)}`}
                                    >
                                        <span>
                                            {getFlatAtivo(val.flagAtivo)}
                                        </span>
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.servico.nome}
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.servicoValor
                                            ? ConvertCurrency(val.servicoValor)
                                            : "Não"}
                                    </td>
                                    <td className={styles.infoText}>
                                        {val.taxaVisitaValor
                                            ? ConvertCurrency(
                                                  val.taxaVisitaValor,
                                              )
                                            : "Não"}
                                    </td>
                                    <td>
                                        {getEditOrDeleteServiceFromProfessional(
                                            val.servicoId,
                                            id,
                                            val.flagAtivo,
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            <ModalConfirm
                labelXSair="nao"
                title="Excluir Serviço"
                description="Tem certeza que deseja excluir este serviço?"
                openModal={openModalConfirm}
                setOpenModal={setOpenModalConfirm}
                onClick={() =>
                    handleDeleteServiceFromProfessional(serviceAndProfessional)
                }
                labelConfirm="Excluir"
            />

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </div>
    );
}
