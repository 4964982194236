import React from "react";
import { Box, Grid } from "@mui/material";
import { Divider } from "@mui/material";

export default function WhiteLine() {
    return (
        <Divider variant="fullWidth" textAlign="center" sx={{mt:5}}>
            <Box
                sx={{
                    p: 0,
                    ml: -5,
                    width: "150%",
                    bgcolor: "#FFF",
                    minWidth: "879px",
                    maxWidth: "1500px",
                    height: 31,
                }}
            ></Box>
        </Divider>
    );
}
