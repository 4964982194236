import React from "react";
import MyMultipleSelectCheckmarks from "../multipleselectcheckmarks";

const Item = {
    // width: 400,
    maxWidth: 400,
    p: 0,
    // pl: 0.2, //fix
    mt: "32px",
    display: "flex",
    justifyContent: "start",
    alignContent: "center",

    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    alignItems: "center",
    color: "#343B40",
    backgroundColor: "#ffffff",
    "& .MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "17px",
    },
    "& .MuiSelect-outlined": {
        // width: 400,
        maxWidth: 400,
        backgroundColor: "#FFF",
    },
    "& .MuiOutlinedInput-root, .MuiInputBase-root, .MuiInputBase-formControl": {
        minWidth: "100%",
        maxWidth: 400,
        m: 0,
        p: 0,
        backgroundColor: "#FFF",
    },
};

export function MyMultiSelectTS(props) {
    return (
        <MyMultipleSelectCheckmarks
            sx={Item}
            id="tipo-servico"
            label={props.label}
            name="tipo-servico"
            {...props}
        >
            {props.children}
        </MyMultipleSelectCheckmarks>
    );
}
