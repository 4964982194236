import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";

import CalendarPickerSkeleton from "@mui/lab/CalendarPickerSkeleton";
import ptBR from "date-fns/esm/locale/pt-BR";
import { MyInput } from "./../inputs/inputs";

export default function MyDatePicker(props) {
    return (
        <>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBR}
            >
                <DatePicker
                    mask="__/__/____"
                    renderLoading={() => <CalendarPickerSkeleton />}
                    loading={false}
                    label={props.title}
                    value={props.value}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    renderInput={(params) => (
                        <TextField {...params} {...props} />
                    )}
                />
            </LocalizationProvider>
        </>
    );
}

//Fix CSS BootStrap
const Item = {
    marginLeft: 1,
    width: "100%",
    minWidth: 130,
    marginTop: "15px",
    //Font
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    // color: "#343B40",
    backgroundColor: "#ffffff",
    "&.MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "17px",
        // color: "#343B40",
    },
    "&.MuiFormHelperText-root  ": {
        fontSize: 10,
        alignSelf: "end",
        alignItems: "end",
        color: "red",
    },
    "&.MuiInputBase-input": {
        position: "relative",
        border: "1px solid #ced4da",
        fontSize: 16,
        width: "auto",
        padding: "10px 4px",
    },
    "&.MuiOutlinedInput-root": {},
};
export function MyDatePickerBootStrap(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
                mask="__/__/____"
                renderLoading={() => <CalendarPickerSkeleton />}
                loading={false}
                label={props.title}
                value={props.value}
                onChange={props.onChange}
                renderInput={(params) => <MyInput {...params} sx={Item} />}
            />
        </LocalizationProvider>
    );
}
