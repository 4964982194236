import React, { useContext, useEffect } from "react";
import Breadcrumb from "./../../../components/breadcrumb";
import Search from "../../../components/search/categoria";
import Title from "../../../components/title";
import { Container } from "react-bootstrap";
import { CategoryProvider } from "./../../../contexts/service";
import CategoriaDashTable from "../../../components/tables/categoria";
import { AuthContext } from "../../../contexts/auth";
import styles from "./styles.module.scss";

const DashboardDeCategoria = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp = operador.operador.funcao.slug === "administrador" ? true : false;

    useEffect(() => {
        setCurrentScreen(5);
    }, []);

    return (
        <CategoryProvider>
            <Container fluid className="justify-content-center">
                <Breadcrumb
                    data={{
                        category: "Categorias e Serviços",
                        page: "Categorias",
                    }}
                />
                <Title title="Categorias" />
                <div className={styles.listagem}>
                    <Search />
                    {checkOp && (
                        <div className={styles.novaCategoria}>
                            <a href="/categoria/criar">Cadastrar nova categoria de serviço</a>
                        </div>
                    )}
                    <CategoriaDashTable />
                </div>
            </Container>
        </CategoryProvider>
    );
};

export default DashboardDeCategoria;
