import { Grid } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import MyAddress from "./../../../components/address/index";
import { ModalConfirm, ModalForm } from "./../../../components/modal/index";
import { ServiceContext } from "./../../../contexts/service";
import { Api } from "./../../../providers/index";
import AddressForm from "./address";

export default function Section4({ tabData }) {
    const { addresses, setAddresses } = React.useContext(ServiceContext);
    const [openModalDelete, setOpenModalDelete] = React.useState(false);
    const [openModalCreate, setOpenModalCreate] = React.useState(false);
    const [openModalUpdate, setOpenModalUpdate] = React.useState(false);
    const [selectAddressId, setSelectAddressId] = React.useState(0);
    //callBack Submit !important
    const MyUseCallback = React.useCallback((close) => {
        if (close) {
            setOpenModalCreate(false);
            setOpenModalUpdate(false);
            //Resolver problema da API que não traz o ID do novo cliente e renderiza
            tabData.enderecos.map((address) => {
                Api.get(`/cliente/${tabData.id}`)
                    .then((res) => {
                        if (res.status === 200) {
                            //Update Object
                            tabData.enderecos = res.data.enderecos;
                            setAddresses(res.data.enderecos);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        }
        // console.log("useCallback", close);
    }, []);

    React.useEffect(() => {
        //Get
        setAddresses(tabData.enderecos);
    }, [tabData]);

    const handleClick = (action, id = null) => {
        switch (action) {
            case "create":
                setOpenModalCreate(true);
                return;

            case "update":
                setSelectAddressId(id);
                setOpenModalUpdate(true);
                return;

            case "delete":
                if (tabData.enderecos.length === 1) {
                    setOpenModalDelete(false);
                    toast.error(
                        "Ops! Não é possível excluir endereço se houver apenas um cadastrado!",
                        {
                            toastId: "error",
                        },
                    );
                    return;
                }
                setSelectAddressId(id);
                setOpenModalDelete(true);
                return;

            default:
                return;
        }
    };

    const handleDelete = () => {
        if (selectAddressId < 1) {
            return;
        }
        //Remove
        //Or indexOf
        let index = tabData.enderecos.findIndex((address) => {
            return address.id === selectAddressId;
        });
        tabData.enderecos.splice(index, 1);
        //Send
        if (tabData) {
            Api.put(`/cliente/${tabData.id}/`, tabData)
                .then((res) => {
                    if (res.status === 200)
                        toast.success(`Endereço removido com sucesso`, {
                            toastId: "success",
                        });
                    setOpenModalDelete(false);
                })
                .catch((err) => {
                    // console.log(err);
                    toast.error("Ops! Aconteceu algum problema!", {
                        toastId: "error",
                    });
                    setOpenModalDelete(false);
                });
        }
    };

    return (
        <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            sx={{ backgroundColor: "#F7F7F7", m: 0, p: 2, minHseight: "546px" }}
        >
            <MyAddress onClick={handleClick} disabled={true} />

            {/* Create */}
            <ModalForm
                id="criar"
                title={tabData.nome}
                description="Adicionar endereço"
                openModal={openModalCreate}
                setOpenModal={setOpenModalCreate}
                onClick={() => setOpenModalCreate(false)}
                disabledButtons={true}
            >
                <AddressForm tabData={tabData} callBack={MyUseCallback} />
            </ModalForm>
            {/* Update */}
            <ModalForm
                id="atualizar"
                title={tabData.nome}
                description="Atualizar endereço"
                openModal={openModalUpdate}
                setOpenModal={setOpenModalUpdate}
                onClick={() => setOpenModalUpdate(false)}
                disabledButtons={true}
            >
                <AddressForm
                    tabData={tabData}
                    selectAddressId={selectAddressId}
                    callBack={MyUseCallback}
                />
            </ModalForm>
            {/* Delete*/}
            <ModalConfirm
                id="excluir"
                title="Excluir endereço"
                description="Tem certeza que deseja excluir o endereço?"
                openModal={openModalDelete}
                setOpenModal={setOpenModalDelete}
                onClick={() => handleDelete()}
                labelConfirm="Excluir"
            />
        </Grid>
    );
}
