import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.scss";

export default function Title({ title }) {
    return (
        <Box>
            <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: 32,
                    fontWeight: 600,
                    color: "#0064B4",
                    marginTop: 4,
                    marginBottom: "40px",
                    wordBreak: "break-all",
                }}
            >
                {title}
            </Typography>
        </Box>
    );
}

export function Title2({ title }) {
    return (
        <div className="cabecalho">
            <h2>{title}</h2>
        </div>
    );
}

export function NewTitle({ title, marginTop = 32, marginBottom = 32 }) {
    return (
        <div className={styles.newTitle} style={{ marginTop, marginBottom }}>
            {title}
        </div>
    );
}
