import moment from "moment";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import MyDatePicker from "../../../components/datepicker";
import { ServiceContext } from "../../../contexts/service";
import { Api } from "../../../providers";
import { SearchField } from "./components/searchField";
import styles from "./styles.module.scss";

const ItemCustomDate = {
    width: "319px !important",
    height: "40px !important",
    borderRadius: "8px !important",
    "& .MuiInputBase-root": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
        height: "40px !important",
        paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
        color: "#343B40",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "& .MuiSvgIcon-root": {
        zIndex: 2,
        color: "#0064B4",
        display: "none",
    },
};

const Search = () => {
    const { setAuditorias, setLoading, setTotalPages, setParamsFilter } =
        useContext(ServiceContext);
    const [busca, setBusca] = useState("");
    const [unidadeID, setUnidadeID] = useState("");
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);

    const [telaAcao, setTelaAcao] = useState([]);
    const [tipoUsuario, setTipoUsuario] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [telaAcaoID, setTelaAcaoID] = useState("");
    const [tipoUsuarioID, setTipoUsuarioID] = useState("");

    useEffect(() => {
        const element = document.getElementById("dataInicio");
        element.setAttribute("placeholder", "Data início");

        const elementDatFim = document.getElementById("dataFim");
        elementDatFim.setAttribute("placeholder", "Data fim");
    }, []);

    useEffect(() => {
        let mounted = true;

        async function filterData() {
            try {
                await Promise.all([
                    Api.get("/auditoria").then((res) => {
                        if (mounted) {
                            const result = res.data.map((data) => {
                                return {
                                    id: data.usuario.perfis[0].id,
                                    nome: data.usuario.perfis[0].nome,
                                };
                            });

                            if (result.length > 0) {
                                const uniqueArray = result.filter(
                                    (obj, index, self) =>
                                        index ==
                                        self.findIndex(
                                            (t) =>
                                                t.id == obj.id &&
                                                t.nome == obj.nome,
                                        ),
                                );
                                setTipoUsuario(uniqueArray);
                            }
                        }
                    }),
                    Api.get("/auditoria/tela").then((res) => {
                        if (mounted) {
                            setTelaAcao(res.data);
                        }
                    }),
                    Api.get("/unidade/?fields=&flagAtivo=").then((res) => {
                        if (mounted) {
                            setUnidades(res.data);
                        }
                    }),
                ]);
            } catch (_) {
                toast.error("Ops! Aconteceu algum problema com os filtros!", {
                    toastId: "msg",
                });
            }
        }

        filterData();
        return () => {
            mounted = false;
        };
    }, []);

    const handleSumbit = (event) => {
        setLoading(true);
        event.preventDefault();

        let params = {};
        if (busca != "") params.fields = busca;
        if (telaAcaoID != "" && telaAcaoID != "0") params.telaId = telaAcaoID;
        if (tipoUsuarioID != "" && tipoUsuarioID != "0")
            params.perfilId = tipoUsuarioID;
        if (unidadeID != "" && unidadeID != "0") params.unidadeId = unidadeID;
        if (dataInicio != null)
            params.dataInicio = moment(dataInicio, "YYYY-MM-DD").format(
                "YYYY-MM-DD",
            );
        if (dataFim != null)
            params.dataFim = moment(dataFim, "YYYY-MM-DD").format("YYYY-MM-DD");

        Api.get(`/auditoria/pagination/0/20?`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Provider
                setAuditorias(res.data.content);
                setTotalPages(res.data.totalPages);
                setParamsFilter(params);
                setLoading(false);
            })
            .catch((_) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    };

    return (
        <div className="conteudo-tabela">
            <form>
                <div>
                    <div>
                        <SearchField
                            placeholder="Busque por nome"
                            onClick={handleSumbit}
                            onChange={(e) => searchText(e.target.value)}
                            search={busca}
                        />
                    </div>

                    <div className={styles.containerFilds}>
                        <div className={styles.subContainerFilds}>
                            <span>Filtrar resultados</span>
                            <div className={styles.containerGeral}>
                                <div className={styles.containerSelectTela}>
                                    <div className={styles.fieldSelect}>
                                        <select
                                            className="form-select"
                                            value={telaAcaoID}
                                            onChange={(e) =>
                                                setTelaAcaoID(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Tela de ação
                                            </option>
                                            <option value="0" key="999">
                                                Todos
                                            </option>
                                            {telaAcao.map(({ id, nome }) => {
                                                return (
                                                    <option value={id} key={id}>
                                                        {nome}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className={styles.containerSelect}>
                                    <div className={styles.fieldSelect}>
                                        <select
                                            className="form-select"
                                            value={tipoUsuarioID}
                                            onChange={(e) =>
                                                setTipoUsuarioID(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Tipo de usuário
                                            </option>
                                            <option value="0" key="999">
                                                Todos
                                            </option>
                                            {tipoUsuario.map(({ id, nome }) => {
                                                return (
                                                    <option value={id} key={id}>
                                                        {nome}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className={styles.fieldSelect}>
                                        <select
                                            className="form-select"
                                            value={unidadeID}
                                            onChange={(e) =>
                                                setUnidadeID(e.target.value)
                                            }
                                        >
                                            <option value="" key="0" disabled>
                                                Unidade
                                            </option>
                                            <option value="0" key="999">
                                                Todos
                                            </option>
                                            {unidades.map(
                                                ({ id, nome }, key) => {
                                                    return (
                                                        <option
                                                            value={id}
                                                            key={key + 1}
                                                        >
                                                            {nome}
                                                        </option>
                                                    );
                                                },
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className={styles.containerInputData}>
                                    <div className={styles.containerDatePicker}>
                                        <MyDatePicker
                                            value={dataInicio}
                                            onChange={(e) => setDataInicio(e)}
                                            fullWidth
                                            sx={ItemCustomDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="dataInicio"
                                        />
                                    </div>
                                    <div className={styles.containerDatePicker}>
                                        <MyDatePicker
                                            value={dataFim}
                                            onChange={(e) => setDataFim(e)}
                                            fullWidth
                                            sx={ItemCustomDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="dataFim"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Search;
