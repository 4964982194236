export const Calendar = (weekDay = true, infoDate = "") => {
    const resultWeek = (currentCheck = "") => {
        const currentDate = new Date(currentCheck);

        let day1 = "";
        let day2 = "";
        let day3 = "";
        let day4 = "";
        let day5 = "";
        let day6 = "";
        let day7 = "";
        let fullDate1 = "";
        let fullDate2 = "";
        let fullDate3 = "";
        let fullDate4 = "";
        let fullDate5 = "";
        let fullDate6 = "";
        let fullDate7 = "";

        let month = 0;
        let resultMonth = "";

        if (currentDate.getUTCDay() === 0) {
            const nextDay1 = new Date(currentCheck);
            day1 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate1 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day1}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day2 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate2 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day2}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day3 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate3 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day3}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day4 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate4 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day4}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day5 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate5 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day5}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day6 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate6 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day6}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day7 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate7 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day7}`;
        }

        // ******************************************************************

        if (currentDate.getUTCDay() === 1) {
            const nextDay1 = new Date(currentCheck);
            if (nextDay1.getUTCDate() === 1) {
                const newNextDay1 = new Date(currentCheck);
                newNextDay1.setUTCDate(
                    newNextDay1.getUTCDate() - currentDate.getUTCDay(),
                );
                const resultDay = newNextDay1.getUTCDate();
                day1 = resultDay <= 9 ? `0${resultDay}` : `${resultDay}`;
                month = newNextDay1.getUTCMonth() + 1;
                resultMonth = month <= 9 ? `0${month}` : `${month}`;
                fullDate1 = `${newNextDay1.getUTCFullYear()}-${resultMonth}-${day1}`;
            } else {
                const resultDay =
                    nextDay1.getUTCDate() - currentDate.getUTCDay();
                day1 = resultDay <= 9 ? `0${resultDay}` : `${resultDay}`;
                month = nextDay1.getUTCMonth() + 1;
                resultMonth = month <= 9 ? `0${month}` : `${month}`;
                fullDate1 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day1}`;
            }

            day2 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate2 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day2}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day3 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate3 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day3}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day4 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate4 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day4}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day5 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate5 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day5}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day6 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate6 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day6}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day7 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate7 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day7}`;
        }

        // ******************************************************************

        if (currentDate.getUTCDay() === 2) {
            const nextDay1 = new Date(currentCheck);
            if (nextDay1.getUTCDate() === 1) {
                const newNextDay1 = new Date(currentCheck);
                newNextDay1.setUTCDate(
                    newNextDay1.getUTCDate() - currentDate.getUTCDay(),
                );
                const resultDay = newNextDay1.getUTCDate();
                day1 = resultDay <= 9 ? `0${resultDay}` : `${resultDay}`;
                month = newNextDay1.getUTCMonth() + 1;
                resultMonth = month <= 9 ? `0${month}` : `${month}`;
                fullDate1 = `${newNextDay1.getUTCFullYear()}-${resultMonth}-${day1}`;
            } else {
                const resultDay =
                    nextDay1.getUTCDate() - currentDate.getUTCDay();
                day1 = resultDay <= 9 ? `0${resultDay}` : `${resultDay}`;
                month = nextDay1.getUTCMonth() + 1;
                resultMonth = month <= 9 ? `0${month}` : `${month}`;
                fullDate1 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day1}`;
            }

            nextDay1.setUTCDate(nextDay1.getUTCDate() - 1);
            day2 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate2 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day2}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day3 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate3 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day3}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day4 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate4 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day4}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day5 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate5 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day5}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day6 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate6 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day6}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day7 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate7 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day7}`;
        }

        // ******************************************************************

        if (
            currentDate.getUTCDay() === 3 ||
            currentDate.getUTCDay() === 4 ||
            currentDate.getUTCDay() === 5 ||
            currentDate.getUTCDay() === 6
        ) {
            const nextDay1 = new Date(currentCheck);
            nextDay1.setUTCDate(
                nextDay1.getUTCDate() - currentDate.getUTCDay(),
            );
            day1 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate1 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day1}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day2 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate2 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day2}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day3 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate3 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day3}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day4 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate4 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day4}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day5 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate5 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day5}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day6 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate6 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day6}`;

            nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
            day7 =
                nextDay1.getUTCDate() <= 9
                    ? `0${nextDay1.getUTCDate()}`
                    : `${nextDay1.getUTCDate()}`;
            month = nextDay1.getUTCMonth() + 1;
            resultMonth = month <= 9 ? `0${month}` : `${month}`;
            fullDate7 = `${nextDay1.getUTCFullYear()}-${resultMonth}-${day7}`;
        }

        const semana = [
            { dayWeek: "Dom.", dateDay: day1, fullDate: fullDate1 },
            { dayWeek: "Seg.", dateDay: day2, fullDate: fullDate2 },
            { dayWeek: "Ter.", dateDay: day3, fullDate: fullDate3 },
            { dayWeek: "Qua.", dateDay: day4, fullDate: fullDate4 },
            { dayWeek: "Qui.", dateDay: day5, fullDate: fullDate5 },
            { dayWeek: "Sex.", dateDay: day6, fullDate: fullDate6 },
            { dayWeek: "Sáb.", dateDay: day7, fullDate: fullDate7 },
        ];

        return semana;
    };

    let weekLoop = [];
    let checkDateTwo = infoDate;

    let newDate = "";
    const callCalendar = async (weekDay = true, currentDate = "") => {
        const date = new Date();
        const resDay =
            date.getUTCDate() <= 9
                ? `0${date.getUTCDate()}`
                : `${date.getUTCDate()}`;
        const checkMonth = date.getUTCMonth() + 1;
        const checkResultMonth =
            checkMonth <= 9 ? `0${checkMonth}` : `${checkMonth}`;
        const dateDispositive = `${date.getUTCFullYear()}-${checkResultMonth}-${resDay}`;

        let checkDate = currentDate ?? dateDispositive;
        const complete = weekDay ? 1 : 6;

        for (let i = 0; i < complete; i++) {
            if (i === 0) {
                const response = resultWeek(checkDate);
                checkDate = response[6]["fullDate"];
                weekLoop.push({
                    week: response,
                });
            }

            if (i > 0) {
                const nextDay1 = new Date(checkDate);
                nextDay1.setUTCDate(nextDay1.getUTCDate() + 1);
                const resultDay =
                    nextDay1.getUTCDate() <= 9
                        ? `0${nextDay1.getUTCDate()}`
                        : `${nextDay1.getUTCDate()}`;
                const month = nextDay1.getUTCMonth() + 1;
                const resultMonth = month <= 9 ? `0${month}` : `${month}`;
                const fullDate = `${nextDay1.getUTCFullYear()}-${resultMonth}-${resultDay}`;
                const response = resultWeek(fullDate);
                checkDate = response[6]["fullDate"];
                weekLoop.push({
                    week: response,
                });
            }
        }
    };

    callCalendar(weekDay, infoDate);

    return weekLoop;
};
