import React from "react";
import logo from "./../../../assets/images/logo.png";
import { Grid, Stack } from "@mui/material";
import styles from "./footer.module.scss";

const Footer = () => {
    return (
        <Grid
            container
            spacing={0}
            sx={{
                p: 4,
                // maxWidth: "1440px",
                height: "100px",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#ffffff",
                boxShadow:
                    "0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12)",
            }}
        >
            <Grid item>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >
                    <img
                        src={logo}
                        alt="Contrate BA"
                        title="Contrate BA"
                        height="36"
                        width="328.15"
                    />
                </Stack>
            </Grid>
            <Grid>
                <ul className="nav-items">
                    <li>
                        <span className={styles.reservedRights}>© 2024, Todos os direitos reservados</span>
                    </li>
                </ul>
            </Grid>
        </Grid>
    );
};

export default Footer;
