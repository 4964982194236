import React from "react";
import { Icon } from "react-icons-kit";
import { ic_search } from "react-icons-kit/md/ic_search";

export const InputSearch = ({
    onClick = () => {
        //do nothing
    },
    onChange = () => {
        //do nothing
    },
    placeholder = "Pesquisa aqui",
}) => {
    return (
        <div className="containerSearch conteudo-tabela d-flex justify-content-center">
            <input
                id="busca"
                type="text"
                placeholder={placeholder}
                name="busca"
                className="input-pesquisar"
                onChange={onChange}
            />
            <span className="containerIconSearch">
                <i className="iconSearch">
                    <Icon size={32} icon={ic_search} onClick={onClick} />
                </i>
            </span>
            <span>
                <button
                    className="button-pesquisar"
                    type="submit"
                    onClick={onClick}
                >
                    Pesquisar
                </button>
            </span>
        </div>
    );
};
