import { Grid, Typography } from "@mui/material";
import React from "react";
import ImageAdicao from "../../assets/images/icons/adicao.png";
import { CardAddress } from "./../../components/cards/";
import { ServiceContext } from "./../../contexts/service";
import styles from "./styles.module.scss";

export default function MyAddress({
    onClick = (e) => {
        // console.log("Click", e.target);
    },
    disabled = false,
}) {
    const { setEnderecoID, addresses, selectCliente } =
        React.useContext(ServiceContext);

    const [isChecked, setIsChecked] = React.useState(0);
    const verifyCheck = () => {
        try {
            let res;
            if (Array.isArray(addresses) && addresses.length > 0) {
                res = addresses.find((address) => {
                    return address.flagPrincipal === true;
                });
            }
            if (res && res.flagPrincipal === true) {
                setIsChecked(res.id);
                setEnderecoID(res.id);
            }
        } catch (error) {
            // console.log(error);
        }
    };

    React.useEffect(() => {
        let mounted = true;
        if (mounted && addresses.length > 0) verifyCheck();
        return () => (mounted = false);
    }, [addresses, selectCliente]);

    return (
        <>
            <Grid item md={12}>
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        color: "#343B40",
                    }}
                >
                    Endereços:
                </Typography>
            </Grid>
            <Grid item md={4}>
                <div className={styles.myCardAddres}>
                    <div
                        className={styles.containerCreate}
                        onClick={() => onClick("create")}
                    >
                        <span>
                            <img src={ImageAdicao} />
                        </span>
                        <span>Adicionar endereço</span>
                    </div>
                </div>
            </Grid>

            {Array.isArray(addresses) &&
                addresses.length > 0 &&
                addresses.map((address, key) => {
                    return (
                        <Grid item md={4} key={key}>
                            {/* <MyCardAddress */}
                            <CardAddress
                                address={address}
                                onClick={onClick}
                                onChange={() => {
                                    setEnderecoID(address.id);
                                    setIsChecked(address.id);
                                }}
                                checked={address.id === isChecked}
                                disabled={disabled}
                            />
                        </Grid>
                    );
                })}
        </>
    );
}
