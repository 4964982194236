import React from "react";
import { View, Text } from "@react-pdf/renderer";
import TableRow from "./table";

// Linha por página: números primos !important
const LINEBYPAGE = 29;
export default function BodyPDF({ styles, title, data }) {
    return (
        <View style={styles.content}>
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
                fixed
            >
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.subtitle} fixed>
                    Resultados (
                    {data.rows.length > LINEBYPAGE
                        ? LINEBYPAGE
                        : data.rows.length}
                    /{data.rows?.length})
                </Text>
            </View>

            <View style={styles.row}>
                <TableRow data={data} lineByPage={LINEBYPAGE} />
            </View>
        </View>
    );
}
