import React from "react";
import TagsPanelProfissional from "./../../../components/tags/profissional";

//Documents
export default function Section5({ tabData, checkLoading, loadingData }) {
    return (
        <TagsPanelProfissional
            tabData={tabData}
            checkLoading={checkLoading}
            loadingData={loadingData}
        />
    );
}
