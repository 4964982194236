import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import { Api } from "../../../providers";
import { DateBR } from "../../../utils/date";
import { CpfCnpjMaskRegex, PhoneMaskRegex } from "../../../utils/mask";
import ResultNotFound from "../../resultNotFound";
import styles from "./styles.module.scss";

export function ListTable() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const [profTrein, setProfTrein] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        Api.get(
            `/treinamento/detalhes/pagination/0/20?treinamentoId=${idQuery}`,
        )
            .then((res) => {
                setProfTrein(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            })
            .catch((error) => {
                console.log("Erro: ", error);
            });
    }, []);

    const nextPage = (page = 0) => {
        setLoading(true);
        Api.get(
            `/treinamento/detalhes/pagination/${page}/20?treinamentoId=${idQuery}`,
        )
            .then((res) => {
                setProfTrein(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            })
            .catch((_) => {
                setLoading(false);
            });
    };

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={`table-responsive ${styles.radiusTable}`}>
                <table className={`table align-middle ${styles.table}`}>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>RG e CPF</th>
                            <th>Telefone</th>
                            <th>Data de inscrição</th>
                        </tr>
                    </thead>
                    <tbody data-test="table-body" className={styles.tbody}>
                        {profTrein.length > 0 &&
                            profTrein.map((val, idx) => {
                                let { rg, cpf } = val.profissional;
                                if (rg === null) {
                                    rg = "";
                                }
                                if (cpf === null) {
                                    cpf = "";
                                } else {
                                    cpf = CpfCnpjMaskRegex(cpf);
                                }

                                return (
                                    <tr key={idx.toString()}>
                                        <td className={styles.infoText}>
                                            {val.profissional?.nome}
                                        </td>
                                        <td className={styles.infoText}>
                                            {rg + " " + cpf}
                                        </td>
                                        <td className={styles.infoText}>
                                            {val.profissional?.telefoneCelular
                                                ? PhoneMaskRegex(
                                                      val.profissional
                                                          ?.telefoneCelular,
                                                  )
                                                : ""}
                                        </td>
                                        <td className={styles.infoText}>
                                            {DateBR(
                                                val.profissional?.dataCadastro,
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>

            {profTrein.length <= 0 && (
                <ResultNotFound style={{ paddingBottom: 30 }} />
            )}

            {profTrein.length > 0 && (
                <div className={styles.reactPaginate}>
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="<"
                        nextLabel=">"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName={styles.pagination}
                        pageClassName={styles.pageItem}
                        pageLinkClassName={"page-link"}
                        previousLinkClassName={styles.pageLink}
                        nextClassName={styles.pageItem}
                        nextLinkClassName={styles.pageLink}
                        activeClassName={"active"}
                        forcePage={currentPage - 1}
                        disabledClassName={styles.disabled}
                    />
                </div>
            )}
        </>
    );
}
