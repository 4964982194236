import React from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { CustomMultipleInput } from "../../utils/calendar";
import { convertToObject, someDays, someWeek } from "../../utils/date";

const weekDays = ["D", "S", "T", "Q", "Q", "S", "S"];
const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
];
const cssStyle = {
    display: "flex",
    width: "400px",
    height: "56px",
    backgroundColor: "#fff",
    color: "#292E32",
    justifyContent: "start",
    alignContent: "center",
    padding: 2,
    margin: 1,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    alignItems: "center",
    border: 0,
    outline: "none",
    boxShadow: "none",
    "& :focus": {
        outline: "none",
    },
};

export const MyCalendar = (props) => {
    const [daysSeclected, setDaysSeclected] = React.useState([]);
    const [daysActive, setDaysActive] = React.useState([]);
    const [daysDisable] = React.useState([]);

    //Two days for week only
    const checkTwoDaysForWeek = (arrayDates) => {
        if (Array.isArray(arrayDates) && arrayDates.length > 0) {
            //Ultimo Dia Adicionado
            let lastDate = new Date(arrayDates[arrayDates.length - 1].toDate());
            let cc = 0;
            arrayDates.forEach((obj) => {
                let nextDate = new Date(obj.toDate());
                if (
                    !someDays(lastDate, nextDate) &&
                    someWeek(lastDate, nextDate)
                )
                    cc++;
            });
            return cc <= 1; //Important
        }
    };

    // SET
    React.useEffect(() => {
        if (Array.isArray(props.dates)) {
            //converte em objetos
            let newArray = props.dates.map((dt) => {
                return convertToObject(dt);
            });
            setDaysActive(newArray);
        }
    }, [props.dates]);

    const handleChange = (array) => {
        props.handleChange(array);
        let size = array.length;
        checkTwoDaysForWeek(array)
            ? setDaysSeclected(array)
            : (array.length = size - 1);
    };
    return (
        <>
            <DatePicker
                style={cssStyle}
                multiple
                editable={false}
                placeholder="Clique aqui para abrir o Calendário"
                weekDays={weekDays}
                months={months}
                format="DD-MM-YYYY"
                minDate={new Date().setDate(1)}
                maxDate={new Date().setDate(90)}
                value={daysSeclected}
                onChange={(array) => {
                    handleChange(array);
                }}
                mapDays={({ date, _selectedDate, currentMonth, _isSameDate }) => {
                    //Active
                    let props = { disabled: true };

                    daysActive.filter((e) => {
                        if (
                            e.day === date.day &&
                            e.month.index === currentMonth.index
                        ) {
                            props.disabled = false;
                            // props.className = "highlight highlight-green";
                            return props;
                        }
                    });
                    daysDisable.filter((e) => {
                        if (
                            e.day === date.day &&
                            e.month.index === currentMonth.index
                        ) {
                            props.disabled = false;
                            props.className = "highlight highlight-red";
                            return props;
                        }
                    });
                    return props;
                }}
                //weekends()
                plugins={[<DatePanel header="Datas" />]}
                render={<CustomMultipleInput {...props} />}
            />
        </>
    );
};
