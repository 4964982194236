import React from "react";
import exportFromJSON from "export-from-json";
import { Button, IconButton } from "@mui/material";
import { Icon } from "react-icons-kit";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";

export default function DownloadExcel({ data }) {
    const downloadExcel = () => {
        const fileName = "download";
        const exportType = exportFromJSON.types.xls;
        exportFromJSON({ data, fileName, exportType });
    };
    return (
        <IconButton
            sx={{
                m: 0.5,
                p: 0,
                "&.MuiButtonBase-root": {
                    textTransform: "none",
                },
                fontSize: "inherit",
            }}
            color="primary"
            component="span"
            onClick={() => downloadExcel()}
        >
            <Icon size={21} icon={fileExcelO} />
        </IconButton>
    );
}
