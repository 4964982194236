/* eslint-disable no-unused-vars */
import { Container, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import imageTrash from "../../../assets/images/icons/trash3.png";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import { ModalLoading } from "../../../components/modal";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { Api, ApiCep } from "../../../providers";
import { CepMask, PhoneMask } from "../../../utils/mask";
import styles from "./styles.module.scss";

const Input = styled("input")({
    display: "none",
});

const ItemCustomField = {
    borderRadius: "8px !important",
    "& .MuiInputBase-root": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
        width: "400px !important",
        height: "56px !important",
    },
    "& .MuiOutlinedInput-input": {
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
        padding: "10px 0",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "& .MuiInputBase-input": {
        width: "93.47%",
    },
};

const ItemCustomFieldLink = {
    borderRadius: "8px !important",
    "& .MuiInputBase-root": {
        borderRadius: "8px !important",
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
        width: "816px !important",
        height: "56px !important",
    },
    "& .MuiOutlinedInput-input": {
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
        padding: "2px 0",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "& .MuiInputBase-input": {
        width: "93.47%",
    },
};

const CriarUnidadePage = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const navigate = useNavigate();
    const [nome, setNome] = useState("");
    const [ativo, setAtivo] = useState(true);
    const [unidadeId, setUnidadeId] = useState("0");
    const [unidades, setUnidades] = useState([]);
    const [imageLocal, setImageLocal] = useState("");
    const [image, setImage] = useState("");
    const [loading, setLoading] = useState(false);

    const [cep, setCep] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [bairro, setBairro] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [telefone, setTelefone] = useState("");
    const [telefoneAdicional, setTelefoneAdicional] = useState("");
    const [horaAtend, setHoraAtend] = useState("");
    const [linkMapa, setLinkMapa] = useState("");
    const [diaAtendimento, setDiaAtendimento] = useState("");

    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp =
        operador.operador.funcao.slug === "administrador" ? true : false;

    const handleRedirect = () => {
        navigate("/unidade");
    };

    useEffect(() => {
        // const element = document.getElementById("myBtn");
        // element.addEventListener("click", displayDate);
    }, []);

    function displayDate() {
        // document.getElementById("demo").innerHTML = Date();
        // let x = document.getElementById("myBtn");
        // x.querySelector(".MuiButtonBase-root").click();
        // const element = document.getElementById("mui-8");
        // element.setAttribute("placeholder", "hora atual");
    }

    useEffect(() => {
        let mounted = true;
        Api.get("/bairro/cidade?unidade=true").then((res) => {
            if (mounted) {
                const result = res.data.map((data, idx) => {
                    const id = idx + 1;
                    return {
                        id,
                        nome: data,
                    };
                });
                setUnidades(result);
            }
        });
        return () => (mounted = false);
    }, []);

    useEffect(() => {
        if (!checkOp) {
            handleRedirect();
        }
        setCurrentScreen(11);
    }, []);

    const handleSubmit = () => {
        const unidade = {
            nome: nome,
            flagAtivo: ativo,
            telefonePrincipal: telefone,
            telefoneSecundario: telefoneAdicional,
            // email: "setre@setre.ba.gov.br",
            cep,
            logradouro,
            bairro,
            numero,
            complemento,
            latitude: "",
            longitude: "",
            diaAtendimento,
            horaAtendimento: horaAtend,
            mapa: linkMapa,
        };
        setLoading(true);

        const formData = new FormData();
        formData.append("file", image);
        formData.append("unidade", JSON.stringify(unidade));

        Api.post("/unidade/", formData)
            .then((_) => {
                toast.success("Cadastrado com sucesso", {
                    toastId: "success",
                });
                setLoading(false);
                handleRedirect();
            })
            .catch((err) => {
                setLoading(false);
                const checkErro = err;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });
    };

    if (!checkOp) {
        return <></>;
    }

    const handleFilesChange = (event) => {
        const checkData = ["image/png", "image/jpg", "image/jpeg"];
        const checkData2 = ["png", "jpg", "jpeg"];
        const checkType = event?.target?.files[0].name.split(".").pop();
        if (
            checkData.includes(event?.target?.files[0]?.type) &&
            checkData2.includes(checkType)
        ) {
            setImageLocal(URL.createObjectURL(event.target.files[0]));
            setImage(event.target.files[0]);
        }
    };

    const handleClearFiles = () => {
        document.getElementById("contained-button-file").value = "";
        setImageLocal("");
        setImage("");
    };

    const getCep = (data) => {
        const trataCep = data.replace(/\D/g, "");
        if (trataCep.length >= 8) {
            setLoading(true);
            ApiCep.get(`${trataCep}`)
                .then((res) => {
                    if (res.status === 200) {
                        const data = res.data;
                        setBairro(data.bairro);
                        setLogradouro(data.logradouro);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    const checkErro = error;
                    let mensagenErro = "";
                    if (checkErro.response.data.status == 500) {
                        mensagenErro = checkErro.response.data.error;
                    } else {
                        mensagenErro = checkErro.response.data.message?.error;
                    }
                    toast.error(`Ops! ${mensagenErro}`, {
                        toastId: "msg",
                    });
                });
        }
    };

    const handleCEP = (data) => {
        setCep(data);
        if (data.length == 10) {
            getCep(data);
        }
    };

    const selectedUnidade = (idUnid) => {
        setUnidadeId(idUnid);
        const result = unidades.filter((data) => data.id == idUnid);
        if (result.length > 0) {
            setNome(result[0].nome);
        }
    };

    const checkUnidade = Number(unidadeId) > 0 ? true : false;
    const checkCEP = cep.length == 10 ? true : false;
    const checkLogradouro =
        logradouro.trim() && logradouro.length > 0 ? true : false;
    const checkNumero = numero.trim() && numero.length > 0 ? true : false;
    const checkBairro = bairro.trim() && bairro.length > 0 ? true : false;
    const checkTelefone =
        telefone.trim() && telefone.length >= 14 ? true : false;
    const checkLinkMapa = linkMapa.trim() && linkMapa.length > 0 ? true : false;
    const checkHoraAtend =
        horaAtend.trim() && horaAtend.length > 0 ? true : false;
    const checkDiaAtendimento =
        diaAtendimento.trim() && diaAtendimento.length > 0 ? true : false;
    const checkComplemento =
        complemento.trim() && complemento.length > 0 ? true : false;
    const checkImage = imageLocal != "" && imageLocal.length > 0 ? true : false;
    const checkButtonSubmit =
        checkCEP &&
        checkLogradouro &&
        checkUnidade &&
        checkNumero &&
        checkBairro &&
        checkComplemento &&
        checkTelefone &&
        checkLinkMapa &&
        checkHoraAtend &&
        checkDiaAtendimento &&
        checkImage
            ? true
            : false;

    return (
        <>
            <Container maxWidth="xl">
                <BreadCrumb
                    data={{
                        page: "Nova Unidade",
                        menu: {
                            item: "Unidades",
                            link: "/unidade",
                        },
                    }}
                />
                <Title title="Nova Unidade" />
                <div className={styles.containerGeral}>
                    <div className={styles.subContainerGeral}>
                        <form className={styles.formularioItem}>
                            <div className={styles.containerInputs}>
                                <div className={`${styles.linhaCard}`}>
                                    <div className={styles.fieldSelect}>
                                        <span>Unidade</span>
                                        <select
                                            className={`form-select`}
                                            value={unidadeId}
                                            onChange={(e) =>
                                                selectedUnidade(e.target.value)
                                            }
                                        >
                                            <option value="0" key="0" disabled>
                                                Selecione uma cidade
                                            </option>
                                            {unidades.map((data, key) => {
                                                return (
                                                    <option
                                                        value={data.id}
                                                        key={key}
                                                    >
                                                        {data.nome}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                    <div className={styles.containerTextField}>
                                        <span>CEP</span>
                                        <TextField
                                            value={cep}
                                            fullWidth
                                            placeholder="00.000-000"
                                            sx={ItemCustomField}
                                            onChange={(e) =>
                                                handleCEP(e.target.value)
                                            }
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputComponent: CepMask,
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`${styles.linhaCard} ${styles.maginTopLabel}`}
                                >
                                    <div className={styles.containerTextField}>
                                        <span>Endereço</span>
                                        <TextField
                                            value={logradouro}
                                            fullWidth
                                            sx={ItemCustomField}
                                            onChange={(e) => {
                                                setLogradouro(e.target.value);
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className={styles.containerTextField}>
                                        <span>Número</span>
                                        <TextField
                                            value={numero}
                                            fullWidth
                                            sx={ItemCustomField}
                                            onChange={(e) => {
                                                setNumero(e.target.value);
                                            }}
                                            name="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`${styles.linhaCard} ${styles.maginTopLabel}`}
                                >
                                    <div className={styles.containerTextField}>
                                        <span>Bairro</span>
                                        <TextField
                                            value={bairro}
                                            fullWidth
                                            sx={ItemCustomField}
                                            onChange={(e) => {
                                                setBairro(e.target.value);
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className={styles.containerTextField}>
                                        <span>Complemento</span>
                                        <TextField
                                            value={complemento}
                                            fullWidth
                                            sx={ItemCustomField}
                                            onChange={(e) => {
                                                setComplemento(e.target.value);
                                            }}
                                            name="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`${styles.linhaCard} ${styles.maginTopLabel}`}
                                >
                                    <div className={styles.containerTextField}>
                                        <span>Telefone</span>
                                        <TextField
                                            placeholder="(00) 00000-0000"
                                            className="contato_acesso"
                                            sx={ItemCustomField}
                                            value={telefone}
                                            fullWidth
                                            onChange={(e) => {
                                                setTelefone(e.target.value);
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputComponent: PhoneMask,
                                            }}
                                        />
                                    </div>
                                    <div className={styles.containerTextField}>
                                        <span>
                                            Telefone adicional (opcional)
                                        </span>
                                        <TextField
                                            placeholder="(00) 00000-0000"
                                            className="contato_acesso"
                                            sx={ItemCustomField}
                                            value={telefoneAdicional}
                                            fullWidth
                                            onChange={(e) => {
                                                setTelefoneAdicional(
                                                    e.target.value,
                                                );
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputComponent: PhoneMask,
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`${styles.linhaCard} ${styles.maginTopLabel}`}
                                >
                                    <div
                                        className={`${styles.containerTextField}`}
                                        // id="myBtn"
                                    >
                                        <span>Horário de atendimento</span>
                                        <TextField
                                            placeholder=""
                                            sx={ItemCustomField}
                                            value={horaAtend}
                                            fullWidth
                                            onChange={(e) => {
                                                setHoraAtend(e.target.value);
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className={styles.containerTextField}>
                                        <span>Dias de atendimento</span>
                                        <TextField
                                            placeholder=""
                                            sx={ItemCustomField}
                                            value={diaAtendimento}
                                            fullWidth
                                            onChange={(e) => {
                                                setDiaAtendimento(
                                                    e.target.value,
                                                );
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${styles.linhaCard} ${styles.maginTopLabel}`}
                                >
                                    <div className={styles.containerTextField}>
                                        <span>Link do mapa</span>
                                        <TextField
                                            placeholder=""
                                            sx={ItemCustomFieldLink}
                                            value={linkMapa}
                                            fullWidth
                                            onChange={(e) => {
                                                setLinkMapa(e.target.value);
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className={styles.containerBtnUpload}>
                            <Input
                                accept="image/png, image/jpg, image/jpeg"
                                id="contained-button-file"
                                type="file"
                                onChange={(e) => {
                                    handleFilesChange(e);
                                }}
                            />
                            {imageLocal ? (
                                <>
                                    <div className={styles.imageSelected}>
                                        <img src={imageLocal} />
                                    </div>
                                    <div
                                        className={styles.imageTrash}
                                        onClick={handleClearFiles}
                                    >
                                        <img src={imageTrash} />
                                    </div>
                                </>
                            ) : (
                                <label htmlFor="contained-button-file">
                                    <span className={styles.textBtnUpload}>
                                        Adicionar imagem
                                    </span>
                                </label>
                            )}
                        </div>
                        <div className={styles.containerBtn}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#0064B4",
                                        lineHeight: "19.36px",
                                        marginBottom: "29px",
                                    }}
                                >
                                    Status
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        width: 200,
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: "#343B40",
                                            lineHeight: "16.94px",
                                        }}
                                    >
                                        Ativo
                                    </Typography>
                                    <Box sx={{ marginLeft: 2 }}>
                                        <input
                                            type="checkbox"
                                            checked={ativo}
                                            onChange={(e) => setAtivo(!ativo)}
                                            id={styles.checkbox}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <div
                                onClick={() =>
                                    checkButtonSubmit ? handleSubmit() : {}
                                }
                            >
                                <button
                                    className={
                                        !checkButtonSubmit
                                            ? styles.buttonSearchBlock
                                            : styles.buttonSave
                                    }
                                    disabled={!checkButtonSubmit}
                                    type="button"
                                >
                                    <span>Salvar</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loading}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};

export default CriarUnidadePage;
