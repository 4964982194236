import { Grid, TextField } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import MyButton from "./../../../../components/button/index";
import MyCheckbox from "./../../../../components/checkbox/index";
import { MyInput } from "./../../../../components/inputs/inputs";
import { MySelect } from "./../../../../components/select/index";
import { Api, ApiCep } from "./../../../../providers/index";
import { CepMask } from "./../../../../utils/mask/index";

const Item = {
    my: 1.5,
    p: 0, //!Important
    height: "56px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "19px",
    display: "flex",
    alignItems: "flex-start",
    color: "#343B40",
    "&.MuiFormLabel-root": {
        // p: 0,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "17px",
    },
    "&.MuiInputLabel-root": {
        // p: 0,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "17px",
    },
    "&.MuiFormHelperText-root  ": {
        fontSize: 10,
        alignSelf: "end",
        alignItems: "end",
        color: "red",
    },
    "&.MuiInputBase-input": {
        // padding: 0,
        width: "auto",
        position: "relative",
        border: "1px solid #ced4da",
        fontSize: 16,
        background: "#fff",
    },
    "&.MuiSelect-select": {
        backgroundColor: "#fff",
    },
    // Outline Input (Dropdown)
    "&.MuiInputLabel-outlined": {
        mt: 0,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "17px",
    },
    // Outline Input (Dropdown Content)
    "&.MuiOutlinedInput-root": {
        mt: 0,
        display: "flex",
        alignItems: "flex-start",
        backgroundColor: "#fff",
    },
};

export default function AddressForm({
    tabData,
    selectAddressId = null,
    callBack = () => {
        //do nothing
    },
}) {
    const [id, setId] = React.useState(null);
    const [titulo, setTitulo] = React.useState("");
    const [cep, setCep] = React.useState("");
    const [logradouro, setLogradouro] = React.useState("");
    const [bairro, setBairro] = React.useState("");
    const [cidade, setCidade] = React.useState("");
    const [numero, setNumero] = React.useState("");
    const [complemento, setComplemento] = React.useState("");
    const [referencia, setReferencia] = React.useState("");
    const [animais, setAnimais] = React.useState(false);
    const [principal, setPrincipal] = React.useState(false);
    //required
    const [isErrorCep, setIsErrorCep] = React.useState(false);
    const [isErrorLogradouro, setIsErrorLogradouro] = React.useState(false);
    const [isErrorBairro, setIsErrorBairro] = React.useState(false);
    const [isErrorCidade, setIsErrorCidade] = React.useState(false);
    const [isErrorNumero, setIsErrorNumero] = React.useState(false);

    React.useEffect(() => {
        //GET
        if (tabData.enderecos && selectAddressId) {
            tabData.enderecos.map((address) => {
                if (address.id === selectAddressId) {
                    setId(address.id);
                    setTitulo(address.titulo);
                    setCep(address.cep);
                    setLogradouro(address.logradouro);
                    setBairro(address.bairro);
                    setCidade(address.cidade);
                    setNumero(address.numero);
                    setComplemento(address.complemento);
                    setReferencia(address.referencia);
                    setAnimais(address.flagAnimais);
                    setPrincipal(address.flagPrincipal);
                }
            });
        }
    }, [tabData]);
    /**
     * Get CEP
     */
    const getCep = () => {
        let trataCep = cep.replace(/\D/g, "");
        if (trataCep.length >= 8) {
            ApiCep.get(`${trataCep}`).then((res) => {
                if (res.status === 200 && res.data) {
                    let data = res.data;
                    if (data) {
                        if (data.logradouro) setLogradouro(data.logradouro);
                        if (data.bairro) setBairro(data.bairro);
                        if (data.localidade) setCidade(data.localidade);
                    }
                }
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!cep || cep.length < 10) {
            setIsErrorCep(true);
            return;
        }
        if (!numero || numero.length < 1) {
            setIsErrorNumero(true);
            return;
        }
        if (!logradouro || logradouro.length < 3) {
            setIsErrorLogradouro(true);
            return;
        }
        if (!bairro || bairro.length < 3) {
            setIsErrorBairro(true);
            return;
        }
        if (!cidade || cidade.length < 3) {
            setIsErrorCidade(true);
            return;
        }

        if (id) {
            //Update
            tabData.enderecos.map((address) => {
                if (address.id === id) {
                    address.titulo = titulo;
                    address.cep = cep;
                    address.logradouro = logradouro;
                    address.bairro = bairro;
                    address.cidade = cidade;
                    address.numero = numero;
                    address.complemento = complemento;
                    address.referencia = referencia;
                    address.flagAnimais = animais;
                    address.flagPrincipal = principal;
                    return address;
                }
                if (principal) {
                    address.flagPrincipal = false;
                }
                return address;
            });
        } else {
            //After Add: If true Update by first
            if (principal) {
                tabData.enderecos.map((address) => {
                    address.flagPrincipal = false;
                    return address;
                });
            }
            //Add
            tabData.enderecos.push({
                id: null,
                titulo: titulo,
                cep: cep,
                logradouro: logradouro,
                bairro: bairro,
                cidade: cidade,
                numero: numero,
                complemento: complemento,
                referencia: referencia,
                flagAnimais: animais,
                flagPrincipal: principal,
            });
        }
        // Update: add and alter
        Api.put(`/cliente/${tabData.id}/`, tabData)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Cliente atualizado com Sucesso", {
                        toastId: "success",
                    });
                    callBack(true);
                }
            })
            .catch((err) => {
                // console.log(err);
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "error",
                });
                callBack(false);
            });
    };

    return (
        <>
            <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={1}>
                    {/* Column One */}
                    <Grid item xs={6}>
                        <TextField
                            id="titulo"
                            name="titulo"
                            label="Nome do endereço (opcional)"
                            value={titulo}
                            fullWidth
                            sx={Item}
                            onChange={(e) => {
                                setTitulo(e.target.value);
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ maxLength: 100 }}
                        />
                        <TextField
                            type="text"
                            id="Cep"
                            name="cep"
                            label="CEP *"
                            value={cep}
                            fullWidth
                            sx={Item}
                            // search address to cep
                            onBlur={(e) => {
                                getCep();
                            }}
                            onChange={(e) => {
                                setIsErrorCep(e.target.value.length < 10);
                                setCep(e.target.value);
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: CepMask,
                            }}
                            error={isErrorCep}
                        />
                        <MyInput
                            id="numero"
                            name="numero"
                            label="Número*"
                            type="text"
                            value={numero}
                            fullWidth
                            sx={Item}
                            onFocus={(e) => {
                                setNumero(e.target.value);
                            }}
                            onChange={(e) => {
                                setIsErrorNumero(e.target.value.length < 1);
                                if (e.target.value.length <= 10)
                                    setNumero(e.target.value);
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={isErrorNumero}
                            inputProps={{ maxLength: 10 }}
                        />
                        <TextField
                            id="bairro"
                            name="bairro"
                            label="Bairro *"
                            value={bairro}
                            fullWidth
                            sx={Item}
                            onChange={(e) => {
                                setIsErrorBairro(e.target.value.length < 3);
                                setBairro(e.target.value);
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={isErrorBairro}
                            inputProps={{ maxLength: 100 }}
                        />

                        <MySelect
                            id="animais"
                            name="animais"
                            label="Este Local possui animais *"
                            value={animais}
                            sx={Item}
                            items={[
                                { id: true, nome: "Sim" },
                                { id: false, nome: "Não" },
                            ]}
                            onChange={(e) => {
                                e.preventDefault();
                                setAnimais(e.target.value);
                            }}
                        />
                        <MyCheckbox
                            sx={{ ml: -13 }}
                            checked={principal === true}
                            onClick={() => setPrincipal(!principal)}
                        >
                            Endereço principal?
                        </MyCheckbox>
                    </Grid>

                    {/* Column Two */}
                    <Grid item xs={6}>
                        <TextField
                            id="endereço"
                            name="endereco"
                            label="Endereço *"
                            value={logradouro}
                            fullWidth
                            sx={Item}
                            onChange={(e) => {
                                setIsErrorLogradouro(e.target.value.length < 3);
                                setLogradouro(e.target.value);
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={isErrorLogradouro}
                            inputProps={{ maxLength: 200 }}
                        />
                        <TextField
                            id="complemento"
                            name="complemento"
                            label="Complemento"
                            value={complemento}
                            fullWidth
                            sx={Item}
                            onChange={(e) => {
                                setComplemento(e.target.value);
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ maxLength: 200 }}
                        />
                        <TextField
                            id="cidade"
                            name="cidade"
                            label="Cidade *"
                            value={cidade}
                            fullWidth
                            sx={Item}
                            onChange={(e) => {
                                setIsErrorCidade(e.target.value.length < 3);
                                setCidade(e.target.value);
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={isErrorCidade}
                            inputProps={{ maxLength: 100 }}
                        />
                        <TextField
                            id="referencia"
                            name="referencia"
                            label="Ponto de Referência (opcional)"
                            value={referencia}
                            fullWidth
                            sx={Item}
                            onChange={(e) => {
                                setReferencia(e.target.value);
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ maxLength: 200 }}
                        />
                        <MyButton
                            id="btnSave"
                            type="submit"
                            sx={{ mt: 10, ml: 5 }}
                            disabled={logradouro.length < 3}
                        >
                            Salvar
                        </MyButton>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}
