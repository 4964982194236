import { Stack } from "@mui/material";
import React from "react";
import btcalendar from "../../../../assets/images/eye.png";
import btedit from "../../../../assets/images/icons/trash-red.png";
import { CpfCnpjMaskRegex, TelMaskRegex } from "../../../../utils/mask";
import styles from "../restricao.module.scss";

export function ListTable({
    setModalRemove,
    currentItems,
    setStatusViewData,
    setViewData,
    setSelectedProf,
}) {
    const removeProf = (idProf) => {
        setModalRemove(true);
        setSelectedProf(idProf);
    };

    const viewData = (data) => {
        const dataInfo = {
            profissional: data.profissional.nome,
            solicitante: data.solicitante,
            motivo: data.motivo.nome,
            observacao: data.observacao,
            usuario: data.usuario.nome,
            dataCadastro: data.dataCadastro,
        };

        setViewData(dataInfo);
        setStatusViewData(true);
    };

    return (
        <div className="table-responsive">
            <table
                className={`table table-borderedP align-middle ${styles.table}`}
            >
                <thead>
                    <tr>
                        <th>Profissional</th>
                        <th>Telefone</th>
                        <th>CPF</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody data-test="table-body" className={styles.tbody}>
                    {currentItems.length > 0 ? (
                        currentItems.map((val, idx) => {
                            return (
                                <tr key={idx.toString()}>
                                    <td>{val.profissional.nome}</td>
                                    <td>
                                        {val.profissional.telefoneCelular
                                            ? TelMaskRegex(
                                                  val.profissional
                                                      .telefoneCelular,
                                              )
                                            : ""}
                                    </td>
                                    <td>
                                        {`${CpfCnpjMaskRegex(
                                            val.profissional.cpf,
                                        )}`}
                                    </td>
                                    <td>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <div onClick={() => viewData(val)}>
                                                <img
                                                    src={btcalendar}
                                                    alt="Visualizar"
                                                    className={styles.imgEYE}
                                                    title="Visualizar"
                                                />
                                            </div>
                                            <div
                                                onClick={() =>
                                                    removeProf(
                                                        val.profissional.id,
                                                    )
                                                }
                                            >
                                                <img
                                                    src={btedit}
                                                    alt="Remover"
                                                    title="Remover"
                                                />
                                            </div>
                                        </Stack>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
        </div>
    );
}
