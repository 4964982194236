import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../providers";
import { ServiceContext } from "./../../../contexts/service";
import { ListTable } from "./listTable";
import styles from "./styles.module.scss";
import pdf from "./../../../assets/images/pdf.png";
import excel from "./../../../assets/images/excel.png";
import { BaseURL } from "../../../api";
import ResultNotFound from "../../resultNotFound";

const ClientesTable = () => {
    const {
        clientes, setClientes, loading, setLoading, totalPages,
        setTotalPages, paramsFilter,
    } = useContext(ServiceContext);
    const navigate = useNavigate();
    
    const [currentPage, setCurrentPage] = useState(1);
    
    let mounted = true;
    const token = localStorage.getItem("token");

    useEffect(() => {
        Api.get(`/cliente/pagination/0/20`).then((res) => {
            if (mounted) {
                setClientes(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            }
        }).catch(_ => {
            if (mounted) {
                setLoading(false);
            }
        });
        return () => {
            mounted = false;
            setClientes([]);
        };
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [paramsFilter]);

    const nextPage = (page = 0) => {
        setLoading(true);
        const urlData = `/cliente/pagination/${page}/20`;
        const params = paramsFilter;
        Api.get(urlData, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        }).then((res) => {
            setClientes(res.data.content);
            setTotalPages(res.data.totalPages);
            setLoading(false);
        }).catch(_ => {
            setLoading(false);
        });
    }
    const currentItems = clientes;
    const pageCountData = totalPages;

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    const handleReport = (type) => {
        const situacao = paramsFilter?.situacao === "EXCLUIDO" ? "Excluído" : paramsFilter?.situacao;
        const reportTab = `${BaseURL}api/relatorio/cliente?export=${type}&situacao=${situacao ?? ""}&fields=${paramsFilter?.fields ?? ""}&token=${token}`;
        window.open(reportTab);
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }
    
    return (
        <>
            <div className={styles.containerRel}>
                <div>
                    <span onClick={() => handleReport("excel")}>
                        <img src={excel} alt="excel" title="excel" />
                    </span>
                    <span onClick={() => handleReport("pdf")}>
                        <img src={pdf} alt="pdf" title="pdf" />
                    </span>
                </div>
            </div>

            {currentItems.length > 0 && (
                <ListTable currentItems={currentItems} />
            )}            

            {currentItems.length <= 0 && (
                <ResultNotFound style={{marginBottom: 10}} />
            )}
            
            <div className={styles.reactPaginate}>
                <ReactPaginate
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCountData}
                    previousLabel="<"
                    nextLabel=">"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName={styles.pagination}
                    pageClassName={styles.pageItem}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={styles.pageLink}
                    nextClassName={styles.pageItem}
                    nextLinkClassName={styles.pageLink}
                    activeClassName={"active"}
                    forcePage={currentPage - 1}
                    disabledClassName={styles.disabled}
                />
            </div>
        </>
    );
};

export default ClientesTable;
