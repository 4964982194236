import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import qs from "qs";
import { Api } from "../../../providers";
import { ServiceContext } from "../../../contexts/service";
import { SearchField } from "./components/searchField";
import styles from "./styles.module.scss";

const Search = () => {
    const {
        setClientes, setLoading, setTotalPages, setTotalElements,
        setParamsFilter
    } = useContext(ServiceContext);
    
    const [busca, setBusca] = useState("");
    const [situacao, setSituacao] = useState([]);
    const [situacaoID, setSituacaoID] = useState("");

    useEffect(() => {
        let mounted = true;

        async function filterData() {
            try {
                await Promise.all([
                    Api.get("/cliente/situacao").then((res) => {
                        if (res.status) {
                            setSituacao(res.data);
                        }
                    })
                ]);
            } catch (_) {
                toast.error("Ops! Aconteceu algum problema com os filtros!", {
                    toastId: "msg",
                });
            }
        }

        filterData();
        return () => {
            mounted = false;
        };
    }, []);

    const handleSumbit = (event) => {
        setLoading(true);
        event.preventDefault();

        let params = {};
        if (busca !== "") params.fields = busca;
        if (situacaoID !== "0") params.situacao = situacaoID;

        Api.get(`/cliente/pagination/0/20?`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Provider
                setClientes(res.data.content);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setParamsFilter(params);
                setLoading(false);
            })
            .catch(_ => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    }

    return (
        <div className="conteudo-tabela">
            <form>
                <div>
                    <div>
                        <SearchField
                            placeholder="Pesquise pelo nome, CPF/CNPJ, telefone..."
                            onClick={handleSumbit}
                            onChange={(e) => searchText(e.target.value)}
                            search={busca}
                        />
                    </div>

                    <div className={styles.containerFilds}>
                        <div className={styles.subContainerFilds}>
                            <span>Filtrar resultados</span>
                            <div className={styles.fieldSelect}>
                                <select className="form-select"
                                    value={situacaoID}
                                    onChange={(e) =>
                                        setSituacaoID(e.target.value)
                                    }
                                >
                                    <option value="" key="0" disabled>
                                        Status
                                    </option>
                                    <option value="0" key="999">
                                        Todos
                                    </option>
                                    {situacao.map(({ id, nome }, key) => {
                                        return (
                                            <option value={id} key={key + 1}>
                                                {nome}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Search;
