import * as React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material/";
const Item = {
    m: 0,
    p: 0,
    width: 400,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: "#343B40",

    "&.MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "17px",
        paddingLeft: 0,
    },

    "&.MuiFormHelperText-root  ": {
        fontSize: 10,
        alignSelf: "end",
        alignItems: "end",
        color: "red",
    },
    "&.MuiInputBase-input": {
        width: "auto",
        position: "relative",
        border: "1px solid #ced4da",
        fontSize: 16,
        background: "#fff",
    },
    "&.MuiOutlinedInput-root": {
        m: 0,
        p: 0,
        background: "#fff",
    },
};

export const MySelect = (props) => {
    return (
        <div style={{marginTop:"8px"}}>
            <FormControl fullWidth>
                {props.label ? (
                    <InputLabel id="myselect" sx={Item} {...props}>
                        {props.label}
                    </InputLabel>
                ) : (
                    ""
                )}
                <Select labelId="myselect" sx={Item} {...props}>
                    <MenuItem value="" key={0} disabled>
                        Escolha uma opção
                    </MenuItem>
                    {props.items.map(({ id, nome }, key) => {
                        return (
                            <MenuItem
                                sx={{
                                    "&.MuiMenuItem-root": {
                                        backgroundColor: "#fff",
                                    },
                                    "&.MuiMenuItem-root:hover, &.Mui-selected:hover":
                                        {
                                            backgroundColor: "#ECEDEE",
                                        },
                                    "&.Mui-selected": {
                                        backgroundColor: "#fff",
                                    },
                                    "&.Mui-selected:focus": {
                                        backgroundColor: "#D8D9DA",
                                    },
                                }}
                                value={id}
                                key={key + 1}
                            >
                                {nome}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};
