import React from "react";
import arrow from "../../../../assets/images/icons/right-arrow.png";
import styles from "./areaAtendimentoProfissional.module.scss";

export function ListTable({
    dataUnit = [],
    personalData = [],
    setServiceArea,
    setNeighborhoods,
    setSelectedUnitName,
    fetchDataDistrict,
    setStatusUpdate,
    setNeighborhoodsUpdate,
    setSelectedUnit,
    setCheckNewSave,
}) {
    const updateData = (data) => {
        const savedNeighborhoods = personalData["bairros"].filter(
            (value) => value.cidade == data.nome,
        );

        setServiceArea(true);
        setNeighborhoods(savedNeighborhoods);
        setSelectedUnitName(data.nome);
        fetchDataDistrict(data.nome);
        setNeighborhoodsUpdate(savedNeighborhoods);
        setSelectedUnit(data);
        setStatusUpdate(true);
        setCheckNewSave(false);
    };

    return (
        <div className={`table-responsive ${styles.containerDataTable}`}>
            <span>Unidades</span>

            {dataUnit.length > 0 &&
                dataUnit.map((data) => (
                    <div
                        key={data.id.toString()}
                        className={styles.lisTable}
                        onClick={() => updateData(data)}
                    >
                        <div>{data.nome}</div>
                        <div onClick={() => {}}>
                            <img
                                src={arrow}
                                alt="Área de atendimento"
                                title="Área de atendimento"
                            />
                        </div>
                    </div>
                ))}
        </div>
    );
}
