import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import qs from "qs";
import { Api } from "../../../providers";
import { ServiceContext } from "./../../../contexts/service";
import { ListTable } from "./listTable";
import styles from "./servico.module.scss";
import ResultNotFound from "../../resultNotFound";

const ServicoTable = ({selectValue}) => {
    const [itemOffset, setItemOffset] = useState(0);
    
    const {
        servicos,
        setServicos,
        loading,
        setLoading,
        totalPages,
        setTotalPages,
        paramsFilter
    } = useContext(ServiceContext);

    const [currentPage, setCurrentPage] = useState(1);
    const [checkPage, setCheckPage] = useState(false);
    const [showing, setShowing] = useState(1);

    const itemsPerPage = selectValue;
    let mounted = true;

    function compare( a, b ) {
        if ( a.nome < b.nome ){
            return -1;
        }
        if ( a.nome > b.nome ){
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        Api.get(`/servico/pagination/0/20`).then((res) => {
            if (mounted) {
                //const result = res.data.sort( compare );
                setServicos(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            }
        }).catch(_ => {
            if (mounted) {
                setLoading(false);
                setServicos([]);
            }
        });
        return () => {
            mounted = false;            
        };
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [paramsFilter]);

    const nextPage = (page = 0) => {
        setLoading(true);
        const urlData = `/servico/pagination/${page}/20`;
        const params = paramsFilter;
        Api.get(urlData, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        }).then((res) => {
            setServicos(res.data.content);
            setTotalPages(res.data.totalPages);
            setLoading(false);
        }).catch(_ => {
            setLoading(false);
        });
    }
    const currentItems = servicos;
    const pageCountData = totalPages;

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    return (
        <>
            
            {currentItems.length > 0 && (
                <ListTable currentItems={currentItems} />
            )}

            {currentItems.length <= 0 && (
                <ResultNotFound style={{marginBottom: 10}} />
            )}

            <div className={styles.reactPaginate}>
                <ReactPaginate
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCountData}
                    previousLabel="<"
                    nextLabel=">"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName={styles.pagination}
                    pageClassName={styles.pageItem}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={styles.pageLink}
                    nextClassName={styles.pageItem}
                    nextLinkClassName={styles.pageLink}
                    activeClassName={"active"}
                    forcePage={currentPage - 1}
                    disabledClassName={styles.disabled}
                />
            </div>
        </>
  );
};

export default ServicoTable;
