import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ptBR from "date-fns/esm/locale/pt-BR";
import * as React from "react";

export default function HourPick(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <TimePicker
                {...props}
                renderInput={(params) => <TextField {...params} {...props} />}
            />
        </LocalizationProvider>
    );
}
