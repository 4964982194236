import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Collapse, Nav, ToggleButton } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { settings } from "react-icons-kit/feather/settings";
import { ic_bookmark_border_outline } from "react-icons-kit/md/ic_bookmark_border_outline";
import { ic_person_outline } from "react-icons-kit/md/ic_person_outline";
import { useNavigate } from "react-router-dom";

import setaIco from "../../assets/images/seta-bottom.png";
import setaOpenIco from "../../assets/images/seta-o-top.png";
import styles from "./styles.module.scss";

export const DropDownMenuUser = ({ currentScreen = 0 }) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);

    // *************************************************************************
    const operador = JSON.parse(localStorage.getItem("user"));
    const permissoes = JSON.parse(localStorage.getItem("permissoes"));
    const checkOp =
        operador?.operador?.funcao?.slug == "administrador" ? true : false;
    const permCliente = permissoes?.find((val) => val.tela.slug == "clientes");
    const permProfissionais = permissoes?.find(
        (val) => val.tela.slug == "profissionais",
    );
    // *************************************************************************

    const serviceData = [1, 2, 3];
    useEffect(() => {
        if (serviceData.includes(currentScreen)) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [currentScreen]);

    const handleClient = () => {
        navigate("/cliente");
    };

    const handleProf = () => {
        navigate("/profissional");
    };

    const handleOper = () => {
        navigate("/operador");
    };

    return (
        <>
            <Nav.Link eventKey="1" className={`${styles.menuItem}`}>
                <ToggleButton
                    className={`${
                        !open ? styles.menuButton : styles.menuButtonActive
                    }`}
                    id="togglemenuUserCollapse"
                    type="checkbox"
                    variant="link"
                    checked={checked}
                    onChange={(e) => setChecked(e.currentTarget.checked)}
                    onClick={() => setOpen(!open)}
                    aria-controls="menuUserCollapse"
                    aria-expanded={open}
                >
                    <div className={styles.menuIcon}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={11}
                            mt={1.5}
                        >
                            <div>
                                <span
                                    style={{
                                        color: !open ? "#0064B4" : "#FFF",
                                    }}
                                >
                                    <Icon size={24} icon={ic_person_outline} />
                                </span>
                                <span
                                    style={{
                                        color: open ? "#FFFFFF" : "#0064B4",
                                        fontSize: 16,
                                        textTransform: "none",
                                        marginLeft: 10,
                                        fontWeight: 700,
                                    }}
                                >
                                    Usuários
                                </span>
                            </div>
                            <div>
                                <img
                                    src={!open ? setaIco : setaOpenIco}
                                    alt="menu"
                                    height="7px"
                                    width="14px"
                                />
                            </div>
                        </Stack>
                    </div>
                </ToggleButton>
            </Nav.Link>
            <Collapse in={open}>
                <div id="menuUserCollapse" className={styles.subMenuItem}>
                    {(checkOp || permCliente?.visualizar) && (
                        <Nav.Link
                            className={
                                currentScreen == 1
                                    ? styles.subItemOnUser
                                    : styles.subItemOffUser
                            }
                            onClick={() => handleClient()}
                        >
                            <span
                                style={{
                                    color: !open ? "#0064B4" : "#FFF",
                                    visibility: "hidden",
                                }}
                            >
                                <Icon size={20} icon={ic_person_outline} />
                            </span>
                            <span style={{ marginLeft: 10 }}>Clientes</span>
                        </Nav.Link>
                    )}

                    {(checkOp || permProfissionais?.visualizar) && (
                        <Nav.Link
                            className={
                                currentScreen == 2
                                    ? styles.subItemOnUser
                                    : styles.subItemOffUser
                            }
                            onClick={() => handleProf()}
                        >
                            <span
                                style={{
                                    color: !open ? "#0064B4" : "#FFF",
                                    visibility: "hidden",
                                }}
                            >
                                <Icon size={20} icon={ic_person_outline} />
                            </span>
                            <span style={{ marginLeft: 10 }}>
                                Profissionais
                            </span>
                        </Nav.Link>
                    )}

                    <Nav.Link
                        className={
                            currentScreen == 3
                                ? styles.subItemOnUser
                                : styles.subItemOffUser
                        }
                        onClick={() => handleOper()}
                    >
                        <span
                            style={{
                                color: !open ? "#0064B4" : "#FFF",
                                visibility: "hidden",
                            }}
                        >
                            <Icon size={20} icon={ic_person_outline} />
                        </span>
                        <span style={{ marginLeft: 10 }}>Operadores</span>
                    </Nav.Link>
                </div>
            </Collapse>
        </>
    );
};

export const DropDownMenuCategory = ({ currentScreen = 0 }) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);

    const serviceData = [5, 6, 7];
    useEffect(() => {
        if (serviceData.includes(currentScreen)) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [currentScreen]);

    const handleCategory = () => {
        navigate("/categoria");
    };

    const handleService = () => {
        navigate("/servico");
    };

    const handleServiceType = () => {
        navigate("/tipo-servico");
    };

    return (
        <>
            <Nav.Link eventKey="5" className={`${styles.menuItem}`}>
                <ToggleButton
                    className={`${
                        !open ? styles.menuButton : styles.menuButtonActive
                    }`}
                    id="togglemenuCategoryCollapse"
                    type="checkbox"
                    variant="link"
                    checked={checked}
                    onChange={(e) => setChecked(e.currentTarget.checked)}
                    onClick={() => setOpen(!open)}
                    aria-controls="menuCategoryCollapse"
                    aria-expanded={open}
                >
                    <div className={styles.menuIcon}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={11}
                            mt={1.5}
                        >
                            <div>
                                <span
                                    style={{
                                        color: !open ? "#0064B4" : "#FFF",
                                    }}
                                >
                                    <Icon
                                        size={24}
                                        icon={ic_bookmark_border_outline}
                                    />
                                </span>

                                <span
                                    style={{
                                        color: open ? "#FFFFFF" : "#0064B4",
                                        fontSize: 16,
                                        textTransform: "none",
                                        marginLeft: 10,
                                        fontWeight: 700,
                                    }}
                                >
                                    Serviços
                                </span>
                            </div>
                            <div>
                                <img
                                    src={!open ? setaIco : setaOpenIco}
                                    alt="menu"
                                    height="7px"
                                    width="14px"
                                />
                            </div>
                        </Stack>
                    </div>
                </ToggleButton>
            </Nav.Link>
            <Collapse in={open}>
                <div id="menuCategoryCollapse" className={styles.subMenuItem}>
                    <Nav.Link
                        className={
                            currentScreen == 5
                                ? styles.subItemOn
                                : styles.subItemOff
                        }
                        onClick={() => handleCategory()}
                    >
                        <span
                            style={{
                                color: !open ? "#0064B4" : "#FFF",
                                visibility: "hidden",
                            }}
                        >
                            <Icon size={20} icon={ic_person_outline} />
                        </span>
                        <span style={{ marginLeft: 10 }}>Categorias</span>
                    </Nav.Link>
                    <Nav.Link
                        className={
                            currentScreen == 6
                                ? styles.subItemOn
                                : styles.subItemOff
                        }
                        onClick={() => handleService()}
                    >
                        <span
                            style={{
                                color: !open ? "#0064B4" : "#FFF",
                                visibility: "hidden",
                            }}
                        >
                            <Icon size={20} icon={ic_person_outline} />
                        </span>
                        <span style={{ marginLeft: 10 }}>Serviços</span>
                    </Nav.Link>
                    <Nav.Link
                        className={
                            currentScreen == 7
                                ? styles.subItemOn
                                : styles.subItemOff
                        }
                        onClick={() => handleServiceType()}
                    >
                        <span
                            style={{
                                color: !open ? "#0064B4" : "#FFF",
                                visibility: "hidden",
                            }}
                        >
                            <Icon size={20} icon={ic_person_outline} />
                        </span>
                        <span style={{ marginLeft: 10 }}>
                            Tipos de Serviços
                        </span>
                    </Nav.Link>
                </div>
            </Collapse>
        </>
    );
};

export const DropDownMenuReports = ({ currentScreen = 0 }) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);

    const serviceData = [10, 14, 15];
    useEffect(() => {
        if (serviceData.includes(currentScreen)) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [currentScreen]);

    const handleProductivity = () => {
        navigate("/relatorios/produtividade");
    };

    const handleServCat = () => {
        navigate("/relatorios/categorias-e-servicos");
    };

    const handleClienteMunic = () => {
        navigate("/relatorios/clientes-municipio");
    };

    return (
        <>
            <Nav.Link eventKey="5" className={`${styles.menuItemRel}`}>
                <ToggleButton
                    className={`${
                        !open ? styles.menuButton : styles.menuButtonReltActive
                    }`}
                    id="togglemenuCategoryCollapse"
                    type="checkbox"
                    variant="link"
                    checked={checked}
                    onChange={(e) => setChecked(e.currentTarget.checked)}
                    onClick={() => setOpen(!open)}
                    aria-controls="menuCategoryCollapse"
                    aria-expanded={open}
                >
                    <div
                        className={
                            !open ? styles.menuIconRelOff : styles.menuIconRelOn
                        }
                    >
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={11}
                            mt={1.5}
                        >
                            <div>
                                <span
                                    style={{
                                        color: !open ? "#0064B4" : "#FFF",
                                    }}
                                >
                                    <Icon
                                        size={24}
                                        icon={ic_bookmark_border_outline}
                                    />
                                </span>

                                <span
                                    style={{
                                        color: open ? "#FFFFFF" : "#0064B4",
                                        fontSize: 16,
                                        textTransform: "none",
                                        marginLeft: 10,
                                        fontWeight: 700,
                                    }}
                                >
                                    Relatórios
                                </span>
                            </div>
                            <div className={styles.imageRel}>
                                <img
                                    src={!open ? setaIco : setaOpenIco}
                                    alt="menu"
                                    height="7px"
                                    width="14px"
                                />
                            </div>
                        </Stack>
                    </div>
                </ToggleButton>
            </Nav.Link>
            <Collapse in={open}>
                <div id="menuCategoryCollapse" className={styles.subMenuItem}>
                    <Nav.Link
                        className={
                            currentScreen == 10
                                ? styles.subItemOn
                                : styles.subItemOff
                        }
                        onClick={() => handleProductivity()}
                    >
                        <div className={styles.containerRelat}>
                            <span />
                            <span>Produtividade</span>
                        </div>
                    </Nav.Link>
                    <Nav.Link
                        className={
                            currentScreen == 14
                                ? styles.subItemOn
                                : styles.subItemOff
                        }
                        onClick={() => handleServCat()}
                    >
                        <div className={styles.containerRelat}>
                            <span />
                            <span>Serviços e categorias</span>
                        </div>
                    </Nav.Link>
                    <Nav.Link
                        className={
                            currentScreen == 15
                                ? styles.subItemOn
                                : styles.subItemOff
                        }
                        onClick={() => handleClienteMunic()}
                    >
                        <div className={styles.containerRelat}>
                            <span />
                            <span>Clientes por município</span>
                        </div>
                    </Nav.Link>
                </div>
            </Collapse>
        </>
    );
};

export const DropDownMenuConfiguracoes = ({ currentScreen = 0 }) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);

    const serviceData = [13, 16];
    useEffect(() => {
        if (serviceData.includes(currentScreen)) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [currentScreen]);

    const handleHabilitarNovosCadastro = () => {
        navigate("/habilitar-novos-cadastros");
    };

    const handleServicoEmail = () => {
        navigate("/servico-de-email");
    };

    return (
        <>
            <Nav.Link eventKey="5" className={`${styles.menuItem}`}>
                <ToggleButton
                    className={`${
                        !open ? styles.menuButton : styles.menuButtonActive
                    }`}
                    id="togglemenuCategoryCollapse"
                    type="checkbox"
                    variant="link"
                    checked={checked}
                    onChange={(e) => setChecked(e.currentTarget.checked)}
                    onClick={() => setOpen(!open)}
                    aria-controls="menuCategoryCollapse"
                    aria-expanded={open}
                >
                    <div className={styles.menuIcon}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={5.5}
                            mt={1.5}
                        >
                            <div>
                                <span
                                    style={{
                                        color: !open ? "#0064B4" : "#FFF",
                                    }}
                                >
                                    <Icon size={20} icon={settings} />
                                </span>

                                <span
                                    style={{
                                        color: open ? "#FFFFFF" : "#0064B4",
                                        fontSize: 16,
                                        textTransform: "none",
                                        marginLeft: 10,
                                        fontWeight: 700,
                                    }}
                                >
                                    Configurações
                                </span>
                            </div>
                            <div>
                                <img
                                    src={!open ? setaIco : setaOpenIco}
                                    alt="menu"
                                    height="7px"
                                    width="14px"
                                />
                            </div>
                        </Stack>
                    </div>
                </ToggleButton>
            </Nav.Link>
            <Collapse in={open}>
                <div id="menuCategoryCollapse" className={styles.subMenuItem}>
                    <Nav.Link
                        className={
                            currentScreen == 13
                                ? styles.subItemOn
                                : styles.subItemOff
                        }
                        onClick={handleHabilitarNovosCadastro}
                    >
                        <span
                            style={{
                                color: !open ? "#0064B4" : "#FFF",
                                visibility: "hidden",
                            }}
                        >
                            <Icon size={20} icon={ic_person_outline} />
                        </span>
                        <div className={styles.containerHabilit}>
                            <span />
                            <span>Habilitar novos cadastros</span>
                        </div>
                    </Nav.Link>

                    <Nav.Link
                        className={
                            currentScreen == 16
                                ? styles.subItemOn
                                : styles.subItemOff
                        }
                        onClick={handleServicoEmail}
                    >
                        <div className={styles.divContainerConf}>
                            <span
                                style={{
                                    color: !open ? "#0064B4" : "#FFF",
                                    visibility: "hidden",
                                }}
                            >
                                <Icon size={20} icon={ic_person_outline} />
                            </span>
                            <div className={styles.textConfig}>
                                <span />
                                <span>Serviço de e-mail</span>
                            </div>
                        </div>
                    </Nav.Link>
                </div>
            </Collapse>
        </>
    );
};
