import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";
import { Form, FormControl, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CpfMaskRegex } from "../../utils/mask/";
import { Api } from "../../providers";
import { toast } from "react-toastify";
import { ModalDefault, ModalConfirm } from "./../../components/modal/index";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./styles.css";
import { UserContext } from "../../contexts/user";

const loginImg = "/images/login/login2.png";

const RedefinirSenhaPage = () => {
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const { setScreenLogin } = useContext(UserContext);
    const [cpf, setCpf] = useState("");
    const [email, setEmail] = useState("");
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModalAviso, setOpenModalAviso] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleRedirect = () => {
        navigate("/resetar?cpf=" + cpf);
    };
    useEffect(() => {
        setScreenLogin(false);
        if (email !== "" && openModalConfirm === false) {
            handleRedirect();
        }
    }, [openModalConfirm]);

    const handleSubmit = () => {
        setLoading(true);
        Api.post(`/operador/pin-create?cpf=${cpf}`)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    setOpenModalAviso(false);
                    setOpenModalConfirm(true);
                    setEmail(res.data?.message);
                }
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;
                setLoading(false);
                setOpenModalAviso(false);
                toast.error(`Ops! ${message.error}`, {
                    toastId: "error",
                });
            });
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid
                    item
                    sx={{ maxWidth: "530px", maxHeight: "942px" }}
                    lg={5}
                    xs={12}
                >
                    {/* <img src={loginImg} alt="login" className="redefinirSenhaImag" /> */}
                    <div className="redefinirSenhaImag" />
                </Grid>
                <Grid item sx={{ m: 0, maxHeight: "940px" }} lg={7} xs={12}>
                    <Box>
                        <div className="login">
                            <h1 className="login-title">Redefinir senha</h1>
                            <Form
                                className="form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setOpenModalAviso(true);
                                }}
                            >
                                <Form.Floating className="mb-3">
                                    <FormControl
                                        type="text"
                                        maxLength="14"
                                        name="cpf"
                                        id="cpf"
                                        value={cpf}
                                        onChange={(e) =>
                                            setCpf(CpfMaskRegex(e.target.value))
                                        }
                                        required
                                    />
                                    <label htmlFor="cpf">CPF</label>
                                </Form.Floating>

                                <Row lg="12" className="my-4 mx-1">
                                    <Col lg="12">
                                        <div className="passwordText">
                                            Não recebi o e-mail
                                        </div>
                                    </Col>
                                    <Row lg="12" className="my-0 mx-1">
                                        <Col
                                            lg="12"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button
                                                className="button-login"
                                                type="submit"
                                            >
                                                Redefinir senha
                                            </Button>
                                        </Col>
                                        <Col
                                            lg="12"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Typography
                                                component={Link}
                                                to="/login"
                                                variant="subtitle1"
                                                sx={{
                                                    textDecoration: "none",
                                                    mt: 2,
                                                }}
                                            >
                                                Cancelar
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Row>
                            </Form>
                        </div>
                    </Box>
                </Grid>
            </Grid>
            <ModalDefault
                title="PIN enviado com sucesso"
                description={`Enviamos o PIN para seu e-mail: ${email}.`}
                openModal={openModalConfirm}
                setOpenModal={setOpenModalConfirm}
                color="blue"
                labelConfirm="OK"
            />
            <ModalConfirm
                title="Redefinir senha?"
                description="Enviaremos para seu email o PIN de segurança. Confirma esta ação?"
                openModal={openModalAviso}
                setOpenModal={setOpenModalAviso}
                onClick={(e) => handleSubmit()}
                color="blue"
                loading={loading}
                labelConfirm="Sim"
                labelCancel="Não"
            />
        </>
    );
};

export default RedefinirSenhaPage;
