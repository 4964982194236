import { Container } from "@mui/material";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import DownloadModalPDF from "../../../download/pdf/modal";
import { ModalConfirmCustom, ModalLoading } from "../../../modal";
import ResultNotFound from "../../../resultNotFound";
import { ServiceContext } from "./../../../../contexts/service";
import { Api } from "./../../../../providers";
import { ListTable } from "./listTable";
import styles from "./styles.module.scss";

const HistoricoTableCliente = ({ idQuery }) => {
    const {
        clientesHist,
        setClientesHist,
        loading,
        setLoading,
        totalPages,
        setTotalPages,
        paramsFilter,
    } = useContext(ServiceContext);
    const [openModalRecibo, setOpenModalRecibo] = useState(false);
    const [solicitacao, setSolicitacao] = useState({});
    const [profissional, setProfissional] = useState([]);
    const [needModalAvaliacoesPendentes, setNeedModalAvaliacoesPendentes] =
        useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const fetchRecibo = (idInfo) => {
        setLoadingModal(true);
        Api.get("/tarefa/" + idInfo)
            .then((res) => {
                if (res.status === 200) {
                    setSolicitacao(res.data);
                    setProfissional(res.data.profissionais);
                    setOpenModalRecibo(true);
                    setLoadingModal(false);
                }
            })
            .catch((error) => {
                setLoadingModal(false);
                toast.error("Ops! Aconteceu algum problema.", {
                    toastId: "msg",
                });
            });
    };

    let mounted = true;
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        Api.get(`tarefa/pagination/0/20?clienteId=${idQuery}&orderBy=REALIZADO`)
            .then((res) => {
                if (mounted) {
                    setClientesHist(res.data.content);
                    setTotalPages(res.data.totalPages);
                    setLoading(false);
                    const checkInfoPendente = res.data.content.filter(
                        (val) => val.status == "Realizado",
                    );
                    if (checkInfoPendente.length > 0) {
                        setNeedModalAvaliacoesPendentes(true);
                    }
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoading(false);
                    setClientesHist([]);
                }
            });
        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [paramsFilter]);

    const nextPage = (page = 0) => {
        setLoading(true);
        const urlData = `/tarefa/pagination/${page}/20?clienteId=${idQuery}`;
        const params = paramsFilter;
        Api.get(urlData, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                setClientesHist(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            })
            .catch((_) => {
                setLoading(false);
            });
    };
    const currentItems = clientesHist;
    const pageCountData = totalPages;

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    return (
        <>
            {currentItems.length > 0 && (
                <ListTable
                    currentItems={currentItems}
                    idQuery={idQuery}
                    fetchRecibo={fetchRecibo}
                />
            )}

            {currentItems.length <= 0 && <ResultNotFound />}

            <div className={styles.reactPaginate}>
                <ReactPaginate
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCountData}
                    previousLabel="<"
                    nextLabel=">"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName={styles.pagination}
                    pageClassName={styles.pageItem}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={styles.pageLink}
                    nextClassName={styles.pageItem}
                    nextLinkClassName={styles.pageLink}
                    activeClassName={"active"}
                    forcePage={currentPage - 1}
                    disabledClassName={styles.disabled}
                />
            </div>

            <ModalConfirmCustom
                title="Avaliações pendentes"
                description="O cliente possui avaliações de profissionais pendentes. É necessário realizar as avaliações para solicitar um novo serviço."
                description2={`Atenção: Todos os serviços já realizados precisam apresentar status de "Avaliado".`}
                openModal={needModalAvaliacoesPendentes}
                setOpenModal={setNeedModalAvaliacoesPendentes}
                onClick={() => {
                    setNeedModalAvaliacoesPendentes(false);
                }}
                labelConfirm="Ok"
                color="blue"
                hasCancel="no"
                labelXSair="nao"
                backgroundColorButton="#0064B4"
            />

            <DownloadModalPDF
                solicitacao={solicitacao}
                profissional={profissional}
                openModalVerRecibo={openModalRecibo}
                setOpenModalVerRecibo={setOpenModalRecibo}
            />

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};

export default HistoricoTableCliente;
