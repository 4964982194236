import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { BreadCrumb } from "../../../components/breadcrumb";
import RquestTable from "../../../components/tables/solicitacao/request";
import Title from "../../../components/title";
import { AuthContext } from "../../../contexts/auth";
import { RequestsProvider } from "../../../contexts/service";
import Search from "./search";

const DashboardOcorrencias = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    useEffect(() => {
        setCurrentScreen(4);
    }, []);

    return (
        <RequestsProvider>
            <Container fluid className="justify-content-center">
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Solicitações de serviço",
                    }}
                />
                <Title title="Solicitações de Serviço" />
                <Container className="listagem">
                    <Search />
                    <RquestTable />
                </Container>
            </Container>
        </RequestsProvider>
    );
};

export default DashboardOcorrencias;
