import { Container, Grid } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import { ModalLoading } from "../../../components/modal";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { ClientesProfissionaisProvider } from "../../../contexts/service";
import { Api } from "../../../providers";
import { ConvertCurrency } from "../../../utils/currency";
import { DateBR } from "../../../utils/date";
import { TelMaskRegex, ZipCodeMask } from "../../../utils/mask";
import styles from "./styles.module.scss";

//Esta tela e visualizada por meio da aba pontuacao que o profissional tem quando vai visualizar suas informacoes.

export default function EditPontuacao() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const { setCurrentScreen } = useContext(AuthContext);
    const [loadingModal, setLoadingModal] = useState(false);
    const [infoData, setInfoData] = useState({});

    let mounted = true;

    const resultRequest = async () => {
        try {
            await Promise.all([
                Api.get(`ocorrencia/${idQuery}`).then((res) => {
                    if (mounted && res.status == 200) {
                        setLoadingModal(false);
                        setInfoData(res.data);
                    }
                }),
            ]);
        } catch (_) {
            if (mounted) {
                setLoadingModal(false);
            }
        }
    };

    useEffect(() => {
        setCurrentScreen(9);
        setLoadingModal(true);
        resultRequest();
        return () => {
            mounted = false;
        };
    }, []);

    const serviceValue = infoData.tarefa?.valor
        ? ConvertCurrency(infoData.tarefa?.valor)
        : ConvertCurrency(infoData.tarefa?.servico.servicoValor);
    const telClient = infoData?.tarefa?.cliente.telefoneCelular
        ? TelMaskRegex(infoData?.tarefa?.cliente.telefoneCelular)
        : "";
    const telProf = infoData.tarefa?.profissionais[0].telefoneCelular
        ? TelMaskRegex(infoData.tarefa?.profissionais[0].telefoneCelular)
        : "";
    const cepMask = infoData.tarefa?.endereco.cep
        ? ZipCodeMask(infoData.tarefa?.endereco.cep)
        : "";

    const getStatus = (status) => {
        switch (status) {
            case "Análise":
                return "Em análise";
            case "Enviada":
                return "Aberta";
            default:
                return status;
        }
    };

    // ***********************************************************************

    return (
        <ClientesProfissionaisProvider>
            <Grid>
                <BreadCrumb
                    data={{
                        category: "Home > Solicitação",
                        page: `#${idQuery}`,
                        menu: {
                            item: "Ocorrências",
                            link: "/ocorrencias/",
                        },
                    }}
                />
                <Title title={`Ocorrência #${idQuery}`} />
                {/* **************************************************************** */}

                <div className={styles.containerGeral}>
                    <div className="justify-content-center align-items-center container">
                        <div className={`${styles.containerInput}`}>
                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Status da ocorrência
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control`}
                                            defaultValue={getStatus(
                                                infoData.status,
                                            )}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Serviço
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control`}
                                            defaultValue={
                                                infoData.tarefa?.servico.servico
                                                    .nome
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Data do serviço
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={DateBR(
                                                infoData.tarefa?.datas[0],
                                            )}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Hora do serviço
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={infoData.tarefa?.hora.substring(
                                                0,
                                                5,
                                            )}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Status do serviço
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa?.status
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Valor do serviço
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={serviceValue}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Solicitante
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={infoData.solicitante}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Tipo da ocorrência
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.ocorrenciaTipo?.nome
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Pontuação da Ocorrência
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.pontuacao === null
                                                    ? infoData.ocorrenciaTipo
                                                          ?.pontuacao
                                                    : infoData.pontuacao
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div />
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.textareaLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Observação
                                        </label>
                                        <textarea
                                            className="form-control"
                                            defaultValue={infoData.descricao}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`d-flex justify-content-between mt-2 mb-3 ${styles.containerOperator}`}
                                >
                                    <div className={styles.textOperator}>
                                        Usuário: {infoData.usuario?.nome} -{" "}
                                        {moment(infoData.dataCadastro).format(
                                            "DD/MM/YY",
                                        )}{" "}
                                        {moment(infoData.dataCadastro).format(
                                            "HH:mm",
                                        )}
                                    </div>
                                    <div>
                                        <span
                                            className={styles.characterNumber}
                                        >
                                            {infoData.descricao?.length}
                                        </span>
                                        <span
                                            className={styles.characterLetter}
                                        >
                                            /200 caracteres
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.divider} />
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Cliente
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa?.cliente.nome
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Telefone
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={telClient}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Profissional
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa
                                                    ?.profissionais[0].nome
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Telefone
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={telProf}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`mt-4 ${styles.divider}`} />
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            CEP
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={cepMask}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Endereço
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa?.endereco
                                                    .logradouro
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Número
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa?.endereco.numero
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Complemento
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa?.endereco
                                                    .complemento
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Bairro
                                        </label>
                                        <select
                                            className="form-select"
                                            defaultValue={
                                                infoData.tarefa?.endereco.bairro
                                            }
                                            disabled
                                        >
                                            <option>
                                                {
                                                    infoData.tarefa?.endereco
                                                        .bairro
                                                }
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Cidade
                                        </label>
                                        <select
                                            className="form-select"
                                            defaultValue={
                                                infoData.tarefa?.endereco.cidade
                                            }
                                            disabled
                                        >
                                            <option>
                                                {
                                                    infoData.tarefa?.endereco
                                                        .cidade
                                                }
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* **************************************************************** */}
            </Grid>

            {/* **************************************************************** */}

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </ClientesProfissionaisProvider>
    );
}
