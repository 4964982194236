import React, { useContext, useEffect } from "react";
import { BreadCrumb } from "./../../../components/breadcrumb";
import Search from "../../../components/search/tipo-servico";
import Title from "../../../components/title";
import TipoServicoTable from "../../../components/tables/tipo-servico";
import { TypeServiceProvider } from "../../../contexts/service";
import { AuthContext } from "../../../contexts/auth";
import styles from "./styles.module.scss";

const DashboardTipoServicoPage = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp = operador.operador.funcao.slug === "administrador" ? true : false;

    useEffect(() => {
        setCurrentScreen(7);
    }, []);

    return (
        <TypeServiceProvider>
            <div className={`justify-content-center ${styles.containerList}`}>
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Tipos de serviços",
                        menu: {
                            item: "Categorias e Serviços",
                            link: "/categoria",
                        },
                    }}
                />
                <Title title="Tipos de serviços" />
                <div className={styles.listagem}>
                    <Search />
                    {checkOp && (
                        <div className={styles.novoTipoServico}>
                            <a href="/tipo-servico/criar">Cadastrar novo tipo de serviço</a>
                        </div>
                    )}
                    <TipoServicoTable />
                </div>
            </div>
        </TypeServiceProvider>
    );
};

export default DashboardTipoServicoPage;
