import React from "react";
import { PropTypes } from "prop-types";
import { MyCalendar } from "./../../components/calendar/index";
import { MyInput } from "./../../components/inputs/inputs";
import { ServiceContext } from "./../../contexts/service";

/* Não funcionou devido ao padrão da lib*/
export const CalendarMask = React.forwardRef(function CalendarMask(props, ref) {
    const { onChange, ...other } = props;

    return (
        <MyCalendar
            getInputRef={ref}
            onChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            {...other}
        />
    );
});

CalendarMask.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
const Item = {
    width: "400px",
    maxWidth: "400px",
    height: "56px",
    display: "flex",
    marginTop: "32px",
    justifyContent: "start",
    alignContent: "center",
    padding: 0,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    alignItems: "center",
    color: "#343B40",
    backgroundColor: "#ffffff",
    "& .MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "17px",
        color: "#343B40",
    },
    "& .MuiFormHelperText-root  ": {
        m: 0,
        p: 0,
        fontSize: 10,
        alignSelf: "end",
        alignItems: "end",
        color: "red",
    },
};

//Produção
export const CustomMultipleInput = React.forwardRef(
    function CustomMultipleInput(props, ref) {
        const { openCalendar, value, label, name } = props;
        return (
            <>
                <MyInput
                    // getInputRef={ref}
                    sx={Item}
                    label={label}
                    name={name}
                    onFocus={openCalendar}
                    value={value}
                    readOnly
                />
            </>
        );
    },
);

CustomMultipleInput.propTypes = {
    // name: PropTypes.string.isRequired,
    // onChange: PropTypes.func.isRequired,
};
