import { Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import olhoCompleto from "../../../../assets/images/eye.png";
import { useUnidades } from "../../../../hooks/useScriptRef";
import { tokenPage } from "../../../../utils/constant";
import btrelogio from "./../../../../assets/images/relogio.png";
import { DateBR } from "./../../../../utils/date/index";
import styles from "./styles.module.scss";

export function ListTable({ currentItems }) {
    const unidadesServer = useUnidades();

    // *************************************************************************
    const operador = JSON.parse(localStorage.getItem("user"));
    const permissoes = JSON.parse(localStorage.getItem("permissoes"));
    const checkOp =
        operador?.operador?.funcao?.slug == "administrador" ? true : false;
    const permSolicitacoes = permissoes?.find(
        (val) => val.tela.slug == "solicitacoes",
    );
    // *************************************************************************

    const getLinhasProfissioanl = (profissionaisSelecionados) => {
        let countPro = 1;
        let nomePrimeiroProfissional = "";
        profissionaisSelecionados.map((linha) => {
            if (countPro == 1) nomePrimeiroProfissional = linha.nome;
            countPro = countPro + 1;
        });
        countPro = countPro - 1;
        return (
            <div className={styles.classProf}>
                <span>{nomePrimeiroProfissional}</span>
                {countPro !== 1 ? (
                    <div className={styles.dataCircle}>
                        <div>+</div>
                        <div>{countPro}</div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    };

    const getUnidadeProfissional = (profissionaisSelecionados) => {
        let unidades = "";
        let unidadesUsadas = [];
        let unidadeTemp = [];
        profissionaisSelecionados.map((linha) => {
            unidadesServer.map((unit) => {
                if (unit.id == linha.unidadeId) {
                    unidadeTemp = unidadesUsadas.filter(
                        (element) => element == unit.id,
                    );
                    if (unidadeTemp.length == 0) {
                        unidades = unidades + unit.nome + "/ ";
                        unidadesUsadas.push(unit.id);
                    }
                }
            });
        });
        return unidades.slice(0, -2);
    };

    const getColorStatus = (status) => {
        switch (status) {
            case "A Confirmar":
                return "#F9B000";
            case "Não Realizado":
                return "#F9B000";
            case "Confirmado":
                return "#95C11F";
            case "Realizado":
                return "#2E7EBE";
            case "Avaliado":
                return "#0064B4";
            case "Cancelado":
                return "#E60E19";
            default:
                return "#656A6E";
        }
    };

    return (
        <div className="table-responsive">
            <table className={`table align-middle ${styles.table}`}>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Nº da solicitação</th>
                        <th>Serviço</th>
                        <th>Data do serviço</th>
                        <th>Nome do cliente</th>
                        <th>Nome do profissional</th>
                        <th>Unidade</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody data-test="table-body">
                    {currentItems.length > 0 ? (
                        currentItems.map((val, idx) => {
                            const colorTr =
                                idx % 2 == 0
                                    ? styles.colorTrOne
                                    : styles.colorTrTwo;

                            return (
                                <tr className={colorTr} key={val.id.toString()}>
                                    <td>
                                        <div
                                            className={styles.boxColor}
                                            style={{
                                                backgroundColor: getColorStatus(
                                                    val.status,
                                                ),
                                            }}
                                        >
                                            <span
                                                className={
                                                    styles.pillBadgesText
                                                }
                                            >
                                                {val.status}
                                            </span>
                                        </div>
                                    </td>
                                    <td>{val.id}</td>
                                    <td>{val.servico?.servico?.nome}</td>
                                    <td>{DateBR(val.datas[0])}</td>
                                    <td>{val.cliente?.nome}</td>
                                    <td>
                                        {getLinhasProfissioanl(
                                            val.profissionais,
                                        )}
                                    </td>
                                    <td>
                                        {getUnidadeProfissional(
                                            val.profissionais,
                                        )}
                                    </td>
                                    <td>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <Link
                                                to={
                                                    "/solicitacao/historico?idTarefa=" +
                                                    val.id
                                                }
                                            >
                                                <img
                                                    src={btrelogio}
                                                    alt="Editar"
                                                />
                                            </Link>
                                            {(checkOp ||
                                                permSolicitacoes?.atualizar) && (
                                                <Link
                                                    to={
                                                        "/aceitar-solicitacao/?id=" +
                                                        val.id +
                                                        "&idProfissional=" +
                                                        val.profissionalId +
                                                        "&preview=cliente" +
                                                        "&showtable=" +
                                                        tokenPage
                                                    }
                                                >
                                                    <img
                                                        src={olhoCompleto}
                                                        alt="Detalhamento de Soliticação"
                                                    />
                                                </Link>
                                            )}
                                        </Stack>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
        </div>
    );
}
