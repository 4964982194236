/* eslint-disable no-unused-vars */
import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { Api } from "../../../providers";
import styles from "./styles.module.scss";

const CriarTipoServicoPage = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const navigate = useNavigate();
    const search = useLocation().search;

    const [id, setId] = useState("");
    const [nome, setNome] = useState("");
    const [descricao, setDescricao] = useState("");
    const [ativo, setAtivo] = useState(true);
    const [tipoServico, setTipoServico] = useState();
    const [servicos, setServicos] = useState([]);
    const [servicoId, setServicoId] = useState("0");

    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp =
        operador.operador.funcao.slug === "administrador" ? true : false;

    const handleRedirect = () => {
        navigate("/tipo-servico");
    };

    useEffect(() => {
        if (!checkOp) {
            handleRedirect();
        }
        setCurrentScreen(7);
    }, []);

    useEffect(() => {
        let mounted = true;
        Api.get("/servico/").then((res) => {
            if (res.status === 200 && mounted) {
                const result = res.data.filter((data) => data.flagAtivo);
                setServicos(result);
            }
        });
        return () => {
            mounted = false;
        };
    }, []);

    const handleSubmit = () => {
        const servico2 = {
            nome: nome,
            slug: "",
            descricao: descricao,
            flagAtivo: ativo,
            tipoAgendamento: tipoServico,
            servicoId: servicoId,
        };
        Api.post("/servico-tipo/", servico2)
            .then((res) => {
                toast.success("Cadastrado com sucesso", {
                    toastId: "success",
                });
                handleRedirect();
            })
            .catch((err) => {
                const checkErro = err;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });
    };

    if (!checkOp) {
        return <></>;
    }

    const checkNome = nome.trim() && nome.length > 0 ? true : false;
    const checkCat = Number(servicoId) > 0 ? true : false;
    const checkDesc = descricao.trim() && descricao.length > 0 ? true : false;
    const checkButtonSubmit = checkNome && checkDesc && checkCat ? true : false;

    return (
        <Container maxWidth="xl">
            <BreadCrumb
                data={{
                    category: "",
                    page: "Criar tipo de serviço",
                    menu: {
                        item: "Categorias e serviços",
                        item2: "Tipos de serviços",
                        link: "/tipo-servico",
                    },
                }}
            />
            <Title title="Criar tipo de serviço" />
            <div className={styles.containerGeral}>
                <div className={styles.subContainerGeral}>
                    <form className={styles.formularioItem}>
                        <Form.Group>
                            <span className={styles.labelText}>
                                Tipo de serviço
                            </span>
                            <Form.Control
                                className={styles.containerInputFull}
                                type="text"
                                placeholder="Digite aqui o tipo de serviço"
                                name="nome"
                                id="nome"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                maxLength={100}
                                size="lg"
                            />
                        </Form.Group>
                        <div>
                            <span
                                className={`${styles.labelText} ${styles.maginTopLabel}`}
                            >
                                Serviços
                            </span>
                            <div className={styles.fieldSelect}>
                                <Form.Select
                                    className="form-select"
                                    aria-label="categoryOption"
                                    as="select"
                                    value={servicoId}
                                    onChange={(e) =>
                                        setServicoId(e.target.value)
                                    }
                                >
                                    <option value="0" key="0" disabled>
                                        Selecionar
                                    </option>
                                    {servicos.map((data, key) => {
                                        return (
                                            <option value={data.id} key={key}>
                                                {data.nome}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </div>
                        </div>

                        <Form.Group>
                            <span
                                className={`${styles.labelText} ${styles.maginTopLabel}`}
                            >
                                Descrição
                            </span>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="descricao"
                                id="descricao"
                                placeholder="Descreva o tipo de serviço"
                                value={descricao}
                                maxLength={200}
                                onChange={(e) => setDescricao(e.target.value)}
                                className={styles.textAreaComment}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    marginTop: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: 12,
                                        fontWeight: 700,
                                        color: "#8D8F90",
                                        lineHeight: "14.52px",
                                    }}
                                >
                                    {descricao.length}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "#8D8F90",
                                        lineHeight: "14.52px",
                                    }}
                                >
                                    /200 caracteres
                                </Typography>
                            </Box>
                        </Form.Group>
                    </form>
                    <div className={styles.containerBtn}>
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#0064B4",
                                    lineHeight: "19.36px",
                                    marginBottom: "29px",
                                }}
                            >
                                Status
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    width: 200,
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: "#343B40",
                                        lineHeight: "16.94px",
                                    }}
                                >
                                    Ativo
                                </Typography>
                                <Box sx={{ marginLeft: 2 }}>
                                    <input
                                        type="checkbox"
                                        checked={ativo}
                                        onChange={(e) => setAtivo(!ativo)}
                                        id={styles.checkbox}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <div
                            onClick={() =>
                                checkButtonSubmit ? handleSubmit() : {}
                            }
                        >
                            <button
                                className={
                                    !checkButtonSubmit
                                        ? styles.buttonSearchBlock
                                        : styles.buttonSave
                                }
                                disabled={!checkButtonSubmit}
                                type="button"
                            >
                                <span>Salvar</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default CriarTipoServicoPage;
