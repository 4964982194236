import React from "react";
import UserProvider from "./../../contexts/user";
import ReturnInfo from "./returnInfo";

const Layout = ({ children }) => {
    return (
        <UserProvider>
            <ReturnInfo>{ children }</ReturnInfo>            
        </UserProvider>
    );
};

export default Layout;
