import React, { useContext, useEffect } from "react";
import ajuda from "./../../../assets/images/bt-ajuda.png";
import perfil from "./../../../assets/images/bt-perfil.png";
import logo from "./../../../assets/images/logo.png";
import { UserContext } from "./../../../contexts/user";
import styles from "./navbar.module.scss";

const Navbar = () => {
    const { isLoggedUser, screenLogin } = useContext(UserContext);

    useEffect(() => {
        const myperfil = document.getElementById("myPerfil");
        isLoggedUser
            ? (myperfil.style.display = "block")
            : (myperfil.style.display = "none");
    }, [isLoggedUser]);

    function handleMyProfile() {
        window.location.href = "/perfil/editar";
    }

    return (
        <div className={`${!isLoggedUser && screenLogin ? styles.navContainer : 'nav-container'}`}>
            <nav id="navbar">
                <div className="logo">
                    <img
                        src={logo}
                        alt="Contrate BA"
                        title="Contrate BA"
                        height="36"
                        width="328.15"
                    />
                </div>
                <div className={styles.navItems}>
                    <div>
                        <img src={ajuda} alt="Ajuda" />
                        <span className={`${styles.textTop}`}>Ajuda</span>
                    </div>
                    <div id="myPerfil" onClick={handleMyProfile}>
                        <img src={perfil} alt="Perfil" />
                        <span className={`${styles.textTop}`}>
                            Meu perfil
                        </span>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
