import { Box } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_check_box_outline_blank } from "react-icons-kit/md/ic_check_box_outline_blank";
import { ic_check_outline } from "react-icons-kit/md/ic_check_outline";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ImageClock from "../../../assets/images/clock.png";
import ImageChecked from "../../../assets/images/icons/checked.png";
import { Api } from "../../../providers";
import { BreadCrumb } from "../../breadcrumb";
import {
    ModalConfirm,
    ModalJustCustom,
    ModalJustificativa,
    ModalLoading,
} from "../../modal";
import ResultNotFound from "../../resultNotFound";
import Title from "../../title";
import MyCheckbox from "./../../checkbox/index";
import { ListTable } from "./listTable";
import { ListTableJustificativas } from "./listTableJustificativas";
import styles from "./styles.module.scss";

const BoxData = ({ children, style }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                background: "#F7F7F7",
                borderRadius: "8px",
                padding: "25px",
                ...style,
            }}
        >
            {children}
        </Box>
    );
};

const ProfissionaisAvaliarTable = () => {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const [profissionais, setProfissionais] = useState([]);
    const [situacao, setSituacao] = useState([]);
    const [profissionaisFaltosos, setProfissionaisFaltosos] = useState([]);
    const [idProf, setIdProf] = useState("");
    const [nome, setNome] = useState("");
    //Justificativa da Reprovação
    const [justificativa, setJustificativa] = useState("");
    //Justificativa da Falta
    const [justificativaFalta, setJustificativaFalta] = useState("");
    const [justificativaSelect, setJustificativaSelect] = useState("0");
    const [reabrirDatas, setReabrirDatas] = useState(false);
    //Modais
    const [openModal, setOpenModal] = useState(false);
    const [openModalConfirmUnload, setOpenModalConfirmUnload] = useState(false);

    const [openModalReabrirDatas, setOpenModalReabrirDatas] = useState(false);
    const [openModalJustificativa, setOpenModalJustificativa] = useState(false);
    const [resultJust, setResultJust] = useState([]);
    const [resultJustView, setResultJustView] = useState([]);
    const [checkUpdate, setCheckUpdate] = useState(false);
    const [operatorName, setOperatorName] = useState("");
    const [operatorDate, setOperatorDate] = useState("");

    const [profissionaisOrig, setProfissionaisOrig] = useState([]);
    const [totalDadosPres, setTotalDadosPres] = useState([]);
    const [totalDadosAprov, setTotalDadosAprov] = useState([]);
    const [flagPresHist, setFlagPresHist] = useState(false);
    const [flagAprovHist, setFlagAprovHist] = useState(false);
    const [modalPres, setModalPres] = useState(false);
    const [negarModal, setNegarModal] = useState(false);
    const [mostrarHistModal, setMostrarHistModal] = useState(false);
    const [marcarTodos, setMarcarTodos] = useState(false);
    const [marcaReprovacao, setMarcaReprovacao] = useState(false);
    const [statusFimTrein, setStatusFimTrein] = useState(false);
    const [statusEditTrein, setStatusEditTrein] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [novoTreinamento, setNovoTreinamento] = useState(false);
    const [limitEditPas, setLimitEditPas] = useState(false);

    const [clickView, setClickView] = useState(false);

    const checkDataSistema = (dataFim, dataFinal, isValidDate) => {
        Api.get("/system/date").then((resData) => {
            if (resData.status === 200) {
                const dataServ = moment(resData.data.hoursDate);
                const dataFimTrein = moment(dataFim);

                if (dataServ.diff(dataFimTrein, "m") >= 1) {
                    setStatusFimTrein(true);

                    // **********************************************************
                    if (isValidDate) {
                        const maxDateAvailable = new Date(dataFinal);
                        maxDateAvailable.setUTCDate(
                            maxDateAvailable.getUTCDate() + 29,
                        );
                        const maxMonthDate = maxDateAvailable.getUTCMonth() + 1;
                        const maxCurrentMonth =
                            maxMonthDate <= 9
                                ? "0" + maxMonthDate
                                : maxMonthDate;
                        const maxCurrentDay =
                            maxDateAvailable.getUTCDate() <= 9
                                ? "0" + maxDateAvailable.getUTCDate()
                                : maxDateAvailable.getUTCDate();
                        const maxCurrentDate =
                            maxDateAvailable.getUTCFullYear() +
                            "-" +
                            maxCurrentMonth +
                            "-" +
                            maxCurrentDay;

                        const dateServ = moment(
                            resData.data.date,
                            "YYYY-MM-DD",
                        ).valueOf();

                        const dataLimiteEdit = moment(
                            maxCurrentDate,
                            "YYYY-MM-DD",
                        ).valueOf();

                        if (dateServ <= dataLimiteEdit) {
                            setStatusEditTrein(true);
                        } else {
                            setLimitEditPas(true);
                        }
                    }
                }
            }
        });
    };

    const checkTreinamento = (dataAtual) => {
        Api.get("/treinamento/" + idQuery).then((resData) => {
            if (resData.status === 200) {
                const dataFimTrein = moment(resData.data.dataHoraFim).format(
                    "YYYY-MM-DD HH:mm:ss",
                );

                const dataFinal = moment(dataAtual).format("YYYY-MM-DD");
                const isValidDate = moment(dataAtual).isValid();

                checkDataSistema(dataFimTrein, dataFinal, isValidDate);
            }
        });
    };

    // GET Profissionais: Todos e Faltosos
    useEffect(() => {
        window.addEventListener("beforeunload", function (event) {
            event.preventDefault();
            setOpenModalConfirmUnload(true);
            event.returnValue = "Hellooww";
        });

        async function Atep() {
            try {
                setLoadingModal(true);
                let faltosos = [];
                const dados = await Api.get(
                    "/treinamento/" + idQuery + "/detalhes",
                );
                const profissioanaisTemp = dados.data.map((val) => {
                    if (val.flagPresenca == false) {
                        faltosos.push(val);
                    }

                    return {
                        ...val,

                        // novoTreinamento:
                        //     val.profissionalId == 39511 ? true : false,
                        // flagPresenca: null,
                        // flagAprovado: null,
                        // dataAtualizacao: null,

                        // flagPresenca:
                        //     val.flagPresenca === null ? true : val.flagPresenca,
                        // flagAprovado:
                        //     val.flagAprovado === null ? false : val.flagAprovado,
                    };
                });

                const profData = dados.data.map((val) => {
                    return {
                        profissionalId: val.profissionalId,
                        outro: "",
                    };
                });

                setProfissionaisOrig(profissioanaisTemp);

                setResultJust(profData);
                setResultJustView(profData);
                setProfissionais(profissioanaisTemp);
                setProfissionaisFaltosos(faltosos);

                if (profissioanaisTemp.length > 0) {
                    checkTreinamento(profissioanaisTemp[0].dataAtualizacao);
                    if (
                        !profissioanaisTemp[0].dataAtualizacao &&
                        !profissioanaisTemp[0].flagAprovado
                    ) {
                        setStatusEditTrein(true);
                    }
                }

                setLoadingModal(false);
            } catch (error) {
                setLoadingModal(false);
            }
        }
        Atep();
        return () => {
            //do nothing
        };
    }, [checkUpdate]);

    //Presença do profissional: único
    const handleAtualizarPresenca = (id, checkStatus, novaPres) => {
        const profissioanisTemp = profissionais.map((val) => {
            let flagPres;
            if (checkStatus) {
                flagPres = !val.flagPresenca;
            } else {
                flagPres = novaPres;
            }

            if (
                !novaPres &&
                val.profissionalId == id &&
                (val.flagAprovado || val.flagAprovado == null)
            ) {
                setNome(val.profissional?.nome);
                setIdProf(val.profissionalId);
                setJustificativa("");
                setJustificativaSelect("0");
                setOpenModal(true);
                setOpenModalJustificativa(false);
                setFlagPresHist(val.flagPresenca);
                setFlagAprovHist(val.flagAprovado);
                setModalPres(true);
                setNegarModal(true);

                const checkPrimAval = profissionaisOrig.filter(
                    (val) =>
                        val.profissionalId == id && val.flagPresenca == null,
                );
                if (statusFimTrein && checkPrimAval.length > 0) {
                    setMarcaReprovacao(true);
                }

                // ******************************************************************

                if (!novaPres) {
                    const resultDataAprov = totalDadosAprov.includes(id);

                    if (!resultDataAprov) {
                        setTotalDadosAprov([...totalDadosAprov, id]);
                    }
                }
            }

            return {
                ...val,
                flagPresenca:
                    val.profissionalId == id ? flagPres : val.flagPresenca,
                flagAprovado:
                    val.profissionalId == id && !flagPres
                        ? false
                        : val.flagAprovado,
            };
        });
        setProfissionais(profissioanisTemp);

        // ********************************************************************

        const checkPrimAval = profissionaisOrig.filter(
            (val) => val.profissionalId == id && val.flagPresenca == null,
        );

        const resultDataPres = totalDadosPres.includes(id);
        const totalPres = totalDadosPres;

        if (resultDataPres && checkPrimAval.length <= 0) {
            const index = totalPres.indexOf(id);
            if (index > -1) {
                totalPres.splice(index, 1);
            }
            setTotalDadosPres(totalPres);
        } else if (!resultDataPres) {
            setTotalDadosPres([...totalDadosPres, id]);
        }

        // ******************************************************************

        const resultDataAprov = totalDadosAprov.includes(id);
        const totalAprov = totalDadosAprov;

        if (resultDataAprov && checkPrimAval.length <= 0) {
            const index = totalAprov.indexOf(id);
            if (index > -1) {
                totalAprov.splice(index, 1);
            }
            setTotalDadosAprov(totalAprov);
        }
    };

    const handleAtualizarPresencaModal = (id) => {
        const profissioanisTemp = profissionais.map((val) => {
            return {
                ...val,
                flagPresenca:
                    val.profissionalId == id ? flagPresHist : val.flagPresenca,
                flagAprovado:
                    val.profissionalId == id ? flagAprovHist : val.flagAprovado,
            };
        });
        setProfissionais(profissioanisTemp);

        // ************************************************************************

        const checkPrimAval = profissionaisOrig.filter(
            (val) => val.profissionalId == id && val.flagPresenca == null,
        );

        const resultDataPres = totalDadosPres.includes(id);
        const totalPres = totalDadosPres;

        if (checkPrimAval.length > 0) {
            if (resultDataPres && negarModal && flagPresHist == null) {
                const index = totalPres.indexOf(id);
                if (index > -1) {
                    totalPres.splice(index, 1);
                }
                setTotalDadosPres(totalPres);
                setNegarModal(false);
            }
        } else {
            const resultDataPres = totalDadosPres.includes(id);
            const totalPres = totalDadosPres;

            if (resultDataPres && negarModal && flagPresHist != null) {
                const index = totalPres.indexOf(id);
                if (index > -1) {
                    totalPres.splice(index, 1);
                }
                setTotalDadosPres(totalPres);
                setNegarModal(false);
            } else if (!resultDataPres) {
                setTotalDadosPres([...totalDadosPres, id]);
            }
        }
    };

    //Presença do Profissional:Todos
    const handleDefinirTodosPresentes = () => {
        const profissioanisTemp = profissionais.map((val) => {
            return {
                ...val,
                flagPresenca: !marcarTodos ? true : null,
                flagAprovado: !marcarTodos ? true : null,
            };
        });

        if (!marcarTodos) {
            const resultProfis = profissionaisOrig.map(
                (val) => val.profissionalId,
            );
            setTotalDadosPres(resultProfis);
            setTotalDadosAprov(resultProfis);
        } else {
            setTotalDadosPres([]);
            setTotalDadosAprov([]);
        }

        setProfissionais(profissioanisTemp);
        setMarcarTodos(!marcarTodos);
    };

    const handleAtualizarAprovacao = (id, valorNovo, checkModal = true) => {
        const profissioanisTemp = profissionais.map((val) => {
            if (!valorNovo && val.profissionalId == id && !checkModal) {
                setNome(val.profissional?.nome);
                setIdProf(val.profissionalId);
                setJustificativa("");
                setJustificativaSelect("0");
                setOpenModal(true);
                setOpenModalJustificativa(false);
                setModalPres(false);
                setNegarModal(true);
                setFlagPresHist(val.flagPresenca);
                setFlagAprovHist(val.flagAprovado);

                const checkPrimAval = profissionaisOrig.filter(
                    (val) =>
                        val.profissionalId == id && val.flagAprovado == null,
                );
                if (statusFimTrein && checkPrimAval.length > 0) {
                    setMarcaReprovacao(true);
                }
            }

            return {
                ...val,
                flagPresenca:
                    val.profissionalId == id && valorNovo
                        ? true
                        : val.flagPresenca,
                flagAprovado:
                    val.profissionalId == id ? valorNovo : val.flagAprovado,
            };
        });

        setProfissionais(profissioanisTemp);

        const totalItem = profissionaisOrig.filter((val) => val.profissionalId);

        const totalItemAprov = profissioanisTemp.filter(
            (val) => val.flagAprovado,
        );

        if (totalItem.length == totalItemAprov.length) {
            setMarcaReprovacao(false);
        }

        // ********************************************************************

        const checkPrimAval = profissionaisOrig.filter(
            (val) => val.profissionalId == id && val.flagAprovado == null,
        );

        const resultDataAprov = totalDadosAprov.includes(id);
        const totalAprov = totalDadosAprov;

        if (resultDataAprov && checkPrimAval.length <= 0) {
            const index = totalAprov.indexOf(id);
            if (index > -1) {
                totalAprov.splice(index, 1);
            }
            setTotalDadosAprov(totalAprov);
        } else if (!resultDataAprov) {
            setTotalDadosAprov([...totalDadosAprov, id]);
        }

        // ********************************************************************

        if (valorNovo) {
            const resultDataPres = totalDadosPres.includes(id);

            if (!resultDataPres) {
                setTotalDadosPres([...totalDadosPres, id]);
            }
        }
    };

    const handleAtualizarAprovacaoModal = (id) => {
        const profissioanisTemp = profissionais.map((val) => {
            return {
                ...val,
                flagPresenca:
                    val.profissionalId == id ? flagPresHist : val.flagPresenca,
                flagAprovado:
                    val.profissionalId == id ? flagAprovHist : val.flagAprovado,
            };
        });
        setProfissionais(profissioanisTemp);

        // ************************************************************************

        const checkPrimAval = profissionaisOrig.filter(
            (val) => val.profissionalId == id && val.flagAprovado == null,
        );

        const resultDataAprov = totalDadosAprov.includes(id);
        const totalAprov = totalDadosAprov;

        //Representa sem avaliacao
        if (checkPrimAval.length > 0) {
            if (resultDataAprov && negarModal && flagAprovHist == null) {
                const index = totalAprov.indexOf(id);
                if (index > -1) {
                    totalAprov.splice(index, 1);
                }
                setTotalDadosAprov(totalAprov);
                setNegarModal(false);
            }
        } else {
            //Representa a parte da edicao
            if (resultDataAprov && negarModal && flagAprovHist != null) {
                const index = totalAprov.indexOf(id);
                if (index > -1) {
                    totalAprov.splice(index, 1);
                }
                setTotalDadosAprov(totalAprov);
                setNegarModal(false);
            } else if (!resultDataAprov) {
                setTotalDadosAprov([...totalDadosAprov, id]);
            }
        }
    };

    const closeModal = () => {
        setOperatorName("");
        setOperatorDate("");
        setOpenModalJustificativa(false);
        setOpenModal(false);
        setJustificativa("");
        setJustificativaSelect("0");
        setClickView(false);
        setNovoTreinamento(false);

        const checkPrimAval = profissionaisOrig.filter(
            (val) =>
                val.dataAtualizacao == null &&
                val.flagPresenca == null &&
                val.flagAprovado == null,
        );
        if (statusFimTrein && checkPrimAval.length > 0) {
            setMarcaReprovacao(false);
        }
    };

    const retornaHist = (tipoModal) => {
        if (tipoModal) {
            handleAtualizarPresencaModal(idProf);
            handleAtualizarAprovacaoModal(idProf);
        } else {
            handleAtualizarAprovacaoModal(idProf);
        }
        closeModal();
    };

    const saveJust = (text) => {
        const rest = resultJust.map((val) => {
            return {
                ...val,
                outro: val.profissionalId == idProf ? text : val.outro,
            };
        });
        setJustificativa(text);
        setResultJust(rest);
        setResultJustView(rest);
    };

    const operatorData = (profId, novoTrein) => {
        setClickView(true);
        setLoadingModal(true);
        setNovoTreinamento(novoTrein);

        Api.get(
            `/treinamento/${idQuery}/profissional/${profId}/detalhes?acao=Reprovação`,
        )
            .then((resp) => {
                setOperatorName(resp?.data[0]?.nome);
                setOperatorDate(resp?.data[0]?.dataAcao);

                Api.get(
                    "/treinamento/detalhes?profissionalId=" +
                        profId +
                        "&treinamentoId=" +
                        idQuery,
                )
                    .then((resOp) => {
                        const justRep = resOp?.data[0]?.justificativaReprovacao
                            ? true
                            : false;
                        if (justRep) {
                            const justSelect =
                                resOp?.data[0]?.justificativaReprovacao.indexOf(
                                    "Outro",
                                ) >= 0
                                    ? "outro"
                                    : resOp?.data[0]?.justificativaReprovacao;
                            setJustificativaSelect(justSelect);
                            setJustificativa(
                                resOp?.data[0]?.justificativaReprovacao.replace(
                                    "Outro:",
                                    "",
                                ),
                            );
                        } else {
                            setOperatorName("");
                            setOperatorDate("");
                            setJustificativaSelect("");
                            setJustificativa("");
                        }

                        setNome(resOp.data[0]?.profissional?.nome);
                        setIdProf(profId);

                        setOpenModalJustificativa(true);
                        setMostrarHistModal(true);
                        setLoadingModal(false);
                    })
                    .catch((error) => {
                        setLoadingModal(false);
                        const checkErro = error;
                        toast.error(
                            `Ops! ${checkErro.response.data.message?.error}`,
                            {
                                toastId: "error",
                            },
                        );
                    });
            })
            .catch((error) => {
                setLoadingModal(false);
                const checkErro = error;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });
    };

    const openEdit = () => {
        setOpenModalJustificativa(false);
        setOpenModal(true);
    };

    //Justificativa
    const handleJustificativa = () => {
        setLoadingModal(true);
        if (!clickView) {
            const result = resultJust.filter(
                (val) => val.profissionalId === idProf,
            );
            const resultTextJust = result[0]?.outro ?? "";
            const resultSelect =
                justificativaSelect === "outro"
                    ? "Outro:"
                    : justificativaSelect;
            const justiSelect =
                resultTextJust.length > 0 ? resultSelect + " " : resultSelect;
            let profissioanisTemp = profissionais.map((val) => {
                setOpenModal(false);
                return {
                    ...val,
                    justificativaReprovacao:
                        val.profissionalId == idProf
                            ? justiSelect + "" + resultTextJust
                            : val.justificativaReprovacao,
                };
            });
            setProfissionais(profissioanisTemp);
            setLoadingModal(false);
        } else {
            const result = resultJustView.filter(
                (val) => val.profissionalId === idProf,
            );
            const resultTextJust = result[0]?.outro ?? "";
            const resultSelect =
                justificativaSelect === "outro"
                    ? "Outro:"
                    : justificativaSelect;
            const justiSelect =
                resultTextJust.length > 0 ? resultSelect + " " : resultSelect;

            const profissioanisTemp = profissionaisOrig.map((val) => {
                setOpenModal(false);
                return {
                    ...val,
                    justificativaReprovacao:
                        val.profissionalId == idProf
                            ? justiSelect + "" + resultTextJust
                            : val.justificativaReprovacao,
                };
            });

            const profData = profissionaisOrig.map((val) => {
                return {
                    profissionalId: val.profissionalId,
                    outro: "",
                };
            });

            const profissioanisInfo = profissionais
                .map((val) => {
                    return {
                        ...val,
                        justificativaReprovacao:
                            val.profissionalId == idProf
                                ? justiSelect + "" + resultTextJust
                                : val.justificativaReprovacao,
                    };
                })
                .filter((data) => data.profissionalId == idProf);

            setProfissionais(profissioanisTemp);

            Api.post("/treinamento/" + idQuery + "/detalhes", profissioanisInfo)
                .then((res) => {
                    setOpenModalConfirmUnload(false);
                    if (res.status === 200) {
                        setJustificativa("");
                        setResultJust(profData);
                        setClickView(false);
                        toast.success(
                            "Justificativa da reprovação realizada com sucesso",
                            {
                                toastId: "success",
                            },
                        );
                        setLoadingModal(false);
                    }
                })
                .catch((err) => {
                    setLoadingModal(false);
                    const checkErro = err;
                    toast.error(
                        `Ops! ${checkErro.response.data.message?.error}`,
                        {
                            toastId: "error",
                        },
                    );
                });
        }
    };

    //Salvar tudo
    const handleSubmit = () => {
        const primAvaliacao = profissionaisOrig.filter(
            (val) =>
                val.dataAtualizacao == null &&
                val.flagPresenca == null &&
                val.flagAprovado == null,
        );

        const totalDados = totalDadosPres.concat(totalDadosAprov);
        //Remove duplicidade
        const semDup = [...new Set(totalDados)];

        let totalProf;
        let textToast = "Avaliação realizada com sucesso";

        //Primeira avaliacao
        if (statusFimTrein && primAvaliacao.length > 0 && statusEditTrein) {
            totalProf = profissionais;
        } else {
            textToast = "Atualizado com sucesso";
            let profSelect = [];
            const _ = semDup.map((data) => {
                return profissionais.map((val) => {
                    if (val.profissionalId == data) {
                        profSelect.push(val);
                    }
                });
            });
            totalProf = profSelect;
        }

        if (totalProf?.length > 0) {
            Api.post("/treinamento/" + idQuery + "/detalhes", totalProf)
                .then((res) => {
                    setOpenModalConfirmUnload(false);
                    if (res.status === 200) {
                        setJustificativa("");
                        setResultJust([]);
                        setTotalDadosPres([]);
                        setTotalDadosAprov([]);
                        setFlagPresHist(false);
                        setFlagAprovHist(false);
                        setCheckUpdate(!checkUpdate);
                        toast.success(textToast, {
                            toastId: "success",
                        });
                    }
                })
                .catch((err) => {
                    const checkErro = err;
                    toast.error(
                        `Ops! ${checkErro.response.data.message?.error}`,
                        {
                            toastId: "error",
                        },
                    );
                });
        } else {
            toast.error(
                "Não foi possível realizar essa ação no momento, por favor tente novamente mais tarde!",
                {
                    toastId: "msg",
                },
            );
        }
    };

    // Reabrir Datas
    const handleModalReabrirDatas = (data) => {
        setIdProf(data.id);
        setJustificativaFalta(data.justificativa);
        setReabrirDatas(data.abrirDatas);
        setOpenModalReabrirDatas(true);
        setNovoTreinamento(data.novoTreinamento);
    };

    const handleReabrirDatas = () => {
        setOpenModalReabrirDatas(false);
        setNovoTreinamento(false);
        setJustificativa("");

        const profissioanisTemp = profissionaisOrig
            .map((val) => {
                const flagData =
                    val.flagAbrirDatas == null
                        ? reabrirDatas
                        : val.flagAbrirDatas;
                return {
                    ...val,
                    flagAbrirDatas:
                        val.profissionalId == idProf ? reabrirDatas : flagData,
                };
            })
            .filter((data) => data.profissionalId == idProf);
        Api.post("/treinamento/" + idQuery + "/detalhes", profissioanisTemp)
            .then((res) => {
                setOpenModalConfirmUnload(false);
                if (res.status === 200) {
                    setJustificativa("");
                    setResultJust([]);
                    setTotalDadosPres([]);
                    setTotalDadosAprov([]);
                    setFlagPresHist(false);
                    setFlagAprovHist(false);
                    setCheckUpdate(!checkUpdate);
                    toast.success("Atualizado com Sucesso", {
                        toastId: "success",
                    });
                }
            })
            .catch((err) => {
                const checkErro = err;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });
    };

    useEffect(() => {
        Api.get("/profissional-situacao/").then((res) => {
            if (res.status === 200) setSituacao(res.data);
        });
    }, []);

    const dataOperator = () => {
        if (operatorName != "") {
            return (
                <div>
                    <img src={ImageClock} alt="imagem" />
                    <span>{`Operador ${operatorName} - ${operatorDate}`}</span>
                </div>
            );
        }
        return <div />;
    };

    let checkButton = false;

    const checkPrimAval = profissionaisOrig.filter(
        (val) =>
            val.dataAtualizacao == null &&
            val.flagPresenca == null &&
            val.flagAprovado == null,
    );

    //Primeira avaliacao
    if (statusFimTrein && checkPrimAval.length > 0 && statusEditTrein) {
        if (
            checkPrimAval.length == totalDadosPres.length &&
            checkPrimAval.length == totalDadosAprov.length
        ) {
            checkButton = true;
        }
    }

    //Editar
    if (statusFimTrein && checkPrimAval.length <= 0 && statusEditTrein) {
        if (totalDadosPres.length > 0 || totalDadosAprov.length > 0) {
            checkButton = true;
        }
    }

    const checkButtonSubmit = checkButton;

    const btnJust = statusEditTrein ? "Salvar" : "Reprovar";

    const closeModalReabrirDatas = () => {
        setOpenModalReabrirDatas(false);
        setNovoTreinamento(false);
    };

    const permitEdit = novoTreinamento ? true : justificativaSelect == 0;
    const checkLimitEdit = limitEditPas ? limitEditPas : permitEdit;

    const checkDisabtrein = novoTreinamento
        ? novoTreinamento
        : !statusEditTrein;

    return (
        <>
            <BreadCrumb
                data={{
                    category: "",
                    page: "Avaliar profissionais",
                    menu: {
                        item: "Treinamentos",
                        item2: "Visualizar treinamento",
                        link: "",
                    },
                }}
            />
            <Title title="Avaliar profissionais" />
            <BoxData>
                {profissionais?.length > 0 && (
                    <ListTable
                        currentItems={profissionais}
                        situacao={situacao}
                        handleAtualizarPresenca={handleAtualizarPresenca}
                        handleAtualizarAprovacao={handleAtualizarAprovacao}
                        operatorData={operatorData}
                        profissionaisOrig={profissionaisOrig}
                        statusFimTrein={statusFimTrein}
                        statusEditTrein={statusEditTrein}
                    />
                )}

                {profissionais?.length <= 0 && <ResultNotFound />}

                {/* Marcar todos */}
                {profissionais?.length > 0 && !limitEditPas && (
                    <>
                        <div
                            className={`${styles.marcarTodos} ${
                                checkPrimAval.length <= 0 || marcaReprovacao
                                    ? styles.marcarTodosDesabilitado
                                    : {}
                            }`}
                            onClick={() =>
                                statusFimTrein &&
                                checkPrimAval.length > 0 &&
                                !marcaReprovacao
                                    ? handleDefinirTodosPresentes()
                                    : {}
                            }
                        >
                            <div>
                                {statusFimTrein &&
                                marcarTodos &&
                                !marcaReprovacao ? (
                                    <img src={ImageChecked} alt="Imagem" />
                                ) : (
                                    <span />
                                )}
                                <span>
                                    Todos os profissionais compareceram, e foram
                                    aprovados neste treinamento.
                                </span>
                            </div>
                        </div>

                        <div className={styles.containerBtn}>
                            <button
                                onClick={() =>
                                    checkButtonSubmit ? handleSubmit() : {}
                                }
                                className={
                                    !checkButtonSubmit
                                        ? styles.buttonSearchBlock
                                        : styles.buttonSave
                                }
                                disabled={!checkButtonSubmit}
                                type="button"
                            >
                                <span>{`${
                                    statusEditTrein ? "Salvar edição" : "Salvar"
                                }`}</span>
                            </button>
                        </div>
                    </>
                )}
            </BoxData>

            <BoxData style={{ marginTop: "32px", marginBottom: "88px" }}>
                {/* Profissionais Faltosos */}
                <div className={styles.tituloJust}>Justificativas de falta</div>

                {profissionaisFaltosos?.length > 0 && (
                    <ListTableJustificativas
                        currentItems={profissionaisFaltosos}
                        situacao={situacao}
                        handleModalReabrirDatas={handleModalReabrirDatas}
                    />
                )}

                {profissionaisFaltosos?.length <= 0 && <ResultNotFound />}
            </BoxData>

            {/* Modal Justificativa da Reprovação (Operador:post)*/}
            <ModalJustCustom
                title="Justificativa da reprovação"
                description=""
                openModal={openModal || openModalJustificativa}
                onClick={() =>
                    openModalJustificativa ? openEdit() : handleJustificativa()
                }
                setOpenModal={() =>
                    mostrarHistModal ? closeModal() : retornaHist(modalPres)
                }
                onClickCancel={() =>
                    mostrarHistModal ? closeModal() : retornaHist(modalPres)
                }
                statusClickCancel={true}
                labelCancel="Voltar"
                labelConfirm={openModalJustificativa ? "Editar" : btnJust}
                disabled={checkLimitEdit}
                color="blue"
                removeBtnConf={checkDisabtrein}
            >
                <div className={styles.containerGeralJust}>
                    <Form.Group>
                        <span className={styles.textLabel}>Nome</span>
                        <Form.Control
                            type="text"
                            placeholder="Nome"
                            name="nome"
                            id="nome"
                            value={nome}
                            disabled
                            className={styles.containerInputFull}
                        />
                    </Form.Group>
                    {/* Não salva no Banco essa informação */}
                    <Form.Group>
                        <span
                            className={`${styles.textLabel} ${styles.dividerSpace}`}
                        >
                            Justificativa
                        </span>
                        <div
                            className={
                                openModalJustificativa
                                    ? styles.fieldSelectDisabled
                                    : styles.fieldSelect
                            }
                        >
                            <Form.Select
                                aria-label="categoryOption"
                                as="select"
                                value={justificativaSelect}
                                disabled={openModalJustificativa}
                                onChange={(e) => {
                                    setJustificativaSelect(e.target.value);
                                    setJustificativa(
                                        e.target.value === "outro"
                                            ? ""
                                            : e.target.value,
                                    );
                                }}
                            >
                                <option
                                    value="0"
                                    key="0"
                                    disabled
                                    style={{ color: "gray" }}
                                >
                                    Escolha uma Justificativa
                                </option>
                                <option
                                    value="Não compareceu ao treinamento"
                                    key="1"
                                >
                                    Não compareceu ao treinamento
                                </option>
                                <option
                                    value="Apresentou conduta inadequada"
                                    key="2"
                                >
                                    Apresentou conduta inadequada
                                </option>
                                <option value="Desistência" key="3">
                                    Desistência
                                </option>
                                <option value="outro" key="4">
                                    Outro
                                </option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                    {justificativaSelect === "outro" ? (
                        <Form.Group>
                            <Form.Label
                                className={`${styles.textLabel} ${styles.dividerSpace}`}
                            >
                                Outro
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Justificativa"
                                name="justificativa"
                                id="justificativa"
                                value={justificativa}
                                maxLength={200}
                                className={`${styles.textAreaComment} ${
                                    openModalJustificativa
                                        ? styles.textAreaCommentDisabled
                                        : {}
                                }`}
                                onChange={(e) => {
                                    saveJust(e.target.value);
                                }}
                                disabled={openModalJustificativa}
                            />
                            <div className={styles.containerOpOutro}>
                                {dataOperator()}
                                <div>
                                    <span>{justificativa.length}</span>
                                    <span>/200 caracteres</span>
                                </div>
                            </div>
                        </Form.Group>
                    ) : (
                        <div className={styles.containerOpOutro}>
                            {dataOperator()}
                        </div>
                    )}
                </div>
            </ModalJustCustom>

            <ModalJustificativa
                styles={{
                    minHeight: "536px",
                    width: "480px",
                }}
                title="Justificativa de falta"
                description=""
                openModal={openModalReabrirDatas}
                setOpenModal={closeModalReabrirDatas}
                onClick={() => handleReabrirDatas()}
                color="blue"
                labelConfirm="Confirmar"
                disabled={checkDisabtrein}
                removeBtnConf={limitEditPas}
            >
                <div className={styles.containerJust}>
                    <span className={styles.label}>Justificativa</span>
                    <Form.Control
                        as="textarea"
                        placeholder="Aguardando justificativa..."
                        rows={3}
                        name="jfalta"
                        id="jfalta"
                        value={justificativaFalta ? justificativaFalta : ""}
                        className={styles.textAreaComment}
                        disabled={true}
                    />
                    <span className={styles.labelOpenDate}>
                        Reabrir datas para o profissional?
                    </span>
                    <div className={styles.containerChackBox}>
                        <MyCheckbox
                            checked={reabrirDatas === true}
                            onClick={(e) => setReabrirDatas(true)}
                            disabled={checkDisabtrein}
                            icon={
                                <Icon
                                    size={24}
                                    icon={ic_check_box_outline_blank}
                                    style={{
                                        color: checkDisabtrein
                                            ? "#D8D9DA"
                                            : "#FFFF",
                                        background: checkDisabtrein
                                            ? "#D8D9DA"
                                            : "#FFF",
                                        border: `2px solid ${
                                            checkDisabtrein
                                                ? "#8D8F90"
                                                : "#0064B4"
                                        }`,
                                        borderRadius: "4px",
                                    }}
                                />
                            }
                            checkedIcon={
                                <Icon
                                    size={24}
                                    icon={ic_check_outline}
                                    style={{
                                        color: "#FFF",
                                        background: checkDisabtrein
                                            ? "#8D8F90"
                                            : "#0064B4",
                                        border: `2px solid ${
                                            checkDisabtrein
                                                ? "#908d8d"
                                                : "#0064B4"
                                        }`,
                                        borderRadius: "4px",
                                    }}
                                />
                            }
                        >
                            <span className={styles.textCheckBox}>Sim</span>
                        </MyCheckbox>
                    </div>
                    <div className={styles.containerChackBox}>
                        <MyCheckbox
                            checked={reabrirDatas === false}
                            onClick={(e) => setReabrirDatas(false)}
                            disabled={checkDisabtrein}
                            icon={
                                <Icon
                                    size={24}
                                    icon={ic_check_box_outline_blank}
                                    style={{
                                        color: checkDisabtrein
                                            ? "#D8D9DA"
                                            : "#FFFF",
                                        background: checkDisabtrein
                                            ? "#D8D9DA"
                                            : "#FFF",
                                        border: `2px solid ${
                                            checkDisabtrein
                                                ? "#8D8F90"
                                                : "#0064B4"
                                        }`,
                                        borderRadius: "4px",
                                    }}
                                />
                            }
                            checkedIcon={
                                <Icon
                                    size={24}
                                    icon={ic_check_outline}
                                    style={{
                                        color: "#FFF",
                                        background: checkDisabtrein
                                            ? "#8D8F90"
                                            : "#0064B4",
                                        border: `2px solid ${
                                            checkDisabtrein
                                                ? "#8D8F90"
                                                : "#0064B4"
                                        }`,
                                        borderRadius: "4px",
                                    }}
                                />
                            }
                        >
                            <span className={styles.textCheckBox}>Não</span>
                        </MyCheckbox>
                    </div>
                </div>
            </ModalJustificativa>

            <ModalConfirm
                title="Salvar alterações"
                description="Existem alterações que não foram salvas. Deseja salvar antes de sair?"
                openModal={openModalConfirmUnload}
                setOpenModal={setOpenModalConfirmUnload}
                onClick={() => handleSubmit()}
                labelConfirm="Salvar"
                labelCancel="Sair"
                color="blue"
                labelXSair="nao"
            />

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};

export default ProfissionaisAvaliarTable;
