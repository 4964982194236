import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ListTable } from "../../../components/tables/servicoProfissional/listTable";
import { InputCustomData } from "./../../../components/inputs/inputs";
import { MultipleSelectCustom } from "./../../../components/select/multipleselectcheckmarks";
import { Api } from "./../../../providers/index";
import { searchInArray } from "./../../../utils/list/index";
import { CnpjMask } from "./../../../utils/mask/index";
import styles from "./styles.module.scss";

const ItemCustomField = {
    width: "400px !important",
    height: "56px",
    "& .MuiOutlinedInput-input": {
        padding: "16.5px 0px",
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiInputBase-input": {
        // width: "93%",
    },
    "& .MuiInputBase-root": {
        borderRadius: "8px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
    },
};

export default function Section3({ tabData }) {
    const navigate = useNavigate();
    const [mei, setMei] = useState("1");
    const [cnpj, setCnpj] = useState("");
    const [tipoAtendimento, setTipoAtendimento] = useState(0);
    const [animais, setAnimais] = useState(0);
    const [formaPagamentoSelect, setFormaPagamentoSelect] = useState([]);
    const [formaPagamentoList, setFormaPagamentoList] = useState([]);
    const [sociaisSelect, setSociaisSelect] = useState([]);
    const [sociaisList, setSociaisList] = useState([]);
    const [sociais, setSociais] = useState("");

    const [celular, setCelular] = React.useState("");
    /**
     * Tipo Atendimento
     * @returns {number}  Se: 0=> null, 1 => CPF, 2 => CPNJ, 3 => CPF E CNPJ
     */
    const tipoAtd = () => {
        let ifCpf = Boolean(tabData.flagAtendeCpf);
        let isCpnj = Boolean(tabData.flagAtendeCnpj);
        if (ifCpf && isCpnj) {
            return 3;
        } else {
            if (ifCpf && !isCpnj) return 1;
            if (!ifCpf && isCpnj) return 2;
        }
        return 0;
    };
    // SET VALUES
    useEffect(() => {
        setMei(Boolean(tabData.flagMei) ? 1 : 2);
        setAnimais(Boolean(tabData.animais) ? 1 : 2);
        setCnpj(tabData.cnpj);
        setTipoAtendimento(tipoAtd);
        setFormaPagamentoSelect(
            tabData.formaPagamentos.map(({ nome }) => nome),
        );
        setSociais(sociaisList.length > 0 ? 1 : 0);
        setSociaisSelect(tabData.programasSociais.map(({ nome }) => nome));
    }, []);

    useEffect(() => {
        setSociais(sociaisSelect.length > 0 ? 1 : 2);
    }, [sociaisSelect]);
    // ENDPOINTs
    useEffect(() => {
        try {
            Api.get("/forma-pagamento/").then((res) => {
                if (res.status === 200) {
                    setFormaPagamentoList(res.data);
                }
            });
            Api.get("/programa-social/").then((res) => {
                if (res.status === 200) {
                    setSociaisList(res.data);
                }
            });
        } catch (error) {
            // console.log(error);
        }
    }, []);
    // UPDATE
    const handleSubmit = (event) => {
        event.preventDefault();

        tabData.cnpj = cnpj;
        //Trata mei/Animais
        tabData.flagMei = Number(mei) === 1 ? true : false;
        tabData.animais = Number(animais) === 1 ? true : false;
        //TipoAtedimento: Default(reset)
        tabData.flagAtendeCpf = false;
        tabData.flagAtendeCnpj = false;
        if (tipoAtendimento === 3) {
            tabData.flagAtendeCpf = true;
            tabData.flagAtendeCnpj = true;
        } else {
            if (tipoAtendimento === 1) tabData.flagAtendeCpf = true;
            if (tipoAtendimento === 2) tabData.flagAtendeCnpj = true;
        }

        // Forma de pagamentos
        // formaPagamentoSelect;
        const trataForma = searchInArray(
            formaPagamentoList,
            formaPagamentoSelect,
        );
        tabData.formaPagamentos = trataForma;
        const trataSocial = searchInArray(sociaisList, sociaisSelect);
        // Trata programas Sociais
        tabData.programasSociais = trataSocial;

        Api.put(`/profissional/${tabData.id}/`, tabData)
            .then((res) => {
                if (res.status === 200)
                    toast.success("Profissional atualizado com Sucesso", {
                        toastId: "success",
                    });
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "error",
                });
            });
    };

    const handleAddService = () => {
        navigate(`/servicoprofissional/add/?id=${tabData.id}`);
    };

    const checkProgramSocial = (value) => {
        if (Number(value) === 2) {
            setSociaisSelect([]);
        }
        setSociais(value);
    };

    const checkQualProg = (data) => {
        setSociaisSelect(data);
        if (data.length <= 0) {
            setSociais("2");
        }
    };

    const checkButtonSubmit = true;

    const BoxData = ({ children }) => {
        return (
            <Box
                sx={{
                    width: "880px",
                    height: "100%",
                    background: "#F7F7F7",
                    borderRadius: "8px",
                    marginBottom: 7,
                }}
            >
                {children}
            </Box>
        );
    };

    return (
        <>
            {/* Form */}
            <Box
                sx={{
                    width: "880px",
                    height: "100%",
                    background: "#F7F7F7",
                    borderRadius: "8px",
                    marginBottom: 7,
                    paddingTop: "32px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <Grid
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                            }}
                        >
                            <Grid item>
                                <div className={styles.containerInfo}>
                                    <div>
                                        <span>
                                            É Microeemprendedor Individual?(MEI)
                                        </span>
                                        <select
                                            className={`form-select ${styles.formDropdown}`}
                                            value={mei}
                                            onChange={(e) => {
                                                setMei(e.target.value);
                                            }}
                                        >
                                            <option value="0" key="0" disabled>
                                                Selecione uma opção
                                            </option>
                                            <option key="1" value="1">
                                                Sim
                                            </option>
                                            <option key="2" value="2">
                                                Não
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className={`${styles.containerInfo} ${styles.marginTop}`}
                                >
                                    <div>
                                        <span>
                                            Trabalha em casa com animais
                                        </span>
                                        <select
                                            className={`form-select ${styles.formDropdown}`}
                                            value={animais}
                                            onChange={(e) => {
                                                setAnimais(e.target.value);
                                            }}
                                        >
                                            <option value="0" key="0" disabled>
                                                Selecione uma opção
                                            </option>
                                            <option key="1" value="1">
                                                Sim
                                            </option>
                                            <option key="2" value="2">
                                                Não
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className={`${styles.containerInfo} ${styles.marginTop}`}
                                >
                                    <div>
                                        <span>
                                            Cadastrado em programa social?
                                        </span>
                                        <select
                                            className={`form-select ${styles.formDropdown}`}
                                            value={sociais}
                                            onChange={(e) =>
                                                checkProgramSocial(
                                                    e.target.value,
                                                )
                                            }
                                            // disabled={true}
                                        >
                                            <option value="0" key="0" disabled>
                                                Selecione uma opção
                                            </option>
                                            <option key="1" value="1">
                                                Sim
                                            </option>
                                            <option key="2" value="2">
                                                Não
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className={`${styles.containerInfo} ${styles.marginTop}`}
                                >
                                    <div>
                                        <span>Forma de pagamento</span>
                                        <MultipleSelectCustom
                                            id="formapagamento"
                                            name="formapagamento"
                                            value={formaPagamentoSelect}
                                            onChange={setFormaPagamentoSelect}
                                            addIcon={"screen-custom"}
                                        >
                                            {formaPagamentoList}
                                        </MultipleSelectCustom>
                                    </div>
                                </div>
                            </Grid>
                            {/* Column Two */}
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div className={styles.containerInput}>
                                    <div>
                                        <span>CNPJ</span>
                                        <InputCustomData
                                            id="cnpj"
                                            name="cnpj"
                                            value={cnpj}
                                            fullWidth
                                            onChange={(e) => {
                                                setCnpj(e.target.value);
                                            }}
                                            InputProps={{
                                                inputComponent: CnpjMask,
                                            }}
                                            disabled={mei == "2" ? true : false}
                                            backgroundColor={
                                                mei == "2"
                                                    ? "#ECEDEE"
                                                    : "#FFFFFF"
                                            }
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`${styles.containerInfo} ${styles.marginTop}`}
                                >
                                    <div>
                                        <span>Tipo de atendimento</span>
                                        <select
                                            className={`form-select ${styles.formDropdown}`}
                                            value={tipoAtendimento}
                                            onChange={(e) => {
                                                setTipoAtendimento(
                                                    e.target.value,
                                                );
                                            }}
                                        >
                                            <option value="0" key="0" disabled>
                                                Selecione uma opção
                                            </option>
                                            {[
                                                {
                                                    id: 1,
                                                    nome: "Apenas pessoa física",
                                                },
                                                {
                                                    id: 2,
                                                    nome: "Apenas Jurídica",
                                                },
                                                {
                                                    id: 3,
                                                    nome: "Pessoa física e jurídica",
                                                },
                                            ].map(({ id, nome }) => (
                                                <option key={id} value={id}>
                                                    {nome}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className={`${styles.containerInfo} ${styles.marginTop}`}
                                >
                                    <div>
                                        <span>Qual?</span>
                                        <MultipleSelectCustom
                                            id="qual"
                                            name="qual"
                                            value={sociaisSelect}
                                            onChange={(e) => checkQualProg(e)}
                                            addIcon={"screen-custom"}
                                        >
                                            {sociaisList}
                                        </MultipleSelectCustom>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <div className={styles.containerInfoButtonService}>
                            <div className={styles.containerButton}>
                                <button
                                    className={
                                        !checkButtonSubmit
                                            ? styles.buttonSearchBlock
                                            : styles.buttonSearch
                                    }
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={false}
                                >
                                    <span>Salvar</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </Box>
            </Box>
            <Box sx={{ mt: "38px" }} />
            <BoxData>
                <Box sx={{ padding: 5 }}>
                    <div className={styles.textTop}>
                        <span>Serviços do profissional</span>
                        <span onClick={handleAddService}>
                            Adicionar serviço
                        </span>
                    </div>
                    <ListTable currentItems={tabData} />
                </Box>
            </BoxData>
        </>
    );
}
