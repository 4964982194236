import { Stack } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import image01 from "../../assets/images/icons/blue-bag.png";
import image05 from "../../assets/images/icons/blue-occurrences.png";
import image03 from "../../assets/images/icons/blue-training.png";
import imageLock01 from "../../assets/images/icons/lock-closed-01.png";
import imageLock02 from "../../assets/images/icons/lock-closed-02.png";
import imageUnid2 from "../../assets/images/icons/office-building-blue.png";
import imageUnid from "../../assets/images/icons/office-building.png";
import image02 from "../../assets/images/icons/white-bag.png";
import image06 from "../../assets/images/icons/white-occurrences.png";
import image04 from "../../assets/images/icons/white-training.png";
import m9 from "../../assets/images/m9.png";
import { UserContext } from "../../contexts/user";
import {
    DropDownMenuCategory,
    DropDownMenuConfiguracoes,
    DropDownMenuReports,
    DropDownMenuUser,
} from "../dropdown";
import styles from "./styles.module.scss";

const MySidebar = ({ currentScreen = 0 }) => {
    const { isLoggedUser } = useContext(UserContext);
    const navigate = useNavigate();
    const myperfil = React.createRef();

    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp =
        operador.operador.funcao.slug == "administrador" ? true : false;

    // *************************************************************************
    const permissoes = JSON.parse(localStorage.getItem("permissoes"));
    const permOcorrencias = permissoes?.find(
        (val) => val.tela.slug == "ocorrencias",
    );
    const permSolicitacoes = permissoes?.find(
        (val) => val.tela.slug == "solicitacoes",
    );
    const permTreinamentos = permissoes?.find(
        (val) => val.tela.slug == "treinamentos",
    );
    // *************************************************************************

    useEffect(() => {
        isLoggedUser
            ? (myperfil.current.style.display = "flex")
            : (myperfil.current.style.display = "flex");
    }, [isLoggedUser, myperfil]);

    const handleRequest = () => {
        navigate("/solicitacao");
    };

    const handleTraining = () => {
        navigate("/treinamento");
    };

    const handleOccurrences = () => {
        navigate("/ocorrencias");
    };

    const handleExit = () => {
        navigate("/sair");
    };

    const handleUnit = () => {
        navigate("/unidade");
    };

    const handleAudit = () => {
        navigate("/auditoria");
    };

    //currentScreen
    // 1 - Clientes
    // 2 - Profissionais
    // 3 - Operadores
    // 4 - Solicitacao
    // 5 - Categorias
    // 6 - Serviços
    // 7 - Tipo de Serviço
    // 8 - Treinamentos
    // 9 - Ocorrências
    // 10 - Produtividade
    // 11 - Unidades
    // 12 - Auditoria
    // 13 - Habilitar novos cadastros
    // 14 - Relatório: Serviços e Categorias
    // 15 - Relatório: Clientes por município
    // 16 - Serviço de e-mail

    return (
        <div className="menu" id="sidebarmenu" ref={myperfil}>
            <div className={`flex-column ${styles.menuSidebar}`}>
                <Nav navbarScroll={true}>
                    {/*    eventKey="1" */}
                    <DropDownMenuUser currentScreen={currentScreen} />

                    {/* ********************************************************** */}
                    {(checkOp || permSolicitacoes?.visualizar) && (
                        <Nav.Link
                            eventKey="2"
                            className={`${styles.menuItem} ${
                                currentScreen === 4
                                    ? styles.menuSidebarOn
                                    : styles.menuSidebarOff
                            }`}
                            onClick={() => handleRequest()}
                        >
                            <Stack>
                                <div className={styles.containerImageText}>
                                    <span>
                                        <img
                                            src={
                                                currentScreen === 4
                                                    ? image02
                                                    : image01
                                            }
                                            className={styles.iconImage}
                                            alt="menu"
                                        />
                                    </span>
                                    <span className="menuText">
                                        Solicitações
                                    </span>
                                </div>
                            </Stack>
                        </Nav.Link>
                    )}

                    {/* ********************************************************** */}

                    {(checkOp || permOcorrencias?.visualizar) && (
                        <Nav.Link
                            eventKey="2"
                            className={`${styles.menuItem} ${
                                currentScreen === 9
                                    ? styles.menuSidebarOn
                                    : styles.menuSidebarOff
                            }`}
                            onClick={() => handleOccurrences()}
                        >
                            <Stack>
                                <div className={styles.containerImageText}>
                                    <span>
                                        <img
                                            src={
                                                currentScreen === 9
                                                    ? image06
                                                    : image05
                                            }
                                            className={styles.iconImage}
                                            alt="menu"
                                        />
                                    </span>
                                    <span className="menuText">
                                        Ocorrências
                                    </span>
                                </div>
                            </Stack>
                        </Nav.Link>
                    )}

                    {/* ********************************************************** */}

                    {/*    eventKey="4" */}
                    <DropDownMenuCategory currentScreen={currentScreen} />

                    {/* ********************************************************** */}
                    {(checkOp || permTreinamentos?.visualizar) && (
                        <Nav.Link
                            eventKey="5"
                            className={`${styles.menuItem} ${
                                currentScreen === 8
                                    ? styles.menuSidebarOn
                                    : styles.menuSidebarOff
                            }`}
                            onClick={() => handleTraining()}
                        >
                            <Stack>
                                <div className={styles.containerImageText}>
                                    <span>
                                        <img
                                            src={
                                                currentScreen === 8
                                                    ? image04
                                                    : image03
                                            }
                                            className={styles.iconImage}
                                            alt="menu"
                                        />
                                    </span>
                                    <span className="menuText">
                                        Treinamentos
                                    </span>
                                </div>
                            </Stack>
                        </Nav.Link>
                    )}

                    {/* ********************************************************** */}

                    <Nav.Link
                        eventKey="5"
                        className={`${styles.menuItem} ${
                            currentScreen === 11
                                ? styles.menuSidebarOn
                                : styles.menuSidebarOff
                        }`}
                        onClick={handleUnit}
                    >
                        <Stack>
                            <div className={styles.containerImageText}>
                                <span>
                                    <img
                                        src={
                                            currentScreen === 11
                                                ? imageUnid
                                                : imageUnid2
                                        }
                                        className={styles.iconImage}
                                        alt="menu"
                                    />
                                </span>
                                <span className="menuText">Unidades</span>
                            </div>
                        </Stack>
                    </Nav.Link>

                    {/* ********************************************************** */}

                    {/*Relatorio*/}
                    <DropDownMenuReports currentScreen={currentScreen} />

                    {/* ********************************************************** */}

                    {checkOp && (
                        <Nav.Link
                            eventKey="5"
                            className={`${styles.menuItem} ${
                                currentScreen === 12
                                    ? styles.menuSidebarOn
                                    : styles.menuSidebarOff
                            }`}
                            onClick={handleAudit}
                        >
                            <Stack>
                                <div className={styles.containerImageText}>
                                    <span>
                                        <img
                                            src={
                                                currentScreen === 12
                                                    ? imageLock02
                                                    : imageLock01
                                            }
                                            className={styles.iconImage}
                                            alt="menu"
                                        />
                                    </span>
                                    <span className="menuText">Auditoria</span>
                                </div>
                            </Stack>
                        </Nav.Link>
                    )}

                    {/* ********************************************************** */}

                    {checkOp && (
                        <DropDownMenuConfiguracoes
                            currentScreen={currentScreen}
                        />
                    )}

                    {/* ********************************************************** */}

                    <Nav.Link
                        eventKey="9"
                        className={`${styles.menuItem} ${styles.menuSidebarOff}`}
                        onClick={() => handleExit()}
                    >
                        <Stack>
                            <div className={styles.containerImageText}>
                                <span>
                                    <img src={m9} alt="menu" />
                                </span>
                                <span className={`menuText ${styles.textExit}`}>
                                    Sair
                                </span>
                            </div>
                        </Stack>
                    </Nav.Link>
                </Nav>
            </div>
        </div>
    );
};

export default MySidebar;
