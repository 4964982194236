import { useContext } from "react";
import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
    useLocation,
} from "react-router-dom";
/* Deixar pastas com as letras em minúscula */
import MakerPDF from "./components/pdf/maker/index";
import { AuthContext, AuthProvider } from "./contexts/auth";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import CriarCategoriaPage from "./pages/categoria/CriarCategoriaPage";
import DashboardDeCategoria from "./pages/categoria/DashboardCategoria/dashboardCategoria";
import EditarCategoriaPage from "./pages/categoria/EditarCategoriaPage";
import DashboardCliente from "./pages/cliente/DashboadCliente";
import EditarClientePage from "./pages/cliente/EditarCliente";
import CadastrarPage from "./pages/operador/CadastroOperadorPage";
import CadastroOperadorPage from "./pages/operador/CadastroOperadorPage/index";
import DashboardOperadores from "./pages/operador/DashboardOperador";
import EditarOperadorPage from "./pages/operador/EditarOperador";
import EditarPerfilPage from "./pages/operador/EditarPerfil";
import DashboardProfissional from "./pages/profissional/DashboardProfissional";
import EditarProfissional from "./pages/profissional/EditarProfissional";
import InativarProfissionalPage from "./pages/profissional/InativarProfissionalPage";
import ProfissionaisExcluidosPage from "./pages/profissional/ProfissionaisExcluidosPage";
import Agenda from "./pages/profissional/ViewAgenda";
import EditarAgenda from "./pages/profissional/ViewAgenda/edit";
import ViewDocuments from "./pages/profissional/ViewDocuments";
import RedefinirSenhaPage from "./pages/redefinirSenhaPage";
import TrocaSenhaPage from "./pages/redefinirSenhaPage/indexReseta";
import SairPage from "./pages/sairPage/index";
import CriarServicoPage from "./pages/servico/CriarServicoPage";
import DashBoardServico from "./pages/servico/DashboardServico";
import EditarServicoPage from "./pages/servico/EditarServicoPage";
import ServicoProfissionalAdd from "./pages/servicoProfissional/add";
import ServicoProfissionalEdit from "./pages/servicoProfissional/edit";
import AceitarSolicitacaoServicoPage from "./pages/solicitacao/AceitarSolicitacao";
import CriarSolicitacao from "./pages/solicitacao/CriarSolicitacao/";
import DashboardSolicitacao from "./pages/solicitacao/DashboardSolicitacao/";
import HistoricoSolicitacaoServicoPage from "./pages/solicitacao/HistoricoSolicitacao";
import CriarTipoServicoPage from "./pages/tiposervico/CreateTipoServicoPage/index";
import DashboardTipoServicoPage from "./pages/tiposervico/DashboardTipoServicoPage";
import EditarTipoServicoPage from "./pages/tiposervico/EditarTipoServicoPage";
import CriarTreinamento from "./pages/treinamento/CriarTreinamento/index";
import DashboardTreinamento from "./pages/treinamento/DashboardTreinamento/index";
import EditarTreinamento from "./pages/treinamento/EditarTreinamento";
import TurmaTreinamentoPage from "./pages/treinamento/TurmaTreinamento";

import DashboardAuditoria from "./pages/auditoria/DashboardAuditoria";
import VisualizarAuditoriaPage from "./pages/auditoria/VisualizarAuditoriaPage";
import HabilitarNovoCadastro from "./pages/configuracoes/HabilitarNovoCadastro";
import ServicoEmail from "./pages/configuracoes/ServicoEmail";
import DashboardOcorrencias from "./pages/ocorrencia/DashboardOcorrencias";
import EditarOcorrencia from "./pages/ocorrencia/EditarOcorrencia/";
import EditPontuacao from "./pages/ocorrencia/EditarOcorrencia/editPontuacao";
import NovaOcorrencia from "./pages/ocorrencia/NovaOcorrencia/";
import DashboardReportProd from "./pages/relatorios/Dashboard";
import RelatClientesMunicipio from "./pages/relatorios/RelatClientesMunicipio";
import RelatServicoCategoria from "./pages/relatorios/RelatServicoCategoria";
import CriarUnidadePage from "./pages/unidade/CriarUnidadePage";
import DashBoardUnidade from "./pages/unidade/DashboardUnidade";
import EditarUnidadePage from "./pages/unidade/EditarUnidadePage";

const AppRoutes = () => {
    const Private = ({ children }) => {
        const { authenticated } = useContext(AuthContext);
        let location = useLocation();
        if (!authenticated) {
            localStorage.clear();
            //Important!
            return <Navigate to="/login" state={{ from: location }} replace />;
        }
        return children;
    };

    const operador = JSON.parse(localStorage.getItem("user"));
    const permissoes = JSON.parse(localStorage.getItem("permissoes"));
    const checkOp =
        operador?.operador?.funcao?.slug == "administrador" ? true : false;

    const permCliente = permissoes?.find((val) => val.tela.slug == "clientes");
    const permOcorrencias = permissoes?.find(
        (val) => val.tela.slug == "ocorrencias",
    );
    const permProfissionais = permissoes?.find(
        (val) => val.tela.slug == "profissionais",
    );
    const permSolicitacoes = permissoes?.find(
        (val) => val.tela.slug == "solicitacoes",
    );
    const permTreinamentos = permissoes?.find(
        (val) => val.tela.slug == "treinamentos",
    );

    // console.log("dados app: ", permCliente);

    return (
        <Router>
            <AuthProvider>
                <Routes>
                    {/* ***Private Pages*** */}
                    <Route
                        exact
                        path="/"
                        element={
                            <Private>
                                <HomePage />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/sair"
                        element={
                            <Private>
                                <SairPage />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/categoria"
                        element={
                            <Private>
                                <DashboardDeCategoria />
                            </Private>
                        }
                    />
                    <Route
                        path="/categoria/criar"
                        element={
                            <Private>
                                <CriarCategoriaPage />
                            </Private>
                        }
                    />
                    <Route
                        path="/categoria/editar"
                        element={
                            <Private>
                                <EditarCategoriaPage />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/servico"
                        element={
                            <Private>
                                <DashBoardServico />
                            </Private>
                        }
                    />
                    <Route
                        path="/servico/criar"
                        element={
                            <Private>
                                <CriarServicoPage />
                            </Private>
                        }
                    />
                    <Route
                        path="/servico/editar"
                        element={
                            <Private>
                                <EditarServicoPage />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/tipo-servico/criar"
                        element={
                            <Private>
                                <CriarTipoServicoPage />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/tipo-servico"
                        element={
                            <Private>
                                <DashboardTipoServicoPage />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/tipo-servico/editar"
                        element={
                            <Private>
                                <EditarTipoServicoPage />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/operador/cadastro"
                        element={
                            <Private>
                                <CadastroOperadorPage />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/operador/editar"
                        element={
                            <Private>
                                <EditarOperadorPage />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/operador"
                        element={
                            <Private>
                                <DashboardOperadores />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/perfil/editar"
                        element={
                            <Private>
                                <EditarPerfilPage />
                            </Private>
                        }
                    />
                    {/* Profissionais */}
                    {(checkOp || permProfissionais?.visualizar) && (
                        <>
                            <Route
                                exact
                                path="/profissional"
                                element={
                                    <Private>
                                        <DashboardProfissional />
                                    </Private>
                                }
                            />
                            {/* Profissionais Agenda*/}
                            {(checkOp || permProfissionais?.atualizar) && (
                                <>
                                    <Route
                                        exact
                                        path="/profissional/agenda"
                                        element={
                                            <Private>
                                                <Agenda />
                                            </Private>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/profissional/agenda/editar"
                                        element={
                                            <Private>
                                                <EditarAgenda />
                                            </Private>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/profissional/editar"
                                        element={
                                            <Private>
                                                <EditarProfissional />
                                            </Private>
                                        }
                                    />

                                    <Route
                                        exact
                                        path="/profissional/excluidos"
                                        element={
                                            <Private>
                                                <ProfissionaisExcluidosPage />
                                            </Private>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/profissional/inativar"
                                        element={
                                            <Private>
                                                <InativarProfissionalPage />
                                            </Private>
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}
                    <Route
                        exact
                        path="/viewdocuments"
                        element={
                            <Private>
                                <ViewDocuments />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/servicoprofissional/"
                        element={
                            <Private>
                                <ServicoProfissionalEdit />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/servicoprofissional/add"
                        element={
                            <Private>
                                <ServicoProfissionalAdd />
                            </Private>
                        }
                    />

                    {(checkOp || permTreinamentos?.visualizar) && (
                        <>
                            {/* Treinamentos */}
                            <Route
                                exact
                                path="/treinamento/"
                                element={
                                    <Private>
                                        <DashboardTreinamento />
                                    </Private>
                                }
                            />
                            {(checkOp || permTreinamentos?.criar) && (
                                <Route
                                    exact
                                    path="/treinamento/criar"
                                    element={
                                        <Private>
                                            <CriarTreinamento />
                                        </Private>
                                    }
                                />
                            )}

                            {(checkOp || permTreinamentos?.atualizar) && (
                                <>
                                    <Route
                                        exact
                                        path="/treinamento/editar"
                                        element={
                                            <Private>
                                                <EditarTreinamento />
                                            </Private>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/treinamento/turma"
                                        element={
                                            <Private>
                                                <TurmaTreinamentoPage />
                                            </Private>
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}

                    {(checkOp || permCliente?.visualizar) && (
                        <>
                            <Route
                                exact
                                path="/cliente"
                                element={
                                    <Private>
                                        <DashboardCliente />
                                    </Private>
                                }
                            />

                            {(checkOp || permCliente?.atualizar) && (
                                <Route
                                    exact
                                    path="/cliente/editar"
                                    element={
                                        <Private>
                                            <EditarClientePage />
                                        </Private>
                                    }
                                />
                            )}
                        </>
                    )}
                    {(checkOp || permSolicitacoes?.visualizar) && (
                        <>
                            {/* Solicitações / Ocorrências */}
                            <Route
                                exact
                                path="/solicitacao"
                                element={
                                    <Private>
                                        <DashboardSolicitacao />
                                    </Private>
                                }
                            />
                            {(checkOp || permSolicitacoes?.criar) && (
                                <Route
                                    exact
                                    path="/solicitacao/add"
                                    element={
                                        <Private>
                                            <CriarSolicitacao />
                                        </Private>
                                    }
                                />
                            )}

                            {(checkOp || permSolicitacoes?.atualizar) && (
                                <>
                                    <Route
                                        exact
                                        path="/solicitacao/historico"
                                        element={
                                            <Private>
                                                <HistoricoSolicitacaoServicoPage />
                                            </Private>
                                        }
                                    />

                                    <Route
                                        exact
                                        path="/aceitar-solicitacao"
                                        element={
                                            <Private>
                                                <AceitarSolicitacaoServicoPage />
                                            </Private>
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}

                    {(checkOp || permOcorrencias?.visualizar) && (
                        <>
                            <Route
                                exact
                                path="/ocorrencias"
                                element={
                                    <Private>
                                        <DashboardOcorrencias />
                                    </Private>
                                }
                            />
                            {(checkOp || permOcorrencias?.criar) && (
                                <Route
                                    exact
                                    path="/ocorrencias/add"
                                    element={
                                        <Private>
                                            <NovaOcorrencia />
                                        </Private>
                                    }
                                />
                            )}

                            {(checkOp || permOcorrencias?.atualizar) && (
                                <>
                                    <Route
                                        exact
                                        path="/ocorrencias/edit"
                                        element={
                                            <Private>
                                                <EditarOcorrencia />
                                            </Private>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/ocorrencias/detalhamento-ocorrencia/view"
                                        element={
                                            <Private>
                                                <EditPontuacao />
                                            </Private>
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}
                    <Route
                        exact
                        path="/relatorios/produtividade"
                        element={
                            <Private>
                                <DashboardReportProd />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/relatorios/categorias-e-servicos"
                        element={
                            <Private>
                                <RelatServicoCategoria />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/relatorios/clientes-municipio"
                        element={
                            <Private>
                                <RelatClientesMunicipio />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/unidade"
                        element={
                            <Private>
                                <DashBoardUnidade />
                            </Private>
                        }
                    />
                    <Route
                        path="/unidade/criar"
                        element={
                            <Private>
                                <CriarUnidadePage />
                            </Private>
                        }
                    />
                    <Route
                        path="/unidade/editar"
                        element={
                            <Private>
                                <EditarUnidadePage />
                            </Private>
                        }
                    />
                    {checkOp && (
                        <Route
                            exact
                            path="/auditoria"
                            element={
                                <Private>
                                    <DashboardAuditoria />
                                </Private>
                            }
                        />
                    )}
                    {checkOp && (
                        <Route
                            path="/auditoria/view"
                            element={
                                <Private>
                                    <VisualizarAuditoriaPage />
                                </Private>
                            }
                        />
                    )}
                    {checkOp && (
                        <Route
                            exact
                            path="/habilitar-novos-cadastros"
                            element={
                                <Private>
                                    <HabilitarNovoCadastro />
                                </Private>
                            }
                        />
                    )}
                    {checkOp && (
                        <Route
                            exact
                            path="/servico-de-email"
                            element={
                                <Private>
                                    <ServicoEmail />
                                </Private>
                            }
                        />
                    )}
                    {/* ***Public Pages*** */}
                    <Route exact path="/pdf/maker" element={<MakerPDF />} />
                    <Route exact path="/login" element={<LoginPage />} />
                    <Route
                        exact
                        path="/cadastrar"
                        element={<CadastrarPage />}
                    />
                    <Route exact path="/resetar" element={<TrocaSenhaPage />} />
                    <Route
                        exact
                        path="/redefinir"
                        element={<RedefinirSenhaPage />}
                    />
                    <Route path="*" element={<Page404 />} />
                    {/* <Route path="/assets/images/" /> */}
                </Routes>
            </AuthProvider>
        </Router>
    );
};

export default AppRoutes;
