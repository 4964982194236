import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "../../contexts/user";
import Navbar from "./navbar/index";

const LayoutNavbar = () => {
    const { statusScreenDoc } = useContext(UserContext);

    if (statusScreenDoc) {
        return <></>;
    }
    return (
        <Grid item sx={{ width: "100%" }}>
            <Navbar />
        </Grid>
    );
};

export default LayoutNavbar;
