import React from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Pontuacao } from "../../../components/tables/pontuacao";
import {
    OccurrencesProvider,
    ProfissionaisProvider,
} from "../../../contexts/service";

//Documents
export default function Section10() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    return (
        <ProfissionaisProvider>
            <OccurrencesProvider>
                <Container fluid className="justify-content-center">
                    <Pontuacao idQuery={idQuery} />
                </Container>
            </OccurrencesProvider>
        </ProfissionaisProvider>
    );
}
