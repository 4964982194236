import { Container, Grid } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadCrumb } from "../../../components/breadcrumb/index";
import { ModalJustificativa, ModalLoading } from "../../../components/modal";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import { Api } from "../../../providers";
import { ConvertCurrency } from "../../../utils/currency";
import { DateBR } from "../../../utils/date";
import { TelMaskRegex, ZipCodeMask } from "../../../utils/mask";
import { ClientesProfissionaisProvider } from "./../../../contexts/service";
import styles from "./styles.module.scss";

export default function EditarOcorrencia() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const { setCurrentScreen } = useContext(AuthContext);
    const [loadingModal, setLoadingModal] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [descricao, setDescricao] = useState("");
    const [descricaoCancel, setDescricaoCancel] = useState("");
    const [textDescError, setTextDescError] = useState("Campo obrigatório");
    const [textDescErrorCancel, setTextDescErrorCancel] =
        useState("Campo obrigatório");
    const [statusButton, setStatusButton] = useState(true);
    const [statusButtonCancel, setStatusButtonCancel] = useState(true);
    const [currentDateTime, setCurrentDateTime] = useState("");
    const [timestamp, setTimestamp] = useState(0);
    const [statusOpenModal, setStatusOpenModal] = useState(false);
    const [score, setScore] = useState("Sim");
    const [updateData, setUpdateData] = useState(false);
    const [openModalCancelar, setOpenModalCancelar] = useState(false);

    let mounted = true;
    const operador = JSON.parse(localStorage.getItem("user"));

    const statusChange = () => {
        Api.put(`/ocorrencia/${idQuery}/vizualizar`)
            .then((res) => {
                if (mounted && res.status == 200) {
                    setUpdateData(!updateData);
                    setLoadingModal(false);
                }
            })
            .catch((error) => {
                if (mounted) {
                    setLoadingModal(false);
                    const checkErro = error;
                    toast.error(
                        `Ops! ${checkErro.response.data.message?.error}`,
                        {
                            toastId: "msg",
                        },
                    );
                }
            });
    };

    const resultRequest = async () => {
        try {
            await Promise.all([
                Api.get(`ocorrencia/${idQuery}`).then((res) => {
                    if (mounted && res.status === 200) {
                        if (res.data.status === "Enviada") {
                            statusChange();
                        } else {
                            setLoadingModal(false);
                            setInfoData(res.data);
                            if (res.data.status === "Finalizada") {
                                setScore(res.data.pontuar ? "Sim" : "Não");
                                setDescricao(res.data.parecer ?? "");
                                setTextDescError(
                                    res.data.parecer ? "" : "Campo obrigatório",
                                );
                                if (
                                    operador.operador.id == res.data.operador.id
                                ) {
                                    setStatusButton(false);
                                }
                            }
                        }
                    }
                }),

                Api.get("/system/date").then((res) => {
                    if (mounted) {
                        setCurrentDateTime(
                            res.data.dateBr + " " + res.data.hoursBr,
                        );
                        setTimestamp(res.data.timestamp);
                    }
                }),
            ]);
        } catch (_) {
            if (mounted) {
                setLoadingModal(false);
            }
        }
    };

    useEffect(() => {
        setCurrentScreen(9);
        setLoadingModal(true);
        resultRequest();
        return () => {
            mounted = false;
        };
    }, [updateData]);

    const submitData = () => {
        setLoadingModal(true);
        const dataSend = {
            id: infoData.id,
            status: infoData.status,
            descricao: infoData.descricao,
            dataCadastro: timestamp,
            pontuacao: infoData.ocorrenciaTipo?.pontuacao,
            tarefaId: infoData.tarefaId,
            pontuar: score == "Sim" ? true : false,
            tarefa: {},
            ocorrenciaTipoId: infoData.ocorrenciaTipo?.id,
            ocorrenciaTipo: {},
            profissionalId: infoData.tarefa?.profissionais[0].id,
            clienteId: infoData.tarefa?.cliente.id,
            solicitante: infoData.solicitante,
            unidade: {},
            operadorId: operador.operador.id,
            operador: {},
            parecer: descricao,
            dataParecer: timestamp,
        };

        Api.put(`/ocorrencia/${idQuery}/finalizar`, dataSend)
            .then((res) => {
                if (mounted && res.status === 200) {
                    setLoadingModal(false);
                    setStatusOpenModal(false);
                    setUpdateData(!updateData);
                    toast.success("Ocorrência finalizada com sucesso", {
                        toastId: "success",
                    });
                }
            })
            .catch((error) => {
                if (mounted) {
                    setLoadingModal(false);
                    setStatusOpenModal(false);
                    const checkErro = error;
                    toast.error(
                        `Ops! ${checkErro.response.data.message?.error}`,
                        {
                            toastId: "msg",
                        },
                    );
                }
            });
    };

    const submitCancelarData = () => {
        setLoadingModal(true);
        const dataSend = {
            id: infoData.id,
            status: infoData.status,
            descricao: infoData.descricao,
            dataCadastro: timestamp,
            pontuacao: infoData.ocorrenciaTipo?.pontuacao,
            tarefaId: infoData.tarefaId,
            pontuar: score == "Sim" ? true : false,
            tarefa: {},
            ocorrenciaTipoId: infoData.ocorrenciaTipo?.id,
            ocorrenciaTipo: {},
            profissionalId: infoData.tarefa?.profissionais[0].id,
            clienteId: infoData.tarefa?.cliente.id,
            solicitante: infoData.solicitante,
            unidade: {},
            operadorId: operador.operador.id,
            operador: {},
            parecer: descricaoCancel,
            dataParecer: timestamp,
        };

        Api.put(`/ocorrencia/${idQuery}/cancelar`, dataSend)
            .then((res) => {
                if (mounted && res.status === 200) {
                    setLoadingModal(false);
                    setStatusOpenModal(false);
                    setUpdateData(!updateData);
                    setOpenModalCancelar(false);
                    toast.success("Ocorrência cancelada com sucesso", {
                        toastId: "success",
                    });
                }
            })
            .catch((error) => {
                if (mounted) {
                    setLoadingModal(false);
                    setStatusOpenModal(false);
                    const checkErro = error;
                    toast.error(
                        `Ops! ${checkErro.response.data.message?.error}`,
                        {
                            toastId: "msg",
                        },
                    );
                }
            });
    };

    const serviceValue = infoData.tarefa?.valor
        ? ConvertCurrency(infoData.tarefa?.valor)
        : ConvertCurrency(infoData.tarefa?.servico.servicoValor);
    const telClient = infoData?.tarefa?.cliente.telefoneCelular
        ? TelMaskRegex(infoData?.tarefa?.cliente.telefoneCelular)
        : "";
    const telProf = infoData.tarefa?.profissionais[0].telefoneCelular
        ? TelMaskRegex(infoData.tarefa?.profissionais[0].telefoneCelular)
        : "";
    const cepMask = infoData.tarefa?.endereco.cep
        ? ZipCodeMask(infoData.tarefa?.endereco.cep)
        : "";

    const getStatus = (status) => {
        switch (status) {
            case "Análise":
                return "Em análise";
            case "Enviada":
                return "Aberta";
            default:
                return status;
        }
    };

    function textDesc(desc) {
        if (desc.length <= 0) {
            setDescricao("");
            setTextDescError("Campo obrigatório");
            setStatusButton(true);
        } else {
            if (desc.length <= 1500 && desc.trim()) {
                setDescricao(desc);
                setTextDescError("");
                setStatusButton(false);
            }
        }
    }

    function textDescCancel(desc) {
        if (desc.length <= 0) {
            setDescricaoCancel("");
            setTextDescErrorCancel("Campo obrigatório");
            setStatusButtonCancel(true);
        } else {
            if (desc.length <= 1500 && desc.trim()) {
                setDescricaoCancel(desc);
                setTextDescErrorCancel("");
                setStatusButtonCancel(false);
            }
        }
    }

    const checkRequest = (text) => {
        setScore(text);
    };

    const validacaoTextoModalCancel =
        infoData.status == "Cancelada" ? "" : textDescErrorCancel;

    // ***********************************************************************

    return (
        <ClientesProfissionaisProvider>
            <Grid>
                <BreadCrumb
                    data={{
                        category: "Home > Solicitação",
                        page: `#${idQuery}`,
                        menu: {
                            item: "Ocorrências",
                            link: "/ocorrencias/",
                        },
                    }}
                />
                <Title title={`Ocorrência #${idQuery}`} />
                {/* **************************************************************** */}

                <div className={styles.containerGeral}>
                    <div className="justify-content-center align-items-center container">
                        <div className={`${styles.containerInput}`}>
                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Status da ocorrência
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control`}
                                            defaultValue={getStatus(
                                                infoData.status,
                                            )}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Serviço
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control`}
                                            defaultValue={
                                                infoData.tarefa?.servico.servico
                                                    .nome
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Data do serviço
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={DateBR(
                                                infoData.tarefa?.datas[0],
                                            )}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Hora do serviço
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={infoData.tarefa?.hora.substring(
                                                0,
                                                5,
                                            )}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Status do serviço
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa?.status
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Valor do serviço
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={serviceValue}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Solicitante
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={infoData.solicitante}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Tipo da ocorrência
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.ocorrenciaTipo?.nome
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Pontuação da Ocorrência
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.pontuacao === null
                                                    ? infoData.ocorrenciaTipo
                                                          ?.pontuacao
                                                    : infoData.pontuacao
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div />
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.textareaLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Observação
                                        </label>
                                        <textarea
                                            className="form-control"
                                            defaultValue={infoData.descricao}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`d-flex justify-content-between mt-2 mb-3 ${styles.containerOperator}`}
                                >
                                    <div className={styles.textOperator}>
                                        Usuário: {infoData.usuario?.nome} -{" "}
                                        {moment(infoData.dataCadastro).format(
                                            "DD/MM/YY",
                                        )}{" "}
                                        {moment(infoData.dataCadastro).format(
                                            "HH:mm",
                                        )}
                                    </div>
                                    <div>
                                        <span
                                            className={styles.characterNumber}
                                        >
                                            {infoData.descricao?.length}
                                        </span>
                                        <span
                                            className={styles.characterLetter}
                                        >
                                            /200 caracteres
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.divider} />
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Cliente
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa?.cliente.nome
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Telefone
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={telClient}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Profissional
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa
                                                    ?.profissionais[0].nome
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Telefone
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={telProf}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`mt-4 ${styles.divider}`} />
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            CEP
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={cepMask}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Endereço
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa?.endereco
                                                    .logradouro
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Número
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa?.endereco.numero
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Complemento
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                                infoData.tarefa?.endereco
                                                    .complemento
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${styles.containerRow}`}>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Bairro
                                        </label>
                                        <select
                                            className="form-select"
                                            defaultValue={
                                                infoData.tarefa?.endereco.bairro
                                            }
                                            disabled
                                        >
                                            <option>
                                                {
                                                    infoData.tarefa?.endereco
                                                        .bairro
                                                }
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className={`col ${styles.inputLine}`}>
                                    <div>
                                        <label className="form-label">
                                            Cidade
                                        </label>
                                        <select
                                            className="form-select"
                                            defaultValue={
                                                infoData.tarefa?.endereco.cidade
                                            }
                                            disabled
                                        >
                                            <option>
                                                {
                                                    infoData.tarefa?.endereco
                                                        .cidade
                                                }
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {infoData.status != "Cancelada" && (
                                <div
                                    className={
                                        infoData.status == "Finalizada"
                                            ? styles.containerButtonFlexEnd
                                            : styles.containerButton
                                    }
                                >
                                    {infoData.status != "Finalizada" ? (
                                        <>
                                            <div>Editar ocorrência</div>
                                            <div>
                                                <button
                                                    className={
                                                        styles.buttonSearchRed
                                                    }
                                                    type="button"
                                                    onClick={() =>
                                                        setOpenModalCancelar(
                                                            true,
                                                        )
                                                    }
                                                >
                                                    <span>
                                                        Cancelar ocorrência
                                                    </span>
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                    className={
                                                        styles.buttonSearch
                                                    }
                                                    type="button"
                                                    onClick={() =>
                                                        setStatusOpenModal(true)
                                                    }
                                                >
                                                    <span>
                                                        Finalizar ocorrência
                                                    </span>
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <div
                                            onClick={() =>
                                                operador.operador.id ==
                                                infoData.operador?.id
                                                    ? setStatusOpenModal(true)
                                                    : {}
                                            }
                                            className={styles.espacamentoButton}
                                        >
                                            Editar finalização
                                        </div>
                                    )}
                                </div>
                            )}

                            {infoData.status == "Cancelada" && (
                                <div className={styles.containerButtonFlexEnd}>
                                    <div
                                        onClick={() =>
                                            setOpenModalCancelar(true)
                                        }
                                        className={styles.espacamentoButton}
                                    >
                                        Visualizar cancelamento
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* **************************************************************** */}
            </Grid>

            <ModalJustificativa
                styles={{
                    minHeight: "578px",
                    width: "520px",
                }}
                title="Finalizar ocorrência"
                description=""
                openModal={statusOpenModal}
                setOpenModal={setStatusOpenModal}
                onClick={() => submitData()}
                labelConfirm={
                    infoData.status === "Finalizada"
                        ? "Salvar edição"
                        : "Finalizar"
                }
                backgroundCacel="#fff"
                color={statusButton ? "" : "blue"}
                disabled={statusButton}
            >
                <Container maxWidth="xs">
                    <div className={styles.containerModal}>
                        <div className={styles.containerEndOccurrence}>
                            <div>
                                <label className="form-label">
                                    Nome do operador
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                        infoData.status == "Finalizada"
                                            ? infoData.operador?.nome
                                            : operador.operador?.nome
                                    }
                                    disabled
                                />
                            </div>
                            <div>
                                <label className="form-label">Pontuar</label>
                                <select
                                    className="form-select"
                                    defaultValue={score}
                                    onChange={(e) =>
                                        checkRequest(e.target.value)
                                    }
                                >
                                    <option value={"Sim"}>Sim</option>
                                    <option value={"Não"}>Não</option>
                                </select>
                            </div>
                            <div>
                                <span
                                    className={`form-label ${
                                        textDescError
                                            ? styles.textLabelRed
                                            : styles.textLabel
                                    }`}
                                >
                                    Comentário
                                </span>
                                <div
                                    className={
                                        textDescError
                                            ? styles.formGroupError
                                            : ""
                                    }
                                >
                                    <textarea
                                        className="form-control"
                                        onChange={(e) =>
                                            textDesc(e.target.value)
                                        }
                                        value={descricao}
                                    />
                                </div>
                            </div>
                            <span className={styles.requiredField}>
                                {textDescError}
                            </span>
                            <div
                                className={`d-flex justify-content-between mt-2 mb-3 ${styles.containerOperatorOccurrence}`}
                            >
                                <div className={styles.textOperator}>
                                    Operador {operador.operador.nome} -{" "}
                                    {currentDateTime}
                                </div>
                                <div>
                                    <span className={styles.characterNumber}>
                                        {descricao.length}
                                    </span>
                                    <span className={styles.characterLetter}>
                                        /1.500 caracteres
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </ModalJustificativa>

            {/* **************************************************************** */}

            <ModalJustificativa
                styles={{
                    minHeight: "578px",
                    width: "520px",
                }}
                title={
                    infoData.status == "Cancelada"
                        ? "Visualizar cancelamento"
                        : "Cancelar ocorrência"
                }
                description=""
                openModal={openModalCancelar}
                setOpenModal={setOpenModalCancelar}
                onClick={() => submitCancelarData()}
                labelConfirm="Cancelar ocorrência"
                labelCancel={
                    infoData.status == "Cancelada" ? "Fechar" : "Voltar"
                }
                backgroundCacel="#fff"
                color={"red"}
                disabled={statusButtonCancel}
                removeBtnConf={infoData.status == "Cancelada" ? true : false}
            >
                <Container maxWidth="xs">
                    <div className={styles.containerModal}>
                        <div className={styles.containerCancelOccurrence}>
                            <div>
                                <label className="form-label">
                                    Nome do operador
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                        infoData.status == "Cancelada"
                                            ? infoData.operador?.nome
                                            : operador.operador?.nome
                                    }
                                    disabled
                                />
                            </div>
                            <div>
                                <span
                                    className={`form-label ${
                                        validacaoTextoModalCancel
                                            ? styles.textLabelRed
                                            : styles.textLabel
                                    }`}
                                >
                                    Comentário
                                </span>
                                <div
                                    className={`${
                                        validacaoTextoModalCancel
                                            ? styles.formGroupError
                                            : ""
                                    }`}
                                >
                                    {infoData.status == "Cancelada" ? (
                                        <section className={styles.section}>
                                            <div className="form-control">
                                                {infoData.parecer}
                                            </div>
                                        </section>
                                    ) : (
                                        <textarea
                                            className={`form-control`}
                                            onChange={(e) =>
                                                textDescCancel(e.target.value)
                                            }
                                            value={descricaoCancel}
                                        />
                                    )}
                                </div>
                            </div>
                            {infoData.status != "Cancelada" && (
                                <span className={styles.requiredField}>
                                    {textDescErrorCancel}
                                </span>
                            )}
                            <div
                                className={`d-flex justify-content-between mt-2 mb-3 ${styles.containerOperatorOccurrence}`}
                            >
                                {infoData.status == "Cancelada" ? (
                                    <div className={styles.textOperator}>
                                        {moment(infoData.dataParecer).format(
                                            "DD/MM/YY HH:mm",
                                        )}
                                    </div>
                                ) : (
                                    <div />
                                )}

                                {infoData.status == "Análise" && (
                                    <div>
                                        <span
                                            className={styles.characterNumber}
                                        >
                                            {descricaoCancel.length}
                                        </span>
                                        <span
                                            className={styles.characterLetter}
                                        >
                                            /1.500 caracteres
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </ModalJustificativa>

            {/* **************************************************************** */}

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={setLoadingModal}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </ClientesProfissionaisProvider>
    );
}
