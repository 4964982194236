import { Button, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import FileUpload from "react-mui-fileuploader";
import { toast } from "react-toastify";
import { Api } from "../../providers";
import { ModalCustomUpload, ModalLoading } from "../modal";
import ImageIconRed from "./../../assets/images/icons/icon_close_red.png";
import uploadIcon from "./../../assets/images/upload.png";
import styles from "./styles.module.scss";

const Input = styled("input")({
    display: "none",
});

// Simple Upload
export default function MyUpload(props) {
    const [selectFile, setsSelectFile] = useState("");
    const [progress, setProgress] = useState(0);
    const [showModalNotSup, setShowModalNotSup] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    let ENDPOINT = `documento/${props.params.type}/profissional/${props.params.profissionalId}`;
    if (props.params.service > 0) {
        ENDPOINT = ENDPOINT + `/servico/${props.params.service}`;
    }
    const handleFilesUpload = (fileData) => {
        if (props.params.profissionalId === "undefined" || !fileData?.name) {
            return;
        }

        const fd = new FormData();
        fd.append("file", fileData, fileData?.name);
        Api.post(ENDPOINT, fd, {
            onUploadProgress: (event) => {
                let uploadProgress = Math.round(
                    (event.loaded * 100) / event.total,
                );
                setProgress(
                    uploadProgress > progress ? uploadProgress : progress,
                );
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Upload realizado com Sucesso!", {
                        toastId: "success",
                        autoClose: 3000,
                        onClose: function () {
                            props.params.noReload
                                ? {}
                                : window.location.reload();
                        },
                    });
                    setLoadingModal(false);
                    if (props.params.noReload) {
                        const resultData = {
                            url: res.data.caminho,
                            type: res.data.documentoTipoId,
                            profissionalId: res.data.profissionalId,
                        };
                        props.redirectPage(resultData);
                        props.loadingData();
                        props.setOpenModal(false);
                        setProgress(0);
                        setsSelectFile("");
                    }
                }
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;

                toast.error(`Ops! ${message.error}`, {
                    toastId: "error",
                });
            });
        return;
    };

    const handleFilesChange = (event) => {
        const totalSize = event.target.files[0].size / 1024 / 1024;
        props.setOpenModal(false);
        setLoadingModal(true);
        const checkData = [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "application/pdf",
        ];
        if (checkData.includes(event.target.files[0].type) && totalSize <= 5) {
            setsSelectFile(() => event.target.files[0]);
            setTimeout(() => {
                handleFilesUpload(event.target.files[0]);
            }, 2000);
        } else {
            setLoadingModal(false);
            setShowModalNotSup(true);
        }
    };

    return (
        <>
            <ModalCustomUpload
                styles={{
                    minHeight: "331px",
                    width: "464px",
                }}
                title="Anexar arquivo"
                openModal={props.openModal}
                setOpenModal={props.setOpenModal}
                // onClick={() => handleTrocaSenha()}
            >
                <div className={styles.containerGeralUpload}>
                    <Input
                        accept="image/png, image/jpg, application/pdf"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                            handleFilesChange(e);
                        }}
                    />
                    <span className={styles.descUpload}>
                        Adicione um arquivo de tamanho máximo de 5mb, em formato
                        de pdf, jpg ou png.
                    </span>
                    <label htmlFor="contained-button-file">
                        <div className={styles.containeBtnUpload}>
                            <Button
                                className={styles.btnUpload}
                                component="span"
                            >
                                <IconButton
                                    color="primary"
                                    aria-label="upload"
                                    component="span"
                                >
                                    <img src={uploadIcon} />
                                </IconButton>
                                <span className={styles.textBtnUpload}>
                                    Fazer upload de arquivo
                                </span>
                            </Button>
                        </div>
                    </label>
                </div>
            </ModalCustomUpload>

            <ModalCustomUpload
                styles={{
                    minHeight: "255px",
                    width: "456px",
                }}
                title=""
                openModal={showModalNotSup}
                setOpenModal={setShowModalNotSup}
                // onClick={() => handleTrocaSenha()}
                labelXSair={"nao"}
            >
                <div className={styles.containerGeralUpload}>
                    <Input
                        accept="image/png, image/jpg, application/pdf"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                            handleFilesChange(e);
                        }}
                    />
                    <div className={styles.containerNotSupport}>
                        <img
                            src={ImageIconRed}
                            title=""
                            style={{ width: 24, height: 24 }}
                        />
                        <span className={styles.descNotSupport}>
                            Arquivo não suportado
                        </span>
                    </div>
                    <div
                        className={styles.containeBtnUpload}
                        onClick={() => setShowModalNotSup(false)}
                    >
                        <div className={styles.btnNotSupport}>
                            <span className={styles.textNotSupport}>Ok</span>
                        </div>
                    </div>
                </div>
            </ModalCustomUpload>

            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
}

// Multiploe Upload
export function MyUploadMultiple() {
    const handleFileUploadError = (error) => {
        // console.log(error);
    };

    const handleFilesChange = (files) => {
        // console.log(files);
    };
    return (
        <FileUpload
            multiFile={true}
            disabled={false}
            title="Upload de Arquivos"
            header="Arraste e solte"
            leftLabel="ou "
            rightLabel="para selecionar um arquivo"
            buttonLabel="Clique aqui"
            buttonRemoveLabel="Remover todos"
            maxFileSize={5} //5mb
            maxUploadFiles={0}
            maxFilesContainerHeight={357}
            errorSizeMessage={
                "Ops! Tamanho do arquivo não pode ser superior a 5mb"
            }
            allowedExtensions={["jpg", "jpeg", "pdf", "png"]}
            onFilesChange={handleFilesChange}
            onError={handleFileUploadError}
            // imageSrc={"path/images"}
            bannerProps={{ elevation: 0, variant: "outlined" }}
            containerProps={{ elevation: 0, variant: "outlined" }}
        />
    );
}
