import React from "react";
import { Container } from "react-bootstrap";
import {SearchRestricaoCliente} from "./../../../components/search/restricao/cliente/";
import {RestricaoTableCliente} from "../../../components/tables/restricao/cliente/";
import { useLocation } from "react-router-dom";

//Documents
export default function Section7() {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    return (
        <>
            <Container fluid className="justify-content-center">
                <SearchRestricaoCliente idQuery={idQuery} />
                <RestricaoTableCliente idQuery={idQuery} />
            </Container>
        </>
    );
}
