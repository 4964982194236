import React from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { TextField } from "@mui/material";
const Item = {
    width: "100%",
    maxWidth: "900px",
    // height: "200px",
    wordWrap: "break-word",
    maxHeight: "200px",
    display: "flex",
    marginTop: "32px",
    justifyContent: "start",
    alignContent: "center",
    padding: 0,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    alignItems: "center",
    color: "#343B40",
    backgroundColor: "#ffffff",
    "&.MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "17px",
        color: "#343B40",
    },
    "&.MuiFormHelperText-root  ": {
        m: 0,
        p: 0,
        fontSize: 10,
        alignSelf: "end",
        alignItems: "end",
        color: "red",
    },
    "&.MuiInputBase-input, .MuiOutlinedInput-input": {
        // width: "870px",
        height: 135,
        // whiteSpace: "normal",
        // wordWrap: "break-word",
    },
};

export const MyTextAreaDefault = (props) => {
    return (
        <TextareaAutosize
            aria-label="my-text"
            placeholder="Escreva aqui"
            minRows={3}
            maxRows={5}
            style={{ width: "100%", maxWidth: "840px" }}
            {...props}
        />
    );
};
export const MyTextArea = (props) => {
    return (
        <TextField
            fullWidth
            multiline
            rows={3}
            sx={Item}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
                shrink: true,
            }}
            // InputProps={{
            //     components: (param) => <textarea {...param} />,
            // }}
            {...props}
        />
    );
};
