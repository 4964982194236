import { ptBR as corePtBr } from "@material-ui/core/locale";
import { Rating } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { BaseURL } from "../../../api";
import creditDebitIcon from "../../../assets/images/credit-debit.png";
import imagemPadrao from "../../../assets/images/fotoPadrao.png";
import moneyIcon from "../../../assets/images/money.png";
import pixIcon from "../../../assets/images/pix.png";
import { Api } from "../../../providers";
import { FormatDateNameBREvaluation } from "../../../utils/date";
import { ModalSimplesSemConfirmacao } from "../../modal";
import { ServiceContext } from "./../../../contexts/service";
import { CpfCnpjMaskRegex } from "./../../../utils/mask/index";
import { MyRating } from "./../../rating/";
import ResultNotFound from "../../resultNotFound";

//Limits
const PROFESSIONAL_LIMIT = 30;
// Theme table
const theme = createTheme(
    {
        palette: {
            primary: { main: "#0069B4" },
        },
    },
    ptBR,
    corePtBr,
);
const SolicitacaoProfssionalTable = ({ columnsCPF }) => {
    // Profissional
    const {
        profissionais,
        selectProfissional,
        setSelectProfissional,
        servicoId,
        isNominal,
    } = React.useContext(ServiceContext);
    const [pageSize, setPageSize] = React.useState(5);

    const [
        openModalVisualizarAvaliacaoCliente,
        setOpenModalVisualizarAvaliacaoCliente,
    ] = useState(false);
    const [nomeProfissional, setNomeProfissional] = useState("");

    const [servicos, setServicos] = useState([]);
    const [formasPagamento, setFormasPagamento] = useState([]);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [rating, setRating] = useState(0);
    const [fotoProfissional, setFotoProfissional] = useState("");
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [totalRatings, setTotalRatings] = useState(2);

    const viewMore = () => {
        setTotalRatings(100);
    };

    const carregarInformacoesDeProfissional = (id, nome) => {
        setNomeProfissional(nome);
        setOpenModalVisualizarAvaliacaoCliente(true);
        setTotalRatings(2);
        Api.get("/profissional/" + id)
            .then((res) => {
                if (res.status === 200) {
                    //setServicos(res.data?.servicos);
                    setFormasPagamento(res.data?.formaPagamentos);
                    setAvaliacoes(res.data?.avaliacoes);
                    setRating(res.data?.avaliacao);
                    if (!res.data.usuario?.foto) {
                        setFotoProfissional(imagemPadrao);
                    } else {
                        setFotoProfissional(
                            BaseURL +
                                "api/usuario/security/" +
                                res.data.usuario?.foto +
                                "?token=" +
                                token,
                        );
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });

        Api.get("/profissional/" + id + "/servico")
            .then((res) => {
                if (res.status === 200) {
                    setServicos(res.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const columns = [
        // { field: "id", headerName: "ID", minWidth: 30 },
        {
            field: "nome",
            headerName: "Nome",
            minWidth: columnsCPF ? 150 : 300,
            sort: "asc",
            align: "center",
            className: "link",
            renderCell: (params) => (
                <span
                    onClick={() => {
                        carregarInformacoesDeProfissional(
                            params.id,
                            params.value,
                        );
                    }}
                    className={`link`}
                    style={{ whiteSpace: "normal" }}
                    title={params.value}
                >
                    {params.value}
                </span>
            ),
        },
        {
            field: "servicoRow",
            headerName: "Serviço",
            minWidth: 160,
            sortable: false,
            align: "center",
        },
        {
            field: "taxaOnly",
            headerName: `Taxa de \n Visita (R$)`,
            maxWidth: 100,
            align: "center",
        },
        {
            field: "valorOnly",
            headerName: "Valor do \n Serviço (R$)",
            maxWidth: 100,
            align: "center",
        },
        {
            field: "distancia",
            headerName: "Distância \n (Km)",
            minWidth: 150,
        },
        {
            field: "avaliacao",
            headerName: "Avaliação",
            minWidth: 130,
            renderCell: (params) => <MyRating value={params.value} />,
            sortable: false,
            align: "center",
        },
        {
            field: "realizado",
            headerName: "Serviços Realizados",
            minWidth: 100,
            align: "center",
        },
    ];

    if (columnsCPF) {
        columns.splice(1, 0, {
            field: "cpf",
            headerName: "CPF",
            minWidth: 160,
            sortable: false,
        });
    }
    /**
     * Return service's name and tax.
     * @param {array} row
     * @returns {array} array 2x2: [0][0] = name e [0][1] = tax
     */
    const searchService = (row) => {
        let res = row.servicos.find((item) => {
            return item.servico.id === servicoId;
        });
        if (res)
            return [res.servico.nome, res.taxaVisitaValor, res.servicoValor];
        return [null, "-", "-"];
    };
    //Id is very important for checkbox's select
    const rows = profissionais.map((row) => {
        let arrService = searchService(row);
        let res = {
            id: row.id,
            nome: row.nome.substring(0, columnsCPF ? 50 : 70),
            servicoRow: arrService[0] ? arrService[0] : "-",
            taxaOnly:
                arrService[1] && arrService[1] !== 0 ? arrService[1] : "Não",
            valorOnly:
                arrService[2] && arrService[2] !== 0 ? arrService[2] : "Não",
            distancia: row.distancia ?? "Não encontrado",
            avaliacao: row.avaliacao,
            realizado: row.tarefasRealizadas,
        };
        if (columnsCPF) res.cpf = CpfCnpjMaskRegex(row.cpf);
        return res;
    });

    const getImagemTipoPagamento = (tipo) => {
        switch (tipo) {
            case "Débito":
                return creditDebitIcon;
            case "Crédito":
                return creditDebitIcon;
            case "Pix":
                return pixIcon;
            default:
                return moneyIcon;
        }
    };

    const exibirAvaliacao = (item) => {
        return (
            <div className="avaliacoes-box">
                <div className="avaliacoes-box-rating">
                    <Rating value={item.nota} disabled={true} />
                </div>
                <div className="avaliacoes-box-rating-data">
                    {FormatDateNameBREvaluation(item.data)}
                </div>
            </div>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#CFD9E1",
                        },
                        "& .MuiDataGrid-columnHeadersInner": {
                            backgroundColor: "#CFD9E1",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: "#343B40",
                            textOverflow: "clip",
                            whiteSpace: "break-spaces",
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel) => {
                        let myLimit = isNominal ? 1 : PROFESSIONAL_LIMIT;
                        if (newSelectionModel.length <= myLimit) {
                            //limit
                            setSelectProfissional(newSelectionModel);
                        }
                    }}
                    selectionModel={selectProfissional}
                    //Pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                />
            </div>
            {profissionais.length <= 0 && (
                <ResultNotFound style={{marginTop: 10}} />
            )}
            <ModalSimplesSemConfirmacao
                styles={{
                    minHeight: "578px",
                    width: "558px",
                }}
                title={nomeProfissional}
                description=""
                openModal={openModalVisualizarAvaliacaoCliente}
                setOpenModal={setOpenModalVisualizarAvaliacaoCliente}
                color="blue"
                labelConfirm="Avaliar"
                labelCancel="Fechar"
            >
                <div className="modal-perfil-profissional">
                    <Container className="justify-content-end">
                        <div className="d-flex justify-content-center align-items-center container mb-4 p-2">
                            <img
                                src={fotoProfissional}
                                className="profile-image"
                            />
                        </div>

                        <Form.Group className="d-flex justify-content-center align-items-center container mb-4 p-2">
                            <Rating
                                name="avaliarCliente"
                                value={rating}
                                disabled={true}
                            />
                        </Form.Group>

                        <Form.Group className="box-servicos">
                            <div className="titulo-box-servicos">Serviços</div>
                            <ul>
                                {servicos.map((item) => {
                                    if (item.flagAtivo) {
                                        return (
                                            <li className="lista-servicos-item">
                                                {item.servico.nome}
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </Form.Group>
                        <Form.Group className="d-flex justify-content-center align-items-center container mb-4 p-2"></Form.Group>
                        <Form.Group className="box-servicos">
                            <div className="titulo-box-servicos">
                                Formas de pagamento
                            </div>
                            <div className="forma-de-pagamento">
                                {formasPagamento.map((item) => (
                                    <div className="formas-de-pagamento-item">
                                        <img
                                            src={getImagemTipoPagamento(
                                                item.nome,
                                            )}
                                            width="48"
                                            height={48}
                                        />
                                        <span className="formas-de-pagamento-item-nome">
                                            {item.nome}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </Form.Group>
                        <Form.Group className="d-flex justify-content-center align-items-center container mb-4 p-2"></Form.Group>
                        <Form.Group className="box-servicos">
                            <div className="titulo-box-servicos">
                                Avaliações
                            </div>

                            {avaliacoes.map((item, idx) =>
                                totalRatings > idx
                                    ? exibirAvaliacao(item)
                                    : null,
                            )}
                            {totalRatings === 2 && avaliacoes.length > 0 && (
                                <span
                                    onClick={() => viewMore()}
                                    className="ver-mais-texto"
                                >
                                    Ver mais
                                </span>
                            )}
                        </Form.Group>
                    </Container>
                </div>
            </ModalSimplesSemConfirmacao>
        </ThemeProvider>
    );
};

export default SolicitacaoProfssionalTable;
