import React from "react";
import SolicitacaoProfissionalSearch from "./../../../components/search/solicitacao/profissional/index";
import SolicitacaoProfssionalTable from "./../../../components/tables/solicitacao/solicitacaoprofssional";
import { Grid } from "@mui/material";
import AddSolicitacao from "./../CriarSolicitacao/add";
import { ServiceContext } from "./../../../contexts/service";

export default function Section2() {
    //Fix MultiSelects
    const { setSelectProfissional, setIsNominal } =
        React.useContext(ServiceContext);
    //Fix MultiSelects
    React.useEffect(() => {
        setSelectProfissional([]);
        setIsNominal(true);
    }, []);
    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <SolicitacaoProfissionalSearch search={true} filter={false} />
            </Grid>
            <Grid item md={12}>
                <SolicitacaoProfssionalTable columnsCPF={true} />
            </Grid>
            <Grid item md={12}>
                <AddSolicitacao onlyButton={false} />
            </Grid>
        </Grid>
    );
}
