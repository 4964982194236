import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import { Api } from "../../../providers";
import ResultNotFound from "../../resultNotFound";
import { ServiceContext } from "./../../../contexts/service";
import { ListTable } from "./listTable";
import styles from "./styles.module.scss";

const OcorrenciasTable = () => {
    const {
        occurrences,
        setOccurrences,
        loading,
        setLoading,
        totalPages,
        setTotalPages,
        paramsFilter,
    } = useContext(ServiceContext);
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);

    // *************************************************************************
    const operador = JSON.parse(localStorage.getItem("user"));
    const permissoes = JSON.parse(localStorage.getItem("permissoes"));
    const checkOp =
        operador?.operador?.funcao?.slug == "administrador" ? true : false;
    const permOcorrencias = permissoes?.find(
        (val) => val.tela.slug == "ocorrencias",
    );
    // *************************************************************************

    let mounted = true;

    useEffect(() => {
        Api.get(`/ocorrencia/pagination/0/10`)
            .then((res) => {
                if (mounted) {
                    setOccurrences(res.data.content);
                    setTotalPages(res.data.totalPages);
                    setLoading(false);
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoading(false);
                }
            });
        return () => {
            mounted = false;
            setOccurrences([]);
        };
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [paramsFilter]);

    const nextPage = (page = 0) => {
        setLoading(true);
        const urlData = `/ocorrencia/pagination/${page}/10`;
        const params = paramsFilter;
        Api.get(urlData, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                setOccurrences(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            })
            .catch((_) => {
                setLoading(false);
            });
    };
    const currentItems = occurrences;
    const pageCountData = totalPages;

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    const handleNewPage = () => {
        navigate(`/ocorrencias/add`);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    return (
        <>
            {(checkOp || permOcorrencias?.criar) && (
                <div className={styles.textNewOccurrence}>
                    <span onClick={() => handleNewPage()}>Nova ocorrência</span>
                </div>
            )}

            {currentItems.length > 0 && (
                <ListTable currentItems={currentItems} />
            )}

            {currentItems.length <= 0 && (
                <ResultNotFound style={{ marginBottom: 10 }} />
            )}

            <div className={styles.reactPaginate}>
                <ReactPaginate
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCountData}
                    previousLabel="<"
                    nextLabel=">"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName={styles.pagination}
                    pageClassName={styles.pageItem}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={styles.pageLink}
                    nextClassName={styles.pageItem}
                    nextLinkClassName={styles.pageLink}
                    activeClassName={"active"}
                    forcePage={currentPage - 1}
                    disabledClassName={styles.disabled}
                />
            </div>
        </>
    );
};

export default OcorrenciasTable;
