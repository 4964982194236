import React, { useContext, useEffect, useState } from "react";
import { Form, FormControl, Button, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Api } from "../../providers";
import { toast } from "react-toastify";
import { Box, Grid } from "@mui/material";
import "./styles.css";
import { UserContext } from "../../contexts/user";

const loginImg = "/images/login/login.png";

const TrocaSenhaPage = () => {
    const navigate = useNavigate();
    const { setScreenLogin } = useContext(UserContext);
    const [pin, setPin] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmSenha, setConfirmSenha] = useState("");
    const search = useLocation().search;
    const cpf = new URLSearchParams(search).get("cpf");

    useEffect(() => {
        setScreenLogin(false);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (pin.length < 4) {
            toast.error(`Ops! Digite um PIN válido.`, {
                toastId: "error",
            });
            return;
        }
        if (senha !== confirmSenha) {
            toast.error(`Ops! As senhas são diferentes.`, {
                toastId: "error",
            });
            return;
        }
        const reseta = {
            pin: pin,
            novaSenha: senha,
            confirmarNovaSenha: confirmSenha,
        };
        Api.post("/operador/reset-senha/?cpf=" + cpf, reseta)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Senha alterada com sucesso!", {
                        toastId: "msg",
                        autoClose: 3000,
                        onClose: function () {
                            navigate("/login/");
                        },
                    });
                }
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;
                toast.error(`Ops! ${message.error}`, {
                    toastId: "msg",
                });
            });
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid
                    item
                    sx={{ maxWidth: "530px", maxHeight: "942px" }}
                    lg={5}
                    xs={12}
                >
                    <img src={loginImg} alt="login" className="login-img" />
                </Grid>
                <Grid item sx={{ m: 0, maxHeight: "940px" }} lg={7} xs={12}>
                    <Box>
                        <div className="login">
                            <h1 className="login-title">Redefinir senha</h1>
                            <Form className="form" onSubmit={handleSubmit}>
                                <Form.Floating className="mb-3">
                                    <FormControl
                                        type="text"
                                        maxLength="30"
                                        name="pin"
                                        id="pin"
                                        value={pin}
                                        onChange={(e) => setPin(e.target.value)}
                                        required
                                    />
                                    <label htmlFor="cpf">PIN</label>
                                </Form.Floating>{" "}
                                <Form.Floating className="mb-3">
                                    <FormControl
                                        type="password"
                                        minLength={8}
                                        maxLength={30}
                                        name="senha"
                                        id="senha"
                                        value={senha}
                                        onChange={(e) =>
                                            setSenha(e.target.value)
                                        }
                                        required
                                    />
                                    <label htmlFor="email">Senha</label>
                                </Form.Floating>
                                <Form.Floating className="mb-3">
                                    <FormControl
                                        type="password"
                                        minLength={8}
                                        maxLength={30}
                                        name="confirmSenha"
                                        id="confirmSenha"
                                        value={confirmSenha}
                                        onChange={(e) =>
                                            setConfirmSenha(e.target.value)
                                        }
                                        required
                                    />
                                    <label htmlFor="email">
                                        Repita a senha
                                    </label>
                                </Form.Floating>
                                <Row lg="12" className="my-4 mx-1">
                                    <Col
                                        lg="12"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Button
                                            className="button-login"
                                            type="submit"
                                        >
                                            Redefinir senha
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default TrocaSenhaPage;
