import { Box, Grid, TextField } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import MyDatePicker from "./../../../components/datepicker/";
import { Api } from "./../../../providers/index";
import { FormatDate } from "./../../../utils/date";
import {
    CpfCnpjMaskRegex,
    PhoneFixoMask,
    PhoneMask,
} from "./../../../utils/mask/index";
import StatusCliente from "./status/index";
import styles from "./styles.module.scss";

const Item = {
    maxWidth: "400px",
    height: "56px",
    display: "flex",
    marginTop: "32px",
    justifyContent: "start",
    alignContent: "center",
    padding: 0,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    alignItems: "center",
    color: "#343B40",
    backgroundColor: "#ffffff",
    "& .MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "17px",
        color: "#343B40",
    },
};

const ItemCustomField = {
    width: "400px !important",
    height: "56px",
    "& .MuiOutlinedInput-input": {
        padding: "16.5px 0px",
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiInputBase-input": {
        width: "93%",
    },
    "& .MuiInputBase-root": {
        borderRadius: "8px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #C7C9D9 !important",
        borderColor: "#C7C9D9 !important",
    },
};

const ItemCustom = {
    width: "400px !important",
    height: "56px !important",
    borderRadius: "8px !important",
    "& .MuiOutlinedInput-input": {
        padding: "16.5px 14px",
        color: "#B3B5B6",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        zIndex: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px !important",
    },
    "& .MuiSvgIcon-root": {
        display: "none",
    },
};

const BoxData = ({ children }) => {
    return (
        <Box
            sx={{
                width: "880px",
                height: "100%",
                background: "#F7F7F7",
                borderRadius: "8px",
            }}
        >
            {children}
        </Box>
    );
};

export default function Section1({ tabData }) {
    const [nome, setNome] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [telefone, setTelefone] = React.useState("");
    const [celular, setCelular] = React.useState("");
    const [dataNascimento, setDataNascimento] = React.useState(null);
    const [cpfCnpj, setCpfCnpj] = React.useState("");
    //Tipo: 1:PF e 2:PJ
    const [flagEmpresa, setFlagEmpresa] = React.useState(true);
    // Jurídico
    const [nomeFantasia, setNomeFantasia] = React.useState("");
    const [razao, setRazao] = React.useState("");

    React.useEffect(() => {
        if (tabData) {
            setFlagEmpresa(!!tabData.flagEmpresa);
            setNomeFantasia(tabData.nomeFantasia);
            setRazao(tabData.nome);
            setNome(tabData.nome);
            setDataNascimento(
                tabData.dataNascimento
                    ? FormatDate(tabData.dataNascimento, "yyyy/MM/dd")
                    : null,
            );
            setTelefone(
                tabData.telefoneResidencial ? tabData.telefoneResidencial : "",
            );
            setCelular(tabData.telefoneCelular ? tabData.telefoneCelular : "");
            setCpfCnpj(tabData.cpfCnpj);
            setEmail(tabData.email);
        }
    }, [tabData]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (flagEmpresa === true) {
            tabData.flagEmpresa = flagEmpresa;
            tabData.nomeFantasia = nomeFantasia;
            // tabData.nome = razao;
        } else {
            // tabData.nome = nome;
            // tabData.dataNascimento = FormatDate(dataNascimento);
            tabData.telefoneResidencial = telefone;
        }
        // tabData.cpfCnpj = cpfCnpj;
        tabData.telefoneCelular = celular;
        tabData.email = email;

        Api.put(`/cliente/${tabData.id}`, tabData)
            .then((res) => {
                // console.log(res, tabData);
                if (res.status === 200)
                    toast.success("Cliente atualizado com Sucesso", {
                        toastId: "success",
                    });
            })
            .catch((err) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "error",
                });
            });
    };

    return (
        <>
            <BoxData>
                <div className={styles.containerGeralDadosCli}>
                    <div className={styles.containerGeralDadosSubCli}>
                        <div className={styles.subContainerGeralDados01}>
                            <div
                                className={`${styles.containerTextField} ${styles.colorFieldBlock}`}
                            >
                                <div>
                                    <span>CPF/CNPJ</span>
                                    <TextField
                                        id="cpfCnpj"
                                        name="cpfCnpj"
                                        value={
                                            cpfCnpj
                                                ? CpfCnpjMaskRegex(
                                                      CpfCnpjMaskRegex(cpfCnpj),
                                                  )
                                                : ""
                                        }
                                        fullWidth
                                        sx={ItemCustomField}
                                        disabled
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>

                            {/* P. Juridica */}
                            {flagEmpresa && (
                                <div
                                    className={`${styles.containerTextField} ${styles.dividerSpace}`}
                                >
                                    <div>
                                        <span>Nome fantasia</span>
                                        <TextField
                                            id="fantasia"
                                            name="fantasia"
                                            value={nomeFantasia}
                                            fullWidth
                                            sx={ItemCustomField}
                                            hidden={!flagEmpresa}
                                            onChange={(e) => {
                                                setNomeFantasia(e.target.value);
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {/* P. Física */}
                            {!flagEmpresa && (
                                <>
                                    <div
                                        className={`${styles.containerDatePicker} ${styles.dividerSpace} ${styles.colorFieldBlock}`}
                                    >
                                        <span>Data de nascimento</span>
                                        <MyDatePicker
                                            id="dataNascimento"
                                            name="dataNascimento"
                                            fullWidth
                                            sx={ItemCustom}
                                            disabled={true}
                                            hidden={flagEmpresa}
                                            value={dataNascimento}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={`${styles.containerTextField} ${styles.dividerSpace}`}
                                    >
                                        <div>
                                            <span>Telefone celular</span>
                                            <TextField
                                                id="celular"
                                                name="celular"
                                                value={celular}
                                                fullWidth
                                                sx={ItemCustomField}
                                                type="tel"
                                                onChange={(e) => {
                                                    setCelular(e.target.value);
                                                }}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputComponent: PhoneMask, //PhoneFixoMask
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            {flagEmpresa && (
                                <div
                                    className={`${styles.containerTextField} ${styles.dividerSpace}`}
                                >
                                    <div>
                                        <span>Email</span>
                                        <TextField
                                            id="email"
                                            name="email"
                                            value={email}
                                            fullWidth
                                            sx={ItemCustomField}
                                            type="email"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            {/* Column Two */}
                            <Grid item xs={6}>
                                {/* P. Juridica */}

                                {flagEmpresa && (
                                    <div
                                        className={`${styles.containerTextField} ${styles.colorFieldBlock}`}
                                    >
                                        <div>
                                            <span>Razão social</span>
                                            <TextField
                                                id="razao"
                                                name="razao"
                                                value={razao}
                                                hidden={!flagEmpresa}
                                                disabled
                                                fullWidth
                                                sx={ItemCustomField}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* p. Fisica */}
                                {!flagEmpresa && (
                                    <div
                                        className={`${styles.containerTextField} ${styles.colorFieldBlock}`}
                                    >
                                        <div>
                                            <span>Nome completo</span>
                                            <TextField
                                                id="nome"
                                                name="nome"
                                                value={nome}
                                                fullWidth
                                                sx={ItemCustomField}
                                                disabled={true}
                                                hidden={flagEmpresa}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                {flagEmpresa && (
                                    <div
                                        className={`${styles.containerTextField} ${styles.dividerSpace}`}
                                    >
                                        <div>
                                            <span>Telefone celular</span>
                                            <TextField
                                                id="celular"
                                                name="celular"
                                                value={celular}
                                                fullWidth
                                                sx={ItemCustomField}
                                                type="tel"
                                                onChange={(e) => {
                                                    setCelular(e.target.value);
                                                }}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputComponent: PhoneMask, //PhoneFixoMask
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                {!flagEmpresa && (
                                    <>
                                        <div
                                            className={`${styles.containerTextField} ${styles.dividerSpace}`}
                                        >
                                            <div>
                                                <span>Email</span>
                                                <TextField
                                                    id="email"
                                                    name="email"
                                                    value={email}
                                                    fullWidth
                                                    sx={ItemCustomField}
                                                    type="email"
                                                    onChange={(e) => {
                                                        setEmail(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className={`${styles.containerTextField} ${styles.dividerSpace}`}
                                        >
                                            <div>
                                                <span>
                                                    Telefone residencial
                                                    (opcional)
                                                </span>
                                                <TextField
                                                    id="telefone"
                                                    name="telefone"
                                                    value={telefone}
                                                    hidden={flagEmpresa}
                                                    fullWidth
                                                    sx={ItemCustomField}
                                                    type="tel"
                                                    onChange={(e) => {
                                                        setTelefone(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        inputComponent:
                                                            PhoneFixoMask,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Grid>
                        </div>
                    </div>

                    <div className={styles.containerInfoButtonEditDadosCli}>
                        <div className={styles.containerButtonEditDadosCli}>
                            <button
                                className={styles.buttonSearch}
                                type="button"
                                onClick={handleSubmit}
                                disabled={false}
                            >
                                <span>Salvar</span>
                            </button>
                        </div>
                    </div>
                </div>
            </BoxData>
            <BoxData>
                <div className={styles.containerGeralSituacao}>
                    <StatusCliente tabData={tabData} />
                </div>
            </BoxData>
        </>
    );
}
