import React from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { UserContext } from "./../../../contexts/user";
import { Button } from "@mui/material";
import DocumentPDF from "./document";
import data from "./data";

export default function MakerPDF() {
    const { setPrintLayout } = React.useContext(UserContext);
    //print
    React.useEffect(() => {
        setPrintLayout(true);
    }, []);
    return (
        <>
            <PDFViewer style={{ width: 1400, height: 800 }}>
                <DocumentPDF title={"LISTA DE PROFISSIONAIS"} data={data} />
            </PDFViewer>
            <PDFDownloadLink
                document={
                    <DocumentPDF title={"LISTA DE PROFISSIONAIS"} data={data} />
                }
                fileName="documento.pdf"
            >
                {({ blob, url, loading, error }) =>
                    loading ? (
                        "Loading document..."
                    ) : (
                        <Button variant="outlined" color="primary">
                            Download do Documento!
                        </Button>
                    )
                }
            </PDFDownloadLink>
        </>
    );
}
