import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

const UnstyledButon = styled(Button)(({ theme }) => ({
    width: "181px",
    height: "60px",
    fontFamily: "Inter",
    fontStyle: "normal",
    textTransform: "none",
    color: theme.palette.grey[700],
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19px",
    background: "#B3B5B6",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "50px",
    margin: "10px 0px",
    //Fix variant outlined
    "&.MuiButton-outlined": {
        margin: "10px 10px",
    },
    "&:hover, &.Mui-focusVisible": {
        color: theme.palette.grey[50],
        background:
            "linear-gradient(268.05deg, #2E7EBE 4.03%, #2E7EBE 101.81%)",
        boxShadow: `0px 0px 0px 4px ${alpha(theme.palette.primary.main, 0.1)}`,
    },
    "&.Mui-active": {
        color: theme.palette.grey[50],
        background:
            "linear-gradient(268.05deg, #2E7EBE 4.03%, #2E7EBE 101.81%)",
        boxShadow: `0px 0px 0px 4px ${alpha(theme.palette.primary.main, 0.1)}`,
    },
}));

export default function MyButton(props) {
    return <UnstyledButon {...props}>{props.children}</UnstyledButon>;
}

export const MyButtonLoading = (props) => {
    return (
        <UnstyledButon
            onClick={props.handleSubmit}
            loading={props.loading}
            component={LoadingButton}
            {...props}
        >
            {props.children}
        </UnstyledButon>
    );
};
