import { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
    ProfissionaisProvider,
} from "../../../contexts/service";
import ProfissionaisAvaliarTable from "../../../components/tables/profissionaisAvaliarTable";
import { AuthContext } from "../../../contexts/auth";
import styles from "./styles.module.scss";

const TurmaTreinamentoPage = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    useEffect(() => {
        setCurrentScreen(8);
    }, []);

    return (
        <ProfissionaisProvider>
            <div className={`justify-content-center ${styles.containerList}`}>
                <Container>
                    <ProfissionaisAvaliarTable />
                </Container>
            </div>
        </ProfissionaisProvider>
    );
};

export default TurmaTreinamentoPage;
