import React, { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import { Container, Button } from "react-bootstrap";
import Breadcrumb from "./../../components/breadcrumb/index";
import Title from "../../components/title";
import { useNavigate } from "react-router-dom";
import "./style.css";

const SairPage = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleLogout = () => {
        logout();
    };
    const handleCancel = () => {
        navigate("/");
    };

    return (
        <Container fluid className="justify-content-start">
            <Breadcrumb data={{ page: "sair" }} />
            <Title title="Sair do Sistema" />
            <div id="sair">
                <h3>Tem certeza que deseja sair do Sistema Web Contrate.ba?</h3>
                <div className="d-flex justify-content-between">
                    <Button
                        variant="outline-primary"
                        onClick={handleCancel}
                        className="cancel"
                        size="lg"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleLogout}
                        className="confirm"
                        size="lg"
                    >
                        Sair
                    </Button>
                </div>
            </div>
        </Container>
    );
};

export default SairPage;
