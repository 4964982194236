import { Image, View } from "@react-pdf/renderer";
import React from "react";

export default function HeaderPDF({ styles }) {
    return (
        <View style={styles.header} fixed>
            <View
                style={{
                    marginLeft: 10,
                    alignItems: "center",
                    justifyContent: "flex-start",
                    // border: "1px dashed #000",
                }}
            >
                <Image
                    src="/images/logo.png"
                    style={{
                        width: 153,
                        height: 23,
                    }}
                />
            </View>
            <View
                style={{
                    marginRight: 10,
                    alignItems: "center",
                    justifyContent: "flex-end",
                    // border: "1px dashed #000",
                }}
            ></View>
        </View>
    );
}
