import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "./../../contexts/user";
import Footer from "./footer/index";
import styles from "./layout.module.scss";
import LayoutNavbar from "./layoutNavbar";

const ReturnInfo = ({ children }) => {
    const { screenLogin } = useContext(UserContext);

    return (
        <>
            {!screenLogin ? (
                <Grid
                    container
                    sx={{
                        position: "relative",
                        maxWidth: "1440px",
                        minHeight: "1024px",
                        minWidth: "350px",
                        margin: "auto",
                    }}
                >
                    <LayoutNavbar />
                    <Grid item sx={{ width: "100%" }} className={styles.gridLayout}>
                        {children}
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <Footer />
                    </Grid>
                </Grid>
            ) : (
                <div>
                    <LayoutNavbar />
                    <div className={`${styles.gridLayout} ${styles.containerGeral}`}>
                        {children}
                    </div>
                    <Grid item sx={{ width: "100%" }}>
                        <Footer />
                    </Grid>
                </div>
            )}
            
        </>
    );
};

export default ReturnInfo;
