import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import btedit from "./../../../assets/images/bt-edit.png";

export function ListTable({ currentItems, unidades }) {

    const getColorStatus = (status) => {
        switch (status) {
            case "ativo":
                return "#95C11F";
            case "inativo":
                return "#E60E19";
            default:
                return "#656A6E";
        }
    };

    return (
        <div className={`${styles.maginTop} table-responsive`}>
            <table className={`table align-middle ${styles.table}`}>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Nome</th>
                        <th>Perfil</th>
                        <th>Unidade</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody data-test="table-body">
                    {currentItems.length > 0 ? currentItems.map((val, idx) => {
                        const colorTr = idx % 2 == 0 ? styles.colorTrOne : styles.colorTrTwo;
                        const unidade = unidades.find((el) => el.id === val.unidadeId);
                        
                        return (
                            <tr className={colorTr} key={val.id.toString()}>
                                <td className={styles.boxTd}>
                                    <div
                                        className={styles.boxColor}
                                        style={{
                                            backgroundColor: getColorStatus(
                                                val.usuario?.flagAtivo ? "ativo" : "inativo",
                                            ),
                                        }}
                                    >
                                        <span
                                            className={
                                                styles.pillBadgesText
                                            }
                                        >
                                            {(val.usuario?.flagAtivo ? "ativo" : "inativo")}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    {val.nome}
                                </td>
                                <td>
                                    {val.funcao.nome}
                                </td>
                                <td>
                                    {unidade?.nome}
                                </td>
                                <td>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Link to={"/operador/editar?id=" + val.id}>
                                            <img src={btedit} alt="Editar" title="Editar" />
                                        </Link>
                                    </Stack>
                                </td>
                            </tr>
                        )
                    }) : <></>}
                </tbody>
            </table>
        </div>
    );
}