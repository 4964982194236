import { Grid } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MySidebar from "../components/sidebar";
import { Api, ApiLogin } from "../providers";
import { LoadingProvider } from "./service";
import { UserContext } from "./user";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const { printLayout, setIsLoggedUser, setPrintLayout } =
        useContext(UserContext);
    //TODO implementar animação no carregamento
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [currentScreen, setCurrentScreen] = useState(0);

    //Usuário
    const setUserData = async (data) => {
        if (data) {
            setUser({
                user: data,
                token: data.token,
            });
            setPrintLayout(false);
            setIsLoggedUser(true);
            try {
                let status1 = false;
                let status2 = false;
                await Promise.all([
                    Api.get("/operador/tela").then((res) => {
                        if (res.status == 200) {
                            status1 = true;
                            localStorage.setItem(
                                "telas",
                                JSON.stringify(res.data),
                            );
                        }
                    }),
                    Api.get("/operador/" + data.operador.id).then((res) => {
                        if (res.status == 200) {
                            status2 = true;
                            localStorage.setItem(
                                "permissoes",
                                JSON.stringify(res.data.permissoes),
                            );
                        }
                    }),
                ]);
                if (status1 && status2) {
                    setLoading(false);
                    //Important!
                    window.location.href = "/servico";
                }
            } catch (_) {
                setLoading(false);
            }
        }
    };

    //LocalStorage
    const setStorage = (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("token", data.token);
    };
    const getStorage = JSON.parse(localStorage.getItem("user"));
    const getToken = localStorage.getItem("token");

    // Login no sistema
    const login = async (cpf, password) => {
        setLoading(true);
        const requisicao = {
            login: cpf,
            senha: password,
            perfil: "OPERADOR",
        };
        try {
            const res = await ApiLogin.post("login", requisicao);
            if (res.status === 200 && res.data) {
                setStorage(res.data);
                setTimeout(() => {
                    setUserData(res.data);
                }, 2000);
            }
        } catch (err) {
            setLoading(false);
            let message =
                typeof err.response !== "undefined"
                    ? err.response.data.message
                    : err.message;

            toast.error(`Ops! ${message.error}`, {
                toastId: "error",
            });
        }
    };

    // Sair do sistema
    const logout = () => {
        //Reset ALL
        localStorage.clear();
        setUser(null);
        setIsLoggedUser(false);
        setLoading(false);

        toast.warn("Você saiu do Sistema", {
            toastId: "warn",
            autoClose: 1000,
        });

        navigate("/login");
    };

    //Atualizar token
    const refreshToken = () => {
        setTimeout(() => {
            if (getToken) {
                // console.log(getToken);
            }
        }, 5000);
    };

    //Verifica se autenticado. Poderá ver se o token é válido
    const isAuthenticated = () => {
        return !!getStorage;
    };

    return (
        // authenticated : true | false
        <AuthContext.Provider
            value={{
                authenticated: isAuthenticated(),
                user,
                login,
                logout,
                refreshToken,
                currentScreen,
                setCurrentScreen,
                loading,
            }}
        >
            <LoadingProvider>
                <Grid container spacing={4}>
                    {/* Imprime layout publico, sem estar logado */}
                    {isAuthenticated() === false ? (
                        <Grid item>{children}</Grid>
                    ) : !!printLayout ? (
                        /*  Layout Private */
                        <Grid item>{children}</Grid>
                    ) : (
                        /* Layout Private */
                        <>
                            <Grid item xs={6} sm={3} md={3}>
                                <MySidebar currentScreen={currentScreen} />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={8}
                                md={8}
                                sx={{
                                    // width: "100%",
                                    // maxWidth: "1056px",
                                    // minHeight: "943px",
                                    margin: "32px 0px 0px 32px",
                                }}
                            >
                                {children}
                            </Grid>
                        </>
                    )}
                </Grid>
            </LoadingProvider>
        </AuthContext.Provider>
    );
};
