import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../providers";
import { ServiceContext } from "./../../../contexts/service";
import { ListTable } from "./listTable";
import styles from "./styles.module.scss";
import ResultNotFound from "../../resultNotFound";

const OperadoresTable = () => {
    const {
        operadores, setOperadores, loading, setLoading, totalPages,
        setTotalPages, paramsFilter,
    } = useContext(ServiceContext);
    const navigate = useNavigate();
    
    const [currentPage, setCurrentPage] = useState(1);
    const [unidades, setUnidades] = useState([]);
    
    let mounted = true;
    const token = localStorage.getItem("token");

    useEffect(() => {
        Api.get(`/operador/pagination/0/10`).then((res) => {
            if (mounted) {
                setOperadores(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            }
        }).catch(_ => {
            if (mounted) {
                setLoading(false);
            }
        });
        Api.get("/unidade/").then((res) => {
            if (res.status === 200) {
                setUnidades(res.data);
            }
        });
        return () => {
            mounted = false;
            setOperadores([]);
        };
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [paramsFilter]);

    const nextPage = (page = 0) => {
        setLoading(true);
        const urlData = `/operador/pagination/${page}/10`;
        const params = paramsFilter;
        Api.get(urlData, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        }).then((res) => {
            setOperadores(res.data.content);
            setTotalPages(res.data.totalPages);
            setLoading(false);
        }).catch(_ => {
            setLoading(false);
        });
    }
    const currentItems = operadores;
    const pageCountData = totalPages;

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }
    
    return (
        <>            
            {currentItems.length > 0 && (
                <ListTable currentItems={currentItems} unidades={unidades} />
            )}

            {currentItems.length <= 0 && (
                <ResultNotFound style={{marginBottom: 20, marginTop: 20}} />
            )}

            <div className={styles.reactPaginate}>
                <ReactPaginate
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCountData}
                    previousLabel="<"
                    nextLabel=">"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName={styles.pagination}
                    pageClassName={styles.pageItem}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={styles.pageLink}
                    nextClassName={styles.pageItem}
                    nextLinkClassName={styles.pageLink}
                    activeClassName={"active"}
                    forcePage={currentPage - 1}
                    disabledClassName={styles.disabled}
                />
            </div>
        </>
    );
};

export default OperadoresTable;
