import { Container } from "@mui/material";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { ServiceContext } from "../../../contexts/service";
import { Api } from "../../../providers";
import { ModalJustificativa } from "../../modal";
import ResultNotFound from "../../resultNotFound";
import { ListTable } from "./listTable";
import styles from "./styles.module.scss";

export const Pontuacao = ({ idQuery }) => {
    const {
        loading,
        setLoading,
        totalPages,
        setTotalPages,
        paramsFilter,
        setOccurrences,
        occurrences,
    } = useContext(ServiceContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPontos, setTotalPontos] = useState(0);
    const [statusOpenModal, setStatusOpenModal] = useState(false);
    const [descModal, setDescModal] = useState("");
    const [operModal, setOperModal] = useState("");
    const [pontModal, setPontModal] = useState("");
    const [idOcorrencia, setIdOcorrencia] = useState(0);

    const itemsPerPage = 3;
    let mounted = true;

    const resultTabela = () => {
        Api.get(
            `/ocorrencia/pagination/0/${itemsPerPage}?profissionalId=${idQuery}&solicitante=cliente&status=Finalizada`,
        )
            .then((res) => {
                if (mounted) {
                    setOccurrences(res.data.content);
                    setTotalPages(res.data.totalPages);
                    setLoading(false);
                }
            })
            .catch((error) => {
                if (mounted) {
                    setLoading(false);
                }
            });
        return () => {
            mounted = false;
            setOccurrences([]);
        };
    };

    const resultData = () => {
        Api.get(
            `ocorrencia/pontuacao?profissionalId=${idQuery}&solicitante=cliente`,
        )
            .then((res) => {
                if (mounted && res.status === 200) {
                    setTotalPontos(res.data > 0 ? res.data : 0);
                    setLoading(false);
                }
            })
            .catch((_) => {
                if (mounted) {
                    setLoading(false);
                    toast.error("Ops! Aconteceu algum problema!", {
                        toastId: "msg",
                    });
                }
            });
    };

    useEffect(() => {
        resultTabela();
        resultData();
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [paramsFilter]);

    const nextPage = (page = 0) => {
        setLoading(true);
        const params = paramsFilter;
        Api.get(
            `ocorrencia/pagination/${page}/${itemsPerPage}?profissionalId=${idQuery}&solicitante=cliente&status=Finalizada`,
            {
                params,
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                },
            },
        )
            .then((res) => {
                setOccurrences(res.data.content);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            })
            .catch((_) => {
                setLoading(false);
            });
    };

    const currentItems = occurrences;
    const pageCountData = totalPages;

    const handlePageClick = (event) => {
        const total = parseInt(event.selected) + 1;
        setCurrentPage(total);
        nextPage(event.selected);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando</span>
                </div>
            </div>
        );
    }

    //Get Rows
    return (
        <div className={styles.containerTable}>
            {currentItems.length > 0 && (
                <ListTable
                    currentItems={currentItems}
                    totalPontos={totalPontos}
                    descModal={setDescModal}
                    operModal={setOperModal}
                    pontModal={setPontModal}
                    idOcorrencia={setIdOcorrencia}
                    statusOpenModal={setStatusOpenModal}
                />
            )}

            {totalPontos <= 0 && currentItems.length <= 0 && (
                <div className={styles.resultNotFound}>
                    <ResultNotFound />
                </div>
            )}

            <div className={styles.reactPaginate}>
                <ReactPaginate
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCountData}
                    previousLabel="<"
                    nextLabel=">"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName={styles.pagination}
                    pageClassName={styles.pageItem}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={styles.pageLink}
                    nextClassName={styles.pageItem}
                    nextLinkClassName={styles.pageLink}
                    activeClassName={"active"}
                    forcePage={currentPage - 1}
                    disabledClassName={styles.disabled}
                />
            </div>

            <ModalJustificativa
                styles={{
                    minHeight: "578px",
                    width: "520px",
                }}
                title={`Ocorrência #${idOcorrencia}`}
                description=""
                openModal={statusOpenModal}
                setOpenModal={setStatusOpenModal}
                onClick={() => {}}
                backgroundCacel="#fff"
                disabled={true}
                labelCancel="Fechar"
                removeBtnConf={true}
            >
                <Container maxWidth="xs">
                    <div className={styles.containerModal}>
                        <div className={styles.containerEndOccurrence}>
                            <div>
                                <label className="form-label">
                                    Nome do operador
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${styles.colorBackground}`}
                                    defaultValue={operModal}
                                    disabled
                                />
                            </div>
                            <div className={styles.inputSelect}>
                                <label className={`form-label`}>Pontuar</label>
                                <select
                                    className={`form-select`}
                                    defaultValue={pontModal}
                                    onChange={() => {}}
                                    disabled={true}
                                >
                                    <option value={"Sim"}>Sim</option>
                                    <option value={"Não"}>Não</option>
                                </select>
                            </div>
                            <div>
                                <span
                                    className={`form-label ${styles.textLabel}`}
                                >
                                    Comentário
                                </span>
                                <div>
                                    <section className={styles.section}>
                                        <div className="form-control">
                                            {descModal}
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </ModalJustificativa>
        </div>
    );
};
