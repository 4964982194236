import React, { useContext, useEffect } from "react";
import { BreadCrumb } from "../../../components/breadcrumb";
import RquestTable from "../../../components/tables/auditoria";
import Title from "../../../components/title";
import { AuthContext } from "../../../contexts/auth";
import { AuditoriaProvider } from "../../../contexts/service";
import Search from "./search";
import styles from "./styles.module.scss";

const DashboardAuditoria = () => {
    const { setCurrentScreen } = useContext(AuthContext);

    useEffect(() => {
        setCurrentScreen(12);
    }, []);

    return (
        <AuditoriaProvider>
            <div className={`justify-content-center ${styles.containerList}`}>
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Auditoria",
                    }}
                />
                <Title title="Auditoria" />
                <div className={styles.listagem}>
                    <Search />
                    <RquestTable />
                </div>
            </div>
        </AuditoriaProvider>
    );
};

export default DashboardAuditoria;
