import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalConfirm } from "../../../../components/modal";
import { Api } from "../../../../providers";
import styles from "../styles.module.scss";
import clockIcon from "./../../../../assets/images/clock.png";

const Item = {
    m: 0,
    p: 0,
    width: 400,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: "#343B40",

    "&.MuiInputLabel-root": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "17px",
        paddingLeft: 0,
    },

    "&.MuiFormHelperText-root  ": {
        fontSize: 10,
        alignSelf: "end",
        alignItems: "end",
        color: "red",
    },
    "&.MuiInputBase-input": {
        width: "auto",
        position: "relative",
        border: "1px solid #ced4da",
        fontSize: 16,
        background: "#fff",
    },
    "&.MuiOutlinedInput-root": {
        m: 0,
        p: 0,
        background: "#fff",
    },
};

const StatusCliente = ({ tabData }) => {
    const search = useLocation().search;
    const idQuery = new URLSearchParams(search).get("id");
    const [operador, setOperador] = useState("");
    const [descricao, setDescricao] = useState("");
    const [nome, setNome] = useState("");
    const [ativo, setAtivo] = useState("");
    const [openModal, setOpenModal] = React.useState(false);
    const [disabledInputs, setDisabledInputs] = React.useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (tabData.situacao) {
            setAtivo(tabData.situacao);
            if (tabData.situacao === "Ativo") {
                setDisabledInputs(false);
            }
        }
    }, [tabData]);

    useEffect(() => {
        let mounted = true;
        Api.get(`/observacao/cliente?clienteId=${idQuery}`).then((res) => {
            if (res.status === 200 && mounted) {
                if (res.data && res.data.length > 0) {
                    setDescricao(res.data[0].observacao);
                    // setAtivo(res.data[0].acao);
                    setNome(res.data[0].cliente.nome);
                    setOperador(
                        `${res.data[0].operador.nome} - ${res.data[0].dataBr} `,
                    );
                }
            }
        });
        return () => {
            mounted = false;
        };
    }, [idQuery]);

    const handleSubmit = () => {
        setLoading(true);
        let getStorage = JSON.parse(localStorage.getItem("user"));
        let acao = "";
        let requisicao = {
            observacao: descricao,
            clienteId: idQuery,
            operadorId:
                getStorage.operador.id > 0
                    ? getStorage.operador.id
                    : getStorage.operador.funcao.id,
        };
        acao = ativo === "Inativo" ? "disable" : "enable";
        Api.post(`/observacao/cliente/` + acao, requisicao)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    setOpenModal(false);
                    toast.success(ativo + "Cliente atualizado com sucesso!", {
                        toastId: "msg",
                        autoClose: 1000,
                        onClose: function () {
                            window.location.reload();
                        },
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setOpenModal(false);
                toast.error("Ops! Aconteceu algum erro", {
                    toastId: "msg",
                });
            });
    };

    const statusSit = [
        { id: "Ativo", nome: "Ativo" },
        { id: "Inativo", nome: "Inativo" },
    ];

    const chooseStatus = (value) => {
        setAtivo(value);
        if (value === "Ativo") {
            setDisabledInputs(false);
        } else {
            setDisabledInputs(true);
        }
    };

    return (
        <>
            <div className={styles.formItem}>
                <div className={styles.subContainerGeral}>
                    <div>
                        <span
                            className={`${styles.labelText} ${styles.maginTopLabel}`}
                        >
                            Situação do cliente
                        </span>
                        <div className={styles.fieldSelect}>
                            <Form.Select
                                className="form-select"
                                aria-label="categoryOption"
                                as="select"
                                value={ativo}
                                onChange={(e) => chooseStatus(e.target.value)}
                            >
                                <option value="0" key="0" disabled>
                                    Escolha uma opção
                                </option>
                                {statusSit.map((data, key) => {
                                    return (
                                        <option value={data.id} key={key}>
                                            {data.nome}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </div>
                    </div>

                    <Form.Group>
                        <span
                            className={`${styles.labelText} ${styles.dividerSpace}`}
                        >
                            Observação
                        </span>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            name="Observação"
                            id="observacao"
                            value={descricao}
                            disabled={disabledInputs}
                            maxLength={300}
                            onChange={(e) => setDescricao(e.target.value)}
                            className={`${styles.textAreaComment} ${
                                disabledInputs
                                    ? styles.textAreaCommentDisabled
                                    : {}
                            }`}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                marginTop: 1,
                                width: 816,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: "#8D8F90",
                                    lineHeight: "14.52px",
                                }}
                            >
                                {descricao.length}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: "#8D8F90",
                                    lineHeight: "14.52px",
                                }}
                            >
                                /300 caracteres
                            </Typography>
                        </Box>
                    </Form.Group>
                    {operador && ativo === "Inativo" ? (
                        <Typography
                            sx={{
                                mt: 2,
                                p: 0,
                                display: "inline-flex",
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "14px",
                                alignItems: "center",
                                color: "#343B40",
                            }}
                            variant="span"
                        >
                            <img src={clockIcon} width="12px" />
                            <Box sx={{ pl: 1 }}>{operador}</Box>
                        </Typography>
                    ) : (
                        ""
                    )}

                    <div className={styles.containerInfoButtonStatus}>
                        <div className={styles.containerButtonEditDadosCli}>
                            <button
                                className={styles.buttonSearch}
                                type="button"
                                onClick={() => setOpenModal(true)}
                            >
                                <span>Salvar</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirm
                id="status"
                title={
                    ativo === "Inativo"
                        ? "Inativar cliente"
                        : "Reativar cliente"
                }
                description={`Tem certeza que deseja ${
                    ativo === "Inativo" ? "inativar" : "reativar"
                } ${ativo === "Ativo" ? "o cliente " + nome : "este cliente"}?`}
                openModal={openModal}
                setOpenModal={setOpenModal}
                onClick={handleSubmit}
                color="blue"
                labelConfirm={ativo === "Inativo" ? "Inativar" : "Reativar"}
                loading={loading}
            />
        </>
    );
};

export default StatusCliente;
