import { Grid, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import Breadcrumb from "../../../components/breadcrumb/index";
import SolicitacaoClienteSearch from "../../../components/search/solicitacao/cliente";
import Title from "../../../components/title/index";
import { AuthContext } from "../../../contexts/auth";
import MyAddress from "./../../../components/address/index";
import SolicitacaoClienteTable from "./../../../components/tables/solicitacao/solicitacaocliente";
import TabsPanel from "./../../../components/tabs/solicitacao";
import { ClientesProfissionaisProvider } from "./../../../contexts/service";

export default function CriarSolicitacao() {
    const { setCurrentScreen } = useContext(AuthContext);
    const tabsData = [
        { id: 1, tabLabel: "Por Data Específica", tabData: [] },
        { id: 2, tabLabel: "Por Profissional", tabData: [] },
    ];

    useEffect(() => {
        setCurrentScreen(4);
    }, []);

    return (
        <ClientesProfissionaisProvider>
            <Grid justifyContent="center" sx={{ m: 0, maxWidth: 1057 }}>
                <Breadcrumb
                    data={{
                        category: "Home > Solicitação",
                        page: "Nova Solicitação",
                        menu: {
                            item: "Solicitação",
                            link: "/solicitacao/",
                        },
                    }}
                />
                <Title title="Nova Solicitação de Serviço" />
                <Grid
                    container
                    spacing={2}
                    justify="flex-start"
                    alignItems="flex-start"
                    sx={{ backgroundColor: "#F7F7F7", m: 0, p: 2 }}
                >
                    <Grid item md={12}>
                        <Typography
                            sx={{
                                mb: 0,
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "14px",
                                color: "#343B40",
                            }}
                        >
                            Cliente
                        </Typography>
                        <SolicitacaoClienteSearch />
                    </Grid>
                    <Grid item md={12}>
                        <SolicitacaoClienteTable />
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    sx={{ backgroundColor: "#F7F7F7", m: 0, p: 2 }}
                >
                    <MyAddress />
                </Grid>

                <Grid
                    container
                    spacing={0}
                    sx={{ m: 0, p: 0, borderRadius: 0 }}
                >
                    <Grid item md={12}>
                        <TabsPanel tabsData={tabsData} />
                    </Grid>
                </Grid>
                <Grid
                    sx={{ my: 1, height: "94px", backgroundColor: "#FFF" }}
                ></Grid>
            </Grid>
        </ClientesProfissionaisProvider>
    );
}
