import { Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import btcalendar from "../../../assets/images/eye.png";
import btedit from "../../../assets/images/icons/edit.png";
import { DateBR } from "../../../utils/date";
import styles from "./styles.module.scss";

export function ListTable({
    currentItems,
    totalPontos,
    statusOpenModal,
    descModal,
    operModal,
    pontModal,
    idOcorrencia,
}) {
    const navigate = useNavigate();
    const opemModal = (data) => {
        statusOpenModal(true);
        descModal(data.parecer);
        operModal(data.operador?.nome);
        pontModal(data.pontuar ? "Sim" : "Não");
        idOcorrencia(data.id);
    };

    const handleNewPage = (id) => {
        navigate(`/ocorrencias/detalhamento-ocorrencia/view?id=${id}`);
    };

    return (
        <div className="table-responsive">
            <table
                className={`table table-bordered align-middle ${styles.table}`}
            >
                <thead className={styles.tableHeader}>
                    <tr>
                        <td colSpan={3}>Total de pontos</td>
                        <td colSpan={2}>{totalPontos}</td>
                    </tr>
                </thead>

                {currentItems?.length > 0 && (
                    <>
                        <thead className={styles.textoHeader}>
                            <tr>
                                <th>Cód</th>
                                <th>Data da ocorrência</th>
                                <th>Tipo de ocorrência</th>
                                <th>Pontuação</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody data-test="table-body" className={styles.tbody}>
                            {currentItems?.length > 0 ? (
                                currentItems.map((val, idx) => {
                                    return (
                                        <tr key={idx.toString()}>
                                            <td>#{val.id}</td>
                                            <td>{DateBR(val.dataCadastro)}</td>
                                            <td>{val.ocorrenciaTipo.nome}</td>
                                            <td>{val.pontuacao ?? 0}</td>
                                            <td>
                                                <Stack
                                                    direction="row"
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <div
                                                        onClick={() =>
                                                            opemModal(val)
                                                        }
                                                    >
                                                        <img
                                                            src={btcalendar}
                                                            alt=""
                                                            className={
                                                                styles.imgEYE
                                                            }
                                                            title=""
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            handleNewPage(
                                                                val?.id,
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={btedit}
                                                            alt=""
                                                            title=""
                                                        />
                                                    </div>
                                                </Stack>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </>
                )}
            </table>
        </div>
    );
}
