import { Box } from "@material-ui/core";
import React from "react";
import ImageCheck from "../../../../assets/images/icons/check2.png";
import ImageEye from "../../../../assets/images/icons/eye.png";
import { DateBR, FormatDate } from "../../../../utils/date";
import styles from "./styles.module.scss";

export function ListTable({
    currentItems,
    situacao,
    handleAtualizarPresenca,
    handleAtualizarAprovacao,
    operatorData,
    checkFlegAcoes,
    setConfirmModal,
}) {
    const resultDataSit = (situacaoId) => {
        const result = situacao.filter((value) => value.id == situacaoId)[0];
        if (result && Object.values(result).length > 0) {
            return result;
        } else {
            const resultData = {
                descricao: null,
                hexadecimal: "#0064B4",
                id: 0,
                nome: "",
                slug: "",
            };
            return resultData;
        }
    };

    const resultService = (service) => {
        return (
            <div className={styles.serviceInfo}>
                <span>{service[0].nome}</span>
                {service.length > 1 && <span>+{service.length - 1}</span>}
            </div>
        );
    };

    let checkButton = true;
    let flegItems = [];
    const _ = currentItems.map((val) => {
        flegItems.push({
            treinamentoId: val.treinamentoId,
            flagPresenca: val.flagPresenca,
            flagAprovado: val.flagAprovado,
        });
    });

    if (JSON.stringify(flegItems) != JSON.stringify(checkFlegAcoes)) {
        checkButton = false;
    }

    return (
        <>
            <div className="table-responsive">
                <table className={`table align-middle ${styles.table}`}>
                    <thead>
                        <tr>
                            <th>Situação</th>
                            <th>Data de inscrição</th>
                            <th>Data do treinamento</th>
                            <th>Serviço</th>
                            <th>Presença</th>
                            <th>Aprovação</th>
                        </tr>
                    </thead>
                    <tbody data-test="table-body">
                        {currentItems.length > 0 &&
                            currentItems.map((row) => {
                                let sit = "";
                                let hexadecimal = "";

                                situacao.map((row2) => {
                                    if (
                                        row2.id == row?.profissional?.situacaoId
                                    ) {
                                        sit = row2?.nome;
                                        hexadecimal = row2?.hexadecimal;
                                    }
                                });

                                let checkModal = row?.flagAprovado;

                                if (row?.flagAprovado) {
                                    checkModal = !row?.flagAprovado;
                                }

                                //Verifica se tem avaliacao
                                const checkPrimAval = currentItems?.filter(
                                    (val) =>
                                        val?.treinamentoId ==
                                            row?.treinamentoId &&
                                        val?.flagPresenca == null,
                                );

                                const avalDisp_mostraCor =
                                    row.statusFimTrein && row.statusEditTrein
                                        ? styles.checkbox
                                        : styles.checkboxDisabled;

                                return (
                                    <tr key={row.id}>
                                        <td>
                                            <div
                                                className={styles.boxColor}
                                                style={
                                                    row?.profissional
                                                        ?.situacaoId
                                                        ? {
                                                              backgroundColor:
                                                                  resultDataSit(
                                                                      row
                                                                          ?.profissional
                                                                          ?.situacaoId,
                                                                  )[
                                                                      "hexadecimal"
                                                                  ],
                                                          }
                                                        : {}
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles.pillBadgesText
                                                    }
                                                >
                                                    {row?.profissional
                                                        ?.situacaoId
                                                        ? resultDataSit(
                                                              row?.profissional
                                                                  ?.situacaoId,
                                                          )["nome"]
                                                        : ""}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            {row?.profissional?.dataCadastro
                                                ? FormatDate(
                                                      row?.profissional
                                                          ?.dataCadastro,
                                                      "dd/MM/yyyy",
                                                  )
                                                : ""}
                                        </td>
                                        <td>{DateBR(row.dataTreinamento)}</td>
                                        <td>
                                            {row?.profissional?.servicos
                                                ? resultService(
                                                      row?.profissional
                                                          ?.servicos,
                                                  )
                                                : ""}
                                        </td>
                                        <td>
                                            <Box
                                                sx={{
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    display: "flex",
                                                }}
                                            >
                                                {row?.flagPresenca != null && (
                                                    <div
                                                        className={
                                                            styles.typeCheckbox
                                                        }
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                !row?.flagPresenca
                                                            }
                                                            onChange={() =>
                                                                row.statusFimTrein &&
                                                                row.statusEditTrein &&
                                                                !row.novoTreinamento
                                                                    ? handleAtualizarPresenca(
                                                                          row?.treinamentoId,
                                                                          true,
                                                                      )
                                                                    : {}
                                                            }
                                                            id={
                                                                row.novoTreinamento
                                                                    ? styles.checkboxDisabled
                                                                    : avalDisp_mostraCor
                                                            }
                                                        />
                                                        <div
                                                            onClick={() =>
                                                                row.statusFimTrein &&
                                                                row.statusEditTrein &&
                                                                !row.novoTreinamento
                                                                    ? handleAtualizarPresenca(
                                                                          row?.treinamentoId,
                                                                          !row?.flagAprovado,
                                                                          checkModal,
                                                                      )
                                                                    : {}
                                                            }
                                                        >
                                                            X
                                                        </div>
                                                    </div>
                                                )}

                                                {row?.flagPresenca == null && (
                                                    <div
                                                        className={
                                                            styles.checkToggle
                                                        }
                                                    >
                                                        <span>
                                                            <img
                                                                src={ImageCheck}
                                                            />
                                                        </span>
                                                        <div>X</div>
                                                    </div>
                                                )}
                                            </Box>
                                        </td>
                                        <td>
                                            {row?.flagAprovado != null && (
                                                <div className={styles.flagApr}>
                                                    <div
                                                        className={
                                                            styles.typeCheckbox
                                                        }
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                !row?.flagAprovado
                                                            }
                                                            onChange={() =>
                                                                row.statusFimTrein &&
                                                                row.statusEditTrein &&
                                                                !row.novoTreinamento
                                                                    ? handleAtualizarAprovacao(
                                                                          row?.treinamentoId,
                                                                          !row?.flagAprovado,
                                                                          checkModal,
                                                                      )
                                                                    : {}
                                                            }
                                                            id={
                                                                row.novoTreinamento
                                                                    ? styles.checkboxDisabled
                                                                    : avalDisp_mostraCor
                                                            }
                                                        />
                                                        <div
                                                            onClick={() =>
                                                                row.statusFimTrein &&
                                                                row.statusEditTrein &&
                                                                !row.novoTreinamento
                                                                    ? handleAtualizarAprovacao(
                                                                          row?.treinamentoId,
                                                                          !row?.flagAprovado,
                                                                          checkModal,
                                                                      )
                                                                    : {}
                                                            }
                                                        >
                                                            X
                                                        </div>
                                                    </div>
                                                    {row?.flagAprovado ==
                                                        false &&
                                                        checkPrimAval?.length <=
                                                            0 && (
                                                            <img
                                                                src={ImageEye}
                                                                onClick={() =>
                                                                    operatorData(
                                                                        row?.profissionalId,
                                                                        row.novoTreinamento,
                                                                        row?.treinamentoId,
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            )}

                                            {row?.flagAprovado == null && (
                                                <div
                                                    className={
                                                        styles.checkToggle
                                                    }
                                                >
                                                    <span>
                                                        <img src={ImageCheck} />
                                                    </span>
                                                    <div>X</div>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div className={styles.containerButton}>
                <button
                    className={
                        checkButton ? styles.buttonSaveBlock : styles.buttonSave
                    }
                    type="button"
                    onClick={
                        !checkButton ? () => setConfirmModal(true) : () => {}
                    }
                    disabled={checkButton ? true : false}
                >
                    <span>Salvar</span>
                </button>
            </div>
        </>
    );
}
