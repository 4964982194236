import { Container } from "@mui/material";
import { format } from "date-fns";
import moment from "moment/moment";
import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { ic_remove_red_eye_outline } from "react-icons-kit/md/ic_remove_red_eye_outline";
import { useNavigate } from "react-router-dom";
import DownloadModalPDF from "../../../download/pdf/modal";
import { ModalLoading } from "../../../modal";
import btbook from "./../../../../assets/images/book.png";
import btedit from "./../../../../assets/images/bt-edit.png";
import { MyRating } from "./../../../../components/rating";
import { DateBR, dateStringToObject } from "./../../../../utils/date";
import { limitString } from "./../../../../utils/string/index";
import styles from "./profissional.module.scss";

export function ListTable({ currentItems, idQuery }) {
    const navigate = useNavigate();
    const [openModalVerRecibo, setOpenModalVerRecibo] = useState(false);
    const [profissional, setProfissional] = useState([]);
    const [solicitacao, setSolicitacao] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);

    //Color Status
    const getColorStatus = (status) => {
        switch (status) {
            case "A Confirmar":
                return "#F9B000";
            case "Confirmado":
                return "#95C11F";
            case "Cancelado":
                return "#E60E19";
            case "Realizado":
                return "#0064B4";
            case "Avaliado":
                return "#1486E1";
            case "Expirado":
                return "#E60E19";
            case "Não Realizado":
                return "#656A6E";
            default:
                return "#656A6E";
        }
    };

    const valorServico = (row) => {
        if (row.valor) return `R$${row.valor}`;
        if (row.servico.servicoValor) return `R$${row.servico.servicoValor}`;
        if (row.servico.taxaVisitaValor)
            return `R$${row.servico.taxaVisitaValor}`;
        return `-`;
    };

    const handleRedirectEdit = (row) => {
        navigate(
            `/aceitar-solicitacao/?id=${row.id}&idProfissional=${idQuery}&preview=profissional`,
        );
    };

    const handleAcceptRequest = (row) => {
        navigate(
            `/aceitar-solicitacao/?id=${row.id}&idProfissional=${idQuery}&preview=profissional`,
        );
    };

    const checkIfDateIsInOneDayRange = (d, h) => {
        let todayDate = dateStringToObject(new Date(), "YYYY-MM-DD H:mm");
        let myDate = dateStringToObject(d + h, "YYYY-MM-DD H:mm");
        let ms = moment(todayDate).diff(moment(myDate));
        let d1 = moment.duration(ms);
        const totalHours = Math.floor(d1.asHours());

        if (totalHours > 0) return true;
    };

    const checkModalPDF = (sol, prof) => {
        setLoadingModal(true);
        setSolicitacao(sol);
        setProfissional(prof);
        setTimeout(() => {
            setOpenModalVerRecibo(true);
            setLoadingModal(false);
        }, 1000);
    };

    const formatTime = (dataList, timeList) => {
        let customTime = "";
        if (timeList) {
            const exactDate = `${dataList} ${timeList}`;
            const exactDateTimestamp = new Date(exactDate).getTime();
            if (exactDateTimestamp) {
                customTime = format(exactDateTimestamp, "H:mm");
            }
        }
        return customTime;
    };

    return (
        <>
            <div className="table-responsive">
                <table className={`table align-middle ${styles.table}`}>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Nº de Solicitação</th>
                            <th>Serviço</th>
                            <th>Data do Serviço</th>
                            <th>Hora</th>
                            <th>Nome do Cliente</th>
                            <th>Valor</th>
                            <th>Endereço</th>
                            <th>Avaliação</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody data-test="table-body">
                        {currentItems.length > 0 ? (
                            currentItems.map((val, idx) => {
                                const colorTr =
                                    checkIfDateIsInOneDayRange(
                                        val.datas[0],
                                        val.hora,
                                    ) && val.status == "Confirmado"
                                        ? styles.colorTrRed
                                        : idx % 2 == 0
                                        ? styles.colorTrOne
                                        : styles.colorTrTwo;
                                const titulo = val.endereco.titulo;
                                const logradouro = val.endereco.logradouro;
                                const numero = val.endereco.numero;
                                const bairro = val.endereco.bairro;
                                const cidade = val.endereco.cidade;
                                const enderecoCompleto = `${logradouro} Nº ${numero}, ${bairro} - ${cidade} (${titulo})`;
                                return (
                                    <tr
                                        className={colorTr}
                                        key={val.id.toString()}
                                    >
                                        <td>
                                            <div
                                                className={styles.boxColor}
                                                style={{
                                                    backgroundColor:
                                                        getColorStatus(
                                                            val.status,
                                                        ),
                                                }}
                                            >
                                                <span
                                                    className={
                                                        styles.pillBadgesText
                                                    }
                                                >
                                                    {val.status}
                                                </span>
                                            </div>
                                        </td>
                                        <td>{val.id}</td>
                                        <td>
                                            {limitString(
                                                val.servico?.servico.nome,
                                            )}
                                        </td>
                                        <td>{DateBR(val.datas[0])}</td>
                                        <td>
                                            {formatTime(val.datas[0], val.hora)}
                                        </td>
                                        <td>{val?.cliente?.nome}</td>
                                        <td>{valorServico(val)}</td>
                                        <td>{enderecoCompleto}</td>
                                        <td>
                                            <MyRating
                                                value={
                                                    val.avaliacao
                                                        ? val.avaliacao
                                                        : 0
                                                }
                                            />
                                        </td>
                                        <td>
                                            <div className={styles.actionIcon}>
                                                {[
                                                    "A Confirmar",
                                                    "Confirmado",
                                                    "Realizado",
                                                    "Avaliado",
                                                    "Não Realizado",
                                                ].findIndex(
                                                    (status) =>
                                                        status === val.status,
                                                ) !== -1 && (
                                                    <div
                                                        className={
                                                            styles.cursorPointer
                                                        }
                                                        onClick={() =>
                                                            handleRedirectEdit(
                                                                val,
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={btedit}
                                                            alt="editar"
                                                        />
                                                    </div>
                                                )}
                                                {(val.status === "Realizado" ||
                                                    val.status ===
                                                        "Avaliado") && (
                                                    <div
                                                        className={
                                                            styles.cursorPointer
                                                        }
                                                        onClick={() =>
                                                            checkModalPDF(
                                                                val,
                                                                val.profissionais,
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={btbook}
                                                            alt="recibo"
                                                        />
                                                    </div>
                                                )}
                                                {(val.status === "Cancelado" ||
                                                    val.status ===
                                                        "Expirado") && (
                                                    <div
                                                        style={{
                                                            color: "#0064B4",
                                                        }}
                                                        onClick={() =>
                                                            handleAcceptRequest(
                                                                val,
                                                            )
                                                        }
                                                    >
                                                        <Icon
                                                            size={24}
                                                            icon={
                                                                ic_remove_red_eye_outline
                                                            }
                                                            alt="ver"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>

            <DownloadModalPDF
                solicitacao={solicitacao}
                profissional={profissional}
                openModalVerRecibo={openModalVerRecibo}
                setOpenModalVerRecibo={setOpenModalVerRecibo}
            />
            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loadingModal}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
}
