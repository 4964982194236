/* eslint-disable no-unused-vars */
import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import imageTrash from "../../../assets/images/icons/trash3.png";
import { ModalConfirm, ModalLoading } from "../../../components/modal";
import { AuthContext } from "../../../contexts/auth";
import { Api } from "../../../providers";
import { BreadCrumb } from "./../../../components/breadcrumb/index";
import Title from "./../../../components/title/index";
import styles from "./styles.module.scss";

const Input = styled("input")({
    display: "none",
});

const CriarServicoPage = () => {
    const { setCurrentScreen } = useContext(AuthContext);
    const navigate = useNavigate();
    const [nome, setNome] = useState("");
    const [descricao, setDescricao] = useState("");
    const [atribuicoes, setAtribuicoes] = useState("");
    const [observacoes, setObservacoes] = useState("");
    const [ativo, setAtivo] = useState(true);
    const [tipoAgendamento, setTipoAgendamento] = useState("0");
    const [categoriaId, setCategoriaID] = useState("0");
    const [categorias, setCategorias] = useState([]);
    const [imageLocal, setImageLocal] = useState("");
    const [image, setImage] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const operador = JSON.parse(localStorage.getItem("user"));
    const checkOp =
        operador.operador.funcao.slug === "administrador" ? true : false;

    const handleRedirect = () => {
        navigate("/servico");
    };

    useEffect(() => {
        let mounted = true;
        Api.get("/categoria/").then((res) => {
            if (mounted) {
                const result = res.data.filter((data) => data.flagAtivo);
                setCategorias(result);
            }
        });
        return () => (mounted = false);
    }, []);

    useEffect(() => {
        if (!checkOp) {
            handleRedirect();
        }
        setCurrentScreen(6);
    }, []);

    const handleSubmit = () => {
        //Validação

        if (nome === "") {
            return;
        }
        if (descricao === "") {
            return;
        }
        if (atribuicoes === "") {
            return;
        }
        if (tipoAgendamento === "") {
            return;
        }
        if (categoriaId === 0) {
            return;
        }

        const servico = {
            nome: nome,
            slug: "",
            descricao: descricao,
            atribuicao: atribuicoes,
            observacao: observacoes,
            flagAtivo: ativo,
            tipoAgendamento: tipoAgendamento,
            categoriaId: parseInt(categoriaId),
        };
        setLoading(true);
        setConfirmModal(false);

        const formData = new FormData();
        formData.append("file", image);
        formData.append("servico", JSON.stringify(servico));

        Api.post("/servico/", formData)
            .then((res) => {
                toast.success("Cadastrado com sucesso", {
                    toastId: "success",
                });
                setLoading(false);
                handleRedirect();
            })
            .catch((err) => {
                setLoading(false);
                const checkErro = err;
                toast.error(`Ops! ${checkErro.response.data.message?.error}`, {
                    toastId: "error",
                });
            });
    };

    if (!checkOp) {
        return <></>;
    }

    const handleFilesChange = (event) => {
        const checkData = ["image/png", "image/jpg", "image/jpeg"];
        const checkData2 = ["png", "jpg", "jpeg"];
        const checkType = event?.target?.files[0].name.split(".").pop();
        if (
            checkData.includes(event?.target?.files[0]?.type) &&
            checkData2.includes(checkType)
        ) {
            setImageLocal(URL.createObjectURL(event.target.files[0]));
            setImage(event.target.files[0]);
        }
    };

    const handleClearFiles = () => {
        document.getElementById("contained-button-file").value = "";
        setImageLocal("");
        setImage("");
    };

    const checkNome = nome.trim() && nome.length > 0 ? true : false;
    const checkCat =
        Number(categoriaId) > 0 &&
        (tipoAgendamento === "Diária" || tipoAgendamento === "Orçamento")
            ? true
            : false;
    const checkDesc = descricao.trim() && descricao.length > 0 ? true : false;
    const checkAtrib =
        atribuicoes.trim() && atribuicoes.length > 0 ? true : false;
    const checkImage = imageLocal != "" && imageLocal.length > 0 ? true : false;
    const checkButtonSubmit =
        checkNome && checkDesc && checkCat && checkAtrib && checkImage
            ? true
            : false;

    return (
        <>
            <Container maxWidth="xl">
                <BreadCrumb
                    data={{
                        category: "",
                        page: "Criar serviço",
                        menu: {
                            item: "Categorias e Serviços",
                            item2: "Serviços",
                            link: "/servico",
                        },
                    }}
                />
                <Title title="Criar serviço" />
                <div className={styles.containerGeral}>
                    <div className={styles.subContainerGeral}>
                        <form className={styles.formularioItem}>
                            <Form.Group>
                                <span className={styles.labelText}>
                                    Nome do serviço
                                </span>
                                <Form.Control
                                    className={styles.containerInputFull}
                                    type="text"
                                    placeholder="Nome do serviço"
                                    name="nome"
                                    id="nome"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    maxLength={100}
                                    size="lg"
                                />
                            </Form.Group>
                            <div
                                className={`${styles.containerSelect} ${styles.maginTopLabel}`}
                            >
                                <div>
                                    <span className={`${styles.labelText}`}>
                                        Categoria
                                    </span>
                                    <div className={styles.fieldSelect}>
                                        <Form.Select
                                            className="form-select"
                                            aria-label="categoryOption"
                                            as="select"
                                            value={categoriaId}
                                            onChange={(e) =>
                                                setCategoriaID(e.target.value)
                                            }
                                        >
                                            <option value="0" key="0" disabled>
                                                Selecione a categoria
                                            </option>
                                            {categorias.map((data, key) => {
                                                return (
                                                    <option
                                                        value={data.id}
                                                        key={key}
                                                    >
                                                        {data.nome}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </div>
                                </div>

                                <div>
                                    <span className={`${styles.labelText}`}>
                                        Tipo de agendamento
                                    </span>
                                    <div className={styles.fieldSelect}>
                                        <Form.Select
                                            className="form-select"
                                            aria-label="categoryOption"
                                            as="select"
                                            value={tipoAgendamento}
                                            onChange={(e) =>
                                                setTipoAgendamento(
                                                    e.target.value,
                                                )
                                            }
                                        >
                                            <option value="0" key="0" disabled>
                                                Selecione o tipo de agendamento
                                            </option>
                                            <option value="Diária">
                                                Diária
                                            </option>
                                            <option value="Orçamento">
                                                Orçamento
                                            </option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>

                            <Form.Group>
                                <span
                                    className={`${styles.labelText} ${styles.maginTopLabel}`}
                                >
                                    Descrição
                                </span>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="descricao"
                                    id="descricao"
                                    placeholder="Campo obrigatório: descreva o serviço"
                                    value={descricao}
                                    maxLength={200}
                                    onChange={(e) => {
                                        setDescricao(e.target.value);
                                    }}
                                    className={styles.textAreaComment}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        marginTop: 1,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: 12,
                                            fontWeight: 700,
                                            color: "#8D8F90",
                                            lineHeight: "14.52px",
                                        }}
                                    >
                                        {descricao.length}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            color: "#8D8F90",
                                            lineHeight: "14.52px",
                                        }}
                                    >
                                        /200 caracteres
                                    </Typography>
                                </Box>
                            </Form.Group>
                            <Form.Group>
                                <span
                                    className={`${styles.labelText} ${styles.maginTopLabel}`}
                                >
                                    Atribuições
                                </span>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="atribuicoes"
                                    id="atribuicoes"
                                    placeholder="Campo obrigatório: descreva as atribuições do serviço"
                                    value={atribuicoes}
                                    onChange={(e) =>
                                        setAtribuicoes(e.target.value)
                                    }
                                    maxLength={300}
                                    className={styles.textAreaComment}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        marginTop: 1,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: 12,
                                            fontWeight: 700,
                                            color: "#8D8F90",
                                            lineHeight: "14.52px",
                                        }}
                                    >
                                        {atribuicoes.length}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            color: "#8D8F90",
                                            lineHeight: "14.52px",
                                        }}
                                    >
                                        /300 caracteres
                                    </Typography>
                                </Box>
                            </Form.Group>

                            <Form.Group>
                                <span
                                    className={`${styles.labelText} ${styles.maginTopLabel}`}
                                >
                                    Observações
                                </span>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="observacoes"
                                    id="observacoes"
                                    placeholder="Opcional"
                                    value={observacoes}
                                    maxLength={200}
                                    onChange={(e) =>
                                        setObservacoes(e.target.value)
                                    }
                                    className={styles.textAreaComment}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        marginTop: 1,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: 12,
                                            fontWeight: 700,
                                            color: "#8D8F90",
                                            lineHeight: "14.52px",
                                        }}
                                    >
                                        {observacoes.length}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            color: "#8D8F90",
                                            lineHeight: "14.52px",
                                        }}
                                    >
                                        /200 caracteres
                                    </Typography>
                                </Box>
                            </Form.Group>
                        </form>
                        <div className={styles.containerBtnUpload}>
                            <Input
                                accept="image/png, image/jpg, image/jpeg"
                                id="contained-button-file"
                                type="file"
                                onChange={(e) => {
                                    handleFilesChange(e);
                                }}
                            />
                            {imageLocal ? (
                                <>
                                    <div className={styles.imageSelected}>
                                        <img src={imageLocal} />
                                    </div>
                                    <div
                                        className={styles.imageTrash}
                                        onClick={handleClearFiles}
                                    >
                                        <img src={imageTrash} />
                                    </div>
                                </>
                            ) : (
                                <label htmlFor="contained-button-file">
                                    <span className={styles.textBtnUpload}>
                                        Adicionar imagem
                                    </span>
                                </label>
                            )}
                        </div>
                        <div className={styles.containerBtn}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#0064B4",
                                        lineHeight: "19.36px",
                                        marginBottom: "29px",
                                    }}
                                >
                                    Status
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        width: 200,
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: "#343B40",
                                            lineHeight: "16.94px",
                                        }}
                                    >
                                        Ativo
                                    </Typography>
                                    <Box sx={{ marginLeft: 2 }}>
                                        <input
                                            type="checkbox"
                                            checked={ativo}
                                            onChange={(e) => setAtivo(!ativo)}
                                            id={styles.checkbox}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <div
                                onClick={() =>
                                    checkButtonSubmit
                                        ? setConfirmModal(true)
                                        : {}
                                }
                            >
                                <button
                                    className={
                                        !checkButtonSubmit
                                            ? styles.buttonSearchBlock
                                            : styles.buttonSave
                                    }
                                    disabled={!checkButtonSubmit}
                                    type="button"
                                >
                                    <span>Salvar</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <ModalConfirm
                title="Salvar novo serviço"
                description="Tem certeza que deseja salvar o novo serviço?"
                openModal={confirmModal}
                setOpenModal={setConfirmModal}
                onClick={() => handleSubmit()}
                labelConfirm="Salvar"
                labelCancel="Cancelar"
                color="blue"
                labelXSair="nao"
            />
            <ModalLoading
                styles={{
                    width: "200px",
                }}
                openModal={loading}
                setOpenModal={() => {}}
            >
                <Container>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        />
                        <span className="visually">Aguarde</span>
                    </div>
                </Container>
            </ModalLoading>
        </>
    );
};

export default CriarServicoPage;
