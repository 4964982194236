import moment from "moment";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import MyDatePicker from "../../../components/datepicker";
import { ServiceContext } from "../../../contexts/service";
import { useUnidades } from "../../../hooks/useScriptRef";
import { Api } from "../../../providers";
import { limitString } from "../../../utils/string";
import { SearchField } from "./components/searchField";
import styles from "./styles.module.scss";

const Search = () => {
    const {
        setRequests,
        setLoading,
        setTotalPages,
        setTotalElements,
        setParamsFilter,
    } = useContext(ServiceContext);
    const unidadesServer = useUnidades();
    const [busca, setBusca] = useState("");

    const [servicoID, setServicoID] = useState("");
    const [unidadeID, setUnidadeID] = useState("");
    const [status, setStatus] = useState("");
    const [situacao, setSituacao] = useState([]);
    const [servicos, setServicos] = useState([]);
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);

    useEffect(() => {
        let mounted = true;

        async function filterData() {
            try {
                await Promise.all([
                    Api.get("/tarefa/status").then((res) => {
                        if (res.status === 200 && mounted) {
                            setSituacao(res.data);
                        }
                    }),
                    Api.get("/servico/").then((res) => {
                        if (mounted) {
                            const result = res.data.filter(
                                (data) => data.flagAtivo,
                            );
                            setServicos(result);
                        }
                    }),
                ]);
            } catch (_) {
                toast.error("Ops! Aconteceu algum problema com os filtros!", {
                    toastId: "msg",
                });
            }
        }

        filterData();
        return () => {
            mounted = false;
        };
    }, []);

    const handleSumbit = (event) => {
        setLoading(true);
        event.preventDefault();

        let params = {};
        if (busca !== "") params.fields = busca;
        if (status !== "" && status != "0") params.status = status;
        if (unidadeID !== "0") params.unidadeId = unidadeID;
        if (servicoID !== "0" && servicoID != "0") params.servicoId = servicoID;
        if (dataInicio !== null)
            params.dataInicio = moment(dataInicio, "YYYY-MM-DD").format(
                "YYYY-MM-DD",
            );
        if (dataFim !== null)
            params.dataFim = moment(dataFim, "YYYY-MM-DD").format("YYYY-MM-DD");

        Api.get(`/tarefa/pagination/0/10?`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                // Provider
                setRequests(res.data.content);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setParamsFilter(params);
                setLoading(false);
            })
            .catch((_) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoading(false);
            });
        return true;
    };

    const searchText = (text) => {
        if (text.length > 0 && text.trim()) {
            setBusca(text);
        } else {
            setBusca("");
        }
    };

    return (
        <div className="conteudo-tabela">
            <form>
                <div>
                    <div>
                        <SearchField
                            placeholder="Pesquise aqui o nome do profissional, cliente ou número da solicitação"
                            onClick={handleSumbit}
                            onChange={(e) => searchText(e.target.value)}
                            search={busca}
                        />
                    </div>

                    <div className={styles.containerFilds}>
                        <div className={styles.subContainerFilds}>
                            <span>Filtrar resultados</span>
                            <div className={styles.containerSelect}>
                                <div className={styles.fieldSelect}>
                                    <select
                                        className="form-select"
                                        value={unidadeID}
                                        onChange={(e) =>
                                            setUnidadeID(e.target.value)
                                        }
                                    >
                                        <option value="" key="0" disabled>
                                            Unidade
                                        </option>
                                        <option value="0" key="999">
                                            Todos
                                        </option>
                                        {unidadesServer.map(({ id, nome }) => {
                                            return (
                                                <option value={id} key={id}>
                                                    {nome}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className={styles.fieldSelect}>
                                    <select
                                        className="form-select"
                                        value={status}
                                        onChange={(e) =>
                                            setStatus(e.target.value)
                                        }
                                    >
                                        <option value="" key="0" disabled>
                                            Status
                                        </option>
                                        <option value="0" key="999">
                                            Todos
                                        </option>
                                        {situacao.map(({ id, nome }, key) => {
                                            return (
                                                <option
                                                    value={id}
                                                    key={key + 1}
                                                >
                                                    {nome}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div
                                    className={`${styles.fieldSelect} ${styles.fieldSelectMarginTop}`}
                                >
                                    <select
                                        className="form-select"
                                        value={servicoID}
                                        onChange={(e) =>
                                            setServicoID(e.target.value)
                                        }
                                    >
                                        <option value="" key="0" disabled>
                                            Serviço
                                        </option>
                                        <option value="0" key="999">
                                            Todos
                                        </option>
                                        {servicos.map(({ id, nome }, key) => {
                                            return (
                                                <option
                                                    value={id}
                                                    key={key + 1}
                                                >
                                                    {limitString(nome)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className={styles.fieldSelect2}>
                                    <div className={styles.inputData}>
                                        <MyDatePicker
                                            value={dataInicio}
                                            title="Data início"
                                            onChange={(e) => setDataInicio(e)}
                                        />
                                    </div>
                                    <div className={styles.inputData}>
                                        <MyDatePicker
                                            value={dataFim}
                                            title="Data fim"
                                            onChange={(e) => setDataFim(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Search;
