import { Box, Chip, Grid, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import checkIcon from "./../../../assets/images/check.png";
import deleteIcon from "./../../../assets/images/delete.png";
import deleteIconCheck from "./../../../assets/images/deleteCheck.png";
import deleteIconError from "./../../../assets/images/deleteError.png";
import documentIcon from "./../../../assets/images/document.png";
import errorIcon from "./../../../assets/images/error.png";
import plusIcon from "./../../../assets/images/plus.png";
import sinoIcon from "./../../../assets/images/sino.png";
import sinoDesativadoIcon from "./../../../assets/images/sinoDesativado.png";
import { Api } from "./../../../providers/index";
import {
    ModalConfirScreenDoc,
    ModalConfirm,
    ModalJustificativa,
    ModalLoading,
} from "./../../modal/index";
import MyUpload from "./../../upload";
import styles from "./profissional.module.scss";

const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const PAPER_MAX_WIDTH = 814;
const CHIP_MAX_WIDTH = 117;
const CHIP_ICON_WIDTH = 16;

const EllipsisText = (props) => {
    const { children } = props;

    return (
        <div
            style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH,
            }}
        >
            {children}
        </div>
    );
};

//Responsavel por fazer com que o modal de novo enderco seja mostrado na tela toda vez que o operador entrar na aba de documentos
let showModNewAddr = true;

export default function TagsPanelProfissional({
    tabData,
    checkLoading,
    loadingData,
}) {
    const navigate = useNavigate();
    //Chip Data
    const [chipData, setChipData] = useState([]);
    //Modal
    const [openModal, setOpenModal] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [selectChip, setSelectChip] = useState(null);
    //Upload
    const [params, setParams] = useState({});
    const search = useLocation().search;
    const [idQuery, setIdQuery] = useState(
        new URLSearchParams(search).get("id"),
    );
    const [notificados, setNotificados] = useState([]);

    const [justificativaReprovacao, setJustificativaReprovacao] = useState(
        "Desatualização de documentos",
    );
    const [descricao, setDescricao] = useState("");
    const [tipoIdSelecionado, setTipoIdSelecionado] = useState();
    const [serviceSelecionado, setServiceSelecionado] = useState("");

    // Modal
    const [openReprovarCategoriaDocumento, setOpenReprovarCategoriaDocumento] =
        useState(false);

    const [textDescError, setTextDescError] = useState("");
    const [statusButton, setStatusButton] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);
    const [titleAnalysis, setTitleAnalysis] = useState("");
    const [messageAnalysis, setMessageAnalysis] = useState("");
    const [statusTipoId1, setStatusTipoId1] = useState(false);
    const [statusTipoId2, setStatusTipoId2] = useState(false);
    const [statusTipoId3, setStatusTipoId3] = useState(false);
    const [statusTipoId4, setStatusTipoId4] = useState(false);
    const [statusTipoId5, setStatusTipoId5] = useState(false);
    const [statusDocAnalysis, setStatusDocAnalysis] = useState(false);
    const [statusDocDisapproved, setStatusDocDisapproved] = useState(false);
    const [statusDocApproved, setStatusDocApproved] = useState(false);
    const [finalAction, setFinalAction] = useState(0);
    const [loadingModal, setLoadingModal] = useState(false);
    const [totalCourse, setTotalCourse] = useState(0);
    const [arrayNewService, setArrayNewService] = useState([]);
    const [arrayNewDocRes, setArrayNewDocRes] = useState([]);
    const [serviceStatusModal, setServiceStatusModal] = useState(false);

    const handleReprovarCategoria = () => {
        let dado = {
            profissionalId: idQuery,
            documentoTipoId: tipoIdSelecionado,
            servicoId: serviceSelecionado,
            justificativa: justificativaReprovacao,
            descricao: descricao,
        };

        Api.post(
            "/documento/profissional/" + idQuery + "/notificacao",
            dado,
        ).then((res) => {
            setOpenReprovarCategoriaDocumento(false);
            if (res.status === 200) {
                toast.success("Atualizado com Sucesso", {
                    toastId: "success",
                });
                setNotificationStatus(!notificationStatus);
            }
        });
    };

    useEffect(() => {
        if (!openReprovarCategoriaDocumento) {
            if (justificativaReprovacao === "Outro") {
                setTextDescError("");
                setDescricao("");
                setStatusButton(true);
            } else {
                setTextDescError("");
                setDescricao("");
            }
        }
    }, [openReprovarCategoriaDocumento]);

    const checkNewService = () => {
        Api.get(`/profissional/${idQuery}/servico`)
            .then((res) => {
                if (res.status === 200) {
                    const checkService = res.data
                        .filter(
                            (data) => data.flagAtivo === null && data.flagNovo,
                        )
                        .map((value) => value.servicoId);
                    if (checkService.length > 0) {
                        setArrayNewService(checkService);
                    } else {
                        setArrayNewService([]);
                    }
                }
            })
            .catch((error) => {
                const checkErro = error;
                toast.error(`Ops!  ${checkErro.response.data.message?.error}`, {
                    toastId: "msg",
                });
            });
    };

    useEffect(() => {
        checkNewService();
        const docRes = tabData?.docProfessional?.filter(
            (value) => value.documentoTipoId == 3 && !value.analise,
        );
        if (
            docRes?.length > 0 &&
            tabData.dataProfessional?.situacao?.slug != "inscrito"
        ) {
            setArrayNewDocRes(docRes);
            if (showModNewAddr && checkLoading) {
                setServiceStatusModal(true);
                showModNewAddr = false;
            }
        }
    }, []);

    useEffect(() => {
        return () => {
            if (checkLoading) {
                showModNewAddr = true;
            }
        };
    }, []);

    // Icon
    const getIconStatus = (status) => {
        if (status === "Análise") return documentIcon;
        if (status === "Aprovado") return checkIcon;
        if (status === "Reprovado") return errorIcon;
    };

    // Delete
    const getIconDelete = (status) => {
        if (status === "Análise") return deleteIcon;
        if (status === "Aprovado") return deleteIconCheck;
        if (status === "Reprovado") return deleteIconError;
    };

    //Status
    const getColorStatus = (status) => {
        if (status === "Análise") return "#0064B4";
        if (status === "Aprovado") return "#95C11F";
        if (status === "Reprovado") return "#E60E19";
    };
    /* Containers */
    // ButtonPlus
    const AddButtonPlus = ({ type, service }) => {
        const resultStatus =
            tabData.dataProfessional?.situacao?.slug == "inscrito" ||
            (tabData.dataProfessional?.situacao?.slug == "ativo" &&
                arrayNewService.includes(service)) ||
            (type == 3 &&
                arrayNewDocRes.length > 0 &&
                tabData.dataProfessional?.situacao?.slug == "ativo");
        return (
            <span
                style={{
                    display: "inline-flex",
                    alignItems: "center",
                }}
            >
                <div
                    onClick={
                        resultStatus
                            ? () => {
                                  //Up Type
                                  let paramsData = {
                                      type: type,
                                      service: service, //se serviço
                                      profissionalId: idQuery,
                                      noReload: true,
                                  };
                                  setParams(paramsData);

                                  setTimeout(() => {
                                      setOpenModal(true);
                                  }, 500);
                              }
                            : () => {}
                    }
                >
                    {resultStatus && (
                        <img
                            src={plusIcon}
                            alt="ico"
                            style={{
                                color: "#0064B4",
                            }}
                            width={"24px"}
                            height={"24px"}
                        />
                    )}
                </div>
            </span>
        );
    };
    // Add Chip
    const AddChip = ({ chip }) => {
        const myColor = getColorStatus(chip.status);
        const resultStatus =
            tabData.dataProfessional?.situacao?.slug == "inscrito" ||
            (tabData.dataProfessional?.situacao?.slug == "ativo" &&
                arrayNewService.includes(chip.serviceId)) ||
            (chip.type == 3 &&
                arrayNewDocRes.length > 0 &&
                tabData.dataProfessional?.situacao?.slug == "ativo");
        return (
            <Box>
                <ListItem>
                    {resultStatus ? (
                        <Chip
                            sx={{
                                display: "flex",
                                color: {
                                    myColor,
                                },
                                minWidth: CHIP_MAX_WIDTH,
                                height: 35,
                                background: "#F7F7F7",
                                border: `1px solid ${myColor}`, //#1486E1
                                boxSizing: "border-box",
                                borderRadius: "8px",
                                justifyContent: "space-between",
                                p: 0.5,
                                m: 0,
                                "&.MuiChip-deleteIcon": {
                                    size: CHIP_ICON_WIDTH,
                                },
                                "&.MuiChip-root": {},
                            }}
                            size="medium"
                            icon={
                                <img
                                    src={getIconStatus(chip.status)}
                                    alt="ico"
                                    style={{
                                        color: myColor,
                                        size: CHIP_ICON_WIDTH,
                                    }}
                                />
                            }
                            deleteIcon={
                                <img
                                    src={getIconDelete(chip.status)}
                                    alt={chip.status}
                                    style={{
                                        color: myColor,
                                        size: CHIP_ICON_WIDTH,
                                    }}
                                />
                            }
                            label={<EllipsisText>{chip.label}</EllipsisText>}
                            onClick={(e) => {
                                handleClick(chip);
                            }}
                            onDelete={() => {
                                setSelectChip(chip.id);
                                setOpenModalConfirm(true);
                            }}
                        />
                    ) : (
                        <Chip
                            sx={{
                                display: "flex",
                                color: {
                                    myColor,
                                },
                                minWidth: CHIP_MAX_WIDTH,
                                height: 35,
                                background: "#F7F7F7",
                                border: `1px solid ${myColor}`, //#1486E1
                                boxSizing: "border-box",
                                borderRadius: "8px",
                                justifyContent: "space-between",
                                p: 0.5,
                                m: 0,
                                "&.MuiChip-deleteIcon": {
                                    size: CHIP_ICON_WIDTH,
                                },
                                "&.MuiChip-root": {},
                            }}
                            size="medium"
                            icon={
                                <img
                                    src={getIconStatus(chip.status)}
                                    alt="ico"
                                    style={{
                                        color: myColor,
                                        size: CHIP_ICON_WIDTH,
                                    }}
                                />
                            }
                            deleteIcon={
                                <img
                                    src={getIconDelete(chip.status)}
                                    alt={chip.status}
                                    style={{
                                        color: myColor,
                                        size: CHIP_ICON_WIDTH,
                                    }}
                                />
                            }
                            label={<EllipsisText>{chip.label}</EllipsisText>}
                            onClick={(e) => {
                                handleClick(chip);
                            }}
                        />
                    )}
                </ListItem>
            </Box>
        );
    };

    const ServicoTag = ({ type, service, data }) => {
        const resultStatus =
            tabData.dataProfessional?.situacao?.slug == "inscrito" ||
            (tabData.dataProfessional?.situacao?.slug == "ativo" &&
                arrayNewService.includes(service.id));
        return (
            <Grid
                container
                direction="row"
                spacing={2}
                sx={{
                    mb: 3,
                    p: 2,
                    border: "0.5px solid #B3B5B6",
                    borderRadius: "8px",
                    backgroundColor: resultStatus ? "transparent" : "#F7F7F7",
                }}
            >
                <Stack sx={{ width: "100%" }}>
                    <Typography
                        variant="inherit"
                        direction="row"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{service.nome}</span>
                        <div>
                            {isServicoNotificado(type, service.id) ? (
                                <span
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={sinoDesativadoIcon} />
                                </span>
                            ) : (
                                resultStatus && (
                                    <a
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                        onClick={() => {
                                            setOpenReprovarCategoriaDocumento(
                                                true,
                                            );
                                            setTipoIdSelecionado(type);
                                            setServiceSelecionado(service.id);
                                        }}
                                    >
                                        <img src={sinoIcon} />
                                    </a>
                                )
                            )}
                        </div>
                    </Typography>
                    <Stack
                        direction="row"
                        sx={{
                            display: "flex",
                            alignContent: "baseline",
                            flexWrap: "wrap",
                            maxWidth: PAPER_MAX_WIDTH,
                        }}
                    >
                        {/* For Chips */}
                        {data.map((item, key) => {
                            if (service.id == item.serviceId) {
                                return <AddChip key={key} chip={item} />;
                            }
                        })}
                        {/* Add button */}
                        <span
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <AddButtonPlus type={type} service={service.id} />
                        </span>
                    </Stack>
                </Stack>
            </Grid>
        );
    };
    //Chips Data
    useEffect(() => {
        //Tratamento de Serviços com arquivos
        let res = [];

        if (tabData.tipoDocumento.length > 0) {
            if (
                tabData.dataProfessional?.situacao?.slug == "inscrito" ||
                (tabData.dataProfessional?.situacao?.slug == "ativo" &&
                    (arrayNewService.length > 0 || arrayNewDocRes.length > 0))
            ) {
                setStatusTipoId1(false);
                setStatusTipoId2(false);
                setStatusTipoId3(false);
                setStatusTipoId4(false);
                setStatusTipoId5(false);
                setStatusDocApproved(false);
                setStatusDocAnalysis(false);
                setStatusDocDisapproved(false);
                setStatusDocApproved(false);
            }
            let checkDocAnalysis = false;
            let checkDocDisapproved = false;

            const totalApproved = tabData.docProfessional.filter(
                (data) => data.status === "Aprovado",
            );

            // ****************************************************************

            const totalProofAddressApproved = tabData.docProfessional.filter(
                (data) =>
                    data.documentoTipoId === 3 && data.status === "Aprovado",
            );
            const totalProofAddress = tabData.docProfessional.filter(
                (data) => data.documentoTipoId === 3,
            );

            // ****************************************************************

            const totalProofExperienceApproved = tabData.docProfessional.filter(
                (data) =>
                    data.documentoTipoId === 4 && data.status === "Aprovado",
            );
            const totalProofExperience = tabData.docProfessional.filter(
                (data) => data.documentoTipoId === 4,
            );
            const totalServiceCourse = tabData.docProfessional.filter(
                (data) => data.documentoTipoId === 2,
            );

            const serviceData = tabData.dataProfessional?.servicos?.map(
                (data) => {
                    return data?.slug;
                },
            );

            const toalProofExpe = totalProofExperience.map((data) => {
                return data.servico?.slug;
            });

            const toalServiceData = totalServiceCourse.map((data) => {
                return data.servico?.slug;
            });

            const uniqueProofExpe = toalProofExpe.filter((element, index) => {
                return toalProofExpe.indexOf(element) === index;
            });

            const uniqueService = toalServiceData.filter((element, index) => {
                return toalServiceData.indexOf(element) === index;
            });

            setTotalCourse(serviceData?.length);

            // documentType = 1 => Documento oficial com foto
            // documentType = 2 => Certificado de cursos(Opcional)
            // documentType = 3 => Comprovante de residência
            // documentType = 4 => Comprovante de experiência
            // documentType = 5 => Certificado de antecedentes criminais

            tabData.tipoDocumento.forEach((documentType) => {
                let data = {
                    tipoId: documentType.id,
                    subtitle: documentType.nome,
                    data: [],
                };
                Array.isArray(tabData.docProfessional) &&
                    tabData.docProfessional.forEach((item, key) => {
                        if (documentType.id === item.documentoTipoId) {
                            //ID Profissional: Upload
                            if (item.profissionalId && idQuery === null)
                                setIdQuery(item.profissionalId);

                            //Dados
                            data.data.push({
                                id: item.id,
                                label: item.nome,
                                url: item.caminho,
                                status: item.status,
                                profissionalId: item.profissionalId,
                                type: item.documentoTipoId,
                                tipeName: item.documentoTipoNome,
                                service: item.servico?.nome,
                                serviceId: item.servico?.id,
                            });

                            if (
                                tabData.dataProfessional?.situacao?.slug ==
                                    "inscrito" ||
                                (tabData.dataProfessional?.situacao?.slug ==
                                    "ativo" &&
                                    (arrayNewService.length > 0 ||
                                        arrayNewDocRes.length > 0))
                            ) {
                                if (
                                    item.status === "Análise" &&
                                    !checkDocAnalysis
                                ) {
                                    setStatusDocAnalysis(true);
                                    checkDocAnalysis = true;
                                }
                                if (
                                    (item.status === "Reprovado" &&
                                        !checkDocDisapproved) ||
                                    notificados.length > 0
                                ) {
                                    setStatusDocDisapproved(true);
                                    checkDocDisapproved = true;
                                }
                                if (
                                    totalApproved.length ===
                                        tabData.docProfessional.length &&
                                    totalProofExperienceApproved.length ===
                                        totalProofExperience.length &&
                                    serviceData?.length ===
                                        uniqueProofExpe.length
                                ) {
                                    setStatusDocApproved(true);
                                }

                                switch (documentType.id) {
                                    case 1:
                                        setStatusTipoId1(true);
                                        break;
                                    case 2:
                                        if (
                                            serviceData?.length ===
                                            uniqueService.length
                                        ) {
                                            setStatusTipoId2(true);
                                        }
                                        break;
                                    case 3:
                                        setStatusTipoId3(true);
                                        break;
                                    case 4:
                                        if (
                                            serviceData?.length ===
                                            uniqueProofExpe.length
                                        ) {
                                            setStatusTipoId4(true);
                                        }
                                        break;
                                    case 5:
                                        setStatusTipoId5(true);
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                    });

                //Add chip data
                res.push(data);
            });
        }

        const resultData1 = res.filter((data) => data.tipoId === 1)[0];
        const resultData2 = res.filter((data) => data.tipoId === 2)[0];
        const resultData3 = res.filter((data) => data.tipoId === 3)[0];
        const resultData4 = res.filter((data) => data.tipoId === 4)[0];
        const resultData5 = res.filter((data) => data.tipoId === 5)[0];

        res[0] = resultData1;
        res[1] = resultData4;
        res[2] = resultData2;
        res[3] = resultData3;
        res[4] = resultData5;

        setChipData(res);

        Api.get(
            "/documento/profissional/" + idQuery + "/notificacao/categoria",
        ).then((resNotificacao) => {
            if (
                resNotificacao.status === 200 &&
                resNotificacao.data.length !== notificados.length
            ) {
                setNotificados(resNotificacao.data);
            }
        });
    }, [
        tabData.docProfessional,
        notificationStatus,
        arrayNewService,
        notificados,
        arrayNewDocRes,
    ]);

    const isServicoNotificado = (idTipo, idServico = 0) => {
        let retorno = false;
        if (idServico !== 0) {
            notificados.map((row) => {
                if (
                    row.documentoTipoId === idTipo &&
                    row.servicoId === idServico
                )
                    retorno = true;
            });
        } else {
            notificados.map((row) => {
                if (row.documentoTipoId === idTipo) retorno = true;
            });
        }

        return retorno;
    };

    //Delete document
    const handleDelete = (id) => {
        setOpenModalConfirm(false);
        setLoadingModal(true);
        Api.delete(`/documento/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Deletado com Sucesso", {
                        toastId: "msg",
                        autoClose: 1000,
                    });
                    loadingData();
                    setLoadingModal(false);
                }
            })
            .catch((_) => {
                toast.error("Ops! Aconteceu algum problema!", {
                    toastId: "msg",
                });
                setLoadingModal(false);
            });
    };

    //View Document
    const handleClick = (chip) => {
        navigate(
            `/viewdocuments/?id=${chip.profissionalId}&tipo=${chip.type}&url=${chip.url}`,
        );
    };

    function textDesc(desc) {
        if (desc.length <= 0) {
            setDescricao("");
            if (justificativaReprovacao === "Outro") {
                setTextDescError("Campo obrigatório");
                setStatusButton(true);
            } else {
                setTextDescError("");
                setStatusButton(false);
            }
        } else {
            if (desc.length <= 200) {
                setDescricao(desc);
                setTextDescError("");
                setStatusButton(false);
            }
        }
    }

    const checkJustification = (data) => {
        setJustificativaReprovacao(data);
        if (data === "Outro") {
            textDesc(descricao);
        } else {
            setStatusButton(false);
        }
    };

    useEffect(() => {
        textDesc(descricao);
    }, [justificativaReprovacao]);

    const arrayTitle = [
        "Ausência de documentos",
        "Análise incompleta",
        "Análise recusada",
        "Ausência de Certificado de curso",
        "Análise completa",
    ];

    const analysisDocAppr = statusDocApproved ? 4 : 3;

    const courseCertificate = !statusTipoId2 ? 3 : analysisDocAppr;

    const analysisDocDisap = statusDocDisapproved ? 2 : courseCertificate;

    const analysisDocAnaly = statusDocAnalysis ? 1 : analysisDocDisap;

    const typeCode =
        !statusTipoId1 || !statusTipoId3 || !statusTipoId4 || !statusTipoId5
            ? 0
            : analysisDocAnaly;

    const handleCheckAnalysis = () => {
        switch (typeCode) {
            case 0:
                setTitleAnalysis(arrayTitle[typeCode]);
                const messageStatus01 =
                    "São obrigatórios os documentos nas categorias: Documento oficial com foto, Comprovante de experiência, Comprovante de residência e Certificado de antecedentes criminais.";
                const messageStatus02 =
                    "Os documentos na categoria de Comprovante de experiência são obrigatórios.";
                const message01 =
                    tabData.dataProfessional?.situacao?.slug == "inscrito"
                        ? messageStatus01
                        : messageStatus02;
                setMessageAnalysis(message01);
                setModalInfo(true);
                break;
            case 1:
                setTitleAnalysis(arrayTitle[typeCode]);
                const message02 =
                    "Não é possível concluir análise pois existem documentos que não foram analisados ainda.";
                setMessageAnalysis(message02);
                setModalInfo(true);
                break;
            case 2:
                setTitleAnalysis(arrayTitle[typeCode]);
                const message03 =
                    "A análise não pode ser concluída pois existem documentos ou categorias com reprovações. Verifique até que todos os documentos estejam aprovados e não existam reprovações em nenhuma categoria.";
                setMessageAnalysis(message03);
                setModalInfo(true);
                break;
            case 3:
                setTitleAnalysis(arrayTitle[typeCode]);
                const message05 =
                    "Existem serviços sem certificado de curso, tem certeza que deseja concluir a análise sem documentos nessa categoria?";
                setMessageAnalysis(message05);
                setModalInfo(true);
                setFinalAction(3);
                break;
            case 4:
                setTitleAnalysis(arrayTitle[typeCode]);
                const messageStatus03 =
                    "Tem certeza que deseja concluir essa análise? Essa ação não poderá ser desfeita e o profissional passará para status de convocado.";
                const messageStatus04 =
                    "Tem certeza que deseja concluir essa análise? Essa ação não poderá ser desfeita.";
                const message06 =
                    tabData.dataProfessional?.situacao?.slug == "inscrito"
                        ? messageStatus03
                        : messageStatus04;
                setMessageAnalysis(message06);
                setModalInfo(true);
                setFinalAction(4);
                break;
        }
    };

    const checkAnalysis = async () => {
        setModalInfo(false);
        setLoadingModal(true);
        Api.get(`/documento/profissional/${idQuery}/analise`)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.data.message.success, {
                        toastId: "msg",
                    });
                    setLoadingModal(false);
                    loadingData();
                    setTimeout(() => {
                        checkNewService();
                    }, 2000);
                }
            })
            .catch((error) => {
                setLoadingModal(false);
                setFinalAction(5);
                const checkErro = error;
                toast.error(`Ops!  ${checkErro.response.data.message?.error}`, {
                    toastId: "msg",
                });
            });
    };

    const handleAbsenceCourse = () => {
        setTitleAnalysis(arrayTitle[4]);
        const messageStatus =
            "Tem certeza que deseja concluir essa análise? Essa ação não poderá ser desfeita e o profissional passará para status de convocado.";
        const messageStatus01 =
            "Tem certeza que deseja concluir essa análise? Essa ação não poderá ser desfeita.";
        const message =
            tabData.dataProfessional?.situacao?.slug == "inscrito"
                ? messageStatus
                : messageStatus01;
        setMessageAnalysis(message);
        setModalInfo(true);
    };

    const handleAbsenceCoursePositive = () => {
        setLoadingModal(true);
        setModalInfo(false);
        setMessageAnalysis("");
        setFinalAction(4);
        setTimeout(() => {
            setLoadingModal(false);
            handleAbsenceCourse();
        }, 2000);
    };

    const handlePositiveButton = () => {
        setModalInfo(false);
        switch (finalAction) {
            case 3:
                handleAbsenceCoursePositive();
                break;
            case 4:
                checkAnalysis();
                break;
            default:
                setModalInfo(false);
                break;
        }
    };

    const handleCancelAction = () => {
        setModalInfo(false);
        setFinalAction(5);
    };

    const redirectPage = (data) => {
        setLoadingModal(true);
        setTimeout(() => {
            setLoadingModal(false);
            handleClick(data);
        }, 2000);
    };

    const textButton = typeCode === 3 || typeCode === 4 ? true : false;
    const checkButtonSubmit =
        tabData.dataProfessional?.situacao?.slug == "inscrito" ||
        (tabData.dataProfessional?.situacao?.slug == "ativo" &&
            (arrayNewService.length > 0 || arrayNewDocRes.length > 0))
            ? true
            : false;

    const resultStatuseXP =
        tabData.dataProfessional?.situacao?.slug == "inscrito";

    const profAtivo = tabData.dataProfessional?.situacao?.slug == "ativo";

    return (
        <>
            {chipData.length > 0 &&
                chipData.map((row, index) => {
                    return (
                        <Box spacing={0.5} key={index}>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    my: "16px",
                                }}
                            >
                                {row?.subtitle}
                            </Typography>
                            <Paper
                                sx={{
                                    display: "flex",
                                    minHeight: 107,
                                    borderRadius: "8px",
                                    justifyContent: "space-between",
                                    alignContent: "baseline",
                                    flexWrap: "wrap",
                                    listStyle: "none",
                                    p: "32px",
                                    mb: "16px",
                                }}
                                component="ul"
                            >
                                {/* Chips without tags*/}
                                {row?.tipoId != 2 && row?.tipoId != 4 ? (
                                    <>
                                        <div
                                            style={{
                                                flexDirection: "row",
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                marginTop: -15,
                                            }}
                                        >
                                            {/* Mostra o sino desativado */}
                                            {isServicoNotificado(row.tipoId) ? (
                                                <span
                                                    style={{
                                                        display: "inline-flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img
                                                        src={sinoDesativadoIcon}
                                                    />
                                                </span>
                                            ) : (
                                                resultStatuseXP && (
                                                    <a
                                                        onClick={() => {
                                                            setOpenReprovarCategoriaDocumento(
                                                                true,
                                                            );
                                                            setTipoIdSelecionado(
                                                                row?.tipoId,
                                                            );
                                                        }}
                                                    >
                                                        <img src={sinoIcon} />
                                                    </a>
                                                )
                                            )}
                                            {/* Mostra se o prof for ativo na categoria comprovante de residencia */}
                                            {row?.tipoId == 3 &&
                                                profAtivo &&
                                                arrayNewDocRes.length > 0 &&
                                                !isServicoNotificado(
                                                    row.tipoId,
                                                ) && (
                                                    <a
                                                        onClick={() => {
                                                            setOpenReprovarCategoriaDocumento(
                                                                true,
                                                            );
                                                            setTipoIdSelecionado(
                                                                row?.tipoId,
                                                            );
                                                        }}
                                                    >
                                                        <img src={sinoIcon} />
                                                    </a>
                                                )}
                                        </div>
                                        <span
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {row?.data.map((row, key) => {
                                                return (
                                                    <AddChip
                                                        key={key}
                                                        chip={row}
                                                    />
                                                );
                                            })}
                                            <AddButtonPlus
                                                type={row?.tipoId}
                                                service={0} //Sem serviços
                                            />
                                        </span>
                                    </>
                                ) : (
                                    tabData.dataProfessional?.servicos?.map(
                                        (tags, index) => {
                                            return (
                                                <ServicoTag
                                                    key={index}
                                                    type={row?.tipoId}
                                                    data={row?.data}
                                                    service={tags}
                                                />
                                            );
                                        },
                                    )
                                )}
                            </Paper>
                        </Box>
                    );
                })}
            <div className={styles.containerButton}>
                <button
                    className={
                        !checkButtonSubmit
                            ? styles.buttonSearchBlock
                            : styles.buttonSearch
                    }
                    type="submit"
                    onClick={
                        checkButtonSubmit
                            ? () => handleCheckAnalysis()
                            : () => {}
                    }
                    disabled={!checkButtonSubmit ? true : false}
                >
                    <span>Concluir análise</span>
                </button>
            </div>
            {/* Upload */}
            <>
                <MyUpload
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    params={params}
                    redirectPage={redirectPage}
                    loadingData={loadingData}
                />
                <ModalConfirm
                    title="Excluir documento"
                    description="Tem certeza que deseja excluir esse documento? Essa ação não poderá ser desfeita."
                    openModal={openModalConfirm}
                    setOpenModal={setOpenModalConfirm}
                    onClick={() => handleDelete(selectChip)}
                    labelConfirm="Excluir"
                    labelXSair="nao"
                />

                <ModalJustificativa
                    styles={{
                        minHeight: "578px",
                        width: "558px",
                    }}
                    title="Reprovar documentos"
                    description=""
                    openModal={openReprovarCategoriaDocumento}
                    setOpenModal={setOpenReprovarCategoriaDocumento}
                    onClick={() => handleReprovarCategoria()}
                    labelConfirm="Confirmar"
                    backgroundCacel="#fff"
                    color={statusButton ? "" : "blue"}
                    disabled={statusButton}
                >
                    <Container className="justify-content-end">
                        <Form.Group className="d-flex justify-content-center align-items-center container mb-4 p-2">
                            <div className="boxCommentProfessional">
                                <div className="boxCommentProfessionalText">
                                    <Form.Label>Nome</Form.Label>
                                </div>

                                <Form.Control
                                    type="text"
                                    placeholder="Nome"
                                    name="nome"
                                    id="nome"
                                    disabled={true}
                                    value={tabData.dataProfessional?.nome}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="d-flex justify-content-center align-items-center container mb-4 p-2">
                            <div className="boxCommentProfessional">
                                <div className="boxCommentProfessionalText">
                                    <Form.Label>
                                        Justificativa da reprovação
                                    </Form.Label>
                                </div>
                                <Form.Select
                                    className="select-modalidade"
                                    value={justificativaReprovacao}
                                    onChange={(e) =>
                                        checkJustification(e.target.value)
                                    }
                                >
                                    <option
                                        value="Desatualização de documentos"
                                        key="1"
                                    >
                                        Desatualização de documentos
                                    </option>
                                    <option
                                        value="Ausência de documentos"
                                        key="2"
                                    >
                                        Ausência de documentos
                                    </option>
                                    <option value="Outro" key="3">
                                        Outro
                                    </option>
                                </Form.Select>
                            </div>
                        </Form.Group>

                        <Form.Group className="d-flex justify-content-center align-items-center container mb-4 p-2">
                            <div className="boxCommentProfessional">
                                <div className="boxCommentProfessionalText">
                                    <Form.Label>Descrição</Form.Label>
                                </div>
                                <Form.Control
                                    className="textAreaCommentProfessional"
                                    as="textarea"
                                    rows={3}
                                    name="descricao"
                                    id="descricao"
                                    maxLength={200}
                                    value={descricao}
                                    onChange={(e) => textDesc(e.target.value)}
                                />
                                <span className={styles.requiredField}>
                                    {textDescError}
                                </span>
                                <div className="d-flex justify-content-end">
                                    <span className={styles.characterNumber}>
                                        {descricao.length}
                                    </span>
                                    <span className={styles.characterLetter}>
                                        /200 caracteres
                                    </span>
                                </div>
                            </div>
                        </Form.Group>
                    </Container>
                </ModalJustificativa>

                <ModalConfirm
                    title={titleAnalysis}
                    description={messageAnalysis}
                    openModal={modalInfo}
                    setOpenModal={() => handleCancelAction()}
                    onClick={() => handlePositiveButton()}
                    labelConfirm={textButton ? "Sim" : "Ok"}
                    color="blue"
                    labelXSair="nao"
                    hasCancel={textButton ? "" : "no"}
                    labelCancel="Não"
                />

                <ModalConfirScreenDoc
                    title="Novo endereço cadastrado"
                    description={`Esse profissional cadastrou um novo endereço no sistema. 
                    É necessário analisar o novo comprovante.`}
                    openModal={serviceStatusModal}
                    setOpenModal={setServiceStatusModal}
                    onClick={() => setServiceStatusModal(false)}
                    labelConfirm="Ok"
                    color="blue"
                    hasCancel="no"
                    labelXSair="nao"
                />

                <ModalLoading
                    styles={{
                        width: "200px",
                    }}
                    openModal={loadingModal}
                    setOpenModal={setLoadingModal}
                >
                    <Container>
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            />
                            <span className="visually">Aguarde</span>
                        </div>
                    </Container>
                </ModalLoading>
            </>
        </>
    );
}
