import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useState } from "react";
import { ServiceContext } from "../../../contexts/service";
import Section1 from "../../../pages/profissional/Section/section1";
import Section10 from "../../../pages/profissional/Section/section10";
import Section2 from "../../../pages/profissional/Section/section2";
import Section3 from "../../../pages/profissional/Section/section3";
import Section4 from "../../../pages/profissional/Section/section4";
import Section5 from "../../../pages/profissional/Section/section5";
import Section6 from "../../../pages/profissional/Section/section6";
import Section7 from "../../../pages/profissional/Section/section7";
import Section8 from "../../../pages/profissional/Section/section8";
import Section9 from "../../../pages/profissional/Section/section9";

/* Content */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

/* id */
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
/* TabLabel */
function TabLabel(props) {
    return <Tab {...props} />;
}
// Size Container
const widthDefault = "880px";
const heightDefault = "880px";

const tabCSS = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    minWidth: "90px",
    "&.MuiButtonBase-root": {
        minWidth: "90px",
    },
    "&.MuiTab-root": {
        minWidth: "90px",
    },
};

export default function TabsPanel({
    tabsData,
    statusNewService,
    checkLoading,
    loadingData,
}) {
    const { setNewServiceModal } = useContext(ServiceContext);
    //Default Tabs Open
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const BoxData = ({ children }) => {
        return (
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    background: "#F7F7F7",
                    borderRadius: "8px",
                    marginBottom: 7,
                    p: 3,
                }}
            >
                {children}
            </Box>
        );
    };

    //Renderiza por seção e carrega os dados
    const renderSwitch = (id, tabData) => {
        if (statusNewService) {
            setNewServiceModal(statusNewService);
        }
        switch (id) {
            case 1:
                return (
                    <Section1 tabData={tabData} handleChange={handleChange} />
                );
            case 2:
                return <Section2 tabData={tabData} />;
            case 3:
                return <Section3 tabData={tabData} />;
            case 4:
                return <Section4 tabData={tabData} />;
            case 5:
                return (
                    <BoxData>
                        <Section5
                            tabData={tabData}
                            checkLoading={checkLoading}
                            loadingData={loadingData}
                        />
                    </BoxData>
                );
            case 7:
                return <Section7 tabData={tabData} />;
            case 6:
                return (
                    <BoxData>
                        <Section6 />
                    </BoxData>
                );
            case 8:
                return (
                    <BoxData>
                        <Section8 />
                    </BoxData>
                );
            case 9:
                return (
                    <BoxData>
                        <Section9 />
                    </BoxData>
                );
            case 10:
                return (
                    <BoxData>
                        <Section10 />
                    </BoxData>
                );
            default:
                return;
        }
    };
    return (
        <Box
            sx={{
                maxWidth: widthDefault,
                minHeight: heightDefault,
            }}
        >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {/* labels */}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs"
                    variant={"scrollable"}
                >
                    {tabsData.map(({ tabLabel }, index) => {
                        // is it Selected?
                        tabCSS.color = value !== index ? "#C7C9D9" : "#0064B4";
                        return (
                            <TabLabel
                                key={index}
                                sx={tabCSS}
                                label={tabLabel}
                                {...a11yProps(index)}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            <Box sx={{ m: 2, color: "#ffffff" }}></Box>
            <div>
                {/* content */}
                {tabsData.map(({ id, tabData }, index) => {
                    return (
                        <TabPanel key={index} value={value} index={index}>
                            {renderSwitch(id, tabData)}
                        </TabPanel>
                    );
                })}
            </div>
        </Box>
    );
}
