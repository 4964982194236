import { Button, Grid, IconButton } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ImageChevronLeft from "../../assets/images/icons/chevron-left.png";
import ImageChevronRight from "../../assets/images/icons/chevron-right.png";
import { Api } from "../../providers/index";
import { Calendar } from "./calendar";
import styles from "./styles.module.scss";

const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
];

export const AgendaDate = ({
    appointments = [],
    competenciaAtual = (data) => {},
    edit = false,
    callBack = () => {
        //do nothing
    },
}) => {
    const [calendarComp, setCalendarComp] = useState([]);
    const [dateCalendar, setDateCalendar] = useState("");
    const [nameMonth, setNameMonth] = useState("");
    const [goCurrentMonth, setGoCurrentMonth] = useState(false);
    const [prevMonth, setPrevMonth] = useState(0);
    const [nextMonth, setNextMonth] = useState(0);
    const [competence, setCompetence] = useState("");
    const [currentDay, setCurrentDay] = useState("");
    const [maxDay, setMaxDay] = useState("");

    const getColor = {
        color1: "#F7F7F7",
        color2: "#ECEDEE",
        color3: "#FFF1CF",
        color4: "#D8D9DA",
        color5: "#8CCCFF",
        color6: "#FFDD98",
        color7: "#FFFFFF",
    };

    useEffect(() => {
        let mounted = true;
        Api.get("/system/date").then((res) => {
            if (mounted) {
                const dataMont =
                    moment(res.data.date).format("YYYY-MM") + "-01";
                setDateCalendar(dataMont);
                const calendar = Calendar(false, dataMont);
                setCalendarComp(calendar);
                const dateBrazil = new Date(dataMont);
                setNameMonth(
                    `${
                        monthNames[dateBrazil.getUTCMonth()]
                    } de ${dateBrazil.getUTCFullYear()}`,
                );
                const compet = moment(res.data.date).format("YYYY-MM");
                setCompetence(compet);
                setCurrentDay(res.data.date);
                competenciaAtual(compet);

                // **********************************************************
                const maxDateAvailable = new Date(res.data.date);
                maxDateAvailable.setUTCDate(maxDateAvailable.getUTCDate() + 89);
                const maxMonthDate = maxDateAvailable.getUTCMonth() + 1;
                const maxCurrentMonth =
                    maxMonthDate <= 9 ? "0" + maxMonthDate : maxMonthDate;
                const maxCurrentDay =
                    maxDateAvailable.getUTCDate() <= 9
                        ? "0" + maxDateAvailable.getUTCDate()
                        : maxDateAvailable.getUTCDate();
                const maxCurrentDate =
                    maxDateAvailable.getUTCFullYear() +
                    "-" +
                    maxCurrentMonth +
                    "-" +
                    maxCurrentDay;

                setMaxDay(maxCurrentDate);
            }
        });
        return () => {
            mounted = false;
        };
    }, [goCurrentMonth]);

    const handleChangeMonth = (typeOption = false) => {
        const nextDay1 = new Date(dateCalendar);
        if (typeOption) {
            setNextMonth((e) => e + 1);
            setPrevMonth((e) => e - 1);
            nextDay1.setUTCMonth(nextDay1.getUTCMonth() + 1);
        } else {
            setPrevMonth((e) => e + 1);
            setNextMonth((e) => e - 1);
            nextDay1.setUTCMonth(nextDay1.getUTCMonth() - 1);
        }

        const month = nextDay1.getUTCMonth() + 1;
        const resultMonth = month <= 9 ? `0${month}` : `${month}`;
        const fullDate = `${nextDay1.getUTCFullYear()}-${resultMonth}-01`;
        setDateCalendar(fullDate);
        setNameMonth(
            `${
                monthNames[nextDay1.getUTCMonth()]
            } de ${nextDay1.getUTCFullYear()}`,
        );
        const calendar = Calendar(false, fullDate);
        setCalendarComp(calendar);
        const compet = moment(fullDate).format("YYYY-MM");
        setCompetence(compet);
        competenciaAtual(compet);
    };

    const handleCurrentMonth = () => {
        setGoCurrentMonth(!goCurrentMonth);
        setPrevMonth(() => 0);
        setNextMonth(() => 0);
    };

    return (
        <Grid container direction="row">
            <div className={styles.containerMonth}>
                <span className={styles.titleMonth}>{nameMonth}</span>
                <div className={styles.containerNextMonth}>
                    <IconButton
                        color="primary"
                        size="medium"
                        onClick={() =>
                            prevMonth <= 0 ? handleChangeMonth(false) : {}
                        }
                    >
                        <img
                            src={ImageChevronLeft}
                            style={{ width: 32, height: 32 }}
                        />
                    </IconButton>
                    <Button variant="text" onClick={() => handleCurrentMonth()}>
                        <div className={styles.currentMonth}>Mês atual</div>
                    </Button>
                    <IconButton
                        color="primary"
                        size="medium"
                        onClick={() =>
                            nextMonth <= 2 ? handleChangeMonth(true) : {}
                        }
                    >
                        <img
                            src={ImageChevronRight}
                            style={{ width: 32, height: 32 }}
                        />
                    </IconButton>
                </div>
            </div>

            <div
                style={{
                    width: "100%",
                }}
                className={styles.tableCalendarHeader}
            >
                <table
                    style={{
                        display: "flex",
                        gap: 9,
                        justifyContent: "center",
                    }}
                >
                    {calendarComp.length > 0 &&
                        calendarComp[0].week.map((value) => {
                            return (
                                <tr
                                    key={value.fullDate}
                                    style={{
                                        alignItems: "center",
                                        width: "14.2857142%",
                                    }}
                                >
                                    <td>{value.dayWeek}</td>
                                    <div style={{ width: 40 }} />
                                </tr>
                            );
                        })}
                </table>
            </div>

            {/* ************************************************************* */}

            <div
                style={{
                    width: "100%",
                }}
                className={styles.tableCalendarBody}
            >
                <table
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                    }}
                >
                    {!edit
                        ? calendarComp.map((data) => {
                              return data.week.map((value) => {
                                  const compet = moment(value.fullDate).format(
                                      "YYYY-MM",
                                  );
                                  const statusColor =
                                      compet === competence ? true : false;

                                  const outCompColor = getColor.color1;

                                  let colorDate = getColor.color2;
                                  const resultDate = appointments.filter(
                                      (dateValue) =>
                                          dateValue.date === value.fullDate,
                                  );
                                  if (resultDate.length > 0) {
                                      colorDate = resultDate[0].color;
                                  }

                                  if (
                                      value.fullDate === currentDay &&
                                      !resultDate[0]?.priority
                                  ) {
                                      colorDate = getColor.color3;
                                  }

                                  return (
                                      <td
                                          key={value.fullDate}
                                          style={{
                                              width: "14.2857142%",
                                              backgroundColor: statusColor
                                                  ? colorDate
                                                  : outCompColor,
                                          }}
                                      >
                                          <div
                                              className={
                                                  statusColor
                                                      ? styles.colorTextNumber01
                                                      : styles.colorTextNumber02
                                              }
                                          >
                                              {value.dateDay}
                                          </div>
                                          <div
                                              style={{
                                                  width: 40,
                                              }}
                                          />
                                      </td>
                                  );
                              });
                          })
                        : calendarComp.map((data) => {
                              return data.week.map((value) => {
                                  const compet = moment(value.fullDate).format(
                                      "YYYY-MM",
                                  );

                                  const date1 = moment(
                                      value.fullDate,
                                      "YYYY-MM-DD",
                                  ).valueOf();
                                  const date2 = moment(
                                      currentDay,
                                      "YYYY-MM-DD",
                                  ).valueOf();

                                  const date3 = moment(
                                      maxDay,
                                      "YYYY-MM-DD",
                                  ).valueOf();

                                  const statusColor =
                                      compet === competence ? true : false;

                                  const outCompColor = getColor.color4;

                                  let colorDate = getColor.color2;
                                  const resultDate = appointments.filter(
                                      (dateValue) =>
                                          dateValue.date === value.fullDate,
                                  );
                                  if (resultDate.length > 0) {
                                      colorDate = resultDate[0].color;
                                  }

                                  if (date1 < date2) {
                                      colorDate = getColor.color4;
                                  }

                                  return (
                                      <td
                                          key={value.fullDate}
                                          style={{
                                              width: "14.2857142%",
                                              backgroundColor: statusColor
                                                  ? colorDate
                                                  : outCompColor,
                                          }}
                                          onClick={() =>
                                              (colorDate === getColor.color2 ||
                                                  colorDate ===
                                                      getColor.color5) &&
                                              statusColor &&
                                              date1 <= date3
                                                  ? callBack(
                                                        value.fullDate,
                                                        currentDay,
                                                    )
                                                  : {}
                                          }
                                      >
                                          <div
                                              className={
                                                  statusColor
                                                      ? styles.colorTextNumber01
                                                      : styles.colorTextNumber02
                                              }
                                          >
                                              {value.dateDay}
                                          </div>
                                          <div
                                              style={{
                                                  width: 40,
                                              }}
                                          />
                                      </td>
                                  );
                              });
                          })}
                </table>
            </div>
        </Grid>
    );
};
